import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { useTimeSelectionList } from "../store/useTimeSelectionList";
import { z } from "zod";
import { useLanguage } from "@/i18n/config";

type RejectComparisonResponse = ThingworxError | RejectComparisonResult;

const RejectData = z.object({
  cause: z.string(),
  description: z.string(),
  occurrences: z.number(),
});

type RejectData = z.infer<typeof RejectData>;

const RejectComparisonData = z.object({
  name: z.string(),
  data: z.array(RejectData),
});

export type RejectComparisonData = z.infer<typeof RejectComparisonData>;

const RejectComparisonResult = z.object({
  response: z.literal(true),
  data: z.array(RejectComparisonData),
});

type RejectComparisonResult = z.infer<typeof RejectComparisonResult>;

type Payload = {
  machineName: string;
  timeSelectionList: {
    name: string;
    dateStart: number;
    dateEnd: number;
  }[];
  language_id: string;
};

export const useGetRejectComparison = () => {
  const { machine } = useMachineContext();

  const { timeSelections } = useTimeSelectionList();

  const { appKey } = useFirebaseContext();
  const language = useLanguage();

  return useQuery<RejectComparisonData[]>({
    queryKey: ["reject-comparison", machine?.machine, timeSelections, language],
    queryFn: async () => {
      const response = await FetchClient<Payload, RejectComparisonResponse>({
        appKey,
        payload: {
          machineName: machine?.machine || "",
          timeSelectionList: timeSelections,
          language_id: language,
        },
        url: "dig.c.fillerAdvancedAnalytics_thing/Services/getMultiTimeSelectionReject",
      });

      if (!response.response) throw new Error(response.errorString);

      return RejectComparisonResult.parse(response).data;
    },
  });
};
