import { createContext, ReactElement } from "react";
import { useImmerReducer } from "use-immer";
import { Line } from "@/features/OverallLineEfficiency/api/useGetUserProductionLines";
import {
  LineConfig,
  LineMachineInfo,
  LineMachinesInfo,
} from "../../Edit/types";

type State = {
  editMode: boolean;
  selectedLine?: Line;
  view_mode: "automatic" | "manual";
  machinesInfo: LineMachinesInfo;
};

export type NestedTree = {
  company_id: number;
  company_name: string;
  factories: {
    factory_id: number;
    factory_name: string;
    lines: Line[];
  }[];
};

type Action =
  | {
      type: "select line";
      line: Line;
    }
  | {
      type: "update line";
      line_id: number;
      config: LineConfig;
      machinesInfo: LineMachinesInfo;
    }
  | {
      type: "enable edit mode";
    }
  | {
      type: "disable edit mode";
    }
  | {
      type: "add machine info";
      machine_info: LineMachineInfo;
    }
  | {
      type: "update machine info";
      machine_info: LineMachineInfo;
    }
  | {
      type: "remove machine info";
      machine_name: string;
    }
  | {
      type: "set view mode";
      view_mode: "automatic" | "manual";
    };

const reducer = (draft: State, action: Action) => {
  switch (action.type) {
    case "select line":
      if (draft.selectedLine?.line_id === action.line.line_id) {
        draft.selectedLine = undefined;
        return;
      } else {
        draft.editMode = false;
      }
      draft.machinesInfo = [];
      if (action.line.line_machines.length > 0) {
        draft.machinesInfo = action.line.line_machines.map((machine) => ({
          machine_name: machine.name,
          is_enabled: machine.is_enabled,
          //? da capire se può essere NULL oppure no
          line_layer: machine.line_layer || 0,
        }));
      }
      draft.selectedLine = action.line;
      break;

    /**
     * used to optimistically update the line configuration when the user edits the line
     */
    case "update line":
      if (draft.selectedLine?.line_id === action.line_id) {
        draft.selectedLine.line_configuration = action.config;
        draft.machinesInfo = action.machinesInfo;
      }
      break;

    case "enable edit mode":
      draft.editMode = true;
      break;

    case "disable edit mode":
      draft.editMode = false;
      break;

    case "add machine info":
      draft.machinesInfo = [...draft.machinesInfo, action.machine_info];
      break;

    case "update machine info": {
      const machineInfoIndex = draft.machinesInfo.findIndex(
        (el) => el.machine_name === action.machine_info.machine_name,
      );
      draft.machinesInfo[machineInfoIndex] = action.machine_info;
      break;
    }

    case "remove machine info":
      draft.machinesInfo = draft.machinesInfo.filter(
        (el) => el.machine_name !== action.machine_name,
      );
      break;

    case "set view mode":
      draft.view_mode = action.view_mode;
      draft.editMode = false;
      draft.selectedLine = undefined;
      break;
    default:
      break;
  }
};

export const OeeLineContext = createContext<State | null>(null);

export const OeeLineDispatchContext =
  createContext<React.Dispatch<Action> | null>(null);

export const OeeLineOverviewContextProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const [state, dispatch] = useImmerReducer<State, Action>(reducer, {
    editMode: false,
    view_mode: "automatic",
    machinesInfo: [],
  });

  return (
    <OeeLineContext.Provider value={state}>
      <OeeLineDispatchContext.Provider value={dispatch}>
        {children}
      </OeeLineDispatchContext.Provider>
    </OeeLineContext.Provider>
  );
};
