import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { Box } from "@mui/material";

export function LineEfficiencyTrend({
  data,
}: {
  data: { category: string; values: number[][] }[];
}) {
  const translate = useTranslate();
  return (
    <Card>
      <Box>
        <LineChart.Custom
          title={translate(
            "overall_line_efficiency.line_efficiency_time_series",
          )}
          legend={{ enabled: true }}
          xAxisOptions={{
            scrollbar: {
              enabled: false,
            },
          }}
          yAxis={[
            {
              options: {
                min: 0,
                max: 100,
              },
              uom: "%",
              series: data.map((item) => ({
                name: item.category,
                data: item.values,
                type: "line",
              })),
            },
          ]}
        />
      </Box>
    </Card>
  );
}
