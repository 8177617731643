import { EventsPage } from "..";
import { AlertSchema } from "../api/useGetEventsData/getAlerts";
import { RuleSchema } from "../api/useGetEventsData/getRules";
import { RuleDefinition } from "../pages/RuleDefinition";
import { EventsSection } from "../pages/EventsSection";
import { Open } from "../pages/Open";
import { InProcess } from "../pages/InProcess";
import { Completed } from "../pages/Completed";

interface EventsRouterProps {
  page: EventsPage;
  alerts: AlertSchema[];
  rules: RuleSchema[];
  machines: string[];
}

export const EventsRouter = ({
  page,
  alerts,
  rules,
  machines,
}: EventsRouterProps) => {
  return (
    <>
      {page === "Rule Definition" && (
        <RuleDefinition rules={rules} machines={machines} />
      )}
      {page === "Events" && <EventsSection alerts={alerts} />}
      {page === "Open" && (
        <Open alerts={alerts.filter((alert) => alert.stateOfTicket === 0)} />
      )}
      {page === "In Process" && (
        <InProcess
          alerts={alerts.filter((alert) => alert.stateOfTicket === 1)}
        />
      )}
      {page === "Completed" && (
        <Completed
          alerts={alerts.filter((alert) => alert.stateOfTicket === 2)}
        />
      )}
    </>
  );
};
