import { Card } from "@/components/Layout/Card";
import { useState } from "react";
import { ListSelectDropdown } from "@/components/ListSelectDropdown";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Stack,
  Switch,
} from "@mui/material";
import {
  UserVisibility,
  useGetColleaguesMachinesAccess,
} from "../api/useGetColleagueMachinesAccess";
import { useSaveUserConfiguration } from "../api/useSaveUserConfiguration";
import { useTranslate } from "@/i18n/config";

export const AssignMachines = () => {
  const { data: users, isLoading, isError } = useGetColleaguesMachinesAccess();

  const translate = useTranslate();
  const [selectedUser, setSelectedUser] = useState<string>();

  const selectedUserConfiguration: UserVisibility | undefined = selectedUser
    ? users?.find((user) => user.user === selectedUser)
    : undefined;

  return (
    <Card>
      <ListSelectDropdown
        renderItem={(user) => {
          return (
            <MenuItem key={user.user_id} value={user.user}>
              {user.user}
            </MenuItem>
          );
        }}
        textlabel={translate("select_user")}
        isLoading={isLoading}
        disabled={isLoading || isError}
        data={users}
        onChange={(user) => {
          setSelectedUser(user);
        }}
      />
      <Box marginTop={4}>
        {selectedUserConfiguration && (
          <UserConfig
            key={selectedUserConfiguration.user_id}
            userVisibility={selectedUserConfiguration}
          />
        )}
      </Box>
    </Card>
  );
};

const UserConfig = ({ userVisibility }: { userVisibility: UserVisibility }) => {
  const [config, setConfig] = useState(userVisibility.machines);
  const translate = useTranslate();
  const [formModified, setFormModified] = useState(false);

  const { mutate: saveConfig, isLoading, isError } = useSaveUserConfiguration();
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        const addedMachines = config
          .filter((machine) => machine.has_access)
          .map((machine) => machine.machine_id);
        const removedMachines = config
          .filter((machine) => !machine.has_access)
          .map((machine) => machine.machine_id);

        saveConfig(
          {
            user_id: userVisibility.user_id,
            add: addedMachines,
            remove: removedMachines,
          },
          {
            onSuccess: () => {
              setFormModified(false);
            },
          },
        );
      }}
    >
      <Stack gap={2}>
        <FormControl component="fieldset" variant="standard">
          <FormLabel component="legend">Assign machines</FormLabel>
          <FormGroup>
            {config.map((machine) => {
              return (
                <FormControlLabel
                  key={machine.machine_id}
                  control={
                    <Switch
                      onChange={(e) => {
                        setConfig((prev) => {
                          return prev.map((machine) => {
                            if (machine.machine_name === e.target.name) {
                              return {
                                ...machine,
                                has_access: e.target.checked,
                              };
                            }
                            return machine;
                          });
                        });
                        setFormModified(true);
                      }}
                      checked={machine.has_access}
                      name={machine.machine_name}
                    />
                  }
                  label={machine.machine_name}
                />
              );
            })}
          </FormGroup>
        </FormControl>
        <Stack direction="row" justifyContent="end" gap={2}>
          <Button
            variant="outlined"
            color="warning"
            onClick={() => {
              setConfig(userVisibility.machines);
              setFormModified(false);
            }}
          >
            {translate("actions.reset")}
          </Button>
          <Button
            disabled={!formModified || isLoading || isError}
            sx={{ alignSelf: "end" }}
            variant="contained"
            color="success"
            type="submit"
            endIcon={isLoading ? <CircularProgress size={14} /> : null}
          >
            {translate("actions.save")}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
