import { useFirebaseContext } from "@/context/firebase-context";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { FetchClient } from "@/services/ApiClient";
import { z } from "zod";
import { ConditionConfig } from "@/features/Events/types";
import { useMachineContext } from "@/context/machine-context";

type Payload = {
  account: string;
  platformLvl: number;
  machineName: string;
};

const ticketStatus = z.union([z.literal(0), z.literal(1), z.literal(2)]);

const AlertSchema = z.object({
  account: z.string(),
  data: z.array(ConditionConfig),
  id: z.number(),
  machine: z.string(),
  platformLvl: z.number(),
  priority: z.number(),
  ruleDescription: z.string(),
  ruleName: z.string(),
  stateOfTicket: ticketStatus,
  timestamp: z.number(),
  machineTimezone: z.string(),
});

export type AlertSchema = z.infer<typeof AlertSchema>;

export const getMachineAlertsResult = z.object({
  response: z.literal(true),
  data: z.array(AlertSchema),
});

export type GetMachineAlertsResult = z.infer<typeof getMachineAlertsResult>;

type Response = ThingworxError | GetMachineAlertsResult;

export const useGetMachineEvents = () => {
  const { machine } = useMachineContext();
  const { appKey, platformLvl, user } = useFirebaseContext();
  const account = user?.email as string;

  return useQuery<AlertSchema[]>({
    queryKey: ["machine-events", machine],
    queryFn: async () => {
      const response = await FetchClient<Payload, Response>({
        appKey,
        payload: { machineName: machine!.machine, platformLvl, account },
        url: "dig.c.event_thing/Services/getMachineAlerts",
      });
      if (!response.response) throw new Error(response.errorString);

      return getMachineAlertsResult.parse(response).data;
    },
  });
};
