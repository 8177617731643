import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

export const OeeStream = z.object({
  Timestamp: z.number(),
  Oee: z.number(),
  Performance: z.number(),
  Quality: z.number(),
  Availability: z.number(),
});
export type OeeStream = z.infer<typeof OeeStream>;

const OeeStreamResult = z.object({
  result: z.array(OeeStream),
  response: z.literal(true),
});
type OeeStreamResult = z.infer<typeof OeeStreamResult>;

type OeeStreamResponse = ThingworxError | OeeStreamResult;

export const useGetOeeStream = ({ isSatActive }: { isSatActive: boolean }) => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  return useQuery<OeeStreamResult>({
    queryKey: ["oee-stream", machine!.machine, isSatActive],
    queryFn: async () => {
      const response = await FetchClient<
        { machineName: string },
        OeeStreamResponse
      >({
        appKey,
        payload: {
          machineName: machine!.machine,
        },
        url: "dig.c.acceptanceTest_thing/Services/getOeeStream",
      });
      if (!response.response) throw new Error(response.errorString);
      return OeeStreamResult.parse(response);
    },
    enabled: isSatActive,
    refetchInterval: isSatActive ? 10 * 60 * 1000 : false,
  });
};
