import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  DateRange,
  DateRangePicker,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { useGetBatchList } from "../../api/useGetBatchList";
import { useLyoContext } from "../../context/lyo-context";
import { Link } from "react-router-dom";
import { HelpDialogButton } from "../HelpDialog";
import { DateTime } from "luxon";
import { useTranslate } from "@/i18n/config";

export const BatchNavigation = () => {
  const translate = useTranslate();
  const [state, dispatch] = useLyoContext();

  const { data: response } = useGetBatchList({
    from: state.from,
    to: state.to,
  });

  const batch_list = response?.batches ?? [];

  return (
    <MachineViewNavBar>
      <DateRangePicker
        reduceAnimations
        label={translate("pick_dates")}
        value={[DateTime.fromJSDate(state.from), DateTime.fromJSDate(state.to)]}
        disableFuture
        slotProps={{
          textField: {
            size: "small",
            sx: {
              width: "fit-content",
            },
          },
        }}
        onChange={(dates: DateRange<DateTime>) => {
          const [fromDate, toDate] = dates;
          if (!fromDate || !toDate) return;

          dispatch({
            type: "pick date",
            from: fromDate.toJSDate(),
            to: toDate.toJSDate(),
          });
        }}
        slots={{
          field: SingleInputDateRangeField,
        }}
      />
      <FormControl>
        <InputLabel size="small" id="batch-selection">
          {translate("select_batch")}
        </InputLabel>
        <Select
          sx={{ minWidth: "10rem" }}
          labelId="batch-selection"
          label={translate("select_batch")}
          size="small"
          value={state.selectedBatch?.batchId.toString()}
          autoWidth
          onChange={(e) => {
            const foundBatch = batch_list?.find((b) => {
              return b.batchId === parseInt(e.target.value, 10);
            });

            if (!foundBatch) return;

            dispatch({
              type: "pick batch",
              selectedBatch: foundBatch,
            });
          }}
        >
          {batch_list.length > 0 ? (
            batch_list.map((batchItem) => (
              <MenuItem
                sx={{ width: "100%" }}
                value={batchItem.batchId}
                key={batchItem.batchId}
              >
                {batchItem.batchName} - {batchItem.recipe}
              </MenuItem>
            ))
          ) : (
            <MenuItem>{translate("user_feedback.no_batches")}</MenuItem>
          )}
        </Select>
      </FormControl>

      <Link
        style={{
          textDecoration: "none",
        }}
        to="/analytics/lyophilizer/summary/production-overview"
      >
        <Button
          sx={{
            whiteSpace: "nowrap",
          }}
          size="small"
          variant="outlined"
        >
          {translate("diagnostics.lyo.production_overview")}
        </Button>
      </Link>
      <HelpDialogButton />
    </MachineViewNavBar>
  );
};
