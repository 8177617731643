import { Phase, Subphase } from "../types";

export const readablePhase = (phase: Phase | Subphase): string => {
  switch (phase) {
    case "leak_test":
      return "Leak Test";
    case "vhp":
      return "VHP";
    case "pre_production":
      return "Pre-Production";
    case "production":
      return "Production";
    case "conditioning":
      return "Conditioning";
    case "decontamination":
      return "Decontamination";
    case "deflating":
      return "Deflating";
    case "fin_aeration":
      return "Final Aeration";
    case "gen_aeration":
      return "Generator Aeration";
    case "int_aeration":
      return "Intermediate Aeration";
    case "prel_aeration":
      return "Preliminary Aeration";
    case "inflating":
      return "Inflating";
    case "lt_setup":
      return "Leak Test Setup";
    case "lt_end":
      return "Leak Test End";
    case "maintenance":
      return "Maintenance";
    case "manual_setup":
      return "Manual Setup";
    case "preconditioning":
      return "Pre-Conditioning";
    case "stabilization":
      return "Stabilization";
    case "test":
      return "Test";
    case "vhp_setup":
      return "VHP Setup";
    case "vhp_abort":
      return "VHP Abort";
  }
};
