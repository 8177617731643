import { Stack, TextField, useMediaQuery, useTheme } from "@mui/material";
import { Card } from "@/components/Layout/Card";
import { TWeek } from "../../api/useTurnParametric";
import { DateRange } from "@mui/x-date-pickers-pro";
import { DateTime } from "luxon";
import { useTranslate } from "@/i18n/config";

export const Shift = ({ shiftProp, ShiftIndex }: { shiftProp: TWeek; ShiftIndex: number }) => {
  const value: DateRange<DateTime> | null = [
    DateTime.local().set({
      hour: shiftProp.HourStart,
      minute: shiftProp.MinuteStart,
    }),
    DateTime.local().set({
      hour: shiftProp.HourEnd,
      minute: shiftProp.MinuteEnd,
    }),
  ];

  const CardStyle = {
    backgroundColor: "rgba(76, 141, 255, 0.5);",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    color: "white",
    padding: ".6rem",
  };

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("md"));
  const midView = useMediaQuery(theme.breakpoints.down("lg"));
  const translate = useTranslate();

  return (
    <Card
      sx={CardStyle}
      style={{
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        width: "fit-content",
      }}
    >
      <Stack direction="column" spacing={1}>
        <TextField
          style={{
            padding: "0",
            minWidth: "40px",
            maxWidth: mobileView ? "200px" : midView ? "110px" : "250px",
          }}
          size="small"
          label={translate("machine_settings.start")}
          value={`${value?.[0]?.hour} : ${
            value?.[0]?.minute.toString().length === 1 ? "0" + value[0].minute : value?.[0]?.minute
          }`}
          variant="standard"
        />
        <TextField
          style={{
            padding: "0",
            minWidth: "40px",
            maxWidth: mobileView ? "200px" : midView ? "110px" : "250px",
          }}
          size="small"
          label={translate("machine_settings.end")}
          value={`${value?.[1]?.hour} : ${
            value?.[1]?.minute.toString().length === 1 ? "0" + value[1].minute : value?.[1]?.minute
          }`}
          variant="standard"
        />
      </Stack>
      {mobileView && (
        <label style={{ marginRight: "2px", fontSize: "small" }}> {ShiftIndex + 1} </label>
      )}
    </Card>
  );
};
