import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { Button, Tabs, Tab } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import { StateInfo } from "../api/useGetConfigurableOeeData";
import { useSetConfiguration } from "../api/useSetCustomEfficiency";
import {
  useOeeConfigurationState,
  useOeeConfigurationDispatch,
} from "../context/ConfigurationContext";
import { useEditStore } from "../store/edit-management-store";
import { Views } from "../types";
import { StatesConfigurationDetail } from "./StatesConfigurationDetail";

export const ConfigurationStatesContainer = ({
  showNotification,
}: {
  showNotification: () => void;
}) => {
  const [view, setView] = useState<Views>("all");
  const { isEditable, toggle } = useEditStore((state) => state);
  const { enableCancel, enableReset, enableSave, displayingConfiguration } =
    useOeeConfigurationState();
  const dispatch = useOeeConfigurationDispatch();

  const { mutate: updateConfiguration } = useSetConfiguration();
  const viewHandler = (_: React.SyntheticEvent, view: Views) => setView(view);

  const translate = useTranslate();

  return (
    <>
      <Card>
        <Grid2 container justifyContent="space-between" alignItems={"center"}>
          <Grid2 container padding={"0 3.5rem"}>
            <Button
              variant="outlined"
              disabled={enableSave ? false : true}
              onClick={() => {
                const mappedList = displayingConfiguration!.map(
                  (_state: StateInfo) => {
                    return {
                      id: _state.id,
                      isClientRelevance: _state.isClientRelevance,
                    };
                  },
                );
                updateConfiguration(
                  { states: mappedList },
                  {
                    onSuccess() {
                      showNotification();
                      toggle();
                    },
                  },
                );
              }}
            >
              {translate("actions.save")}
            </Button>
            <Button
              sx={{ marginLeft: ".5rem" }}
              variant="outlined"
              disabled={enableCancel ? false : true}
              onClick={() => {
                dispatch({ type: "cancel current edits" });
              }}
            >
              {translate("actions.cancel")}
            </Button>
            <Button
              sx={{ marginLeft: ".5rem" }}
              variant="outlined"
              disabled={enableReset ? false : true}
              onClick={() => {
                dispatch({ type: "reset to default" });
              }}
            >
              {translate("actions.reset")}
            </Button>
            <Button
              sx={{
                marginLeft: "3rem",
                backgroundColor: isEditable ? "red" : "",
              }}
              variant="outlined"
              onClick={() => {
                toggle();
              }}
            >
              {translate("configurable_oee.cancel_edit")}
            </Button>
          </Grid2>
          <Tabs
            value={view}
            onChange={viewHandler}
            textColor="primary"
            indicatorColor="primary"
            aria-label="secondary tabs example"
          >
            <Tab value="all" label={translate("configurable_oee.all")} />
            <Tab
              value="configured"
              label={translate("configurable_oee.configured")}
            />
            <Tab
              value="notConfigured"
              label={translate("configurable_oee.not_configured")}
            />
          </Tabs>
        </Grid2>
        <StatesConfigurationDetail view={view} />
      </Card>
    </>
  );
};
