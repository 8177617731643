import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { QueryBuilder } from "@/utils/query-builder";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type JamsDetailResponse = ThingworxError | JamsResult;

const JamOverview = z.object({
  Duration: z.number(),
  Count: z.number(),
  ZoneDescription: z.string(),
});

export type JamOverview = z.infer<typeof JamOverview>;

const JamsResult = z.object({
  response: z.literal(true),
  Result: z.array(JamOverview),
});

type JamsResult = z.infer<typeof JamsResult>;

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
  dateStart?: DateTime;
  dateEnd?: DateTime;
};
/**
 * Questa api restituisce i dati per la tabella delle cause di stop con le sommatorie per ogni zona macchina
 */
export const useGetJamsOverview = () => {
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();
  const { timeSelection, dates } = useTimeSelection();

  const query = QueryBuilder.buildWithCondition({
    baseQuery: ["jams-overview", machine?.machine, timeSelection],
    condition: timeSelection === "Custom",
    query: [dates?.dateStart, dates?.dateEnd],
  });

  const payload = {
    machineName: machine!.machine,
    timeSelection,
  };

  return useQuery<JamOverview[]>({
    queryKey: query,
    queryFn: async () => {
      const response = await FetchClient<Payload, JamsDetailResponse>({
        appKey,
        url: "dig.c.jam_thing/Services/getData",
        payload:
          timeSelection === "Custom"
            ? {
                ...payload,
                dateStart: dates?.dateStart,
                dateEnd: dates?.dateEnd,
              }
            : payload,
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return JamsResult.parse(response).Result;
    },
  });
};
