import { useContext } from "react";
import { OeeLineContext } from "./oee-line-context";

export const useOeeLineContextState = () => {
  const context = useContext(OeeLineContext);
  if (!context) {
    throw new Error(
      "useOeeLineContextState must be used within a OeeLineContextProvider",
    );
  }
  return context;
};
