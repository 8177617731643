import { Stack, Typography, useTheme } from "@mui/material";
import { UserRole, useFirebaseContext } from "../src/context/firebase-context";
import { Loader } from "./components/Layout/Loader";
import { useCheckMobileScreen } from "./hooks/useCheckMobileScreen";
import { Login } from "./pages/Login/Login";
import { SentinelRoutes } from "./routes/SentinelRoutes";
import "./styles/global.style.css";
import { useCheckIfMaintenance } from "./hooks/useCheckIfMaintenance";
import { SentinelLogo } from "./components/Icons/SentinelLogo";
import { useIsAllowed } from "./hooks/useIsAllowed";
import { useTranslate } from "./i18n/config";

export const App = () => {
  const { user, showLoader, appKey } = useFirebaseContext();
  const { isMaintenance } = useCheckIfMaintenance();
  const isMobile = useCheckMobileScreen();
  const userCanBypass = useIsAllowed([UserRole.SUPER_USER_SENTINEL]);
  const translate = useTranslate();

  if (isMobile) {
    return (
      <Stack height="100vh" width="100vw" p={12} justifyContent="center" alignItems="center">
        <Typography variant="h5" align="center">
          {translate("sentinel.mobile_device")}
        </Typography>
      </Stack>
    );
  }

  return (
    <>
      {showLoader ? (
        <Loader isLoading={showLoader} />
      ) : (
        (() => {
          return (
            <>
              {user && appKey === "" && <Loader isLoading={showLoader} />}
              {user &&
                appKey !== "" &&
                (isMaintenance && !userCanBypass ? <MaintenancePage /> : <SentinelRoutes />)}
              {user === null && <Login />}
            </>
          );
        })()
      )}
    </>
  );
};

const MaintenancePage = () => {
  const { palette } = useTheme();
  const translate = useTranslate();

  return (
    <Stack height="100vh" width="100vw" p={12} justifyContent="center" alignItems="center" gap={2}>
      <SentinelLogo dimension={120} color={palette.primary.main} />
      <Typography variant="h5" align="center">
        {translate("sentinel.maintenance")}
      </Typography>
    </Stack>
  );
};
