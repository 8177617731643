import { Evaluation } from "../types";

type EvaluationMap = {
  [key: string]: Evaluation;
};

export const mapColor = (color: string): Evaluation => {
  const evaluations: EvaluationMap = {
    "#76A12B": "good",
    "#e8c52a": "warning",
    "#D62422": "critical",
    "#999999": "lack_of_data",
    "#CCCCCC": "no_data",
    "#FFFFFF": "undefined",
  };
  return evaluations[color];
};
