import { Card } from "@/components/Layout/Card";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { OverviewNavBar } from "@/pages/Overview/layout/OverviewNavBar/OverviewNavBar";
import { Stack, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { AdvancedAnalyticsList } from "../Diagnostic/pages/LYO/components/AdvancedAnalyticsList";
import {
  useGetEdgePcData,
  DataChart,
  UserLoginSchema,
  BanInfoTransformed,
} from "./api/useGetEdgePcData";
import { Configuration } from "./components/Configuration";
import { FooterSide } from "./components/FooterSide";
import { Health, ChartProps } from "./components/Health";
import { LeftSideSection } from "./components/LeftSideSection";
import { Logs } from "./components/Logs";
import { Network } from "./components/Network";
import { PCListDropdown } from "./components/PCListDropdown";
import { Services } from "./components/Services";
import { SoftwareVersion } from "./components/SoftwareVersion";
import { TimeSelection } from "./components/TimeSelection";

import {
  A4GateContextProvider,
  useA4GateContext,
  useA4GateContextDispatch,
} from "./context/a4gate-context";
import { useTranslate } from "@/i18n/config";
import { Plugins } from "./components/Plugins";
import { UserLogin } from "./components/UserLogin";
import { timezoneStore } from "@/store/useTimezoneStore";
import { useEffect } from "react";

const navMenu = [
  {
    name: "Health",
    id: 0,
  },
  {
    name: "Services",
    id: 1,
  },
  {
    name: "Network PC A",
    id: 2,
  },
  {
    name: "PC Configuration",
    id: 3,
  },
  {
    name: "Logs",
    id: 4,
  },
  {
    name: "Software versions",
    id: 5,
  },
  {
    name: "Login",
    id: 6,
  },
  {
    name: "Plugins",
    id: 7,
  },
] as const;


export const A4GateMonitoring = () => {
  const { setTimezone } = timezoneStore();
  useEffect(() => {
    setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, [setTimezone]);
    return (
      <>
        <A4GateContextProvider>
          <OverviewNavBar hasTimezone={false}>
            <Stack gap={2} direction="row">
              <TimeSelection />
              <PCListDropdown />
            </Stack>
          </OverviewNavBar>
          <SelectPC />
        </A4GateContextProvider>
      </>
    );
};

const SelectPC = () => {
  const translate = useTranslate();
  const { selectedPC } = useA4GateContext();
  return (
    <>
      {selectedPC === "" && (
        <>
          <Card sx={{ border: "1px solid rgba(81, 81, 81, 1)" }}>
            <Typography
              border={"1px solid rgba(81, 81, 81, 1)"}
              padding={2}
              textAlign="center"
            >
              {translate("a4gate_monitoring.select_edge_pc")}
            </Typography>
          </Card>
        </>
      )}
      {selectedPC !== "" && <ContentWrapper />}
    </>
  );
};
const ContentWrapper = () => {
  const { selectedPC } = useA4GateContext();
  const { data, isLoading } = useGetEdgePcData({ edgePcName: selectedPC });
  return (
    <>
      {isLoading ? <SkeletonCard /> : null}
      {data ? (
        <Stack gap={2}>
          <Grid2 container spacing={2}>
            <Grid2 md={2.7} xs={12}>
              <LeftSideSection
                dataLive={data.dataLive}
                poweroffPcB={data.dataChart.pcB.poweroff}
              />
            </Grid2>
            <NavSection
              data={data.dataChart}
              opcuaServer={data.dataLive.opcuaServer}
              fastData={data.dataLive.fastData}
              httpServer={data.dataLive.httpServer}
              userLogin={data.dataLive.userLogin}
              banInfo={data.dataLive.banInfo}
            />
          </Grid2>
          <FooterSide connectionStatus={data.dataChart.connectionStatus} />
        </Stack>
      ) : null}
    </>
  );
};

const NavSection = ({
  data,
  fastData,
  httpServer,
  opcuaServer,
  userLogin,
  banInfo,
}: {
  data: DataChart;
  opcuaServer: boolean;
  fastData: boolean;
  httpServer: boolean;
  userLogin: UserLoginSchema[];
  banInfo: BanInfoTransformed[];
}) => {
  const { resource } = useA4GateContext();
  const translate = useTranslate();
  const { setResource } = useA4GateContextDispatch();
  const chartsArray: ChartProps[] = [
    {
      chartName: "PC A",
      dataArray: [
        createChartData({
          name: "CPU",
          type: "line",
          data: data.pcA.cpu,
          color: "#51A5DE",
        }),
        createChartData({
          name: "RAM",
          type: "line",
          data: data.pcA.ram,
          color: "#56BC72",
        }),
        createChartData({
          name: "Disk",
          type: "line",
          data: data.pcA.disk,
          color: "#007E77",
        }),
      ],
    },
    {
      chartName: "PC B",
      dataArray: [
        createChartData({
          name: "CPU",
          type: "line",
          data: data.pcB.cpu,
          color: "#51A5DE",
        }),
        createChartData({
          name: "RAM",
          type: "line",
          data: data.pcB.ram,
          color: "#56BC72",
        }),
        createChartData({
          name: "Disk",
          type: "line",
          data: data.pcB.disk,
          color: "#007E77",
        }),
      ],
    },
    {
      chartName: translate("a4gate_monitoring.pc_b_load_average"),
      dataArray: [
        createChartData({
          name: "1min",
          type: "line",
          data: data.pcB.loadAvg.oneMinute,
          color: "#00C1D2",
        }),
        createChartData({
          name: "5min",
          type: "line",
          data: data.pcB.loadAvg.fiveMinutes,
          color: "#F7B500",
        }),
        createChartData({
          name: "15min",
          type: "line",
          data: data.pcB.loadAvg.fifteenMinutes,
          color: "#FF55BA",
        }),
      ],
    },
  ];

  /**@description servicesData - dati per il componenete Services */
  const servicesData = { pcA: data.pcA.isWorking, pcB: data.pcB.isWorking };

  /**@description PC Configuration - dati per il componenete PC Configuration */
  const pcConfiguration = { jsonPcA: data.pcA.conf, jsonPcB: data.pcB.conf };
  /**@description Logs - dati per il componenete Logs */
  const dataLogs = {
    bidirectionalLog: data.bidirectional,
    uptimeLog: data.uptimeAp,
    fail2BanLog: data.fail2Ban,
    powerOffPcB: data.pcB.poweroff,
  };
  /**@description software version - dati per il componenete software version */
  const softwareVersionsData = {
    pcAVersion: data.pcA.version,
    pcBVersion: data.pcB.version,
  };

  return (
    <Grid2 md={9.3}>
      <Stack gap={2}>
        <AdvancedAnalyticsList
          items={navMenu.map((item) => ({
            id_value: item.id,
            display_name: item.name,
          }))}
          activeId={resource.id_value}
          renderTextItem={(item) => `${item.display_name}`}
          onItemClicked={(item) => setResource(item)}
        />
        <Grid2 padding={0} height={520} overflow={"scroll"}>
          {resource.id_value === 0 && <Health chartsData={chartsArray} />}
          {resource.id_value === 1 && <Services data={servicesData} />}
          {resource.id_value === 2 && <Network networkPcA={data.pcA.network} />}
          {resource.id_value === 3 && (
            <Configuration configurationData={pcConfiguration} />
          )}
          {resource.id_value === 4 && <Logs dataLogs={dataLogs} />}
          {resource.id_value === 5 && (
            <SoftwareVersion data={softwareVersionsData} />
          )}
          {resource.id_value === 6 && (
            <UserLogin userLoginData={userLogin} banInfo={banInfo} />
          )}
          {resource.id_value === 7 && (
            <Plugins
              opcuaServer={opcuaServer}
              fastData={fastData}
              httpServer={httpServer}
            />
          )}
        </Grid2>
      </Stack>
    </Grid2>
  );
};

function createChartData({
  name,
  type,
  data,
  color,
}: {
  name: string;
  type: "line";
  data: number[][];
  color: string;
}) {
  return {
    name,
    type,
    data,
    color,
  };
}
