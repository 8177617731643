import { createContext, useContext } from "react";
import { useImmerReducer } from "use-immer";
import { TWeek, TwTurnConfiguration } from "../api/useTurnParametric";

type Action =
  | {
      type: "modify shift";
      DayIndex: number;
      ShiftIndex: number;
      WeekIndex: number;
      shift: TWeek;
    }
  | { type: "add shift"; DayIndex: number; WeekIndex: number }
  | {
      type: "delete shift";
      DayIndex: number;
      ShiftIndex: number;
      WeekIndex: number;
    }
  | { type: "reset" }
  | { type: "set_start_date"; startWeek: number };

const reducer = (draft: ConfigurationContextState, action: Action) => {
  switch (action.type) {
    case "modify shift": {
      if (action.WeekIndex === 0) {
        const clonedShifts = [
          ...draft.editableData.currentWeek[action.DayIndex],
        ];
        clonedShifts[action.ShiftIndex] = action.shift;
        draft.editableData.currentWeek[action.DayIndex] = clonedShifts;
      } else {
        const clonedShifts = [...draft.editableData.nextWeek[action.DayIndex]];
        clonedShifts[action.ShiftIndex] = action.shift;
        draft.editableData.nextWeek[action.DayIndex] = clonedShifts;
      }
      break;
    }
    case "add shift": {
      const tempShift = {
        MinuteStart: 0,
        MinuteEnd: 0,
        HourStart: 0,
        HourEnd: 0,
      };

      if (action.WeekIndex === 0) {
        draft.editableData.currentWeek[action.DayIndex] = [
          ...(draft.editableData.currentWeek[action.DayIndex] || []),
          tempShift,
        ];
      } else {
        draft.editableData.nextWeek[action.DayIndex] = [
          ...(draft.editableData.nextWeek[action.DayIndex] || []),
          tempShift,
        ];
      }
      break;
    }
    case "delete shift": {
      if (action.WeekIndex === 0) {
        const clonedShifts = [
          ...draft.editableData.currentWeek[action.DayIndex],
        ];
        clonedShifts.splice(action.ShiftIndex, 1);
        draft.editableData.currentWeek[action.DayIndex] = clonedShifts;
      } else {
        const clonedShifts = [...draft.editableData.nextWeek[action.DayIndex]];
        clonedShifts.splice(action.ShiftIndex, 1);
        draft.editableData.nextWeek[action.DayIndex] = clonedShifts;
      }
      break;
    }
    case "reset": {
      draft.editableData = draft.originalData;
      break;
    }
    case "set_start_date": {
      draft.editableData.StartWeek = action.startWeek;
      break;
    }
    default:
      break;
  }
};

type ConfigurationContextState = {
  originalData: TwTurnConfiguration;
  editableData: TwTurnConfiguration;
};

const ConfigurationContextState =
  createContext<ConfigurationContextState | null>(null);

const ConfigurationContextDispatch =
  createContext<React.Dispatch<Action> | null>(null);

export const MachineSettingsContextProvider = ({
  children,
  editData,
}: React.PropsWithChildren<{ editData: TwTurnConfiguration }>) => {
  const [state, dispatch] = useImmerReducer<ConfigurationContextState, Action>(
    reducer,
    { editableData: editData, originalData: editData },
  );
  return (
    <ConfigurationContextState.Provider value={state}>
      <ConfigurationContextDispatch.Provider value={dispatch}>
        {children}
      </ConfigurationContextDispatch.Provider>
    </ConfigurationContextState.Provider>
  );
};

export const useMachineContextState = () => {
  const context = useContext(ConfigurationContextState);
  if (!context) {
    throw new Error(
      "useMachineContextState can only be used within MachineContextProvider",
    );
  }
  return context;
};

export const useMachineContextDispatch = () => {
  const context = useContext(ConfigurationContextDispatch);
  if (!context) {
    throw new Error(
      "useMachineContextDispatch can only be used within MachineContextProvider",
    );
  }
  return context;
};
