import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";

import {
  EfficiencyNavigtorResult,
  EfficiencyNavigtorResponse,
} from "../useGetEfficiencyNavigatorData";

export const useGetDataHistoric = ({ id }: { id: string }) => {
  const { machine } = useMachineContext();
  const { timeSelection } = useTimeSelection();
  const { appKey } = useFirebaseContext();

  return useQuery<EfficiencyNavigtorResult>({
    queryKey: ["efficiency-navigator", machine!.machine, timeSelection, id],
    queryFn: async () => {
      const response = await FetchClient<
        { machineName: string; id: string },
        EfficiencyNavigtorResponse
      >({
        appKey,
        payload: {
          machineName: machine!.machine,
          id,
        },
        url: "dig.c.historicEfficiencyNavigator_thing/Services/getData",
      });
      if (!response.response) {
        throw new Error(response.errorString);
      }
      const validatedResponse = EfficiencyNavigtorResult.parse(response);
      return validatedResponse;
    },
    refetchInterval: 1000 * 30,
  });
};
