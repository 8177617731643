import { z } from "zod";

export const evaluationColors = z.object({
  good: z.object({
    selected: z.string(),
    notSelected: z.string(),
  }),
  warning: z.object({
    selected: z.string(),
    notSelected: z.string(),
  }),
  critical: z.object({
    selected: z.string(),
    notSelected: z.string(),
  }),
  lack_of_data: z.object({
    selected: z.string(),
    notSelected: z.string(),
  }),
  no_data: z.object({
    selected: z.string(),
    notSelected: z.string(),
  }),
  undefined: z.object({
    selected: z.string(),
    notSelected: z.string(),
  }),
});

export type EvaluationColors = z.infer<typeof evaluationColors>;

export const runPoint = z.object({
  x: z.number(),
  x2: z.number(),
  y: z.number(),
  name: z.string(),
  color: z.string(),
  custom: z.object({
    isActive: z.boolean(),
  }),
});

export type RunPoint = z.infer<typeof runPoint>;

export const evaluation = z.enum([
  "good",
  "warning",
  "critical",
  "lack_of_data",
  "no_data",
  "undefined",
]);

export type Evaluation = z.infer<typeof evaluation>;

export const evaluationScatter = z.enum([
  "Normal",
  "Upper Outlier",
  "Lower Outlier",
  "Upper and Lower Outlier",
]);

export type EvaluationScatter = z.infer<typeof evaluationScatter>;

export const run = z.object({
  id: z.number(),
  name: z.string(),
  start: z.number(),
  end: z.number(),
  evaluation: evaluation,
});

export type Run = z.infer<typeof run>;

export const lap = z.object({
  id: z.number(),
  name: z.string(),
  start: z.number(),
  end: z.number(),
  evaluation: evaluation,
});

export type Lap = z.infer<typeof lap>;

export const lapScatter = z.object({
  id: z.number(),
  name: z.string(),
  timestamp: z.number(),
  evaluation: evaluation,
});

export type LapScatter = z.infer<typeof lapScatter>;

export const segment = z.object({
  id: z.number(),
  name: z.string(),
});

export type Segment = z.infer<typeof segment>;

export const shuttle = z.object({
  id: z.number(),
  name: z.string(),
});

export type Shuttle = z.infer<typeof shuttle>;

export const itemHealthStatus = z.object({
  name: z.string(),
  healthStatus: evaluation,
});

export type ItemHealthStatus = z.infer<typeof itemHealthStatus>;

export const descriptiveAnalytic = z.object({
  name: z.string(),
  clientName: z.string(),
});

export type DescriptiveAnalytic = z.infer<typeof descriptiveAnalytic>;

export const conditionAnalytic = z.object({
  id: z.number(),
  clientName: z.string(),
});

export type ConditionAnalytic = z.infer<typeof conditionAnalytic>;

export const deviancesAnalytic = z.object({
  id: z.number(),
  clientName: z.string(),
});

export type DeviancesAnalytic = z.infer<typeof deviancesAnalytic>;

export const decompositionAnalytic = z.object({
  id: z.number(),
  clientName: z.string(),
});

export type DecompositionAnalytic = z.infer<typeof decompositionAnalytic>;

export const noveltyAnomalyAnalytic = z.object({
  id: z.number(),
  clientName: z.string(),
});

export type NoveltyAnomalyAnalytic = z.infer<typeof noveltyAnomalyAnalytic>;

export const contextInfo = z.record(
  z.union([
    z.string(),
    z.number(),
    z.record(z.union([z.string(), z.number()])),
  ]),
);

export type ContextInfo = z.infer<typeof contextInfo>;

export const heatmapData = z.record(z.record(evaluation));

export type HeatmapData = z.infer<typeof heatmapData>;

export const compareLapsItem = z.object({
  runName: z.string(),
  runId: z.number(),
  shuttleId: z.number(),
  lapId: z.number(),
});

export type CompareLapsItem = z.infer<typeof compareLapsItem>;

const timelapse = z.enum(["hour", "day", "week"]);

export type Timelapse = z.infer<typeof timelapse>;

const timelapseOption = z.object({
  value: timelapse,
  clientName: z.string(),
});

export type TimelapseOption = z.infer<typeof timelapseOption>;

export const thresholds = z.object({
  critical_upper: z.number(),
  warning_upper: z.number(),
});

export type Thresholds = z.infer<typeof thresholds>;
