import { Box, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

export const SettingSectionLayout = ({
  title,
  description,
  render,
}: {
  title: string;
  description: string;
  render: React.ReactNode;
}) => {
  return (
    <Box>
      <Grid2 container spacing={6} wrap="wrap">
        <Grid2 xs={12} md={4}>
          <Typography color="textPrimary" gutterBottom variant="h6">
            {title}
          </Typography>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body1"
            sx={{ maxWidth: 500 }}
          >
            {description}
          </Typography>
        </Grid2>
        <Grid2 xs={12} md={8}>
          {render}
        </Grid2>
      </Grid2>
    </Box>
  );
};
