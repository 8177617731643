import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { Shift } from "../../types";

type ProductResponse = ThingworxError | ThingworxProductResult;

// type ShiftList = z.infer<typeof ShiftList>;

/**
 * type definitions used as the shape in which
 * the data that comes from the API needs to be transformed.
 * This problems arises because the API returns the data in a different shape, with keys that have blank spaces.
 *
 * @todo refactor thingworx API to return data in a more usable shape so that we can remove this transformation step
 */

const ProductSelectorData = z.object({
  goodProducts: z.array(z.array(z.number())),
  wasteCounter: z.array(z.array(z.number())),
});

type ProductSelectorData = z.infer<typeof ProductSelectorData>;

const ProductSelectorRatioData = z.object({
  goodProductsRatio: z.array(z.array(z.number())),
  wasteRatio: z.array(z.array(z.number())),
});
type ProductSelectorRatioData = z.infer<typeof ProductSelectorRatioData>;

const ResultProduct = z.object({
  data: z.tuple([ProductSelectorData, ProductSelectorRatioData]),
  chartName: z.string(),
  configuration: z.string(),
  response: z.literal(true),
  shiftList: z.array(Shift),
});

type ResultProduct = z.infer<typeof ResultProduct>;

/**
 * Type definitions for data that comes from the API.
 */

const ThingworxProductSelectorData = z.object({
  "Good Products": z.array(z.array(z.number())),
  "Waste Counter": z.array(z.array(z.number())),
});

type ThingworxProductSelectorData = z.infer<
  typeof ThingworxProductSelectorData
>;

const ThingworxProductSelectorRatioData = z.object({
  "Good Products Ratio": z.array(z.array(z.number())),
  "Waste Ratio": z.array(z.array(z.number())),
});

type ThingworxProductSelectorRatioData = z.infer<
  typeof ThingworxProductSelectorRatioData
>;

const ThingworxProductResult = z.object({
  data: z.tuple([
    ThingworxProductSelectorData,
    ThingworxProductSelectorRatioData,
  ]),
  chartName: z.string(),
  configuration: z.string(),
  response: z.literal(true),
  shiftList: z.array(Shift),
});

type ThingworxProductResult = z.infer<typeof ThingworxProductResult>;

/**  */

type Payload = {
  machineName: string;
  dateStart: DateTime;
  dateEnd: DateTime;
  timeSelection: TimeSelection;
};

export const useGetProductSelector = () => {
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();
  const { timeSelection, dates } = useTimeSelection();

  return useQuery<ResultProduct>({
    queryKey: [
      "machinePerformance-sentinel-ProductSelector",
      timeSelection,
      machine?.machine,
      dates.dateStart,
      dates.dateEnd,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, ProductResponse>({
        appKey,
        payload: {
          machineName: machine?.machine || "",
          dateStart: dates.dateStart,
          dateEnd: dates.dateEnd,
          timeSelection: timeSelection,
        },
        url: "dig.c.machinePerformance_thing/Services/getProductSelector",
      });

      if (!response.response) throw new Error(response.errorString);
      const validatedResponse = ThingworxProductResult.parse(response);

      const [counters, percentages] = validatedResponse.data;

      return {
        ...validatedResponse,
        data: [
          {
            goodProducts: counters["Good Products"],
            wasteCounter: counters["Waste Counter"],
          },
          {
            goodProductsRatio: percentages["Good Products Ratio"],
            wasteRatio: percentages["Waste Ratio"],
          },
        ],
      };
    },
    refetchInterval: timeSelection === "Custom" ? false : 1000 * 20,
  });
};
