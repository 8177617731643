import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { AdvancedAnalyticEvaluationInfo } from "./useGetAdvancedAnalysisList";
import { z } from "zod";
type AnomalyDetectionResponse = ThingworxError | AnomalyDetectionResults;

const AnomalyDetectionData = z.object({
  asset_id: z.number(),
  timestamp: z.number(),
  value: z.number(),
  evaluation: z.enum(["bad", "novelty"]).optional(),
});

type AnomalyDetectionData = z.infer<typeof AnomalyDetectionData>;

const AnomalyDetectionResults = z.object({
  response: z.literal(true),
  datapoints: z.array(AnomalyDetectionData),
  anomalies: z.array(
    z.object({
      value: z.number(),
      color: z.string(),
    }),
  ),
});
type AnomalyDetectionResults = z.infer<typeof AnomalyDetectionResults>;

export const useGetAnomalyDetectionData = ({
  analytic,
}: {
  analytic: AdvancedAnalyticEvaluationInfo;
}) => {
  const { appKey } = useFirebaseContext();

  return useQuery<AnomalyDetectionResults>({
    queryKey: [
      "lyo-anomaly-detection",
      analytic.asset_id,
      analytic.from,
      analytic.to,
    ],
    queryFn: async () => {
      const response = await FetchClient<
        {
          asset_id: number;
          from: number;
          to: number;
        },
        AnomalyDetectionResponse
      >({
        appKey,
        payload: {
          asset_id: analytic.asset_id,
          from: analytic.from,
          to: analytic.to,
        },
        url: "dig.c.lyophilizer_thing/Services/getAnomalyDetectionData",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return AnomalyDetectionResults.parse(response);
    },
    refetchInterval: false,
  });
};
