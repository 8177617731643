import { Dispatch, SetStateAction } from "react";
import { Variable } from "../api/useGetVariableList";
import { Box, Typography } from "@mui/material";
import { ConditionEdit as ConditionEditType } from "../types";
import { ConditionEdit } from "./ConditionEdit";
import { useTranslate } from "@/i18n/config";

interface ConditionsProps {
  variables: Variable[];
  machine: string;
  setSelectedVariables: Dispatch<SetStateAction<Variable[]>>;
  conditions: ConditionEditType[];
  updateCondition: (condition: ConditionEditType) => void;
  deleteCondition: (propertyName: string) => void;
}

export const ConditionsEdit = ({
  variables,
  machine,
  setSelectedVariables,
  conditions,
  updateCondition,
  deleteCondition,
}: ConditionsProps) => {
  const translate = useTranslate();
  return (
    <>
      <Box sx={{ display: "flex", gap: 2 }}>
        {/* <Typography sx={{ flex: 0.5 }}></Typography> */}
        <Typography sx={{ flex: 2 }} fontWeight="bold">
          {translate("machine")}
        </Typography>
        <Typography sx={{ flex: 3 }} fontWeight="bold">
          {translate("variable")}
        </Typography>
        <Typography sx={{ flex: 2 }} fontWeight="bold">
          {translate("math.operator")}
        </Typography>
        <Typography sx={{ flex: 1 }} fontWeight="bold">
          {translate("value")} 1
        </Typography>
        <Typography sx={{ flex: 1 }} fontWeight="bold">
          {translate("value")} 2
        </Typography>
        <Typography sx={{ flex: 1 }} fontWeight="bold">
          {translate("events.cumulative")}
        </Typography>
        <Typography sx={{ flex: 1 }} fontWeight="bold">
          {translate("events.enable_duration")}
        </Typography>
        <Typography sx={{ flex: 2 }} fontWeight="bold">
          {translate("duration")}
        </Typography>
        <Typography sx={{ flex: 1.2 }} fontWeight="bold">
          {translate("uom")}
        </Typography>
        <Typography sx={{ flex: 1 }} fontWeight="bold">
          {translate("threshold")}
        </Typography>
      </Box>
      {variables.map((variable) => (
        <ConditionEdit
          key={variable.propertyName}
          variable={variable}
          setSelectedVariables={setSelectedVariables}
          updateCondition={updateCondition}
          deleteCondition={deleteCondition}
          defaultValues={
            conditions.find(
              (condition) => condition.property === variable.propertyName
            ) || {
              duration: 0,
              isActiveDuration: false,
              isCumulative: false,
              machine,
              operator: "Above",
              property: variable.propertyName,
              propertyNameClient: variable.propertyNameClient,
              propertyType: variable.propertyType,
              rate: 0,
              uom: variable.uom[0],
              value1: 0,
              name: "",
            }
          }
        />
      ))}
    </>
  );
};
