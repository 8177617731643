import { RuleSchema } from "../api/useGetEventsData/getRules";
import { queryRules } from "./queryRules";

export const filterRules = ({
  rules,
  machine,
  query,
  showPersonal,
  account,
}: {
  rules: RuleSchema[];
  machine: string;
  query: string;
  showPersonal: boolean;
  account: string;
}): RuleSchema[] => {
  // controllare prima show personal
  const firstLayerFilter = showPersonal
    ? rules.filter((rule) => rule.account === account)
    : rules;
  // poi machine
  const secondLayerFilter =
    machine === "---"
      ? firstLayerFilter
      : firstLayerFilter.filter((rule) => rule.machine === machine);
  // poi query
  const thirdLayerFilter =
    query === ""
      ? secondLayerFilter
      : queryRules({ rules: secondLayerFilter, query });
  return thirdLayerFilter;
};
