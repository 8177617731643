import { create } from "zustand";

export type TimezoneStore = {
  hasMachineTimezone: boolean;
  timezone: string;
  machineTimezone: string;
  setHasMachineTimezone: () => void;
  setTimezone: (timezone: string) => void;
  setMachineTimezone: (machineTimezone: string) => void;
};

export const timezoneStore = create<TimezoneStore>()((set) => ({
  hasMachineTimezone: localStorage.getItem("isMachineTimezone") === "true",
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  machineTimezone: "",
  setHasMachineTimezone() {
    set((state) => {
      localStorage.setItem(
        "isMachineTimezone",
        (!state.hasMachineTimezone).toString(),
      );
      return { hasMachineTimezone: !state.hasMachineTimezone };
    });
  },
  setTimezone(newTimezone: string) {
    set(() => ({ timezone: newTimezone }));
  },
  setMachineTimezone(newMachineTimezone: string) {
    set((state) => {
      if (state.hasMachineTimezone) {
        state.timezone = newMachineTimezone;
      } else {
        state.machineTimezone =
          Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
      return { machineTimezone: newMachineTimezone };
    });
  },
}));
