import { Card } from "@/components/Layout/Card";
import { SegmentsHeatmap } from "./SegmentsHeatmap";
import { ShuttlesHeatmap } from "./ShuttlesHeatmap";
import { RunsInspection } from "./RunsInspection";

export const HeatmapSection = () => {
  return (
    <Card>
      <RunsInspection />
      <SegmentsHeatmap />
      <ShuttlesHeatmap />
    </Card>
  );
};
