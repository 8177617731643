import { PointOptionsObject } from "highcharts";
import { Phase, PieData } from "../types";
import { CHART_COLORS } from "./chartColors";
import { EVALUATION_COLORS } from "./evaluationColors";
import { Translations } from "@/i18n/config";

export const parsePieData = ({
  data,
  phase,
  translate,
}: {
  data: PieData[];
  phase?: Phase;
  translate: (name: Translations) => string;
}): PointOptionsObject[] => {
  const chartData: PointOptionsObject[] = [];
  let goodCount = 0;
  let badCount = 0;

  data.forEach((batchPhase) => {
    batchPhase.evaluation === "good" ? goodCount++ : badCount++;
  });

  const totalCount = goodCount + badCount;
  const goodPercentage = parseFloat(((100 / totalCount) * goodCount).toFixed(2));
  const badPercentage = parseFloat(((100 / totalCount) * badCount).toFixed(2));

  if (phase) {
    if (phase === "production") {
      chartData.push(
        { name: translate("ISO_not_completed"), y: badPercentage, color: CHART_COLORS[3] },
        { name: translate("ISO_completed"), y: goodPercentage, color: CHART_COLORS[0] },
      );
    } else {
      chartData.push(
        {
          name: translate("ISO_passed"),
          y: goodPercentage,
          color: EVALUATION_COLORS.good.selected,
        },
        {
          name: translate("ISO_failed"),
          y: badPercentage,
          color: EVALUATION_COLORS.critical.selected,
        },
      );
    }
  } else {
    chartData.push(
      {
        name: translate("ISO_passed"),
        y: goodPercentage,
        color: EVALUATION_COLORS.good.selected,
      },
      {
        name: translate("ISO_failed"),
        y: badPercentage,
        color: EVALUATION_COLORS.critical.selected,
      },
    );
  }

  return chartData;
};
