import { AlertSchema } from "../api/useGetEventsData/getAlerts";
import { queryAlerts } from "./queryAlerts";

export const filterAlerts = ({
  alerts,
  machine,
  query,
  showPersonal,
  account,
}: {
  alerts: AlertSchema[];
  machine: string;
  query: string;
  showPersonal: boolean;
  account: string;
}): AlertSchema[] => {
  // controllare prima show personal
  const firstLayerFilter = showPersonal
    ? alerts.filter((alert) => alert.account === account)
    : alerts;
  // poi machine
  const secondLayerFilter =
    machine === "---"
      ? firstLayerFilter
      : firstLayerFilter.filter((rule) => rule.machine === machine);
  // poi query
  const thirdLayerFilter =
    query === ""
      ? secondLayerFilter
      : queryAlerts({ alerts: secondLayerFilter, query });
  return thirdLayerFilter;
};
