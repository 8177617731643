import { useState } from "react";
import { Card } from "@/components/Layout/Card";
import { Stack, Tab, Tabs, useTheme, useMediaQuery } from "@mui/material";
import { ShiftPanel } from "../components/view/ShiftPanel";
import { StartWeekForm } from "../components/useSetStartOfWeek";
import { TwTurnConfiguration } from "../api/useTurnParametric";

import { MachineSettingsContextProvider } from "../context/MachineSettingsContext";
import { ShiftEditPanel } from "../components/edit/ShiftEditPanel";
import { useIsAllowed } from "@/hooks/useIsAllowed";
import { UserRole } from "@/context/firebase-context";
import { useTranslate } from "@/i18n/config";

export const MachineSettingsView = ({
  ShiftConfiguration,
}: {
  ShiftConfiguration: TwTurnConfiguration;
}) => {
  const [weekTab, setWeekTab] = useState<"CurrentWeek" | "NextWeek">(
    "CurrentWeek",
  );
  const [isError, setIsError] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [startWeek, setStartWeek] = useState(ShiftConfiguration.StartWeek);
  const translate = useTranslate();

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("md"));

  const allowEdit = useIsAllowed([
    UserRole.USER_ADMIN,
    UserRole.SUPER_USER,
    UserRole.SUPER_USER_SENTINEL,
  ]);

  return (
    <>
      <Stack spacing={1} alignItems={mobileView ? "center" : "normal"}>
        <Tabs
          value={weekTab}
          onChange={(
            _: React.SyntheticEvent,
            newValue: "CurrentWeek" | "NextWeek",
          ) => {
            setWeekTab(newValue);
          }}
        >
          <Tab
            // style={{ fontWeight: "bold", fontSize: "15px" }}
            label={translate("current_week")}
            value="CurrentWeek"
          ></Tab>
          <Tab
            // style={{ fontWeight: "bold", fontSize: "15px" }}
            label={translate("next_week")}
            value="NextWeek"
          ></Tab>
        </Tabs>
        <Card>
          <MachineSettingsContextProvider editData={ShiftConfiguration}>
            <StartWeekForm
              dayStart={ShiftConfiguration.StartWeek}
              editMode={editMode}
              setEditMode={setEditMode}
              setStartWeek={setStartWeek}
              isError={isError}
            />
            {editMode === true && allowEdit ? (
              <ShiftEditPanel
                startWeek={startWeek}
                weekIndex={weekTab === "CurrentWeek" ? 0 : 1}
                setIsError={setIsError}
              />
            ) : (
              <ShiftPanel
                key={weekTab}
                week={
                  weekTab === "CurrentWeek"
                    ? ShiftConfiguration.currentWeek
                    : ShiftConfiguration.nextWeek
                }
                startWeek={startWeek}
              />
            )}
          </MachineSettingsContextProvider>
        </Card>
      </Stack>
    </>
  );
};
