import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type AvailableDescriptiveDataResponse =
  | ThingworxError
  | AvailableDescriptiveDataResult;

const AvailableDescriptiveDataResult = z.object({
  response: z.literal(true),
  availableAnalytics: z.array(z.string()),
});

export type AvailableDescriptiveDataResult = z.infer<
  typeof AvailableDescriptiveDataResult
>;

interface Payload {
  machineName: string;
}

export const useGetAvailableDescriptiveData = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();

  return useQuery<string[]>({
    queryKey: ["available-descriptive-data", machine?.machine],
    queryFn: async () => {
      const response = await FetchClient<
        Payload,
        AvailableDescriptiveDataResponse
      >({
        appKey,
        payload: {
          machineName: machine?.machine || "",
        },
        url: "dig.c.motors_thing/Services/getAvailableDescriptiveData",
      });

      if (!response.response) throw new Error(response.errorString);

      const validatedResponse = AvailableDescriptiveDataResult.parse(response);
      return validatedResponse.availableAnalytics;
    },
  });
};
