import {
  DateRange,
  DateRangePicker,
  PickersShortcutsItem,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import { DateTime } from "luxon";
import { ComponentProps, forwardRef } from "react";

const shortcutsItems: PickersShortcutsItem<DateRange<DateTime>>[] = [
  {
    label: "Today",
    getValue: () => {
      const today = DateTime.now();
      return [today.startOf("day"), today.endOf("day")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = DateTime.now();
      return [today.minus({ days: 7 }), today];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = DateTime.now();
      return [
        today.startOf("week").minus({ weeks: 1 }),
        today.endOf("week").minus({ weeks: 1 }),
      ];
    },
  },
  {
    label: "Last 30 days",
    getValue: () => {
      const today = DateTime.now();
      return [today.minus({ days: 30 }), today];
    },
  },

  { label: "Reset", getValue: () => [null, null] },
];

export const RangeCalendar = forwardRef<
  HTMLDivElement,
  Omit<ComponentProps<typeof DateRangePicker>, "slots" | "slotProps">
>((props, ref) => {
  return (
    <DateRangePicker
      ref={ref}
      slots={{ field: SingleInputDateRangeField }}
      slotProps={{
        shortcuts: {
          items: shortcutsItems,
        },
        textField: {
          size: "small",
        },
      }}
      {...props}
    />
  );
});
