import { useMachineContext } from "@/context/machine-context";
import { useQuery } from "@tanstack/react-query";
import { get, ref } from "firebase/database";
import { db } from "@/lib/sentinel-app";

export const useGetMinMaxOeeRanges = () => {
  const { machine } = useMachineContext();
  const machineName = machine?.machine as string;

  return useQuery<{ lowerBound: number; upperBound: number }>({
    queryKey: ["getMinMaxOeeRanges", machineName],
    queryFn: async () => {
      const response = (await get(ref(db, `/ranges/${machineName}`))).val() as {
        maxBad?: number;
        maxMedium?: number;
      } | null;

      if (!response) {
        return { lowerBound: 50, upperBound: 80 };
      }

      const { maxBad, maxMedium } = response;

      return {
        lowerBound: maxBad ?? 50,
        upperBound: maxMedium ?? 80,
      };
    },
  });
};
