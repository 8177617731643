import { Stack } from "@mui/material";
import { RejectCauseDetail } from "./RejectCauseDetail";
import { TopTenRejects } from "./TopTenRejects";

export const RejectCausesBaseView = () => {
  return (
    <Stack gap={2} marginTop={2}>
      <TopTenRejects />
      <RejectCauseDetail />
    </Stack>
  );
};
