import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type MotorListResponse = ThingworxError | MotorListResult;

const Motor = z.object({
  id: z.number(),
  name: z.string(),
});

export type Motor = z.infer<typeof Motor>;

const MotorListResult = z.object({
  response: z.literal(true),
  motorList: z.array(Motor),
});

export type MotorListResult = z.infer<typeof MotorListResult>;

interface Payload {
  machineName: string;
}

export const useGetMotorList = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();

  return useQuery<Motor[]>({
    queryKey: ["motor-list", machine?.machine],
    queryFn: async () => {
      const response = await FetchClient<Payload, MotorListResponse>({
        appKey,
        payload: {
          machineName: machine?.machine || "",
        },
        url: "dig.c.motors_thing/Services/getMotorsList",
      });

      if (!response.response) throw new Error(response.errorString);

      const validatedResponse = MotorListResult.parse(response);
      return validatedResponse.motorList;
    },
  });
};
