import { useState } from "react";
import { toast } from "react-hot-toast";
import {
  Stack,
  IconButton,
  Box,
  Typography,
  Tooltip,
  ListItemIcon,
  MenuItem,
  Menu,
  CircularProgress,
} from "@mui/material";
import {
  MoreVert,
  DeleteOutlined,
  DriveFileRenameOutlineOutlined,
  PushPin,
  PushPinOutlined,
} from "@mui/icons-material";
import { useTranslate } from "@/i18n/config";
import { useDisclosure } from "@/hooks/useDisclosure";
import { Chat } from "../../types";
import { chatStore } from "../../store/chat-store";
import { useGetHistoricChatById } from "../../api/useGetHistoricChatById";
import { usePinHistoricChatById } from "../../api/usePinHistoricChatById";
import { useUnpinHistoricChatById } from "../../api/useUnpinHistoricChatById";
import { RenameHistoricChatModal } from "./RenameHistoricChatModal";
import { DeleteHistoricChatModal } from "./DeleteHistoricChatModal";
import { userConfigurationStore } from "../../store/user-configuration-store";

export const HistoricChat = ({ chat }: { chat: Chat }) => {
  const translate = useTranslate();
  const { mutate: getHistoricChatById, isLoading: isLoadingChat } =
    useGetHistoricChatById();
  const { mutate: pinHistoricChatById, isLoading: isPinning } =
    usePinHistoricChatById();
  const { mutate: unpinHistoricChatById, isLoading: isUnpinning } =
    useUnpinHistoricChatById();
  const { close: hide, open: show, isOpen: isHover } = useDisclosure();
  const {
    open: openRenameModal,
    isOpen: isOpenRenameModal,
    close: closeRenameModal,
  } = useDisclosure();
  const {
    open: openDeleteModal,
    isOpen: isOpenDeleteModal,
    close: closeDeleteModal,
  } = useDisclosure();
  const { addMessage, setChatId, clearChat } = chatStore();
  const { setLastMachineId } = userConfigurationStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = () => {
    getHistoricChatById(
      { chat_id: chat.id },
      {
        onSuccess(data) {
          clearChat();
          setChatId(data.chat_id);
          if (data.last_machine_id) setLastMachineId(data.last_machine_id);
          data.chat_messages.sort((a, b) => a.id - b.id);

          data.chat_messages.forEach((message) => {
            if (message.mode === "standard") {
              addMessage({
                sender: "User",
                message: message.query,
                timestamp: message.timestamp,
                sources: message.sources,
              });
              addMessage({
                sender: "Intellecta",
                message: message.answer,
                id: message.id,
                timestamp: message.timestamp,
                sources: message.sources,
                image: message.image ? message.image : undefined,
              });
            } else {
              // sentry mode
              addMessage({
                sender: "Sentry Mode",
                message: message.answer,
                id: message.id,
                timestamp: message.timestamp,
                sources: message.sources,
                image: message.image ? message.image : undefined,
              });
            }
          });
        },
        onError() {
          toast.error(translate("user_feedback.an_error_occurred"));
        },
      },
    );
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);

    if (!isHover) {
      hide();
    }
  };

  const handlePin = () => {
    handleCloseMenu();
    pinHistoricChatById(
      { chat_id: chat.id },
      {
        onError() {
          toast.error(translate("user_feedback.an_error_occurred"));
        },
      },
    );
  };

  const handleUnpin = () => {
    handleCloseMenu();
    unpinHistoricChatById(
      { chat_id: chat.id },
      {
        onError() {
          toast.error(translate("user_feedback.an_error_occurred"));
        },
      },
    );
  };

  const handleOpenRenameModal = () => {
    handleCloseMenu();
    openRenameModal();
  };

  const handleOpenDeleteModal = () => {
    handleCloseMenu();
    openDeleteModal();
  };

  return (
    <>
      <Stack>
        <Typography variant="caption">
          {new Date(chat.timestamp * 1000).toLocaleString()}
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          onMouseEnter={show}
          onMouseLeave={hide}
          width="100%"
          height="42px"
          sx={{
            transition: "background-color 0.3s",
            borderRadius: 4,
            cursor: "pointer",
            padding: ".25rem .5rem",
            "&:hover": {
              backgroundColor: "#ffffff15",
            },
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            maxWidth={
              isHover && chat.is_pinned
                ? "calc(100% - 68px)"
                : isHover && !chat.is_pinned
                ? "calc(100% - 44px)"
                : !isHover && chat.is_pinned
                ? "calc(100% - 44px)"
                : "100%"
            }
          >
            <Tooltip title={chat.title} enterDelay={2000}>
              <Typography
                sx={{ justifyContent: "flex-start" }}
                onClick={handleClick}
                noWrap
              >
                {chat.title}
              </Typography>
            </Tooltip>
          </Box>
          <Box display="flex" alignItems="center">
            {isLoadingChat || isPinning ? (
              <CircularProgress size={24} sx={{ alignSelf: "center" }} />
            ) : (
              <IconButton
                sx={{
                  visibility: isHover ? "visible" : "hidden",
                  width: isHover ? undefined : 0,
                }}
                size="small"
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClickMenu}
              >
                <MoreVert />
              </IconButton>
            )}
            {chat.is_pinned && (
              <Tooltip title={translate("actions.unpin")}>
                <IconButton
                  size="small"
                  aria-label="more"
                  id="long-button"
                  aria-haspopup="true"
                  onClick={handleUnpin}
                >
                  {isUnpinning ? (
                    <CircularProgress size={24} sx={{ alignSelf: "center" }} />
                  ) : (
                    <PushPin fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        id="historic-chat-menu"
        open={open}
        onClose={handleCloseMenu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {chat.is_pinned ? (
          <MenuItem onClick={handleUnpin}>
            <ListItemIcon>
              <PushPinOutlined fontSize="small" />
            </ListItemIcon>
            {translate("actions.unpin")}
          </MenuItem>
        ) : (
          <MenuItem onClick={handlePin}>
            <ListItemIcon>
              <PushPin fontSize="small" />
            </ListItemIcon>
            {translate("actions.pin")}
          </MenuItem>
        )}
        <MenuItem onClick={handleOpenRenameModal}>
          <ListItemIcon>
            <DriveFileRenameOutlineOutlined fontSize="small" />
          </ListItemIcon>
          {translate("actions.rename")}
        </MenuItem>
        <MenuItem onClick={handleOpenDeleteModal}>
          <ListItemIcon>
            <DeleteOutlined fontSize="small" />
          </ListItemIcon>
          {translate("actions.delete")}
        </MenuItem>
      </Menu>
      <RenameHistoricChatModal
        chat={chat}
        close={closeRenameModal}
        isOpen={isOpenRenameModal}
      />
      <DeleteHistoricChatModal
        chat={chat}
        close={closeDeleteModal}
        isOpen={isOpenDeleteModal}
      />
    </>
  );
};
