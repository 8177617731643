import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { useIsolatorContext } from "../../context/useIsolatorContext";
import { Analytic } from "../../types";

interface DescriptiveRawDataSelection {
  variables: Analytic[];
  selectedVariable: number | null;
  onSelectVariable: (variable: number | null) => void;
}

export const DescriptiveRawDataSelection = ({
  variables,
  selectedVariable,
  onSelectVariable,
}: DescriptiveRawDataSelection) => {
  const translate = useTranslate();
  const { batches } = useIsolatorContext();

  if (variables.length === 0)
    return (
      <Card sx={{ height: 300 }}>
        {translate("user_feedback.no_data_to_display")}
      </Card>
    );

  variables.sort((a, b) => a.chartName.localeCompare(b.chartName));

  return (
    <Card sx={{ height: 300, overflowY: "auto" }}>
      <Typography variant="h6">{translate("variable_other")}</Typography>
      {batches.length === 0 ? (
        <div>{translate("isolator.no_batch_selected")}</div>
      ) : (
        <FormControl>
          <RadioGroup
            onChange={(
              _: React.ChangeEvent<HTMLInputElement>,
              value: string,
            ) => {
              onSelectVariable(parseInt(value));
            }}
            value={selectedVariable}
          >
            {variables.map((item) => (
              <FormControlLabel
                key={`radio-${item.id}`}
                control={<Radio />}
                value={item.id}
                label={item.chartName}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    </Card>
  );
};
