import { BasicTable } from "@/components/BasicTable";
import { Card } from "@/components/Layout/Card";
import { DataTransformer } from "@/utils/DataTransformer";
import { TableParser } from "@/utils/TableParser";
import { Stack } from "@mui/material";
import { DataChart, PcB } from "../api/useGetEdgePcData";
import { TableTitle } from "../layout/StyledText";
import { formatDuration } from "../utils/convert-duration";
import { useTranslate } from "@/i18n/config";

export const Logs = ({
  dataLogs,
}: {
  dataLogs: {
    bidirectionalLog: DataChart["bidirectional"];
    uptimeLog: DataChart["uptimeAp"];
    fail2BanLog: DataChart["fail2Ban"];
    powerOffPcB: PcB["poweroff"];
  };
}) => {
  const translate = useTranslate();
  const { bidirectionalLog, uptimeLog, fail2BanLog, powerOffPcB } = dataLogs;
  /** bidirectional table */
  const { columns: bidirectionalCol, rows: bidirectionalRows } =
    bidirectionalLog.length > 0
      ? TableParser.parseData({
          data: bidirectionalLog,
          columnsMap: {
            timestamp: "Timestamp",
            active: "Status",
          },
          columnsOrder: ["timestamp", "active"],
        })
      : { columns: [], rows: [] };

  /** uptime table*/
  const { columns: uptimeCol, rows: uptimeRows } =
    uptimeLog.length > 0
      ? TableParser.parseData<{
          timestamp: number;
          /** @description viene parsato in stringa per la voncersione della duration, dato grezzo è tipo numero */
          pcA: string;
          pcB: string;
        }>({
          data: uptimeLog.map((log) =>
            DataTransformer.dataTransformer({
              input: {
                ...log,
                pcA: formatDuration(log.pcA * 1000),
                pcB: formatDuration(log.pcB * 1000),
              },
              transform: {},
            }),
          ),

          columnsMap: {
            timestamp: "Timestamp",
            pcA: "Duration A",
            pcB: "Duration B",
          },
          columnsOrder: ["timestamp", "pcA", "pcB"],
        })
      : { columns: [], rows: [] };

  /** fail2BAn table*/
  // ho mappato il l'array per uniformare la chiave timeofban a timestamp con il tipo di valore number
  const fail2BAnTableMapped = fail2BanLog.map((el) => {
    return { ip: el.ip, jail: el.jail, timestamp: parseInt(el.timeofban) };
  });
  const { columns: fail2BanCol, rows: fail2BanRows } =
    fail2BAnTableMapped.length > 0
      ? TableParser.parseData({
          data: fail2BAnTableMapped,
          columnsMap: {
            timestamp: "Timestamp",
            ip: "IP",
            jail: "Service",
          },
          columnsOrder: ["timestamp", "jail", "ip"],
        })
      : { columns: [], rows: [] };

  /** power off pc B table*/
  const { columns: poweroffBCol, rows: poweroffBRows } =
    powerOffPcB.length > 0
      ? TableParser.parseData({
          data: powerOffPcB,
          columnsMap: {
            timestamp: "Timestamp",
            value: "State",
          },
          columnsOrder: ["timestamp", "value"],
        })
      : { columns: [], rows: [] };

  return (
    <Stack gap={2} direction="column">
      <Stack gap={2} direction={{ md: "row", xs: "column" }}>
        <Card>
          <TableTitle>
            {translate("a4gate_monitoring.bidirectional_log")}
          </TableTitle>
          <BasicTable
            columns={bidirectionalCol}
            rows={bidirectionalRows}
            classes={{ height: 300 }}
            fileName="bidirectional_log_data"
          />
        </Card>
        <Card>
          <TableTitle>{translate("a4gate_monitoring.uptime_log")}</TableTitle>
          <BasicTable
            columns={uptimeCol}
            rows={uptimeRows}
            classes={{ height: 300 }}
            fileName="uptime_log_data"
          />
        </Card>
      </Stack>
      <Stack gap={2} direction={{ md: "row", xs: "column" }}>
        <Card>
          <TableTitle>{translate("a4gate_monitoring.fail2ban_log")}</TableTitle>
          <BasicTable
            columns={fail2BanCol}
            rows={fail2BanRows}
            classes={{ height: 300 }}
            fileName="fail2ban_log_data"
          />
        </Card>
        <Card>
          <TableTitle>{translate("power_off")} PC B</TableTitle>
          <BasicTable
            columns={poweroffBCol}
            rows={poweroffBRows}
            classes={{ height: 300 }}
            fileName="power_off_pc_b_data"
          />
        </Card>
      </Stack>
    </Stack>
  );
};
