import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useSetEnableSat = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      isActive,
      isLimitedLogic,
    }: {
      isActive: boolean;
      isLimitedLogic: boolean;
    }) => {
      await FetchClient<
        { machineName: string; isActive: boolean; isLimitedLogic: boolean },
        null
      >({
        appKey,
        payload: {
          machineName: machine!.machine,
          isActive,
          isLimitedLogic,
        },
        url: "dig.c.acceptanceTest_thing/Services/enable",
      });
    },
    // ! per ora funziona- lato back-end ha manda la renponse prima del processo di chiusura del test
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["sat-isActive", machine?.machine],
      });
    },
  });
};
