import { z } from "zod";
import { DateTime } from "luxon";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";

type DailyMeanCurrentResponse = ThingworxError | DailyMeanCurrentResult;

const data = z.object({
  meanData: z.array(z.array(z.number())),
  //   rmsData: z.array(z.array(z.number())),
});

export type Data = z.infer<typeof data>;

const dailyMeanCurrentResult = z.object({
  response: z.literal(true),
  data,
});

export type DailyMeanCurrentResult = z.infer<typeof dailyMeanCurrentResult>;

interface Payload {
  machineName: string;
  motor: number;
  dateStart?: DateTime;
  dateEnd?: DateTime;
}

export const useGetDailyMeanCurrent = ({
  motor,
  dateStart,
  dateEnd,
}: {
  motor: number;
  dateStart?: DateTime;
  dateEnd?: DateTime;
}) => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();

  return useQuery<Data>({
    queryKey: [
      "daily-mean-current-data",
      machine?.machine,
      motor,
      dateStart,
      dateEnd,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, DailyMeanCurrentResponse>({
        appKey,
        payload: {
          machineName: machine?.machine || "",
          motor,
          dateStart,
          dateEnd,
        },
        url: "dig.c.motors_thing/Services/getDailyMeanCurrent",
      });

      if (!response.response) throw new Error(response.errorString);

      const validatedResponse = dailyMeanCurrentResult.parse(response);
      return validatedResponse.data;
    },
  });
};
