import { Card } from "@/components/Layout/Card";
import { useGetDescriptiveRawData } from "../../api/Monitoring/useGetDescriptiveRawData";
import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { ContextInformation } from "@/features/Motors/components/Layouts/ContextInformation";
import { Stack } from "@mui/material";
import { parseUom } from "../../utils/parseUom";
import { readablePhase } from "../../utils/readablePhase";
import { useTranslate } from "@/i18n/config";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { CHART_COLORS } from "../../utils/chartColors";
import { parseUomAxisLabel } from "../../utils/parseUomAxisLabel";
import { parseMultiBatchXAxes } from "../../utils/parseMultiBatchXAxes";

export const DescriptiveRawDataChart = ({
  analyticId,
  phaseId,
  allowMultiBatch,
}: {
  analyticId: number;
  phaseId: number;
  allowMultiBatch: boolean;
}) => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetDescriptiveRawData({
    analyticId,
    phaseId,
    allowMultiBatch,
  });

  if (isLoading) return <SkeletonCard height={400} />;

  if (error)
    return (
      <Card sx={{ height: 400 }}>
        {translate("user_feedback.an_error_occurred")}
      </Card>
    );

  if (!data)
    return (
      <Card sx={{ height: 400 }}>{translate("user_feedback.no_data")}</Card>
    );

  if (data.chartData.length === 0)
    return (
      <Card sx={{ height: 400 }}>{translate("user_feedback.no_data")}</Card>
    );

  return (
    <Stack gap={2}>
      {data.chartData.map((chart, i) => (
        <Card key={JSON.stringify(chart.contextInfo) + i}>
          <ContextInformation props={chart.contextInfo} />
          <LineChart.Custom
            title=""
            tooltip={{
              enabled: true,
              options: { valueSuffix: parseUom(chart.uom), valueDecimals: 2 },
            }}
            height={chart.isMultiBatch ? 500 : 400}
            highchartsChartProps={{ colors: CHART_COLORS }}
            legend={{ enabled: true }}
            xAxes={
              chart.isMultiBatch ? parseMultiBatchXAxes(chart.data) : undefined
            }
            yAxis={[
              {
                yAxisTitleOptions: {
                  text: parseUomAxisLabel(chart.uom),
                  style: { color: "white" },
                },
                uom: "",
                series: chart.isMultiBatch
                  ? chart.data.map((serie, i) => ({
                      type: "line",
                      name: serie.name,
                      data: serie.data,
                      xAxis: i,
                    }))
                  : chart.subphasesData.map((serie) => ({
                      type: "line",
                      name: readablePhase(serie.subphase),
                      data: serie.data,
                    })),
              },
            ]}
          ></LineChart.Custom>
        </Card>
      ))}
    </Stack>
  );
};
