import {
  Button,
  CircularProgress,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { Modal } from "@/components/Modal";
import { useGetManualList } from "./api/useGetManualList";
import { DownloadableDocument } from "./components/DownloadableDocument";
import { toast } from "react-hot-toast";
import { useTranslate } from "@/i18n/config";

export const HelpModal = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const translate = useTranslate();
  const { data, error, isLoading } = useGetManualList();

  if (isLoading) {
    return (
      <Modal
        open={isOpen}
        titleContent={translate("available_documents")}
        bodyContent={<CircularProgress size={24} color="inherit" />}
        actions={
          <Button variant="outlined" onClick={close}>
            {translate("actions.cancel")}
          </Button>
        }
        maxWidth="sm"
        fullWidth
      />
    );
  }

  if (error) {
    toast.error(translate("user_feedback.an_error_occurred"));
    return null;
  }

  if (!data) {
    toast.error(translate("user_feedback.no_documents_available"));
    return null;
  }

  if (Object.keys(data).length === 0) {
    toast.error(translate("user_feedback.no_documents_available"));
    return null;
  }

  return (
    <Modal
      open={isOpen}
      onClose={close}
      titleContent={translate("available_documents")}
      bodyContent={
        <>
          {Object.keys(data).map((lang) => (
            <List key={lang}>
              <Typography variant="h6">{lang}</Typography>
              {data[lang].map((document) => (
                <ListItem key={`item-${document.filesPath}`}>
                  <DownloadableDocument
                    key={document.filesPath}
                    name={document.name}
                    filesPath={document.filesPath}
                  />
                </ListItem>
              ))}
            </List>
          ))}
        </>
      }
      actions={
        <Button variant="outlined" onClick={close}>
          {translate("actions.cancel")}
        </Button>
      }
      maxWidth="sm"
      fullWidth
    />
  );
};
