import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type MachineLocationResponse = ThingworxError | MachineLocationResult;

const MachineLocationResult = z.object({
  response: z.literal(true),
  infoAccountNumber: z.string(),
  infoAddress: z.string(),
  infoEquipment: z.string(),
  infoBBox: z.string(),
  infoLine: z.string(),
  infoAccountName: z.string(),
  infoSaleOrganization: z.string(),
  infoCity: z.string(),
  infoCountry: z.string(),
  infoDescription: z.string(),
  infoMarker: z.string(),
});

export type MachineLocationResult = z.infer<typeof MachineLocationResult>;

export const useGetMachineLocation = () => {
  const { machine } = useMachineContext();
  const { appKey, user } = useFirebaseContext();

  return useQuery<MachineLocationResult>({
    queryKey: [machine?.machine, user?.email],
    queryFn: async () => {
      const response = await FetchClient<
        { machineName: string; user: string },
        MachineLocationResponse
      >({
        appKey,
        payload: {
          machineName: machine!.machine,
          user: user?.email ?? "",
        },
        url: "dig.c.machineInfo_thing/Services/getData",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return MachineLocationResult.parse(response);
    },
  });
};
