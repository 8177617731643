import { useContext } from "react";
import { QuantizationDispatchContext } from "./quantization-context";

export const useQuantizationDispatchContext = () => {
  const context = useContext(QuantizationDispatchContext);

  if (!context) {
    throw new Error(
      "You can only use this context inside QuantizationDispatchContextProvider",
    );
  }
  return context;
};
