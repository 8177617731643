import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { Timelapse, TimelapseOption } from "../../types";
import { Card } from "@/components/Layout/Card";

interface SelectDeviancesTimelapseProps {
  timelapse: Timelapse;
  setTimelapse: React.Dispatch<React.SetStateAction<Timelapse>>;
}

export const SelectDeviancesTimelapse = ({
  timelapse,
  setTimelapse,
}: SelectDeviancesTimelapseProps) => {
  const options: TimelapseOption[] = [
    {
      value: "hour",
      clientName: "Hour",
    },
    {
      value: "day",
      clientName: "Day",
    },
    {
      value: "week",
      clientName: "Week",
    },
  ];

  const handleChange = (event: SelectChangeEvent) => {
    setTimelapse(event.target.value as Timelapse);
  };

  return (
    <Card>
      <FormControl fullWidth>
        <InputLabel id="timelapse-select-label">Timelapse</InputLabel>
        <Select
          labelId="timelapse-select-label"
          id="timelapse-select"
          value={timelapse}
          label="Timelapse"
          onChange={handleChange}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.clientName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Card>
  );
};
