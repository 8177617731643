import JSZip from "jszip";
import { ReportResult } from "./display-report";
import { REPORT_AUTH_TOKEN, REPORT_ENDPOINT } from "../api/report-endpoint";

export async function getDataForDownload(filePath: string) {
  const payload = {
    filePath,
  };
  try {
    const res = await fetch(`${REPORT_ENDPOINT}/get-file`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Basic ${REPORT_AUTH_TOKEN}`,
      },
      body: JSON.stringify(payload),
    });
    const response = (await res.json()) as ReportResult;

    if (!response) return;
    const validatedResponse = ReportResult.parse(response);
    downloadReport(validatedResponse.file_content.data);
  } catch (error) {
    console.error("Something went wrong", error);
  }
}

function downloadReport(bufferData: Array<number>) {
  new JSZip()
    .loadAsync(new Uint8Array(bufferData))
    .then((zipObject) => {
      const htmlFile = Object.values(zipObject.files)[0];
      return htmlFile.async("string");
    })
    .then((content) => {
      const linkBlob = URL.createObjectURL(
        new Blob([content], { type: "text/html" }),
      );
      const a = document.createElement("a");
      a.href = linkBlob;
      a.download = "document.html";
      a.click();
      URL.revokeObjectURL(linkBlob);
      return;
    });
}
