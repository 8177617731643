import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { useLyoContext } from "../context/lyo-context";
import { z } from "zod";

type AssetDataResponse = ThingworxError | AssetDataResult;

const AdvancedAnalyticName = z.enum([
  "Pressure_anomaly",
  "Refrigeration",
  "Condenser",
  "vacuum_pump",
]);

export type AdvancedAnalyticName = z.infer<typeof AdvancedAnalyticName>;

const AdvancedAnalytic = z.object({
  id: z.number(),
  advanced_analytic_base_id: z.number(),
  name: AdvancedAnalyticName,
  display_name: z.string(),
});

export type AdvancedAnalytic = z.infer<typeof AdvancedAnalytic>;

const AssetDataResult = z.object({
  response: z.literal(true),
  analytics: z.array(AdvancedAnalytic),
});

type AssetDataResult = z.infer<typeof AssetDataResult>;

export const useGetBatchAdvancedAnalytics = () => {
  const { appKey } = useFirebaseContext();

  const [{ selectedBatch }] = useLyoContext();

  return useQuery<AdvancedAnalytic[]>({
    queryKey: ["batch-advanced-analytics-list", selectedBatch?.batchId],
    queryFn: async () => {
      if (!selectedBatch) return [];

      const response = await FetchClient<
        {
          batch_id?: number;
        },
        AssetDataResponse
      >({
        appKey,
        payload: {
          batch_id: selectedBatch.batchId,
        },
        url: "dig.c.lyophilizer_thing/Services/getBatchAdvancedAnalytics",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = AssetDataResult.parse(response);

      return validatedResponse.analytics;
    },
    refetchInterval: 1000 * 30,
    cacheTime: 0,
    enabled: !!selectedBatch,
  });
};
