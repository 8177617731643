/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, LinearProgress, Portal, Snackbar, Stack, styled } from "@mui/material";
import { motion } from "framer-motion";
import { useState } from "react";
import { useGetLandingData, MachineArray } from "../api/useGetLandingData";
import { useLandingContext } from "../context/landing-context";
import { ContainerLayout } from "./ContainerLayout";
import MuiAlert from "@mui/material/Alert";
import { useTranslate } from "@/i18n/config";

type CategoryCode = 1 | 2 | 3 | 4 | 5 | 6;

const categories: {
  code: MachineArray["categoryCode"];
  color: string;
}[] = [
  {
    code: 1,
    color: "#00FF00",
  },
  {
    code: 2,
    color: "#ff7a0d",
  },
  {
    code: 3,
    color: "#1a98c9",
  },
  {
    code: 4,
    color: "#B277E2",
  },
  {
    code: 5,
    color: "#8a8a8a",
  },
  {
    code: 6,
    color: "#eeeeee",
  },
];

const CategoryContainer = styled(ContainerLayout)({
  cursor: "pointer",
});

export const StateCategories = () => {
  const [{ filteredMachines, filteringByCategory }, dispatch] = useLandingContext();
  const [snackbar, setSnackbar] = useState<{
    message: string;
    duration: number;
  }>();
  const translate = useTranslate();

  const onClose = () => setSnackbar(undefined);

  const { data: landingData } = useGetLandingData();

  const selectedMachinesId = filteredMachines.map((m) => m.machine);

  const machines =
    landingData?.landingPageData.machineArray.filter((m) =>
      selectedMachinesId.includes(m.machine),
    ) || [];

  // count the amount of machines in each state"
  const stateCount = categories.map((category) => {
    const amount = machines.filter((machine) => machine.categoryCode === category.code).length;
    return { ...category, amount };
  });

  const totalAmount = filteredMachines.length;

  return (
    <>
      <Stack gap={2} direction="row" justifyContent="space-around" flexWrap="wrap">
        {stateCount.map((value) => {
          return (
            <CategoryContainer
              onClick={() => {
                if (value.amount === 0) {
                  setSnackbar({
                    message: "No machines to display",
                    duration: 3000,
                  });
                  return;
                }
                dispatch({
                  type: "filter by Category",
                  category: value.code,
                });
              }}
              key={value.code}
              component={motion.div}
              flex={1}
              // @ts-ignore
              whileHover={{ scale: 1.1 }}
              sx={{
                color: value.color,
                minWidth: "10rem",
                fontweight: "bold",
                minHeight: "6rem",
                boxShadow: filteringByCategory === value.code ? `0px 0px 0px 2px inset` : "none",
              }}
            >
              <Stack justifyContent="space-between" alignItems="center" direction="column">
                <Box
                  sx={{
                    fontWeight: "bold",
                    height: "3rem",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {translate(`category.${value.code as CategoryCode}`)}
                </Box>
                <Box display="flex" width="100%" justifyContent="space-between" pb={0.5}>
                  <Box>
                    {totalAmount > 0
                      ? `${((value.amount / totalAmount) * 100).toFixed(0)}%`
                      : "---"}
                  </Box>
                  <Box>
                    {value.amount}/{totalAmount}
                  </Box>
                </Box>
              </Stack>
              <LinearProgress
                color="inherit"
                variant="determinate"
                value={totalAmount > 0 ? (value.amount / totalAmount) * 100 : 0}
              />
            </CategoryContainer>
          );
        })}
      </Stack>
      <Portal>
        <Snackbar
          open={snackbar != null}
          autoHideDuration={snackbar?.duration}
          onClose={onClose}
          message={snackbar?.message}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <MuiAlert elevation={6} variant="filled" severity="info">
            {snackbar?.message}
          </MuiAlert>
        </Snackbar>
      </Portal>
    </>
  );
};
