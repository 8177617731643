import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { SubphaseEl } from "../../types";
import { Card } from "@/components/Layout/Card";
import { Asset } from "./Asset";
import { TagListSelect } from "./TagListSelect";
import { readablePhase } from "../../utils/readablePhase";
import { SUBPHASE_ORDER } from "../../utils/phaseOrder";

export const SubphaseListSelect = ({
  subphaseList,
  phaseId,
}: {
  subphaseList: SubphaseEl[];
  phaseId: number;
}) => {
  const [selectedSubphaseId, setSelectedSubphaseId] = useState<number | null>(
    null,
  );
  const [selectedSubphase, setSelectedSubphase] = useState<SubphaseEl | null>(
    null,
  );

  useEffect(() => {
    return () => {
      setSelectedSubphase(null);
      setSelectedSubphaseId(null);
    };
  }, []);

  subphaseList.sort(
    (a, b) => SUBPHASE_ORDER.indexOf(a.name) - SUBPHASE_ORDER.indexOf(b.name),
  );

  return (
    <>
      <Grid item xs={1} md={1}>
        <Card
          key={"condition-subphase-list" + phaseId}
          initial={{ opacity: 0, scale: 0.75, x: 20 }}
          animate={{ opacity: 1, scale: 1, x: 0 }}
          exit={{ opacity: 0 }}
          sx={{
            maxHeight: 300,
            overflow: "auto",
          }}
        >
          {subphaseList.map((subphase) => (
            <Asset
              key={subphase.id}
              type="Other"
              active={selectedSubphaseId === subphase.id}
              name={readablePhase(subphase.name)}
              hasWarn={subphase.hasWarn}
              id={subphase.id}
              onClick={(id) => {
                if (id === selectedSubphaseId) {
                  setSelectedSubphaseId(null);
                  setSelectedSubphase(null);
                } else {
                  setSelectedSubphaseId(id);
                  const foundSubphase = subphaseList.find(
                    (subphase) => subphase.id === id,
                  );
                  if (foundSubphase) setSelectedSubphase(foundSubphase);
                }
              }}
            />
          ))}
        </Card>
      </Grid>
      {selectedSubphase && selectedSubphaseId ? (
        <TagListSelect
          key={selectedSubphaseId}
          tagList={selectedSubphase.tagList}
          phaseId={selectedSubphaseId}
        />
      ) : null}
    </>
  );
};
