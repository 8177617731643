import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { useIsolatorContext } from "../../context/useIsolatorContext";
import { Analytic } from "../../types";

interface ConditionAnalyticsSelectionProps {
  analytics: Analytic[];
  selectedAnalytic: number | null;
  onSelectAnalytic: (analytic: number | null) => void;
}

export const DescriptiveAnalyticsSelection = ({
  analytics,
  selectedAnalytic,
  onSelectAnalytic,
}: ConditionAnalyticsSelectionProps) => {
  const translate = useTranslate();
  const { batches } = useIsolatorContext();

  if (analytics.length === 0)
    return (
      <Card sx={{ width: 320, height: 400 }}>
        {translate("motors.no_analytics_to_display")}
      </Card>
    );

  return (
    <Card sx={{ width: 320, height: 400, overflowY: "auto" }}>
      <Typography variant="h6">{translate("analytics_other")}</Typography>
      {batches.length === 0 ? (
        <div>{translate("isolator.no_batch_selected")}</div>
      ) : (
        <FormControl>
          <RadioGroup
            onChange={(
              _: React.ChangeEvent<HTMLInputElement>,
              value: string,
            ) => {
              onSelectAnalytic(parseInt(value));
            }}
            value={selectedAnalytic}
          >
            {analytics.map((item) => (
              <FormControlLabel
                key={`radio-${item.id}`}
                control={<Radio />}
                value={item.id}
                label={item.chartName}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    </Card>
  );
};
