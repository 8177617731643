import { PieData } from "../api/CyclicLive/useGetSentinelLiveData";

export const withPieSteps = (
  data: { name: string; y: number; color: string; z?: number }[],
): PieData[] => {

  const allHaveZ = data.every((item) => item.z !== undefined);
  if (allHaveZ) {
    return data as PieData[];
  }

  const total = data.length;

  if (total === 0) {
    return [
      {
        color: "#0D1626",
        name: "No data",
        y: 1,
        z: 1,
      },
    ];
  }

  // map data adding a z value calculating it based on the total steps
  return data
    .sort((a, b) => b.y - a.y)
    .map((item, index) => ({
      ...item,
      z: total - index,
    }));
};
