import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface Payload {
  machineName?: string;
  targetBatch: string;
  hourTargetBatch: number;
  nameBatch: string;
  targetBatchProductHour: string;
  startChangedFormat: boolean;
}

export const useSetTargetBatch = () => {
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      targetBatch,
      hourTargetBatch,
      nameBatch,
      targetBatchProductHour,
      startChangedFormat,
    }: Payload) => {
      await FetchClient<Payload, null>({
        appKey,
        payload: {
          machineName: machine!.machine,
          targetBatch,
          hourTargetBatch,
          nameBatch,
          targetBatchProductHour,
          startChangedFormat,
        },
        url: "dig.c.batch_thing/Services/updateBatchData",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["batch-navigator", machine!.machine],
      });
    },
  });
};
