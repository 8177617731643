import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useCustomTimeRange } from "../store/useCustomTimeRange";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type RejectSunburstResponse = ThingworxError | DosersScalesResult;

const DosersScalesData = z.object({
  scales: z.object({
    series: z.array(
      z.object({
        name: z.string(),
        y: z.number(),
        drilldown: z.string(),
        weighings: z.number(),
      }),
    ),
    drilldown: z.array(
      z.object({
        name: z.string(),
        id: z.string(),

        data: z.array(
          z.object({
            name: z.string(),
            y: z.number(),
            weighings: z.number(),
          }),
        ),
      }),
    ),
  }),
  dosers: z.array(
    z.object({
      name: z.string(),
      y: z.number(),
      weighings: z.number(),
    }),
  ),
});
type DosersScalesData = z.infer<typeof DosersScalesData>;

const DosersScalesResult = z.object({
  response: z.literal(true),
  data: DosersScalesData,
});

export type DosersScalesResult = z.infer<typeof DosersScalesResult>;

interface RejectDosersScalesPayload {
  machineName: string;
  dateStart: DateTime;
  dateEnd: DateTime;
}

export const useGetDosersScalesData = () => {
  const { machine } = useMachineContext();

  const { dateStart, dateEnd } = useCustomTimeRange();

  const { appKey } = useFirebaseContext();

  return useQuery<DosersScalesData>({
    queryKey: ["dosers-scales", machine?.machine, dateStart, dateEnd],
    queryFn: async () => {
      const response = await FetchClient<
        RejectDosersScalesPayload,
        RejectSunburstResponse
      >({
        appKey,
        payload: {
          dateStart,
          dateEnd,
          machineName: machine?.machine || "",
        },
        url: "dig.c.fillerAdvancedAnalytics_thing/Services/getDosersScalesData",
      });

      if (!response.response) throw new Error(response.errorString);

      const validatedResponse = DosersScalesResult.parse(response);
      return validatedResponse.data;
    },
  });
};
