import { useEffect } from "react";
import { Autocomplete, Stack, TextField } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { useLandingContext } from "../context/landing-context";
import { Company, Factory, Line, Machine } from "../types";

export const Filters = () => {
  const [
    {
      availableCompanyFilters,
      availableFactoryFilters,
      availableLineFilters,
      availableMachineFilters,
      filteringByFactory,
      filteringByLine,
      filteringByMachine,
      filteringByCompany,
    },
    dispatch,
  ] = useLandingContext();
  const translate = useTranslate();

  useEffect(() => {
    const companyCtx = localStorage.getItem("home-filter-company");
    const factoryCtx = localStorage.getItem("home-filter-factory");
    const lineCtx = localStorage.getItem("home-filter-line");
    const machineCtx = localStorage.getItem("home-filter-machine");

    if (companyCtx) {
      const companyCtxJson = JSON.parse(companyCtx) as Company;
      dispatch({
        type: "filter by Company",
        company: {
          company_name: companyCtxJson.company_name,
          company_id: companyCtxJson.company_id,
        },
      });
    }
    if (factoryCtx) {
      const factoryCtxJson = JSON.parse(factoryCtx) as Factory;
      dispatch({
        type: "filter by Factory",
        factory: {
          factory_name: factoryCtxJson.factory_name,
          factory_id: factoryCtxJson.factory_id,
        },
      });
    }
    if (lineCtx) {
      const lineCtxJson = JSON.parse(lineCtx) as Line;
      dispatch({
        type: "filter by Line",
        line: {
          line_name: lineCtxJson.line_name,
          line_id: lineCtxJson.line_id,
        },
      });
    }
    if (machineCtx) {
      const machineCtxJson = JSON.parse(machineCtx) as Machine;
      dispatch({
        type: "filter by Machine",
        machine: {
          machine_name: machineCtxJson.machine_name,
          machine_id: machineCtxJson.machine_id,
        },
      });
    }
  }, [dispatch]);

  // get unique instances of each filter
  const uniqueCompanyId = Array.from(
    new Set(availableCompanyFilters.map((f) => f.company_id)),
  );
  const uniqueFactoryId = Array.from(
    new Set(availableFactoryFilters.map((f) => f.factory_id)),
  );
  const uniqueLineId = Array.from(
    new Set(availableLineFilters.map((f) => f.line_id)),
  );
  const uniqueMachineId = Array.from(
    new Set(availableMachineFilters.map((f) => f.machine_id)),
  );

  // use unique instances to filter out duplicates from the original array
  const uniqueCompanyFilters = availableCompanyFilters.filter((f) => {
    if (uniqueCompanyId.includes(f.company_id)) {
      uniqueCompanyId.splice(uniqueCompanyId.indexOf(f.company_id), 1);
      return true;
    }
  });
  const uniqueFactoryFilters = availableFactoryFilters.filter((f) => {
    if (uniqueFactoryId.includes(f.factory_id)) {
      uniqueFactoryId.splice(uniqueFactoryId.indexOf(f.factory_id), 1);
      return true;
    }
  });
  const uniqueLineFilters = availableLineFilters.filter((f) => {
    if (uniqueLineId.includes(f.line_id)) {
      uniqueLineId.splice(uniqueLineId.indexOf(f.line_id), 1);
      return true;
    }
  });
  const uniqueMachineFilters = availableMachineFilters.filter((f) => {
    if (uniqueMachineId.includes(f.machine_id)) {
      uniqueMachineId.splice(uniqueMachineId.indexOf(f.machine_id), 1);
      return true;
    }
  });

  return (
    <Stack
      gap={2}
      direction="row"
      flexWrap={"wrap"}
      justifyContent="space-between"
    >
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        size="small"
        options={uniqueCompanyFilters}
        value={filteringByCompany}
        onChange={(e, value, reason) => {
          if (reason === "clear") {
            dispatch({ type: "reset", entity: "Company" });
            return;
          }
          if (!value) return;
          dispatch({
            type: "filter by Company",
            company: {
              company_name: value.company_name,
              company_id: value.company_id,
            },
          });
          value.company_id === filteringByCompany?.company_id
            ? null
            : dispatch({ type: "reset", entity: "Factory" });
        }}
        getOptionLabel={(option) => option.company_name}
        isOptionEqualToValue={(option, value) =>
          option.company_id === value.company_id
        }
        sx={{ flex: 1, minWidth: 300 }}
        renderInput={(params) => (
          <TextField
            variant="filled"
            {...params}
            label={translate("select_company")}
          />
        )}
      />
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        size="small"
        value={filteringByFactory}
        options={uniqueFactoryFilters}
        onChange={(e, value, reason) => {
          if (reason === "clear") {
            dispatch({ type: "reset", entity: "Factory" });
            return;
          }
          if (!value) return;
          dispatch({
            type: "filter by Factory",
            factory: {
              factory_name: value.factory_name,
              factory_id: value.factory_id,
            },
          });
          value.factory_id === filteringByFactory?.factory_id
            ? null
            : dispatch({ type: "reset", entity: "Line" });
        }}
        getOptionLabel={(option) => option.factory_name}
        isOptionEqualToValue={(option, value) =>
          option.factory_id === value.factory_id
        }
        sx={{ flex: 1, minWidth: 300 }}
        renderInput={(params) => (
          <TextField
            variant="filled"
            {...params}
            label={translate("select_factory")}
          />
        )}
      />
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        size="small"
        options={uniqueLineFilters}
        value={filteringByLine}
        onChange={(e, value, reason) => {
          if (reason === "clear") {
            dispatch({ type: "reset", entity: "Line" });
            return;
          }
          if (!value) return;
          dispatch({
            type: "filter by Line",
            line: {
              line_name: value.line_name,
              line_id: value.line_id,
            },
          });
          value.line_id === filteringByLine?.line_id
            ? null
            : dispatch({ type: "reset", entity: "Machine" });
        }}
        getOptionLabel={(option) => option.line_name}
        isOptionEqualToValue={(option, value) =>
          option.line_id === value.line_id
        }
        sx={{ flex: 1, minWidth: 300 }}
        renderInput={(params) => (
          <TextField
            variant="filled"
            {...params}
            label={translate("select_line")}
          />
        )}
      />
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        size="small"
        options={uniqueMachineFilters}
        value={filteringByMachine}
        onChange={(e, value, reason) => {
          if (reason === "clear") {
            dispatch({ type: "reset", entity: "Machine" });
            return;
          }
          if (!value) return;
          dispatch({
            type: "filter by Machine",
            machine: {
              machine_name: value.machine_name,
              machine_id: value.machine_id,
            },
          });
        }}
        getOptionLabel={(option) => option.machine_name}
        isOptionEqualToValue={(option, value) =>
          option.machine_id === value.machine_id
        }
        sx={{ flex: 1, minWidth: 300 }}
        renderInput={(params) => (
          <TextField
            variant="filled"
            {...params}
            label={translate("select_machine")}
          />
        )}
      />
    </Stack>
  );
};
