import Highcharts from "highcharts/highstock";
import HC_exporting from "highcharts/modules/exporting";
import ExportData from "highcharts/modules/export-data";
import variablepie from "highcharts/modules/variable-pie";
import applyDrilldown from "highcharts/modules/drilldown";
import addSunburstModule from "highcharts/modules/sunburst";
import addHighchartsMore from "highcharts/highcharts-more";
import addHistogramBellCurveModule from "highcharts/modules/histogram-bellcurve";
import addParetoModule from "highcharts/modules/pareto";
import addBoostModule from "highcharts/modules/boost";
import addXRangeModule from "highcharts/modules/xrange";
import addGauge from "highcharts/modules/solid-gauge";
import addHeatmap from "highcharts/modules/heatmap";
import addBullet from "highcharts/modules/bullet";
import addAnnotations from "highcharts/modules/annotations";
import addNoDataToDisplay from "highcharts/modules/no-data-to-display";
import addBorderRadius from "highcharts-border-radius";
import { HighchartsProvider } from "react-jsx-highstock";
import { useEffect } from "react";
import { timezoneStore } from "@/store/useTimezoneStore";
import MomentTimeZone from "moment-timezone";
import Moment from "moment";
import { dateTimeFormatStore } from "@/store/useDateTimeFormat";
import { useTranslate } from "@/i18n/config";

HC_exporting(Highcharts);
ExportData(Highcharts);
variablepie(Highcharts);
applyDrilldown(Highcharts);
addSunburstModule(Highcharts);
addHighchartsMore(Highcharts);
addHistogramBellCurveModule(Highcharts);
addParetoModule(Highcharts);
addBoostModule(Highcharts);
addXRangeModule(Highcharts);
addGauge(Highcharts);
addHeatmap(Highcharts);
addBullet(Highcharts);
addBorderRadius(Highcharts);
addAnnotations(Highcharts);
addNoDataToDisplay(Highcharts);

MomentTimeZone();
Moment();
window.moment = Moment;

/**
 * There are some options like `marker` that are not overridden if used inside the plotOptions[series] object.
 * Remember to configure them in directly inside the component.
 */
Highcharts.setOptions({
  yAxis: {
    gridLineColor: "#333333",
    tickAmount: 5,
  },
  chart: { style: { fontFamily: "Montserrat" } },
  legend: {
    itemHoverStyle: { color: "lightblue" },
    itemStyle: { color: "white" },
  },
  exporting: {
    csv: {
      itemDelimiter: ",",
    },

    buttons: {
      contextButton: {
        menuItems: [
          "viewFullscreen",
          "separator",
          "downloadPNG",
          "downloadJPEG",
          "downloadPDF",
          "downloadSVG",
          "separator",
          "downloadCSV",
          "downloadXLS",
        ],
      },
    },
    chartOptions: {
      chart: {
        backgroundColor: "#0d1626",
      },
    },
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
    },
    scatter: {
      marker: {
        enabled: true,
      },
    },
  },
});

export function HighchartsCustomProvider({ children }: { children: React.ReactNode }) {
  const { timezone } = timezoneStore();
  const { dateFormat, timeFormat } = dateTimeFormatStore();
  const isEuropean = dateFormat === "en-GB";
  const is24Hour = timeFormat === "24h";
  const translate = useTranslate();

  const tooltipdDateTimeFormats = {
    day: isEuropean
      ? is24Hour
        ? "%A, %d/%m/%Y %H:%M:%S" // Europeo, 24 ore
        : "%A, %d/%m/%Y %l:%M:%S %p" // Europeo, AM/PM
      : is24Hour
      ? "%A, %m/%d/%Y %H:%M:%S" // Americano, 24 ore
      : "%A, %m/%d/%Y %l:%M:%S %p", // Americano, AM/PM

    hour: is24Hour
      ? "%H:%M" // 24 ore
      : "%l:%M %p", // AM/PM
  };

  useEffect(() => {
    const xAxisDate = is24Hour
      ? {
          minute: "%H:%M",
          hour: "%H:%M",
        }
      : {
          minute: "%l:%M %p",
          hour: "%l %p",
        };
    Highcharts.setOptions({
      lang: {
        noData: translate("user_feedback.no_data_to_display"),
      },
      time: {
        timezone,
      },
      tooltip: {
        xDateFormat: tooltipdDateTimeFormats.day,
        dateTimeLabelFormats: {
          day: tooltipdDateTimeFormats.day,
          hour: tooltipdDateTimeFormats.hour,
        },
      },
      xAxis: {
        dateTimeLabelFormats: {
          ...xAxisDate,
          day: isEuropean ? "%e %b" : "%b %e",
          hour: is24Hour ? "%l:%M" : "%l:%M %p",
        },
      },
    });
  }, [
    timezone,
    dateFormat,
    timeFormat,
    tooltipdDateTimeFormats.day,
    tooltipdDateTimeFormats.hour,
    isEuropean,
    is24Hour,
    translate,
  ]);

  return (
    <HighchartsProvider key={timezone} Highcharts={Highcharts}>
      {children}
    </HighchartsProvider>
  );
}
