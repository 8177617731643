import { SelectTimeSelection } from "@/components/SelectTimeSelection";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { Stack, Tab, Tabs } from "@mui/material";
import { useGetAlarmsTableData } from "./api/useGetAlarmsTableData";
import { AnalyticContainerSkeleton } from "../Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { useGetAlarmsColumnData } from "./api/useGetAlarmsColumnData";
import { useState } from "react";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { AlarmColumns } from "./components/AlarmColumns";
import { AlarmsSummaryTable } from "./components/AlarmsSummaryTable";
import { AlarmsDetailTable } from "./components/AlarmsDetailTable";

const SECTIONS = {
  STANDARD: 0,
  BLOCKING_ONLY: 1,
};

export const Alarms = () => {
  const translate = useTranslate();
  const [section, setSection] = useState(SECTIONS.STANDARD);
  const {
    data: columnData,
    isLoading: columnDataLoading,
    isError: columnDataError,
  } = useGetAlarmsColumnData();
  const {
    data: streamData,
    isLoading: streamDataLoading,
    isError: streamDataError,
  } = useGetAlarmsTableData();

  return (
    <>
      <MachineViewNavBar>
        <SelectTimeSelection />
      </MachineViewNavBar>
      <Stack gap={2}>
        <Tabs
          value={section}
          aria-label="alarms tabs"
          onChange={(_: React.SyntheticEvent, newValue: number) => {
            setSection(newValue);
          }}
        >
          <Tab
            value={SECTIONS.STANDARD}
            label={translate("configurable_oee.standard")}
          />
          <Tab
            value={SECTIONS.BLOCKING_ONLY}
            label={translate("alarms.blocking_only")}
          />
        </Tabs>

        {columnData ? (
          <>
            {section === SECTIONS.STANDARD && (
              <AlarmColumns alarmData={columnData.Result} />
            )}
            {section === SECTIONS.STANDARD && (
              <AlarmsSummaryTable data={columnData.Result} />
            )}
            {section === SECTIONS.BLOCKING_ONLY && (
              <AlarmColumns
                alarmData={columnData.Result.filter(
                  (alarm) => alarm.Family === 200 || alarm.Family === 201,
                )}
              />
            )}
            {section === SECTIONS.BLOCKING_ONLY && (
              <AlarmsSummaryTable
                data={columnData.Result.filter(
                  (alarm) => alarm.Family === 200 || alarm.Family === 201,
                )}
              />
            )}
          </>
        ) : columnDataLoading ? (
          <AnalyticContainerSkeleton />
        ) : columnDataError ? (
          <Card>{translate("user_feedback.an_error_occurred")}</Card>
        ) : null}
        {streamData ? (
          <>
            {section === SECTIONS.STANDARD && (
              <AlarmsDetailTable data={streamData.array} />
            )}
            {section === SECTIONS.BLOCKING_ONLY && (
              <AlarmsDetailTable
                data={streamData.array.filter(
                  (alarm) => alarm.Family === 200 || alarm.Family === 201,
                )}
              />
            )}
          </>
        ) : streamDataLoading ? (
          <AnalyticContainerSkeleton />
        ) : streamDataError ? (
          <Card>{translate("user_feedback.an_error_occurred")}</Card>
        ) : null}
      </Stack>
    </>
  );
};
