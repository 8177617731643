import { useTranslate } from "@/i18n/config";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { Card } from "@/components/Layout/Card";
import { ExportTable } from "../components/table_export";
import { useGetExport } from "../api/getExportData";

export function ExportPage({
  machineName,
  categories,
}: {
  machineName: string;
  categories: string[];
}) {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetExport({
    machineName,
    categories,
  });

  if (isLoading) {
    return <AnalyticContainerSkeleton />;
  }

  if (error) {
    return (
      <Card sx={{ height: 400 }}>
        {translate("user_feedback.an_error_occurred")}
      </Card>
    );
  }

  if (!data) {
    return (
      <Card sx={{ height: 400 }}>{translate("user_feedback.no_data")}</Card>
    );
  }

  const n = Object.keys(data).length;

  if (n === 0) {
    return (
      <Card sx={{ height: 400 }}>{translate("user_feedback.no_data")}</Card>
    );
  }

  return <ExportTable exportData={data} />;
}
