import { useAcoposContext } from "../context/acopos-context";

interface ReturnType {
  canRenderAnalytic: boolean;
  noRunsSelected: boolean;
  noLapsSelected: boolean;
  noSegmentSelected: boolean;
  noShuttlesSelected: boolean;
}

export const useCheckIfCanRenderAnalytic = ({
  hasRunsSelection,
  hasLapsSelection,
}: {
  hasRunsSelection: boolean;
  hasLapsSelection: boolean;
}): ReturnType => {
  let canRenderAnalytic = true;
  let noRunsSelected = false;
  let noLapsSelected = false;
  let noSegmentSelected = false;
  let noShuttlesSelected = false;
  const { runs, laps, activeSegment, shuttles } = useAcoposContext();

  let totalLapsLength = 0;
  for (const [, val] of Object.entries(laps)) {
    totalLapsLength += val.length;
  }

  if (hasRunsSelection && runs.length === 0) noRunsSelected = true;
  if (hasLapsSelection && totalLapsLength === 0) noLapsSelected = true;
  if (!activeSegment) noSegmentSelected = true;
  if (shuttles.length === 0) noShuttlesSelected = true;

  if (
    noRunsSelected ||
    noLapsSelected ||
    noSegmentSelected ||
    noShuttlesSelected
  )
    canRenderAnalytic = false;

  return {
    canRenderAnalytic,
    noRunsSelected,
    noLapsSelected,
    noSegmentSelected,
    noShuttlesSelected,
  };
};
