import { useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  ListItemButton,
  ListItemText,
  Collapse,
  List,
  Checkbox,
} from "@mui/material";
import { NoveltyAnomalyAnalytic } from "../../types";

interface NoveltyAnomalyListProps {
  listName: string;
  listItems: NoveltyAnomalyAnalytic[];
  selectedAnalytics: number[];
  setSelectedAnalytics: React.Dispatch<React.SetStateAction<number[]>>;
}

export const NoveltyAnomalyList: React.FC<NoveltyAnomalyListProps> = ({
  listName,
  listItems,
  selectedAnalytics,
  setSelectedAnalytics,
}) => {
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleItemClick = (item: number) => {
    if (selectedAnalytics.find((el) => el === item)) {
      setSelectedAnalytics((prevState) =>
        prevState.filter((el) => el !== item),
      );
    } else {
      setSelectedAnalytics((prevState) => [...prevState, item]);
    }
  };

  return (
    <Box>
      <ListItemButton key={listName} onClick={handleClick}>
        <ListItemText primary={listName} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {listItems.map((item) => (
            <ListItemButton
              key={item.id}
              sx={{ pl: 2 }}
              onClick={() => handleItemClick(item.id)}
            >
              <Checkbox
                checked={
                  selectedAnalytics.find((el) => el === item.id) ? true : false
                }
                color="primary"
              />
              <ListItemText primary={item.clientName} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </Box>
  );
};
