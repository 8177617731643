import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { QueryBuilder } from "@/utils/query-builder";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { useLanguage } from "@/i18n/config";

type JamsDetailResponse = ThingworxError | Jams;

const JamsDetail = z.object({
  ErrorDescription: z.string(),
  Error: z.number(),
  Timestamp: z.number(),
  ZoneDescription: z.string(),
});
export type JamsDetail = z.infer<typeof JamsDetail>;

const Jams = z.object({
  response: z.literal(true),
  Result: z.array(JamsDetail),
  categories: z.array(z.string()),
});
type Jams = z.infer<typeof Jams>;

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
  dateStart?: DateTime;
  dateEnd?: DateTime;
  language_id: string;
};
// questa si ricollega a getZoneList2, che è appunto da eliminare ed includere i dati, da questa api si genera
// sia la tabella che lo scatterplot delle jams
export const useGetJamsDetail = () => {
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();
  const { timeSelection, dates } = useTimeSelection();
  const language = useLanguage();

  const query = QueryBuilder.buildWithCondition({
    baseQuery: ["jams-detail", machine?.machine, timeSelection, language],
    condition: timeSelection === "Custom",
    query: [dates?.dateStart, dates?.dateEnd],
  });
  const payload = {
    machineName: machine!.machine || "",
    timeSelection,
    language_id: language,
  };

  return useQuery<Jams>({
    queryKey: query,
    queryFn: async () => {
      const response = await FetchClient<Payload, JamsDetailResponse>({
        appKey,
        url: "dig.c.jam_thing/Services/getDetail",
        payload:
          timeSelection === "Custom"
            ? {
                ...payload,
                dateStart: dates?.dateStart,
                dateEnd: dates?.dateEnd,
              }
            : payload,
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return Jams.parse(response);
    },
  });
};
