import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useSetIsPause = () => {
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ isPause }: { isPause: boolean }) => {
      await FetchClient<{ machineName: string; isPause: boolean }, null>({
        appKey,
        payload: {
          machineName: machine!.machine,
          isPause,
        },
        url: "dig.c.acceptanceTest_thing/Services/setPause",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["acceptance-test-data", machine!.machine],
      });
      queryClient.invalidateQueries({
        queryKey: ["isPause-check", machine?.machine],
      });
    },
  });
};
