import { ToggleButtonGroup, ToggleButton } from "@mui/material";

interface VariableSelectionProps {
  variables: string[];
  selectedVariable: string;
  setSelectedVariable: React.Dispatch<React.SetStateAction<string>>;
}

export const VariableSelection = ({
  variables,
  selectedVariable,
  setSelectedVariable,
}: VariableSelectionProps) => {
  const handleChange = (
    _: React.MouseEvent<HTMLElement>,
    newVariable: string,
  ) => {
    setSelectedVariable(newVariable);
  };

  return (
    <ToggleButtonGroup
      value={selectedVariable}
      exclusive
      onChange={handleChange}
      aria-label="Variables"
      sx={{ backgroundColor: "#1F293F" }}
    >
      {variables.map((variable) => (
        <ToggleButton key={variable} value={variable}>
          {variable}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
