import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useSetManualCorrection = () => {
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      related,
      unrelated,
    }: {
      related: string;
      unrelated: string;
    }) => {
      await FetchClient<
        { machineName: string; related: string; unrelated: string },
        null
      >({
        appKey,
        payload: {
          machineName: machine!.machine,
          related,
          unrelated,
        },
        url: "dig.c.acceptanceTest_thing/Services/setManualCorrection",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["acceptance-test-data", machine!.machine],
      });
    },
  });
};
