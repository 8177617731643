import { motion } from "framer-motion";
import { IconProps } from "../../types";

const svgVariants = {
  start: {
    opacity: 0,
    pathLength: 0,
  },
  finished: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 1.4,
      ease: "easeInOut",
    },
  },
};

export const SentinelLogo: React.FC<IconProps> = ({
  dimension = "24",
  color,
}) => (
  <svg
    style={{
      width: `${dimension}px`,
      height: `${dimension}px`,
    }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
  >
    <motion.path
      d="M12 5.96037L11.2079 6.05938L10.7129 6.15839L10.1188 6.35641L9.87129 6.45542L9.42575 6.65344L9.02971 6.85146L8.53466 7.14849L8.13862 7.44552L7.64357 7.84156L7.34654 8.13859L6.9505 8.63364L6.5 9.28216L6.17822 10L6 10.5L5.86139 11.375V11.5905V12.099V12.2425L5.91089 12.8911L5.98515 13.2871L6.15842 13.8812L6.25743 14.1782L6.55446 14.7723L6.85149 15.2673L7.14852 15.6633L7.54456 16.1584L7.84159 16.4554L8.03961 16.6534L8.53466 17.0495L9.02971 17.3465L9.82179 17.7426L10.2673 17.8911L10.8119 18.0396L11.604 18.1386H11.9505H12.396L13 18.0891L13.5 18.0396L14 17.9406"
      variants={svgVariants}
      initial="start"
      animate="finished"
      strokeWidth="2.4"
    />
    <motion.path
      d="M12.099 2L13.1881 2.09901L13.9802 2.19802L14.9703 2.39604L15.3663 2.49505L15.6634 2.59406L16.1584 2.79208L17.1485 3.28713L18.0396 3.88119L18.4356 4.17822L19.0297 4.67327L19.4257 5.06931L19.9208 5.66337L20.3168 6.15842L20.6138 6.65347L20.9109 7.14851L21.1089 7.54455L21.2574 7.94059L21.4059 8.33663L21.703 9.32673L21.901 10.3168L22 11.3069V12.396L21.901 13.6832L21.703 14.6733L21.4059 15.6634L21.0099 16.6535L20.4158 17.6436L19.8218 18.4356L19.1287 19.2277L18.1386 20.1188L17.0495 20.8119L15.7624 21.4059L14.5742 21.802L13.1881 22H11.703L10.5148 21.901L9.67326 21.7525L8.93068 21.505L7.64355 20.9109L6.65345 20.3168L5.56435 19.5248L4.57425 18.5347L3.78217 17.4455L3.18811 16.3564L2.69306 15.1683L2.29702 13.8812L2.19801 13.3366L2.099 12.5V11"
      variants={svgVariants}
      initial="start"
      animate="finished"
      strokeWidth="3.3"
    />
  </svg>
);
