import { SERVER_URL } from "@/config/index";
import { useFirebaseContext } from "@/context/firebase-context";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type EdgePcListResponse = ThingworxError | EdgePcListResult;

const PC = z.object({
  edgePc: z.string(),
  isConnected: z.boolean(),
  lastConnection: z.number(),
  machine: z.record(z.string()),
});
export type PC = z.infer<typeof PC>;

const EdgePcListResult = z.object({
  listOfEdgePc: z.array(PC),
  response: z.literal(true),
});

type EdgePcListResult = z.infer<typeof EdgePcListResult>;

export const useGetEdgePcList = () => {
  const { appKey } = useFirebaseContext();
  const url = "dig.c.edgePc_thing/Services/getEdgePcList";
  return useQuery<EdgePcListResult>({
    queryKey: ["a4GateMonitoring-edgePc-list"],
    queryFn: async () => {
      const res = await fetch(SERVER_URL + url, {
        method: "POST",
        headers: {
          appkey: appKey,
          "content-type": "application/json",
          accept: "application/json",
          "x-thingworx-session": "true",
        },
      });
      const response = (await res.json()) as EdgePcListResponse;
      if (!response.response) {
        throw new Error(response.errorString);
      }
      const validatedResponse = EdgePcListResult.parse(response);
      return validatedResponse;
    },
  });
};
