import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { IntellectaError, IntellectaVersion } from "../types";
import { intellectaClient } from "./intellectaClient";

type Response = IntellectaError | Result;

type Payload = {
  user: string;
  version: IntellectaVersion;
  profile: string;
};

const result = z.literal("OK");

type Result = z.infer<typeof result>;

export const useSetActiveProfile = () => {
  const queryClient = useQueryClient();
  const { user } = useFirebaseContext();
  const email = user?.email || "";

  return useMutation({
    mutationFn: async ({
      version,
      profile,
    }: {
      version: IntellectaVersion;
      profile: string;
    }) => {
      const response = await intellectaClient<Payload, Response>({
        url: "/set_active_profile",
        payload: { user: email, version, profile },
      });

      const validatedResponse = result.parse(response);
      return validatedResponse;
    },
    onSettled: (data) => {
      if (data === "OK") {
        //* per scaricare di nuovo la configurazione dell'utente
        queryClient.invalidateQueries({
          queryKey: ["intellecta-config", email],
        });
      } else {
        toast.error("An error occurred");
      }
    },
  });
};
