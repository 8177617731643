import { Button, Stack, Typography } from "@mui/material";
import { SentinelLogo } from "@/components/Icons/SentinelLogo";
import { Component } from "react";

export class ErrorBoundary extends Component {
  children;
  state: { hasError: boolean };
  constructor(props: { children: JSX.Element }) {
    super(props);
    this.children = props.children;
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch() {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Stack
          gap={6}
          sx={{ width: "100vw", height: "100vh", backgroundColor: "#1F293F" }}
          textAlign="center"
          justifyContent="center"
          alignItems="center"
        >
          <SentinelLogo color="#fff" dimension={128} />
          <Typography variant="h4" color="white">
            Oops! Something went wrong.
          </Typography>
          <Typography variant="h5" color="white">
            We are aware of the issue and are working to fix it. In the
            meantime, please try refreshing the page.
          </Typography>
          <Stack direction="row" gap={2}>
            <Button
              variant="contained"
              onClick={() => window.location.reload()}
            >
              Refresh
            </Button>
          </Stack>
        </Stack>
      );
    }

    return this.children;
  }
}
