import { XrangePointOptionsObject } from "highcharts";
import { ImputationLogData } from "../types";

export const parseImputationsLogData = (
  data: ImputationLogData[]
): {
  categories: string[];
  chartData: XrangePointOptionsObject[];
} => {
  const categories: string[] = [];
  const chartData: XrangePointOptionsObject[] = [];

  data.forEach((imputation) => {
    const { start, end, category, color, subCategory } = imputation;
    if (!categories.includes(category)) categories.push(category);
    chartData.push({
      name: category,
      x: start,
      x2: end,
      y: categories.indexOf(category),
      color,
      custom: { subCategory },
    });
  });

  return { categories, chartData };
};
