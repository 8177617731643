import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useCustomTimeRange } from "../store/useCustomTimeRange";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type ScaleDeviationResponse = ThingworxError | ScaleDeviationResult;

const ScaleDeviationData = z.object({
  name: z.string(),
  netData: z.object({
    meanData: z.array(z.array(z.number())),
    sigma1Data: z.array(z.array(z.number())),
    sigma2Data: z.array(z.array(z.number())),
    sigma3Data: z.array(z.array(z.number())),
    scatterData: z
      .array(
        z.object({
          x: z.number(),
          y: z.number(),
          modality: z.string().optional(),
        }),
      )
      .optional(),
    benchmark: z
      .object({
        minWarn: z.number().optional(),
        minErr: z.number().optional(),
        maxWarn: z.number().optional(),
        maxErr: z.number().optional(),
      })
      .optional(),
  }),
  tareData: z.object({
    meanData: z.array(z.array(z.number())),
    sigma1Data: z.array(z.array(z.number())),
    sigma2Data: z.array(z.array(z.number())),
    sigma3Data: z.array(z.array(z.number())),
    scatterData: z
      .array(
        z.object({
          x: z.number(),
          y: z.number(),
          modality: z.string().optional(),
        }),
      )
      .optional(),
  }),
});

export type ScaleDeviationData = z.infer<typeof ScaleDeviationData>;

const ScaleDeviationResult = z.object({
  response: z.literal(true),
  data: z.array(ScaleDeviationData),
});

export type ScaleDeviationResult = z.infer<typeof ScaleDeviationResult>;

interface Payload {
  machineName: string;
  dateStart: DateTime;
  dateEnd: DateTime;
}

export const useGetScaleDeviationData = () => {
  const { machine } = useMachineContext();

  const { dateStart, dateEnd } = useCustomTimeRange();

  const { appKey } = useFirebaseContext();

  return useQuery<ScaleDeviationData[]>({
    queryKey: ["scale-deviation", machine?.machine, dateStart, dateEnd],
    queryFn: async () => {
      const response = await FetchClient<Payload, ScaleDeviationResponse>({
        appKey,
        payload: {
          dateStart,
          dateEnd,
          machineName: machine?.machine || "",
        },
        url: "dig.c.fillerAdvancedAnalytics_thing/Services/getBalancesAnalysisData",
      });

      if (!response.response) throw new Error(response.errorString);

      const validatedResponse = ScaleDeviationResult.parse(response);

      return validatedResponse.data;
    },
  });
};
