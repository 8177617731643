import { useFirebaseContext } from "@/context/firebase-context";
import { ThingworxError } from "src/types/error";
import { FetchClient } from "@/services/ApiClient";
import { ConditionConfig, ConditionEdit, PropertyType } from "../types";
import { useCallback } from "react";

type Response = ThingworxError | { response: true };

type Payload = {
  property: string;
  description: string;
  alertType: string;
  priority: number;
  enabled: boolean;
  propertyType: PropertyType;
  units: string;
  limitInclusive: boolean;
  value_1: string;
  value_2?: string;
  ruleName: string;
  duration: number;
  cumulative: boolean;
  machineName: string;
  rate: number;
  propertyNameClient: string;
  isActiveDuration: boolean;
};

export const useUpdateCondition = () => {
  const { appKey } = useFirebaseContext();
  const updateConditions = useCallback(
    ({
      conditions,
      machineName,
    }: {
      conditions: ConditionEdit[];
      machineName: string;
    }): Promise<ConditionConfig[]> => {
      return Promise.all(
        conditions.map(async (condition) => {
          const response = await FetchClient<Payload, Response>({
            appKey,
            payload: {
              ruleName: condition.name,
              machineName,
              alertType: condition.operator,
              cumulative: condition.isCumulative,
              description: "",
              duration: condition.duration,
              enabled: true,
              isActiveDuration: condition.isActiveDuration,
              limitInclusive: false,
              priority: 0,
              property: condition.property,
              propertyNameClient: condition.propertyNameClient,
              propertyType: condition.propertyType,
              rate: condition.rate,
              units: condition.uom,
              value_1: condition.value1.toString(),
              value_2: condition.value2
                ? condition.value2.toString()
                : undefined,
            },
            url: "dig.c.event_thing/Services/updateAlertOneInputType",
          });
          if (!response.response) throw new Error(response.errorString);
          return ConditionConfig.parse(response);
        }),
      );
    },
    [],
  );

  return { updateConditions };
};
