import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { Shift } from "../../types";

type SpeedLossesResponse = ThingworxError | ThingworxSpeedLossesResult;

/**
 * type definitions used as the shape in which
 * the data that comes from the API needs to be transformed.
 * This problems arises because the API returns the data in a different shape, with keys that have blank spaces.
 *
 * @todo refactor thingworx API to return data in a more usable shape so that we can remove this transformation step
 */

const SpeedLossesData = z.object({
  extraCapacityLoss: z.array(z.array(z.number())),
  lineLoss: z.array(z.array(z.number())),
  interactionLoss: z.array(z.array(z.number())),
  machineLoss: z.array(z.array(z.number())),
});

type SpeedLossesData = z.infer<typeof SpeedLossesData>;

const SpeedLossesResult = z.object({
  data: z.array(SpeedLossesData),
  chartName: z.string(),
  configuration: z.string(),
  response: z.literal(true),
  shiftList: z.array(Shift),
});

type SpeedLossesResult = z.infer<typeof SpeedLossesResult>;

/**
 * Type definitions for data that comes from the API.
 */

const ThingworxSpeedLossesData = z.object({
  "Extra Capacity Loss": z.array(z.array(z.number())),
  "Line Loss": z.array(z.array(z.number())),
  "Interaction Loss": z.array(z.array(z.number())),
  "Machine Loss": z.array(z.array(z.number())),
});

type ThingworxSpeedLossesData = z.infer<typeof ThingworxSpeedLossesData>;

const ThingworxSpeedLossesResult = z.object({
  data: z.array(ThingworxSpeedLossesData),
  chartName: z.string(),
  configuration: z.string(),
  response: z.literal(true),
  shiftList: z.array(Shift),
});

type ThingworxSpeedLossesResult = z.infer<typeof ThingworxSpeedLossesResult>;

/**  */

type Payload = {
  machineName: string;
  dateStart: DateTime;
  dateEnd: DateTime;
  timeSelection: TimeSelection;
};

export const useGetSpeedLossesSelector = () => {
  const { timeSelection, dates } = useTimeSelection();
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();

  return useQuery<SpeedLossesResult>({
    queryKey: [
      "machinePerformance-sentinel-SpeedLossesSelector",
      timeSelection,
      machine?.machine,
      dates.dateStart,
      dates.dateEnd,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, SpeedLossesResponse>({
        appKey,
        payload: {
          machineName: machine?.machine || "",
          dateStart: dates.dateStart,
          dateEnd: dates.dateEnd,
          timeSelection: timeSelection,
        },
        url: "dig.c.machinePerformance_thing/Services/getSpeedLossesSelector",
      });

      if (!response.response) throw new Error(response.errorString);

      const validatedResponse = ThingworxSpeedLossesResult.parse(response);
      return {
        ...validatedResponse,
        data: validatedResponse.data.map((d) => ({
          extraCapacityLoss: d["Extra Capacity Loss"],
          lineLoss: d["Line Loss"],
          interactionLoss: d["Interaction Loss"],
          machineLoss: d["Machine Loss"],
        })),
      };
    },
    refetchInterval: timeSelection === "Custom" ? false : 1000 * 20,
  });
};
