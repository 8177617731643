import { useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { useFirebaseContext } from "@/context/firebase-context";
import { INTELLECTA_ENDPOINT } from "@/config/index";
import { auth_token } from "./auth";

type Payload = {
  user: string;
  rating: number;
  user_notes: string;
  message_id: number; //? univoco per ogni messaggio
};

type Response = "OK" | "ERROR";

export const useLeaveFeedback = () => {
  const { user } = useFirebaseContext();
  const email = user?.email || "";

  return useMutation<Response, Error, Omit<Payload, "user">>({
    mutationFn: async (payload) => {
      const response = await fetch(INTELLECTA_ENDPOINT + "/feedback", {
        headers: {
          Authorization: `Basic ${auth_token}`,
        },
        body: JSON.stringify({ ...payload, user: email }),
        method: "POST",
      });

      if (!response.ok) {
        throw new Error("An error occurred");
      }

      const data = (await response.json()) as Response;

      return data;
    },
    onError: () => {
      toast.error("An error occurred");
    },
  });
};
