import { useState, useEffect, ChangeEvent } from "react";
import {
  Box,
  Button,
  Stack,
  Switch,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import { pink } from "@mui/material/colors";
import { sentryModeStore } from "../../store/sentry-mode-store";
import { parseSecondsLeft } from "../../utilities/parseSecondsLeft";

const PinkSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: pink[300],
    "&:hover": {
      backgroundColor: alpha(pink[300], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: pink[300],
  },
}));

export const SentryMode = () => {
  const [isActivating, setIsActivating] = useState(false);
  const { isActive, setIsActive, secondsLeft, resetTimer, clearTimer } =
    sentryModeStore();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsActivating(event.target.checked);
    if (event.target.checked) {
      resetTimer();
    } else {
      setIsActive(false);
      clearTimer();
    }
  };

  const handleEnableNowClick = () => {
    if (isActivating) {
      clearTimer();
      setIsActive(true);
    }
    if (isActive) {
      resetTimer();
    }
  };

  useEffect(() => {
    if (secondsLeft === 0 && isActivating) {
      clearTimer();
      setIsActive(true);
    }
  }, [secondsLeft, isActivating, setIsActive, clearTimer]);

  return (
    <Stack p={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Button
          onClick={handleEnableNowClick}
          sx={{ color: isActive ? pink[300] : undefined }}
        >
          Sentry Mode
        </Button>
        <PinkSwitch
          checked={isActivating}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Box>
      {!isActive && isActivating && secondsLeft > 0 && (
        <>
          <Typography variant="caption">
            Sentry Mode will activate in {parseSecondsLeft(secondsLeft)}.
          </Typography>
          <Typography
            variant="caption"
            sx={{ cursor: "pointer", color: pink[300] }}
            onClick={handleEnableNowClick}
          >
            Enable now
          </Typography>
        </>
      )}
    </Stack>
  );
};
