import { useFirebaseContext } from "@/context/firebase-context";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { FetchClient } from "@/services/ApiClient";
import { PropertyType } from "../types";
import { z } from "zod";
import { useLanguage } from "@/i18n/config";

const variable = z.object({
  category: z.string(),
  propertyName: z.string(),
  propertyNameClient: z.string(),
  propertyType: PropertyType,
  uom: z.array(z.string()),
});

const variablesResponse = z.object({
  response: z.literal(true),
  data: z.array(variable),
});

export type VariablesResponse = z.infer<typeof variablesResponse>;

export type Variable = z.infer<typeof variable>;

type Response = ThingworxError | VariablesResponse;

type Payload = {
  machineName: string;
  language_id: string;
};

export const useGetVariableList = (machineName: string) => {
  const { appKey } = useFirebaseContext();
  const language = useLanguage();

  return useQuery<Variable[]>({
    queryKey: ["events-machine-variables", machineName, language],
    queryFn: async () => {
      const response = await FetchClient<Payload, Response>({
        appKey,
        payload: { machineName, language_id: language },
        url: "dig.c.event_thing/Services/getAlertListOfVariables",
      });
      if (!response.response) throw new Error(response.errorString);

      return variablesResponse.parse(response).data;
    },
  });
};
