export const fileNameFactory = ({
  machine,
  date,
  name,
}: {
  machine?: string;
  date?: string;
  name: string;
}) => {
  const machineName = machine ? `${machine}-` : "";
  const dateName = date ? `${date}-` : "";

  return `${machineName}${dateName}${name}`;
};
