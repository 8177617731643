import { XrangePointOptionsObject } from "highcharts/highstock";
import { XRangeChart } from "@/components/highcharts/xrange/XRange";
import { getCustomHeightFromN } from "@/features/StateLog/utils/getCustomHeightFromN";
import { AnomaliesData } from "../api/getAnomaliesData";
import { Card } from "@/components/Layout/Card";

const mapXrangeData = ({
  data,
}: {
  data: AnomaliesData[];
}): { categories: string[]; chartData: XrangePointOptionsObject[] } => {
  const chartData: XrangePointOptionsObject[] = [];
  const categories: string[] = [];

  data.forEach((occurrence) => {
    if (!categories.includes(occurrence.reason))
      categories.push(occurrence.reason);
  });

  data.forEach((occurrence) => {
    const index = categories.indexOf(occurrence.reason);
    if (index !== -1) {
      chartData.push({
        name: occurrence.reason,
        x: occurrence.ts_start,
        x2: occurrence.ts_end,
        y: index,
        color: occurrence.is_resolved ? "#00b00c" : "#D62422",
      });
    }
  });

  return { categories, chartData };
};

export function AnomaliesXRange({
  data,
  onSetExtremes,
}: {
  data: AnomaliesData[];
  onSetExtremes: ({ min, max }: { min: number; max: number }) => void;
}) {
  const { categories, chartData } = mapXrangeData({ data });

  return (
    <Card>
      <XRangeChart
        key={"anomalies-log-chart-" + data.length}
        enableStepZoomOut={true}
        categories={categories}
        customHeight={getCustomHeightFromN(categories.length)}
        data={chartData}
        title=""
        xAxisOptions={{
          events: {
            afterSetExtremes: (e) => {
              onSetExtremes({ min: e.min, max: e.max });
            },
          },
        }}
      />
    </Card>
  );
}
