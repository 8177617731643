import {
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Card } from "@/components/Layout/Card";
import {
  Languages,
  useChangeLanguage,
  useLanguage,
  useTranslate,
} from "@/i18n/config";

export const Language = () => {
  const lang = useLanguage();
  const changeLanguage = useChangeLanguage();
  const translate = useTranslate();

  return (
    <Card>
      <CardContent>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            {translate("language")}
          </InputLabel>

          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={lang}
            label={translate("language")}
            variant="outlined"
            onChange={(e) => {
              changeLanguage(e.target.value as Languages);
            }}
          >
            <MenuItem value={"en"}>{translate("languages.en")}</MenuItem>
            <MenuItem value={"es"}>{translate("languages.es")}</MenuItem>
            <MenuItem value={"de"}>{translate("languages.de")}</MenuItem>
            <MenuItem value={"it"}>{translate("languages.it")}</MenuItem>
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  );
};
