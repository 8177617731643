import { useQuery } from "@tanstack/react-query";
import { useMachineContext } from "@/context/machine-context";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useFirebaseContext } from "@/context/firebase-context";
import { queryKeyBuilder } from "../utils/alarmsQueryBuilder";
import { FetchClient } from "@/services/ApiClient";
import { z } from "zod";
import { useLanguage } from "@/i18n/config";

type AlarmsDataResponse =
  | {
      Result: "No Data";
    }
  | AlarmsColumnData;

export const AlarmData = z.object({
  ErrorDescription: z.string(),
  MTTR: z.number(),
  CumulativeCount: z.number(),
  CountPercentage: z.number(),
  CumulativeDuration: z.number(),
  Error: z.number(),
  Family: z.number(),
  Duration: z.number(),
  Count: z.number(),
  DurationPercentage: z.number(),
  MTBF: z.number(),
});

export type AlarmData = z.infer<typeof AlarmData>;

export const AlarmsColumnData = z.object({
  InteractionLosses: z.number(),
  LineLosses: z.number(),
  Result: z.array(AlarmData),
});

export type AlarmsColumnData = z.infer<typeof AlarmsColumnData>;

export interface TransformedAlarmData {
  ErrorDescription: string;
  MTBFduration: number;
  CumulativeCount: number;
  CountPercentage: number;
  CumulativeDuration: number;
  Error: number;
  Family: number;
  Duration: number;
  Count: number;
  timeAmountPercentage: number;
  MTTRduration: number;
}

export type RequestPayload = {
  dateStart?: number;
  dateEnd?: number;
  language_id: string;
};

type Payload = {
  dateStart?: number;
  dateEnd?: number;
  timeSelection: TimeSelection;
  machineName: string;
  language_id: string;
};

export const useGetAlarmsColumnData = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();

  const { timeSelection, dates } = useTimeSelection();
  const language = useLanguage();

  const dateStart = timeSelection === "Custom" ? dates.dateStart.toMillis() : undefined;
  const dateEnd = timeSelection === "Custom" ? dates.dateEnd.toMillis() : undefined;

  return useQuery<AlarmsColumnData>({
    queryKey: [
      ...queryKeyBuilder({
        queryName: "alarms-column-data",
        payload: {
          dateStart: dates.dateStart.toMillis(),
          dateEnd: dates.dateEnd.toMillis(),
          language_id: language,
        },
        timeSelection,
      }),
      machine?.machine,
      language,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, AlarmsDataResponse>({
        appKey,
        url: "dig.c.error_thing/Services/getData",
        payload: {
          dateStart,
          dateEnd,
          timeSelection,
          machineName: machine?.machine ?? "",
          language_id: language,
        },
      });

      if (response.Result === "No Data") {
        return {
          InteractionLosses: 0,
          LineLosses: 0,
          Result: [],
        };
      }

      const validatedResponse = AlarmsColumnData.parse(response);

      return validatedResponse;
    },
    refetchInterval: timeSelection === "Custom" ? false : 1000 * 30,
  });
};
