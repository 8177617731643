import { useState } from "react";
import { BatchDiagnosticAsset } from "../../types";
import { Card } from "@/components/Layout/Card";
import {
  Typography,
  ListItemButton,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { BatchDiagnosticChart } from "./BatchDiagnosticChart";
import { Check, PriorityHigh } from "@mui/icons-material";
import { EVALUATION_COLORS } from "../../utils/evaluationColors";

export const BatchDiagnosticAssetSelection = ({
  assets,
  analyticId,
}: {
  assets: BatchDiagnosticAsset[];
  analyticId: number;
}) => {
  const [selectedAssets, setSelectedAssets] = useState<number[]>([]);

  const handleItemClick = (item: number) => {
    if (selectedAssets.find((el) => el === item)) {
      setSelectedAssets((prevState) => prevState.filter((el) => el !== item));
    } else {
      setSelectedAssets((prevState) => [...prevState, item]);
    }
  };

  return (
    <>
      <Card sx={{ width: 320, height: 400, overflowY: "auto" }}>
        <Typography variant="h6">Assets</Typography>
        {assets.map((asset) => (
          <ListItemButton
            key={"list-item-" + analyticId + asset.id}
            sx={{ pl: 2 }}
            onClick={() => handleItemClick(asset.id)}
          >
            <Checkbox
              checked={
                selectedAssets.find((el) => el === asset.id) ? true : false
              }
              color="primary"
            />
            <ListItemText primary={asset.name} />
            {asset.hasWarn ? (
              <PriorityHigh
                sx={{ color: EVALUATION_COLORS.warning.selected }}
              />
            ) : (
              <Check sx={{ color: EVALUATION_COLORS.good.selected }} />
            )}
          </ListItemButton>
        ))}
      </Card>
      {selectedAssets.length > 0 && (
        <BatchDiagnosticChart
          key={"batch-diagnostic-chart-analytic" + analyticId}
          analyticId={analyticId}
          assetIds={selectedAssets}
        />
      )}
    </>
  );
};
