import { DeleteOutline } from "@mui/icons-material";
import {
  Menu,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Handle, useNodeId, useUpdateNodeInternals } from "reactflow";

import {
  useNodeEditDispatch,
  useNodeEditorContext,
} from "../context/NodeEditorContextProvider";
import { useHandleStyle } from "../hooks/useHandleStyle";
import { useState } from "react";

export const EditableHandle = (
  props: Partial<React.ComponentProps<typeof Handle>>,
) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const nodeId = useNodeId();
  const updateNodeInternals = useUpdateNodeInternals();

  const dispatch = useNodeEditDispatch();
  const state = useNodeEditorContext();
  /** throws if "" gets passed */
  const { position, style } = useHandleStyle(props.id ?? "");

  if (!props.id) return null;

  const edgeId = props.id as keyof typeof state.handles;

  const edgeInfo = state.handles[edgeId];

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Handle
        {...props}
        type={!edgeInfo.enabled ? "source" : edgeInfo.type}
        position={position}
        onClick={handleClick}
        style={{
          ...style,
          cursor: "pointer",
          background: !edgeInfo.enabled
            ? "black"
            : edgeInfo.type === "source"
            ? "none"
            : "white",
          border: !edgeInfo.enabled
            ? "none"
            : edgeInfo.type === "source"
            ? "1px solid white"
            : "none",
          boxShadow: "2px 2xp 2px 2px rgba(0,0,0,0.2)",
        }}
      />

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem>
          <FormControl>
            <FormLabel id="connection-type-group-label">
              Connection type
            </FormLabel>
            <RadioGroup
              aria-labelledby="connection-type-group-label"
              value={edgeInfo.enabled ? edgeInfo.type : "none"}
              onChange={(event) => {
                dispatch({
                  type:
                    event.target.value === "source"
                      ? "set edge type as source"
                      : event.target.value === "target"
                      ? "set edge type as target"
                      : "remove edge",
                  handle: edgeId,
                });
                if (nodeId) {
                  updateNodeInternals(nodeId);
                }
              }}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="source"
                control={<Radio size="small" />}
                label="Output"
              />
              <FormControlLabel
                value="target"
                control={<Radio size="small" />}
                label="Input"
              />

              <FormControlLabel
                value="none"
                control={<Radio size="small" />}
                label="None"
                sx={{ display: "none" }}
              />
            </RadioGroup>
          </FormControl>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            dispatch({ type: "remove edge", handle: edgeId });
          }}
        >
          <ListItemIcon>
            <DeleteOutline fontSize="small" />
          </ListItemIcon>
          <ListItemText>Remove connection point</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
