import { ParetoChart } from "@/components/highcharts/pareto/ParetoChart";
import { Card } from "@/components/Layout/Card";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { useGetRejectParetoData } from "../api/useGetParetoData";
import { useTranslate } from "@/i18n/config";

export const OccurrencesPareto = () => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetRejectParetoData();

  if (isLoading) {
    return <AnalyticContainerSkeleton />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  const filteredData = data.filter((cause) => cause.occurrences !== 0);

  return (
    <Card>
      <ParetoChart
        title="Pareto"
        categories={filteredData?.map((value) => value.cause)}
        scrollbarEnabled={true}
        xAxisMax={10}
        column={{
          series: {
            type: "column",
            data: filteredData?.map((value) => ({
              name: value.cause,
              description: value.description,
              y: value.occurrences,
            })),
          },
          yAxisTitleOptions: { text: translate("occurrences") },
        }}
        pareto={{
          yAxisTitleOptions: { text: `${translate("occurrences")} (%)` },
        }}
        tooltip={{
          enabled: true,
          options: {
            formatter: function () {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const options = this as unknown as any;
              return `
                ${options.points[1].point.description}<br>
                <span style='color: ${
                  options.points[0].color
                }'>● </span>Pareto: <b>${options.y.toFixed(2)} %</b><br>
                <span style='color: ${
                  options.points[1].color
                }'>● </span>Rejects: <b>${options.points[1].y}</b><br>
              `;
            },
          },
        }}
      />
    </Card>
  );
};
