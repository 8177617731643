import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { Timelapse, contextInfo } from "../../types";
import { useAcoposContext } from "../../context/acopos-context";
import { useTimeSelection } from "@/store/useTimeSelection";

export type GetDeviancesDataResponse = ThingworxError | GetDeviancesDataResult;

const boxPlotDataSchema = z.object({
  boxPlotData: z.array(z.array(z.number())),
  categories: z.array(
    z.object({
      timestamp: z.number(),
      count: z.number(),
    }),
  ),
  scatterData: z.array(z.array(z.number())),
  trendData: z.array(z.array(z.number())),
});

export type BoxPlotDataSchema = z.infer<typeof boxPlotDataSchema>;

const resSchema = z.object({
  analyticName: z.string(),
  chartType: z.literal("box"),
  metricType: z.literal("timestamp"),
  data: boxPlotDataSchema,
  contextInfo: contextInfo,
});

export type ResSchema = z.infer<typeof resSchema>;

const deviancesData = z.object({
  analyticName: z.string(),
  data: z.array(z.array(z.number())),
  contextInfo: contextInfo,
});

export type DeviancesData = z.infer<typeof deviancesData>;

type Payload = {
  machineName: string;
  dateStart: number;
  dateEnd: number;
  segment: number;
  shuttles: number[];
  timelapse: Timelapse;
};

const getDeviancesDataResult = z.object({
  response: z.literal(true),
  data: z.array(resSchema),
});

export type GetDeviancesDataResult = z.infer<typeof getDeviancesDataResult>;

export const useGetDeviancesData = ({
  timelapse,
}: {
  timelapse: Timelapse;
}) => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const { dates } = useTimeSelection();
  const { activeSegment, shuttles } = useAcoposContext();

  return useQuery<GetDeviancesDataResult>({
    queryKey: [
      "deviances-analytics-data",
      machine!.machine,
      dates.dateEnd,
      dates.dateStart,
      activeSegment,
      JSON.stringify(shuttles),
      timelapse,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, GetDeviancesDataResponse>({
        appKey,
        payload: {
          machineName: machine!.machine,
          dateStart: dates.dateStart.toMillis(),
          dateEnd: dates.dateEnd.toMillis(),
          segment: activeSegment!,
          shuttles,
          timelapse,
        },
        // url: "dig.c.acopostrak_thing/Services/getDeviancesData",
        url: "dig.c.acoposTrack_thing/Services/getDeviancesData",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = getDeviancesDataResult.parse(response);

      return validatedResponse;
    },
  });
};
