import {
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Auth, onAuthStateChanged, User } from "firebase/auth";
import { auth, db } from "@/lib/sentinel-app";
import { useNavigate } from "react-router-dom";
import { environment } from "../config";
import * as Sentry from "@sentry/react";

import { get, ref } from "firebase/database";

type ContextState = {
  user: User | null;
  authService: Auth;
  showLoader: boolean;
  appKey: string;
  userRole: UserRole;
  updateAppKey: (appKey: string) => void;
  platformLvl: number;
  company: string;
};

export enum UserRole {
  SUPER_USER_SENTINEL = 0,
  SUPER_USER = 2,
  USER_ADMIN = 3,
  USER = 4,
}

const FirebaseAuthContext = createContext<ContextState | null>(null);

export const FirebaseAuthProvider: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [user, setUser] = useState<User | null>(null);
  const [userRole, setUserRole] = useState<UserRole>(UserRole.USER);
  const [appKey, setAppKey] = useState("");
  const [platformLvl, setPlatformLvl] = useState(0);
  const [company, setCompany] = useState("");

  const navigate = useNavigate();

  /**
   * @see @link https://github.com/react-navigation/react-navigation/issues/7631
   *
   * Non sono sicuro di come risolvere l'errore eslint, in questo link si parla della problematica, e sembrerebbe
   * che il non rispettare questo warn sia sintomo di un problema di design dell'implementazione.
   *
   * Al momento non ho trovato una soluzione migliore, quindi ho deciso di mantenere il codice così com'è.
   *
   * Se aggiungiamo navigate come dipendenza di useEffect, ad ogni cambio di pagina tutto viene ricaricato, creando
   * quindi un flickering della pagina.
   */
  useEffect(() => {
    setShowLoader(true);
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);

      if (currentUser) {
        localStorage.setItem("userUID", currentUser.uid);

        Sentry.setUser({
          id: currentUser.uid,
          email: currentUser.email || "",
        });

        const getSetUserInfo = async () => {
          const {
            appKeyDev,
            appKeyQa,
            appKeyProd,
            ruolo,
            platformLvl,
            company,
          } = (await (
            await get(ref(db, `/users/${currentUser.uid}`))
          ).val()) as {
            appKeyDev: string;
            appKeyQa: string;
            appKeyProd: string;
            platformLvl: number;
            ruolo: UserRole;
            company: string;
          };

          if (environment === "development") {
            if (!appKeyDev) {
              Sentry.captureException(
                `appKeyDev is not defined for user ${currentUser.uid}`,
              );
              throw new Error("appKeyDev is not defined");
            }

            setAppKey(appKeyDev);
          } else if (environment === "staging") {
            if (!appKeyQa) {
              Sentry.captureException(
                `appKeyQa is not defined for user ${currentUser.uid}`,
              );
              throw new Error("appKeyQa is not defined");
            }

            setAppKey(appKeyQa);
          } else {
            if (!appKeyProd) {
              Sentry.captureException(
                `appKeyProd is not defined for user ${currentUser.uid}`,
              );
              throw new Error("appKeyProd is not defined");
            }

            setAppKey(appKeyProd);
          }

          setCompany(company);
          setPlatformLvl(platformLvl);
          setUserRole(ruolo);

          setTimeout(() => {
            const isLoggingIn = localStorage.getItem("isLoggingIn") || "true";
            if (isLoggingIn === "true") {
              setShowLoader(false);
            }
          }, 100);
        };

        getSetUserInfo();
      } else {
        Sentry.setUser(null);
        setShowLoader(false);
        navigate("/login");
      }
    });

    return unsubscribe;
  }, []);

  return (
    <FirebaseAuthContext.Provider
      value={{
        user,
        authService: auth,
        showLoader,
        appKey,
        userRole,
        updateAppKey: setAppKey,
        platformLvl,
        company,
      }}
    >
      {children}
    </FirebaseAuthContext.Provider>
  );
};

export const useFirebaseContext = () => {
  const context = useContext(FirebaseAuthContext);
  if (!context) {
    throw new Error(
      "useFirebaseContext can only be used inside firebase provider",
    );
  }
  return context;
};
