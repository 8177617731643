/* eslint-disable @typescript-eslint/ban-ts-comment */
import HighchartsReactOfficial from "highcharts-react-official";
import * as Highcharts from "highcharts";
import addHeatmap from "highcharts/modules/heatmap";
import { Card } from "@/components/Layout/Card";
import { HeatmapSeriesData } from "../../types";
import { useTranslate } from "@/i18n/config";

addHeatmap(Highcharts);

const COLOR_AXES = {
  WARNING: {
    stops: [
      [0, "#0d1626"],
      [0.0000001, "#dbd2a9"],
      [1, "#e8c52a"],
    ],
    gridLineWidth: 0,
  },
  CRITICAL: {
    stops: [
      [0, "#0d1626"],
      [0.0000001, "#e0a7a6"],
      [1, "#D62422"],
    ],
    gridLineWidth: 0,
  },
  DEFAULT: {
    stops: [
      [0, "#0d1626"],
      [0.0000001, "#a1bfd1"],
      [1, "#2294d6"],
    ],
    gridLineWidth: 0,
  },
  NO_DATA: {
    minColor: "#0d1626",
    maxColor: "#0d1626",
    gridLineWidth: 0,
  },
};

const getPointCategoryName = (
  point: Highcharts.Point,
  dimension: "x" | "y",
) => {
  const series = point.series,
    isY = dimension === "y",
    axis = series[isY ? "yAxis" : "xAxis"];
  return axis.categories[point[isY ? "y" : "x"] || 0];
};

interface SummaryHeatmapProps {
  title: string;
  xCategories: string[];
  yCategories: string[];
  seriesData: HeatmapSeriesData[];
}

export const SummaryHeatmap = ({
  title,
  xCategories,
  yCategories,
  seriesData,
}: SummaryHeatmapProps) => {
  const translate = useTranslate();
  return (
    <Card>
      <HighchartsReactOfficial
        highcharts={Highcharts}
        options={{
          chart: {
            type: "heatmap",
            plotBorderWidth: 0,
            backgroundColor: "transparent",
            style: {
              fontFamily: "Montserrat, Helvetica Neue,Arial, sans-serif",
              color: "white",
            },
          },
          title: {
            text: `${title} - ${translate("event_occurrences")}`,
            align: "left",
            style: {
              color: "white",
            },
          },
          xAxis: {
            categories: xCategories,
            gridLineColor: "transparent",
            lineColor: "transparent",
            labels: {
              style: {
                color: "white",
              },
            },
          },
          yAxis: {
            categories: yCategories,
            gridLineColor: "transparent",
            lineColor: "transparent",
            title: null,
            reversed: true,
            labels: {
              style: {
                color: "white",
              },
            },
          },
          colorAxis: [
            COLOR_AXES.DEFAULT,
            COLOR_AXES.WARNING,
            COLOR_AXES.CRITICAL,
            COLOR_AXES.NO_DATA,
          ],
          legend: {
            enabled: false,
          },
          tooltip: {
            // @ts-ignore
            formatter: function () {
              // @ts-ignore
              const { point } =
                this as unknown as Highcharts.TooltipFormatterContextObject;
              const xCategory = getPointCategoryName(point, "x");
              const yCategory = getPointCategoryName(point, "y");
              // @ts-ignore
              return `${xCategory}<br>
                <b>${yCategory}</b> ${translate("occurrences")}: <b>${
                  point.value
                }</b>`;
            },
          },
          series: seriesData,
          credits: {
            enabled: false,
          },
          exporting: {
            enabled: false,
          },
        }}
      />
    </Card>
  );
};
