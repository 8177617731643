import { useFirebaseContext } from "@/context/firebase-context";
import { useTranslate } from "@/i18n/config";
import { FetchClient } from "@/services/ApiClient";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type Payload = {
  account: string;
  share_to: string;
  chart_id: number;
};

type Response = ThingworxError | Result;

const Result = z.object({
  response: z.literal(true),
});

type Result = z.infer<typeof Result>;

export const useShareChart = () => {
  const translate = useTranslate();
  const { appKey, user } = useFirebaseContext();
  const email = user?.email as string;

  return useMutation({
    mutationFn: async ({
      share_to,
      chart_id,
    }: {
      share_to: string;
      chart_id: number;
    }) => {
      const response = await FetchClient<Payload, Response>({
        appKey,
        payload: {
          account: email,
          chart_id,
          share_to,
        },
        url: "dig.c.customChartsDev_thing/services/shareChart",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return Result.parse(response);
    },
    onSuccess(data, { share_to }) {
      if (data.response) {
        toast.success(
          translate("custom_charts.chart_shared_successfully", {
            user: share_to,
          }),
        );
      } else {
        toast.error(
          translate("custom_charts.failed_to_shared_chart", {
            user: share_to,
          }),
        );
      }
    },
  });
};
