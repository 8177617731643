import { XrangePointOptionsObject } from "highcharts";
import { StateLogArrayProps } from "@/features/StateLog/api/useGetStateLogData";

export const parseStatesLogData = (
  data: StateLogArrayProps[]
): {
  categories: string[];
  chartData: XrangePointOptionsObject[];
} => {
  const categories: string[] = [];
  const chartData: XrangePointOptionsObject[] = [];

  data.forEach((occurrence) => {
    const { start, end, state, color } = occurrence;
    if (!categories.includes(state)) categories.push(state);
    chartData.push({
      name: state,
      x: start,
      x2: end,
      y: categories.indexOf(state),
      color,
    });
  });

  return { categories, chartData };
};
