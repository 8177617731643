import { z } from "zod";
import { useMutation } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";
import { ThingworxError } from "src/types/error";
import { FetchClient } from "@/services/ApiClient";
import { Line } from "./useGetUserProductionLines";
import { useTimeSelection } from "@/store/useTimeSelection";
import { UNAVAILABLE_TIME_SELECTIONS } from "../constants";

type UserLinesResponse = ThingworxError | UserLineResult;

const UserLineResult = z.object({
  line: Line,
  response: z.literal(true),
});

type UserLineResult = z.infer<typeof UserLineResult>;

type Payload = {
  account: string;
  lineId: number;
  timeSelection: string;
};

export const useGetUserProductionLineById = () => {
  const { appKey, user } = useFirebaseContext();
  const account = user?.email || "";
  const { timeSelection } = useTimeSelection();

  return useMutation({
    mutationFn: async ({ lineId }: { lineId: number }) => {
      const response = await FetchClient<Payload, UserLinesResponse>({
        appKey,
        payload: {
          account,
          lineId,
          timeSelection: UNAVAILABLE_TIME_SELECTIONS.includes(timeSelection)
            ? "Shift"
            : timeSelection,
        },
        url: "dig.c.plantOverview_thing/Services/getUserProductionLineById",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return UserLineResult.parse(response);
    },
  });
};
