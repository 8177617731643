import { FormGroup, FormControlLabel, Switch, Box } from "@mui/material";
import { performanceViewStore } from "../store/performance-view-store";
import { useTranslate } from "@/i18n/config";

export const TogglePerformanceView = () => {
  const translate = useTranslate();
  const { toggleView, view } = performanceViewStore();

  const checked = view === "configurable oee";

  return (
    <Box>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch size="small" checked={checked} onChange={toggleView} />
          }
          label={translate("overall_line_efficiency.view_configurable_oee")}
        />
      </FormGroup>
    </Box>
  );
};
