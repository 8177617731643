import { useQuery } from "@tanstack/react-query";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { FetchClient } from "@/services/ApiClient";
import { useFirebaseContext } from "@/context/firebase-context";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { performanceViewStore } from "@/features/OverallLineEfficiency/store/performance-view-store";
import { useLanguage } from "@/i18n/config";
import { CategoryCode } from "@/features/MachineDetail/api/CyclicLive/useGetSentinelLiveData";

type MachineNodeDataResponse = ThingworxError | MachineNodeData;

const MachineNodeData = z.object({
  response: z.literal(true),
  state: z.string(),
  state_color: z.string().optional(),
  // category: z.enum([
  //   "Disconnected",
  //   "Producing",
  //   "Not Producing",
  //   "Line",
  //   "Setup Pre-Production",
  //   "Intentional No Production",
  // ]),
  categoryCode: CategoryCode,
  hasSpeed: z.boolean(),
  machineTimezone: z.string(),
  oee: z.number().optional(),
  lineLosses: z.number(),
  processLosses: z.number(),
  machineLosses: z.number(),
  extraCapacity: z.number(),
  total_products: z.number(),
  rejected_products: z.number(),
  products: z.number(),
  machine_type: z.enum(["cyclic", "process"]),
  machineLogic: z.enum(["static", "parametric"]),
  hasBatch: z.boolean(),
  label: z.string().optional(),
  alarm_code: z.number().optional(),
  alarm_description: z.string().optional(),
});

export type MachineNodeData = z.infer<typeof MachineNodeData>;

type MachineNodeDataPayload = {
  timeSelection: TimeSelection;
  machineName: string;
  view_mode: string;
  language_id: string;
};

export const useGetMachineData = ({ machine }: { machine: string }) => {
  const { timeSelection } = useTimeSelection();
  const { view } = performanceViewStore();

  const { appKey } = useFirebaseContext();
  const language = useLanguage();

  return useQuery<MachineNodeData>({
    queryKey: ["machine-noda-data", machine, timeSelection, view, language],
    queryFn: async () => {
      const response = await FetchClient<MachineNodeDataPayload, MachineNodeDataResponse>({
        url: "dig.c.plantOverview_thing/services/getMachineDetail",
        appKey,
        payload: {
          timeSelection,
          machineName: machine,
          view_mode: view,
          language_id: language,
        },
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return MachineNodeData.parse(response);
    },
    refetchInterval: 20000,
  });
};
