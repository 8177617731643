import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { shuttle } from "../../types";
import { useTimeSelection } from "@/store/useTimeSelection";

export type GetDeviancesShuttlesResponse =
  | ThingworxError
  | GetDeviancesShuttlesResult;

type Payload = {
  machineName: string;
  dateStart: number;
  dateEnd: number;
};

const getDeviancesShuttlesResult = z.object({
  response: z.literal(true),
  shuttles: z.array(shuttle),
});

export type GetDeviancesShuttlesResult = z.infer<
  typeof getDeviancesShuttlesResult
>;

export const useGetDeviancesShuttles = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const { dates } = useTimeSelection();

  return useQuery<GetDeviancesShuttlesResult>({
    queryKey: [
      "deviances-shuttles-data",
      machine!.machine,
      dates.dateEnd,
      dates.dateStart,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, GetDeviancesShuttlesResponse>(
        {
          appKey,
          payload: {
            machineName: machine!.machine,
            dateStart: dates.dateStart.toMillis(),
            dateEnd: dates.dateEnd.toMillis(),
          },
          // url: "dig.c.acopostrak_thing/Services/getDeviancesShuttles",
          url: "dig.c.acoposTrack_thing/Services/getDeviancesShuttles",
        },
      );

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = getDeviancesShuttlesResult.parse(response);

      return validatedResponse;
    },
  });
};
