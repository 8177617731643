import { Modal } from "@/components/Modal";
import { Button } from "@mui/material";
import { useCallback, useState } from "react";

export const useAskConfirmAction = (message: string, onConfirm: () => void) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const askConfirm = useCallback(() => {
    setShowConfirmModal(true);
  }, []);

  const renderConfirmModal = useCallback(() => {
    if (!showConfirmModal) {
      return null;
    }

    return (
      <Modal
        open={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
        }}
        titleContent="Confirm"
        bodyContent={message}
        actions={
          <>
            <Button
              onClick={() => {
                setShowConfirmModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setShowConfirmModal(false);
                onConfirm();
              }}
            >
              Confirm
            </Button>
          </>
        }
      />
    );
  }, [message, showConfirmModal, onConfirm]);

  return {
    askConfirm,
    renderConfirmModal,
  };
};
