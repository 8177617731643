import { Dialog } from "@mui/material";
import { CreateChartContextProvider } from "../context/create-chart-context";
import { CreateChartStepController } from "../context/CreateChartStepController";

export const CreateChartModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <CreateChartContextProvider closeModal={onClose}>
        <CreateChartStepController />
      </CreateChartContextProvider>
    </Dialog>
  );
};
