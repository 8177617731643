import { useReducer, createContext } from "react";
import { z } from "zod";
import { Frame, Run } from "../types";

export const MAX_ITEMS_NUMBER = 1;
export const MAX_RUNS_NUMBER = 1;
export const MAX_FRAMES_NUMBER = 3;
export const FILTER_COLOR = "#46c5c7";

const Item = z.object({
  timelapse: z.string(),
  dateStart: z.number(),
  dateEnd: z.number(),
  id: z.number(),
});

export type Item = z.infer<typeof Item>;

export type Timelapse = "recipes" | "batches";

const Motor = z.object({
  motor_id: z.number(),
});

export type Motor = z.infer<typeof Motor>;

type MotorsContextType = {
  timelapse: Timelapse;
  // recipesFavorites: Item[];
  // batchesFavorites: Item[];
  selectedItems: Item[];
  runs: Run[];
  frames: Frame[];
  motors: number[];
};

type Action =
  // | {
  //     type: "ADD_RECIPE";
  //     item: Item;
  //   }
  // | {
  //     type: "REMOVE_RECIPE";
  //     item: Item;
  //   }
  // | {
  //     type: "CLEAR_RECIPES";
  //   }
  // | {
  //     type: "ADD_BATCH";
  //     item: Item;
  //   }
  // | {
  //     type: "REMOVE_BATCH";
  //     item: Item;
  //   }
  // | {
  //     type: "CLEAR_BATCHES";
  //   }
  | {
      type: "ADD_ITEM";
      item: Item;
    }
  | {
      type: "REMOVE_ITEM";
      item: Item;
    }
  | {
      type: "CLEAR_ITEMS";
    }
  | {
      type: "ADD_RUN";
      run: Run;
    }
  | {
      type: "REMOVE_RUN";
      run: Run;
    }
  | {
      type: "CLEAR_RUNS";
    }
  | {
      type: "ADD_FRAME";
      frame: Frame;
    }
  | {
      type: "REMOVE_FRAME";
      frame: Frame;
    }
  | {
      type: "CLEAR_FRAMES";
    }
  | {
      type: "SET_TIMELAPSE";
      timelapse: Timelapse;
    }
  | {
      type: "ADD_MOTOR";
      item: number;
    }
  | {
      type: "REMOVE_MOTOR";
      item: number[];
    }
  | {
      type: "CLEAR_MOTORS";
    };

const reducer: React.Reducer<MotorsContextType, Action> = (
  state,
  action,
): MotorsContextType => {
  switch (action.type) {
    // case "ADD_RECIPE": {
    //   if (
    //     !state.recipesFavorites.find(
    //       (obj) =>
    //         obj.id === action.item.id &&
    //         obj.dateStart === action.item.dateStart &&
    //         obj.dateEnd === action.item.dateEnd,
    //     )
    //   ) {
    //     return {
    //       ...state,
    //       recipesFavorites: [...state.recipesFavorites, action.item],
    //     };
    //   } else {
    //     return state;
    //   }
    // }

    // case "REMOVE_RECIPE": {
    //   if (
    //     state.recipesFavorites.find(
    //       (obj) =>
    //         obj.id === action.item.id &&
    //         obj.dateStart === action.item.dateStart &&
    //         obj.dateEnd === action.item.dateEnd,
    //     )
    //   ) {
    //     return {
    //       ...state,
    //       recipesFavorites: state.recipesFavorites.filter(
    //         (item) =>
    //           `${item.id}-${item.timelapse}-${item.dateStart}-${item.dateEnd}` !==
    //           `${action.item.id}-${action.item.timelapse}-${action.item.dateStart}-${action.item.dateEnd}`,
    //       ),
    //     };
    //   } else {
    //     return state;
    //   }
    // }

    // case "CLEAR_RECIPES": {
    //   return {
    //     ...state,
    //     recipesFavorites: [],
    //     frames: [],
    //   };
    // }
    // case "ADD_BATCH": {
    //   if (
    //     !state.batchesFavorites.find(
    //       (obj) =>
    //         obj.id === action.item.id &&
    //         obj.dateStart === action.item.dateStart &&
    //         obj.dateEnd === action.item.dateEnd,
    //     )
    //   )
    //     return {
    //       ...state,
    //       batchesFavorites: [...state.batchesFavorites, action.item],
    //     };
    //   else return state;
    // }

    // case "REMOVE_BATCH": {
    //   const found = state.batchesFavorites.find(
    //     (obj) =>
    //       obj.id === action.item.id &&
    //       obj.dateStart === action.item.dateStart &&
    //       obj.dateEnd === action.item.dateEnd,
    //   );

    //   if (found) {
    //     return {
    //       ...state,
    //       batchesFavorites: state.batchesFavorites.filter(
    //         (item) =>
    //           `${item.id}-${item.timelapse}` !==
    //           `${action.item.id}-${action.item.timelapse}`,
    //       ),
    //     };
    //   }
    //   return state;
    // }
    // case "CLEAR_BATCHES": {
    //   return {
    //     ...state,
    //     batchesFavorites: [],
    //     frames: [],
    //   };
    // }

    case "ADD_ITEM": {
      if (
        !state.selectedItems.find(
          (obj) =>
            obj.id === action.item.id &&
            obj.dateStart === action.item.dateStart &&
            obj.dateEnd === action.item.dateEnd,
        )
      )
        return {
          ...state,
          selectedItems: [...state.selectedItems, action.item],
        };
      else return state;
    }

    case "REMOVE_ITEM": {
      const found = state.selectedItems.find(
        (obj) =>
          obj.id === action.item.id &&
          obj.dateStart === action.item.dateStart &&
          obj.dateEnd === action.item.dateEnd,
      );

      if (found) {
        return {
          ...state,
          selectedItems: state.selectedItems.filter(
            (item) =>
              `${item.id}-${item.timelapse}` !==
              `${action.item.id}-${action.item.timelapse}`,
          ),
        };
      }
      return state;
    }
    case "CLEAR_ITEMS": {
      return {
        ...state,
        selectedItems: [],
        runs: [],
      };
    }

    case "ADD_RUN": {
      if (
        !state.runs.find(
          (obj) =>
            obj.id === action.run.id &&
            obj.start === action.run.start &&
            obj.end === action.run.end,
        )
      )
        return {
          ...state,
          runs: [...state.runs, action.run],
        };
      else return state;
    }

    case "REMOVE_RUN": {
      const found = state.runs.find(
        (obj) =>
          obj.id === action.run.id &&
          obj.start === action.run.start &&
          obj.end === action.run.end,
      );

      if (found) {
        return {
          ...state,
          runs: state.runs.filter(
            (item) =>
              `${item.id}-${item.name}` !==
              `${action.run.id}-${action.run.name}`,
          ),
        };
      }
      return state;
    }
    case "CLEAR_RUNS": {
      return {
        ...state,
        runs: [],
      };
    }

    case "ADD_FRAME": {
      if (!state.frames.find((obj) => obj.id === action.frame.id))
        return {
          ...state,
          frames: [...state.frames, action.frame],
        };
      else return state;
    }

    case "REMOVE_FRAME": {
      if (state.frames.find((obj) => obj.id === action.frame.id)) {
        return {
          ...state,
          frames: state.frames.filter(
            (frame) =>
              `${frame.id}-${frame.timelapse}` !==
              `${action.frame.id}-${action.frame.timelapse}`,
          ),
        };
      } else {
        return state;
      }
    }
    case "CLEAR_FRAMES": {
      return {
        ...state,
        frames: [],
      };
    }

    case "SET_TIMELAPSE": {
      return {
        ...state,
        timelapse: action.timelapse,
      };
    }

    case "ADD_MOTOR": {
      if (!state.motors.find((el) => el === action.item))
        return {
          ...state,
          motors: [...state.motors, action.item],
        };
      else return state;
    }

    case "REMOVE_MOTOR": {
      const idToBeRemoved: number | undefined = state.motors.find(
        (num) => !action.item.includes(num),
      );
      if (idToBeRemoved) {
        if (state.motors.find((el) => el === idToBeRemoved)) {
          return {
            ...state,
            motors: state.motors.filter((item) => item !== idToBeRemoved),
          };
        } else {
          return state;
        }
      } else {
        return state;
      }
    }

    case "CLEAR_MOTORS": {
      return {
        ...state,
        motors: [],
      };
    }

    default:
      return state;
  }
};

export const MotorsContext = createContext<MotorsContextType | null>(null);

export const MotorsDispatchContext =
  createContext<React.Dispatch<Action> | null>(null);

export const MotorsContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, {
    timelapse: "recipes",
    // recipesFavorites: [],
    // batchesFavorites: [],
    selectedItems: [],
    runs: [],
    frames: [],
    motors: [],
  });

  const motorsContext: MotorsContextType = {
    timelapse: state.timelapse,
    // recipesFavorites: state.recipesFavorites,
    // batchesFavorites: state.batchesFavorites,
    selectedItems: state.selectedItems,
    runs: state.runs,
    frames: state.frames,
    motors: state.motors,
  };

  return (
    <MotorsContext.Provider value={motorsContext}>
      <MotorsDispatchContext.Provider value={dispatch}>
        {children}
      </MotorsDispatchContext.Provider>
    </MotorsContext.Provider>
  );
};
