import { SVGProps } from "react";

export const SetupPreProdCategory = ({
  width = 24,
  height = 24,
  fill = "#B277E2",
  ...rest
}: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    {...rest}
  >
    <g clipPath="url(#clip0_1249_5501)">
      <g>
        <path d="M21.4339 5.15974C21.595 5.04271 21.8211 5.07812 21.9333 5.24262C22.3649 5.8754 22.7348 6.54801 23.0382 7.25121C23.1171 7.43406 23.0259 7.64376 22.8406 7.71706C22.6554 7.79036 22.4464 7.69944 22.3672 7.5168C22.0865 6.86958 21.7458 6.25016 21.3494 5.66659C21.2375 5.50186 21.2727 5.27675 21.4339 5.15974Z" />
        <path d="M23.21 8.79294C23.4001 8.73847 23.5989 8.84822 23.6477 9.0397C23.8373 9.78353 23.9551 10.5438 23.9994 11.3101C24.0108 11.5074 23.8545 11.672 23.6568 11.6775C23.4592 11.683 23.2951 11.5273 23.2833 11.3301C23.2411 10.6238 23.1326 9.92303 22.9591 9.23702C22.9106 9.04547 23.02 8.84742 23.21 8.79294Z" />
        <path d="M23.5953 13.2198C23.7932 13.2405 23.9373 13.4178 23.9107 13.6149C23.8077 14.374 23.6322 15.1216 23.3867 15.8472C23.323 16.0356 23.115 16.1304 22.9285 16.061C22.742 15.9917 22.6476 15.7846 22.711 15.5961C22.9359 14.9272 23.0975 14.2386 23.1937 13.5396C23.2208 13.3426 23.3975 13.199 23.5953 13.2198Z" />
        <path d="M22.4825 17.1075C22.6601 17.194 22.7343 17.4083 22.6424 17.5831C22.2853 18.2629 21.8636 18.9068 21.3834 19.5061C21.2598 19.6602 21.0336 19.678 20.8832 19.55C20.7328 19.422 20.7151 19.1968 20.8383 19.0425C21.28 18.4891 21.6687 17.8956 21.9991 17.2695C22.0913 17.0949 22.305 17.0209 22.4825 17.1075Z" />
        <path d="M20.1001 20.3756C20.2394 20.5198 20.2357 20.75 20.0873 20.8849C19.5215 21.3989 18.9079 21.8576 18.2549 22.2549C18.0835 22.3592 17.8615 22.2977 17.7624 22.1234C17.6633 21.9491 17.7247 21.728 17.8959 21.6234C18.4961 21.2565 19.0608 20.8344 19.5824 20.3624C19.7311 20.2278 19.9607 20.2314 20.1001 20.3756Z" />
        <path d="M16.748 22.6385C16.8289 22.8203 16.7473 23.0338 16.5633 23.1093C15.854 23.3999 15.1185 23.6219 14.3669 23.7724C14.1718 23.8114 13.9857 23.6787 13.9525 23.4825C13.9193 23.2863 14.0516 23.101 14.2467 23.0615C14.9387 22.9216 15.6161 22.7171 16.27 22.4507C16.4543 22.3756 16.667 22.4568 16.748 22.6385Z" />
        <path d="M12.0097 23.6458C12.0097 23.8443 11.8486 24.0058 11.6501 23.9998C9.86696 23.9465 8.1165 23.4967 6.52668 22.6814C4.83009 21.8112 3.3652 20.5499 2.25323 19.0016C1.14125 17.4534 0.414152 15.6628 0.132099 13.7779C-0.149955 11.8931 0.0211417 9.96819 0.631233 8.16255C1.24132 6.35695 2.27287 4.72246 3.64051 3.39436C5.00816 2.06627 6.67259 1.08275 8.49607 0.525172C10.3196 -0.0324015 12.2497 -0.147994 14.1268 0.187958C15.8857 0.502772 17.5507 1.20562 19.0012 2.24352C19.1627 2.35909 19.1928 2.5851 19.0724 2.74297C18.952 2.90085 18.7267 2.93076 18.565 2.8155C17.2055 1.84663 15.6465 1.19027 14 0.895576C12.2353 0.579742 10.4208 0.688413 8.70651 1.21259C6.99225 1.73677 5.4275 2.66139 4.14177 3.90994C2.85604 5.1585 1.88627 6.69508 1.31272 8.39256C0.739174 10.0901 0.578323 11.8997 0.843487 13.6716C1.10865 15.4435 1.79219 17.127 2.83757 18.5825C3.88295 20.038 5.2601 21.2238 6.85509 22.0418C8.34329 22.805 9.98126 23.2275 11.6501 23.2807C11.8486 23.287 12.0097 23.4473 12.0097 23.6458Z" />
        <path d="M11.7096 5.10118C11.7096 4.93546 11.844 4.80112 12.0098 4.80112C12.1757 4.80112 12.3101 4.93546 12.3101 5.10118V12.0027H11.7096V5.10118Z" />
        <path d="M17.8562 15.2045C17.996 15.2936 18.0372 15.4791 17.9481 15.6189C17.859 15.7586 17.6733 15.7997 17.5335 15.7107L11.7094 12.0025L12.0321 11.4964L17.8562 15.2045Z" />
        <path d="M20.6431 3.70828C20.6707 3.82014 20.5897 3.92954 20.4746 3.9358L17.9845 4.07136C17.8291 4.07982 17.7342 3.90362 17.8267 3.77864L19.7186 1.22352C19.8111 1.09854 20.0074 1.13779 20.0448 1.28872L20.6431 3.70828Z" />
      </g>
    </g>
    <defs>
      <clipPath>
        <rect width={24} height={24} />
      </clipPath>
    </defs>
  </svg>
);
