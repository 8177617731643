import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { phaseAnalytics } from "../../types";
import { useIsolatorContext } from "../../context/useIsolatorContext";

export type GetDescriptiveAnalyticsListResponse =
  | ThingworxError
  | GetDescriptiveAnalyticsListResult;

type Payload = {
  machineName: string;
  batchIds: number[];
};

const getDescriptiveAnalyticsListResult = z.object({
  response: z.literal(true),
  analytics: z.object({
    descriptive_raw_data: z.array(phaseAnalytics),
    descriptive_analytics: z.array(phaseAnalytics),
  }),
});

export type GetDescriptiveAnalyticsListResult = z.infer<
  typeof getDescriptiveAnalyticsListResult
>;

export const useGetDescriptiveAnalyticsList = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const { batches } = useIsolatorContext();
  const batchIds = batches.map((batch) => batch.id);

  return useQuery<GetDescriptiveAnalyticsListResult>({
    queryKey: [
      "descriptive-analytics-raw-data-list",
      machine!.machine,
      JSON.stringify(batchIds),
    ],
    queryFn: async () => {
      const response = await FetchClient<
        Payload,
        GetDescriptiveAnalyticsListResponse
      >({
        appKey,
        payload: {
          machineName: machine!.machine,
          batchIds,
        },
        url: "dig.c.isolator_thing/Services/getDescriptiveAnalyticsList",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse =
        getDescriptiveAnalyticsListResult.parse(response);

      return validatedResponse;
    },
  });
};
