import {
  TextSnippetOutlined,
  AudioFileOutlined,
  FontDownloadOutlined,
  ImageOutlined,
  VideoFileOutlined,
} from "@mui/icons-material";

export const getImageFromFileType = (fileType: Blob["type"]): JSX.Element => {
  console.log(fileType);
  if (fileType.startsWith("application/")) {
    return <TextSnippetOutlined />;
  } else if (fileType.startsWith("audio/")) {
    return <AudioFileOutlined />;
  } else if (fileType.startsWith("example/")) {
    return <TextSnippetOutlined />;
  } else if (fileType.startsWith("font/")) {
    return <FontDownloadOutlined />;
  } else if (fileType.startsWith("image/")) {
    return <ImageOutlined />;
  } else if (fileType.startsWith("message/")) {
    return <TextSnippetOutlined />;
  } else if (fileType.startsWith("model/")) {
    return <TextSnippetOutlined />;
  } else if (fileType.startsWith("multipart/")) {
    return <TextSnippetOutlined />;
  } else if (fileType.startsWith("text/")) {
    return <TextSnippetOutlined />;
  } else if (fileType.startsWith("video/")) {
    return <VideoFileOutlined />;
  } else {
    return <TextSnippetOutlined />;
  }
};
