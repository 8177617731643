import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { Prompt } from "../../types";
import { useDeletePrompt } from "../../api/useDeletePrompt";
import { Loader } from "@/components/Layout/Loader";

export const DeletePromptModal = ({
  prompt,
  isOpen,
  close,
}: {
  prompt: Prompt;
  isOpen: boolean;
  close: () => void;
}) => {
  const translate = useTranslate();
  const { mutate: deletePrompt, isLoading } = useDeletePrompt();

  if (isLoading) {
    return <Loader isLoading />;
  }

  const handleClose = () => {
    close();
  };

  const handleConfirm = () => {
    deletePrompt(
      { prompt_id: prompt.id },
      {
        onSettled: (data) => {
          if (data?.value === "OK") close();
        },
      },
    );
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={handleClose}>
      <DialogTitle> {translate("intellecta.delete_prompt")}</DialogTitle>
      <DialogContent>
        <Stack gap={2} p={1}>
          <Typography variant="h6">
            {translate("intellecta.delete_prompt_2")}
          </Typography>
          <Typography>Label: {prompt.label}</Typography>
          <Typography>Prompt: {prompt.prompt}</Typography>
          <Typography>Description: {prompt.description}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {translate("actions.cancel")}
        </Button>
        <Button variant="contained" onClick={handleConfirm}>
          {translate("actions.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
