const BASE_HORIZONTAL_DIMENSION = 220;
const BASE_VERTICAL_DIMENSION = 150;
const BASE_HORIZONTAL_SPACING = 30;
const BASE_VERTICAL_SPACING = 20;

export {
  BASE_HORIZONTAL_DIMENSION,
  BASE_VERTICAL_DIMENSION,
  BASE_HORIZONTAL_SPACING,
  BASE_VERTICAL_SPACING,
};
