import { Box, Stack, Typography } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import {
  ResSchema,
  useGetDeviancesData,
} from "../../api/Monitoring/useGetDeviancesData";
import { Card } from "@/components/Layout/Card";
import { ContextInformation } from "@/features/Motors/components/Layouts/ContextInformation";
import { BoxPlot } from "@/components/highcharts/boxplot/BoxPlot";
import { Timelapse } from "../../types";
import { DateTime } from "luxon";

const getTimeLabel = ({
  timestamp,
  timelapse,
}: {
  timestamp: number;
  timelapse: Timelapse;
}): string => {
  const date = DateTime.fromMillis(timestamp);
  switch (timelapse) {
    case "hour":
      return date.toFormat("dd/MM 'hour' HH");

    case "day":
      return date.toFormat("dd/MM");

    case "week":
      return `Week ${date.weekNumber}`;
  }
};

export const DeviancesAnalytics = ({ timelapse }: { timelapse: Timelapse }) => {
  const { data, isLoading, error } = useGetDeviancesData({ timelapse });
  const translate = useTranslate();

  if (isLoading) return <AnalyticContainerSkeleton />;

  if (error) return <Card>{translate("user_feedback.an_error_occurred")}</Card>;

  if (!data) return <Card>{translate("user_feedback.no_data")}</Card>;

  return (
    <Stack gap={1} width="100%">
      {data.data.length > 0 ? (
        data.data.map((analytic, index) => (
          <Analytic
            key={`${analytic.analyticName}-${index}`}
            timelapse={timelapse}
            props={analytic}
          />
        ))
      ) : (
        <Card sx={{ height: 400 }}>
          <Typography sx={{ color: "red" }} variant="h4">
            {translate("motors.no_selection")}
          </Typography>
          <Typography variant="h6">
            {translate("motors.no_data_try_another")}
          </Typography>
        </Card>
      )}
    </Stack>
  );
};

const Analytic = ({
  props,
  timelapse,
}: {
  props: ResSchema;
  timelapse: Timelapse;
}) => {
  const { analyticName, data, contextInfo } = props;
  const categories = data.categories.map((category) => {
    const { count, timestamp } = category;
    const timeLabel = getTimeLabel({ timelapse, timestamp });
    return `${timeLabel} (${count} entries)`;
  });

  return (
    <Card>
      <Box>
        <ContextInformation props={contextInfo} key={analyticName} />
        <BoxPlot
          key={JSON.stringify(categories)}
          boxplotData={data.boxPlotData}
          categories={categories}
          scatterData={data.scatterData}
          trendData={data.trendData}
          seriesName={analyticName}
          title={analyticName}
          yAxisTitle=""
        />
      </Box>
    </Card>
  );
};
