import { create } from "zustand";

interface TimeSelection {
  name: string;
  dateStart: number;
  dateEnd: number;
}

interface TimeSelectionStore {
  timeSelections: TimeSelection[];
  setTimeSelections: (newItems: TimeSelection[]) => void;
}

export const useTimeSelectionList = create<TimeSelectionStore>((set) => ({
  timeSelections: [],
  setTimeSelections: (newItems: TimeSelection[]) =>
    set({ timeSelections: newItems }),
}));
