import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { AlarmData } from "@/features/Alarms/api/useGetAlarmsColumnData";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { EfficiencyData, KpiData } from "../ProcessLive/useGetProcessData";
import { ProductionPeriodItem } from "./useProcessGetDataList";
import { z } from "zod";
import { useLanguage } from "@/i18n/config";

type ProcessHistoricDataResponse = ThingworxError | ProcessHistoricDataResult;

const PieChartValue = z.object({
  color: z.string(),
  name: z.string(),
  y: z.number(),
  z: z.number(),
});

export type PieChartValue = z.infer<typeof PieChartValue>;

const Recipe = z.object({
  Recipe: z.string(),
  Duration: z.number(),
});
export type Recipe = z.infer<typeof Recipe>;

const ProcessHistoricDataResult = z.object({
  response: z.literal(true),
  recipes: z.array(Recipe),
  efficiencyData: EfficiencyData,
  pieChartData: z.array(PieChartValue),
  stateLogData: z.unknown(),
  alarms: z.array(AlarmData),
  kpiData: KpiData.extend({ timelapseDuration: z.number() }),
});

export type ProcessHistoricDataResult = z.infer<typeof ProcessHistoricDataResult>;

type Payload = {
  timeSelection: TimeSelection;
  id: string;
  platformTimezone: "user";
  machineName: string;
  dateStart: number;
  dateEnd: number;
  language_id: string;
};

export const useProcessGetHistoricData = ({ item }: { item: ProductionPeriodItem }) => {
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();
  const { timeSelection } = useTimeSelection();
  const language = useLanguage();

  return useQuery<ProcessHistoricDataResult>({
    queryKey: [
      "machinedetail-historic-process",
      machine!.machine,
      timeSelection,
      item.id,
      language,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, ProcessHistoricDataResponse>({
        url: "dig.c.historicMachineDetailProcess_thing/Services/getData",
        appKey,
        payload: {
          timeSelection,
          id: item.id.toString(),
          platformTimezone: "user",
          machineName: machine!.machine,
          dateStart: item.dataStartTimeLapse,
          dateEnd: item.dataEndTimeLapse,
          language_id: language,
        },
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return ProcessHistoricDataResult.parse(response);
    },
  });
};
