import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { PieData } from "@/features/MachineDetail/api/CyclicLive/useGetSentinelLiveData";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { ComparisonKpiType } from "../useGetConfigurableOeeData";
import { useLanguage } from "@/i18n/config";

const StateInfo = z.object({
  stateName: z.string(),
  duration: z.number(),
  isClientRelevance: z.boolean(),
  isEfficiencyRelevance: z.boolean(),
});

type Payload = {
  machineName: string;
  timelapse: TimeSelection;
  id: number;
  language_id: string;
};

const HistoricConfigurableOeeResult = z.object({
  pieData: z.array(PieData),
  standardAvailability: z.number(),
  customAvailability: z.number(),
  standardOee: z.number(),
  customOee: z.number(),
  statesInfo: z.array(StateInfo),
  comparisonKpi: ComparisonKpiType,
  standardPerformance: z.number().transform((v) => Number(v.toFixed(2))),
  customPerformance: z.number().transform((v) => Number(v.toFixed(2))),
  response: z.literal(true),
});
type HistoricConfigurableOeeResult = z.infer<typeof HistoricConfigurableOeeResult>;

type HistoricConfigurableOeeResponse = ThingworxError | HistoricConfigurableOeeResult;

export const useGetHistoricConfigurableData = ({ id }: { id: number }) => {
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();
  const { timeSelection } = useTimeSelection();
  const language = useLanguage();

  return useQuery<HistoricConfigurableOeeResult>({
    queryKey: ["historic-configurable-oee-data", id, machine?.machine, timeSelection, language],
    queryFn: async () => {
      const response = await FetchClient<Payload, HistoricConfigurableOeeResponse>({
        appKey,
        payload: {
          id,
          machineName: machine!.machine,
          timelapse: timeSelection,
          language_id: language,
        },
        url: "dig.c.configurableOee_thing/services/getHistoricCustomEfficiencyConfiguration",
      });
      if (!response.response) {
        throw new Error(response.errorString);
      }
      return HistoricConfigurableOeeResult.parse(response);
    },
  });
};
