import {
  DateRange,
  DateRangePicker,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { useGetBatchList } from "../../api/useGetBatchList";
import { useLyoContext } from "../../context/lyo-context";
import { RangeBatchMenu } from "../RangeBatchMenu/RangeBatchMenu";
import { HelpDialogButton } from "../HelpDialog";
import { DateTime } from "luxon";

export const SummaryNavigation = () => {
  const [{ to, from }, dispatch] = useLyoContext();

  useGetBatchList({ from, to });

  return (
    <MachineViewNavBar>
      <DateRangePicker
        reduceAnimations
        label="Pick dates"
        disableFuture
        slotProps={{
          textField: {
            size: "small",
            sx: {
              width: "fit-content",
            },
          },
        }}
        value={[DateTime.fromJSDate(from), DateTime.fromJSDate(to)]}
        onChange={(dates: DateRange<DateTime>) => {
          const [fromDate, toDate] = dates;
          if (!fromDate || !toDate) return;

          dispatch({
            type: "pick date",
            from: fromDate.toJSDate(),
            to: toDate.toJSDate(),
          });
        }}
        slots={{
          field: SingleInputDateRangeField,
        }}
      />

      <RangeBatchMenu />
      <HelpDialogButton />
    </MachineViewNavBar>
  );
};
