import { Modal } from "@/components/Modal";
import {
  Button,
  Checkbox,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { Variable } from "../api/useGetVariableList";
import { useRuleDispatchContext } from "../context/RuleContextProvider";
import { useTranslate } from "@/i18n/config";

interface VariablesModalProps {
  isOpen: boolean;
  close: () => void;
  variables: Variable[];
  selectedVariables: Variable[];
  setSelectedVariables: Dispatch<SetStateAction<Variable[]>>;
}

export const VariablesModal = ({
  isOpen,
  close,
  variables,
  selectedVariables,
  setSelectedVariables,
}: VariablesModalProps) => {
  const dispatch = useRuleDispatchContext();
  const translate = useTranslate();
  const handleVariableClick = (variable: Variable) => {
    if (
      selectedVariables.find((v) => v.propertyName === variable.propertyName)
    ) {
      setSelectedVariables((prevState) =>
        prevState.filter((v) => v.propertyName !== variable.propertyName),
      );
      dispatch({
        type: "REMOVE_CONDITION",
        variableName: variable.propertyName,
      });
    } else {
      setSelectedVariables((prevState) => [...prevState, variable]);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={close}
      bodyContent={
        <List component="div" disablePadding>
          {variables.map((variable) => (
            <ListItemButton
              key={variable.propertyName}
              sx={{ pl: 2 }}
              onClick={() => handleVariableClick(variable)}
            >
              <Checkbox
                checked={
                  selectedVariables.find(
                    (selectedVar) =>
                      selectedVar.propertyName === variable.propertyName,
                  )
                    ? true
                    : false
                }
                color="primary"
              />
              <ListItemText primary={variable.propertyNameClient} />
            </ListItemButton>
          ))}
        </List>
      }
      titleContent={translate("variable_other")}
      actions={
        <Button variant="outlined" onClick={close}>
          {translate("actions.back")}
        </Button>
      }
      fullWidth
    />
  );
};
