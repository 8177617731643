import { TimeRange } from "../types";

export const getSliderValue = ({
  timeRange,
  duration,
}: {
  timeRange: TimeRange;
  duration: number;
}) => {
  switch (timeRange) {
    case "minutes":
      return duration * 60;
    case "hours":
      return duration * 3600;
    case "days":
      return duration * 86400;
    case "weeks":
      return duration * 604800;
  }
};
