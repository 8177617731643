import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Variable } from "../api/useGetVariableList";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";
import {
  useRuleContext,
  useRuleDispatchContext,
} from "../context/RuleContextProvider";
import { Operator, PropertyType, Condition as ConditionType } from "../types";
import { DurationInput, Duration } from "./DurationInput";
import { useTranslate } from "@/i18n/config";

const formatOperator = (operator: Operator): string => {
  const FORMATTED_OPERATORS = {
    Above: "Above",
    Below: "Below",
    EqualTo: "Equal To",
    NotEqualTo: "Not Equal To",
    InRange: "In Range",
    OutOfRange: "Out Of Range",
  };
  return FORMATTED_OPERATORS[operator];
};

const calculateDuration = (duration: Duration): number => {
  return duration.seconds + duration.minutes * 60 + duration.hours * 3600;
};

const getOperators = (propertyType: PropertyType): Operator[] => {
  const OPERATORS: { [key: string]: Operator[] } = {
    String: ["EqualTo", "NotEqualTo"],
    boolean: ["EqualTo", "NotEqualTo"],
    number: [
      "Above",
      "Below",
      "EqualTo",
      "NotEqualTo",
      "InRange",
      "OutOfRange",
    ],
    timestamp: [
      "Above",
      "Below",
      "EqualTo",
      "NotEqualTo",
      "InRange",
      "OutOfRange",
    ],
  };
  return OPERATORS[propertyType];
};

interface ConditionProps {
  variable: Variable;
  machine: string;
  setSelectedVariables: Dispatch<SetStateAction<Variable[]>>;
}

export const Condition = ({
  variable,
  machine,
  setSelectedVariables,
}: ConditionProps) => {
  const translate = useTranslate();
  const { conditions } = useRuleContext();

  const dispatch = useRuleDispatchContext();

  const { propertyNameClient, propertyType, uom, propertyName } = variable;
  const operators = getOperators(propertyType);
  const [duration, setDuration] = useState<Duration>({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [selectedData, setSelectedData] = useState<ConditionType>(() => {
    const existingCondition = conditions.find(
      (el) => el.property === propertyName,
    );
    if (existingCondition) {
      return existingCondition;
    } else {
      return {
        machine,
        property: propertyName,
        propertyNameClient,
        propertyType,
        operator: operators[0],
        value1: 0,
        // value2: undefined,
        value2: 0,
        isCumulative: false,
        isActiveDuration: false,
        duration: 0,
        uom: uom[0],
        rate: 1,
      };
    }
  });

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      dispatch({
        type: "CREATE_UPDATE_CONDITION",
        condition: {
          machine,
          property: propertyName,
          propertyNameClient,
          propertyType,
          operator: selectedData.operator,
          value1: selectedData.value1,
          value2: selectedData.value2,
          isCumulative: selectedData.isCumulative,
          isActiveDuration: selectedData.isActiveDuration,
          duration: selectedData.duration,
          uom: selectedData.uom,
          rate: selectedData.rate,
        },
      });
    }, 1000);
    return () => clearTimeout(timeOutId);
  }, [selectedData]);

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        setSelectedData({
          ...selectedData,
          duration: calculateDuration(duration),
        }),
      1000,
    );
    return () => clearTimeout(timeOutId);
  }, [duration]);

  const handleChangeIsCumulative = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedData({
      ...selectedData,
      isCumulative: event.target.checked,
    });
  };

  const handleChangeIsActiveDuration = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setSelectedData({
      ...selectedData,
      isActiveDuration: event.target.checked,
    });
  };

  if (propertyName === "firstAlarm") {
    // eccezione per questa variabile
  }

  const handleChangeOperator = (event: SelectChangeEvent) => {
    const operator = event.target.value as Operator;
    setSelectedData({
      ...selectedData,
      operator,
    });
  };

  const handleDeleteCondition = () => {
    dispatch({ type: "REMOVE_CONDITION", variableName: propertyName });
    setSelectedVariables((prevState) =>
      prevState.filter((v) => v.propertyName !== propertyName),
    );
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <Box sx={{ flex: 0.5 }}>
        <DeleteOutline
          sx={{ cursor: "pointer" }}
          onClick={handleDeleteCondition}
        />
      </Box>
      <Typography sx={{ flex: 2 }}>{machine}</Typography>
      <Typography sx={{ flex: 3 }}>{propertyNameClient}</Typography>
      <FormControl sx={{ flex: 2 }}>
        <InputLabel id="select-operator-label">
          {translate("math.operator")}
        </InputLabel>
        <Select
          labelId="select-operator-label"
          id="select-operator"
          value={selectedData.operator}
          label={translate("math.operator")}
          size="small"
          onChange={handleChangeOperator}
        >
          {operators.map((operator) => (
            <MenuItem key={operator} value={operator}>
              {formatOperator(operator)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        id="value-1"
        label={`${translate("value")} 1`}
        type="number"
        size="small"
        value={selectedData.value1}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const value = parseInt(event.target.value);
          setSelectedData({
            ...selectedData,
            value1: value,
          });
        }}
        sx={{ flex: 1 }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        id="value-2"
        label={`${translate("value")} 2`}
        type="number"
        size="small"
        disabled={
          selectedData.operator === "InRange" ||
          selectedData.operator === "OutOfRange"
            ? false
            : true
        }
        value={selectedData.value2}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const value = parseInt(event.target.value);
          setSelectedData({
            ...selectedData,
            value2: value,
          });
        }}
        sx={{ flex: 1 }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Box sx={{ display: "flex", justifyContent: "center", flex: 1 }}>
        <Checkbox
          checked={selectedData.isCumulative}
          onChange={handleChangeIsCumulative}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", flex: 1 }}>
        <Checkbox
          checked={selectedData.isActiveDuration}
          onChange={handleChangeIsActiveDuration}
        />
      </Box>
      <Box sx={{ flex: 2 }}>
        <DurationInput setDuration={setDuration} size="small" />
      </Box>
      <FormControl sx={{ flex: 1.2 }}>
        <InputLabel id="select-uom-label">{translate("uom")}</InputLabel>
        <Select
          labelId="select-uom-label"
          id="select-uom"
          value={selectedData.uom}
          label={translate("uom")}
          size="small"
          onChange={handleChangeOperator}
        >
          {uom.map((singleUom) => (
            <MenuItem key={singleUom} value={singleUom}>
              {singleUom}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        id="rate"
        label={translate("threshold")}
        type="number"
        size="small"
        value={selectedData.rate}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const value = parseInt(event.target.value);
          setSelectedData({
            ...selectedData,
            rate: value,
          });
        }}
        sx={{ flex: 1 }}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{ inputProps: { min: 1 } }}
      />
    </Box>
  );
};
