import { BASE_HORIZONTAL_DIMENSION } from "@/features/OverallLineEfficiency/config";
import { useNodeId, useNodes } from "reactflow";

export const useGetNodeWidth = (): { width: number; handleWidth: number } => {
  const nodeId = useNodeId();
  const nodes = useNodes();
  const node = nodes.find((n) => n.id === nodeId);
  if (!node) {
    return {
      width: BASE_HORIZONTAL_DIMENSION,
      handleWidth: BASE_HORIZONTAL_DIMENSION * 0.05,
    };
  }

  const { width } = node;

  const handleWidth = width ? width * 0.05 : 0;

  return { width: width ?? BASE_HORIZONTAL_DIMENSION, handleWidth };
};
