import { Box, Card, Skeleton, styled } from "@mui/material";

export const CircleLayout = styled(Box)(() => ({
  backgroundColor: "#0D1626",
  color: "#fff",
  backgroundImage: "none",
  padding: "1rem",
  boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
  display: "flex",
  height: "100%",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  aspectRatio: "1/1",
  borderRadius: "50%",
  margin: "auto",
}));

export const SmallCircleLayout = styled(Box)(() => ({
  backgroundColor: "#0D1626",
  color: "#fff",
  backgroundImage: "none",
  padding: "1rem",
  boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
  display: "flex",
  maxWidth: "75%",
  minWidth: "75px",
  height: "100%",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  aspectRatio: "1/1",
  borderRadius: "50%",
  margin: "auto",
}));

export const SmallCircleLayoutLoader = styled(Skeleton)(() => ({
  backgroundColor: "#0D1626",
  color: "#fff",
  backgroundImage: "none",
  padding: "1rem",
  boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
  transform: "none",
  display: "flex",
  flexDirection: "column",
  maxWidth: "75%",
  minWidth: "75px",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
  aspectRatio: "1/1",
  borderRadius: "50%",
  margin: "auto",
}));

export const TestCircleLayout = styled(Card)<{
  size?: "large" | "small" | "extrasmall";
}>(({ size = "large" }) => ({
  backgroundColor: "#0D1626",
  color: "#fff",
  backgroundImage: "none",
  padding: "1rem",
  boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
  display: "flex",
  //  maxWidth: size === "small" ? "75%" : undefined,
  // minWidth: size === "small" ? "75px" : undefined,
  minWidth: size === "small" ? "25%" : size === "extrasmall" ? "20%" : "35%",
  // flexGrow: "1",
  // height: "100%",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  aspectRatio: "1/1",
  borderRadius: "50%",
  margin: "auto",
}));

export const TestCircleLayoutLoader = styled(Skeleton)<{
  size?: "large" | "small";
}>(({ size = "large" }) => ({
  backgroundColor: "#0D1626",
  color: "#fff",
  backgroundImage: "none",
  padding: "1rem",
  transform: "none",
  boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
  display: "flex",
  maxWidth: size === "small" ? "75%" : undefined,
  minWidth: size === "small" ? "75px" : undefined,
  height: "100%",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  aspectRatio: "1/1",
  borderRadius: "50%",
  margin: "auto",
}));

export const GaugeCircleLayout = styled(Card)<{ color?: string }>(
  ({ color = "#0D1626" }) => ({
    backgroundColor: color,
    color: "#fff",
    padding: "2rem",
    backgroundImage: "none",
    boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
    aspectRatio: "1/1",
    borderRadius: "50%",
  })
);

export const GaugeSkeleton = styled(Skeleton)(() => ({
  backgroundColor: "#0D1626",
  color: "#fff",
  padding: "2rem",
  backgroundImage: "none",
  boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
  aspectRatio: "1/1",
  transform: "none",
  borderRadius: "50%",
}));
