import { styled } from "@mui/system";
import { Card } from "@mui/material";
import { colors } from "@/styles/colors";

export const LightContainer = styled(Card)(() => ({
  backgroundColor: colors.palette.lightBlue,
  color: "#fff",
  width: "100%",
  minHeight: "100%",
  backgroundImage: "none",
  padding: "1rem",
  boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
  display: "flex",
  flexDirection: "column",
  gap: ".5rem",
  overflowY: "auto",
  borderRadius: ".25rem",
}));
