import { useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { IntellectaError, chat } from "../types";
import { intellectaClient } from "./intellectaClient";

type Response = IntellectaError | Result;

type Payload = {
  user: string;
};

const result = z.object({
  chat_history: z.array(chat),
});

type Result = z.infer<typeof result>;

export const useGetHistoricChatList = () => {
  const { user } = useFirebaseContext();
  const email = user?.email || "";

  return useQuery<Result>({
    queryKey: ["intellecta-historic", email],
    queryFn: async () => {
      const response = await intellectaClient<Payload, Response>({
        url: "/user/historic",
        payload: { user: email },
      });

      if (response === "ERROR") {
        throw new Error("An error occurred");
      }

      const validatedResponse = result.parse(response);
      return validatedResponse;
    },
    staleTime: 1000 * 60 * 60,
    onError: () => {
      toast.error("An error occurred");
    },
  });
};
