import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { QueryBuilder } from "@/utils/query-builder";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { CategoryCode } from "../CyclicLive/useGetSentinelLiveData";
import { z } from "zod";
import { useLanguage } from "@/i18n/config";

type ProcessDataResponse = ThingworxError | ProcessDataResult;

export const EfficiencyData = z.object({
  plotBands: z.object({
    data: z.array(z.array(z.number())),
    name: z.string(),
  }),
  response: z.boolean(),
  oee: z.array(z.array(z.number())),
  quality: z.array(z.array(z.number())),
});

export type EfficiencyData = z.infer<typeof EfficiencyData>;

export const KpiData = z.object({
  processQuality: z.number(),
  stopTimeTurn: z.number(),
  totalTime: z.number(),
  netUptime: z.number(),
  plannedProductionTime: z.number(),
  inactiveTime: z.number(),
  productionTime: z.number(),
  oee: z.number(),
  uptime: z.number(),
});

export type KpiData = z.infer<typeof KpiData>;

export interface PieChartDatum {
  color: string;
  name: string;
  y: number;
  z: number;
}

export interface RecipeParameters {
  response: boolean;
  Result: Result[];
}

export interface Result {
  Value: string;
  Name: string;
}

export interface ProcessDataResult {
  response: true;
  stateColor: string;
  efficiencyData: EfficiencyData;
  durationOfTimelapse: number;
  recipeClient: string;
  pieChartData: PieChartDatum[];
  recipe: string;
  stateString: string;
  recipeParameters: RecipeParameters;
  categoryCode: CategoryCode;
  batchIdentifier: string;
  kpiData: KpiData;
}

export const useGetProcessData = () => {
  const { machine } = useMachineContext();
  const { timeSelection, dates } = useTimeSelection();
  const { appKey } = useFirebaseContext();
  const language = useLanguage();
  const query = QueryBuilder.buildWithCondition({
    baseQuery: ["process-data", machine?.machine, timeSelection, language],
    condition: timeSelection === "Custom",
    query: [dates.dateStart, dates.dateEnd],
  });

  const payload = {
    machineName: machine?.machine || "",
    timeSelection,
    language_id: language,
  };

  return useQuery<ProcessDataResult>({
    queryKey: query,
    queryFn: async () => {
      const response = await FetchClient<
        {
          timeSelection: string;
          machineName: string;
          dateStart?: DateTime;
          dateEnd?: DateTime;
          language_id: string;
        },
        ProcessDataResponse
      >({
        appKey,
        payload:
          timeSelection === "Custom"
            ? { ...payload, dateStart: dates.dateStart, dateEnd: dates.dateEnd }
            : payload,
        url: "dig.c.machineDetailProcess_thing/Services/getData",
      });

      if (!response.response) throw new Error(response.errorString);
      return response;
    },
    refetchInterval: timeSelection === "Custom" ? false : 1000 * 30,
  });
};
