import { useState, useEffect } from "react";
import {
  ComparisonKpiType,
  KpiProperty,
} from "../api/useGetConfigurableOeeData";

export const useKpiDifferences = (props: ComparisonKpiType) => {
  const [kpiInfosDifferences, setKpiInfosDifferences] = useState<KpiProperty>({
    oee: 0,
    availability: 0,
    inactiveTime: 0,
    downtime: 0,
    performance: 0,
  });

  const oeeDiff = +(props.customKpi.oee - props.standardKpi.oee).toFixed(2);
  const availabilityDiff = +(
    props.customKpi.availability - props.standardKpi.availability
  ).toFixed(2);
  const performanceDiff = +(
    props.customKpi.performance - props.standardKpi.performance
  ).toFixed(2);
  const inactiveTimeDiff = +(
    props.customKpi.inactiveTime - props.standardKpi.inactiveTime
  ).toFixed(2);
  const downtimeDiff = +(
    props.customKpi.downtime - props.standardKpi.downtime
  ).toFixed(2);

  useEffect(() => {
    setKpiInfosDifferences({
      oee: oeeDiff,
      availability: availabilityDiff,
      inactiveTime: inactiveTimeDiff,
      downtime: downtimeDiff,
      performance: performanceDiff,
    });
  }, [
    props,
    oeeDiff,
    availabilityDiff,
    performanceDiff,
    inactiveTimeDiff,
    downtimeDiff,
  ]);

  return kpiInfosDifferences;
};
