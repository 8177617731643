import { useFirebaseContext } from "@/context/firebase-context";
import { useQuery } from "@tanstack/react-query";
import { Batch } from "../types";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { FetchClient } from "@/services/ApiClient";

type HistoricDataResponse = ThingworxError | HistoricData;

export const Status = z.enum(["Good", "Bad", "Not Analyzable", "Not Analyzed"]);
export type Status = z.infer<typeof Status>;

export const PhaseData = z.object({
  duration: z.number(),
  batch_id: z.number(),
  name: z.string(),
  batch_phase_id: z.number(),
  phase_duration: z.number(),
  phase_id: z.number(),
  from: z.number(),
  id: z.number(),
  to: z.number(),
  machine_name: z.string(),
  analyzable: z.boolean(),
  status: Status.optional(),
});

export type PhaseData = z.infer<typeof PhaseData>;

const HistoricData = z.object({
  response: z.literal(true),
  data: z.array(PhaseData),
});

export type HistoricData = z.infer<typeof HistoricData>;

export const useGetPhaseOverviewHistoricalData = ({
  batches,
}: {
  batches: Batch[];
}) => {
  const { appKey } = useFirebaseContext();

  const batchIds = batches.map((batch) => batch.batchId).sort((a, b) => a - b);

  return useQuery<HistoricData>({
    queryKey: ["overview-phases", batchIds],
    queryFn: async () => {
      const response = await FetchClient<
        { batch_ids: number[] },
        HistoricDataResponse
      >({
        appKey,
        url: "dig.c.lyophilizer_thing/Services/getPhases",
        payload: {
          batch_ids: batchIds,
        },
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return HistoricData.parse(response);
    },

    enabled: batchIds.length > 0,
    refetchInterval: 1000 * 30,
  });
};
