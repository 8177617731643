import { PointOptionsObject } from "highcharts";
import { HeatmapData } from "../types";
import { EVALUATION_COLORS } from "./evaluationColors";

export const parseHeatmapData = (
  data: HeatmapData[],
): {
  xCategories: string[];
  yCategories: string[];
  seriesData: PointOptionsObject[];
} => {
  const xCategories: string[] = [];
  const yCategories: string[] = [];
  const seriesData: PointOptionsObject[] = [];

  data.forEach((timeInterval, xIndex) => {
    xCategories.push(timeInterval.name);
    timeInterval.batches.forEach((batch) => {
      if (xIndex === 0) yCategories.push(batch.batchName);
      seriesData.push({
        x: xIndex,
        y: yCategories.indexOf(batch.batchName),
        color: EVALUATION_COLORS[batch.evaluation].selected,
        custom: {
          evaluation: batch.evaluation,
        },
      });
    });
  });

  xCategories.forEach((_, xIndex) => {
    yCategories.forEach((_, yIndex) => {
      const foundEl = seriesData.find(
        (el) => el.x === xIndex && el.y === yIndex,
      );
      if (!foundEl)
        seriesData.push({
          x: xIndex,
          y: yIndex,
          color: "transparent",
          custom: {
            evaluation: "undefined",
          },
        });
    });
  });

  return { xCategories, yCategories, seriesData };
};
