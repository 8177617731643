import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useSetBatchStop = () => {
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ stopBatchForced }: { stopBatchForced: boolean }) => {
      await FetchClient<
        { machineName: string; stopBatchForced: boolean },
        null
      >({
        appKey,
        payload: {
          machineName: machine!.machine,
          stopBatchForced,
        },
        url: "dig.c.batch_thing/Services/updateBatchStop",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["batch-navigator", machine!.machine],
      });
    },
  });
};
