import { TableParser } from "@/utils/TableParser";
import { BanInfoTransformed, UserLoginSchema } from "../api/useGetEdgePcData";
import { Card } from "@/components/Layout/Card";
import { TableTitle } from "../layout/StyledText";
import { BasicTable } from "@/components/BasicTable";
import { Stack } from "@mui/material";

export const UserLogin = ({
  userLoginData,
  banInfo,
}: {
  userLoginData: UserLoginSchema[];
  banInfo: BanInfoTransformed[];
}) => {
  const { columns, rows } = userLoginData.length
    ? TableParser.parseData({
        data: userLoginData,
        columnsMap: {
          username: "User",
          created_at_timestamp: "Created at",
          last_login_timestamp: "Last login",
        },
        columnsOrder: [
          "username",
          "created_at_timestamp",
          "last_login_timestamp",
        ],
      })
    : { columns: [], rows: [] };

  const { columns: banColumns, rows: banRows } = banInfo.length
    ? TableParser.parseData({
        data: banInfo,
        columnsMap: {
          ip: "IP address",
          failedAttempts: "Failed attempts",
          unbanTimestamp: "Unban timestamp",
          bans: "Number of bans",
        },
        columnsOrder: ["ip", "failedAttempts", "unbanTimestamp", "bans"],
      })
    : { columns: [], rows: [] };
  
  return (
    <Stack direction="row" spacing={2}>
      <Card>
        <TableTitle>Login</TableTitle>
        <BasicTable columns={columns} rows={rows} fileName="Login_data" />
      </Card>
      <Card>
        <TableTitle>A4Conf2Ban</TableTitle>
        <BasicTable columns={banColumns} rows={banRows} fileName="A4Conf2Ban_data" />
      </Card>
    </Stack>
  );
};
