import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { contextInfo, subphase, uom } from "../../types";
import { useIsolatorContext } from "../../context/useIsolatorContext";

export type GetDescriptiveRawDataResponse =
  | ThingworxError
  | GetDescriptiveRawDataResult;

type Payload = {
  machineName: string;
  batchIds: number[];
  analyticId: number;
  phaseId: number;
  allowMultiBatch: boolean;
};

const analyticSchema = z.union([
  z.object({
    isMultiBatch: z.literal(false),
    name: z.string(),
    uom: uom,
    subphasesData: z.array(
      z.object({
        subphase: subphase,
        data: z.array(z.array(z.number())),
      }),
    ),
    contextInfo: contextInfo,
  }),
  z.object({
    isMultiBatch: z.literal(true),
    name: z.string(),
    uom: uom,
    data: z.array(
      z.object({
        name: z.string(),
        id: z.number(),
        data: z.array(z.array(z.number())),
      }),
    ),
    contextInfo: contextInfo,
  }),
]);

const getDescriptiveRawDataResult = z.object({
  response: z.literal(true),
  chartData: z.array(analyticSchema),
});

export type GetDescriptiveRawDataResult = z.infer<
  typeof getDescriptiveRawDataResult
>;

export const useGetDescriptiveRawData = ({
  analyticId,
  phaseId,
  allowMultiBatch,
}: {
  analyticId: number;
  phaseId: number;
  allowMultiBatch: boolean;
}) => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const { batches } = useIsolatorContext();
  const batchIds = batches.map((batch) => batch.id);

  return useQuery<GetDescriptiveRawDataResult>({
    queryKey: [
      "descriptive-raw-data",
      machine!.machine,
      JSON.stringify(batchIds),
      analyticId,
      phaseId,
      allowMultiBatch,
    ],
    queryFn: async () => {
      const response = await FetchClient<
        Payload,
        GetDescriptiveRawDataResponse
      >({
        appKey,
        payload: {
          machineName: machine!.machine,
          batchIds,
          analyticId,
          phaseId,
          allowMultiBatch,
        },
        url: "dig.c.isolator_thing/Services/getDescriptiveRawData",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = getDescriptiveRawDataResult.parse(response);

      return validatedResponse;
    },
  });
};
