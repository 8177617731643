import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import {
  TestCircleLayoutLoader,
  SmallCircleLayoutLoader,
  GaugeSkeleton,
} from "@/features/MachineDetail/components/CircleLayout";
import { Box, Stack, Grid } from "@mui/material";

export const HistoricLoadingView = () => {
  return (
    <>
      <Grid container columns={12} alignItems="center" spacing={2}>
        <Grid item xs={6} md={2}>
          <TestCircleLayoutLoader height={"35%"} />
        </Grid>
        <Grid item xs={6} md={2}>
          <TestCircleLayoutLoader height={"35%"} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack gap={2}>
            <SkeletonCard height={100} />
            <SkeletonCard height={100} />
          </Stack>
        </Grid>
        <Grid alignItems="center" justifyContent="center" item xs={12} md={4}>
          <Box
            sx={{
              paddingX: "15%",
              height: {
                sx: "200px",
                md: "auto",
              },
            }}
          >
            <SmallCircleLayoutLoader />
          </Box>
        </Grid>
      </Grid>
      <Grid id="machine-detail-gauge" container columns={8} alignItems="center">
        <Grid
          item
          sx={{ margin: "auto" }}
          md={1}
          alignItems="center"
          flex={1}
          justifyContent="center"
        >
          <GaugeSkeleton
            sx={{
              padding: 1,
              margin: "auto",
              width: "9vmax",
              height: "9vmax",
            }}
          />
        </Grid>
        <Grid
          item
          sx={{ margin: "auto" }}
          md={1}
          alignItems="center"
          flex={1}
          justifyContent="center"
        >
          <GaugeSkeleton
            sx={{
              padding: 1,
              margin: "auto",
              width: "9vmax",
              height: "9vmax",
            }}
          />
        </Grid>
        <Grid
          item
          sx={{ margin: "auto" }}
          md={1}
          alignItems="center"
          flex={1}
          justifyContent="center"
        >
          <GaugeSkeleton
            sx={{
              padding: 1,
              margin: "auto",
              width: "9vmax",
              height: "9vmax",
            }}
          />
        </Grid>
        <Grid
          item
          sx={{ margin: "auto" }}
          md={2}
          alignItems="center"
          flex={1}
          justifyContent="center"
        >
          <GaugeSkeleton
            sx={{
              padding: 1,
              margin: "auto",
              width: "12vmax",
              height: "12vmax",
            }}
          />
        </Grid>
        <Grid
          item
          sx={{ margin: "auto" }}
          md={1}
          alignItems="center"
          flex={1}
          justifyContent="center"
        >
          <GaugeSkeleton
            sx={{
              padding: 1,
              margin: "auto",
              width: "9vmax",
              height: "9vmax",
            }}
          />
        </Grid>
        <Grid
          item
          sx={{ margin: "auto" }}
          md={1}
          alignItems="center"
          flex={1}
          justifyContent="center"
        >
          <GaugeSkeleton
            sx={{
              padding: 1,
              margin: "auto",
              width: "9vmax",
              height: "9vmax",
            }}
          />
        </Grid>
        <Grid
          item
          sx={{ margin: "auto" }}
          md={1}
          alignItems="center"
          flex={1}
          justifyContent="center"
        >
          <GaugeSkeleton
            sx={{
              padding: 1,
              margin: "auto",
              width: "9vmax",
              height: "9vmax",
            }}
          />
        </Grid>
      </Grid>
      <SkeletonCard height={300} />
      <SkeletonCard height={300} />
    </>
  );
};
