import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { ThingworxError } from "src/types/error";
import { TimeSelection, useTimeSelection } from "@/store/useTimeSelection";

const anomaliesData = z.object({
  id: z.number(),
  duration: z.number(),
  time_threshold: z.number(),
  ts_start: z.number(),
  ts_end: z.number(),
  machine_id: z.string(),
  reason: z.string(),
  is_resolved: z.boolean(),
});

export type AnomaliesData = z.infer<typeof anomaliesData>;

const anomaliesResult = z.object({
  response: z.literal(true),
  data: z.array(anomaliesData),
  totalAnomalies: z.number(),
  resolvedAnomalies: z.number(),
  resolvedAnomaliesPercentage: z.number(),
  meanDuration: z.number(),
  currentThreshold: z.number(),
  goodTime: z.number(),
  goodTimePercentage: z.number(),
  badTime: z.number(),
  badTimePercentage: z.number(),
});

export type AnomaliesResult = z.infer<typeof anomaliesResult>;

type Result = ThingworxError | AnomaliesResult;

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
  dateStart?: number;
  dateEnd?: number;
};

export const useGetAnomalies = ({ machineName }: { machineName: string }) => {
  const { appKey } = useFirebaseContext();
  const { dates, timeSelection } = useTimeSelection();

  const payload = {
    machineName,
    timeSelection,
  };

  return useQuery<AnomaliesResult>({
    queryKey: [
      "get-anomalies",
      machineName,
      timeSelection,
      JSON.stringify(dates),
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, Result>({
        url: "dig.c.dataInsights_thing/services/getAnomalies",
        appKey,
        payload:
          timeSelection === "Custom"
            ? {
                machineName,
                timeSelection,
                dateStart: dates.dateStart.toMillis(),
                dateEnd: dates.dateEnd.toMillis(),
              }
            : payload,
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }
      return response;
    },
  });
};
