import { Stack } from "@mui/material";
import { SocialNotification } from "./SocialNotification";
import { Notification } from "../../types";

export const Social = ({
  notifications,
  onUsePrompt,
}: {
  notifications: Notification[];
  onUsePrompt: (prompt: string) => void;
}) => {
  const pinnedNotifications: Notification[] = [];
  const unpinnedNotifications: Notification[] = [];
  notifications.forEach((notification) => {
    if (notification.is_pinned) {
      pinnedNotifications.push(notification);
    } else {
      unpinnedNotifications.push(notification);
    }
  });
  const sortedPinnedNotifications = pinnedNotifications.sort(
    (a, b) => b.timestamp - a.timestamp,
  );
  const sortedUnpinnedNotifications = unpinnedNotifications.sort(
    (a, b) => b.timestamp - a.timestamp,
  );

  return (
    <Stack p={2} gap={1}>
      {sortedPinnedNotifications.map((notification) => (
        <SocialNotification
          key={notification.id}
          notification={notification}
          onUsePrompt={onUsePrompt}
        />
      ))}
      {sortedUnpinnedNotifications.map((notification) => (
        <SocialNotification
          key={notification.id}
          notification={notification}
          onUsePrompt={onUsePrompt}
        />
      ))}
    </Stack>
  );
};
