import { useGetNoveltyAndAnomalyAnalytics } from "../../api/Diagnostic/useGetNoveltyAndAnomalyAnalytics";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { Card } from "@/components/Layout/Card";
import { NoveltyAnomalyList } from "./NoveltyAnomalyList";
import { useTranslate } from "@/i18n/config";

interface NoveltyAnomalyAnalyticsSelectionProps {
  noveltyAnalytics: number[];
  setNoveltyAnalytics: React.Dispatch<React.SetStateAction<number[]>>;
  anomalyAnalytics: number[];
  setAnomalyAnalytics: React.Dispatch<React.SetStateAction<number[]>>;
}

export const NoveltyAnomalyAnalyticsSelection = ({
  noveltyAnalytics,
  anomalyAnalytics,
  setNoveltyAnalytics,
  setAnomalyAnalytics,
}: NoveltyAnomalyAnalyticsSelectionProps) => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetNoveltyAndAnomalyAnalytics();

  if (isLoading) {
    return <AnalyticContainerSkeleton sx={{ width: 320, height: 400 }} />;
  }

  if (error) {
    return (
      <Card sx={{ width: 320, height: 400 }}>
        {translate("user_feedback.an_error_occurred")}
      </Card>
    );
  }

  if (!data) {
    return (
      <Card sx={{ width: 320, height: 400 }}>
        {translate("user_feedback.no_data")}
      </Card>
    );
  }

  return (
    <Card sx={{ width: 320, height: 400, overflowY: "auto" }}>
      <NoveltyAnomalyList
        listName={translate("diagnostic.novelty_detection")}
        listItems={data.analyticsList.noveltyAnalytics}
        selectedAnalytics={noveltyAnalytics}
        setSelectedAnalytics={setNoveltyAnalytics}
      />
      <NoveltyAnomalyList
        listName={translate("diagnostic.anomaly_detection")}
        listItems={data.analyticsList.anomalyAnalytics}
        selectedAnalytics={anomalyAnalytics}
        setSelectedAnalytics={setAnomalyAnalytics}
      />
    </Card>
  );
};
