export const parseSecondsLeft = (secondsLeft: number): string => {
  let remainingTime = "";

  if (secondsLeft > 240) {
    remainingTime = "5 minutes";
  } else if (secondsLeft > 180) {
    remainingTime = "4 minutes";
  } else if (secondsLeft > 120) {
    remainingTime = "3 minutes";
  } else if (secondsLeft > 60) {
    remainingTime = "2 minutes";
  } else if (secondsLeft > 30) {
    remainingTime = "1 minute";
  } else if (secondsLeft > 1) {
    remainingTime = `${secondsLeft} seconds`;
  } else {
    remainingTime = "1 second";
  }
  return remainingTime;
};
