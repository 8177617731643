/* eslint-disable @typescript-eslint/ban-ts-comment */
import { HighchartsCustomProvider } from "@/components/highcharts/highcharts";

import {
  HighchartsChart,
  Chart,
  YAxis,
  Tooltip,
  Title,
  VariablePieSeries,
  Subtitle,
} from "react-jsx-highcharts";
import { Evaluation, Evaluations } from "../../types";
import { formatStatus } from "../../utils/formatStatus";
import { mapStatus } from "../../utils/mapStatus";
import { useTranslate } from "@/i18n/config";

export interface PieDataProps {
  name: Evaluation;
  y: number;
  color: string;
  frameOccurrences: number;
  preFrameOccurrences: number;
}

interface SummaryPieChartProps {
  title: string;
  goodFrames: number;
  totalFrames: number;
  pieData: Evaluations;
}

function generateSummaryPieChart(data: Evaluations): PieDataProps[] {
  const pieData: PieDataProps[] = Object.entries(data).map(([name, values]) => {
    return {
      name: name as Evaluation,
      y: values.globalCount,
      color: mapStatus(name as Evaluation),
      frameOccurrences: values.frameCount,
      preFrameOccurrences: values.preFrameCount,
    };
  });

  return pieData;
}

export const SummaryPieChart = ({
  title,
  goodFrames,
  totalFrames,
  pieData,
}: SummaryPieChartProps) => {
  const formattedPieData = generateSummaryPieChart(pieData);
  const translate = useTranslate();

  return (
    <HighchartsCustomProvider>
      <HighchartsChart
        plotOptions={{
          variablepie: {
            dataLabels: { enabled: false },
            size: "100%",
            center: ["50%", "49.9%"],
            states: {
              inactive: {
                enabled: false,
              },
              hover: {
                halo: null,
              },
            },
          },
        }}
        accessibility={{ enabled: false }}
        exporting={{
          enabled: false,
        }}
      >
        <Chart
          backgroundColor={"transparent"}
          type="variablepie"
          alignTicks={false}
          margin={[0, 5, 0, 5]}
        />
        <Tooltip
          formatter={function () {
            // return `<span style="color:${point.color}">\u25CF</span>
            //   <b>${point.name}<br>
            //   Frame: ${point.y}<br/>
            //   Pre-frame: ${point.preFrameOccurrences}</b>`;

            const name = Evaluation.parse(this.point.name);
            // @ts-ignore
            const frameOccurrences = this.point.frameOccurrences;
            // @ts-ignore
            const preFrameOccurrences = this.point.preFrameOccurrences;

            return `<span style="color:${this.point.color}">\u25CF</span>
             ${formatStatus(name)}<br>
             ${translate("frame")}: <b>${frameOccurrences}</b><br/>
             ${translate("pre_frame")}: <b>${preFrameOccurrences}</b>`;
          }}
        />
        <YAxis>
          <Title
            style={{ color: "#ddd", fontWeight: "bold", fontSize: "120%" }}
            y={5}
            align={"center"}
            verticalAlign={"middle"}
          >
            {title}
          </Title>
          <Subtitle
            style={{ color: "white" }}
            align={"center"}
            verticalAlign={"middle"}
            y={30}
          >
            {`${goodFrames}/${totalFrames}`}
          </Subtitle>
          <VariablePieSeries
            minPointSize={"20%"}
            zMin={20}
            innerSize={"90%"}
            borderWidth={0}
            data={formattedPieData}
          />
        </YAxis>
      </HighchartsChart>
    </HighchartsCustomProvider>
  );
};
