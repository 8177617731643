import { HighchartsCustomProvider } from "@/components/highcharts/highcharts";
import {
  BarSeries,
  Chart,
  HighchartsChart,
  Tooltip,
  XAxis,
  YAxis,
} from "react-jsx-highcharts";
import { renderToString } from "react-dom/server";
import { z } from "zod";
import { useTranslate } from "@/i18n/config";
import { Evaluation } from "../../../types";

export const AnalyticBarChartProps = z.object({
  categories: z.array(z.string()).optional(),
  data: z.array(
    z.object({
      name: z.string(),
      color: z.string(),
      data: z.array(z.number()),
    }),
  ),
});

export type AnalyticBarChartProps = z.infer<typeof AnalyticBarChartProps>;

export interface AnalyticBarChartData {
  name: string;
  color: string;
  data: number[];
}

export const HealthStatusBarChart = ({
  data,
  categories = ["Status"],
}: AnalyticBarChartProps) => {
  const translate = useTranslate();
  const clonedData = data
    .map((item) => {
      return { ...item };
    })
    .reverse();

  return (
    <HighchartsCustomProvider>
      <HighchartsChart
        accessibility={{ enabled: false }}
        exporting={{ enabled: false }}
      >
        <Chart backgroundColor={"transparent"} type="bar" margin={0} />
        <Tooltip
          formatter={function () {
            const point = this.point;

            const color = z.string().safeParse(point.color);

            const seriesName = point.series.name as Evaluation;

            return renderToString(
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem",
                }}
              >
                <div>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: "\u25CF ",
                    }}
                    style={{
                      color: color.success ? color.data : "black",
                    }}
                  />
                  {translate(`evaluation.${seriesName}`)}: <b>{point.y}</b>
                </div>
              </div>,
            );
          }}
          outside={true}
        />
        <XAxis categories={categories} visible={false}></XAxis>
        <YAxis visible={false}>
          {clonedData.map((dataset) => (
            <BarSeries
              key={dataset.name}
              stacking="percent"
              name={dataset.name}
              borderColor="none"
              color={dataset.color}
              data={dataset.data}
            />
          ))}
        </YAxis>
      </HighchartsChart>
    </HighchartsCustomProvider>
  );
};
