import {
  DataGridPremium,
  GridColDef,
  GridToolbar,
  enUS,
  itIT,
  esES,
  deDE,
} from "@mui/x-data-grid-premium";
import { useTranslate, useLanguage, Translations } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";
import { useFormatTimestamp } from "@/utils/TimeHelpers";
import { ExportData } from "../api/getExportData";

export function ExportTable({ exportData }: { exportData: ExportData }) {
  const translate = useTranslate();
  const language = useLanguage();
  const { formatTimestamp } = useFormatTimestamp();

  const getHeaderName = (key: string) => {
    const headerMappings: Record<string, string> = {
      state: "state",
      "alarm code": "code",
      imputation: "stop_imputation.imputation",
      "alarm is active": "events.active",
    };

    const translationKey = headerMappings[key];
    return translationKey
      ? translate(translationKey as Translations)
      : (key.charAt(0).toUpperCase() + key.slice(1)).replaceAll("_", " ");
  };

  const nCols = Object.keys(exportData[0]).length;

  const columns: GridColDef[] = [
    {
      field: "timestamp",
      headerName: translate("date"),
      type: "dateTime",
      valueFormatter: ({ value }) => {
        return (
          value &&
          formatTimestamp({
            timestamp: +new Date(value),
            skipTimezoneTransform: true,
          })
        );
      },
      valueGetter: ({ value }) => {
        const date =
          value &&
          formatTimestamp({
            timestamp: +new Date(value),
            skipLocale: true,
          });
        return value ? new Date(date) : null;
      },
      width: 200,
    },
    ...Object.keys(exportData[0])
      .filter((key) => key !== "timestamp")
      .map((key) => ({
        field: key,
        headerName: getHeaderName(key),
        width: nCols < 5 ? 250 : 150,
        type: typeof exportData[0][key] === "boolean" ? "boolean" : "string",
      })),
  ];

  const rows = exportData.map((row, index) => ({
    id: index,
    ...row,
  }));

  return (
    <Card>
      <DataGridPremium
        localeText={
          language === "en"
            ? enUS.components.MuiDataGrid.defaultProps.localeText
            : language === "it"
            ? itIT.components.MuiDataGrid.defaultProps.localeText
            : language === "es"
            ? esES.components.MuiDataGrid.defaultProps.localeText
            : language === "de"
            ? deDE.components.MuiDataGrid.defaultProps.localeText
            : enUS.components.MuiDataGrid.defaultProps.localeText
        }
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            excelOptions: {
              fileName: "Sentinel-Download",
            },
            csvOptions: {
              fileName: "Sentinel-Download",
            },
          },
        }}
        columns={columns}
        rows={rows}
        pagination={true}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10, 25, 50]} // Numero di elementi per pagina
      />
    </Card>
  );
}
