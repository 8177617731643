import { useState } from "react";
import { Stack, Tab, Tabs } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { useGetData } from "./api/useGetData";
import { StatesLogChart } from "./components/StatesLogChart";
import { StatesLogTable } from "./components/StatesLogTable";
import { SelectTimeSelection } from "@/components/SelectTimeSelection";

const SECTIONS = {
  STANDARD: 0,
  GROUPED_BY_ROOT_CAUSE: 1,
};

export const StateLog = () => {
  const translate = useTranslate();
  const [dateRange, setDateRange] = useState<{
    min: number;
    max: number;
  } | null>(null);
  const [section, setSection] = useState(SECTIONS.STANDARD);
  const { data, isLoading, error } = useGetData();

  if (isLoading) {
    return (
      <>
        <MachineViewNavBar>
          <SelectTimeSelection
            exclude={["TwoWeeks", "Month", "ThreeMonths", "SixMonths", "Year"]}
          />
        </MachineViewNavBar>
        <Stack gap={2}>
          <Tabs
            value={section}
            aria-label="states log tabs"
            onChange={(_: React.SyntheticEvent, newValue: number) => {
              setSection(newValue);
            }}
          >
            <Tab
              value={SECTIONS.STANDARD}
              label={translate("configurable_oee.standard")}
            />
            <Tab
              value={SECTIONS.GROUPED_BY_ROOT_CAUSE}
              label={translate("state_log.grouped_by_root_cause")}
            />
          </Tabs>
          <SkeletonCard height={250}></SkeletonCard>
          <SkeletonCard height={250}></SkeletonCard>
        </Stack>
      </>
    );
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  const handleSetExtremes = ({ min, max }: { min: number; max: number }) => {
    setDateRange({ min, max });
  };

  return (
    <>
      <MachineViewNavBar>
        <SelectTimeSelection
          exclude={["TwoWeeks", "Month", "ThreeMonths", "SixMonths", "Year"]}
        />
      </MachineViewNavBar>
      <Stack gap={2}>
        <Tabs
          value={section}
          aria-label="states log tabs"
          onChange={(_: React.SyntheticEvent, newValue: number) => {
            setSection(newValue);
          }}
        >
          <Tab
            value={SECTIONS.STANDARD}
            label={translate("configurable_oee.standard")}
          />
          <Tab
            value={SECTIONS.GROUPED_BY_ROOT_CAUSE}
            label={translate("state_log.grouped_by_root_cause")}
          />
        </Tabs>
        <StatesLogChart
          key={
            section === SECTIONS.STANDARD
              ? `state-log-standard-chart-${data.data.length}`
              : `state-log-grouped-chart-${data.rootCauseGroupedData.length}`
          }
          data={
            section === SECTIONS.STANDARD
              ? data.data
              : data.rootCauseGroupedData
          }
          onSetExtremes={handleSetExtremes}
        />
        <StatesLogTable
          key={
            section === SECTIONS.STANDARD
              ? `state-log-standard-table-${data.data.length}`
              : `state-log-grouped-table-${data.rootCauseGroupedData.length}`
          }
          data={
            section === SECTIONS.STANDARD
              ? data.data
              : data.rootCauseGroupedData
          }
          extremes={dateRange}
        />
      </Stack>
    </>
  );
};
