import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { QueryBuilder } from "@/utils/query-builder";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { useLanguage } from "@/i18n/config";

type ChangeOverListResponse = ThingworxError | ChangeOverListResult;

const ChangeOverInfo = z.object({
  ChangeDuration: z.number(),
  OldRecipe: z.string(),
  State: z.enum(["Start", "End"]),
  Timestamp: z.number(),
  Machine: z.string(),
  NewRecipe: z.string(),
});
export type ChangeOverInfo = z.infer<typeof ChangeOverInfo>;

const ChangeOverListResult = z.object({
  response: z.literal(true),
  Result: z.array(ChangeOverInfo),
});

export type ChangeOverListResult = z.infer<typeof ChangeOverListResult>;

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
  dateEnd?: DateTime;
  dateStart?: DateTime;
  language_id: string;
};

/**
 * return the data for the upper tables that has the red and green circles
 */

export const useGetChangeOverList = () => {
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();

  const { timeSelection, dates } = useTimeSelection();
  const language = useLanguage();

  const query = QueryBuilder.buildWithCondition({
    baseQuery: ["change-over-times", machine?.machine, timeSelection, language],
    condition: timeSelection === "Custom",
    query: [dates.dateStart, dates.dateEnd],
  });

  const payload: Payload = {
    machineName: machine?.machine || "",
    timeSelection,
    language_id: language,
  };

  return useQuery<ChangeOverInfo[]>({
    queryKey: query,
    queryFn: async () => {
      const response = await FetchClient<Payload, ChangeOverListResponse>({
        appKey,
        payload:
          timeSelection === "Custom"
            ? { ...payload, dateStart: dates.dateStart, dateEnd: dates.dateEnd }
            : payload,
        url: "dig.c.changeOver_thing/Services/getEndStartSelector",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = ChangeOverListResult.parse(response);

      return validatedResponse.Result;
    },
  });
};
