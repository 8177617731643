import { useTranslate } from "@/i18n/config";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectProps,
  CircularProgress,
  Box,
} from "@mui/material";
import { useState } from "react";

interface ListSelectDropdownProps<T> {
  data?: T[];
  /**
   * @example (item: Batch) => <MenuItem value={item.id}>{item.name}</MenuItem>
   */
  renderItem: (item: T) => React.ReactNode;
  /**
   * @deprecated just use renderItem and pass a key to it
   */
  keyExtractor?: (item: T) => string;
  onChange: (itemKey: string) => void;
  menuProps?: SelectProps<string>;
  // value: T;
  textlabel: string;
  defaultValue?: string;
  disabled?: boolean;
  isLoading?: boolean;
}

/**
 *
 * this is an attempt to a generic dropdown that can handles multiple tipe of lists,
 * the idea is to pass the data, the keyExtractor and the renderItem function to render the list
 * @link https://medium.com/edonec/creating-a-generic-component-with-react-typescript-2c17f8c4386e
 */
export const ListSelectDropdown = <T,>({
  data = [],
  onChange,
  renderItem,
  textlabel,
  defaultValue,
  menuProps,
  disabled,
  isLoading = false,
}: ListSelectDropdownProps<T>) => {
  const translate = useTranslate();
  const [selectedItem, setSelectedItem] = useState(defaultValue);
  return (
    <FormControl>
      <InputLabel size="small" id="batch-selection">
        {textlabel}
      </InputLabel>
      <Select
        IconComponent={
          isLoading
            ? () => {
                return (
                  <Box
                    sx={{
                      marginRight: 1,
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress size={18} />
                  </Box>
                );
              }
            : undefined
        }
        disabled={disabled}
        sx={{ minWidth: "10rem" }}
        labelId="batch-selection"
        label={textlabel}
        size="small"
        value={selectedItem}
        {...menuProps}
        autoWidth
        onChange={(e) => {
          setSelectedItem(e.target.value);
          onChange(e.target.value);
        }}
      >
        {data && data.length > 0 ? (
          data.map((listItem) => renderItem(listItem))
        ) : (
          <MenuItem>{translate("user_feedback.no_data")}</MenuItem>
        )}
      </Select>
    </FormControl>
  );
};
