import { z } from "zod";

export const Evaluation = z.enum([
  "good",
  "warning",
  "critical",
  "lack_of_data",
  "no_data",
  "undefined",
]);

export type Evaluation = z.infer<typeof Evaluation>;

export const evaluationColors = z.object({
  good: z.object({
    selected: z.string(),
    notSelected: z.string(),
  }),
  warning: z.object({
    selected: z.string(),
    notSelected: z.string(),
  }),
  critical: z.object({
    selected: z.string(),
    notSelected: z.string(),
  }),
  lack_of_data: z.object({
    selected: z.string(),
    notSelected: z.string(),
  }),
  no_data: z.object({
    selected: z.string(),
    notSelected: z.string(),
  }),
  undefined: z.object({
    selected: z.string(),
    notSelected: z.string(),
  }),
  aborted: z.object({
    selected: z.string(),
    notSelected: z.string(),
  }),
});

export type EvaluationColors = z.infer<typeof evaluationColors>;

export const Evaluations = z.record(
  Evaluation,
  z.object({
    frameCount: z.number(),
    preFrameCount: z.number(),
    globalCount: z.number(),
  }),
);

export type Evaluations = z.infer<typeof Evaluations>;

export const Frame = z.object({
  timelapse: z.string(),
  dateStart: z.number(),
  dateEnd: z.number(),
  id: z.number(),
  status: Evaluation,
});

export type Frame = z.infer<typeof Frame>;

const HeatmapSeriesData = z.object({
  name: z.string(),
  borderWidth: z.number(),
  colorAxis: z.number(),
  data: z.array(
    z.object({
      x: z.number(),
      y: z.number(),
      value: z.number(),
      borderColor: z.string(),
    }),
  ),
  dataLabels: z.object({
    enabled: z.boolean(),
    color: z.string(),
    borderColor: z.string(),
  }),
});

export type HeatmapSeriesData = z.infer<typeof HeatmapSeriesData>;

export const ContextInfo = z.record(
  z.union([
    z.string(),
    z.number(),
    z.record(z.union([z.string(), z.number()])),
  ]),
);

export type ContextInfo = z.infer<typeof ContextInfo>;

export const run = z.object({
  id: z.number(),
  name: z.string(),
  start: z.number(),
  end: z.number(),
  evaluation: Evaluation,
});

export type Run = z.infer<typeof run>;
