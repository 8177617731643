import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type BatchesAndRecipesResponse = ThingworxError | BatchesAndRecipesResult;

const Item = z.object({
  timelapse: z.string(),
  dateStart: z.number(),
  dateEnd: z.number(),
  id: z.number(),
});

export type Item = z.infer<typeof Item>;

const BatchesAndRecipesResult = z.object({
  response: z.literal(true),
  recipes: z.array(Item),
  batches: z.array(Item),
});

export type BatchesAndRecipesResult = z.infer<typeof BatchesAndRecipesResult>;

interface Payload {
  machineName: string;
  dateStart: DateTime;
  dateEnd: DateTime;
}

export const useGetBatchesAndRecipes = () => {
  const { machine } = useMachineContext();

  const {
    dates: { dateStart, dateEnd },
  } = useTimeSelection();

  const { appKey } = useFirebaseContext();

  return useQuery<{ items: { recipes: Item[]; batches: Item[] } }>({
    queryKey: ["batches-and-recipes", machine?.machine, dateStart, dateEnd],
    queryFn: async () => {
      const response = await FetchClient<Payload, BatchesAndRecipesResponse>({
        appKey,
        payload: {
          dateStart,
          dateEnd,
          machineName: machine?.machine || "",
        },
        url: "dig.c.motors_thing/Services/getBatchesAndRecipes",
      });

      if (!response.response) throw new Error(response.errorString);

      const validatedResponse = BatchesAndRecipesResult.parse(response);
      return {
        items: {
          recipes: validatedResponse.recipes,
          batches: validatedResponse.batches,
        },
      };
    },
  });
};
