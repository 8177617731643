type RGB = { r: number; g: number; b: number };

export function generateColorScale(
  colorStart: RGB,
  colorEnd: RGB,
  rangeSize: number
) {
  const colors = [];
  const rDiff = colorEnd.r - colorStart.r;
  const gDiff = colorEnd.g - colorStart.g;
  const bDiff = colorEnd.b - colorStart.b;
  const step = rangeSize > 1 ? rangeSize - 1 : 1;
  for (let i = 0; i < rangeSize; i++) {
    const r = colorStart.r + (rDiff / step) * i;
    const g = colorStart.g + (gDiff / step) * i;
    const b = colorStart.b + (bDiff / step) * i;
    colors.push(`rgb(${r},${g},${b})`);
  }
  return colors;
}

export const convertColorToRgbString = (color: RGB): string => {
  return `rgb(${color.r},${color.g},${color.b})`;
};

export const darkMin: RGB = { r: 150, g: 0, b: 0 };
export const darkMedian: RGB = { r: 0, g: 150, b: 0 };
export const darkMax: RGB = { r: 0, g: 0, b: 150 };

export const lightMin: RGB = { r: 255, g: 200, b: 200 };
export const lightMedian: RGB = { r: 200, g: 255, b: 200 };
export const lightMax: RGB = { r: 200, g: 200, b: 255 };
