import { Handle, Position } from "reactflow";
import { useGetNodeWidth } from "./useGetNodeWidth";

/**
 * Ascii representation of a node, showing the position of the edges and their respective ids
 *
 *      2     4     6
 *    --|-----|-----|--
 *  1 -               - 8
 *    --|-----|-----|--
 *      3     5     7
 *
 */
export const useHandleStyle = (
  id: string
): Pick<React.ComponentProps<typeof Handle>, "style" | "position"> => {
  const { width, handleWidth } = useGetNodeWidth();

  switch (id) {
    case "1":
      return { style: {}, position: Position.Left };
    case "2":
      return {
        style: { left: width - handleWidth },
        position: Position.Top,
      };
    case "3":
      return {
        style: { left: width - handleWidth },
        position: Position.Bottom,
      };
    case "4":
      return { style: {}, position: Position.Top };

    case "5":
      return { style: {}, position: Position.Bottom };
    case "6":
      return {
        style: { left: handleWidth },
        position: Position.Top,
      };
    case "7":
      return {
        style: { left: handleWidth },
        position: Position.Bottom,
      };
    case "8":
      return { style: {}, position: Position.Right };

    default:
      throw new Error(`Invalid handle id: ${id}`);
  }
};
