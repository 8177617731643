import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { contextInfo, heatmapData, lineData } from "../../types";
import { useIsolatorContext } from "../../context/useIsolatorContext";

const analyticSchema = z.union([
  z.object({
    analyticName: z.literal("Preconditioning"), //? 1
    chartType: z.literal("lines"),
    batchData: z.array(lineData),
    goldenData: z.array(z.array(z.number())),
    contextInfo,
  }),
  z.object({
    analyticName: z.literal("Anomaly detection"), //? 2
    chartType: z.literal("heatmap"),
    data: z.array(heatmapData),
    contextInfo,
  }),
  z.object({
    analyticName: z.literal("Novelty detection"), //? 3
    chartType: z.literal("heatmap"),
    data: z.array(heatmapData),
    contextInfo,
  }),
  z.object({
    analyticName: z.literal("Entropic factor"), //? 4
    chartType: z.literal("lines_with_limits"),
    data: z.array(z.array(z.number())),
    thresholds: z.object({
      critical: z.number(),
      warning: z.number(),
    }),
    contextInfo,
  }),
  z.object({
    analyticName: z.literal("Overall Risk Index"), //? 5
    chartType: z.literal("lines_with_limits"),
    data: z.array(lineData),
    thresholds: z.object({
      criticalUpper: z.number(),
      criticalLower: z.number(),
      warningUpper: z.number(),
      warningLower: z.number(),
    }),
    contextInfo,
  }),
]);

export type AnalyticSchema = z.infer<typeof analyticSchema>;

export type GetBatchDiagnosticDataResponse =
  | ThingworxError
  | GetBatchDiagnosticDataResult;

const getBatchDiagnosticDataResult = z.object({
  response: z.literal(true),
  chartData: z.array(analyticSchema),
});

export type GetBatchDiagnosticDataResult = z.infer<
  typeof getBatchDiagnosticDataResult
>;

type Payload = {
  machineName: string;
  batchIds: number[];
  analyticId: number;
  assetIds: number[];
};

export const useGetBatchDiagnosticData = ({
  analyticId,
  assetIds = [],
}: {
  analyticId: number;
  assetIds: number[];
}) => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const { batches } = useIsolatorContext();
  const batchIds = batches.map((batch) => batch.id);

  return useQuery<GetBatchDiagnosticDataResult>({
    queryKey: [
      "batch-diagnostic-data",
      machine!.machine,
      JSON.stringify(batchIds),
      analyticId,
      JSON.stringify(assetIds),
    ],
    queryFn: async () => {
      const response = await FetchClient<
        Payload,
        GetBatchDiagnosticDataResponse
      >({
        appKey,
        payload: {
          machineName: machine!.machine,
          batchIds,
          analyticId,
          assetIds,
        },
        url: "dig.c.isolator_thing/Services/getBatchDiagnosticData",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = getBatchDiagnosticDataResult.parse(response);

      return validatedResponse;
    },
  });
};
