import { create } from "zustand";

export type Sender = "Intellecta" | "User" | "Sentry Mode";

export type Message = {
  sender: Sender;
  message: string;
  id?: number;
  image?: string;
  timestamp?: number;
  sources?: string[];
};

export const chatStore = create<{
  messages: Message[];
  addMessage: (message: Message) => void;
  chatId: number | null;
  setChatId: (chatId: number) => void;
  clearChat: () => void;
  isAnswering: boolean;
  setIsAnswering: (isAnswering: boolean) => void;
}>((set) => ({
  messages: [],

  addMessage: (message) => {
    set((state) => {
      const newState = { ...state };

      newState.messages.push(message);

      return newState;
    });
  },

  chatId: null,

  setChatId: (chatId) => {
    set((state) => {
      const newState = { ...state };

      newState.chatId = chatId;

      return newState;
    });
  },

  clearChat: () => {
    set((state) => {
      const newState = { ...state };

      newState.messages = [];
      newState.chatId = null;

      return newState;
    });
  },

  isAnswering: false,

  setIsAnswering: (isAnswering) => {
    set((state) => {
      const newState = { ...state };

      newState.isAnswering = isAnswering;

      return newState;
    });
  },
}));
