import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { Run, lap } from "../../types";
import { useAcoposContext } from "../../context/acopos-context";

export type GetLapsResponse = ThingworxError | GetLapsResult;

type Payload = {
  machineName: string;
  runs: Run[];
};

const getLapsResult = z.object({
  response: z.literal(true),
  laps: z.record(z.array(lap)),
});

export type GetLapsResult = z.infer<typeof getLapsResult>;

export const useGetLaps = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const { runs } = useAcoposContext();

  return useQuery<GetLapsResult>({
    queryKey: ["laps-data", machine!.machine, JSON.stringify(runs)],
    queryFn: async () => {
      const response = await FetchClient<Payload, GetLapsResponse>({
        appKey,
        payload: {
          machineName: machine!.machine,
          runs,
        },
        // url: "dig.c.acopostrak_thing/Services/getLaps",
        url: "dig.c.acoposTrack_thing/Services/getLaps",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = getLapsResult.parse(response);

      return validatedResponse;
    },
  });
};
