import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  TextField,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
// import { toast } from "react-hot-toast";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslate } from "@/i18n/config";
import { useAddPrompt } from "../../api/useAddPrompt";
import { Loader } from "@/components/Layout/Loader";

interface IFormInput {
  prompt: string;
  label: string;
  description: string;
}

export const AddPromptModal = ({
  prompt,
  isOpen,
  close,
}: {
  prompt: string;
  isOpen: boolean;
  close: () => void;
}) => {
  const translate = useTranslate();
  const { mutate: addPrompt, isLoading } = useAddPrompt();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: {
      prompt,
    },
  });

  if (isLoading) {
    return <Loader isLoading />;
  }

  const handleClose = () => {
    close();
  };

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    addPrompt(
      {
        prompt: data.prompt,
        label: data.label,
        description: data.description,
      },
      {
        onSettled: (data) => {
          if (data?.value === "OK") close();
        },
      },
    );
  };

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogTitle> {translate("intellecta.add_prompt")}</DialogTitle>
        <DialogContent>
          <Stack gap={2} p={1}>
            <Controller
              name="label"
              control={control}
              rules={{
                required: "Please enter a label",
                maxLength: {
                  value: 25,
                  message: "Maximum 25 characters",
                },
              }}
              render={({ field: { onChange } }) => (
                <TextField
                  label="label"
                  size="small"
                  placeholder="Add a label"
                  type="text"
                  onChange={onChange}
                  error={!!errors.label}
                  helperText={errors.label?.message}
                />
              )}
            />
            <Controller
              name="prompt"
              control={control}
              rules={{
                required: "Please enter a prompt",
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="prompt"
                  size="small"
                  placeholder="Add a prompt"
                  type="text"
                  onChange={onChange}
                  error={!!errors.prompt}
                  helperText={errors.prompt?.message}
                  multiline
                  value={value}
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              rules={{
                required: "Please enter a description",
              }}
              render={({ field: { onChange } }) => (
                <TextField
                  label="description"
                  size="small"
                  placeholder="Add a description"
                  type="text"
                  onChange={onChange}
                  error={!!errors.description}
                  helperText={errors.description?.message}
                  multiline
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {translate("actions.cancel")}
          </Button>
          <Button variant="contained" type="submit">
            {translate("actions.confirm")}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
