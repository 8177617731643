import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { useGetMachineLocation } from "./api/useGetMachineLocation";
import { ShowMachineInfo } from "./components/ShowMachineInfo";
import { LoaderLayout } from "./components/LoaderLayout";

export const MachineInfo = () => {
  const { data } = useGetMachineLocation();

  return (
    <>
      <MachineViewNavBar />

      {!data ? <LoaderLayout /> : <ShowMachineInfo machineInfo={data} />}
    </>
  );
};
