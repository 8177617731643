import { ReactNode, useMemo } from "react";
import { ThemeProvider, createTheme } from "@mui/material";

import { defaultTheme } from "../themes";

interface ThemeProps {
  children: ReactNode;
}

export const ThemeContextProvider: React.FC<ThemeProps> = ({ children }) => {
  const theme = useMemo(() => createTheme(defaultTheme), []);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
