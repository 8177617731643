import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { intellectaClient } from "./intellectaClient";

type Payload = {
  user: string;
  prompt: {
    id: number;
    prompt: string;
    label: string;
    description: string;
  };
};

const Response = z.object({
  value: z.union([z.literal("OK"), z.literal("ERROR")]),
});
type Response = z.infer<typeof Response>;

export const useUpdatePrompt = () => {
  const queryClient = useQueryClient();
  const { user } = useFirebaseContext();
  const email = user?.email || "";

  return useMutation({
    mutationFn: async ({
      id,
      prompt,
      label,
      description,
    }: {
      id: number;
      prompt: string;
      label: string;
      description: string;
    }) => {
      return await intellectaClient<Payload, Response>({
        url: "/prompt/update",
        payload: {
          user: email,
          prompt: {
            id,
            prompt,
            label,
            description,
          },
        },
      });
    },
    onError: () => {
      toast.error("An error occurred");
    },
    onSettled: (data) => {
      if (data?.value === "OK") {
        toast.success("Prompt updated successfully");
        //* per scaricare di nuovo i prompt
        queryClient.invalidateQueries({
          queryKey: ["intellecta-config", email],
        });
      } else {
        toast.error("An error occurred");
      }
    },
  });
};
