import { Card } from "@/components/Layout/Card";
import { styled, Tab, Tabs, Skeleton } from "@mui/material";
import { useGetSegments } from "../../api/Common/useGetSegments";
import { useTranslate } from "@/i18n/config";
import {
  useAcoposContext,
  useAcoposDispatchContext,
} from "../../context/acopos-context";

const SkeletonTab = styled(
  Skeleton,
  {},
)(() => ({
  backgroundColor: "#151B2F",
  backgroundImage: "none",
  padding: ".5rem",
  boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
  display: "flex",
  flexDirection: "column",
  transform: "none",
  borderRadius: ".25rem",
  width: 320,
  alignItems: "center",
}));

const StyledTab = styled(Tab, {
  shouldForwardProp: (prop) => prop !== "active",
})<{
  active: boolean;
}>(({ active }) => ({
  border: "none",
  backgroundColor: active ? "#444959" : "transparent",
  transition: "background-color 0.2s ease-in-out",
  color: "#fff",
  fontSize: ".75rem",
  padding: ".5rem 1rem",
  margin: "0 .25rem",
  display: "flex",
  borderRadius: ".25rem",
}));

const StyledCard = styled(
  Card,
  {},
)({
  padding: ".5rem",
  // maxWidth: 320,
  maxWidth: 480,
  display: "flex",
  alignItems: "center",
});

export const SelectSegment = () => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetSegments();
  const { activeSegment } = useAcoposContext();
  const dispatch = useAcoposDispatchContext();

  if (isLoading) return <SkeletonTab />;
  if (error)
    return (
      <StyledCard>{translate("user_feedback.an_error_occurred")}</StyledCard>
    );
  if (!data)
    return <StyledCard>{translate("user_feedback.no_data")}</StyledCard>;

  const sortedSegments = data.segments.sort((a, b) => a.id - b.id);

  return (
    <StyledCard>
      <Tabs variant="scrollable" value={false}>
        {sortedSegments.map((segment) => (
          <StyledTab
            value={segment.id}
            key={segment.id}
            active={segment.id === activeSegment}
            onClick={() => {
              dispatch({ type: "SET_ACTIVE_SEGMENT", item: segment.id });
            }}
            label={segment.name}
          />
        ))}
      </Tabs>
    </StyledCard>
  );
};
