import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

const LineChartDataType = z.array(z.array(z.number()));
type LineChartDataType = z.infer<typeof LineChartDataType>;

const ConnectionStatus = z.object({
  channel: z.string(),
  connected: z.union([z.boolean(), z.unknown()]),
  device: z.string(),
  driver_type: z.string(),
  ip_address: z.string(),
  port: z.number(),
  timestamp: z.number(),
});
export type ConnectionStatus = z.infer<typeof ConnectionStatus>;

const IsWorking = z.object({
  timestamp: z.number(),
  value: z.string(),
});
export type IsWorking = z.infer<typeof IsWorking>;

const CommonProps = z.object({
  conf: z.record(z.any(), z.any()),
  cpu: LineChartDataType,
  disk: LineChartDataType,
  ram: LineChartDataType,
  isWorking: z.array(IsWorking),
  lastUpdate: z.number(), // TODO: rivedere,
  version: z.union([z.number(), z.record(z.any(), z.any())]),
});
type CommonProps = z.infer<typeof CommonProps>;

export const NetworkProps = z.object({
  networks: z.record(
    z.string(),
    z.record(
      z.string(),
      z.object({
        mac: z.string(),
        rtt: z.number(),
      }),
    ),
  ),
  timestamp: z.object({
    end_scan: z.string(),
    scan_time_seconds: z.number(),
    start_scan: z.string(),
  }),
});
export type NetworkProps = z.infer<typeof NetworkProps>;

const PcA = CommonProps.extend({
  network: z.array(NetworkProps),
});
export type PcA = z.infer<typeof PcA>;

const PcB = CommonProps.extend({
  loadAvg: z.object({
    fifteenMinutes: LineChartDataType,
    fiveMinutes: LineChartDataType,
    oneMinute: LineChartDataType,
  }),
  poweroff: z.array(
    z.object({
      timestamp: z.number(),
      value: z.boolean(),
    }),
  ),
});
export type PcB = z.infer<typeof PcB>;

export const UserLoginSchema = z.object({
  username: z.string(),
  created_at_timestamp: z.number(),
  last_login_timestamp: z.union([z.number(), z.string()]),
});
export type UserLoginSchema = z.infer<typeof UserLoginSchema>;

export const BanInfoSchema = z.record(
  z.object({
    n_attempts: z.number(),
    unban_timestamp: z.number(),
    n_ban: z.number(),
  }),
);
export type BanInfo = z.infer<typeof BanInfoSchema>;

export const BanInfoTransformed = z.object({
  ip: z.string(),
  failedAttempts: z.number(),
  unbanTimestamp: z.number(),
  bans: z.number(),
});
export type BanInfoTransformed = z.infer<typeof BanInfoTransformed>;

const DataChart = z.object({
  bidirectional: z.array(
    z.object({
      timestamp: z.number(),
      active: z.boolean(),
    }),
  ),
  connectionStatus: z.array(ConnectionStatus),
  fail2Ban: z.array(
    z.object({
      ip: z.string(),
      jail: z.string(),
      timeofban: z.string(),
    }),
  ),
  pcA: PcA,
  pcB: PcB,
  uptimeAp: z.array(
    z.object({
      pcA: z.number(),
      pcB: z.number(),
      timestamp: z.number(),
    }),
  ),
});
export type DataChart = z.infer<typeof DataChart>;

const DataLive = z.object({
  apMode: z.boolean(),
  bidirectionalActive: z.union([z.string(), z.boolean()]),
  bidirectionalRemainingTime: z.number(),
  isConnected: z.boolean(),
  lastConnection: z.number(),
  pcAUptime: z.number(),
  pcBUptime: z.number(),
  siteManagerColor: z.string(),
  siteManagerStatus: z.string(),
  vitality: z.number(),
  vitalityStatus: z.boolean(),
  opcuaServer: z.boolean(),
  fastData: z.boolean(),
  httpServer: z.boolean(),
  userLogin: z.array(UserLoginSchema),
  banInfo: BanInfoSchema.transform((v) =>
    Object.keys(v).map((key) => ({
      ip: key,
      failedAttempts: v[key].n_attempts,
      unbanTimestamp: v[key].unban_timestamp,
      bans: v[key].n_ban,
    })),
  ),
});
export type DataLive = z.infer<typeof DataLive>;

const A4GateResult = z.object({
  dataChart: DataChart,
  dataLive: DataLive,
  response: z.literal(true),
});
type A4GateResult = z.infer<typeof A4GateResult>;

type A4GateResponse = ThingworxError | A4GateResult;

export const useGetEdgePcData = ({ edgePcName }: { edgePcName: string }) => {
  const { appKey } = useFirebaseContext();
  const { dates } = useTimeSelection();
  return useQuery<A4GateResult>({
    queryKey: ["a4gate-data", edgePcName, dates.dateStart, dates.dateEnd],
    queryFn: async () => {
      const response = await FetchClient<
        { edgePcName: string; dateStart: number; dateEnd: number },
        A4GateResponse
      >({
        appKey,
        payload: {
          edgePcName,
          dateStart: dates.dateStart.toMillis(),
          dateEnd: dates.dateEnd.toMillis(),
        },
        url: "dig.c.edgePc_thing/Services/getDataNew",
      });
      if (!response.response) throw new Error(response.errorString);
      return A4GateResult.parse(response);
    },
    refetchInterval: 20 * 1000,
  });
};
