import { Switch } from "@/components/Switch";
import { alternativeViewStore } from "../Pages/LineDetail/store/alternative-view-store";

export const AlternativeViewSwitch = () => {
  const { toggle, viewFromFirstProduct } = alternativeViewStore();

  return (
    <Switch
      isChecked={viewFromFirstProduct}
      onChange={toggle}
      label="view from first product"
    />
  );
};
