import styled from "@emotion/styled";
import { Skeleton } from "@mui/material";

export const AssetSkeletonLayout = styled(Skeleton)(() => ({
  color: "#fff",
  width: "100%",
  padding: ".5rem",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  gap: "1rem",
  alignItems: "center",
  borderRadius: ".25rem",
  transition: "all .3s ease-in",
  cursor: "pointer",
}));
