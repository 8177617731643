import { GridColDef, GridRowsProp } from "@mui/x-data-grid-premium";
import { ColumnDefinition, RuleBreakageRow } from "../api/useGetRuleBreakages";

export class BreakagesParser {
  static parse({
    columnsDefinitions,
    rowValues,
    extendColumns,
  }: {
    columnsDefinitions: ColumnDefinition[];
    rowValues: RuleBreakageRow[];
    extendColumns: GridColDef[];
  }): {
    columns: GridColDef[];
    rows: GridRowsProp;
  } {
    const parsedColumns: GridColDef[] = columnsDefinitions.map((item) => ({
      field: item.idName,
      headerName: item.displayName,
      flex: 1,
    }));

    parsedColumns.push(...extendColumns);

    const parsedRows: GridRowsProp = rowValues.flatMap((parentItem) => {
      // ! TODO: dinamicizzare il nome della colonna per i valore di rule_*
      const parent = {
        path: [parentItem.name],
        id: parentItem.id,
        phaseId: parentItem.phaseId,
        phaseName: parentItem.name,
        rule_1: parentItem.values.rule_1,
        rule_2: parentItem.values.rule_2,
        rule_3: parentItem.values.rule_3,
      };

      const children =
        parentItem.innerRows?.map((childItem) => {
          return {
            path: [parentItem.name, childItem.name],
            id: childItem.id,
            rule_1: childItem.values.rule_1,
            rule_2: childItem.values.rule_2,
            rule_3: childItem.values.rule_3,
          };
        }) ?? [];

      return [parent, ...children];
    });

    return {
      columns: parsedColumns,
      rows: parsedRows,
    };
  }
}
