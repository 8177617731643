import { z } from "zod";

export const Shift = z.object({
  from: z.number(),
  to: z.number(),
  TimeLapse: z.string(),
});

export type Shift = z.infer<typeof Shift>;

