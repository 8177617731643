import { HighchartsCustomProvider } from "@/components/highcharts/highcharts";

import {
  BarSeries,
  Chart,
  HighchartsChart,
  Tooltip,
  XAxis,
  YAxis,
} from "react-jsx-highcharts";
import { renderToString } from "react-dom/server";
import { z } from "zod";
import { formatStatus } from "../../utils/formatStatus";
import { useTranslate } from "@/i18n/config";
import { Evaluation } from "../../types";

export const AnalyticBarChartProps = z.object({
  categories: z.array(z.string()).optional(),
  data: z.array(
    z.object({
      name: z.string(),
      color: z.string(),
      data: z.array(z.number()),
      frameData: z.array(z.number()),
      preFrameData: z.array(z.number()),
    }),
  ),
});

export type AnalyticBarChartProps = z.infer<typeof AnalyticBarChartProps>;

export interface AnalyticBarChartData {
  name: string;
  color: string;
  data: number[];
}

export const AnalyticBarChart = ({
  data,
  categories = ["Status"],
}: AnalyticBarChartProps) => {
  const translate = useTranslate();

  return (
    <HighchartsCustomProvider>
      <HighchartsChart
        accessibility={{ enabled: false }}
        exporting={{ enabled: false }}
      >
        <Chart backgroundColor={"transparent"} type="bar" margin={0} />
        <Tooltip
          formatter={function () {
            // const point = this.point as unknown as any;
            const point = this.point;
            const seriesName = Evaluation.parse(point.series.name);
            const series = data.find((obj) => obj.name === seriesName);

            return renderToString(
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem",
                }}
              >
                <div>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: "\u25CF ",
                    }}
                    style={{
                      color: point.color as string,
                    }}
                  />
                  {formatStatus(seriesName)}: <b>{point.y}</b>
                  <br />
                  {translate("frame")}: <b>{series?.frameData[0] || 0}</b>
                  <br />
                  {translate("pre_frame")}:{" "}
                  <b>{series?.preFrameData[0] || 0}</b>
                </div>
              </div>,
            );
          }}
          outside={true}
        />
        <XAxis categories={categories} visible={false}></XAxis>
        <YAxis visible={false}>
          {data.map((dataset) => (
            <BarSeries
              key={dataset.name}
              stacking="percent"
              name={dataset.name}
              borderColor="none"
              color={dataset.color}
              data={dataset.data}
            />
          ))}
        </YAxis>
      </HighchartsChart>
    </HighchartsCustomProvider>
  );
};
