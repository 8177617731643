import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { fileNameFactory } from "@/utils/fileNameFactory";

export function LineProductsProjection({
  products,
  products_projection,
  machine,
}: {
  products: number[][];
  products_projection: number[][];
  machine: string;
}) {
  const translate = useTranslate();

  return (
    <Card>
      <LineChart.Custom
        filename={fileNameFactory({
          name: "products_projection",
          machine: machine,
        })}
        title={translate("overall_line_efficiency.products_projection")}
        legend={{ enabled: true }}
        yAxis={[
          {
            uom: translate("machine.products"),
            series: [
              {
                name: translate("line.products"),
                type: "line",
                data: products,
                color: "green",
              },
              {
                name: translate("line.projection"),
                type: "line",
                data: products_projection,
                color: "yellow",
              },
            ],
          },
        ]}
      />
    </Card>
  );
}
