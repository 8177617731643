import { Dispatch, useReducer, createContext, useContext } from "react";
import { Condition } from "../types";

export type RuleContextType = {
  conditions: Condition[];
};

type Action =
  | {
      type: "CREATE_UPDATE_CONDITION";
      condition: Condition;
    }
  | {
      type: "REMOVE_CONDITION";
      variableName: string;
    }
  | {
      type: "RESET_CONDITIONS";
    };

export const RuleContext = createContext<RuleContextType | null>(null);

export const RuleDispatchContext = createContext<Dispatch<Action> | null>(null);

export const useRuleContext = () => {
  const context = useContext(RuleContext);
  if (!context) {
    throw new Error("useRuleContext must be used within RuleContextProvider");
  }
  return context;
};

export const useRuleDispatchContext = () => {
  const context = useContext(RuleDispatchContext);
  if (!context) {
    throw new Error(
      "useRuleDispatchContext must be used within RuleContextProvider",
    );
  }
  return context;
};

const reducer: React.Reducer<RuleContextType, Action> = (
  state,
  action,
): RuleContextType => {
  switch (action.type) {
    case "CREATE_UPDATE_CONDITION": {
      const alreadyExistingCondition = state.conditions.find(
        (condition) => condition.property === action.condition.property,
      );
      if (alreadyExistingCondition) {
        return {
          ...state,
          conditions: state.conditions.map((condition) =>
            condition.property === action.condition.property
              ? action.condition
              : condition,
          ),
        };
      } else {
        return {
          ...state,
          conditions: [...state.conditions, action.condition],
        };
      }
    }

    case "REMOVE_CONDITION": {
      return {
        ...state,
        conditions: state.conditions.filter(
          (condition) => condition.property !== action.variableName,
        ),
      };
    }

    case "RESET_CONDITIONS": {
      return {
        ...state,
        conditions: [],
      };
    }

    default:
      return state;
  }
};

export const RuleContextProvider = ({ children }: React.PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, {
    conditions: [],
  } satisfies RuleContextType);

  const ruleContext: RuleContextType = {
    conditions: state.conditions,
  };

  return (
    <RuleContext.Provider value={ruleContext}>
      <RuleDispatchContext.Provider value={dispatch}>
        {children}
      </RuleDispatchContext.Provider>
    </RuleContext.Provider>
  );
};
