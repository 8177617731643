import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { MachineSettingsView } from "./page/MachineSettingsView";
import { useTurnConfiguration } from "./api/useTurnParametric";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { Stack } from "@mui/material";
import { useTranslate } from "@/i18n/config";

export const MachineSettings = () => {
  const { data, isLoading, error } = useTurnConfiguration();
  const translate = useTranslate();

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  return (
    <>
      <MachineViewNavBar />
      {isLoading ? (
        <Stack gap={2}>
          <Stack
            gap={2}
            direction="row"
            justifyContent={{ xs: "center", md: "start" }}
          >
            <SkeletonCard width={130} />
            <SkeletonCard width={130} />
          </Stack>
          <SkeletonCard height={300} />
        </Stack>
      ) : null}
      {data ? <MachineSettingsView ShiftConfiguration={data} /> : null}
    </>
  );
};
