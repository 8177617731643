import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useCustomTimeRange } from "../store/useCustomTimeRange";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type DoserDeviationResponse = ThingworxError | DoserDeviationResult;

const DoserDeviationData = z.object({
  name: z.string(),
  netData: z.object({
    meanData: z.array(z.array(z.number())),
    sigma1Data: z.array(z.array(z.number())),
    sigma2Data: z.array(z.array(z.number())),
    sigma3Data: z.array(z.array(z.number())),
    scatterData: z
      .array(
        z.object({
          x: z.number(),
          y: z.number(),
          modality: z.string(),
        }),
      )
      .optional(),
    benchmark: z
      .object({
        setpoint: z.number().optional(),
        minWarn: z.number().optional(),
        minErr: z.number().optional(),
        maxWarn: z.number().optional(),
        maxErr: z.number().optional(),
      })
      .optional(),
  }),
});

export type DoserDeviationData = z.infer<typeof DoserDeviationData>;

const DoserDeviationResult = z.object({
  response: z.literal(true),
  data: z.array(DoserDeviationData),
});

export type DoserDeviationResult = z.infer<typeof DoserDeviationResult>;

interface Payload {
  machineName: string;
  dateStart: DateTime;
  dateEnd: DateTime;
}

export const useGetDoserDeviationData = () => {
  const { machine } = useMachineContext();

  const { dateStart, dateEnd } = useCustomTimeRange();

  const { appKey } = useFirebaseContext();

  return useQuery<DoserDeviationData[]>({
    queryKey: ["doser-deviation", machine?.machine, dateStart, dateEnd],
    queryFn: async () => {
      const response = await FetchClient<Payload, DoserDeviationResponse>({
        appKey,
        payload: {
          dateStart,
          dateEnd,
          machineName: machine?.machine || "",
        },
        url: "dig.c.fillerAdvancedAnalytics_thing/Services/getDosersAnalysisData",
      });

      if (!response.response) throw new Error(response.errorString);

      const validatedResponse = DoserDeviationResult.parse(response);
      return validatedResponse.data;
    },
  });
};
