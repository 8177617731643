import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { evaluation } from "../../types";

export type GetSystemHealthResponse = ThingworxError | GetSystemHealthResult;

type Payload = {
  machineName: string;
};

const getSystemHealthResult = z.object({
  response: z.literal(true),
  healthStatus: evaluation,
});

export type GetSystemHealthResult = z.infer<typeof getSystemHealthResult>;

export const useGetSystemHealth = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();

  return useQuery<GetSystemHealthResult>({
    queryKey: ["system-health-status", machine!.machine],
    queryFn: async () => {
      const response = await FetchClient<Payload, GetSystemHealthResponse>({
        appKey,
        payload: {
          machineName: machine!.machine,
        },
        // url: "dig.c.acopostrak_thing/Services/getSystemHealth",
        url: "dig.c.acoposTrack_thing/Services/getSystemHealth",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = getSystemHealthResult.parse(response);

      return validatedResponse;
    },
  });
};
