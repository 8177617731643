import { getDownloadURL, ref } from "firebase/storage";
import {
  Navigation,
  NavigationLink,
} from "../../../../constants/sentinelNavigations";
import { storage } from "@/lib/sentinel-app";

/**
 *
 * @param allNavigations : list of all possible navigations
 * @param unavailablePages : pages not available for specific machine
 * @returns Navigation[] filtered from allNavigations
 */
export const getAvailableNavigations = ({
  allNavigations,
  unavailablePages,
}: {
  allNavigations: Navigation[];
  unavailablePages: string[];
}): Navigation[] => {
  if (unavailablePages.length === 0) {
    return allNavigations;
  }

  const availableNavigations: Navigation[] = [];
  allNavigations.forEach((navigation) => {
    if (navigation.nav.length === 1) {
      const nameReformat = navigation.nav[0].to.replace("/", "");
      if (!unavailablePages.includes(nameReformat)) {
        availableNavigations.push(navigation);
      }
    } else {
      const groupName = navigation.groupName;
      const tmpNav: NavigationLink[] = [];
      navigation.nav.forEach((nav) => {
        const nameReformat = nav.to.replace("/", "");
        if (!unavailablePages.includes(nameReformat)) {
          tmpNav.push(nav);
        }
      });

      availableNavigations.push({
        isGroupNavigation: true,
        nav: tmpNav,
        groupName,
      });
    }
  });

  return availableNavigations;
};

/**
 *
 * @param platformLevel
 * 0 - all platforms
 * 1 - control room
 * 2 - a4Sentinel
 * 3 - ima sentinel
 * @returns {string}[]
 * [0] - platform name
 * [1] - logo path
 */
export const getDefaultPlatformNameAndLogo = (platformLevel: number) => {
  switch (platformLevel) {
    case 0:
      return {
        platformName: "IMA Sentinel",
        logoUrl: "/assets/img/sentinel.png",
      };
    case 1:
      return {
        platformName: "Control Room",
        logoUrl: "/assets/img/sentinel.png",
      };
    case 2:
      return {
        platformName: "a4Sentinel",
        logoUrl: "/assets/img/a4sentinel.png",
      };
    case 3:
      return {
        platformName: "IMA Sentinel",
        logoUrl: "/assets/img/sentinel.png",
      };
    default:
      return {
        platformName: "data room",
        logoUrl: "assets/img/sentinel.png",
      };
  }
};

/**
 *
 * @param company name of company with custom logo
 * @sets logoIngo in sessionStorage
 * @returns string - logo url from firebase storage bucket
 */
export const getCustomLogo = async (company: string): Promise<string> => {
  const url = await getDownloadURL(ref(storage, `${company}/logo.svg`));

  const logoInfo = {
    logo: url,
    name: company,
  };

  sessionStorage.setItem("custom", JSON.stringify(logoInfo));
  return url;
};
