import { Skeleton, styled } from "@mui/material";

export const SkeletonCard = styled(
  Skeleton,
  {},
)(() => ({
  backgroundColor: "#151B2F",
  width: "100%",
  backgroundImage: "none",
  padding: "1rem",
  boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  transform: "none",
  maxWidth: "100%",
}));
