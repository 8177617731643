import { Modal } from "@/components/Modal";
import { useDisclosure } from "@/hooks/useDisclosure";
import { PlayCircleOutline } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useSetRunningTrue } from "../api/useSetRunningTrue";
import { useTranslate } from "@/i18n/config";

export const StartConfiguration = ({ config_id }: { config_id: number }) => {
  const { open, close, isOpen } = useDisclosure();
  const { mutate: startConfig } = useSetRunningTrue();
  const translate = useTranslate();
  return (
    <>
      <Button
        variant="outlined"
        sx={{
          width: "fit-content",
        }}
        color="success"
        endIcon={<PlayCircleOutline />}
        onClick={open}
      >
        {translate("start")}
      </Button>
      <Modal
        open={isOpen}
        onClose={close}
        bodyContent={<></>}
        titleContent="Are you sure you want to start this configuration?"
        actions={
          <>
            <Button variant="outlined" onClick={close}>
              {translate("actions.cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                startConfig({ config_id });
              }}
            >
              {translate("actions.confirm")}
            </Button>
          </>
        }
      />
    </>
  );
};
