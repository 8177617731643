import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { Run, contextInfo, thresholds } from "../../types";
import { useAcoposContext } from "../../context/acopos-context";

export type GetConditionDataResponse = ThingworxError | GetConditionDataResult;

const boxPlotDataSchema = z.object({
  boxPlotData: z.array(z.array(z.number())),
  categories: z.array(z.string()),
  scatterData: z.array(z.array(z.number())),
  trendData: z.array(z.array(z.number())),
});

export type BoxPlotDataSchema = z.infer<typeof boxPlotDataSchema>;

const resSchema = z.union([
  z.object({
    analyticName: z.literal("RMS Current by Lap"),
    chartType: z.literal("lines"),
    metricType: z.literal("timestamp"),
    data: z.array(z.array(z.number())),
    thresholds,
    contextInfo: contextInfo,
  }),
  z.object({
    analyticName: z.literal("RMS Current Segment Position by Lap"),
    chartType: z.literal("scatter"),
    metricType: z.literal("meters"),
    data: z.array(z.array(z.number())),
    thresholds,
    contextInfo: contextInfo,
  }),
  z.object({
    analyticName: z.literal("Mean Current by Lap"),
    chartType: z.literal("lines"),
    metricType: z.literal("timestamp"),
    data: z.array(z.array(z.number())),
    thresholds,
    contextInfo: contextInfo,
  }),
  z.object({
    analyticName: z.literal("Box Plot by Lap"),
    chartType: z.literal("box"),
    metricType: z.literal("timestamp"),
    data: boxPlotDataSchema,
    thresholds,
    contextInfo: contextInfo,
  }),
]);

export type ResSchema = z.infer<typeof resSchema>;

const conditionData = z.object({
  analyticName: z.string(),
  data: z.array(z.array(z.number())),
  contextInfo: contextInfo,
});

export type ConditionData = z.infer<typeof conditionData>;

type Payload = {
  machineName: string;
  runs: Run[];
  segment: number;
  shuttles: number[];
  analytics: number[];
};

const getConditionDataResult = z.object({
  response: z.literal(true),
  data: z.array(resSchema),
});

export type GetConditionDataResult = z.infer<typeof getConditionDataResult>;

export const useGetConditionData = ({ analytics }: { analytics: number[] }) => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const { runs, activeSegment, shuttles } = useAcoposContext();

  return useQuery<GetConditionDataResult>({
    queryKey: [
      "condition-analytics-data",
      machine!.machine,
      JSON.stringify(runs),
      activeSegment,
      shuttles,
      JSON.stringify(analytics),
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, GetConditionDataResponse>({
        appKey,
        payload: {
          machineName: machine!.machine,
          runs,
          segment: activeSegment!,
          shuttles,
          analytics,
        },
        // url: "dig.c.acopostrak_thing/Services/getConditionData",
        url: "dig.c.acoposTrack_thing/Services/getConditionData",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = getConditionDataResult.parse(response);

      return validatedResponse;
    },
  });
};
