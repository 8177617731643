/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { HighchartsCustomProvider } from "../highcharts";

import {
  HighchartsChart,
  Chart,
  XAxis,
  YAxis,
  Tooltip,
  SunburstSeries,
} from "react-jsx-highcharts";
import { useState } from "react";

interface SunburstProps {
  title: string;
  data: {
    id: string;
    parent: string;
    name: string;
    value?: number;
  }[];
}

const COLORS = [
  "#F7B500",
  "#14a5e3",
  "#C51CBA",
  "#00f7d6",
  "#4ef700",
  "#f70000",
  // "#F50AC6",
  // "#ff950a",
  // "#21d1ae",
];

export const Sunburst = ({ title, data }: SunburstProps) => {
  const [rootId, setRootId] = useState("0.0");

  const handleClick = (_: unknown, selectedZoneId: string) => {
    if (selectedZoneId === rootId) {
      setRootId("0.0");
    } else {
      setRootId(selectedZoneId);
    }
  };

  return (
    <Box
      sx={{ display: "flex", height: "70vh", justifyContent: "space-between" }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h6">{title}</Typography>
        <List
          sx={{
            overflowY: "auto",
          }}
          subheader={<ListSubheader>Machine zones:</ListSubheader>}
        >
          {data.map((entry, i) => {
            if (entry.parent === "0.0") {
              const totalSum = data
                .filter((el) => el.parent === entry.id)
                .reduce((acc, val) => {
                  return acc + (val.value || 0);
                }, 0);
              return (
                <ListItemButton
                  key={entry.name}
                  onClick={() => handleClick(null, entry.id)}
                >
                  <ListItemIcon>
                    <CircleIcon sx={{ color: COLORS[i] }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${entry.name} (${totalSum.toFixed(2)}%)`}
                  />
                </ListItemButton>
              );
            }
          })}
        </List>
      </Box>
      <Box>
        <HighchartsCustomProvider>
          <HighchartsChart accessibility={{ enabled: false }} plotOptions={{}}>
            <Chart backgroundColor={"transparent"} />
            {data.length > 0 && (
              <Tooltip
                formatter={function () {
                  // @ts-ignore
                  const tooltipContextObject: Highcharts.TooltipFormatterContextObject =
                    this;
                  const point: Highcharts.Point = tooltipContextObject.point;
                  const name = point.name || "";
                  const value = point.value || 0;
                  return `
                  ${name}<br>
                  <b>${value.toFixed(2)} %</b>
                `;
                }}
              />
            )}
            <XAxis></XAxis>
            <YAxis>
              <SunburstSeries
                rootId={rootId}
                color={"transparent"}
                colors={COLORS}
                name="Root"
                cursor="pointer"
                borderColor={"transparent"}
                breadcrumbs={{
                  style: { display: "none" },
                  separator: { style: { display: "none" } },
                }}
                data={data}
                allowTraversingTree
                dataLabels={{
                  enabled: true,
                  color: "white",
                  format: "{point.name}",
                  filter: {
                    property: "innerArcLength",
                    operator: ">",
                    value: 16,
                  },
                  rotationMode: "parallel",
                }}
                levels={[
                  {
                    level: 1,
                    dataLabels: {
                      filter: {
                        property: "outerArcLength",
                        operator: ">",
                        value: 64,
                      },
                    },
                  },
                  { level: 2, colorByPoint: true },
                  { level: 3, colorVariation: { key: "brightness", to: -0.5 } },
                  { level: 4, colorVariation: { key: "brightness", to: 0.5 } },
                ]}
              />
            </YAxis>
          </HighchartsChart>
        </HighchartsCustomProvider>
      </Box>
    </Box>
  );
};
