import { useTranslate } from "@/i18n/config";
import { ExpandMore } from "@mui/icons-material";
import {
  Container,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";

export const InfoHelpPage = () => {
  const translate = useTranslate();

  return (
    <Container sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="h5">{translate("acopos.run")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* (da tradurre)  */}
          <Typography variant="body2">
            Con "run" si intende l'intervallo di tempo che trascorre da quando
            la macchina entra in uno stato automatico a quando passa ad uno
            stato manuale. Ad ogni selezione da calendario vengono selezionate
            di default le prime 5 run.
          </Typography>
          <Typography variant="body2">
            NOTA: numero massimo di run selezionabili: 2 (fatta eccezione per la
            sezione "Summary" che non presenta limiti).
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography variant="h5">{translate("acopos.lap")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* (da tradurre)  */}
          <Typography variant="body2">
            Con "lap" si intende un giro intero del circuito sul quale si muove
            lo shuttle. Ha solitamente una durata media di circa 20 secondi.
          </Typography>
          <Typography variant="body2">
            NOTA: numero massimo di lap selezionabili: 3 (fatta eccezione per la
            modalità "aggregated" che non presenta limiti).
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Typography variant="h5">{translate("health_status")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* (da tradurre)  */}
          <Typography variant="body2">
            Con "health status" si intende un giudizio complessivo sullo stato
            di salute del sistema.
          </Typography>
          <Typography variant="body2">
            Lo stato di salute del sistema è basato sul giorno precedente, i
            calcoli vengono eseguiti alla mezzanotte.
          </Typography>
          <Typography variant="body2">
            Lo stato di salute dei segmenti e degli shuttle è basato
            sull'intervallo di tempo che intercorre tra la mezzanotte e adesso.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};
