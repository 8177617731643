import { useContext } from "react";
import { OeeLineDispatchContext } from "./oee-line-context";

export const useOeeLineContextDispatch = () => {
  const context = useContext(OeeLineDispatchContext);
  if (!context) {
    throw new Error(
      "useOeeLineContextDispatch must be used within a OeeLineContextProvider",
    );
  }
  return context;
};
