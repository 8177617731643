import { useTheme } from "@mui/material/styles";
import { Grid, Stack, useMediaQuery } from "@mui/material";
import { Shift } from "./Shift";
import { TWeek } from "../../api/useTurnParametric";
import { TranslationKey, useTranslate } from "@/i18n/config";

const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

function getDayTranslationKey(day: string): TranslationKey {
  return `days.${day.toLowerCase()}` as TranslationKey;
}

const orderWeekByIndex = (
  inputIndex: number,
  week: { [key: string]: TWeek[] },
): { name: string; shifts: TWeek[] }[] => {
  const orderedWeek: { name: string; shifts: TWeek[] }[] = [];

  for (let i = inputIndex; i <= 6; i++) {
    if (Object.prototype.hasOwnProperty.call(week, i.toString())) {
      orderedWeek.push({ name: daysOfWeek[i], shifts: week[i.toString()] });
    }
  }

  for (let i = 0; i < inputIndex; i++) {
    if (Object.prototype.hasOwnProperty.call(week, i.toString())) {
      orderedWeek.push({ name: daysOfWeek[i], shifts: week[i.toString()] });
    }
  }

  return orderedWeek;
};

export const ShiftPanel = ({
  week,
  startWeek,
}: {
  week: Record<string, TWeek[]>;
  startWeek: number;
}) => {
  const shiftsConfigurations: Record<string, TWeek[]> = week;
  const orderedWeek = orderWeekByIndex(startWeek, shiftsConfigurations);

  // const sortedWeek = moveDayToFirst(startWeek);

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("md"));
  const DesktopView = useMediaQuery(theme.breakpoints.up("md"));
  const translate = useTranslate();

  return (
    <Stack
      marginTop="1%"
      // padding="1%"
      marginRight={"auto"}
      direction={"column"}
      // spacing={2}
      justifyContent={"space-between"}
    >
      {mobileView && (
        <>
          {orderedWeek.map((day) => {
            const { name, shifts } = day;
            const dayIndex = daysOfWeek.indexOf(name);

            return (
              <Stack
                style={{
                  backgroundColor: "#254173",
                  padding: "10px",
                  border: "solid #254173",
                  borderRadius: "4px",
                  width: "fit-content",
                }}
                direction="row"
                spacing={3}
                key={dayIndex}
                alignItems="center"
              >
                <Grid container spacing={3} direction={"row"} style={{ width: "fit-content" }}>
                  <Grid item xs={6} fontSize="large">
                    <div
                      key={dayIndex}
                      style={{
                        width: "120px",
                      }}
                    >
                      <label>
                        <b>{translate(getDayTranslationKey(name))}</b>
                      </label>
                    </div>
                  </Grid>
                  <Grid item container xs={12} rowSpacing={2}>
                    {shifts.map((shift, shiftIndex) => {
                      return (
                        <Grid
                          key={shiftIndex}
                          item
                          xs={6}
                          style={{
                            display: "flex",
                            alignContent: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Shift shiftProp={shift} ShiftIndex={shiftIndex} />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Stack>
            );
          })}
          {/* {Object.entries(shiftsConfigurations).map(
            ([day, shifts], dayIndex) => {
              const dayName: string = sortedWeek[dayIndex];
              return (
                <Stack
                  style={{
                    backgroundColor: "#254173",
                    padding: "10px",
                    border: "solid #254173",
                    borderRadius: "4px",
                    width: "fit-content",
                  }}
                  direction="row"
                  spacing={3}
                  key={dayIndex}
                  alignItems="center"
                >
                  <Grid
                    container
                    spacing={3}
                    direction={"row"}
                    style={{ width: "fit-content" }}
                  >
                    <Grid item xs={6} fontSize="large">
                      <div
                        key={dayIndex}
                        style={{
                          width: "120px",
                        }}
                      >
                        <label>
                          <b>{dayName}</b>
                        </label>
                      </div>
                    </Grid>
                    <Grid item container xs={12} rowSpacing={2}>
                      {shifts.map((shift, shiftIndex) => {
                        return (
                          <Grid
                            key={shiftIndex}
                            item
                            xs={6}
                            style={{
                              display: "flex",
                              alignContent: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Shift shiftProp={shift} ShiftIndex={shiftIndex} />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Stack>
              );
            }
          )} */}
        </>
      )}
      {DesktopView && (
        <Stack direction="row" spacing={2}>
          {orderedWeek.map((day) => {
            const { name, shifts } = day;
            const dayIndex = daysOfWeek.indexOf(name);

            return (
              <Stack
                style={{
                  padding: ".5rem",
                  borderRadius: "4px",
                }}
                spacing={2}
                key={dayIndex}
                alignItems="center"
              >
                <div
                  key={dayIndex}
                  style={{
                    minWidth: "100px",
                    textAlign: "center",
                  }}
                >
                  <label>{translate(getDayTranslationKey(name))}</label>
                </div>
                {shifts.map((shift, shiftIndex) => {
                  return (
                    <Stack key={shiftIndex}>
                      <Shift ShiftIndex={shiftIndex} shiftProp={shift} />
                    </Stack>
                  );
                })}
              </Stack>
            );
          })}
          {/* {Object.entries(shiftsConfigurations).map(
            ([day, shifts], dayIndex) => {
              const dayName: string = sortedWeek[dayIndex];
              return (
                <Stack
                  style={{
                    // backgroundColor: "#254173",
                    padding: ".5rem",
                    // border: "solid 2px #254173",
                    borderRadius: "4px",
                  }}
                  // direction="row"
                  spacing={2}
                  key={dayIndex}
                  alignItems="center"
                >
                  <div
                    key={dayIndex}
                    style={{
                      minWidth: "100px",
                      textAlign: "center",
                      // justifyContent: "center",
                    }}
                  >
                    <label>{dayName}</label>
                  </div>
                  {shifts.map((shift, shiftIndex) => {
                    return (
                      <Stack key={shiftIndex}>
                        <Shift ShiftIndex={shiftIndex} shiftProp={shift} />
                      </Stack>
                    );
                  })}
                </Stack>
              );
            }
          )} */}
        </Stack>
      )}
    </Stack>
  );
};
