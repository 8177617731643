import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { contextInfo, uom } from "../../types";

export type GetConditionMonitoringDataResponse =
  | ThingworxError
  | GetConditionMonitoringDataResult;

type Payload = {
  machineName: string;
  subphasesAssetsAnalysisId: number;
};

const getConditionMonitoringDataResult = z.object({
  response: z.literal(true),
  rawData: z.array(z.array(z.number())),
  goldenData: z.array(z.array(z.number())),
  uom: uom,
  contextInfo: contextInfo,
});

export type GetConditionMonitoringDataResult = z.infer<
  typeof getConditionMonitoringDataResult
>;

export const useGetConditionMonitoringData = ({
  subphasesAssetsAnalysisId,
}: {
  subphasesAssetsAnalysisId: number;
}) => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();

  return useQuery<GetConditionMonitoringDataResult>({
    queryKey: [
      "condition-monitoring-data",
      machine!.machine,
      subphasesAssetsAnalysisId,
    ],
    queryFn: async () => {
      const response = await FetchClient<
        Payload,
        GetConditionMonitoringDataResponse
      >({
        appKey,
        payload: {
          machineName: machine!.machine,
          subphasesAssetsAnalysisId,
        },
        url: "dig.c.isolator_thing/Services/getConditionMonitoringData",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse =
        getConditionMonitoringDataResult.parse(response);

      return validatedResponse;
    },
  });
};
