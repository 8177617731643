import { useContext } from "react";
import { LineEditorDispatch } from "./LineEditorContextProvider";

export const useLineEditorDispatch = () => {
  const context = useContext(LineEditorDispatch);
  if (context === null) {
    throw new Error(
      "useLineEditorDispatch must be used within a LineEditorContextProvider",
    );
  }
  return context;
};
