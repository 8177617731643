import { Languages, useLanguage } from "@/i18n/config";
import { dateTimeFormatStore, TimeFormat } from "@/store/useDateTimeFormat";
import { timezoneStore } from "@/store/useTimezoneStore";
import { DateTime, Duration } from "luxon";
import { useCallback } from "react";

export const useFormatTimestamp = () => {
  const { timezone } = timezoneStore();
  const { dateFormat, timeFormat } = dateTimeFormatStore();
  const language = useLanguage();

  const formatTimestamp = useCallback(
    ({
      timestamp,
      fmt,
      skipTimezoneTransform,
      skipLocale,
    }: {
      timestamp: number;
      fmt?: string;
      skipTimezoneTransform?: boolean;
      skipLocale?: boolean;
    }) => {
      return TimeHelpers.parseTimestampToString({
        timestamp,
        fmt,
        timezone,
        dateFormat,
        timeFormat,
        skipTimezoneTransform,
        language,
        skipLocale,
      });
    },
    [timezone, dateFormat, timeFormat, language],
  );

  return { formatTimestamp };
};

export const useFormatMultiMachineTimestamp = () => {
  const { timezone, hasMachineTimezone } = timezoneStore();
  const { dateFormat, timeFormat } = dateTimeFormatStore();
  const language = useLanguage();
  const formatMultiMachineTimestamp = useCallback(
    ({
      timestamp,
      machineTimezone,
      fmt,
      skipTimezoneTransform,
      skipLocale,
    }: {
      timestamp: number;
      machineTimezone?: string;
      fmt?: string;
      skipTimezoneTransform?: boolean;
      skipLocale?: boolean;
    }) => {
      return TimeHelpers.parseTimestampToString({
        timestamp,
        fmt,
        timezone: hasMachineTimezone ? machineTimezone : timezone,
        dateFormat,
        timeFormat,
        skipTimezoneTransform,
        language,
        skipLocale,
      });
    },
    [hasMachineTimezone, timezone, dateFormat, timeFormat, language],
  );

  return { formatMultiMachineTimestamp };
};

export class TimeHelpers {
  /**
   * Converts 24h format to 12h format
   * @param fmt 24h format string
   * @param timeFormat desired time format
   * @returns converted format string
   */
  private static convertTimeFormat(fmt: string, timeFormat: TimeFormat): string {
    if (fmt === "DDD") {
      return fmt;
    }
    if (timeFormat === "12h") {
      const convertedFmt = fmt.replace(/HH/g, "hh");
      return convertedFmt.includes("a") ? convertedFmt : `${convertedFmt} a`;
    }
    return fmt.replace(/hh/g, "HH").replace(/\s*a/g, ""); // Remove AM/PM marker and any surrounding spaces
  }

  /**
   * Converts date format between British (dd/MM/yyyy) and American (MM/dd/yyyy)
   * @param fmt original format string
   * @param dateFormat target date format
   * @returns converted format string
   */
  private static convertDateFormat(fmt: string, dateFormat: string): string {
    if (fmt === "DD HH:mm:ss" && dateFormat === "en-US") {
      return "MMM d, HH:mm:ss";
    }
    if (fmt.includes("dd/MM/yyyy") && dateFormat === "en-US") {
      return fmt.replace(/dd\/MM\/yyyy/g, "MM/dd/yyyy");
    }
    return fmt;
  }

  static parseTimestampToString({
    timestamp,
    fmt = "DD HH:mm:ss",
    timezone,
    dateFormat = "en-GB",
    timeFormat,
    skipTimezoneTransform,
    language,
    skipLocale,
  }: {
    timestamp: number;
    fmt?: string;
    timezone?: string;
    dateFormat?: string;
    timeFormat?: TimeFormat;
    skipTimezoneTransform?: boolean;
    language: Languages;
    skipLocale?: boolean;
  }) {
    const convertedDateFormat = this.convertDateFormat(fmt, dateFormat);

    const convertedFormat = timeFormat
      ? this.convertTimeFormat(convertedDateFormat, timeFormat)
      : convertedDateFormat;

    //? necessario fare questo passaggio per vedere la stringa in formato Americano/Europeo
    const localeLanguage = dateFormat === "en-US" ? `${language}-US` : `${language}-GB`;

    if (skipLocale) {
      return DateTime.fromMillis(timestamp, {
        zone: skipTimezoneTransform ? undefined : timezone,
      })
        .setLocale(dateFormat)
        .toFormat(convertedFormat);
    }

    return DateTime.fromMillis(timestamp, {
      zone: skipTimezoneTransform ? undefined : timezone,
    })
      .setLocale(localeLanguage)
      .toFormat(convertedFormat);
  }

  static parseDurationToString({ duration, fmt = "hh:mm:ss" }: { duration: number; fmt?: string }) {
    return Duration.fromMillis(duration).toFormat(fmt);
  }
}
