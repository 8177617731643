import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Grid, IconButton, Stack } from "@mui/material";
import { ShiftEdit } from "./ShiftEdit";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import {
  useMachineContextDispatch,
  useMachineContextState,
} from "../../context/MachineSettingsContext";
import { TWeek } from "../../api/useSetTurnParametric";
import { Dispatch, SetStateAction } from "react";

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const orderWeekByIndex = (
  inputIndex: number,
  week: { [key: string]: TWeek[] },
): { name: string; shifts: TWeek[] }[] => {
  const orderedWeek: { name: string; shifts: TWeek[] }[] = [];

  for (let i = inputIndex; i <= 6; i++) {
    if (Object.prototype.hasOwnProperty.call(week, i.toString())) {
      orderedWeek.push({ name: daysOfWeek[i], shifts: week[i.toString()] });
    }
  }

  for (let i = 0; i < inputIndex; i++) {
    if (Object.prototype.hasOwnProperty.call(week, i.toString())) {
      orderedWeek.push({ name: daysOfWeek[i], shifts: week[i.toString()] });
    }
  }

  return orderedWeek;
};

export const ShiftEditPanel = ({
  startWeek,
  weekIndex,
  setIsError,
}: {
  startWeek: number;
  weekIndex: number;
  setIsError: Dispatch<SetStateAction<boolean>>;
}) => {
  const dispatch = useMachineContextDispatch();
  const { currentWeek, nextWeek } = useMachineContextState().editableData;

  const week = weekIndex === 0 ? currentWeek : nextWeek;
  const orderedWeek = orderWeekByIndex(startWeek, week);

  const addShift = (dayIndex: number, shiftLenght: number) => {
    if (shiftLenght <= 3) {
      dispatch({ type: "add shift", DayIndex: dayIndex, WeekIndex: weekIndex });
    }
  };

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("md"));
  const desktopView = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Stack
      marginTop="1%"
      direction="column"
      spacing={2}
      justifyContent={"space-between"}
      width={"inherit"}
      marginRight={"auto"}
    >
      {mobileView && (
        <>
          {orderedWeek.map((day) => {
            const { name, shifts } = day;
            const dayIndex = daysOfWeek.indexOf(name);
            const previousDayShifts =
              dayIndex === 0
                ? orderedWeek[6].shifts
                : orderedWeek[dayIndex - 1].shifts;

            const nextDayShifts =
              dayIndex === 6
                ? orderedWeek[0].shifts
                : orderedWeek[dayIndex + 1].shifts;

            return (
              <Stack
                style={{
                  backgroundColor: "#254173",
                  border: "solid #254173",
                  borderRadius: "4px",
                  padding: "10px",
                  marginRight: "auto",
                  width: "inherit",
                }}
                spacing={2}
                key={dayIndex}
                direction="row"
              >
                <Grid
                  container
                  spacing={3}
                  direction={"row"}
                  style={{ width: "fit-content" }}
                >
                  <Grid item xs={6} fontSize="large">
                    <div
                      key={dayIndex}
                      style={{
                        width: "120px",
                      }}
                    >
                      <label>
                        <b>{name}</b>
                      </label>
                    </div>
                  </Grid>
                  <Grid item container xs={12} rowSpacing={2}>
                    {shifts.map((shift, shiftIndex) => {
                      const isLastShift = shiftIndex === shifts.length - 1;
                      return (
                        <Grid
                          item
                          xs={6}
                          style={{
                            display: "flex",
                            alignContent: "center",
                            justifyContent: "center",
                          }}
                          key={
                            Object.values(shift).reduce(
                              (acc, val) => (acc += val),
                              0,
                            ) + dayIndex
                          }
                        >
                          <ShiftEdit
                            shift={shift}
                            dayIndex={dayIndex}
                            shiftIndex={shiftIndex}
                            weekIndex={weekIndex}
                            prevShift={
                              shiftIndex === 0 && previousDayShifts.length > 0
                                ? previousDayShifts[
                                    previousDayShifts.length - 1
                                  ]
                                : shiftIndex > 0
                                ? shifts[shiftIndex - 1]
                                : null
                            }
                            nextShift={
                              isLastShift
                                ? nextDayShifts[0] ?? null
                                : shifts[shiftIndex + 1]
                            }
                            last={isLastShift}
                            setIsError={setIsError}
                          />
                        </Grid>
                      );
                    })}
                    {shifts.length <= 3 && (
                      <Grid
                        item
                        xs={6}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <IconButton
                            size="large"
                            onClick={() => addShift(dayIndex, shifts.length)}
                          >
                            <AddBoxRoundedIcon color="success" />
                          </IconButton>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Stack>
            );
          })}
        </>
      )}

      {desktopView && (
        <Stack direction="row" spacing={2}>
          {orderedWeek.map((day) => {
            const { name, shifts } = day;
            const dayIndex = daysOfWeek.indexOf(name);
            const previousDayShifts =
              dayIndex === 0
                ? orderedWeek[6].shifts
                : orderedWeek[dayIndex - 1].shifts;

            const nextDayShifts =
              dayIndex === 6
                ? orderedWeek[0].shifts
                : orderedWeek[dayIndex + 1].shifts;

            return (
              <Stack
                style={{
                  borderRadius: "4px",
                  padding: ".5rem",
                }}
                direction="column"
                spacing={2}
                key={dayIndex}
                alignItems="center"
              >
                <div
                  key={dayIndex}
                  style={{
                    minWidth: "110px",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <label>{name}</label>
                </div>

                {shifts.map((shift, shiftIndex) => {
                  const isLastShift = shiftIndex === shifts.length - 1;
                  return (
                    <Stack
                      direction={"row"}
                      spacing={2}
                      key={JSON.stringify(shift) + dayIndex}
                    >
                      <ShiftEdit
                        shift={shift}
                        dayIndex={dayIndex}
                        shiftIndex={shiftIndex}
                        weekIndex={weekIndex}
                        prevShift={
                          shiftIndex === 0 && previousDayShifts.length > 0
                            ? previousDayShifts[previousDayShifts.length - 1]
                            : shiftIndex > 0
                            ? shifts[shiftIndex - 1]
                            : null
                        }
                        nextShift={
                          isLastShift
                            ? nextDayShifts[0] ?? null
                            : shifts[shiftIndex + 1]
                        }
                        last={isLastShift}
                        setIsError={setIsError}
                      />
                    </Stack>
                  );
                })}
                {shifts.length <= 3 && (
                  <Grid
                    item
                    xs={4}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box>
                      <IconButton
                        size="large"
                        onClick={() => addShift(dayIndex, shifts.length)}
                      >
                        <AddBoxRoundedIcon color="success" />
                      </IconButton>
                    </Box>
                  </Grid>
                )}
              </Stack>
            );
          })}
        </Stack>
      )}
    </Stack>
  );
};
