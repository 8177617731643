import { useTranslate } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { useGetDailyMeanCurrent } from "../../api/useGetDailyMeanCurrent";
import { DailyMeanCurrentChart } from "./DailyMeanCurrentChart";

export const DailyMeanCurrentChartAllTime = ({ motor }: { motor: number }) => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetDailyMeanCurrent({ motor });

  if (isLoading) {
    return <AnalyticContainerSkeleton />;
  }

  if (error) {
    return (
      <Card sx={{ height: 400 }}>
        {translate("user_feedback.an_error_occurred")}
      </Card>
    );
  }

  if (!data) {
    return (
      <Card sx={{ height: 400 }}>{translate("user_feedback.no_data")}</Card>
    );
  }

  return (
    <Card>
      <DailyMeanCurrentChart
        title={translate("motors.all_time_mean_current")}
        seriesData={data.meanData.map((entry) => ({
          x: entry[0],
          y: entry[1],
          marker: { radius: 2 },
        }))}
      />
    </Card>
  );
};
