import { Box, Typography } from "@mui/material";
import { Card } from "@/components/Layout/Card";
import { Phase } from "../../types";
import { readablePhase } from "../../utils/readablePhase";
import { colors } from "@/styles/colors";
import { SolidGaugeWithPane } from "@/components/highcharts/gauge/SolidGaugeWithPane";
import { EVALUATION_COLORS } from "../../utils/evaluationColors";

interface PhaseCardProps {
  phase: Phase;
  onSelectedPhase: (phase: Phase) => void;
  active: boolean;
  goodBatches?: string[];
  badBatches?: string[];
}

export const PhaseCard = ({
  phase,
  onSelectedPhase,
  active,
  goodBatches,
  badBatches,
}: PhaseCardProps) => {
  const goodCount = goodBatches ? goodBatches.length : 0;
  const badCount = badBatches ? badBatches.length : 0;
  const totalCount = goodCount + badCount;
  const goodPercentage = totalCount > 0 ? (100 / totalCount) * goodCount : 0;
  const badPercentage = totalCount > 0 ? (100 / totalCount) * badCount : 0;

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: active ? "#444959" : colors.palette.darkBlue,
        transition: "all .2s ease-in",
        color: "#fff",
        width: "180px",
        maxHeight: "180px",
        cursor: "pointer",
        borderRadius: ".25rem",
      }}
      onClick={() => onSelectedPhase(phase)}
      initial={{ opacity: 0, scale: 0.75, x: 20 }}
      animate={{ opacity: 1, scale: 1, x: 0 }}
      exit={{ opacity: 0 }}
    >
      <Box height="100%">
        <Typography sx={{ fontWeight: "bold" }}>
          {readablePhase(phase)}
        </Typography>
        <>
          <Typography variant="caption">{`${goodCount}/${
            goodCount + badCount
          }`}</Typography>
          <SolidGaugeWithPane
            title=""
            seriesName="completed"
            seriesValue={
              goodCount === 0 && badCount === 0
                ? 0
                : parseFloat(
                    ((100 / (badCount + goodCount)) * goodCount).toFixed(2),
                  )
            }
            tooltipOptions={{
              outside: true,
              formatter: function () {
                let goodBatchesList = "";
                let badBatchesList = "";

                if (goodBatches && badBatches) {
                  goodBatches.forEach((el) => (goodBatchesList += `${el}<br>`));
                  badBatches.forEach((el) => (badBatchesList += `${el}<br>`));
                }
                return `
                  <span style="color:${EVALUATION_COLORS.good.selected}">● </span>completed: <b>${goodPercentage}%</b><br>
                  ${goodBatchesList}<br>
                  <span style="color:${EVALUATION_COLORS.critical.selected}">● </span>not completed: <b>${badPercentage}%</b><br>
                  ${badBatchesList}
                `;
              },
            }}
            seriesColor={colors.kpi.quality}
            paneColor={colors.palette.lightBlue}
            chartHeight="75%"
          />
        </>
      </Box>
    </Card>
  );
};
