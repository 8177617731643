import { useFirebaseContext } from "@/context/firebase-context";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { ThingworxError } from "src/types/error";
import { FetchClient } from "@/services/ApiClient";

type Response = ThingworxError | { response: true };

type Payload = {
  platformLvl: number;
  ruleName: string;
  eventLoop: boolean;
};

export const useChangeEventLoop = () => {
  const { appKey, user } = useFirebaseContext();
  const account = user?.email as string;
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (payload: Payload) => {
      return await FetchClient<Payload, Response>({
        appKey,
        payload,
        url: "dig.c.event_thing/Services/updateEventLoop",
      });
    },
    onSuccess(data, variables) {
      if (data.response) {
        toast.success("Event Loop updated successfully");
      } else {
        toast.error("Unable to update Event Loop");
      }
      client.invalidateQueries({
        queryKey: ["list-of-rules", account, variables.platformLvl],
      });
    },
  });
};
