import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { useMachineContext } from "@/context/machine-context";
import { Run, noveltyAnomalyAnalytic } from "../../types";
import { useAcoposContext } from "../../context/acopos-context";

export type GetRunsLapsShuttlesListResponse =
  | ThingworxError
  | GetRunsLapsShuttlesListResult;

type Payload = {
  machineName: string;
  runs: Run[];
};

const element = z.object({
  id: z.number(),
  clientName: z.string(),
});

const runsLapsShuttles = z.array(
  z.object({
    id: z.number(),
    clientName: z.string(),
    laps: z.array(element),
    shuttles: z.array(element),
  }),
);

export type RunsLapsShuttles = z.infer<typeof runsLapsShuttles>;

const getRunsLapsShuttlesListResult = z.object({
  response: z.literal(true),
  data: runsLapsShuttles,
  analyticsList: z.array(noveltyAnomalyAnalytic),
});

export type GetRunsLapsShuttlesListResult = z.infer<
  typeof getRunsLapsShuttlesListResult
>;

export const useGetRunsLapsShuttlesList = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const { runs } = useAcoposContext();

  return useQuery<GetRunsLapsShuttlesListResult>({
    queryKey: [
      "runs-laps-shuttles-list",
      machine!.machine,
      JSON.stringify(runs),
    ],
    queryFn: async () => {
      const response = await FetchClient<
        Payload,
        GetRunsLapsShuttlesListResponse
      >({
        appKey,
        payload: {
          machineName: machine!.machine,
          runs,
        },
        // url: "dig.c.acopostrak_thing/Services/getRunsLapsShuttlesList",
        url: "dig.c.acoposTrack_thing/Services/getRunsLapsShuttlesList",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = getRunsLapsShuttlesListResult.parse(response);

      return validatedResponse;
    },
  });
};
