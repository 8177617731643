import Stack from "@mui/material/Stack";
import { useMediaQuery } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";

export const ConfigurableLoaderLayout = () => {
  const extraSmallScreen = useMediaQuery("(max-width:900px)");
  const smallScreen = useMediaQuery("(max-width:1190px)");
  return (
    <Stack spacing={2}>
      <Grid2
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        md={12}
      >
        <Grid2
          container
          md={4}
          xs={12}
          justifyContent={"center"}
          marginBottom={extraSmallScreen ? 2 : 0}
          alignItems={"center"}
        >
          <SkeletonCard sx={{ borderRadius: "2rem", marginBottom: "1rem" }} />
          <Grid2
            container
            flexWrap={"nowrap"}
            alignItems={"flex-end"}
            justifyContent={"center"}
          >
            <Grid2 alignItems={"center"} style={{ flexBasis: "fit-content" }}>
              <SkeletonCard
                variant="circular"
                width={extraSmallScreen ? 150 : 100}
                height={extraSmallScreen ? 150 : 100}
              />
            </Grid2>
            <Grid2 style={{ flexBasis: "fit-content" }}>
              <SkeletonCard
                variant="circular"
                width={extraSmallScreen ? 150 : smallScreen ? 100 : 150}
                height={extraSmallScreen ? 150 : smallScreen ? 100 : 150}
              />
            </Grid2>
            <Grid2 alignItems={"center"} style={{ flexBasis: "fit-content" }}>
              <SkeletonCard
                variant="circular"
                width={extraSmallScreen ? 150 : 100}
                height={extraSmallScreen ? 150 : 100}
              />
            </Grid2>
          </Grid2>
        </Grid2>
        <Grid2 xs={12} md={4} order={extraSmallScreen ? 1 : 0}>
          <SkeletonCard
            sx={{ margin: "auto" }}
            variant="circular"
            width={220}
            height={220}
          />
        </Grid2>

        <Grid2
          container
          md={4}
          xs={12}
          justifyContent={"center"}
          marginBottom={extraSmallScreen ? 2 : 0}
          alignItems={"center"}
        >
          <SkeletonCard sx={{ borderRadius: "2rem", marginBottom: "1rem" }} />
          <Grid2
            container
            flexWrap={"nowrap"}
            alignItems={"flex-end"}
            justifyContent={"center"}
          >
            <Grid2
              alignItems={"center"}
              xs={12}
              md={6}
              style={{ flexBasis: "fit-content" }}
            >
              <SkeletonCard
                variant="circular"
                width={extraSmallScreen ? 150 : 100}
                height={extraSmallScreen ? 150 : 100}
              />
            </Grid2>
            <Grid2 style={{ flexBasis: "fit-content" }}>
              <SkeletonCard
                variant="circular"
                width={extraSmallScreen ? 150 : smallScreen ? 100 : 150}
                height={extraSmallScreen ? 150 : smallScreen ? 100 : 150}
              />
            </Grid2>
            <Grid2 alignItems={"center"} style={{ flexBasis: "fit-content" }}>
              <SkeletonCard
                variant="circular"
                width={extraSmallScreen ? 150 : 100}
                height={extraSmallScreen ? 150 : 100}
              />
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
      <SkeletonCard width={"100%"} height={300} />
      <Grid2 container columns={12} gap={2} justifyContent="space-between">
        <Grid2 padding={0} md={9.8} xs={12}>
          <SkeletonCard width={"100%"} height={250} />
        </Grid2>
        <Grid2 padding={0} md={2} xs={12}>
          <SkeletonCard width={"100%"} height={250} />
        </Grid2>
      </Grid2>
    </Stack>
  );
};
