import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { useLanguage } from "@/i18n/config";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type RecipeResponse = ThingworxError | RecipeResult;

const RecipeValue = z.object({
  Name: z.string(),
  Value: z.string(),
});

export type RecipeValue = z.infer<typeof RecipeValue>;

const RecipeResult = z.object({
  response: z.literal(true),
  Result: z.array(RecipeValue),
});

export type RecipeResult = z.infer<typeof RecipeResult>;

export const useGetRecipe = () => {
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();
  const language = useLanguage();

  return useQuery<RecipeValue[]>({
    queryKey: ["machine-recipe", machine!.machine, language],
    queryFn: async () => {
      const response = await FetchClient<
        { machineName: string; language_id: string },
        RecipeResponse
      >({
        url: "dig.c.machineDetail_thing/Services/getRecipeParametersList",
        appKey,
        payload: {
          machineName: machine!.machine ?? "",
          language_id: language,
        },
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return RecipeResult.parse(response).Result;
    },
    refetchInterval: 1000 * 60 * 5,
    staleTime: 1000 * 60 * 5,
  });
};
