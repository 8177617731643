import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Tag } from "../../types";
import { Card } from "@/components/Layout/Card";
import { Asset } from "./Asset";
import { useIsolatorDispatchContext } from "../../context/useIsolatorDispatchContext";

export const TagListSelect = ({
  tagList,
  phaseId,
}: {
  tagList: Tag[];
  phaseId: number;
}) => {
  const [selectedTagId, setSelectedTagId] = useState<number | null>(null);
  const dispatch = useIsolatorDispatchContext();

  useEffect(() => {
    return () => {
      setSelectedTagId(null);
      dispatch({ type: "REMOVE_SELECTED_TAG" });
    };
  }, []);

  return (
    <Grid item xs={1} md={1}>
      <Card
        key={"condition-tag-list" + phaseId}
        initial={{ opacity: 0, scale: 0.75, x: 20 }}
        animate={{ opacity: 1, scale: 1, x: 0 }}
        exit={{ opacity: 0 }}
        sx={{
          maxHeight: 300,
          overflow: "auto",
        }}
      >
        {tagList.map((tag) => (
          <Asset
            key={tag.id}
            type="Tag"
            active={selectedTagId === tag.id}
            name={tag.name}
            evaluation={tag.evaluation}
            id={tag.id}
            onClick={(id) => {
              setSelectedTagId(id);
              dispatch({ type: "SET_SELECTED_TAG", item: id });
            }}
          />
        ))}
      </Card>
    </Grid>
  );
};
