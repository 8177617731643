import { ShowEmptyData } from "@/components/ShowEmptyData";
import { BasicTable } from "@/components/BasicTable";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { TableParser } from "@/utils/TableParser";
import { useGetMaterialStream, MaterialSummaryDetail } from "../api/useGetMaterialStream";
import { Card } from "@/components/Layout/Card";
import { useFormatTimestamp } from "@/utils/TimeHelpers";
import { useTranslate } from "@/i18n/config";

export const MaterialsList = () => {
  const { formatTimestamp } = useFormatTimestamp();
  const { data: materials, isLoading } = useGetMaterialStream();
  const translate = useTranslate();

  if (isLoading) return <AnalyticContainerSkeleton />;

  if (!materials) return null;

  if (materials.length === 0) {
    return <ShowEmptyData title={translate("materials")} />;
  }

  const { columns, rows } = TableParser.parseData<MaterialSummaryDetail>({
    data: materials,
    columnsMap: {
      ErrorDescription: translate("error_description"),
      FamilyDescription: translate("family_description"),
      Timestamp: translate("date"),
      ZoneDescription: translate("zone_description"),
    },
    columnsOrder: ["FamilyDescription", "ZoneDescription", "ErrorDescription", "Timestamp"],
    dateFormatter: formatTimestamp,
  });

  return (
    <Card>
      <BasicTable columns={columns} rows={rows} fileName="materials_data" />
    </Card>
  );
};
