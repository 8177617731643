import { MachineNodeView } from "@/features/OverallLineEfficiency/components/CustomNodes";
import { NodeProps, NodeTypes, Node } from "reactflow";
import { EditableHandle } from "../custom-handles/EditableHandle";
import { PlaceholderMachineNode } from "./view/PlaceholderNode";
import { memo, Fragment } from "react";
import { useTranslate } from "@/i18n/config";
/**
 * @description used to render nodes in the edit node modal
 */
type MachineEditNodeData = { name: string; isPlaceholder: boolean };
export type CustomMachineEditNode = Node<MachineEditNodeData, "machine">;

/** */
const MachineEditNode = memo((props: NodeProps<MachineEditNodeData>) => {
  const translate = useTranslate();
  return (
    <Fragment>
      <EditableHandle id="1" />
      <EditableHandle id="2" />
      <EditableHandle id="3" />
      <EditableHandle id="4" />
      {props.data.isPlaceholder ? (
        <PlaceholderMachineNode model={props.data.name} />
      ) : (
        <MachineNodeView
          machineName={props.data?.name ?? ""}
          data={{
            categoryCode: 1,
            rejected_products: 10,
            total_products: 100,
            extraCapacity: 10,
            hasBatch: false,
            hasSpeed: false,
            lineLosses: 20,
            machineLosses: 30,
            machine_type: "cyclic",
            machineLogic: "parametric",
            oee: 10,
            processLosses: 20,
            response: true,
            state: translate("production"),
            products: Math.floor(Math.random() * 100),
            machineTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          }}
        />
      )}

      <EditableHandle id="5" />
      <EditableHandle id="6" />
      <EditableHandle id="7" />
      <EditableHandle id="8" />
    </Fragment>
  );
});

export const machineNodeTypes: NodeTypes = {
  machine: MachineEditNode,
};
