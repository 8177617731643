import { useState } from "react";
import { Box, Button, Collapse, List, Typography } from "@mui/material";
import { XrangePointOptionsObject } from "highcharts/highstock";
import { Card } from "@/components/Layout/Card";
import {
  EVALUATION_COLORS,
  MAX_RUNS_NUMBER,
  useAcoposContext,
  useAcoposDispatchContext,
} from "../../context/acopos-context";
import { useTranslate } from "@/i18n/config";
import { Evaluation, Run, evaluation } from "../../types";
import { SelectedRun } from "./SelectedRun";
import { ExpandLess, ExpandMore, FilterAltOutlined } from "@mui/icons-material";
import { useDisclosure } from "@/hooks/useDisclosure";
import { Modal } from "@/components/Modal";
import { useGetRuns } from "../../api/Common/useGetRuns";
import { XRangeWithContextMenu } from "./XRangeWithContextMenu";
import { toast } from "react-hot-toast";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { ONE_MINUTE } from "@/utils/durationsInMilliseconds";

const THIRTY_MINUTES = ONE_MINUTE * 30;

const parseData = ({
  data,
  selectedRuns,
  filterBy,
}: {
  data: Run[];
  selectedRuns: Run[];
  filterBy: Evaluation | "all" | "30m";
}): XrangePointOptionsObject[] => {
  const filteredData =
    filterBy === "all"
      ? data
      : filterBy === "30m"
      ? data.filter((entry) => entry.end - entry.start > THIRTY_MINUTES)
      : data.filter((entry) => entry.evaluation === filterBy);

  return filteredData.map((occurrence) => {
    const { name, start, end, evaluation } = occurrence;
    const isSelected = selectedRuns.find(
      (run) => `${run.start}-${run.end}` === `${start}-${end}`,
    );
    const color = isSelected
      ? EVALUATION_COLORS[evaluation].selected
      : EVALUATION_COLORS[evaluation].notSelected;

    return {
      x: start,
      x2: end,
      y: 0,
      color,
      name: name,
    };
  });
};

export const SelectRuns = ({ enableFilter }: { enableFilter: boolean }) => {
  const translate = useTranslate();
  const [expand, setExpand] = useState(true);
  const [filterBy, setFilterBy] = useState<Evaluation | "all" | "30m">("all");
  const { runs, isRunsLimitActive } = useAcoposContext();
  const dispatch = useAcoposDispatchContext();
  const { open, close, isOpen } = useDisclosure();
  const { data: apiData, error, isLoading } = useGetRuns();

  if (isLoading) return <SkeletonCard height={180} />;
  if (error) return <Card>{translate("user_feedback.an_error_occurred")}</Card>;
  if (!apiData) return <Card>{translate("user_feedback.no_data")}</Card>;

  if (apiData.runs.length === 0)
    return <Card>{translate("user_feedback.no_data_to_display")}</Card>;

  const data = parseData({
    data: apiData.runs,
    selectedRuns: runs,
    filterBy,
  });

  const onClickRun = ({ start, end }: { start: number; end: number }) => {
    const clonedData: Run[] = apiData.runs.map((entry) => {
      return { ...entry };
    });
    const selectedRun = clonedData.find(
      (lap) => lap.start === start && lap.end === end,
    );
    if (selectedRun) {
      const isAlreadySelected =
        runs.findIndex(
          (run) =>
            `${run.start}-${run.end}` ===
            `${selectedRun.start}-${selectedRun.end}`,
        ) > -1;

      if (isAlreadySelected) {
        dispatch({ type: "REMOVE_RUN", item: selectedRun });
      } else {
        if (isRunsLimitActive && runs.length >= MAX_RUNS_NUMBER) {
          // toast(
          //   translate("user_feedback.max_n_selected", { max: MAX_RUNS_NUMBER }),
          //   { icon: "⚠" }
          // );
          toast.error(
            translate("user_feedback.max_n_selected", { max: MAX_RUNS_NUMBER }),
          );
        } else {
          dispatch({ type: "ADD_RUN", item: selectedRun });
        }
      }
    }
  };

  // const handleConfirm = () => {
  //   console.log("confirm");
  //   //? far partire chiamate
  //   //* mutation
  // };

  return (
    <>
      {isOpen && (
        <Modal
          open={isOpen}
          titleContent={translate("actions.show")}
          fullWidth
          maxWidth="lg"
          bodyContent={
            <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
              <Button
                variant="outlined"
                sx={{ width: 120 }}
                onClick={() => {
                  setFilterBy("all");
                  close();
                }}
              >
                {translate("all")}
              </Button>
              <Button
                variant="outlined"
                sx={{ width: 120 }}
                onClick={() => {
                  setFilterBy("30m");
                  close();
                }}
              >
                {`> 30 ${translate("durations.minute_other")}`}
              </Button>
              {Object.values(evaluation.Values).map((value) => (
                <Button
                  key={value}
                  sx={{ width: 120 }}
                  variant="outlined"
                  onClick={() => {
                    setFilterBy(value);
                    close();
                  }}
                >
                  {translate(`evaluation.${value}`)}
                </Button>
              ))}
            </Box>
          }
          actions={
            <Button variant="outlined" onClick={close}>
              {translate("actions.cancel")}
            </Button>
          }
        />
      )}

      <Card sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box display="flex" alignSelf="flex-end">
          <Button onClick={() => setExpand(!expand)}>
            <Typography>{translate("acopos.run_selection")}</Typography>
            {expand ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </Box>
        <Collapse in={expand} timeout="auto" unmountOnExit>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ p: ".5rem", display: "flex", gap: 1 }}>
              <Button variant="outlined" onClick={() => open()}>
                <FilterAltOutlined />
              </Button>
              {enableFilter && (
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (filterBy === "all") {
                      dispatch({ type: "UPDATE_RUNS", item: apiData.runs });
                    } else if (filterBy === "30m") {
                      dispatch({
                        type: "UPDATE_RUNS",
                        item: apiData.runs.filter(
                          (entry) => entry.end - entry.start > THIRTY_MINUTES,
                        ),
                      });
                    } else {
                      dispatch({
                        type: "UPDATE_RUNS",
                        item: apiData.runs.filter(
                          (entry) => entry.evaluation === filterBy,
                        ),
                      });
                    }
                  }}
                >
                  {translate("actions.select_all")}
                </Button>
              )}
              <Button
                variant="outlined"
                onClick={() => dispatch({ type: "CLEAR_RUNS" })}
              >
                {translate("actions.clear_all")}
              </Button>
              {/* <Button variant="outlined" onClick={handleConfirm}>
                  {translate("actions.confirm")}
                </Button> */}
            </Box>

            <Box sx={{ display: "flex", height: 100 }}>
              <Box sx={{ flex: 4 }}>
                <XRangeWithContextMenu data={data} onClickRun={onClickRun} />
              </Box>
              <List
                sx={{ maxWidth: 300, flex: 1, overflowY: "auto" }}
                component="nav"
              >
                {runs.length === 0 && (
                  <Typography p="1rem" variant="caption" color="#ccc">
                    {translate("acopos.no_run_selected")}
                  </Typography>
                )}
                {runs.map((run) => (
                  <SelectedRun key={run.name} run={run} />
                ))}
              </List>
            </Box>
          </Box>
        </Collapse>
      </Card>
    </>
  );
};
