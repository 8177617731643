/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Stack, Box, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useGetBatchData } from "../../api/useGetBatchData";
import { AnalyticContainer } from "../../components/AnalyticContainer/AnalyticContainer";
import { PhaseCard } from "../../components/PhaseCard/PhaseCard";
import { useLyoContext } from "../../context/lyo-context";
import { PhaseCardSkeletonLayout } from "../../components/PhaseCard/PhaseCardSkeletonLayout";
import { useTranslate } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";

export const DiagnosticView = () => {
  const translate = useTranslate();
  const [state, dispatch] = useLyoContext();

  const { data: response, isLoading: loadingBatchPhasesData } = useGetBatchData(
    {
      batch_id: state.selectedBatch?.batchId ?? null,
    },
  );

  if (!response) return null;

  return (
    <>
      <Stack
        gap={2}
        direction="row"
        flexWrap="wrap"
        sx={{ position: "relative" }}
      >
        <AnimatePresence presenceAffectsLayout mode="wait">
          {loadingBatchPhasesData ? (
            <>
              {Array(5)
                .fill("")
                .map((_, i) => (
                  // @ts-ignore
                  <PhaseCardSkeletonLayout
                    key={i}
                    // @ts-ignore
                    component={motion.div}
                    initial={{ opacity: 0, scale: 0.75, x: 20 }}
                    animate={{ opacity: 1, scale: 1, right: "100", x: 0 }}
                    exit={{ opacity: 0 }}
                    variant="rectangular"
                    width={124}
                    height={124}
                  />
                ))}
            </>
          ) : null}
          {response.data.length > 0 ? (
            <>
              {response.data
                .sort((a, b) => a.from - b.from)
                .map((batchData) => {
                  return (
                    <PhaseCard
                      active={state.selectedPhase?.id === batchData.id}
                      key={batchData.id}
                      batchData={batchData}
                      onSelectedBatch={(id) => {
                        const foundPhase = response.data.find(
                          (p) => p.id === id,
                        );
                        if (!foundPhase) return;
                        dispatch({
                          type: "pick phase",
                          selectedPhase: foundPhase,
                        });
                      }}
                    />
                  );
                })}
            </>
          ) : (
            <Card
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: 200,
              }}
            >
              <Typography variant="h5" sx={{ height: "fit-content" }}>
                {translate("user_feedback.no_data_to_display")}
              </Typography>
            </Card>
          )}
        </AnimatePresence>
      </Stack>
      <Box sx={{ marginTop: "1rem" }} />
      <AnimatePresence>
        {state.selectedBatch && state.selectedPhase ? (
          <AnalyticContainer
            name={state.selectedPhase.name}
            batchId={state.selectedBatch.batchId}
            selectedPhaseId={state.selectedPhase.id}
            selectedPhaseName={state.selectedPhase.name}
            batches={state.batches}
          />
        ) : null}
      </AnimatePresence>
    </>
  );
};
