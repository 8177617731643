import { Phase, Subphase } from "../types";

export const PHASE_ORDER: Phase[] = [
  "leak_test",
  "vhp",
  "pre_production",
  "production",
];

export const SUBPHASE_ORDER: Subphase[] = [
  "lt_setup",
  "inflating",
  "stabilization",
  "test",
  "lt_end",
  "deflating",
  "maintenance",
  "vhp_setup",
  "preconditioning",
  "conditioning",
  "decontamination",
  "gen_aeration",
  "prel_aeration",
  "int_aeration",
  "fin_aeration",
  "vhp_abort",
  "manual_setup",
  "pre_production",
  "production",
];
