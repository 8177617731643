import { XrangePointOptionsObject } from "highcharts";

export const DATA_BATCH_SIZE = 15000;

export const getDataBatches = (
  data: XrangePointOptionsObject[],
): { [key: string]: XrangePointOptionsObject[] } => {
  const batches: { [key: string]: XrangePointOptionsObject[] } = {};
  const totalStates = data.length;
  if (totalStates === 0) return batches;

  const numberOfBatches = Math.ceil(totalStates / DATA_BATCH_SIZE);
  const firstTimestamp = data[0].x!;
  const referenceTimestamps: number[] = [];
  const fragmentsLength = Math.round(totalStates / (numberOfBatches + 1));
  for (let i = 1; i < numberOfBatches; i++) {
    referenceTimestamps.push(data[fragmentsLength * i].x!);
  }
  if (numberOfBatches === 1) {
    batches[new Date(firstTimestamp).toLocaleString()] = data;
    return batches;
  }

  referenceTimestamps.forEach((timestamp, index) => {
    const batchIndex = index + 1;
    const isFirst = index === 0;
    const isLast = batchIndex === referenceTimestamps.length;
    const prevTimestamp = referenceTimestamps[index - 1];

    const batchName = isFirst
      ? new Date(firstTimestamp).toLocaleString()
      : new Date(prevTimestamp).toLocaleString();
    batches[batchName] = [];
    if (isFirst) {
      const statesBatch = data.filter(
        (occurrence) => occurrence.x! < timestamp,
      );
      batches[batchName] = statesBatch;
    } else {
      const statesBatch = data.filter(
        (occurrence) =>
          occurrence.x! > prevTimestamp && occurrence.x! < timestamp,
      );
      batches[batchName] = statesBatch;
    }
    if (isLast) {
      const name = new Date(timestamp).toLocaleString();
      const statesBatch = data.filter(
        (occurrence) => occurrence.x! > timestamp,
      );
      batches[name] = statesBatch;
    }
  });

  return batches;
};
