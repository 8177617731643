import { useState } from "react";
import { DateTime } from "luxon";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { useTranslate } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { useGetDailyMeanCurrent } from "../../api/useGetDailyMeanCurrent";
import { DailyMeanCurrentChartComparison } from "./DailyMeanCurrentChartComparison";

export const DailyMeanCurrentChartByRange = ({ motor }: { motor: number }) => {
  const translate = useTranslate();
  const [dates, setDates] = useState<
    [DateTime<true> | null, DateTime<true> | null]
  >([DateTime.now().minus({ months: 1 }), DateTime.now()]);
  const [comparisonDates, setComparisonDates] = useState<
    [DateTime<true> | null, DateTime<true> | null]
  >([
    DateTime.now().minus({ years: 1, months: 1 }),
    DateTime.now().minus({ years: 1 }),
  ]);
  const { data, isLoading, error } = useGetDailyMeanCurrent({
    motor,
    dateStart: dates[0]!,
    dateEnd: dates[1]!,
  });
  const {
    data: dataComparison,
    isLoading: isLoadingComparison,
    error: errorComparison,
  } = useGetDailyMeanCurrent({
    motor,
    dateStart: comparisonDates[0]!,
    dateEnd: comparisonDates[1]!,
  });

  if (isLoading || isLoadingComparison) {
    return <AnalyticContainerSkeleton />;
  }

  if (error || errorComparison) {
    return (
      <Card sx={{ height: 500 }}>
        {translate("user_feedback.an_error_occurred")}
      </Card>
    );
  }

  if (!data || !dataComparison) {
    return (
      <Card sx={{ height: 500 }}>{translate("user_feedback.no_data")}</Card>
    );
  }

  return (
    <Card sx={{ height: 500 }}>
      <DateRangePicker
        value={dates}
        format="dd/MM/yyyy"
        onChange={(newDates) => {
          setDates(newDates);
          setComparisonDates([
            newDates[0] ? newDates[0].minus({ years: 1 }) : null,
            newDates[1] ? newDates[1].minus({ years: 1 }) : null,
          ]);
        }}
        disableFuture
        sx={{ width: "fit-content" }}
      />
      {dates && comparisonDates && (
        <DailyMeanCurrentChartComparison
          title={translate("motors.mean_current_by_range")}
          dates={dates}
          comparisonDates={comparisonDates}
          seriesData={data.meanData}
          comparisonSeriesData={dataComparison.meanData}
        />
      )}
    </Card>
  );
};
