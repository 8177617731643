/* eslint-disable import/default */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import HighchartsReactOfficial from "highcharts-react-official";
import Highcharts, { PointOptionsObject } from "highcharts";
import addHeatmap from "highcharts/modules/heatmap";
import addNoDataToDisplay from "highcharts/modules/no-data-to-display";
import { z } from "zod";
import { Box, Fade } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { BatchEvaluation, batchEvaluation } from "../../types";

addHeatmap(Highcharts);
addNoDataToDisplay(Highcharts);

interface Props {
  title: string;
  xCategories: string[];
  yCategories: string[];
  seriesData: PointOptionsObject[];
}

export const BatchDiagnosticHeatmap = ({
  title,
  xCategories,
  yCategories,
  seriesData,
}: Props) => {
  const translate = useTranslate();
  const n = xCategories.length;
  const chartHeight = n < 3 ? 250 : n < 5 ? 300 : undefined;
  const xAxisLabelsStep =
    n > 1000
      ? 200
      : n > 500
      ? 100
      : n > 200
      ? 50
      : n > 100
      ? 20
      : n > 50
      ? 10
      : n > 10
      ? 4
      : 1;

  return (
    <Fade in easing="ease-in">
      <Box>
        <HighchartsReactOfficial
          highcharts={Highcharts}
          options={{
            chart: {
              type: "heatmap",
              zoomType: "xy",
              plotBorderWidth: 0,
              height: chartHeight,
              backgroundColor: "transparent",
              style: {
                fontFamily: "Montserrat, Helvetica Neue,Arial, sans-serif",
                color: "white",
              },
            },
            title: {
              text: title,
              align: "left",
              style: {
                color: "white",
              },
            },
            lang: {
              noData: translate("user_feedback.no_data_to_display"),
            },
            xAxis: {
              categories: xCategories,
              gridLineColor: "transparent",
              lineColor: "transparent",
              tickInterval: xAxisLabelsStep,
              labels: {
                style: {
                  color: "white",
                },
                formatter: function () {
                  const parseResult = z
                    .object({
                      value: z.string(),
                    })
                    .safeParse(this);
                  if (!parseResult.success) return "";

                  const { value } = parseResult.data;
                  const formattedValue = value.split(" - ")[0];
                  return formattedValue;
                },
              },
            },
            yAxis: {
              categories: yCategories,
              gridLineColor: "transparent",
              lineColor: "transparent",
              title: null,
              reversed: true,
              labels: {
                style: {
                  color: "white",
                  //? non capisco perché non funziona
                  width: 200,
                },
              },
            },
            legend: {
              enabled: false,
            },
            tooltip: {
              formatter: function () {
                const parseResult = z
                  .object({
                    point: z.object({
                      x: z.number(),
                      y: z.number(),
                      color: z.string(),
                      custom: z.object({
                        evaluation: batchEvaluation,
                      }),
                    }),
                  })
                  .safeParse(this);
                if (!parseResult.success) return "";

                const {
                  point: { x, y, custom },
                } = parseResult.data;
                const xCategory = xCategories[x];
                const yCategory = yCategories[y];
                const evaluation = custom.evaluation as BatchEvaluation;
                return `${xCategory}<br>
                  ${yCategory}<br>
                  Status: <b>${evaluation}</b>
                `;
              },
            },
            series: [
              {
                rowsize: 0.8,
                data: seriesData,
                turboThreshold: 0,
              },
            ],
            credits: {
              enabled: false,
            },
            exporting: {
              enabled: false,
            },
          }}
        />
      </Box>
    </Fade>
  );
};
