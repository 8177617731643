import { createContext, ReactNode, useContext } from "react";
import { useImmerReducer } from "use-immer";
import { MachineArray } from "../api/useGetLandingData";
import { Company, Factory, Line, Machine } from "../types";

interface State {
  machines: MachineArray[];
  filteredMachines: MachineArray[];
  filteringByCompany: Company | null;
  filteringByFactory: Factory | null;
  filteringByLine: Line | null;
  filteringByMachine: Machine | null;
  availableCompanyFilters: Company[];
  availableFactoryFilters: Factory[];
  availableLineFilters: Line[];
  availableMachineFilters: Machine[];
  treeStructure: NestedTree[];
  filteringByCategory: MachineArray["categoryCode"] | null;
}

export interface NestedTree {
  company_id: number;
  company_name: string;
  factories: {
    factory_id: number;
    factory_name: string;
    lines: {
      line_id: number;
      line_name: string;
      machines: MachineArray[];
    }[];
  }[];
}

type Action =
  | { type: "filter by Company"; company: Company }
  | { type: "filter by Factory"; factory: Factory }
  | { type: "filter by Line"; line: Line }
  | { type: "reset"; entity: "Company" | "Factory" | "Line" | "Machine" }
  | { type: "filter by Machine"; machine: Machine }
  | { type: "filter by Category"; category: MachineArray["categoryCode"] };

const reducer = (draft: State, action: Action) => {
  switch (action.type) {
    case "filter by Company":
      {
        draft.filteringByCompany = action.company;
        localStorage.setItem("home-filter-company", JSON.stringify(action.company));
        localStorage.removeItem("home-filter-factory");
        localStorage.removeItem("home-filter-line");
        localStorage.removeItem("home-filter-machine");

        const factories = draft.treeStructure.find(
          (company) => company.company_id === action.company.company_id,
        )?.factories;

        draft.availableFactoryFilters =
          factories?.map((factory) => ({
            factory_id: factory.factory_id,
            factory_name: factory.factory_name,
          })) || [];

        const lines = factories?.flatMap((factory) => factory.lines);

        draft.availableLineFilters =
          lines?.map((line) => ({
            line_id: line.line_id,
            line_name: line.line_name,
          })) || [];

        const machines = lines?.flatMap((line) => line.machines) || [];

        draft.availableMachineFilters = machines.map((machine) => ({
          machine_id: machine.machine_id,
          machine_name: machine.machine,
        }));

        draft.filteredMachines = machines;
      }
      break;

    case "filter by Factory":
      {
        draft.filteringByFactory = action.factory;
        localStorage.setItem("home-filter-factory", JSON.stringify(action.factory));
        localStorage.removeItem("home-filter-line");
        localStorage.removeItem("home-filter-machine");

        // find the company id based on the action.factory_id
        const company_id = draft.treeStructure.find((company) =>
          company.factories.find((factory) => factory.factory_id === action.factory.factory_id),
        )?.company_id;

        const lines = draft.treeStructure
          .find((company) => company.company_id === company_id)
          ?.factories.find((factory) => factory.factory_id === action.factory.factory_id)?.lines;

        draft.availableLineFilters =
          lines?.map((line) => ({
            line_id: line.line_id,
            line_name: line.line_name,
          })) || [];

        const machines = lines?.flatMap((line) => line.machines) || [];

        draft.availableMachineFilters = machines.map((machine) => ({
          machine_id: machine.machine_id,
          machine_name: machine.machine,
        }));

        draft.filteredMachines = machines;
      }
      break;

    case "filter by Line":
      {
        draft.filteringByLine = action.line;
        localStorage.setItem("home-filter-line", JSON.stringify(action.line));
        localStorage.removeItem("home-filter-machine");

        // find the company id and the factory id based on the action.line_id
        const company_id = draft.treeStructure.find((company) =>
          company.factories.find((factory) =>
            factory.lines.find((line) => line.line_id === action.line.line_id),
          ),
        )?.company_id;

        const factory_id = draft.treeStructure
          .find((company) => company.company_id === company_id)
          ?.factories.find((factory) =>
            factory.lines.find((line) => line.line_id === action.line.line_id),
          )?.factory_id;

        const machines =
          draft.treeStructure
            .find((company) => company.company_id === company_id)
            ?.factories.find((factory) => factory.factory_id === factory_id)
            ?.lines.find((line) => line.line_id === action.line.line_id)?.machines || [];

        draft.availableMachineFilters =
          machines?.map((machine) => ({
            machine_id: machine.machine_id,
            machine_name: machine.machine,
          })) || [];

        draft.filteredMachines = machines;
      }

      break;

    case "filter by Machine":
      {
        draft.filteringByMachine = action.machine;
        localStorage.setItem("home-filter-machine", JSON.stringify(action.machine));

        // find the company id and the factory id and the line id based on the action.machine_id
        const company_id = draft.treeStructure.find((company) =>
          company.factories.find((factory) =>
            factory.lines.find((line) =>
              line.machines.find((machine) => machine.machine_id === action.machine.machine_id),
            ),
          ),
        )?.company_id;

        const factory_id = draft.treeStructure
          .find((company) => company.company_id === company_id)
          ?.factories.find((factory) =>
            factory.lines.find((line) =>
              line.machines.find((machine) => machine.machine_id === action.machine.machine_id),
            ),
          )?.factory_id;

        const line_id = draft.treeStructure
          .find((company) => company.company_id === company_id)
          ?.factories.find((factory) => factory.factory_id === factory_id)
          ?.lines.find((line) =>
            line.machines.find((machine) => machine.machine_id === action.machine.machine_id),
          )?.line_id;

        draft.filteredMachines = draft.treeStructure
          .find((company) => company.company_id === company_id)
          ?.factories.find((factory) => factory.factory_id === factory_id)
          ?.lines.find((line) => line.line_id === line_id)
          ?.machines.filter(
            (machine) => machine.machine_id === action.machine.machine_id,
          ) as MachineArray[];
      }

      break;

    case "reset":
      {
        switch (action.entity) {
          case "Company":
            {
              draft.filteringByCompany = null;
              draft.filteringByFactory = null;
              draft.filteringByLine = null;
              draft.filteringByMachine = null;
              localStorage.removeItem("home-filter-company");
              localStorage.removeItem("home-filter-factory");
              localStorage.removeItem("home-filter-line");
              localStorage.removeItem("home-filter-machine");

              const companies = draft.machines.map((machine) => ({
                company_name: machine.company,
                company_id: machine.company_id,
              }));

              const factories = draft.machines.map((machine) => ({
                factory_name: machine.factory,
                factory_id: machine.factory_id,
              }));

              const lines = draft.machines.map((machine) => ({
                line_name: machine.line,
                line_id: machine.line_id,
              }));
              const machine_names = draft.machines.map((machine) => ({
                machine_name: machine.machine,
                machine_id: machine.machine_id,
              }));
              draft.availableCompanyFilters = companies;
              draft.availableFactoryFilters = factories;
              draft.availableLineFilters = lines;
              draft.availableMachineFilters = machine_names;
              draft.filteredMachines = draft.machines;
            }
            break;
          case "Factory":
            {
              draft.filteringByFactory = null;
              draft.filteringByLine = null;
              draft.filteringByMachine = null;
              localStorage.removeItem("home-filter-factory");
              localStorage.removeItem("home-filter-line");
              localStorage.removeItem("home-filter-machine");

              const factories = (() => {
                if (draft.filteringByCompany) {
                  return (
                    draft.treeStructure
                      .find(
                        (company) => company.company_id === draft.filteringByCompany?.company_id,
                      )
                      ?.factories.map((factory) => ({
                        factory_name: factory.factory_name,
                        factory_id: factory.factory_id,
                      })) || []
                  );
                }

                return draft.treeStructure.flatMap((company) =>
                  company.factories.map((factory) => ({
                    factory_name: factory.factory_name,
                    factory_id: factory.factory_id,
                  })),
                );
              })();

              draft.treeStructure.find(
                (company) => company.company_id === draft.filteringByCompany?.company_id,
              )?.factories;

              draft.availableFactoryFilters = factories;

              const lines = (() => {
                if (draft.filteringByCompany) {
                  return (
                    draft.treeStructure
                      .find(
                        (company) => company.company_id === draft.filteringByCompany?.company_id,
                      )
                      ?.factories.flatMap(
                        (factory) =>
                          factory.lines.map((line) => ({
                            line_name: line.line_name,
                            line_id: line.line_id,
                          })) || [],
                      ) || []
                  );
                }

                return draft.treeStructure.flatMap((company) =>
                  company.factories.flatMap((factory) =>
                    factory.lines.map((line) => ({
                      line_name: line.line_name,
                      line_id: line.line_id,
                    })),
                  ),
                );
              })();

              draft.availableLineFilters = lines;

              const machines = (() => {
                if (draft.filteringByCompany) {
                  return draft.machines.filter(
                    (machine) => machine.company_id === draft.filteringByCompany?.company_id,
                  );
                }
                return draft.machines;
              })();

              draft.availableMachineFilters = machines.map((machine) => ({
                machine_id: machine.machine_id,
                machine_name: machine.machine,
              }));

              draft.filteredMachines = machines;
            }
            break;
          case "Line":
            {
              draft.filteringByLine = null;
              draft.filteringByMachine = null;
              localStorage.removeItem("home-filter-line");
              localStorage.removeItem("home-filter-machine");

              const machines = (() => {
                if (draft.filteringByFactory) {
                  return draft.machines.filter(
                    (machine) => machine.factory_id === draft.filteringByFactory?.factory_id,
                  );
                } else if (draft.filteringByCompany) {
                  return draft.machines.filter(
                    (machine) => machine.company_id === draft.filteringByCompany?.company_id,
                  );
                }
                return draft.machines;
              })();

              draft.availableMachineFilters =
                machines.map((machine) => ({
                  machine_id: machine.machine_id,
                  machine_name: machine.machine,
                })) || [];

              draft.filteredMachines = machines;
            }
            break;
          case "Machine":
            {
              draft.filteringByMachine = null;
              localStorage.removeItem("home-filter-machine");

              const machines = (() => {
                if (draft.filteringByLine) {
                  return draft.machines.filter(
                    (machine) => machine.line_id === draft.filteringByLine?.line_id,
                  );
                } else if (draft.filteringByFactory) {
                  return draft.machines.filter(
                    (machine) => machine.factory_id === draft.filteringByFactory?.factory_id,
                  );
                } else if (draft.filteringByCompany) {
                  return draft.machines.filter(
                    (machine) => machine.company_id === draft.filteringByCompany?.company_id,
                  );
                }
                return draft.machines;
              })();

              draft.availableMachineFilters =
                machines?.map((machine) => ({
                  machine_id: machine.machine_id,
                  machine_name: machine.machine,
                })) || [];

              draft.filteredMachines = machines;
            }
            break;
        }
      }

      break;

    case "filter by Category":
      {
        if (draft.filteringByCategory === action.category) {
          draft.filteringByCategory = null;
          return;
        }

        draft.filteringByCategory = action.category;
      }
      break;

    default:
      break;
  }
};

export function parseNestedTree(machines: MachineArray[]): NestedTree[] {
  return machines.reduce((acc, machine) => {
    const company = acc.find((company) => company.company_id === machine.company_id);

    if (company) {
      const factory = company.factories.find(
        (factory) => factory.factory_id === machine.factory_id,
      );
      if (factory) {
        const line = factory.lines.find((line) => line.line_id === machine.line_id);
        if (line) {
          line.machines.push(machine);
        } else {
          factory.lines.push({
            line_name: machine.line,
            line_id: machine.line_id,
            machines: [machine],
          });
        }
      } else {
        company.factories.push({
          factory_name: machine.factory,
          factory_id: machine.factory_id,
          lines: [
            {
              line_name: machine.line,
              line_id: machine.line_id,
              machines: [machine],
            },
          ],
        });
      }
    } else {
      acc.push({
        company_name: machine.company,
        company_id: machine.company_id,
        factories: [
          {
            factory_name: machine.factory,
            factory_id: machine.factory_id,
            lines: [
              {
                line_name: machine.line,
                line_id: machine.line_id,
                machines: [machine],
              },
            ],
          },
        ],
      });
    }
    return acc;
  }, [] as NestedTree[]);
}

const LandingContext = createContext<[State, React.Dispatch<Action>]>([
  {
    machines: [],
    filteredMachines: [],
    filteringByCompany: null,
    filteringByFactory: null,
    filteringByLine: null,
    filteringByMachine: null,
    filteringByCategory: null,
    availableCompanyFilters: [],
    availableFactoryFilters: [],
    availableLineFilters: [],
    availableMachineFilters: [],
    treeStructure: [],
  },
  () => {},
]);

export const LandingContextProvider = ({
  children,
  machines,
}: {
  machines: MachineArray[];
  children: ReactNode;
}) => {
  const companies = machines.map((machine) => ({
    company_name: machine.company,
    company_id: machine.company_id,
  }));

  const factories = machines.map((machine) => ({
    factory_name: machine.factory,
    factory_id: machine.factory_id,
  }));

  const lines =
    machines.map((machine) => ({
      line_name: machine.line,
      line_id: machine.line_id,
    })) || [];

  const machine_names =
    machines.map((machine) => ({
      machine_name: machine.machine,
      machine_id: machine.machine_id,
    })) || [];

  return (
    <LandingContext.Provider
      value={useImmerReducer<State, Action>(reducer, {
        machines: machines,
        filteredMachines: machines,
        filteringByCompany: null,
        filteringByFactory: null,
        filteringByLine: null,
        filteringByMachine: null,
        filteringByCategory: null,
        availableCompanyFilters: companies,
        availableFactoryFilters: factories,
        availableLineFilters: lines,
        availableMachineFilters: machine_names,
        treeStructure: parseNestedTree(machines),
      })}
    >
      {children}
    </LandingContext.Provider>
  );
};

export const useLandingContext = () => useContext(LandingContext);
