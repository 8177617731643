export const generatePlotBands = ({
  data,
  minimumDate,
  maxDate,
  currentState,
}: {
  data: number[][];
  minimumDate: number;
  maxDate: number;
  currentState: string;
}): number[][] => {
  const plotBands: number[][] = [];

  if (data.length === 0 && currentState === "Not Contaminated Production") {
    plotBands.push([minimumDate, 100], [maxDate, 100]);
    return [
      [minimumDate, 100],
      [maxDate, 100],
    ];
  }

  data.forEach((el, i) => {
    const [timestamp, value] = el;

    if (i === 0 && value === 0) {
      plotBands.push([minimumDate, 100], [timestamp, 0]);
    }
    if (i === data.length - 1 && value === 100) {
      plotBands.push([timestamp, 100], [new Date().getTime(), 100]);
    }
    if (value === 0) {
      plotBands.push([timestamp, 100], [timestamp, 0]);
    } else if (value === 100) {
      plotBands.push([timestamp, 0], [timestamp, 100]);
    }
  });

  return plotBands;
};
