import { Card } from "@/components/Layout/Card";
import { Heatmap } from "@/components/highcharts/heatmap/Heatmap";
import Grid2 from "@mui/material/Unstable_Grid2";

export const NeuralNetworkSummary = () => {
  return (
    <Card>
      <Grid2 container padding={2} spacing={4}>
        <Grid2 xs={6}>
          <Heatmap.ForecastAccuracy />
        </Grid2>
        <Grid2 xs={6}>
          <div className="text-container">
            <h3>Neural Network Summary</h3>
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ textAlign: "left" }}></th>
                  <th style={{ textAlign: "left" }}>Precision</th>
                  <th style={{ textAlign: "left" }}>Recall</th>
                  <th style={{ textAlign: "left" }}>F1-score</th>
                  <th style={{ textAlign: "left" }}>Support</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    M3410
                  </td>
                  <td>0,91</td>
                  <td>0,98</td>
                  <td>0,94</td>
                  <td>93</td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    M3420
                  </td>
                  <td>0,80</td>
                  <td>0,50</td>
                  <td>0,62</td>
                  <td>8</td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    M3430
                  </td>
                  <td>0,96</td>
                  <td>0,93</td>
                  <td>0,95</td>
                  <td>58</td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    M3440
                  </td>
                  <td>0,81</td>
                  <td>0,72</td>
                  <td>0,76</td>
                  <td>18</td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Accuracy
                  </td>
                  <td></td>
                  <td></td>
                  <td>0,92</td>
                  <td>177</td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Macro avg
                  </td>
                  <td>0,87</td>
                  <td>0,78</td>
                  <td>0,82</td>
                  <td>177</td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Weighted avg
                  </td>
                  <td>0,91</td>
                  <td>0,92</td>
                  <td>0,91</td>
                  <td>177</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Grid2>
      </Grid2>
    </Card>
  );
};
