import { styled } from "@mui/system";
import { Card } from "@/components/Layout/Card";

export const PhaseCardLayout = styled(Card)(() => ({
  color: "#fff",
  width: "110px",
  minWidth: "fit-content",
  backgroundImage: "none",
  padding: "1rem",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  minHeight: "110px",
  cursor: "pointer",
  borderRadius: ".25rem",
}));
