import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { useState, useEffect } from "react";
import { Stack, TextField, Typography } from "@mui/material";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { useGetMachineEvents } from "./api/useGetMachineEvents";
import { Card } from "@/components/Layout/Card";
import { queryAlerts } from "../Events/utils/queryAlerts";
import { Event } from "./components/Event";
import { useTranslate } from "@/i18n/config";

export const EventsLog = () => {
  const [query, setQuery] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const { data: dataEvents, isLoading, error } = useGetMachineEvents();
  const translate = useTranslate();

  useEffect(() => {
    const queryInputTimeout = setTimeout(() => {
      setQuery(searchValue);
    }, 500);
    return () => clearTimeout(queryInputTimeout);
  }, [searchValue]);

  if (isLoading)
    return (
      <>
        <MachineViewNavBar />
        <Stack spacing={1}>
          <SkeletonCard height={80} />
          <SkeletonCard height={400} />
        </Stack>
      </>
    );

  if (error) return <div>{translate("user_feedback.an_error_occurred")}</div>;

  if (!dataEvents) return <div>{translate("user_feedback.no_data")}</div>;

  const filteredEvents =
    query === "" ? dataEvents : queryAlerts({ alerts: dataEvents, query });

  return (
    <>
      <MachineViewNavBar />
      <Stack spacing={1}>
        <Card>
          <TextField
            id="search"
            label={translate("actions.search")}
            type="search"
            fullWidth
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Card>
        <Card sx={{ display: "flex", gap: 2 }}>
          <Typography sx={{ flex: 3 }} fontWeight="bold">
            {translate("events.event_description")}
          </Typography>
          <Typography sx={{ flex: 3 }} fontWeight="bold">
            {translate("timestamp")}
          </Typography>
          <Typography sx={{ flex: 5 }} fontWeight="bold">
            {translate("events.event_type")}
          </Typography>
          <Typography sx={{ flex: 1 }} fontWeight="bold">
            {translate("events.severity")}
          </Typography>
        </Card>
        <Stack gap={0.5}>
          {filteredEvents.length === 0 && (
            <p>{translate("events.no_events")}</p>
          )}
          {filteredEvents.map((alert) => (
            <Event key={alert.id} info={alert} />
          ))}
        </Stack>
      </Stack>
    </>
  );
};
