import { SelectTimeSelection } from "@/components/SelectTimeSelection";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { Stack } from "@mui/material";
import { ChangeOverList } from "./components/ChangeOverList";
import { ChangeOverSummaryCounters } from "./components/ChangeOverSummaryCounters";
import { HomingList } from "./components/HomingList";

export const ChangeOver = () => {
  return (
    <>
      <MachineViewNavBar>
        <SelectTimeSelection />
      </MachineViewNavBar>
      <Stack gap={2}>
        <ChangeOverList />
        <HomingList />
        <ChangeOverSummaryCounters />
      </Stack>
    </>
  );
};
