import { useCallback, useState } from "react";
import {
  Stack,
  Typography,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
  Menu,
  MenuItem,
  MenuProps,
  alpha,
  styled,
  Button,
  Radio,
  RadioGroup,
  Input,
} from "@mui/material";
import { Add, Delete, Edit, MoreVert, Share } from "@mui/icons-material";

import { useDisclosure } from "@/hooks/useDisclosure";
import { Chart, useGetUserCharts } from "../api/useGetUserCharts";
import { CreateChartModal } from "./CreateChartModal";
import { Card } from "@/components/Layout/Card";
import { useDeleteChart } from "../api/useDeleteChart";
import { useAskConfirmAction } from "@/hooks/ask-confirm-action";
import { useGetSharableUsersList } from "../api/useGetSharableUsersList";
import { Modal } from "@/components/Modal";
import { Controller, useForm } from "react-hook-form";
import { useShareChart } from "../api/useShareChart";
import { useEditChartName } from "../api/useEditChartName";
import { useTranslate } from "@/i18n/config";

export const ChartSidebar = ({
  activeCharts,
  selectChart,
}: {
  activeCharts: number[];
  selectChart: (chartId: number, checked: boolean) => void;
}) => {
  const { data: charts } = useGetUserCharts();
  const { open, close, isOpen } = useDisclosure();

  const closeModalHandler = useCallback(() => close(), []);

  return (
    <>
      <Card
        sx={{
          maxWidth: "25%",
          height: "calc(100vh - 96px)",
          flex: 1,
          position: "relative",
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6" sx={{ marginBottom: 4 }}>
            Custom Charts
          </Typography>
        </Stack>
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
          size="small"
          onClick={open}
        >
          <Add />
        </IconButton>
        <FormGroup>
          {charts?.map((chart) => (
            <ChartItem
              key={chart.id}
              chart={chart}
              isActive={activeCharts.includes(chart.id)}
              selectChart={selectChart}
            />
          ))}
        </FormGroup>
      </Card>
      <CreateChartModal open={isOpen} onClose={closeModalHandler} />
    </>
  );
};

const ChartItem = ({
  chart,
  selectChart,
  isActive,
}: {
  chart: Chart;
  selectChart: (chart_id: number, checked: boolean) => void;
  isActive: boolean;
}) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <FormControlLabel
        key={chart.id}
        control={
          <Checkbox
            onChange={(e) => selectChart(chart.id, e.target.checked)}
            checked={isActive}
          />
        }
        label={chart.name}
      />
      <ChartMenu chart={chart} onDelete={() => selectChart(chart.id, false)} />
    </Stack>
  );
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const ChartMenu = ({
  chart,
  onDelete,
}: {
  chart: Chart;
  onDelete: () => void;
}) => {
  const translate = useTranslate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { mutate: deleteChart } = useDeleteChart();

  const { askConfirm, renderConfirmModal } = useAskConfirmAction(
    translate("custom_charts.ask_delete_chart_confirmation"),
    () => {
      deleteChart(chart.id, {
        onSuccess() {
          handleClose();
          onDelete();
        },
      });
    },
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size="small"
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <EditNameMenuItem chart={chart} />
        <MenuItem onClick={() => askConfirm()} disableRipple>
          <Delete />
          {translate("actions.delete")}
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />

        <ShareMenuItem chart={chart} />
      </StyledMenu>
      {renderConfirmModal()}
    </>
  );
};

const ShareMenuItem = ({ chart }: { chart: Chart }) => {
  const translate = useTranslate();
  const { open, isOpen, close } = useDisclosure(false);
  const { data: users = [] } = useGetSharableUsersList();

  const { mutate: shareChart } = useShareChart();
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<{
    user: string;
  }>({
    defaultValues: {
      user: "",
    },
  });

  return (
    <>
      <MenuItem onClick={() => open()} disableRipple>
        <Share />
        {translate("actions.share")}
      </MenuItem>
      <Modal
        open={isOpen}
        onClose={close}
        titleContent="Share chart"
        bodyContent={
          <form
            onSubmit={handleSubmit(({ user }) => {
              shareChart(
                { chart_id: chart.id, share_to: user },
                {
                  onSuccess() {
                    close();
                  },
                },
              );
            })}
          >
            <Stack spacing={2}>
              <Typography variant="body2">
                {translate("custom_charts.select_user_to_share_chart_with")}
              </Typography>
              <Controller
                control={control}
                name={"user"}
                rules={{
                  required: translate("select_user"),
                }}
                render={({ field }) => {
                  return (
                    <RadioGroup
                      {...field}
                      value={field.value}
                      onChange={(event, value) => field.onChange(value)}
                    >
                      {users.map((user) => (
                        <FormControlLabel
                          key={user}
                          control={<Radio />}
                          value={user}
                          label={user}
                        />
                      ))}
                    </RadioGroup>
                  );
                }}
              />
            </Stack>
            <Button onClick={close}>{translate("actions.cancel")}</Button>
            <Button type="submit" disabled={!isValid}>
              {translate("actions.confirm")}
            </Button>
          </form>
        }
      />
    </>
  );
};

const EditNameMenuItem = ({ chart }: { chart: Chart }) => {
  const translate = useTranslate();
  const { open, isOpen, close } = useDisclosure(false);

  const { mutate: editChartName } = useEditChartName();
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<{
    chart_name: string;
  }>({
    defaultValues: {
      chart_name: "",
    },
  });

  return (
    <>
      <MenuItem onClick={() => open()} disableRipple>
        <Edit />
        {translate("actions.edit_name")}
      </MenuItem>
      <Modal
        open={isOpen}
        onClose={close}
        titleContent={translate("actions.edit_name")}
        bodyContent={
          <form
            onSubmit={handleSubmit(({ chart_name }) => {
              editChartName(
                { chart_id: chart.id, name: chart_name },
                {
                  onSuccess() {
                    close();
                  },
                },
              );
            })}
          >
            <Stack spacing={2}>
              <Controller
                control={control}
                name={"chart_name"}
                rules={{
                  required: translate("custom_charts.enter_chart_name"),
                }}
                render={({ field }) => (
                  <Input
                    type="text"
                    {...field}
                    placeholder={translate("placeholders.enter_new_name")}
                  />
                )}
              />
              <Stack direction="row" justifyContent="flex-end">
                <Button onClick={close}>{translate("actions.cancel")}</Button>
                <Button type="submit" disabled={!isValid}>
                  {translate("actions.confirm")}
                </Button>
              </Stack>
            </Stack>
          </form>
        }
      />
    </>
  );
};
