import { SeriesColumnOptions } from "highcharts";
import { GroupedHistogramData } from "../types";
import { CHART_COLORS } from "./chartColors";

export const parseGroupedHistogramData = (
  data: GroupedHistogramData[],
): { categories: string[]; series: SeriesColumnOptions[] } => {
  const series: SeriesColumnOptions[] = [];
  const categories: string[] = [];

  data.forEach((entry, i) => {
    categories.push(entry.name);
    if (i === 0) {
      series.push(
        {
          type: "column",
          name: "Max",
          color: CHART_COLORS[0],
          data: [entry.max],
        },
        {
          type: "column",
          name: "Min",
          color: CHART_COLORS[1],
          data: [entry.min],
        },
      );
    } else {
      series[0].data?.push(entry.max);
      series[1].data?.push(entry.min);
    }
  });

  return { series, categories };
};
