import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

export const chartSchema = z.object({
  id: z.number(),
  name: z.string(),
});

type Result = ThingworxError | Response;
const Response = z.object({
  response: z.literal(true),
  chartList: z.array(chartSchema),
});
type Response = z.infer<typeof Response>;

export type Chart = z.infer<typeof chartSchema>;

export const useGetUserCharts = () => {
  const { user, appKey } = useFirebaseContext();
  const email = user?.email as string;

  return useQuery<Chart[]>({
    queryKey: ["user-chart", email],
    queryFn: async () => {
      const response = await FetchClient<{ account: string }, Result>({
        appKey,
        url: "dig.c.customChartsDev_thing/services/getUserCharts",
        payload: {
          account: email,
        },
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return Response.parse(response).chartList;
    },
  });
};
