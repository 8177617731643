import { Typography } from "@mui/material";
import { Card } from "@/components/Layout/Card";
import { AlertSchema } from "../api/useGetMachineEvents";
import { EventValues } from "@/features/Events/types";

const getValuesText = (values: EventValues): string => {
  if (Object.keys(values).length === 1) {
    return `${values.value_1}`;
  } else {
    return `between ${values.value_1} and ${values.value_2}`;
  }
};

export const Event = ({ info }: { info: AlertSchema }) => {
  const { ruleDescription, timestamp, priority, data } = info;

  let eventType = "";
  data.forEach((limit) => {
    const {
      isActiveDuration,
      duration,
      property,
      name,
      propertyNameClient,
      alertType,
      values,
      rate,
    } = limit;

    const formatted = new Date(duration * 1000).toISOString().slice(11, 19);
    if (property === "firstAlarm" || name === "firstAlarm") {
      const durationText =
        formatted == "00:00:00" ? "" : `with duration of ${formatted}`;

      eventType += `${propertyNameClient} ${alertType} ${getValuesText(
        values,
      )} ${durationText}`;
    } else {
      if (isActiveDuration) {
        eventType += `${propertyNameClient} ${alertType} ${getValuesText(
          values,
        )} and lasted for at least ${formatted} ${rate} times`;
      } else {
        eventType += `${propertyNameClient} ${alertType} ${getValuesText(
          values,
        )}`;
      }
    }
  });

  return (
    <Card sx={{ display: "flex", gap: 2 }}>
      <Typography sx={{ flex: 3 }}>{ruleDescription}</Typography>
      <Typography sx={{ flex: 3 }}>
        {new Date(timestamp).toLocaleString()}
      </Typography>
      <Typography sx={{ flex: 5 }}>{eventType}</Typography>
      <Typography sx={{ flex: 1 }}>{priority}</Typography>
    </Card>
  );
};
