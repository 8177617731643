import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import {
  contextInfo,
  groupedHistogramData,
  histogramData,
  lineData,
  pieData,
  stackedHistogramData,
} from "../../types";
import { useIsolatorContext } from "../../context/useIsolatorContext";

const analyticSchema = z.union([
  z.object({
    analyticName: z.literal("Passed vs failed batches"), //? 1
    chartType: z.literal("pie"),
    data: z.array(pieData),
    contextInfo,
  }),
  z.object({
    analyticName: z.literal("Inflation time"), //? 2
    chartType: z.literal("histogram"),
    data: z.array(histogramData),
    contextInfo,
  }),
  z.object({
    analyticName: z.literal("Leak rate"), //? 3
    chartType: z.literal("histogram"),
    data: z.array(histogramData),
    contextInfo,
  }),
  z.object({
    analyticName: z.literal("Min/max pressure"), //? 4
    chartType: z.literal("grouped_histogram"),
    data: z.array(groupedHistogramData),
    contextInfo,
  }),
  z.object({
    analyticName: z.literal("Phase duration"), //? 5
    chartType: z.literal("stacked_histogram"),
    data: z.array(stackedHistogramData),
    contextInfo,
  }),
  z.object({
    analyticName: z.literal("Aeration time"), //? 6
    chartType: z.literal("stacked_histogram"),
    data: z.array(stackedHistogramData),
    contextInfo,
  }),
  z.object({
    analyticName: z.literal("H2O2 concentration"), //? 7
    chartType: z.literal("line_with_area"),
    lineData: z.array(lineData), //? predisposto per gestire più linee
    areaData: z.array(z.array(z.number())), //* 3 indici: ts, upper, lower
    contextInfo,
  }),
  z.object({
    analyticName: z.literal("Preconditioning duration"), //? 8
    chartType: z.literal("histogram"),
    data: z.array(histogramData),
    contextInfo,
  }),
]);

export type AnalyticSchema = z.infer<typeof analyticSchema>;

export type GetDescriptiveAnalyticsResponse =
  | ThingworxError
  | GetDescriptiveAnalyticsResult;

const getDescriptiveAnalyticsResult = z.object({
  response: z.literal(true),
  chartData: z.array(analyticSchema),
});

export type GetDescriptiveAnalyticsResult = z.infer<
  typeof getDescriptiveAnalyticsResult
>;

type Payload = {
  machineName: string;
  batchIds: number[];
  analyticId: number;
  phaseId: number;
};

export const useGetDescriptiveAnalytics = ({
  analyticId,
  phaseId,
}: {
  analyticId: number;
  phaseId: number;
}) => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const { batches } = useIsolatorContext();
  const batchIds = batches.map((batch) => batch.id);

  return useQuery<GetDescriptiveAnalyticsResult>({
    queryKey: [
      "descriptive-analytics",
      machine!.machine,
      JSON.stringify(batchIds),
      analyticId,
      phaseId,
    ],
    queryFn: async () => {
      const response = await FetchClient<
        Payload,
        GetDescriptiveAnalyticsResponse
      >({
        appKey,
        payload: {
          machineName: machine!.machine,
          batchIds,
          analyticId,
          phaseId,
        },
        url: "dig.c.isolator_thing/Services/getDescriptiveAnalytics",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = getDescriptiveAnalyticsResult.parse(response);

      return validatedResponse;
    },
  });
};
