import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { useLyoContext } from "../context/lyo-context";
import { z } from "zod";

type VacuumAnalysisDurationResponse =
  | ThingworxError
  | VacuumAnalysisDurationResult;

const VacuumAnalysisDuration = z.object({
  batch_advanced_analytic_id: z.number(),
  batch_id: z.number(),
  envelope_id: z.number(),
  name: z.string(),
  from: z.number(),
  id: z.number(),
  to: z.number(),
  asset_id: z.number(),
  advanced_analytic_id: z.number(),
  machine_name: z.string(),
  display_name: z.string(),
  adv_chart_type: z.string(),
  batch_name: z.string(),
});

export type VacuumAnalysisDuration = z.infer<typeof VacuumAnalysisDuration>;

const VacuumAnalysisDurationResult = z.object({
  response: z.literal(true),
  durations: z.array(VacuumAnalysisDuration),
});

type VacuumAnalysisDurationResult = z.infer<
  typeof VacuumAnalysisDurationResult
>;

export const useGetVacuumPumpSummaryDurations = () => {
  const { appKey } = useFirebaseContext();
  const [{ filteredBatches }] = useLyoContext();

  const batchIds = filteredBatches
    .map((batch) => batch.batchId)
    .sort((a, b) => a - b);

  return useQuery<VacuumAnalysisDuration[]>({
    queryKey: ["vacuum-pump-duration-overview", batchIds],
    queryFn: async () => {
      const response = await FetchClient<
        {
          batch_ids: number[];
        },
        VacuumAnalysisDurationResponse
      >({
        payload: {
          batch_ids: batchIds,
        },
        url: "dig.c.lyophilizer_thing/Services/getVacuumPumpSummaryDurations",
        appKey,
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return VacuumAnalysisDurationResult.parse(response).durations;
    },
    enabled: !!filteredBatches.length,
    refetchInterval: 1000 * 30,
  });
};
