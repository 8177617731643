import { Card } from "@/components/Layout/Card";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { useTranslate } from "@/i18n/config";
import { Box } from "@mui/material";
import { useGetBatchDiagnosticData } from "../../api/Diagnostic/useGetBatchDiagnosticData";
import { ContextInformation } from "@/features/Motors/components/Layouts/ContextInformation";
import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { EVALUATION_COLORS } from "../../utils/evaluationColors";
import { parseHeatmapData } from "../../utils/parseHeatmapData";
import { BatchDiagnosticHeatmap } from "./BatchDiagnosticHeatmap";
import { CHART_COLORS } from "../../utils/chartColors";
import { parseMultiBatchXAxes } from "../../utils/parseMultiBatchXAxes";

const calculateYAxisMax = ({
  data,
  maxThreshold,
}: {
  data: number[][][];
  maxThreshold: number;
}): number | undefined => {
  let highestValue = 0;
  data.forEach((dataset) => {
    dataset.forEach((entry) => {
      if (entry[1] > highestValue) {
        highestValue = entry[1];
      }
    });
  });
  return maxThreshold > highestValue ? maxThreshold : undefined;
};

export const BatchDiagnosticChart = ({
  analyticId,
  assetIds,
}: {
  analyticId: number;
  assetIds: number[];
}) => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetBatchDiagnosticData({
    analyticId,
    assetIds,
  });

  if (isLoading)
    return <SkeletonCard width="calc(100% - 320px)" height={400} />;

  if (error)
    return (
      <Card sx={{ width: "calc(100% - 320px)", height: 400 }}>
        {translate("user_feedback.an_error_occurred")}
      </Card>
    );

  if (!data)
    return (
      <Card sx={{ width: "calc(100% - 320px)", height: 400 }}>
        {translate("user_feedback.no_data")}
      </Card>
    );

  if (data.chartData.length === 0)
    return (
      <Card sx={{ width: "calc(100% - 320px)", height: 400 }}>
        {translate("user_feedback.no_data")}
      </Card>
    );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "calc(100% - 320px)",
      }}
    >
      {data.chartData.map((chart, i) => (
        <Card key={JSON.stringify(chart.contextInfo) + i}>
          <ContextInformation props={chart.contextInfo} />
          {chart.chartType === "lines_with_limits" && (
            <LineChart.Custom
              title=""
              height={500}
              legend={{ enabled: true }}
              xAxes={
                chart.analyticName === "Overall Risk Index"
                  ? parseMultiBatchXAxes(chart.data)
                  : undefined
              }
              yAxis={
                chart.analyticName === "Overall Risk Index"
                  ? [
                      {
                        uom: "",
                        thresholds: [
                          {
                            text: "warning upper",
                            color: EVALUATION_COLORS.warning.selected,
                            value: chart.thresholds.warningUpper,
                          },
                          {
                            text: "warning lower",
                            color: EVALUATION_COLORS.warning.selected,
                            value: chart.thresholds.warningLower,
                          },
                          {
                            text: "critical upper",
                            color: EVALUATION_COLORS.critical.selected,
                            value: chart.thresholds.criticalUpper,
                          },
                          {
                            text: "critical lower",
                            color: EVALUATION_COLORS.critical.selected,
                            value: chart.thresholds.criticalLower,
                          },
                        ],
                        options: {
                          max: calculateYAxisMax({
                            data: chart.data.map((serie) => serie.data),
                            maxThreshold: chart.thresholds.criticalUpper,
                          }),
                        },
                        series: chart.data.map((serie, n) => ({
                          type: "line",
                          name: serie.name,
                          data: serie.data,
                          color: CHART_COLORS[n],
                          xAxis: n,
                        })),
                      },
                    ]
                  : [
                      {
                        uom: "",
                        thresholds: [
                          {
                            text: "warning",
                            color: EVALUATION_COLORS.warning.selected,
                            value: chart.thresholds.warning,
                          },
                          {
                            text: "critical",
                            color: EVALUATION_COLORS.critical.selected,
                            value: chart.thresholds.critical,
                          },
                        ],
                        options: {
                          max: calculateYAxisMax({
                            data: [chart.data],
                            maxThreshold: chart.thresholds.critical,
                          }),
                        },
                        series: [
                          {
                            type: "line",
                            name: chart.analyticName,
                            data: chart.data,
                          },
                        ],
                      },
                    ]
              }
            ></LineChart.Custom>
          )}
          {chart.chartType === "heatmap" && (
            <BatchDiagnosticHeatmap
              title={chart.analyticName}
              xCategories={parseHeatmapData(chart.data).xCategories}
              yCategories={parseHeatmapData(chart.data).yCategories}
              seriesData={parseHeatmapData(chart.data).seriesData}
            />
          )}
          {chart.chartType === "lines" && (
            <LineChart.Custom
              title=""
              height={400}
              legend={{ enabled: true }}
              xAxes={Array(chart.batchData.length + 1)
                .fill("")
                .map((_, i) => ({
                  id: "x-axis-" + i,
                  type: "datetime",
                  visible: false,
                }))}
              yAxis={[
                {
                  uom: "",
                  series:
                    chart.batchData.length > 1
                      ? [
                          ...chart.batchData,
                          { name: "Golden batch", data: chart.goldenData },
                        ].map((serie, n) => ({
                          type: "line",
                          name: serie.name,
                          data: serie.data,
                          color:
                            serie.name === "Golden batch"
                              ? "#FFBF00"
                              : CHART_COLORS[n],
                          xAxis: n,
                        }))
                      : [],
                },
              ]}
            ></LineChart.Custom>
          )}
        </Card>
      ))}
    </Box>
  );
};
