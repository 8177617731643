import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
  TextField,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import { z } from "zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslate } from "@/i18n/config";

interface IFormInput {
  email: string;
}

export const ShareModal = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const translate = useTranslate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();

  const handleClose = () => {
    close();
  };

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    console.log(`mutation per condivisione con questa mail ${data.email}`);
    //? toggle per selezione modalità di condivisione: "mail" | "intellecta"
    //? se l'utente ha intellecta e la condivisione va a buon fine, si chiude la modale e notifica di successo
    //? se l'utente non ha intellecta attivo appare il messaggio di errore e la modale resta aperta
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={handleClose}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogTitle> {translate("intellecta.share_message_1")}</DialogTitle>
        <DialogContent>
          <Stack gap={1}>
            <Typography variant="body2">
              {translate("intellecta.share_message_2")}
            </Typography>
            <Controller
              name="email"
              control={control}
              rules={{
                required: "Please enter an email address",
                validate: {
                  isEmail: (value) => {
                    return (
                      z.string().email().safeParse(value).success ||
                      "Badly formatted email"
                    );
                  },
                },
              }}
              render={({ field: { onChange } }) => (
                <TextField
                  label="email"
                  size="small"
                  placeholder="test@mail.com"
                  type="email"
                  onChange={onChange}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {translate("actions.cancel")}
          </Button>
          <Button variant="contained" type="submit">
            {translate("actions.confirm")}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
