import { useFirebaseContext } from "@/context/firebase-context";
import { useQuery } from "@tanstack/react-query";
import { Batch } from "../types";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { FetchClient } from "@/services/ApiClient";

export type FreezeDryingDurationResponse =
  | ThingworxError
  | FreezeDryingSummaryData;

const FreezeDryingSummaryData = z.object({
  response: z.literal(true),
  series: z.array(
    z.object({
      data: z.array(z.number()),
      name: z.string(),
    }),
  ),
  categories: z.array(z.string()),
});

type FreezeDryingSummaryData = z.infer<typeof FreezeDryingSummaryData>;

export const useGetFreezeSubphases = ({ batches }: { batches: Batch[] }) => {
  const { appKey } = useFirebaseContext();
  const batchId = batches.map((batch) => batch.batchId).sort();

  return useQuery<FreezeDryingSummaryData>({
    queryKey: ["freeze-drying-chart", batchId.toString()],
    queryFn: async () => {
      const response = await FetchClient<
        { batch_ids: number[] },
        FreezeDryingDurationResponse
      >({
        appKey,
        url: "dig.c.lyophilizer_thing/Services/getDryingDurations",
        payload: {
          batch_ids: batchId,
        },
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return FreezeDryingSummaryData.parse(response);
    },
    enabled: batchId.length > 0,
    refetchInterval: 1000 * 30,
  });
};
