import { colors } from "@/styles/colors";
import { styled } from "@mui/material";
import { motion } from "framer-motion";

export const Card = styled(motion.div)(() => ({
  backgroundColor: colors.palette.darkBlue,
  color: "#fff",
  width: "100%",
  backgroundImage: "none",
  padding: "1rem",
  boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: ".25rem",
}));
