import { INTELLECTA_ENDPOINT } from "@/config/index";
import { auth_token } from "./auth";

export const intellectaClient = async <Input, Output>({
  payload,
  url = "",
}: {
  payload: Input;
  url: string;
}): Promise<Output> => {
  const response = await fetch(INTELLECTA_ENDPOINT + url, {
    method: "POST",
    headers: {
      Authorization: `Basic ${auth_token}`,
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) throw new Error("Something went wrong!");

  return response.json() as Promise<Output>;
};
