import { SERVER_URL } from "@/config/index";
import { useFirebaseContext } from "@/context/firebase-context";
import { useQuery } from "@tanstack/react-query";

const SERVICE_PATH = `${SERVER_URL}dig.c.lyophilizer_thing/Services/goldenSummary`;

export const useGetGoldenInfo = () => {
  const { appKey } = useFirebaseContext();
  // todo sbloccare in caso reale
  const machineName = "Demo";

  return useQuery<{
    response: true;
    goldenSummary: GoldenInfo[];
  }>({
    queryKey: ["goldenInfo", machineName],
    queryFn: () =>
      fetch(SERVICE_PATH, {
        method: "POST",
        headers: {
          appkey: appKey,
          "content-type": "application/json",
          accept: "application/json",
          "x-thingworx-session": "true",
        },
        body: JSON.stringify({
          machineName: machineName,
        }),
      }).then((res) => res.json()),
  });
};

export type GoldenInfo = {
  subphase_name: string;
  recipe: string;
  timestampstart: number;
  phase_name: string;
  timestampend: number;
};
