import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { useLanguage } from "@/i18n/config";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { QueryBuilder } from "@/utils/query-builder";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type AccessRequestDetailResponse = ThingworxError | AccessRequestDetailResult;

const AccessRequestDetail = z.object({
  CarterDescription: z.string(),
  //Zone: z.number(),
  Timestamp: z.number(),
  ZoneDescription: z.string(),
});

export type AccessRequestDetail = z.infer<typeof AccessRequestDetail>;

const AccessRequestDetailResult = z.object({
  response: z.literal(true),
  categories: z.array(z.string()),
  Result: z.array(AccessRequestDetail),
});

export type AccessRequestDetailResult = z.infer<typeof AccessRequestDetailResult>;

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
  dateStart?: DateTime;
  dateEnd?: DateTime;
  language_id: string;
};

export const useGetAccessRequestDetail = () => {
  const { machine } = useMachineContext();
  const { timeSelection, dates } = useTimeSelection();
  const { appKey } = useFirebaseContext();
  const language = useLanguage();

  const query = QueryBuilder.buildWithCondition({
    baseQuery: ["safetyGuards-detail", machine?.machine, timeSelection, language],
    condition: timeSelection === "Custom",
    query: [dates?.dateStart, dates?.dateEnd],
  });

  const payload: Payload = {
    machineName: machine!.machine,
    timeSelection,
    language_id: language,
  };

  return useQuery<AccessRequestDetailResult>({
    queryKey: query,
    queryFn: async () => {
      const response = await FetchClient<Payload, AccessRequestDetailResponse>({
        appKey,
        url: "dig.c.safetyGuard_thing/Services/getDetail",
        payload:
          timeSelection === "Custom"
            ? {
                ...payload,
                dateStart: dates?.dateStart,
                dateEnd: dates?.dateEnd,
              }
            : payload,
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return AccessRequestDetailResult.parse(response);
    },
  });
};
