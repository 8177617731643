import { useTranslate } from "@/i18n/config";
import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { Card } from "@/components/Layout/Card";
import { ContextInformation } from "@/features/Motors/components/Layouts/ContextInformation";
import { useGetConditionMonitoringData } from "../../api/Monitoring/useGetConditionMonitoringData";
import { parseUom } from "../../utils/parseUom";
import { CHART_COLORS, GOLDEN_COLOR } from "../../utils/chartColors";
import { parseUomAxisLabel } from "../../utils/parseUomAxisLabel";
import { XAxisOptions } from "highcharts";

const parseXAxes = ({
  rawData,
  goldenData,
}: {
  rawData: number[][];
  goldenData: number[][];
}): XAxisOptions[] => {
  const xAxes: XAxisOptions[] = [];

  if (rawData.length === 0 || goldenData.length === 0) {
    xAxes.push(
      {
        type: "datetime",
      },
      {
        type: "datetime",
        visible: false,
      },
    );

    return xAxes;
  }

  const firstTsRaw = rawData[0][0];
  const firstTsGolden = goldenData[0][0];
  const maxDuration = Math.max(
    rawData[rawData.length - 1][0] - rawData[0][0],
    goldenData[goldenData.length - 1][0] - goldenData[0][0],
  );

  xAxes.push(
    {
      type: "datetime",
      max: firstTsRaw + maxDuration,
    },
    {
      type: "datetime",
      visible: false,
      max: firstTsGolden + maxDuration,
    },
  );
  return xAxes;
};

export const TagLineChart = ({ tagId }: { tagId: number }) => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetConditionMonitoringData({
    subphasesAssetsAnalysisId: tagId,
  });

  if (isLoading) return <SkeletonCard height={400} />;

  if (error)
    return (
      <Card sx={{ height: 400 }}>
        {translate("user_feedback.an_error_occurred")}
      </Card>
    );

  if (!data)
    return (
      <Card sx={{ height: 400 }}>{translate("user_feedback.no_data")}</Card>
    );

  return (
    <Card>
      <ContextInformation props={data.contextInfo} />
      <LineChart.Custom
        key={`tag-line-chart-${tagId}`}
        title=""
        tooltip={{
          enabled: true,
          options: { valueSuffix: parseUom(data.uom) },
        }}
        height={400}
        legend={{ enabled: true }}
        xAxes={parseXAxes({
          rawData: data.rawData,
          goldenData: data.goldenData,
        })}
        yAxis={[
          {
            yAxisTitleOptions: {
              text: parseUomAxisLabel(data.uom),
              style: { color: "white" },
            },
            uom: "",
            series: [
              {
                type: "line",
                name: "raw data",
                color: CHART_COLORS[4],
                data: data.rawData,
                xAxis: 0,
                zIndex: 1,
              },
              {
                type: "line",
                name: "golden data",
                color: GOLDEN_COLOR,
                data: data.goldenData,
                xAxis: 1,
              },
            ],
          },
        ]}
      ></LineChart.Custom>
    </Card>
  );
};
