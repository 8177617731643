import Highcharts from "highcharts/highstock";
import { XAxisOptions } from "highcharts";

import HighchartsReact from "highcharts-react-official";
import xrange from "highcharts/modules/xrange";
import {
  PhaseData,
  Status,
} from "@/features/Diagnostic/pages/LYO/api/useGetHistoricData";
import { colors } from "@/styles/colors";

xrange(Highcharts);

export function getColor(status?: Status): string {
  switch (status) {
    case "Bad":
      return colors.lyo.status.bad;
    case "Good":
      return colors.lyo.status.good;
    case "Not Analyzable":
      return colors.lyo.status.not_analyzable;
    case "Not Analyzed":
      return colors.lyo.status.not_analyzed;
    default:
      return colors.lyo.status.not_analyzed;
  }
}

export const Gant = ({
  data,
  categories,
  title = "Phases",
  plotBands,
  plotLines,
}: {
  data: PhaseData[];
  categories: string[];
  title?: string;
  plotBands?: XAxisOptions["plotBands"];
  plotLines?: XAxisOptions["plotLines"];
}) => {
  const mappedData = data.map((value) => {
    return {
      x2: value.to,
      x: value.from,
      y: categories.findIndex((category) => category === value.name),
      color: getColor(value.status),
    };
  });

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            type: "xrange",
            backgroundColor: "transparent",
            zoomType: "x",
            style: {
              color: "white",
            },
          },
          exporting: {
            enabled: true,
            buttons: {
              contextButton: {
                menuItems: ["downloadCSV", "downloadXLS"],
              },
            },
          },
          tooltip: {
            followPointer: true,
          },
          accessibility: {
            enabled: false,
          },
          title: {
            text: title,
            align: "left",
            style: {
              color: "white",
            },
          },
          xAxis: {
            type: "datetime",
            style: {
              color: "white",
            },
            plotBands,
            plotLines,
          },
          yAxis: {
            categories: categories,
            style: {
              color: "white",
            },
            labels: {
              style: {
                color: "white",
              },
            },
          },
          series: [
            {
              borderColor: "none",
              data: mappedData,
              showInLegend: false,
            },
          ],
          credits: {
            enabled: false,
          },
          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 500,
                },
                chartOptions: {
                  legend: {
                    align: "center",
                    verticalAlign: "bottom",
                    layout: "horizontal",
                  },
                  yAxis: {
                    labels: {
                      align: "left",
                      x: 0,
                      y: -5,
                    },
                    title: {
                      text: null,
                    },
                  },
                  subtitle: {
                    text: null,
                  },
                },
              },
            ],
          },
        }}
      />
    </>
  );
};
