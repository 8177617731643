import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { useLanguage } from "@/i18n/config";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

const AISuggest = z.object({
  nEventsBm: z.number(),
  mttr: z.number(),
  mttrBm: z.number(),
  category: z.string(),
  error: z.string(),
  nEvents: z.number(),
  efficiencyLost: z.number(),
});
export type AISuggest = z.infer<typeof AISuggest>;

const FaultAnalysis = z.object({
  duration: z.number(),
  mttr: z.number(),
  lossName: z.string(),
  count: z.number(),
  mtbf: z.number(),
  value: z.number(),
  colorType: z.string(),
});
export type FaultAnalysis = z.infer<typeof FaultAnalysis>;

const Loss = z.object({
  valueBm: z.number(),
  nEventsBm: z.number(),
  mttr: z.number(),
  lossName: z.string(),
  mttrBm: z.number(),
  value: z.number(),
  nEvents: z.number(),
});
export type Loss = z.infer<typeof Loss>;

const OeeMachineStatus = z.object({
  process: z.number(),
  extraCapacity: z.number(),
  line: z.number(),
  machine: z.number(),
  noData: z.number(),
  oee: z.number(),
});
export type OeeMachineStatus = z.infer<typeof OeeMachineStatus>;

const Other = z.object({
  recipeName: z.string(),
  counter: z.number(),
  elapsedTimeSs: z.number(),
  recipeId: z.string(),
});
export type Other = z.infer<typeof Other>;

export const EfficiencyNavigtorResult = z.object({
  oeeMachineStatus: OeeMachineStatus,
  other: Other,
  lineLosses: z.array(Loss),
  machineLosses: z.array(Loss),
  processLosses: z.array(Loss),
  response: z.literal(true),
  faultAnalysis: z.array(FaultAnalysis),
  aiSuggest: z.array(AISuggest),
});

export type EfficiencyNavigtorResult = z.infer<typeof EfficiencyNavigtorResult>;

export type EfficiencyNavigtorResponse = ThingworxError | EfficiencyNavigtorResult;

export const useGetEfficiencyNavigatorData = () => {
  const { machine } = useMachineContext();
  const { timeSelection } = useTimeSelection();
  const { appKey } = useFirebaseContext();
  const language = useLanguage();

  return useQuery<EfficiencyNavigtorResult>({
    queryKey: ["efficiency-navigator", machine!.machine, timeSelection, language],
    queryFn: async () => {
      const response = await FetchClient<
        { machineName: string; timeSelection: TimeSelection; language_id: string },
        EfficiencyNavigtorResponse
      >({
        appKey,
        payload: {
          machineName: machine!.machine,
          timeSelection,
          language_id: language,
        },
        url: "dig.c.benchmark_thing/Services/getData",
      });
      if (!response.response) {
        throw new Error(response.errorString);
      }
      const validatedResponse = EfficiencyNavigtorResult.parse(response);
      return validatedResponse;
    },
    refetchInterval: 1000 * 30,
  });
};
