import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";

const contentMixin = (theme: Theme): CSSObject => ({
  width: `calc(100vw - calc(${theme.spacing(7)} + 1px))`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(100vw - calc(${theme.spacing(8)} + 1px))`,
  },
});

export const AppShell = styled(Box)(({ theme }) => ({
  ...contentMixin(theme),
}));
