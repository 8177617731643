import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Switch } from "@/components/Switch";
import { useEditStore } from "../store/edit-management-store";

type PropertyProps = {
  propertyName: string;
  value: string;
};

type SwithProps = {
  propertyName: string;
  isRelevant: boolean;
  isEdited: (value: boolean) => void;
};

export const ItemLayout = ({ propertyName, value }: PropertyProps) => {
  const typeOfColor = () => {
    if (value.charAt(0) === "+") {
      return "#2dd12d";
    }
    if (value.charAt(0) === "-") {
      return "red";
    }
    return "white";
  };

  return (
    <Grid container padding={0.2}>
      <Grid item xs={6}>
        <Typography align="left" variant="body2" component="div">
          {propertyName}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography
          align="right"
          variant="body2"
          component="div"
          color={typeOfColor}
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const SwitchItem = ({
  propertyName,
  isRelevant,
  isEdited,
}: SwithProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    isEdited(event.target.checked);
  };

  const { isEditable } = useEditStore((state) => state);

  return (
    <Grid container alignItems={"center"} padding={"0 .5rem"} justifyContent="space-between">
      <Grid item xs={9}>
        <Typography align="left" variant="body2" component="div">
          {propertyName}
        </Typography>
      </Grid>
      <Grid item xs={3} paddingX={2}>
        <Switch
          disabled={!isEditable}
          isChecked={isRelevant}
          label={""}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
};
