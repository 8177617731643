import { XAxisOptions } from "highcharts";

export const parseMultiBatchXAxes = (
  data: {
    name: string;
    data: number[][];
    id: number;
  }[],
): XAxisOptions[] => {
  const xAxes: XAxisOptions[] = [];
  const durations: number[] = [];

  data.forEach((batch) => {
    durations.push(batch.data[batch.data.length - 1][0] - batch.data[0][0]);
  });
  const maxDuration = Math.max(...durations);

  data.forEach((batch) => {
    const firstTs = batch.data[0][0];
    xAxes.push({
      type: "datetime",
      visible: false,
      max: firstTs + maxDuration,
    });
  });
  return xAxes;
};
