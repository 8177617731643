/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  HighchartsChart,
  Title,
  XAxis,
  YAxis,
  Chart,
  Tooltip,
  Legend,
  BulletSeries,
  Annotation,
} from "react-jsx-highcharts";
import { HighchartsCustomProvider } from "../highcharts";

export class BulletChart {
  static Custom({
    title,
    yAxis,
    tooltip = { enabled: true },
    annotation = { enabled: true },
    legend = { enabled: false },
    xAxisOptions,
  }: {
    title: string;
    yAxis: {
      options?: Highcharts.YAxisOptions;
      series?: Highcharts.SeriesBulletOptions[];
      yAxisTitleOptions?: Highcharts.YAxisTitleOptions;
    };
    legend?:
      | { enabled: false }
      | { enabled: true; options?: Partial<Highcharts.LegendOptions> };
    tooltip?:
      | { enabled: false }
      | { enabled: true; options?: Partial<Highcharts.TooltipOptions> };
    annotation?:
      | { enabled: false }
      | { enabled: true; options?: Partial<Highcharts.AnnotationsOptions> };
    xAxisOptions?: Highcharts.XAxisOptions;
    exportingEnabled?: boolean;
  }) {
    return (
      <HighchartsCustomProvider>
        <HighchartsChart
          exporting={{
            enabled: false,
          }}
          accessibility={{ enabled: false }}
          plotOptions={{
            series: {
              borderWidth: 0,
              color: "#000",
            },
            bullet: {
              // @ts-ignore
              borderRadiusTopRight: 5,
              // @ts-ignore
              borderRadiusTopLeft: 5,
            },
          }}
        >
          <Chart
            backgroundColor={"transparent"}
            type="bullet"
            inverted
            zooming={{ type: "xy" }}
            style={{
              color: "white",
            }}
          />
          <Title style={{ color: "white" }} align="left">
            {title}
          </Title>

          {tooltip.enabled && <Tooltip shared useHTML {...tooltip.options} />}

          {annotation.enabled && <Annotation {...annotation.options} />}

          {legend.enabled && (
            <Legend
              itemStyle={{
                color: "white",
              }}
              {...legend.options}
            ></Legend>
          )}
          <XAxis
            labels={{ style: { color: "white" }, enabled: false }}
            {...xAxisOptions}
          ></XAxis>

          <YAxis
            labels={{ style: { color: "white" }, format: "{value} " }}
            showEmpty
            gridLineWidth={0}
            title={yAxis.yAxisTitleOptions}
            {...yAxis.options}
            alignTicks={false}
          >
            {yAxis.series?.map((serie, i) => (
              <BulletSeries key={i} {...serie} borderColor="none" />
            ))}
          </YAxis>
        </HighchartsChart>
      </HighchartsCustomProvider>
    );
  }
}
