import { useContext } from "react";
import { IsolatorContext } from "./isolator-context";

export const useIsolatorContext = () => {
  const context = useContext(IsolatorContext);

  if (!context) {
    throw new Error(
      "You can only use this context inside IsolatorContextProvider",
    );
  }
  return context;
};
