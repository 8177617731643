import { useState } from "react";
import { Stack, Box, Tab, Tabs, Typography } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { Notification } from "../../types";
import { Social } from "./Social";
import { Historic } from "./Historic";
import { useCheckIfMessageBanner } from "@/hooks/useCheckIfInformationBanner";
import { userConfigurationStore } from "../../store/user-configuration-store";

const notifications: Notification[] = [
  {
    id: 1,
    query: "domanda che ho fatto ad intellecta",
    answer: "risposta che ho ottenuto",
    is_read: true,
    is_pinned: false,
    sending_user: "utente@mail.com",
    timestamp: 1721651908.19378,
  },
  {
    id: 2,
    query: "seconda domanda che ho fatto ad intellecta",
    answer: "seconda risposta che ho ottenuto",
    is_read: true,
    is_pinned: true,
    sending_user: "altro.utente@mail.com",
    timestamp: 1721739569.86008,
  },
  {
    id: 3,
    query: "terza domanda che ho fatto ad intellecta",
    answer: "terza risposta che ho ottenuto",
    is_read: false,
    is_pinned: false,
    sending_user: "ennesimo.utente@mail.com",
    timestamp: 1721741533.69552,
  },
];

const PANELS = {
  SOCIAL: 0,
  HISTORIC: 1,
};

export const RightDrawer = ({ onUsePrompt }: { onUsePrompt: (prompt: string) => void }) => {
  const { showBanner } = useCheckIfMessageBanner();
  const [panel, setPanel] = useState(PANELS.HISTORIC);
  const translate = useTranslate();
  const { services } = userConfigurationStore();

  return (
    <Stack overflow="hidden" pt={showBanner ? "32px" : 0}>
      <Tabs
        value={panel}
        aria-label="intellecta social and historic tabs"
        onChange={(_: React.SyntheticEvent, newValue: number) => {
          setPanel(newValue);
        }}
        sx={{ pl: 2 }}
      >
        <Tab value={PANELS.HISTORIC} label={translate("intellecta.historic")} />
        <Tab value={PANELS.SOCIAL} label="Social" />
      </Tabs>
      <Box display="flex" flexDirection="column" overflow="auto">
        {panel === PANELS.SOCIAL && services.includes("social") && (
          <Social notifications={notifications} onUsePrompt={onUsePrompt} />
        )}
        {panel === PANELS.SOCIAL && !services.includes("social") && (
          <Stack p={2} gap={1}>
            <Typography variant="h6">{translate("user_feedback.service_not_enabled")}</Typography>
          </Stack>
        )}
        {panel === PANELS.HISTORIC && <Historic />}
      </Box>
    </Stack>
  );
};
