import { ListItem, Typography } from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";
import { useIsolatorDispatchContext } from "../../../context/useIsolatorDispatchContext";
import { Batch } from "../../../types";

export const SelectedBatch = ({ batch }: { batch: Batch }) => {
  const dispatch = useIsolatorDispatchContext();

  return (
    <ListItem
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Typography fontSize=".9rem">{batch.name}</Typography>
      <DeleteOutline
        sx={{
          cursor: "pointer",
          transition: "transform .5s, color .5s",
          ":hover": {
            color: "cyan",
            transform: "scale(1.2)",
          },
        }}
        onClick={() => dispatch({ type: "REMOVE_BATCH", item: batch })}
      />
    </ListItem>
  );
};
