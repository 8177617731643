import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { chatStore } from "../store/chat-store";
import { IntellectaError, IntellectaVersion } from "../types";
import { intellectaClient } from "./intellectaClient";
import { userConfigurationStore } from "../store/user-configuration-store";

type Response = IntellectaError | Result;

type Payload = {
  user: string;
  query: string;
  version: IntellectaVersion;
  machine?: string;
  chat_id: number | null;
  profile: string;
};

export type Chat = {
  user: string;
  intellecta: string;
};

const result = z.object({
  value: z.string(),
  image: z.optional(z.string()),
  chat_id: z.number(),
  message_id: z.number(),
});

type Result = z.infer<typeof result>;

export const useAskQuestion = () => {
  const queryClient = useQueryClient();
  const { chatId } = chatStore();
  const { version, machine, profile } = userConfigurationStore();
  const { user } = useFirebaseContext();
  const email = user?.email || "";

  return useMutation({
    mutationFn: async ({ query }: { query: string }) => {
      if (!version || !profile) {
        throw new Error("An error occurred");
      }

      const response = await intellectaClient<Payload, Response>({
        url: "/ask",
        payload: {
          user: email,
          query,
          version,
          machine: machine ? machine : undefined,
          chat_id: chatId,
          profile,
        },
      });

      if (response === "ERROR") {
        throw new Error("An error occurred");
      }

      const validatedResponse = result.parse(response);
      return validatedResponse;
    },
    onSuccess() {
      if (chatId === null) {
        //* Inizio nuova chat
        //* Scarico di nuovo lo storico delle chat
        queryClient.invalidateQueries({
          queryKey: ["intellecta-historic", email],
        });
      }
    },
    onError: () => {
      toast.error("An error occurred");
    },
  });
};
