import { PointOptionsObject } from "highcharts/highstock";
import { HeatmapData } from "../types";
import { EVALUATION_COLORS } from "../context/acopos-context";

export const parseHeatmapData = ({
  data,
}: {
  data: HeatmapData;
}): {
  xCategories: string[];
  yCategories: string[];
  seriesData: PointOptionsObject[];
} => {
  if (Object.keys(data).length === 0)
    return { xCategories: [], yCategories: [], seriesData: [] };

  const sortedData: HeatmapData = {};
  // const sortedKeysX = Object.keys(data).sort((a, b) => a.localeCompare(b));
  //? stiamo presupponendo di avere il formato 'nome numero'
  const sortedKeysX = Object.keys(data).sort((a, b) => {
    const aSplit = a.split(" ");
    const aNum = parseInt(aSplit[aSplit.length - 1]);
    const bSplit = b.split(" ");
    const bNum = parseInt(bSplit[bSplit.length - 1]);
    return aNum > bNum ? 1 : -1;
  });

  sortedKeysX.forEach((xKey) => {
    sortedData[xKey] = {};
    // const sortedKeysY = Object.keys(data[xKey]).sort((a, b) =>
    //   a.localeCompare(b)
    // );
    //? stiamo presupponendo di avere il formato 'nome numero'
    const sortedKeysY = Object.keys(data[xKey]).sort((a, b) => {
      const aSplit = a.split(" ");
      const aNum = parseInt(aSplit[aSplit.length - 1]);
      const bSplit = b.split(" ");
      const bNum = parseInt(bSplit[bSplit.length - 1]);
      return aNum > bNum ? 1 : -1;
    });
    sortedKeysY.forEach((yKey) => {
      sortedData[xKey][yKey] = data[xKey][yKey];
    });
  });

  const xCategories: string[] = Object.keys(sortedData);
  const yCategories: string[] = Object.keys(sortedData[xCategories[0]]);
  const seriesData: PointOptionsObject[] = [];

  for (let y = 0; y < yCategories.length; y++) {
    for (let x = 0; x < xCategories.length; x++) {
      const evaluation = sortedData[xCategories[x]][yCategories[y]];

      seriesData.push({
        x,
        y,
        color: EVALUATION_COLORS[evaluation].selected,
        custom: { evaluation },
        borderColor: "rgba(0,0,0,0.2)",
        borderWidth: 1,
      });
    }
  }

  return { xCategories, yCategories, seriesData };
};
