import { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useGetUserConfiguration } from "../../api/useGetUserConfiguration";
import { useTranslate } from "@/i18n/config";
import { userConfigurationStore } from "../../store/user-configuration-store";
import { Machine } from "../../types";

export const TroubleShootingMachineSelector = ({
  machines,
}: {
  machines: Machine[];
}) => {
  const translate = useTranslate();
  const { data, isLoading } = useGetUserConfiguration();
  const { setMachine, lastMachineId } = userConfigurationStore();
  const [selectedMachine, setSelectedMachine] = useState("");

  useEffect(() => {
    return () => setMachine(null);
  }, [setMachine]);

  useEffect(() => {
    if (lastMachineId) {
      setMachine(lastMachineId);
      setSelectedMachine(lastMachineId);
    }
  }, [lastMachineId, setMachine]);

  if (isLoading || !data) {
    return <div>{translate("user_feedback.loading")}</div>;
  }

  if (!machines.length) {
    return (
      <Box>
        <Typography variant="body2">
          {translate("intellecta.no_machines_access")}
        </Typography>
      </Box>
    );
  }

  const handleChange = (event: SelectChangeEvent) => {
    const val = event.target.value;
    setSelectedMachine(val);
    setMachine(val);
  };

  return (
    <FormControl>
      <InputLabel id="intellecta-troubleshooting-machine-select-label">
        {translate("machine")}
      </InputLabel>
      <Select
        labelId="intellecta-troubleshooting-machine-select-label"
        id="intellecta-troubleshooting-machine-select"
        value={selectedMachine}
        label={translate("machine")}
        onChange={handleChange}
      >
        <MenuItem key="None" value="">
          {translate("motors.no_selection")}
        </MenuItem>
        {machines.map(({ machine }) => (
          <MenuItem key={machine} value={machine}>
            {machine}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
