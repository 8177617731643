/* eslint-disable react-hooks/rules-of-hooks */
import { HighchartsCustomProvider } from "../highcharts";
import {
  Chart,
  ColumnSeries,
  HighchartsChart,
  HighchartsChartProps,
  Legend,
  Title,
  Tooltip,
  XAxis,
  YAxis,
} from "react-jsx-highcharts";

interface ColumnchartProps {
  title: string;
  uom: string;
  series?: Highcharts.SeriesColumnOptions[];
  yAxisTitleOptions?: Highcharts.YAxisTitleOptions;
  highchartsChartOptions?: HighchartsChartProps;
  chartOptions?: Highcharts.ChartOptions;
  plotOptions?: Highcharts.PlotOptions;
  legend?:
    | { enabled: false }
    | { enabled: true; options?: Partial<Highcharts.LegendOptions> };
  tooltip?:
    | { enabled: false }
    | { enabled: true; options?: Partial<Highcharts.TooltipOptions> };
  zooming?: Highcharts.ChartZoomingOptions;
  scrollbarEnabled?: boolean;
  xAxisMax?: number;
  xAxisOptions?: Highcharts.XAxisOptions;
  yAxisOptions?: Highcharts.YAxisOptions;
  height?: number;
  filename?: string;
}

/**
 * Columnchart component, groups all the different kind of columncharts
 */
export class ColumnChart {
  static Custom({
    title,
    series = [],
    uom,
    yAxisTitleOptions,
    highchartsChartOptions,
    chartOptions,
    tooltip = { enabled: false },
    legend = { enabled: false },
    zooming = { type: "x" },
    scrollbarEnabled = false,
    plotOptions = {},
    xAxisMax,
    xAxisOptions,
    yAxisOptions = {},
    height,
    filename,
  }: ColumnchartProps) {
    return (
      <>
        <HighchartsCustomProvider>
          <HighchartsChart
            exporting={{
              filename,
            }}
            accessibility={{ enabled: false }}
            plotOptions={plotOptions}
            chart={{
              height: height,
            }}
            {...highchartsChartOptions}
          >
            <Chart
              backgroundColor={"transparent"}
              type="column"
              zooming={zooming}
              style={{
                color: "white",
              }}
              alignTicks={false}
              height={height}
              {...chartOptions}
            />
            <Title style={{ color: "white" }} align="left">
              {title}
            </Title>

            {tooltip.enabled && <Tooltip shared useHTML {...tooltip.options} />}

            {legend.enabled && (
              <Legend
                itemStyle={{
                  color: "white",
                }}
                {...legend.options}
              ></Legend>
            )}

            <XAxis
              labels={{
                style: {
                  color: "white",
                  /* test - aggiunto questa chiave perche in alcuni chart la label troppo lunga prende troppo spazio */
                  whiteSpace: "nowrap",
                },
              }}
              type="category"
              scrollbar={{
                enabled: scrollbarEnabled,
                liveRedraw: true,
                barBackgroundColor: "#0D1626",
                barBorderRadius: 7,
                barBorderWidth: 0,
                buttonBackgroundColor: "#0D1626",
                buttonBorderWidth: 1,
                buttonBorderRadius: 7,
                buttonArrowColor: "#0D1626",
                buttonBorderColor: "#3f4961",
                rifleColor: "#0D1626",
                trackBackgroundColor: "#3f4961",
                trackBorderWidth: 1,
                trackBorderRadius: 8,
                trackBorderColor: "none",
              }}
              max={xAxisMax}
              {...xAxisOptions}
            ></XAxis>
            <YAxis
              labels={{ style: { color: "white" }, format: "{value} " + uom }}
              showEmpty
              title={yAxisTitleOptions}
              type="linear"
              {...yAxisOptions}
            >
              {series.map((serie, i) => (
                <ColumnSeries key={i} borderColor="none" {...serie} />
              ))}
            </YAxis>
          </HighchartsChart>
        </HighchartsCustomProvider>
      </>
    );
  }
}
