import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { Variable, ChartType, variableSchema } from "../types/variable";

type Payload = {
  machineName: string;
  chart_type: ChartType;
};

const Result = z.object({
  response: z.literal(true),
  list: z.array(variableSchema),
});

type Result = z.infer<typeof Result>;

type Response = Result | ThingworxError;

export const useGetVariables = ({
  machine,
  chart_type,
}: {
  machine: string;
  chart_type: ChartType;
}) => {
  const { appKey } = useFirebaseContext();
  return useQuery<Variable[]>({
    queryKey: ["variables", machine, chart_type],
    queryFn: async () => {
      const response = await FetchClient<Payload, Response>({
        appKey,
        url: "dig.c.customChartsDev_thing/services/getVariables",
        payload: {
          machineName: machine,
          chart_type,
        },
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return Result.parse(response).list;
    },
  });
};
