import { useFirebaseContext } from "@/context/firebase-context";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { FetchClient } from "@/services/ApiClient";
import { RuleEngineJson } from "../types";

type Response = ThingworxError | { response: true };

type Payload = {
  platformLvl: number;
  json: RuleEngineJson;
};

export const useCreateRule = () => {
  const { appKey, user, platformLvl } = useFirebaseContext();
  const account = user?.email as string;
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (json: Omit<RuleEngineJson, "account">) => {
      const payload: Payload = {
        json: { ...json, account },
        platformLvl,
      };
      return await FetchClient<Payload, Response>({
        appKey,
        payload,
        url: "dig.c.event_thing/Services/createNewAlertRuleEngine",
      });
    },
    onSuccess(data) {
      if (data.response) {
        client.invalidateQueries({
          queryKey: ["list-of-rules", account, platformLvl],
        });
      }
    },
  });
};
