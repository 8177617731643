import { useState } from "react";
import { toast } from "react-hot-toast";
import {
  Stack,
  Typography,
  Menu,
  MenuItem,
  ListSubheader,
  Box,
  CircularProgress,
} from "@mui/material";
import { CheckRounded } from "@mui/icons-material";
import { useTranslate } from "@/i18n/config";
import { userConfigurationStore } from "../../store/user-configuration-store";
import { IntellectaVersion } from "../../types";
import { chatStore } from "../../store/chat-store";
import { useSetActiveProfile } from "../../api/useSetActiveProfile";
import { useGetUserConfiguration } from "../../api/useGetUserConfiguration";

export const VersionProfileMenu = () => {
  const translate = useTranslate();
  const { version, profile, role, availableVersionsAndProfiles } =
    userConfigurationStore();
  const { clearChat } = chatStore();
  const { mutate: setActiveProfile, isLoading: isSettingActiveProfile } =
    useSetActiveProfile();
  const { isFetching: isGettingUserConfiguration } = useGetUserConfiguration();

  // Stato per il controllo del menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // Handler per aprire e chiudere il menu
  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectVersion = ({
    newVersion,
  }: {
    newVersion: IntellectaVersion;
  }) => {
    if (newVersion === version) return handleClose();
    if (!profile)
      return toast.error(translate("user_feedback.an_error_occurred"));
    setActiveProfile(
      { version: newVersion, profile },
      {
        onSuccess: () => {
          clearChat();
          handleClose();
        },
      },
    );
  };

  const handleSelectProfile = ({
    newVersion,
    newProfile,
  }: {
    newVersion: IntellectaVersion;
    newProfile: string;
  }) => {
    if (newVersion === version && newProfile === profile) return handleClose();
    setActiveProfile(
      { version: newVersion, profile: newProfile },
      {
        onSuccess: () => {
          clearChat();
          handleClose();
        },
      },
    );
  };

  if (!version) return null;

  return (
    <>
      {/* Contenitore cliccabile */}
      <Stack
        sx={{
          border: "1px solid #fff",
          padding: 1,
          width: "100%",
          borderRadius: 1,
          overflow: "hidden",
          whiteSpace: "nowrap",
          cursor: "pointer",
        }}
        onClick={handleOpen}
      >
        {isSettingActiveProfile || isGettingUserConfiguration ? (
          <CircularProgress size={24} sx={{ alignSelf: "center" }} />
        ) : (
          <Typography
            variant="body1"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {translate(`intellecta.version_${version as IntellectaVersion}`)}
          </Typography>
        )}
      </Stack>

      {/* Menu */}
      {!isSettingActiveProfile && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: {
              sx: { maxHeight: 300, width: "250px" },
            },
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {role === "base" &&
            availableVersionsAndProfiles.map((v) => (
              <MenuItem
                key={v.name}
                onClick={() => handleSelectVersion({ newVersion: v.name })}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {translate(`intellecta.version_${v.name as IntellectaVersion}`)}
                {v.name === version && <CheckRounded />}
              </MenuItem>
            ))}
          {role === "premium" &&
            availableVersionsAndProfiles.map((v) => (
              <Box key={v.name}>
                <ListSubheader sx={{ backgroundColor: "transparent" }}>
                  {translate(
                    `intellecta.version_${v.name as IntellectaVersion}`,
                  )}
                </ListSubheader>
                {v.profiles.map((p) => (
                  <MenuItem
                    key={p.name}
                    onClick={() =>
                      handleSelectProfile({
                        newVersion: v.name,
                        newProfile: p.name,
                      })
                    }
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {p.name}
                    {p.name === profile && <CheckRounded />}
                  </MenuItem>
                ))}
              </Box>
            ))}
        </Menu>
      )}
    </>
  );
};
