import { useFirebaseContext } from "@/context/firebase-context";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { ThingworxError } from "src/types/error";
import { FetchClient } from "@/services/ApiClient";

type Response = ThingworxError | { response: true };

type Payload = {
  platformLvl: number;
  id: number;
};

export const useDeleteAlert = () => {
  const { appKey, user, platformLvl } = useFirebaseContext();
  const account = user?.email as string;
  const client = useQueryClient();

  return useMutation({
    mutationFn: async ({ id }: { id: number }) => {
      const payload: Payload = { platformLvl, id };
      return await FetchClient<Payload, Response>({
        appKey,
        payload,
        url: "dig.c.event_thing/Services/deleteAlertStatus",
      });
    },
    onSuccess(data) {
      if (data.response) {
        toast.success("Alert deleted successfully");
        client.invalidateQueries({
          queryKey: ["list-of-alerts", account, platformLvl],
        });
      }
    },
  });
};
