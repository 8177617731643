import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { ItemLayout, SwitchItem } from "./StateItems";
import { Card } from "@/components/Layout/Card";
import { Grid, styled } from "@mui/material";
import { Duration } from "luxon";
import { useOeeConfigurationDispatch } from "../context/ConfigurationContext";
import { useEditStore } from "../store/edit-management-store";
import { StateInfo } from "../api/useGetConfigurableOeeData";
import { useTranslate } from "@/i18n/config";
import { Switch } from "@/components/Switch";

export const CustomCard = styled(
  Card,
  {},
)<{ isEdited: boolean }>(({ isEdited }) => ({
  width: "auto",
  minHeight: "29px",
  margin: "1rem",
  backgroundColor: isEdited ? "#205E8B" : "#252d3c",
  color: "#ffffff",
}));

export const StateInfoLayout = (state: StateInfo) => {
  const { isEditable } = useEditStore((state) => state);
  const translate = useTranslate();

  const duration = formatDuration(state.duration);
  const oeeGain = formatPercent(state.oeeGain);
  const availability = formatPercent(state.availabilityGain);
  const performance = formatPercent(state.performanceGain);

  return (
    <CustomCard
      isEdited={!(state.isClientRelevance === state.isEfficiencyRelevance)}
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Typography
        align="center"
        style={{ fontSize: "14px", padding: ".4rem", fontWeight: "bold" }}
        component="div"
      >
        {state.stateName}
      </Typography>
      <CardContent sx={{ padding: "0 .5rem", minWidth: "250px" }}>
        <ItemLayout
          propertyName={translate("configurable_oee.oee_impact")}
          value={oeeGain}
        />
        <ItemLayout
          propertyName={translate("kpi.availability")}
          value={availability}
        />
        <ItemLayout
          propertyName={translate("kpi.performance")}
          value={performance}
        />
        <ItemLayout propertyName={translate("duration")} value={duration} />
      </CardContent>
      {isEditable ? (
        <EditableSwitchItem
          isClientRelevance={state.isClientRelevance}
          id={state.id}
        />
      ) : (
        <SwitchItem
          propertyName={translate("configurable_oee.impact")}
          isRelevant={state.isClientRelevance}
          isEdited={() => {}}
        />
      )}
    </CustomCard>
  );
};

const EditableSwitchItem = ({
  isClientRelevance,
  id,
}: {
  isClientRelevance: boolean;
  id: number;
}) => {
  const dispatch = useOeeConfigurationDispatch();
  const translate = useTranslate();

  return (
    <SwitchItem
      propertyName={translate("configurable_oee.impact")}
      isRelevant={isClientRelevance}
      isEdited={(checked) => {
        dispatch({
          type: "toggle switch",
          switchedState: {
            id: id,
            isClientRelevance: checked,
          },
        });
      }}
    />
  );
};

const formatPercent = (percent: number) =>
  percent > 0 ? `+ ${percent}%` : `${percent}%`;

export const HistoricStateInfoLayout = (state: {
  stateName: string;
  duration: number;
  // id: number;
  isClientRelevance: boolean;
  isEfficiencyRelevance: boolean;
}) => {
  const duration = formatDuration(state.duration);
  const translate = useTranslate();

  return (
    <CustomCard
      isEdited={!(state.isClientRelevance === state.isEfficiencyRelevance)}
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Typography
        align="center"
        style={{ fontSize: "14px", padding: ".4rem", fontWeight: "bold" }}
        component="div"
      >
        {state.stateName}
      </Typography>
      <CardContent sx={{ padding: "0 .5rem", minWidth: "250px" }}>
        <ItemLayout propertyName={translate("duration")} value={duration} />
      </CardContent>
      <Grid container alignItems={"center"} paddingX={1}>
        <Grid item xs={9}>
          <Typography align="left" variant="body2" component="div">
            {translate("configurable_oee.impact")}
          </Typography>
        </Grid>
        <Grid item xs={3} paddingX={3}>
          <Switch
            disabled
            isChecked={state.isClientRelevance}
            label={""}
            onChange={() => {}}
          />
        </Grid>
      </Grid>
    </CustomCard>
  );
};

const formatDuration = (durationInSeconds: number) => {
  const duration = Duration.fromObject({ seconds: durationInSeconds });
  if (duration.as("weeks") >= 1) {
    return duration.toFormat("w 'w' d 'd'");
  }
  if (duration.as("days") >= 1) {
    return duration.toFormat("d 'd' h 'h'");
  }
  if (duration.as("hours") >= 1) {
    return duration.toFormat("h 'h' m 'm'");
  }
  if (duration.as("minutes") >= 1) {
    return duration.toFormat("m 'm' s 's'");
  }
  return duration.toFormat("s 's'");
};
