import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import {
  evaluation,
  phase,
  barChartData,
  pieData,
  tag,
  summaryTableData,
} from "../../types";

export type GetSummaryResponse = ThingworxError | GetSummaryResult;

type Payload = {
  machineName: string;
};

const getSummaryResult = z.object({
  response: z.literal(true),
  currentPhase: phase,
  currentPhaseStatus: evaluation,
  elapsedTime: z.number(),
  expectedTime: z.number(),
  currentRecipe: z.string(),
  currentBatch: z.string(),
  barChartData: barChartData,
  pieData: z.array(pieData),
  tagData: z.optional(
    z.object({
      pressure: z.array(tag),
      temperature: z.array(tag),
      humidity: z.array(tag),
    }),
  ),
  tableData: z.array(summaryTableData),
});

export type GetSummaryResult = z.infer<typeof getSummaryResult>;

export const useGetSummaryData = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();

  return useQuery<GetSummaryResult>({
    queryKey: ["summary-data", machine!.machine],
    queryFn: async () => {
      const response = await FetchClient<Payload, GetSummaryResponse>({
        appKey,
        payload: {
          machineName: machine!.machine,
        },
        url: "dig.c.isolator_thing/Services/getSummary",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = getSummaryResult.parse(response);

      return validatedResponse;
    },
  });
};
