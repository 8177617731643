import { Uom } from "../types";

export const parseUomAxisLabel = (uom: Uom): string => {
  switch (uom) {
    case "celsius":
      return "Temperature (°C)";
    case "deg":
      return "Degrees (°)";
    case "grade":
      return "Degrees (°)";
    case "meter":
      return "Length (m)";
    case "pascal":
      return "Pressure (Pa)";
    case "percentage":
      return "Percentage (%)";
    case "timestamp":
      return "";
    case "ppm":
      return "Parts Per Million (ppm)";
    case "unit":
      return "";
    case "none":
      return "";
  }
};
