import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

export const TWeekSchema = z.object({
  MinuteStart: z.number(),
  MinuteEnd: z.number(),
  HourStart: z.number(),
  HourEnd: z.number(),
});
export type TWeek = z.infer<typeof TWeekSchema>;

export const DataSchema = z.object({
  nextWeek: z.record(z.string(), z.array(TWeekSchema)),
  isFactoryEqual: z.boolean(),
  currentWeek: z.record(z.string(), z.array(TWeekSchema)),
  StartWeek: z.number(),
});
export type Data = z.infer<typeof DataSchema>;

type Payload = {
  machineName: string;
  account: string;
} & Data;

export const TurnConfiguration = z.object({
  response: z.literal(true),
  Result: z.string(),
});

export type TurnConfiguration = z.infer<typeof TurnConfiguration>;

type TurnConfigurationResponse = ThingworxError | TurnConfiguration;

export const useSetTurnParametric = () => {
  const { appKey, user } = useFirebaseContext();
  const { machine } = useMachineContext();

  const client = useQueryClient();
  return useMutation({
    mutationFn: async (data: Data) => {
      const response = await FetchClient<Payload, TurnConfigurationResponse>({
        appKey,
        payload: {
          ...data,
          machineName: machine?.machine ?? "",
          account: user?.email ?? "",
        },
        url: "dig.c.shift_thing/Services/setTurnParametric",
      });

      if (!response.response) throw new Error(response.errorString);
      return TurnConfiguration.parse(response);
    },
    onSuccess(data) {
      if (data.response) {
        client.invalidateQueries([
          "machineSettings-sentinel-getTurnConfiguration",
          machine?.machine || "",
        ]);
        toast.success("Configuration saved");
      }
    },
    onError() {
      toast.error("Something went wrong");
    },
  });
};
