import { StatesLogData } from "../Pages/LineDetail/api/useGetStatesLog";

export const getDataBatches = (
  data: StatesLogData,
  timezone: string,
): { [key: string]: StatesLogData } => {
  const batches: { [key: string]: StatesLogData } = {};
  if (Object.keys(data).length === 0) return batches;
  const batchLength = 15000;
  let totalStates = 0;

  for (const array of Object.values(data)) {
    totalStates += array.length;
  }
  const numberOfBatches = Math.ceil(totalStates / batchLength);
  const benchmarkBatch = data[Object.keys(data)[0]];
  const benchmarkBatchLength = benchmarkBatch.length;
  const fragmentsLength = Math.round(
    benchmarkBatchLength / (numberOfBatches + 1),
  );
  if (Object.keys(benchmarkBatch).length === 0) return batches;
  const firstTimestamp = benchmarkBatch[0].start;
  const benchmarkTimestamps: number[] = [];
  for (let i = 1; i < numberOfBatches; i++) {
    benchmarkTimestamps.push(benchmarkBatch[fragmentsLength * i].start);
  }
  if (numberOfBatches === 1) {
    //batches[new Date(firstTimestamp).toLocaleString()] = data;
    batches[
      new Date(firstTimestamp).toLocaleString(undefined, { timeZone: timezone })
    ] = data;
    return batches;
  }

  benchmarkTimestamps.forEach((timestamp, index) => {
    const batchIndex = index + 1;
    const isFirst = index === 0;
    const isLast = batchIndex === benchmarkTimestamps.length;
    const prevTimestamp = benchmarkTimestamps[index - 1];

    const batchName = isFirst
      ? new Date(firstTimestamp).toLocaleString(undefined, {
          timeZone: timezone,
        })
      : new Date(prevTimestamp).toLocaleString(undefined, {
          timeZone: timezone,
        });
    batches[batchName] = {};
    if (isFirst) {
      Object.entries(data).forEach(([machine, states]) => {
        if (!Object.keys(batches[batchName]).includes(machine)) {
          batches[batchName][machine] = [];
        }
        const statesFragment = states.filter(
          (state) => state.start < timestamp,
        );
        batches[batchName][machine] = statesFragment;
      });
    } else {
      Object.entries(data).forEach(([machine, states]) => {
        if (!Object.keys(batches[batchName]).includes(machine)) {
          batches[batchName][machine] = [];
        }
        const statesFragment = states.filter(
          (state) => state.start > prevTimestamp && state.start < timestamp,
        );
        batches[batchName][machine] = statesFragment;
      });
    }
    if (isLast) {
      const name = new Date(timestamp).toLocaleString();
      batches[name] = {};
      Object.entries(data).forEach(([machine, states]) => {
        if (!Object.keys(batches[name]).includes(machine)) {
          batches[name][machine] = [];
        }
        const statesFragment = states.filter(
          (state) => state.start > timestamp,
        );
        batches[name][machine] = statesFragment;
      });
    }
  });

  return batches;
};
