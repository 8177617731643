import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { Dispatch, SetStateAction, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  useMachineContextDispatch,
  useMachineContextState,
} from "../context/MachineSettingsContext";
import { toast } from "react-hot-toast";
import { Card } from "@/components/Layout/Card";
import { useSetTurnParametric } from "../api/useSetTurnParametric";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useIsAllowed } from "@/hooks/useIsAllowed";
import { UserRole } from "@/context/firebase-context";
import { useTranslate } from "@/i18n/config";

const ButtonStyle = {
  width: "110px",
  padding: "10px",
  marginLeft: "1rem",
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  p: 4,
};

type WeekFormConfiguration = {
  dayStart: number;
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  setStartWeek: Dispatch<SetStateAction<number>>;
  isError: boolean;
};

export const StartWeekForm = (props: WeekFormConfiguration) => {
  const { dayStart, editMode, setEditMode, setStartWeek, isError } = props;
  const [day, setDay] = useState(dayStart);
  const [isFactoryEqual, setIsFactoryEqual] = useState(false);
  const { open, close, isOpen } = useDisclosure();
  const translate = useTranslate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);

  const dispatch = useMachineContextDispatch();
  const state = useMachineContextState().editableData;

  const { mutate: setConfiguration } = useSetTurnParametric();

  const saveConfigurationHandler = () => {
    setConfiguration({
      currentWeek: state.currentWeek,
      nextWeek: state.nextWeek,
      StartWeek: state.StartWeek,
      isFactoryEqual: isFactoryEqual,
    });
    close();
    setEditMode(false);
  };

  const HandleFactoryEqual = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsFactoryEqual(event.target.checked);
  };

  const allowEdit = useIsAllowed([
    UserRole.USER_ADMIN,
    UserRole.SUPER_USER,
    UserRole.SUPER_USER_SENTINEL,
  ]);

  return (
    <Stack direction="row" spacing={1}>
      <Modal open={isOpen} onClose={close} closeAfterTransition>
        <Card sx={modalStyle}>
          <Stack direction={"column"} spacing={2} justifyContent={"center"}>
            <Typography variant="h6" component="h2">
              {translate("user_feedback.saving")}
            </Typography>
            <Typography sx={{ mt: 2 }}>
              {translate("machine_settings.want_to_save")}
            </Typography>
            <Typography sx={{ mt: 2 }}>
              {translate("machine_settings.warn_nb")}
            </Typography>
            <Stack alignItems={"center"}>
              <Typography sx={{ mt: 1 }}>
                {translate("machine_settings.apply_to_factory")}
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isFactoryEqual}
                      onChange={HandleFactoryEqual}
                    />
                  }
                  label={isFactoryEqual ? "Yes" : "No"}
                />
              </FormGroup>
            </Stack>
            <Stack direction={"row"} justifyContent={"center"}>
              <Button
                style={ButtonStyle}
                variant="outlined"
                color="success"
                onClick={saveConfigurationHandler}
              >
                {translate("actions.confirm")}
              </Button>
              <Button
                style={ButtonStyle}
                variant="outlined"
                color="error"
                onClick={close}
              >
                {translate("actions.cancel")}
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Modal>
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
      >
        <Box sx={{ width: "100%" }}>
          <FormControl>
            <InputLabel id="first-day-select-label">
              {translate("first_day")}
            </InputLabel>
            <Select
              labelId="first-day-select-label"
              id="first-day-select-label"
              value={day}
              label={translate("first_day")}
              onChange={(e) => {
                const value = e.target.value as number;
                setStartWeek(value);
                setDay(value);
                dispatch({ type: "set_start_date", startWeek: value });
              }}
              sx={{ fontWeight: "bold", width: "10rem", height: "2.8rem" }}
            >
              <MenuItem value={0}>{translate("days.sunday")}</MenuItem>
              <MenuItem value={1}>{translate("days.monday")}</MenuItem>
              <MenuItem value={2}>{translate("days.tuesday")}</MenuItem>
              <MenuItem value={3}>{translate("days.wednesday")}</MenuItem>
              <MenuItem value={4}>{translate("days.thursday")}</MenuItem>
              <MenuItem value={5}>{translate("days.friday")}</MenuItem>
              <MenuItem value={6}>{translate("days.saturday")}</MenuItem>
            </Select>
          </FormControl>
          {editMode ? (
            <>
              <Button
                style={ButtonStyle}
                variant="outlined"
                onClick={open}
                disabled={isError ? true : false}
              >
                {translate("actions.save")}
              </Button>
              <Button
                style={ButtonStyle}
                variant="outlined"
                onClick={() => {
                  setEditMode(false);
                }}
              >
                {translate("actions.cancel")}
              </Button>
              <Button
                onClick={() => {
                  dispatch({ type: "reset" });
                  toast.success("shifts have been reset ");
                }}
                style={ButtonStyle}
                variant="outlined"
                color="error"
              >
                {translate("actions.reset")}
              </Button>
            </>
          ) : allowEdit ? (
            <Button
              style={ButtonStyle}
              variant="outlined"
              onClick={() => {
                setEditMode(true);
              }}
            >
              {translate("actions.edit")}
            </Button>
          ) : null}
        </Box>
        <Box>
          <IconButton
            aria-owns={openPopover ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <InfoOutlinedIcon />
          </IconButton>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: "none",
            }}
            open={openPopover}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography variant="h5" sx={{ p: 1 }}>
              {translate("actions.save")}
            </Typography>
            <Typography sx={{ p: 1 }}>
              {translate("machine_settings.save_helper")}
            </Typography>
            <Divider />
            <Typography variant="h5" sx={{ p: 1 }}>
              {translate("actions.reset")}
            </Typography>
            <Typography sx={{ p: 1 }}>
              {translate("machine_settings.reset_helper")}
            </Typography>
            <Divider />
            <Typography variant="h5" sx={{ p: 1 }}>
              {translate("first_day")}
            </Typography>
            <Typography sx={{ p: 1 }}>
              {translate("machine_settings.first_day_helper")}
            </Typography>
            <Divider />
            <Typography variant="h5" sx={{ p: 1 }}>
              {`${translate("current_week")} / ${translate("next_week")}`}
            </Typography>
            <Typography sx={{ p: 1 }}>
              {translate("machine_settings.week_helper")}
            </Typography>
          </Popover>
        </Box>
      </Box>
    </Stack>
  );
};
