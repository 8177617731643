import { EvaluationColors } from "../types";

export const EVALUATION_COLORS: EvaluationColors = {
  good: {
    selected: "#00b00c",
    notSelected: "#374a15",
  },
  warning: {
    selected: "#e8c52a",
    notSelected: "#52450f",
  },
  critical: {
    selected: "#D62422",
    notSelected: "#470e0d",
  },
  lack_of_data: {
    selected: "#999999",
    notSelected: "#222",
  },
  no_data: {
    selected: "#CCCCCC",
    notSelected: "#444",
  },
  undefined: {
    selected: "#FFFFFF",
    notSelected: "#666",
  },
  aborted: {
    selected: "#D62422",
    notSelected: "#470e0d",
  },
};
