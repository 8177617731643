/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Grid } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useGetPhaseData } from "../../api/useGetPhaseData";
import { Batch, Phases } from "../../types";
import { Asset } from "../Asset/Asset";
import { AssetSkeletonLayout } from "../Asset/AssetSkeletonLayout";
import { AssetChart } from "../AssetChart/AssetChart";
import { LightContainer } from "../Layouts/LightContainer";
import { LightSkeletonContainer } from "../Layouts/LightSkeletonContainer";
import { useTranslate } from "@/i18n/config";

const containerVariants = {
  open: {
    transition: { staggerChildren: 0.07 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

export const BatchAnalytic = (props: {
  name: string;
  batchId: number;
  batches: Batch[];
  selectedPhaseId: number;
  selectedPhaseName: string;
}) => {
  const translate = useTranslate();
  const { data: response, isLoading: loadingInitialData } = useGetPhaseData({
    phase_id: props.selectedPhaseId,
    phaseName: props.selectedPhaseName as Phases,
    batch_id: props.batchId,
  });

  const [selectedSubphase, setSelectedSubphase] = useState<null | number>(null);
  const [selectedAsset, setSelectedAsset] = useState<null | number>(null);

  const subhphases = response?.data ?? [];
  const fond = subhphases?.filter((subphase) => {
    return subphase.subphase_id === selectedSubphase;
  });

  useEffect(() => {
    setSelectedAsset(null);

    return () => {
      setSelectedSubphase(null);
      setSelectedAsset(null);
    };
  }, [props.selectedPhaseId]);

  const subphaseAssets = fond?.length ? fond[0].assets : null;

  const assetName = subphaseAssets?.find(
    (asset) => asset.asset_id === selectedAsset,
  )?.assetName;

  if (loadingInitialData)
    return (
      <Grid container spacing={2} columns={{ xs: 1, md: 4 }} flex={1}>
        <Grid item xs={1} md={1}>
          <LightContainer
            // @ts-ignore
            component={motion.div}
            variants={containerVariants}
            initial="closed"
            animate="open"
          >
            {Array(6)
              .fill("")
              .map((_, i) => (
                <AssetSkeletonLayout key={i} />
              ))}
          </LightContainer>
        </Grid>

        <Grid item xs={1} md={1}>
          <LightContainer
            // @ts-ignore
            component={motion.div}
            variants={containerVariants}
            initial="closed"
            animate="open"
          >
            {Array(6)
              .fill("")
              .map((_, i) => (
                <AssetSkeletonLayout key={i} />
              ))}
          </LightContainer>
        </Grid>

        <Grid item xs={1} md={2}>
          <LightSkeletonContainer />
        </Grid>
      </Grid>
    );

  if (subhphases.length === 0) {
    return (
      <LightContainer
        // @ts-ignore
        component={motion.div}
        variants={containerVariants}
        initial="closed"
        animate="open"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <h3>
          {translate("diagnostics.lyo.no_analyzable_subphases_have_started")}
        </h3>
      </LightContainer>
    );
  }

  return (
    <Grid container spacing={2} columns={{ xs: 1, md: 4 }} flex={1}>
      {subhphases && (
        <Grid item xs={1} md={1}>
          <LightContainer
            // @ts-ignore
            component={motion.div}
            variants={containerVariants}
            initial="closed"
            animate="open"
          >
            {subhphases.map((data) => (
              <Asset
                key={data.subphase_id}
                active={selectedSubphase === data.subphase_id}
                name={data.name}
                status={data.status}
                id={data.subphase_id}
                onClick={(id) => {
                  setSelectedSubphase(id);
                  setSelectedAsset(null);
                }}
              />
            ))}
          </LightContainer>
        </Grid>
      )}
      {subphaseAssets && (
        <Grid item xs={1} md={1}>
          <LightContainer
            // @ts-ignore
            component={motion.div}
            variants={containerVariants}
            initial="closed"
            animate="open"
          >
            {subphaseAssets.map((asset) => (
              <Asset
                id={asset.asset_id}
                active={selectedAsset === asset.asset_id}
                status={asset.status}
                name={asset.assetName}
                key={asset.asset_id}
                onClick={(assetId) => setSelectedAsset(assetId)}
              />
            ))}
          </LightContainer>
        </Grid>
      )}

      {selectedAsset !== null && assetName && (
        <Grid item xs={1} md={2}>
          <LightContainer sx={{ position: "relative" }}>
            <AssetChart asset_id={selectedAsset} name={assetName} />
          </LightContainer>
        </Grid>
      )}
    </Grid>
  );
};
