import { useFirebaseContext } from "@/context/firebase-context";
import { useQuery } from "@tanstack/react-query";
import { PhaseData } from "../types";
import { FetchClient } from "@/services/ApiClient";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

const MACHINE_NAME = "Demo";

type BatchDataResponse = ThingworxError | BatchDataResult;

const BatchDataResult = z.object({
  response: z.literal(true),
  data: z.array(PhaseData),
});
type BatchDataResult = z.infer<typeof BatchDataResult>;

type Payload = {
  batch_id: number | null;
  machineName: string;
};

export const useGetBatchData = ({ batch_id }: { batch_id: number | null }) => {
  const { appKey } = useFirebaseContext();

  return useQuery<BatchDataResult>({
    queryKey: ["batch-data", batch_id],
    queryFn: async () => {
      const response = await FetchClient<Payload, BatchDataResponse>({
        appKey,
        url: "dig.c.lyophilizer_thing/Services/getBatchPhases",
        payload: {
          batch_id,
          machineName: MACHINE_NAME,
        },
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = BatchDataResult.parse(response);
      return validatedResponse;
    },
    enabled: batch_id !== null,
    refetchInterval: 1000 * 30,
  });
};
