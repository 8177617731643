import { Card } from "@/components/Layout/Card";
import { Box, Divider, Stack, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

export const ModelSummary = () => {
  return (
    <Card>
      <Grid2 container padding={2} spacing={4}>
        <Grid2 xs={6}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <img
              src="assets/img/RandomForest.svg"
              alt="random forest summary diagram"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
        </Grid2>
        <Grid2 xs={6}>
          <Box>
            <Typography variant="h6">Model Summary</Typography>

            <Stack gap={2} divider={<Divider />}>
              <Stack
                direction="row"
                width="100%"
                justifyContent="space-between"
              >
                <Typography variant="body2">Total predictors:</Typography>
                <Typography variant="body2">504</Typography>
              </Stack>

              <Stack
                direction="row"
                width="100%"
                justifyContent="space-between"
              >
                <Typography variant="body2">Important predictors:</Typography>
                <Typography variant="body2">53</Typography>
              </Stack>

              <Stack
                direction="row"
                width="100%"
                justifyContent="space-between"
              >
                <Typography variant="body2">
                  Number of terminal nodes:
                </Typography>
                <Typography variant="body2">6</Typography>
              </Stack>

              <Stack
                direction="row"
                width="100%"
                justifyContent="space-between"
              >
                <Typography variant="body2">
                  Minimum terminal node size:
                </Typography>
                <Typography variant="body2">7</Typography>
              </Stack>

              <Stack
                direction="row"
                width="100%"
                justifyContent="space-between"
              >
                <Typography variant="body2">
                  Misclassification cost (Training):
                </Typography>
                <Typography variant="body2">0,0305</Typography>
              </Stack>

              <Stack
                direction="row"
                width="100%"
                justifyContent="space-between"
              >
                <Typography variant="body2">
                  Misclassification cost (Test):
                </Typography>
                <Typography variant="body2">0,0746</Typography>
              </Stack>

              <Typography variant="h6">Area under ROC curve</Typography>
              <Stack
                direction="row"
                width="100%"
                justifyContent="space-between"
              >
                <Typography variant="body2">label = M3410 vs not:</Typography>
                <Typography variant="body2">0,9934</Typography>
              </Stack>

              <Stack
                direction="row"
                width="100%"
                justifyContent="space-between"
              >
                <Typography variant="body2">labels = M3420 vs not</Typography>
                <Typography variant="body2">0,9677</Typography>
              </Stack>

              <Stack
                direction="row"
                width="100%"
                justifyContent="space-between"
              >
                <Typography variant="body2">labels = M3430 vs not:</Typography>
                <Typography variant="body2">0,9936</Typography>
              </Stack>

              <Stack
                direction="row"
                width="100%"
                justifyContent="space-between"
              >
                <Typography variant="body2">labels = M3440 vs not:</Typography>
                <Typography variant="body2">0,9846</Typography>
              </Stack>
            </Stack>
          </Box>
        </Grid2>
      </Grid2>
    </Card>
  );
};
