import { Box } from "@mui/material";
import { Card } from "@/components/Layout/Card";
import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { humanizeString } from "../../utils/humanizeString";
import { ContextInformation } from "../Layouts/ContextInformation";
import { DescriptiveData } from "../../api/useGetDescriptiveData";
import { fileNameFactory } from "@/utils/fileNameFactory";

export const DescriptiveAnalytic = ({ props }: { props: DescriptiveData }) => {
  const { analyticName, frameName, motorName, data, contextInfo } = props;

  return (
    <Card>
      <Box>
        <ContextInformation
          props={contextInfo}
          key={`${analyticName}-${motorName}-${frameName}`}
        />
        <LineChart.Custom
          filename={fileNameFactory({
            name: analyticName,
            machine: motorName,
          })}
          title={`Time Series ${humanizeString(analyticName)}`}
          yAxis={[
            { uom: "", series: [{ type: "line", name: analyticName, data }] },
          ]}
          zoomType="x"
        ></LineChart.Custom>
      </Box>
    </Card>
  );
};
