import { useFirebaseContext } from "@/context/firebase-context";
import { useQuery } from "@tanstack/react-query";
import { Batch } from "../types";
import { FetchClient } from "@/services/ApiClient";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type AssetComparisonResponse = ThingworxError | AssetComparisonResult;

const AssetComparisonResult = z.object({
  response: z.literal(true),
  chart: z.discriminatedUnion("chart_type", [
    z.object({
      chart_type: z.literal("barchart"),
      uom: z.string(),
      data: z.array(
        z.object({
          name: z.string(),
          started_at: z.number(),
          batch_id: z.number(),
          data: z.array(z.number()),
          color: z.string().optional(),
        }),
      ),
    }),
    z.object({
      chart_type: z.literal("linechart"),
      uom: z.string(),
      data: z.array(
        z.object({
          name: z.string(),
          started_at: z.number(),
          batch_id: z.number(),
          data: z.array(z.array(z.number())),
          color: z.string().optional(),
        }),
      ),
    }),
  ]),
});

type AssetComparisonResult = z.infer<typeof AssetComparisonResult>;

type Payload = {
  phase_id: number;
  batch_ids: number[];
  asset_id: number;
  subphase_name: string;
};

export const useGetAssetComparisonData = ({
  phase_id,
  subphase_name,
  asset_id,
  batches,
}: {
  asset_id: number;
  phase_id: number;
  subphase_name: string;
  batches: Batch[];
}) => {
  const { appKey } = useFirebaseContext();

  //   const { machine } = useMachineContext();
  const batchIds = batches.map((batch) => batch.batchId).sort((a, b) => a - b);

  return useQuery<AssetComparisonResult>({
    queryKey: [
      "asset-comparison",
      "asset-data",
      phase_id,
      subphase_name,
      asset_id,
      batchIds,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, AssetComparisonResponse>({
        appKey,
        url: "dig.c.lyophilizer_thing/Services/getAssetComparison",
        payload: {
          phase_id,
          batch_ids: batchIds,
          asset_id,
          subphase_name,
        },
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = AssetComparisonResult.parse(response);
      return validatedResponse;
    },
  });
};
