import { motion, SVGMotionProps } from "framer-motion";

export const ClockIcon = ({
  width = 24,
  height = 24,
  fill = "#e8eaed",
  ...rest
}: SVGMotionProps<SVGSVGElement>) => (
  <motion.svg
    viewBox="0 0 960 960"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    {...rest}
    whileHover="hover"
  >
    {/* Cerchio principale */}
    <motion.path
      d="M480 80c-217.6 0-400 182.4-400 400s182.4 400 400 400 400-182.4 400-400S697.6 80 480 80Zm0 720c-176 0-320-144-320-320s144-320 320-320 320 144 320 320-144 320-320 320Z"
      variants={{
        hover: {
          scale: 1.1, // Il cerchio diventa più grande
          transition: { duration: 0.5, ease: "easeInOut" },
        },
        initial: { scale: 1 },
      }}
    />

    <motion.path
      d="M480 480v-160"
      stroke="currentColor"
      strokeWidth="50"
      strokeLinecap="round"
      initial={{ rotate: 330, originX: "50%", originY: "50%" }}
      variants={{
        hover: {
          rotate: 660,
          originX: "50%",
          originY: "50%",
          transition: { duration: 1, ease: "easeInOut" },
        },
        initial: { rotate: 330 },
      }}
    />

    <motion.path
      d="M480 480v-270"
      stroke="currentColor"
      strokeWidth="50"
      strokeLinecap="round"
      initial={{ rotate: 30, originX: "50%", originY: "50%" }}
      variants={{
        hover: {
          rotate: 390,
          originX: "50%",
          originY: "50%",
          transition: { duration: 0.5, ease: "easeInOut" },
        },
        initial: { rotate: 0 },
      }}
    />
  </motion.svg>
);
