import { Card } from "@/components/Layout/Card";
import { InfoOutlined } from "@mui/icons-material";
import { Box, Icon, Tooltip } from "@mui/material";

interface CardAnomalyProps {
  datatype: "perc" | "time" | string;
  data: number;
  text: string;
  tooltipDescription?: string;
}

export function CardAnomaly({
  datatype,
  data,
  text,
  tooltipDescription,
}: CardAnomalyProps) {
  let formattedData = "";

  switch (datatype) {
    case "perc": {
      const value = Number.isInteger(data) ? data : data.toFixed(2);
      formattedData = `${value}%`;
      break;
    }
    case "time": {
      const rawTime = Math.round(data);
      const hoursRaw = Math.floor(rawTime / 3600);
      let hours = "";

      if (hoursRaw > 24) {
        hours = `${Math.floor(hoursRaw / 24)}d ${hoursRaw % 24}`;
      } else {
        hours = hoursRaw.toString();
      }

      const minutesRaw = Math.floor((rawTime / 60) % 60);
      const minutes =
        minutesRaw < 10 ? `0${minutesRaw}` : minutesRaw.toString();

      const secondsRaw = rawTime % 60;
      const seconds =
        secondsRaw < 10 ? `0${secondsRaw}` : secondsRaw.toString();

      formattedData = `${hours}:${minutes}:${seconds}`;
      break;
    }
    default: {
      formattedData = data.toString();
      break;
    }
  }

  return (
    <Card
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      {tooltipDescription && (
        <Icon sx={{ position: "absolute", top: ".5rem", right: ".5rem" }}>
          <Tooltip title={tooltipDescription}>
            <InfoOutlined />
          </Tooltip>
        </Icon>
      )}
      {text}
      <Box
        sx={{
          fontWeight: "bold",
          textAlign: "center", // Sostituisce `align` con `textAlign`
        }}
      >
        {formattedData}
      </Box>
    </Card>
  );
}
