import { create } from "zustand";

export const useEditStore = create<{
  isEditable: boolean;
  toggle: () => void;
}>()((set) => ({
  isEditable: false,
  toggle() {
    set((state) => ({ isEditable: !state.isEditable }));
  },
}));
