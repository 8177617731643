import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { useLanguage } from "@/i18n/config";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type TopRejectCausesResponse = ThingworxError | RejectCausesResult;

const RejectCauseDetail = z.object({
  cause: z.string(),
  description: z.string(),
  occurences: z.number(),
});
export type RejectCauseDetail = z.infer<typeof RejectCauseDetail>;

const RejectCausesResult = z.object({
  response: z.literal(true),
  data: z.array(RejectCauseDetail),
});

type RejectCausesResult = z.infer<typeof RejectCausesResult>;

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
  dateStart?: DateTime;
  dateEnd?: DateTime;
  language_id: string;
};

export const useGetTopRejectCauses = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const {
    timeSelection,
    dates: { dateStart, dateEnd },
  } = useTimeSelection();

  const language = useLanguage();

  const payload: Payload =
    timeSelection === "Custom"
      ? {
          machineName: machine?.machine || "",
          timeSelection,
          dateStart,
          dateEnd,
          language_id: language,
        }
      : {
          machineName: machine?.machine || "",
          timeSelection,
          language_id: language,
        };

  const queryKey =
    timeSelection === "Custom"
      ? ["top-reject-causes", machine?.machine, timeSelection, dateStart, dateEnd, language]
      : ["top-reject-causes", machine?.machine, timeSelection, language];

  return useQuery<RejectCauseDetail[]>({
    queryKey,
    queryFn: async () => {
      const response = await FetchClient<Payload, TopRejectCausesResponse>({
        appKey,
        url: "dig.c.rejectCause_thing/Services/getTopTenRejectsCauses",
        payload,
      });

      if (!response.response) throw new Error(response.errorString);

      return RejectCausesResult.parse(response).data;
    },
    enabled: !!machine?.machine,
  });
};
