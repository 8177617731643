import { Batch, LineData } from "../types";
import { useQuery } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";
import { z } from "zod";
import { ThingworxError } from "src/types/error";
import { FetchClient } from "@/services/ApiClient";

type RangeChartResponse = ThingworxError | RangeDataResult;

const GridColumnDefinition = z.object({
  field: z.string(),
  headerName: z.string(),
});

const RangeChart = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("barchart"),
    title: z.string(),
    categories: z.array(z.string()),
    uom: z.string(),
    thresholds: z
      .array(
        z.object({
          value: z.number(),
          color: z.string(),
          text: z.string(),
        }),
      )
      .optional(),
    data: z.array(
      z.object({
        name: z.string(),
        y: z.number(),
        color: z.string().optional(),
      }),
    ),
    zones: z
      .array(
        z.object({
          from: z.number(),
          to: z.number(),
          color: z.string(),
        }),
      )
      .optional(),
  }),

  z.object({
    type: z.literal("linechart"),
    title: z.string(),
    data: z.array(LineData),
  }),

  z.object({
    type: z.literal("table"),
    columns: z.array(GridColumnDefinition),
    rows: z.array(z.record(z.any())),
  }),

  z.object({
    type: z.literal("barchart-drilldown"),
    title: z.string(),
    data: z.array(
      z.object({
        name: z.string(),
        y: z.number(),
        drilldown: z.number(),
      }),
    ),
    drilldown: z.array(
      z.object({
        type: z.literal("column"),
        borderColor: z.literal("none"),
        id: z.number(),
        data: z.array(z.tuple([z.string(), z.number()])),
      }),
    ),
  }),

  z.object({
    type: z.literal("pieChart"),
    title: z.string(),
    data: z.array(
      z.object({
        name: z.string(),
        y: z.number(),
        color: z.string().optional(),
      }),
    ),
    label: z.string().optional(),
  }),
]);

export type RangeChart = z.infer<typeof RangeChart>;

const RangeDataResult = z.object({
  response: z.literal(true),
  rangeChartResponse: z.array(RangeChart),
});

type RangeDataResult = z.infer<typeof RangeDataResult>;

const MACHINE_NAME = "Demo";

export const useGetRangeData = ({
  phase_id,
  batches,
}: {
  phase_id: number;
  batches: Batch[];
}) => {
  const { appKey } = useFirebaseContext();

  const batchId = batches.map((batch) => batch.batchId).sort();

  return useQuery<RangeDataResult>({
    queryKey: ["range-batch", phase_id, batchId.toString()],
    queryFn: async () => {
      const response = await FetchClient<
        {
          phase_id: number;
          batch_ids: number[];
          machine_name: string;
        },
        RangeChartResponse
      >({
        appKey,
        url: "dig.c.lyophilizer_thing/Services/getRangeData",
        payload: {
          phase_id,
          batch_ids: batchId,
          machine_name: MACHINE_NAME,
        },
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return RangeDataResult.parse(response);
    },
  });
};
