import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon/src/datetime";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { Shift } from "../../types";

type SpeedResponse = ThingworxError | SpeedResult;

const SpeedSelectorData = z.object({
  CS: z.array(z.array(z.number())),
  BS: z.array(z.array(z.number())),
  MS: z.array(z.array(z.number())),
  MIS: z.array(z.array(z.number())),
  IS: z.array(z.array(z.number())),
  MRS: z.array(z.array(z.number())),
});

type SpeedSelectorData = z.infer<typeof SpeedSelectorData>;

const SpeedResult = z.object({
  data: z.array(SpeedSelectorData),
  chartName: z.string(),
  configuration: z.string(),
  response: z.literal(true),
  shiftList: z.array(Shift),
});

type SpeedResult = z.infer<typeof SpeedResult>;

type Payload = {
  machineName: string;
  dateStart: DateTime;
  dateEnd: DateTime;
  timeSelection: TimeSelection;
};

export const useGetSpeedSelector = () => {
  const { timeSelection, dates } = useTimeSelection();
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();

  return useQuery<SpeedResult>({
    queryKey: [
      "machinePerformance-sentinel-SpeedSelector",
      timeSelection,
      machine?.machine,
      dates.dateStart,
      dates.dateEnd,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, SpeedResponse>({
        appKey,
        payload: {
          machineName: machine?.machine || "",
          dateStart: dates.dateStart,
          dateEnd: dates.dateEnd,
          timeSelection: timeSelection,
        },
        url: "dig.c.machinePerformance_thing/Services/getSpeedSelector",
      });

      if (!response.response) throw new Error(response.errorString);

      return SpeedResult.parse(response);
    },
    refetchInterval: timeSelection === "Custom" ? false : 1000 * 20,
  });
};
