import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { useFirebaseContext } from "@/context/firebase-context";
import { config, Config, IntellectaVersion } from "../types";
import { intellectaClient } from "./intellectaClient";
import { userConfigurationStore } from "../store/user-configuration-store";

export const useGetUserConfiguration = () => {
  const { user } = useFirebaseContext();
  const email = user?.email || "";

  const queryResult = useQuery({
    queryKey: ["intellecta-config", email],
    queryFn: async () => {
      const response = await intellectaClient<{ user: string }, Config>({
        url: "/user/config",
        payload: { user: email },
      });

      return config.parse(response);
    },
    staleTime: 1000 * 60 * 60,
    onError: () => {
      toast.error("An error occurred");
    },
  });

  // Update store Zustand con configurazione utente
  // Compliant con @tanstack/react-query v6
  const { data } = queryResult;

  useEffect(() => {
    if (data) {
      let defaultVersion: IntellectaVersion | null = null;
      let defaultProfile: string | null = null;
      data.versions.forEach((version) => {
        version.profiles.forEach((profile) => {
          if (profile.is_active) {
            defaultProfile = profile.name;
            defaultVersion = version.name;
          }
        });
      });

      if (!defaultVersion || !defaultProfile) {
        if (data.versions.length > 0) {
          const firstAvailableVersion = data.versions[0];
          defaultVersion = firstAvailableVersion.name;
          if (firstAvailableVersion.profiles.length > 0) {
            defaultProfile = firstAvailableVersion.profiles[0].name;
          }
        }
      }

      userConfigurationStore.setState((state) => ({
        ...state,
        availableVersionsAndProfiles: data.versions
          .map((version) => ({
            ...version,
            profiles: version.profiles.sort((a, b) =>
              a.name.localeCompare(b.name),
            ), // Ordina i profili per nome
          }))
          .sort((a, b) => a.name.localeCompare(b.name)), // Ordina le versioni per nome
        availableMachines: data.machines,
        powerWords: data.powerwords,
        prompts: data.prompts,
        role: data.role,
        services: data.services,
        // Inizializzo 'version' e 'profile'
        version: defaultVersion,
        profile: defaultProfile,
      }));
    }
  }, [data]);

  return queryResult;
};
