import {
  ExpandLess,
  ExpandMore,
  QueryStatsOutlined,
} from "@mui/icons-material";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  useTheme,
} from "@mui/material";

import { useState } from "react";

import { Nav } from "./Nav";
import { useMachineContext } from "@/context/machine-context";

export const Monitoring = ({ open: isSidebarOpen }: { open: boolean }) => {
  const { palette } = useTheme();
  const [open, setOpen] = useState(false);

  const { monitoringPages } = useMachineContext();

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  if (!monitoringPages.length) return null;

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        sx={{
          minHeight: 48,
          px: 2.5,
        }}
      >
        <ListItemIcon
          className="transition-colors"
          sx={{
            minWidth: 0,
            mr: isSidebarOpen ? 1 : 200,
            justifyContent: "center",
            color: palette.info.light,
          }}
        >
          <QueryStatsOutlined />
        </ListItemIcon>
        <ListItemText primary={"Monitoring"} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        in={open}
        timeout="auto"
        sx={{ opacity: open ? 1 : 0 }}
        unmountOnExit
      >
        <List component="div" disablePadding>
          {monitoringPages.map((page) => (
            <Nav key={page.to} {...page} open={isSidebarOpen} />
          ))}
        </List>
      </Collapse>
    </>
  );
};
