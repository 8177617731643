import { BellCurve } from "@/components/highcharts/bellcurve/BellCurve";
import { Card } from "@/components/Layout/Card";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useState } from "react";
import { colors } from "@/styles/colors";
import { useTranslate } from "@/i18n/config";

interface DoserBellCurveProps {
  data: {
    [key: string]: {
      bellCurveData: {
        name: string;
        data: {
          [key: string]: number[];
        };
      }[];
      benchmark?: {
        min?: number;
        setpoint?: number;
        max?: number;
      };
    };
  };
  modalities: string[];
}

export const DoserBellCurve = ({
  data,
  modalities = [],
}: DoserBellCurveProps) => {
  const translate = useTranslate();
  const defaultScale = Object.keys(data)[0] || "";
  const [scale, setScale] = useState<string>(defaultScale);
  const [showBenchmark, setShowBenchmark] = useState(true);
  const [customModalities, setCustomModalities] =
    useState<string[]>(modalities);

  const handleBenchmarkChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setShowBenchmark(event.target.checked);
  };

  const handleScaleChange = (event: SelectChangeEvent<string | null>) => {
    const selectedScale = event.target.value as string;
    setScale(selectedScale);
  };

  const handleModalitiesChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const {
      target: { value, checked },
    } = event;
    if (checked) {
      setCustomModalities([...customModalities, value]);
    } else {
      setCustomModalities(customModalities.filter((item) => item !== value));
    }
  };

  const chartData: {
    name: string;
    data: number[];
  }[] = scale
    ? data[scale].bellCurveData.map((doser) => {
        const weighingsArr = [];
        for (const key in doser.data) {
          if (customModalities.includes(key)) {
            weighingsArr.push(doser.data[key]);
          }
        }
        return {
          name: doser.name,
          data: weighingsArr.flat(),
        };
      })
    : [];

  const benchmarkData = scale ? data[scale].benchmark : null;

  return (
    <Box sx={{ display: "flex" }}>
      <List
        subheader={
          <ListSubheader>{translate("filler.modality_other")}:</ListSubheader>
        }
        sx={{
          mr: 1,
          width: "21vw",
          overflowY: "auto",
          backgroundColor: colors.palette.darkBlue,
          color: "#fff",
          backgroundImage: "none",
          padding: "1rem",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: ".25rem",
        }}
      >
        {modalities.map((modality) => (
          <ListItem key={modality} disablePadding>
            <FormControlLabel
              control={
                <Checkbox
                  checked={customModalities.includes(modality)}
                  onChange={handleModalitiesChange}
                  value={modality}
                />
              }
              label={<ListItemText primary={modality} />}
            />
          </ListItem>
        ))}
      </List>
      <Box
        sx={{
          width: "79vw",
        }}
      >
        <Card sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormControl sx={{ m: 1, width: 240 }} size="small">
            <InputLabel id="select-scale-label">
              {translate("filler.scale")}
            </InputLabel>
            <Select
              labelId="select-scale-label"
              id="select-scale"
              value={scale}
              onChange={handleScaleChange}
              label={translate("filler.scale")}
            >
              {Object.keys(data).map((balance) => (
                <MenuItem key={balance} value={balance}>
                  {balance}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showBenchmark}
                  onChange={handleBenchmarkChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={translate("benchmark")}
            />
          </FormGroup>
        </Card>
        <Card>
          {chartData && (
            <BellCurve
              key={`${scale}_${customModalities}`}
              title={translate("filler.dosers_net_weights_distribution")}
              bellCurveData={chartData ?? []}
              benchmark={showBenchmark ? benchmarkData || undefined : undefined}
              histogram={true}
              uom="g"
              xAxisTitle={translate("weight")}
            />
          )}
        </Card>
      </Box>
    </Box>
  );
};
