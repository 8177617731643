import { useDisclosure } from "@/hooks/useDisclosure";
import { Settings } from "@mui/icons-material";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import { HistoricLineConfigViewer } from "../../LineDetail/components/LineConfig";
import { HistoricConfiguration } from "../api/useGetHistoricLineData";

export const HistoricConfigurationModal = ({
  configuration,
}: {
  configuration: HistoricConfiguration[];
}) => {
  const { open, close, isOpen } = useDisclosure();

  return (
    <>
      <IconButton
        sx={{ padding: 2 }}
        edge="start"
        color="inherit"
        aria-label="close"
        // className={styles.pulse}
        onClick={open}>
        <Settings />
      </IconButton>
      <Dialog open={isOpen} onClose={close} fullWidth>
        <DialogContent sx={{ padding: 0, margin: 0 }}>
          <HistoricLineConfigViewer config={configuration} />
        </DialogContent>
      </Dialog>
    </>
  );
};
