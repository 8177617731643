import { useEffect, useState } from "react";
import { ref, onValue } from "firebase/database";
import { db } from "@/lib/sentinel-app";
import { environment } from "../config";

export const useCheckIfMessageBanner = (): {
  showBanner: boolean;
} => {
  const [showBanner, setShowBanner] = useState(false);
  const env: "dev" | "qa" | "prod" =
    environment === "development"
      ? "dev"
      : environment === "staging"
      ? "qa"
      : "prod";

  useEffect(() => {
    const maintenanceRef = ref(db, "message");
    const unsubscribe = onValue(maintenanceRef, (snapshot) => {
      const data = snapshot.val();
      if (data.isEnabled && data.env) {
        if (typeof data.env === "string" && data.env.includes(env)) {
          setShowBanner(true);
        } else {
          setShowBanner(false);
        }
      } else {
        setShowBanner(false);
      }
    });

    return unsubscribe;
  }, []);

  return {
    showBanner,
  };
};
