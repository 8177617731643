import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { Stack, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useGetDataSelect } from "../api/useGetDataSelect";
import { colors } from "@/styles/colors";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { fileNameFactory } from "@/utils/fileNameFactory";
import { useMachineContext } from "@/context/machine-context";

export const PhysicalAsset = ({ asset }: { asset: string }) => {
  const translate = useTranslate();
  const { data, isLoading } = useGetDataSelect({ physical_name: asset });
  const { machine } = useMachineContext();
  const machineName = machine?.machine;

  if (isLoading) return <AnalyticContainerSkeleton />;

  return (
    <>
      {data ? (
        <Grid2 container spacing={2}>
          <Grid2 xs={12} md={10} display="flex" justifyItems={"stretch"}>
            <Card>
              <LineChart.Custom
                filename={fileNameFactory({
                  name: "physical_measures",
                  machine: machineName,
                  date: asset,
                })}
                title={data.name}
                yAxis={[
                  {
                    uom: "",
                    series: [
                      {
                        type: "line",
                        data: data.valueSeries,
                        name: data.name,
                        color: colors.physicalMeasures.actual,
                      },
                      {
                        type: "line",
                        data: data.setpointSeries,
                        name: translate("setpoint"),
                        color: colors.physicalMeasures.setpoint,
                      },
                    ],
                  },
                ]}
                legend={{ enabled: true }}
                lineType="line"
              />
            </Card>
          </Grid2>
          <Grid2 xs={12} md={2} display="flex" justifyItems={"stretch"}>
            <Card
              sx={{
                minHeight: "auto",
              }}
            >
              <Stack flex={1} height="100%" textAlign="center" justifyContent="center" gap={1}>
                <Typography>{translate("setpoint_changes")}</Typography>
                <Typography>{data.setpointChanges}</Typography>
              </Stack>
            </Card>
          </Grid2>
        </Grid2>
      ) : null}
    </>
  );
};
