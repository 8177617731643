import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getCustomLogo, getDefaultPlatformNameAndLogo } from "./utils";
import { useFirebaseContext } from "@/context/firebase-context";

interface PersonalizableHeaderProps {
  open: boolean;
}

export const PersonalizableHeader: React.FC<PersonalizableHeaderProps> = ({
  open,
}) => {
  const { platformLvl } = useFirebaseContext();

  const customLogoCompany = localStorage.getItem("customLogoCompany");

  const [logoUrl, setLogoUrl] = useState<string>(
    getDefaultPlatformNameAndLogo(platformLvl).logoUrl,
  );

  const [platformName, setPlatformName] = useState<string>(
    getDefaultPlatformNameAndLogo(platformLvl).platformName,
  );

  useEffect(() => {
    const getSetCustomLogo = async (company: string) => {
      try {
        const url = await getCustomLogo(company);
        setLogoUrl(url);
        setPlatformName(company);
      } catch (err) {
        console.error(err);
      }
    };
    if (customLogoCompany) {
      getSetCustomLogo(customLogoCompany);
    }
  }, [customLogoCompany]);

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: 48,
        padding: "1rem",
        px: 2,
        alignItems: "center",
      }}
    >
      <img
        src={logoUrl}
        width={34}
        style={{
          marginRight: open ? 1 : "auto",
        }}
      />
      <Typography
        sx={{
          opacity: open ? 1 : 0,
          fontWeight: "bold",
          fontSize: "18px",
          marginLeft: ".7rem",
        }}
      >
        {platformName.toUpperCase()}
      </Typography>
    </Box>
  );
};
