import { Card } from "@/components/Layout/Card";
import { useGetDosersScalesData } from "../api/useGetDosersScalesData";
import { AnalyticContainerSkeleton } from "../../LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { SeriesOptionsType } from "highcharts";
import { Tabs, Tab, Box } from "@mui/material";
import { useState } from "react";
import { useTranslate } from "@/i18n/config";

const VIEWS = {
  scales: 0,
  dosers: 1,
};

export const BalanceDoserColumn = () => {
  const [view, setView] = useState(VIEWS.scales);
  const { data, isLoading, error } = useGetDosersScalesData();
  const translate = useTranslate();

  if (isLoading) {
    return <AnalyticContainerSkeleton />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  const dosersData = data.dosers.sort((a, b) => a.name.localeCompare(b.name));
  const scalesData = data.scales.series.sort((a, b) =>
    a.name.localeCompare(b.name),
  );
  const drilldownData = data.scales.drilldown.sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  return (
    <Card>
      <Tabs
        value={view}
        aria-label="scales and dosers tabs"
        onChange={(_: React.SyntheticEvent, newValue: number) => {
          setView(newValue);
        }}
      >
        <Tab value={VIEWS.scales} label={translate("filler.scale_other")} />
        <Tab value={VIEWS.dosers} label={translate("filler.doser_other")} />
      </Tabs>
      {view === VIEWS.dosers ? (
        <Box sx={{ height: "400px" }}>
          <BarChart.Custom
            key={VIEWS.dosers}
            title={translate("filler.scales_dosers_reject_detail")}
            uom=""
            series={[{ type: "column", data: dosersData, color: "orange" }]}
            tooltip={{
              enabled: true,
              options: {
                headerFormat: "",
                pointFormatter: function (this) {
                  const point = this as unknown as {
                    options: { weighings: number };
                  };
                  return `
                  <span style='font-size:11px'>${this.name}</span><br>
                  <span>${translate("machine.total_rejects")}</span>: <b>${
                    this.y
                  }</b><br>
                  <span>${translate(
                    "filler.total_weighings",
                  )}</span>: <b>${point.options?.weighings}</b><br>
                  <span>${translate("filler.reject_percentage")}</span>: <b>${(
                    (100 / point.options?.weighings) *
                    (this.y || 0)
                  ).toFixed(2)} %</b>
                  `;
                },
              },
            }}
          />
        </Box>
      ) : view === VIEWS.scales ? (
        <Box sx={{ height: "400px" }}>
          <BarChart.Custom
            key={VIEWS.scales}
            title={translate("filler.scales_dosers_reject_detail")}
            uom=""
            series={[
              {
                type: "column",
                color: "#08cfeb",
                data: scalesData,
              },
            ]}
            drillDownOptions={{
              series:
                drilldownData.flatMap<SeriesOptionsType>((drillDownData) => {
                  return [
                    {
                      borderColor: "transparent",
                      type: "column",
                      color: "orange",
                      ...drillDownData,
                    },
                  ];
                }) || [],
            }}
            tooltip={{
              enabled: true,
              options: {
                headerFormat: "",
                pointFormatter: function (this) {
                  const point = this as unknown as {
                    options: { weighings: number };
                  };
                  return `
                  <span style='font-size:11px'>${this.name}</span><br>
                  <span>${translate("machine.total_rejects")}</span>: <b>${
                    this.y
                  }</b><br>
                  <span>${translate(
                    "filler.total_weighings",
                  )}</span>: <b>${point.options?.weighings}</b><br>
                  <span>${translate("filler.reject_percentage")}</span>: <b>${(
                    (100 / point.options?.weighings) *
                    (this.y || 0)
                  ).toFixed(2)} %</b>
                  `;
                },
              },
            }}
          />
        </Box>
      ) : null}
    </Card>
  );
};
