import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { batchEl } from "../../types";
import { useIsolatorContext } from "../../context/useIsolatorContext";

export type GetConditionMonitoringAnalyticsListResponse =
  | ThingworxError
  | GetConditionMonitoringAnalyticsListResult;

type Payload = {
  machineName: string;
  batchIds: number[];
};

const getConditionMonitoringAnalyticsListResult = z.object({
  response: z.literal(true),
  analytics: z.array(batchEl),
});

export type GetConditionMonitoringAnalyticsListResult = z.infer<
  typeof getConditionMonitoringAnalyticsListResult
>;

export const useGetConditionMonitoringAnalyticsList = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const { batches } = useIsolatorContext();
  const batchIds = batches.map((batch) => batch.id);

  return useQuery<GetConditionMonitoringAnalyticsListResult>({
    queryKey: [
      "condition-monitoring-analytics-list",
      machine!.machine,
      JSON.stringify(batchIds),
    ],
    queryFn: async () => {
      const response = await FetchClient<
        Payload,
        GetConditionMonitoringAnalyticsListResponse
      >({
        appKey,
        payload: {
          machineName: machine!.machine,
          batchIds,
        },
        url: "dig.c.isolator_thing/Services/getConditionMonitoringAnalyticsList",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse =
        getConditionMonitoringAnalyticsListResult.parse(response);

      return validatedResponse;
    },
  });
};
