import { RangePicker } from "@/components/Calendars/RangePicker";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import { useState } from "react";
import { QuantizazionErrorChart } from "./components/QuantizazionErrorChart";
import { SelfOrganizingMap } from "./components/SelfOrganizingMap";
import { ForecastAccuracyChart } from "./components/ForecastAccuracyChart";
import { ModelSummary } from "./components/ModelSummary";
import { NeuralNetworkSummary } from "./components/NeuralNetworkSummary";

export const PredictiveAnalytics = () => {
  const [selectedAnalytic, setSelectedAnalytic] = useState<
    "som" | "decision tree" | "neural network"
  >("som");

  const content =
    selectedAnalytic === "som" ? (
      <Stack gap={2}>
        <QuantizazionErrorChart />
        <SelfOrganizingMap />
      </Stack>
    ) : (
      <Stack gap={2}>
        <ForecastAccuracyChart />
        {selectedAnalytic === "decision tree" ? (
          <ModelSummary />
        ) : (
          <NeuralNetworkSummary />
        )}
      </Stack>
    );

  return (
    <>
      <MachineViewNavBar>
        <RangePicker onPickDate={() => {}} />

        <FormControl size="small">
          <InputLabel id="gear">Gear</InputLabel>
          <Select
            labelId="gear"
            id="gear-select"
            defaultValue={"fan"}
            label="Gear"
          >
            <MenuItem value={"fan"}>Fan</MenuItem>
            <MenuItem value={"isolator"}>Isolator</MenuItem>
          </Select>
        </FormControl>

        <FormControl size="small">
          <InputLabel id="recipe">Recipe</InputLabel>
          <Select
            labelId="recipe"
            id="recipe-select"
            defaultValue={"recipe 1"}
            label="Recipe"
          >
            <MenuItem value={"recipe 1"}>Recipe 1</MenuItem>
            <MenuItem value={"recipe 2"}>Recipe 2</MenuItem>
            <MenuItem value={"recipe 3"}>Recipe 3</MenuItem>
          </Select>
        </FormControl>

        <FormControl size="small">
          <InputLabel id="analytic">Analytic</InputLabel>
          <Select
            labelId="analytic"
            id="analytic-select"
            value={selectedAnalytic}
            label="Analytics"
            onChange={(e) => {
              setSelectedAnalytic(
                e.target.value as "som" | "decision tree" | "neural network",
              );
            }}
          >
            <MenuItem value={"som"}>SOM</MenuItem>
            <MenuItem value={"decision tree"}>Decision Tree</MenuItem>
            <MenuItem value={"neural network"}>Neural Network</MenuItem>
          </Select>
        </FormControl>
      </MachineViewNavBar>
      {content}
    </>
  );
};
