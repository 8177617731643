import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { Batch } from "../types";
import { Status } from "./useGetHistoricData";
import { z } from "zod";

type AssetComparisonResponse = ThingworxError | BarchartAssetComparison;

const CoefficientInfo = z.object({
  batch_id: z.number(),
  batch_name: z.string(),
  distance_coefficient: z.number(),
  asset_id: z.number(),
  subphase_start_time: z.number(),
  subphase_end_time: z.number(),
  id: z.number(),
  asset_status: Status,
});

type CoefficientInfo = z.infer<typeof CoefficientInfo>;

const BarchartAssetComparison = z.object({
  response: z.literal(true),
  data: z.array(CoefficientInfo),
});

type BarchartAssetComparison = z.infer<typeof BarchartAssetComparison>;

export const useGetAssetDistanceCoefficient = ({
  subphase_name,
  asset_id,
  batches,
}: {
  asset_id: number;
  subphase_name: string;
  batches: Batch[];
}) => {
  const { appKey } = useFirebaseContext();

  //   const { machine } = useMachineContext();
  const batchIds = batches.map((batch) => batch.batchId).sort((a, b) => a - b);

  return useQuery<CoefficientInfo[]>({
    queryKey: [
      "asset-distance-coefficient",
      // phase_id,
      subphase_name,
      asset_id,
      batchIds,
    ],
    queryFn: async () => {
      const response = await FetchClient<
        {
          batch_ids: number[];
          asset_id: number;
          subphase_name: string;
        },
        AssetComparisonResponse
      >({
        payload: {
          batch_ids: batchIds,
          asset_id,
          subphase_name,
        },
        url: "dig.c.lyophilizer_thing/Services/getAssetDistanceCoefficients",
        appKey,
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = BarchartAssetComparison.parse(response);

      return validatedResponse.data;
    },
  });
};
