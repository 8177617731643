import { toast } from "react-hot-toast";
import { Button, Tooltip } from "@mui/material";
import { PowerWord } from "../../types";
import { chatStore } from "../../store/chat-store";
import { usePowerWord } from "../../api/usePowerWord";
import { scrollChat } from "../../utilities/scrollChat";
import { sentryModeStore } from "../../store/sentry-mode-store";
import { useTranslate } from "@/i18n/config";
import { intellectaColors } from "../../utilities/colors";
import { userConfigurationStore } from "../../store/user-configuration-store";

export const PowerwordButtonStandard = ({
  powerWord,
}: {
  powerWord: PowerWord;
}) => {
  const translate = useTranslate();
  const { addMessage, setChatId, setIsAnswering } = chatStore();
  const { version, machine } = userConfigurationStore();
  const { mutateAsync: callPowerWord } = usePowerWord();
  const { resetTimer } = sentryModeStore();

  const onClick = async () => {
    if (!version)
      return toast.error(translate("user_feedback.an_error_occurred"));

    //? in versione 'troubleshooting' devo prima selezionare una macchina, fatta eccezione per 'help'
    if (
      version === "troubleshooting" &&
      !machine &&
      powerWord.endpoint !== "/powerword/help"
    ) {
      return toast.error("No machine selected");
    }

    setIsAnswering(true);
    resetTimer();

    addMessage({
      sender: "User",
      message: powerWord.prompt,
    });

    scrollChat();

    callPowerWord({
      endpoint: powerWord.endpoint,
    })
      .then((response) => {
        addMessage({
          sender: "Intellecta",
          message: response.value,
        });
        setChatId(response.chat_id);
      })
      .finally(() => {
        scrollChat();
        setIsAnswering(false);
        resetTimer();
      });
  };

  return (
    <Tooltip title={powerWord.description}>
      <Button
        fullWidth
        variant="contained"
        onClick={onClick}
        sx={{
          backgroundColor: intellectaColors.powerWordButtonBackground,
          color: "white",
          justifyContent: "flex-start",
          ":hover": {
            backgroundColor: "#ffffff15",
          },
        }}
      >
        {powerWord.label}
      </Button>
    </Tooltip>
  );
};
