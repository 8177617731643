import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { useLyoContext } from "../context/lyo-context";
import { z } from "zod";

export type RuleBreakagesResponse = ThingworxError | RuleBreakagesResult;

const BatchBreakageData = z.object({
  batch_name: z.string(),
  batch_id: z.number(),
  phase_base_id: z.number(),
  phase_name: z.string(),
  negative_assets: z.number(),
  rule_breakage_2_count: z.number(),
  rule_breakage_3_count: z.number(),
});
export type BatchBreakageData = z.infer<typeof BatchBreakageData>;

const RuleBreakagesResult = z.object({
  response: z.literal(true),
  data: z.array(BatchBreakageData),
});
export type RuleBreakagesResult = z.infer<typeof RuleBreakagesResult>;

/**
 * These types describe how the data coming from the api should be mapped to be displayed inside the table
 */

type DataResult = {
  columnsDefinition: ColumnDefinition[];
  data: RuleBreakageRow[];
};

export type RuleBreakageRow = {
  name: string;
  id: number;
  phaseId?: number;
  values: { [key: string]: number };
  innerRows?: RuleBreakageRow[];
};

export type ColumnDefinition = {
  idName: string;
  displayName: string;
};

export const useGetRuleBreakages = () => {
  const { appKey } = useFirebaseContext();

  const [{ filteredBatches }] = useLyoContext();
  const batchId = filteredBatches.map((batch) => batch.batchId).sort();

  return useQuery<DataResult>({
    queryKey: ["rule-breakages", batchId.toString()],
    queryFn: async () => {
      const response = await FetchClient<
        { batch_ids: number[] },
        RuleBreakagesResponse
      >({
        url: "dig.c.lyophilizer_thing/Services/getBreakages",
        appKey,
        payload: {
          batch_ids: batchId,
        },
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = RuleBreakagesResult.parse(response);

      // add here validation before transforming

      const columnsDefinition: ColumnDefinition[] = [
        {
          idName: "rule_1",
          displayName: "Breakages rule #1",
        },
        {
          idName: "rule_2",
          displayName: "Breakages rule #2",
        },
        {
          idName: "rule_3",
          displayName: "Breakages rule #3",
        },
      ];

      return {
        columnsDefinition,
        data: parseBreakagesData(validatedResponse.data),
      };
    },
    refetchInterval: 1000 * 30,
    cacheTime: 0,
    enabled: batchId.length > 0,
  });
};

function parseBreakagesData(data: BatchBreakageData[]): RuleBreakageRow[] {
  const uniquePhases = Array.from(
    new Set(data.map((breakage) => breakage.phase_base_id)),
  );

  let id = 0;

  return uniquePhases.map((phaseId) => {
    const phaseBreakages = data.filter(
      (breakage) => breakage.phase_base_id === phaseId,
    ) as BatchBreakageData[];

    const totalSum = phaseBreakages.reduce(
      (acc, breakage) => acc + breakage.negative_assets,
      0,
    );

    const totalBreakage2Sum = phaseBreakages.reduce(
      (acc, breakage) => acc + breakage.rule_breakage_2_count,
      0,
    );

    const totalBreakage3Sum = phaseBreakages.reduce(
      (acc, breakage) => acc + breakage.rule_breakage_3_count,
      0,
    );

    return {
      name: phaseBreakages[0].phase_name,
      id: id++,
      phaseId: phaseId,
      values: {
        rule_1: totalSum,
        rule_2: totalBreakage2Sum,
        rule_3: totalBreakage3Sum,
      },
      innerRows: phaseBreakages.map((breakage) => ({
        name: breakage.batch_name,
        id: id++,
        values: {
          rule_1: breakage.negative_assets,
          rule_2: breakage.rule_breakage_2_count,
          rule_3: breakage.rule_breakage_3_count,
        },
      })),
    };
  });
}
