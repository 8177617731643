import { PropsWithChildren, createContext, useContext } from "react";
import { useImmerReducer } from "use-immer";
import { StateInfo } from "../api/useGetConfigurableOeeData";
import { StateChangedInfo } from "../types";

interface ConfigurationContextState {
  defaultStates: StateInfo[];
  productionTimeValue: number;
  qualityValue: number;
  performanceValue: number;
  displayingConfiguration: StateInfo[];
  configuredView: StateInfo[];
  notConfiguredView: StateInfo[];
  enableReset: boolean;
  enableCancel: boolean;
  enableSave: boolean;
}

type Action =
  | {
      type: "toggle switch";
      switchedState: StateChangedInfo;
    }
  | {
      type: "reset to default";
    }
  | {
      type: "cancel current edits";
    };

const reducer = (draft: ConfigurationContextState, action: Action) => {
  switch (action.type) {
    case "reset to default": {
      const resettedStates = [...draft.displayingConfiguration].map(
        (_state: StateInfo) => ({
          ..._state,
          isClientRelevance: _state.isEfficiencyRelevance,
        }),
      );

      draft.displayingConfiguration = resettedStates;
      draft.notConfiguredView = resettedStates;
      draft.configuredView = [];
      draft.enableReset = false;
      draft.enableCancel = resettedStates.some(
        (s, index) =>
          s.isClientRelevance != draft.defaultStates[index].isClientRelevance,
      );
      draft.enableSave = true;
      break;
    }

    case "toggle switch": {
      const _defaultStates = [...draft.defaultStates];
      const newDisplayingStates = [...draft.displayingConfiguration].map(
        (s: StateInfo) =>
          s.id === action.switchedState.id
            ? {
                ...s,
                isClientRelevance: action.switchedState.isClientRelevance,
              }
            : s,
      );
      const updatedStates = newDisplayingStates;

      draft.displayingConfiguration = updatedStates;
      draft.notConfiguredView = updatedStates.filter(
        (s) => s.isClientRelevance === s.isEfficiencyRelevance,
      );
      draft.configuredView = updatedStates.filter(
        (s) => s.isClientRelevance != s.isEfficiencyRelevance,
      );

      draft.enableReset = newDisplayingStates.some(
        (s, index) =>
          s.isClientRelevance != _defaultStates[index].isEfficiencyRelevance,
      );
      draft.enableCancel = newDisplayingStates.some(
        (s, index) =>
          s.isClientRelevance != _defaultStates[index].isClientRelevance,
      );
      draft.enableSave = draft.enableCancel;
      break;
    }
    case "cancel current edits": {
      const defaultStates = [...draft.defaultStates];

      draft.displayingConfiguration = defaultStates;
      draft.notConfiguredView = defaultStates.filter(
        (s) => s.isClientRelevance === s.isEfficiencyRelevance,
      );
      draft.configuredView = defaultStates.filter(
        (s) => s.isClientRelevance != s.isEfficiencyRelevance,
      );
      draft.enableReset = draft.displayingConfiguration.some(
        (s) => s.isClientRelevance != s.isEfficiencyRelevance,
      );
      draft.enableCancel = false;
      draft.enableSave = false;
      break;
    }

    default:
      return draft;
  }
};

const ConfigurationContext = createContext<ConfigurationContextState | null>(
  null,
);

const ConfigurationDispatchContext =
  createContext<React.Dispatch<Action> | null>(null);

export const ConfigurationContextProvider = ({
  children,
  defaultStates,
  productionTimeValue,
  qualityValue,
  performanceValue,
}: PropsWithChildren<{
  defaultStates: StateInfo[];
  productionTimeValue: number;
  qualityValue: number;
  performanceValue: number;
}>) => {
  const displayingConfiguration = [...defaultStates];

  const [state, dispatch] = useImmerReducer<ConfigurationContextState, Action>(
    reducer,
    {
      defaultStates: defaultStates,
      productionTimeValue: productionTimeValue,
      qualityValue: qualityValue,
      performanceValue: performanceValue,
      displayingConfiguration: displayingConfiguration,
      configuredView: displayingConfiguration.filter(
        (s) => s.isClientRelevance != s.isEfficiencyRelevance,
      ),
      notConfiguredView: displayingConfiguration.filter(
        (s) => s.isClientRelevance === s.isEfficiencyRelevance,
      ),
      enableReset: displayingConfiguration.some(
        (s) => s.isClientRelevance != s.isEfficiencyRelevance,
      ),
      enableCancel: displayingConfiguration.some(
        (s, index) =>
          s.isClientRelevance != defaultStates[index].isClientRelevance,
      ),
      enableSave: false,
    },
  );

  return (
    <ConfigurationContext.Provider value={state}>
      <ConfigurationDispatchContext.Provider value={dispatch}>
        {children}
      </ConfigurationDispatchContext.Provider>
    </ConfigurationContext.Provider>
  );
};

export const useOeeConfigurationState = () => {
  const context = useContext(ConfigurationContext);
  if (!context) {
    throw new Error(
      "useConfigurationState can be used only within ConfigurationContextProvider",
    );
  }
  return context;
};

export const useOeeConfigurationDispatch = () => {
  const context = useContext(ConfigurationDispatchContext);
  if (!context) {
    throw new Error(
      "useOeeConfigurationDispatch can be used only within ConfigurationContextProvider",
    );
  }
  return context;
};
