import { colors } from "@/styles/colors";

export function convertKpiToArray(kpi: {
  [key: string]: number;
}): [string, number][] {
  const kpiArray = Object.entries(kpi);
  return kpiArray.sort((a, b) => {
    const order = [
      "oee",
      "availability",
      "performance",
      "inactiveTime",
      "downtime",
    ];
    return order.indexOf(a[0]) - order.indexOf(b[0]);
  });
}

export const customBarChartColors = [
  colors.kpi.oee,
  colors.kpi.availability,
  colors.kpi.performance,
  colors.kpi.inactiveTime,
  colors.kpi.downtime,
];
