/* eslint-disable @typescript-eslint/ban-ts-comment */
import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { DurationDrilldown } from "@/components/highcharts/barchart/BarchartDrilldown";
import { Stack, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { Piechart } from "@/components/highcharts/piechart/Piechart";
import { BasicTable } from "@/components/BasicTable";
import { useGetRangeData } from "../../api/useGetRangeData";
import { Batch } from "../../types";
import { LightSkeletonContainer } from "../Layouts/LightSkeletonContainer";
import { AssetComparison } from "../AssetComparison";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";

const assetVariants = {
  open: {
    y: 0,
    opacity: 1,
  },
  closed: {
    y: 10,
    opacity: 0,
  },
};

const containerVariants = {
  open: {
    transition: { staggerChildren: 0.07 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

export const RangeAnalytic = ({
  phase_id,
  batches,
  selectedPhaseName,
}: {
  phase_id: number;
  batches: Batch[];
  selectedPhaseName: string;
}) => {
  const translate = useTranslate();
  const { isLoading, data } = useGetRangeData({
    phase_id,
    batches: batches,
  });

  if (isLoading)
    return (
      <AnimatePresence>
        <Stack
          // @ts-ignore
          component={motion.div}
          variants={containerVariants}
          initial="closed"
          animate="open"
          alignItems="center"
          sx={{ width: "100%", height: "100%" }}
          justifyContent="center"
          gap={4}
          flexDirection="column"
        >
          {Array(3)
            .fill("")
            .map((_, i) => (
              <LightSkeletonContainer
                key={i}
                // @ts-ignore
                component={motion.div}
                variants={assetVariants}
                height={300}
                sx={{
                  transform: "none",
                }}
              />
            ))}
        </Stack>
      </AnimatePresence>
    );

  if (data && data.rangeChartResponse.length === 0) {
    return (
      <Card
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: 200,
        }}
      >
        <Typography variant="h5" sx={{ height: "fit-content" }}>
          {translate("user_feedback.no_data_to_display")}
        </Typography>
      </Card>
    );
  }

  return (
    <>
      <Stack
        key={selectedPhaseName}
        component={motion.div}
        sx={{ width: "100%" }}
        initial={{ opacity: 0, left: 10 }}
        animate={{ opacity: 1, left: 0 }}
        exit={{ opacity: 0 }}
        direction="column"
        gap={2}
        flexWrap="wrap"
      >
        <AssetComparison phase_id={phase_id} phase_name={selectedPhaseName} />

        {data !== undefined
          ? data.rangeChartResponse.map((chart, index) => {
              return (
                <Card key={index}>
                  {chart.type === "barchart" && (
                    <BarChart.LinearBarchart
                      categories={chart.categories}
                      data={chart.data}
                      title={chart.title}
                      uom={chart.uom}
                      thresholds={chart.thresholds || []}
                    />
                  )}
                  {chart.type === "barchart-drilldown" && (
                    <DurationDrilldown
                      data={chart.data}
                      drilldown={chart.drilldown}
                      title={selectedPhaseName + " phase times"}
                    />
                  )}
                  {chart.type === "linechart" && (
                    <LineChart.DurationLine {...chart} />
                  )}
                  {chart.type === "pieChart" && (
                    <Piechart {...chart} version="lyo" />
                  )}
                  {chart.type === "table" && (
                    // @ts-ignore
                    <BasicTable
                      columns={chart.columns}
                      rows={chart.rows}
                      fileName="range_analytic_data"
                    />
                  )}
                </Card>
              );
            })
          : null}
      </Stack>
    </>
  );
};
