/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Loader } from "@/components/Layout/Loader";
import { Stack, Box } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useGetPhaseOverviewHistoricalData } from "../../api/useGetHistoricData";
import { HistoricContainer } from "../../components/AnalyticContainer/HistoricContainer";
import { PhaseOverviewCard } from "../../components/PhaseCard/PhaseCard";
import { RangeAnalytic } from "../../components/RangeAnalytic/RangeAnalytic";
import { useLyoContext } from "../../context/lyo-context";
import { PhaseCardSkeletonLayout } from "../../components/PhaseCard/PhaseCardSkeletonLayout";
import { StringHelpers } from "@/utils/string-helpers";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";

export const PhaseOverview = () => {
  const translate = useTranslate();
  const [state] = useLyoContext();

  const { isLoading, data: downloadedData } = useGetPhaseOverviewHistoricalData(
    { batches: state.batches },
  );

  const [selectedPhase, setSelectedPhase] = useState<{
    phase_id: number;
    phase_name: string;
  } | null>(null);

  if (state.filteredBatches.length === 0)
    return (
      <Card
        initial={{ opacity: 0.5, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "1.5rem",
        }}
      >
        {translate("select_at_least_one_batch")}
      </Card>
    );

  if (isLoading) return <Loader isLoading />;

  if (!downloadedData) return <Loader isLoading />;

  const data = downloadedData.data
    .filter(
      (item) =>
        state.filteredBatches.map((f) => f.batchId).includes(item.batch_id) &&
        item.from < +new Date(),
    )
    .map((item) => ({
      ...item,
      to: item.to > +new Date() ? +new Date() : item.to,
      duration:
        item.to > +new Date()
          ? (+new Date() - +new Date(item.from)) / 1000
          : item.duration,
    }));

  const categories = StringHelpers.uniqueValues(data.map((item) => item.name));

  const agglomeratedCounterata = data
    .filter((state) => state.analyzable && state.from < +new Date())
    .reduce(
      (acc, curr) => {
        const index = acc.findIndex((item) => item.name === curr.name);

        if (index === -1) {
          if (curr.status === "Bad") {
            acc.push({
              phase_id: curr.phase_id,
              name: curr.name,
              total: 1,
              goodAmount: 0,
            });
          } else {
            acc.push({
              phase_id: curr.phase_id,
              name: curr.name,
              total: 1,
              goodAmount: 1,
            });
          }
        } else {
          acc[index].total += 1;

          if (curr.status === "Good") {
            acc[index].goodAmount += 1;
          }
        }
        return acc;
      },
      [] as {
        total: number;
        name: string;
        phase_id: number;
        goodAmount: number;
      }[],
    );

  return (
    <>
      <Stack
        gap={2}
        direction="row"
        flexWrap="wrap"
        sx={{ position: "relative" }}
      >
        <AnimatePresence presenceAffectsLayout mode="wait">
          {isLoading ? (
            <>
              {Array(5)
                .fill("")
                .map((_, i) => (
                  // @ts-ignore
                  <PhaseCardSkeletonLayout
                    key={i}
                    // @ts-ignore
                    component={motion.div}
                    initial={{ opacity: 0, scale: 0.75, x: 20 }}
                    animate={{ opacity: 1, scale: 1, right: "100", x: 0 }}
                    exit={{ opacity: 0 }}
                    variant="rectangular"
                    width={124}
                    height={124}
                  />
                ))}
            </>
          ) : (
            <Stack gap={2} direction="row" flexWrap="wrap">
              {agglomeratedCounterata.map((item) => (
                <PhaseOverviewCard
                  key={item.phase_id}
                  item={item}
                  onSelectedBatch={(id) =>
                    setSelectedPhase((prev) =>
                      prev?.phase_id === id
                        ? null
                        : { phase_id: id, phase_name: item.name },
                    )
                  }
                  active={selectedPhase?.phase_id === item.phase_id}
                />
              ))}
            </Stack>
          )}
        </AnimatePresence>
      </Stack>
      <Box sx={{ marginTop: "1rem" }} />

      <AnimatePresence>
        {selectedPhase ? (
          <RangeAnalytic
            phase_id={selectedPhase.phase_id}
            batches={state.filteredBatches}
            selectedPhaseName={selectedPhase.phase_name}
          />
        ) : (
          <HistoricContainer ganttData={data} categories={categories} />
        )}
      </AnimatePresence>
    </>
  );
};
