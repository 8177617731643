import {
  HighchartsChart,
  Title,
  Tooltip,
  Legend,
  Chart,
  XAxis,
  YAxis,
  ScatterSeries,
} from "react-jsx-highcharts";
import { ChartZoomingOptions } from "highcharts";
import { HighchartsCustomProvider } from "../highcharts";

export class ScatterPlot {
  static Custom({
    title,
    yAxis = [],
    tooltip = { enabled: true },
    legend = { enabled: false },
    xAxisType = "datetime",
    xAxisOptions,
    scrollbarEnabled = true,
    zoomType = "x",
  }: {
    title: string;
    yAxis: {
      uom: string;
      options?: Highcharts.YAxisOptions;
      series?: Highcharts.SeriesScatterOptions[];
      yAxisTitleOptions?: Highcharts.YAxisTitleOptions;
      thresholds?: {
        value: number;
        color: string;
        text: string;
      }[];
    }[];
    legend?:
      | { enabled: false }
      | { enabled: true; options?: Partial<Highcharts.LegendOptions> };
    tooltip?:
      | { enabled: false }
      | { enabled: true; options?: Partial<Highcharts.TooltipOptions> };
    xAxisType?: Highcharts.AxisTypeValue;
    xAxisOptions?: Highcharts.XAxisOptions;
    scrollbarEnabled?: boolean;
    zoomType?: ChartZoomingOptions["type"];
  }) {
    return (
      <HighchartsCustomProvider>
        <HighchartsChart
          accessibility={{ enabled: false }}
          plotOptions={{}}
        >
          <Chart
            backgroundColor={"transparent"}
            type=""
            zooming={{ type: zoomType }}
            style={{
              color: "white",
            }}
          />
          <Title style={{ color: "white" }} align="left">
            {title}
          </Title>

          {tooltip.enabled && <Tooltip shared useHTML {...tooltip.options} />}

          {legend.enabled && (
            <Legend
              itemStyle={{
                color: "white",
              }}
              {...legend.options}
            ></Legend>
          )}
          <XAxis
            labels={{ style: { color: "white" } }}
            scrollbar={{
              enabled: scrollbarEnabled,
              liveRedraw: true,
              barBackgroundColor: "#0D1626",
              barBorderRadius: 7,
              barBorderWidth: 0,
              buttonBackgroundColor: "#0D1626",
              buttonBorderWidth: 1,
              buttonBorderRadius: 7,
              buttonArrowColor: "#0D1626",
              buttonBorderColor: "#3f4961",
              rifleColor: "#0D1626",
              trackBackgroundColor: "#3f4961",
              trackBorderWidth: 1,
              trackBorderRadius: 8,
              trackBorderColor: "none",
            }}
            type={xAxisType}
            {...xAxisOptions}
          ></XAxis>
          {yAxis?.map(
            (
              { uom, series = [], thresholds = [], yAxisTitleOptions, options },
              i,
            ) => (
              <YAxis
                key={i}
                labels={{ style: { color: "white" }, format: "{value} " + uom }}
                showEmpty
                title={yAxisTitleOptions}
                type={"linear"}
                plotLines={thresholds?.map((t) => ({
                  value: t.value,
                  color: t.color,
                  width: 2,
                  zIndex: 5,
                  acrossPanes: true,
                  dashStyle: "Dash",
                  label: {
                    text: t.text,
                    align: "right",
                    x: -10,
                    style: {
                      color: t.color,
                    },
                  },
                }))}
                {...options}
                alignTicks={false}
              >
                {series.map((serie, i) => (
                  <ScatterSeries
                    key={i}
                    {...serie}
                    borderColor="none"
                    marker={{ enabled: true }}
                  />
                ))}
              </YAxis>
            ),
          )}
        </HighchartsChart>
      </HighchartsCustomProvider>
    );
  }
}
