import { BoxPlot } from "@/components/highcharts/boxplot/BoxPlot";
import { Card } from "@/components/Layout/Card";
import { useGetBoxPlotData } from "../api/useGetBoxPlotData";
import { AnalyticContainerSkeleton } from "../../LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { Tabs, Tab } from "@mui/material";
import { useState } from "react";
import { useTranslate } from "@/i18n/config";

const VIEWS = {
  net: 0,
  tare: 1,
};

export const BalanceBoxPlot = () => {
  const [view, setView] = useState(VIEWS.net);
  const { data, isLoading, error } = useGetBoxPlotData();
  const translate = useTranslate();

  if (isLoading) {
    return <AnalyticContainerSkeleton />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  return (
    <Card>
      <Tabs
        value={view}
        aria-label="net and tare view tabs"
        onChange={(_: React.SyntheticEvent, newValue: number) => {
          setView(newValue);
        }}
      >
        <Tab value={VIEWS.net} label={translate("filler.net")} />
        <Tab value={VIEWS.tare} label={translate("filler.tare")} />
      </Tabs>
      {view === VIEWS.net && (
        <BoxPlot
          key={view}
          title={translate("filler.scale_comparison")}
          seriesName={translate("filler.weighings")}
          categories={data.netData.categories}
          boxplotData={data.netData.boxplotData}
          scatterData={data.netData.scatterData}
        />
      )}
      {view === VIEWS.tare && (
        <BoxPlot
          key={view}
          title={translate("filler.scale_comparison")}
          seriesName={translate("filler.weighings")}
          categories={data.tareData.categories}
          boxplotData={data.tareData.boxplotData}
          scatterData={data.tareData.scatterData}
        />
      )}
    </Card>
  );
};
