import { Card } from "@/components/Layout/Card";
import { Stack } from "@mui/material";
import { AnomalyDetectionOverview } from "../../components/AnomalyDetectionOverview";
import { VacuumPumpOverview } from "../../components/VacuumPumpOverview";
import { useLyoContext } from "../../context/lyo-context";
import { useTranslate } from "@/i18n/config";

export const AdvancedOverview = () => {
  const translate = useTranslate();
  const [{ filteredBatches }] = useLyoContext();

  if (!filteredBatches?.length) {
    return (
      <Card
        initial={{ opacity: 0.5, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
        sx={{
          display: "flex",
          height: "10rem",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "1.5rem",
        }}
      >
        {translate("select_at_least_one_batch")}
      </Card>
    );
  }
  return (
    <Stack gap={2}>
      <AnomalyDetectionOverview />
      <VacuumPumpOverview />
    </Stack>
  );
};
