import { createContext, useReducer } from "react";
import { Batch } from "../types";

export const MAX_BATCHES_NUMBER = 5;
export const FILTER_COLOR = "#46c5c7";

type IsolatorContextType = {
  maxDaysCalendar: number;
  batches: Batch[];
  selectedTag: number | null;
};

type Action =
  | {
      type: "SET_MAX_DAYS_CALENDAR";
      item: number;
    }
  | {
      type: "ADD_BATCH";
      item: Batch;
    }
  | {
      type: "REMOVE_BATCH";
      item: Batch;
    }
  | {
      type: "TOGGLE_BATCHES";
      item: Batch[];
    }
  | {
      type: "UPDATE_BATCHES";
      item: Batch[];
    }
  | {
      type: "CLEAR_BATCHES";
    }
  | {
      type: "SET_SELECTED_TAG";
      item: number;
    }
  | {
      type: "REMOVE_SELECTED_TAG";
    };

const reducer: React.Reducer<IsolatorContextType, Action> = (
  state,
  action,
): IsolatorContextType => {
  switch (action.type) {
    case "SET_MAX_DAYS_CALENDAR": {
      return {
        ...state,
        maxDaysCalendar: action.item,
      };
    }

    case "ADD_BATCH": {
      return {
        ...state,
        batches: [...state.batches, action.item],
      };
    }

    case "REMOVE_BATCH": {
      return {
        ...state,
        batches: state.batches.filter((batch) => batch.id !== action.item.id),
      };
    }

    case "TOGGLE_BATCHES": {
      const toggleBatchesClone = [...state.batches];
      action.item.forEach((batch) => {
        const batchIndex = toggleBatchesClone.findIndex(
          (obj) => obj.id === batch.id,
        );
        if (batchIndex > -1) {
          toggleBatchesClone.splice(batchIndex, 1);
        } else {
          toggleBatchesClone.push(batch);
        }
      });
      return { ...state, batches: toggleBatchesClone };
    }

    case "UPDATE_BATCHES": {
      return {
        ...state,
        batches: action.item,
      };
    }

    case "CLEAR_BATCHES": {
      return { ...state, batches: [] };
    }

    case "SET_SELECTED_TAG": {
      return {
        ...state,
        selectedTag: action.item,
      };
    }

    case "REMOVE_SELECTED_TAG": {
      return {
        ...state,
        selectedTag: null,
      };
    }

    default:
      return state;
  }
};

export const IsolatorContext = createContext<IsolatorContextType | null>(null);
export const IsolatorDispatchContext =
  createContext<React.Dispatch<Action> | null>(null);

export const IsolatorContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, {
    maxDaysCalendar: 30,
    batches: [],
    selectedTag: null,
  });

  return (
    <IsolatorContext.Provider
      value={{
        maxDaysCalendar: state.maxDaysCalendar,
        batches: state.batches,
        selectedTag: state.selectedTag,
      }}
    >
      <IsolatorDispatchContext.Provider value={dispatch}>
        {children}
      </IsolatorDispatchContext.Provider>
    </IsolatorContext.Provider>
  );
};
