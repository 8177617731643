import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { ThingworxError } from "src/types/error";
import { TimeSelection, useTimeSelection } from "@/store/useTimeSelection";

const exportData = z.array(
  z.record(z.union([z.number(), z.string(), z.boolean()])),
);

export type ExportData = z.infer<typeof exportData>;

const exportResult = z.object({
  response: z.literal(true),
  data: exportData,
});

type ExportResult = z.infer<typeof exportResult>;

type Result = ThingworxError | ExportResult;

type Payload = {
  dateStart?: number;
  dateEnd?: number;
  timeSelection: TimeSelection;
  machineName: string;
  categories: string[];
};

export const useGetExport = ({
  machineName,
  categories,
}: {
  machineName: string;
  categories: string[];
}) => {
  const { appKey } = useFirebaseContext();
  const { dates, timeSelection } = useTimeSelection();

  return useQuery<ExportData>({
    queryKey: [
      "get-data",
      machineName,
      timeSelection,
      JSON.stringify(categories),
      JSON.stringify(dates),
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, Result>({
        url: "dig.c.dataInsights_thing/services/getData",
        appKey,
        payload:
          timeSelection === "Custom"
            ? {
                machineName,
                categories,
                timeSelection,
                dateStart: dates.dateStart.toMillis(),
                dateEnd: dates.dateEnd.toMillis(),
              }
            : { machineName, categories, timeSelection },
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }
      return response.data;
    },
  });
};
