import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";

type Payload = {
  who: string;
  id: string;
  machineName: string;
};

export const useSetWho = () => {
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, who }: { id: string; who: string }) => {
      await FetchClient<Payload, null>({
        appKey,
        payload: {
          machineName: machine!.machine,
          id,
          who,
        },
        url: "dig.c.acceptanceTest_thing/Services/setWho",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["acceptance-test-data", machine!.machine],
      });
    },
  });
};
