/* eslint-disable import/default */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import HighchartsReactOfficial from "highcharts-react-official";
import Highcharts from "highcharts";
import addHighchartsMore from "highcharts/highcharts-more";
import addSolidGauge from "highcharts/modules/solid-gauge";
import addAccessibility from "highcharts/modules/accessibility";
import addNoDataToDisplay from "highcharts/modules/no-data-to-display";
import { Box, Fade } from "@mui/material";

addHighchartsMore(Highcharts);
addSolidGauge(Highcharts);
addAccessibility(Highcharts);
addNoDataToDisplay(Highcharts);

interface Props {
  title: string;
  seriesName: string;
  seriesValue: number;
  seriesColor: string;
  paneColor: string;
  chartHeight?: number | string;
  tooltipOptions?: Highcharts.TooltipOptions;
}

export const SolidGaugeWithPane = ({
  title,
  seriesName,
  seriesValue,
  seriesColor,
  paneColor,
  chartHeight,
  tooltipOptions,
}: Props) => {
  return (
    <Fade in easing="ease-in">
      <Box>
        <HighchartsReactOfficial
          highcharts={Highcharts}
          options={{
            chart: {
              type: "solidgauge",
              backgroundColor: "transparent",
              style: {
                fontFamily: "Montserrat, Helvetica Neue,Arial, sans-serif",
                color: "white",
              },
              height: chartHeight,
              margin: [0, 0, 0, 0],
              spacing: [0, 0, 0, 0],
            },

            title: {
              text: title,
              style: {
                color: "white",
              },
            },

            tooltip: {
              enabled: true,
              valueSuffix: "%",
              ...tooltipOptions,
            },

            pane: {
              startAngle: 0,
              endAngle: 360,
              background: [
                {
                  outerRadius: "100%",
                  innerRadius: "80%",
                  backgroundColor: paneColor,
                  borderWidth: 0,
                },
              ],
            },

            yAxis: {
              min: 0,
              max: 100,
              lineWidth: 0,
              tickPositions: [],
            },

            credits: { enabled: false },

            plotOptions: {
              solidgauge: {
                dataLabels: {
                  enabled: true,
                  y: -10,
                  borderColor: "transparent",
                  color: "white",
                  padding: 0,
                  style: {
                    textShadow: false,
                    fontSize: ".75rem",
                  },
                  format: "{y}%",
                },
                linecap: "round",
                stickyTracking: false,
                rounded: true,
              },
            },

            series: [
              {
                name: seriesName,
                data: [
                  {
                    color: seriesColor,
                    radius: "100%",
                    innerRadius: "80%",
                    y: seriesValue,
                  },
                ],
              },
            ],
          }}
        />
      </Box>
    </Fade>
  );
};
