import { Box } from "@mui/material";
import { ConditionMonitoringAnalyticsSelection } from "../components/Monitoring/ConditionMonitoringAnalyticsSelection";
import { useState } from "react";
import { ConditionMonitoringAnalytics } from "../components/Monitoring/ConditionMonitoringAnalytics";

export const ConditionMonitoring = () => {
  const [selectedAnalytics, setSelectedAnalytics] = useState<string[]>([]);

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      <ConditionMonitoringAnalyticsSelection
        setSelectedAnalytics={setSelectedAnalytics}
      />
      <ConditionMonitoringAnalytics analytics={selectedAnalytics} />
    </Box>
  );
};
