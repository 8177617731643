import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { ShiftListResult } from "../useGetShiftList";

const LastSatResult = ShiftListResult;
type LastSatResult = ShiftListResult;
type LastSatResponse = ThingworxError | LastSatResult;

export const useGetLastSats = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  return useQuery<LastSatResult>({
    queryKey: ["last-five-sat", machine!.machine],
    queryFn: async () => {
      const response = await FetchClient<
        { machineName: string },
        LastSatResponse
      >({
        appKey,
        payload: {
          machineName: machine!.machine,
        },
        url: "dig.c.acceptanceTest_thing/Services/getLastFiveSat",
      });
      if (!response.response) throw new Error(response.errorString);
      return LastSatResult.parse(response);
    },
  });
};
