import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { QueryBuilder } from "@/utils/query-builder";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { useLanguage } from "@/i18n/config";

type MaterialsResponse = ThingworxError | MaterialsResult;

const MaterialInfo = z.object({
  lackDuration: z.number(),
  state: z.enum(["End", "Start"]),
  timestamp: z.number(),
});

export type MaterialInfo = z.infer<typeof MaterialInfo>;

/**
 * return the data for the upper tables that has the red and green circles
 */

const MaterialsResult = z.object({
  duration: z.number(),
  data: z.array(MaterialInfo),
  response: z.literal(true),
  count: z.number(),
});

type MaterialsResult = z.infer<typeof MaterialsResult>;

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
  dateEnd?: DateTime;
  dateStart?: DateTime;
  language_id: string;
};

export const useGetMaterialsList = () => {
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();

  const { timeSelection, dates } = useTimeSelection();
  const language = useLanguage();

  const query = QueryBuilder.buildWithCondition({
    baseQuery: ["materials-list", machine?.machine, timeSelection, language],
    condition: timeSelection === "Custom",
    query: [dates.dateStart, dates.dateEnd],
  });

  const payload: Payload = {
    machineName: machine?.machine || "",
    timeSelection,
    language_id: language,
  };

  return useQuery<MaterialsResult>({
    queryKey: query,
    queryFn: async () => {
      const response = await FetchClient<Payload, MaterialsResponse>({
        appKey,
        payload:
          timeSelection === "Custom"
            ? { ...payload, dateStart: dates.dateStart, dateEnd: dates.dateEnd }
            : payload,
        url: "dig.c.material_thing/Services/getData",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return MaterialsResult.parse(response);
    },
  });
};
