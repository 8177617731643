import { useState } from "react";
import { LinearProgress, Stack } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";
import { MotorCard } from "../components/Layouts/MotorCard";
import { SummarySpecific } from "./SummarySpecific";
import { SummaryGeneric } from "./SummaryGeneric";
import { useGetDataSummaryGeneric } from "../api/useGetDataSummaryGeneric";
import { BatchRecipeSelection } from "../components/Selections/BatchRecipeSelection";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";

export const Summary = () => {
  const { data, isLoading, error, isFetching } = useGetDataSummaryGeneric();
  const [selectedMotorId, setSelectedMotorId] = useState<number | null>(null);
  const [selectedMotorName, setSelectedMotorName] = useState<string | null>(
    null,
  );
  const translate = useTranslate();

  if (isLoading) {
    return (
      <Stack gap={1}>
        <SkeletonCard height={180} />
        <SkeletonCard height={300} />
      </Stack>
    );
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  return (
    <Stack gap={1} pt="1rem">
      <BatchRecipeSelection />
      <Card
        sx={{
          display: "flex",
          gap: "1rem",
          overflowX: "auto",
        }}
      >
        {data.cardData.map((motor) => (
          <MotorCard
            key={motor.motorId}
            name={motor.motorName}
            id={motor.motorId}
            description={motor.description}
            pieData={motor.evaluations}
            active={motor.motorId === selectedMotorId}
            setMotorId={setSelectedMotorId}
            setMotorName={setSelectedMotorName}
            healthStatus={motor.healthState}
            lastStatus={motor.lastStatus}
            totalFrames={motor.totalFrames}
          />
        ))}
      </Card>
      {selectedMotorId && selectedMotorName ? (
        <SummarySpecific
          motorId={selectedMotorId}
          motorName={selectedMotorName}
        />
      ) : (
        <SummaryGeneric />
      )}
      {isFetching && (
        <LinearProgress
          sx={{
            position: "fixed",
            width: "80%",
            margin: "auto",
            bottom: 10,
            left: 64,
            right: 0,
            borderRadius: "1000px",
          }}
        />
      )}
    </Stack>
  );
};
