import { SmallAnalyticContainerLayout } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { Card } from "@/components/Layout/Card";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  MAX_SHUTTLES_NUMBER,
  useAcoposContext,
  useAcoposDispatchContext,
} from "../../context/acopos-context";
import { useGetShuttles } from "../../api/Common/useGetShuttles";
import { Shuttle } from "../../types";
import { useTranslate } from "@/i18n/config";
import toast from "react-hot-toast";
import { useGetDeviancesShuttles } from "../../api/Monitoring/useGetDeviancesShuttles";
import { useEffect } from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const getNameFromId = (
  shuttleList: Shuttle[],
  id: number,
  shuttleTranslation: string,
): string => {
  const foundShuttle = shuttleList.find((shuttle) => shuttle.id === id);
  if (foundShuttle) return foundShuttle.name;
  return `${shuttleTranslation} ${id}`;
};

export const SelectShuttlesStandard = () => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetShuttles();

  if (isLoading) {
    return <SmallAnalyticContainerLayout sx={{ height: 80 }} />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  return <SelectShuttles data={data.shuttles} />;
};

export const SelectShuttlesDeviances = () => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetDeviancesShuttles();

  if (isLoading) {
    return <SmallAnalyticContainerLayout sx={{ height: 80 }} />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  return <SelectShuttles data={data.shuttles} />;
};

const SelectShuttles = ({ data }: { data: Shuttle[] }) => {
  const translate = useTranslate();
  const { shuttles } = useAcoposContext();
  const dispatch = useAcoposDispatchContext();

  useEffect(() => {
    shuttles.forEach((shuttleId) => {
      const isInCurrentSelection = data.find((el) => el.id === shuttleId);
      if (!isInCurrentSelection)
        dispatch({ type: "REMOVE_SINGLE_SHUTTLE", item: shuttleId });
    });
  }, []);

  const sortedShuttles = data.sort((a, b) => a.id - b.id);
  const shuttleIds: number[] = sortedShuttles.map((shuttle) => shuttle.id);

  return (
    <Card>
      <Autocomplete
        multiple
        options={shuttleIds}
        disableCloseOnSelect
        getOptionLabel={(item) =>
          getNameFromId(sortedShuttles, item, translate("acopos.shuttle"))
        }
        value={shuttles}
        onChange={(_, value, reason) => {
          switch (reason) {
            case "clear":
              dispatch({ type: "CLEAR_SHUTTLES" });
              break;

            case "removeOption":
              if (!Array.isArray(value)) return;
              if (!value) return;

              dispatch({ type: "REMOVE_SHUTTLE", item: value });
              break;

            case "selectOption":
              if (!Array.isArray(value)) return;
              if (!value) return;

              if (shuttles.length >= MAX_SHUTTLES_NUMBER) {
                toast.error(
                  translate("user_feedback.max_n_selected", {
                    max: MAX_SHUTTLES_NUMBER,
                  }),
                );
              } else {
                dispatch({
                  type: "ADD_SHUTTLE",
                  item: value[value.length - 1],
                });
              }
              break;

            default:
              break;
          }
        }}
        renderOption={(props, option) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={shuttles.includes(option)}
            />
            {getNameFromId(sortedShuttles, option, translate("acopos.shuttle"))}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={translate("acopos.shuttle")}
            placeholder={translate("acopos.shuttle")}
          />
        )}
      />
    </Card>
  );
};
