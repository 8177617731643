import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

/** TODO: chavi in Pascal --> camel? */
export const ShiftListResult = z.object({
  Result: z.array(
    z.object({
      TimeZoneDifference: z.number(),
      DataStartTimeLapse: z.number(),
      Id: z.number(),
      DataEnd: z.number(),
      TimeLapse: z.string(),
    }),
  ),
  response: z.literal(true),
});

export type ShiftListResult = z.infer<typeof ShiftListResult>;
type ShiftListResponse = ThingworxError | ShiftListResult;
export const useGetShiftList = () => {
  const { machine } = useMachineContext();
  const { dates } = useTimeSelection();
  const { appKey } = useFirebaseContext();
  return useQuery<ShiftListResult>({
    queryKey: ["shift-list", machine!.machine, dates.dateStart, dates.dateEnd],
    queryFn: async () => {
      const response = await FetchClient<
        { dateStart: number; dateEnd: number; machineName: string },
        ShiftListResponse
      >({
        appKey,
        payload: {
          dateStart: dates.dateStart.toMillis(),
          dateEnd: dates.dateEnd.toMillis(),
          machineName: machine!.machine,
        },
        url: "dig.c.acceptanceTest_thing/Services/getList",
      });
      if (!response.response) throw new Error(response.errorString);
      return ShiftListResult.parse(response);
    },
  });
};
