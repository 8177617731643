import { TimeSelection } from "@/store/useTimeSelection";
import { RequestPayload } from "../api/useGetAlarmsColumnData";

export function queryKeyBuilder({
  queryName,
  payload,
  timeSelection,
}: {
  queryName: string;
  payload: RequestPayload;
  timeSelection: TimeSelection;
}) {
  if (timeSelection === "Custom") {
    const { dateStart, dateEnd } = payload;
    return [queryName, timeSelection, dateStart, dateEnd];
  }

  return [queryName, timeSelection];
}
