import { PropsWithChildren } from "react";
import { AdapterLuxon } from "@mui/x-date-pickers-pro/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { dateTimeFormatStore } from "@/store/useDateTimeFormat";
import { deDE, enUS, esES, itIT } from "@mui/x-date-pickers/locales";
import { useLanguage } from "@/i18n/config";

export const DatepickerLanguageProvider = ({ children }: PropsWithChildren) => {
  const { dateFormat } = dateTimeFormatStore();
  const language = useLanguage();
  return (
    <LocalizationProvider
      dateAdapter={AdapterLuxon}
      adapterLocale={dateFormat}
      localeText={
        language === "en"
          ? enUS.components.MuiLocalizationProvider.defaultProps.localeText
          : language === "it"
          ? itIT.components.MuiLocalizationProvider.defaultProps.localeText
          : language === "es"
          ? esES.components.MuiLocalizationProvider.defaultProps.localeText
          : language === "de"
          ? deDE.components.MuiLocalizationProvider.defaultProps.localeText
          : enUS.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      {children}
    </LocalizationProvider>
  );
};
