import { ImputationCategory, ImputationCategoriesData } from "../../types";
import { Grid, Typography } from "@mui/material";
import { ImputationSubCategoryCell } from "./ImputationSubCategoryCell";
import { ImputationCategoryCell } from "./ImputationCategoryCell";

export const ImputationSubCategoryRow = ({
  data,
  categories,
}: {
  data: ImputationCategoriesData;
  categories: ImputationCategory[];
}) => {
  return (
    <Grid container columns={12} alignItems="center" paddingY={2}>
      <Grid item xs={2}>
        <Typography>{data.subCategoryId}</Typography>
      </Grid>
      <Grid item xs={5}>
        <ImputationSubCategoryCell
          id={data.subCategoryId}
          value={data.subCategory}
          isEditable={data.isEditable}
        />
      </Grid>
      <Grid item xs={5}>
        <ImputationCategoryCell
          categoryId={data.categoryId}
          subCategoryId={data.subCategoryId}
          categories={categories}
          isEditable={data.isEditable}
        />
      </Grid>
    </Grid>
  );
};
