import { NoveltyAnomalyAnalytic } from "@/features/Acopostrak/types";
import { useTranslate } from "@/i18n/config";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
} from "@mui/material";
import { useEffect } from "react";

interface AnalyticSelectionProps {
  analyticsList: NoveltyAnomalyAnalytic[];
  setAnalyticId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const AnalyticSelection = ({
  analyticsList,
  setAnalyticId,
}: AnalyticSelectionProps) => {
  const translate = useTranslate();

  useEffect(() => {
    setAnalyticId(analyticsList[0].id);
  }, []);

  return (
    <Stack width="100%" gap={2} padding={2}>
      {/* <Typography>{translate("analytics_other")}</Typography> */}
      <FormControl>
        <FormLabel id="demo-controlled-radio-buttons-group">
          {translate("analytics_other")}
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          defaultValue={analyticsList[0].id}
          onChange={(_, val) => setAnalyticId(parseInt(val))}
        >
          {analyticsList.map((analytic) => (
            <FormControlLabel
              key={analytic.id}
              value={analytic.id}
              control={<Radio />}
              label={analytic.clientName}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};
