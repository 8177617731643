import {
  DialogTitle,
  DialogContent,
  Stack,
  DialogActions,
  Button,
  Input,
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Divider,
  TextField,
} from "@mui/material";
import { Fragment, useMemo } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  useCreateChartContext,
  useCreateChartDispatch,
} from "../../context/create-chart-context";
import { useGetVariables } from "../../api/useGetVariables";
import { generatePreviewData } from "../../utils/generate-preview-data";
import { AggregableCustomChart, CustomChart } from "../CustomChart";
import { useCreateChart } from "../../api/useCreateChart";
import { Variable } from "../../types/variable";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";

export const SelectVariables = () => {
  const translate = useTranslate();
  const { closeModal } = useCreateChartContext();
  const {
    chart_type,
    machine,
    chart_title,
    variables: selected_variables,
  } = useCreateChartContext();

  const dispatch = useCreateChartDispatch();

  const allowConfirm = selected_variables.length > 0 && chart_title;

  const { mutate: createChart } = useCreateChart();

  const { data: variables = [] } = useGetVariables({
    chart_type: chart_type!,
    machine: machine!,
  });

  const onConfirmHandler = () => {
    const variables_id = selected_variables.map((variable) => variable.id);

    if (!chart_type || !chart_title || !variables_id.length) {
      return;
    }

    createChart(
      {
        chart_name: chart_title,
        chart_type: chart_type,
        variables: variables_id,
      },
      {
        onSuccess() {
          closeModal();
        },
      },
    );
  };

  const previewData = useMemo(
    () => generatePreviewData(selected_variables),
    [selected_variables],
  );

  return (
    <>
      <DialogTitle>{translate("custom_charts.select_variables")}</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <VariablePicker variables={variables} />
          <Input
            type="text"
            placeholder={translate("custom_charts.chart_title")}
            onChange={(e) =>
              dispatch({ type: "set chart title", title: e.target.value })
            }
          />

          <Box
            sx={{
              marginTop: 2,
            }}
          />
          <Typography variant="body1">{translate("preview")}</Typography>
          <Typography variant="body2">
            {translate("custom_charts.chart_preview_description")}{" "}
          </Typography>

          {chart_type === "timeseries" ? (
            <Card>
              <CustomChart variables={previewData} title={chart_title ?? ""} />
            </Card>
          ) : chart_type === "aggregated" ? (
            <Card>
              <AggregableCustomChart
                variables={previewData}
                title={chart_title ?? ""}
              />
            </Card>
          ) : null}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ color: "white" }}
          variant="contained"
          color="secondary"
          onClick={() => dispatch({ type: "back" })}
        >
          {translate("actions.back")}
        </Button>
        <Button
          sx={{ color: "white" }}
          variant="contained"
          color="success"
          onClick={onConfirmHandler}
          disabled={!allowConfirm}
        >
          {translate("custom_charts.create_chart")}
        </Button>
      </DialogActions>
    </>
  );
};

const VariablePicker = ({ variables }: { variables: Variable[] }) => {
  const translate = useTranslate();
  const dispatch = useCreateChartDispatch();

  return (
    <Autocomplete
      multiple
      id="multiple-variable-input"
      options={variables.sort((a, b) => -b.category.localeCompare(a.category))}
      groupBy={(option) => option.category}
      getOptionLabel={(option) => option.display_name}
      onChange={(_, newValue) => {
        dispatch({
          type: "pick variables",
          variables: newValue,
        });
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={translate("variable_other")}
          placeholder={translate("placeholders.pick_a_variable")}
        />
      )}
      disableCloseOnSelect
      renderGroup={(params) => (
        <Fragment key={params.key}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{params.group}</Typography>
            </AccordionSummary>
            <AccordionDetails>{params.children} </AccordionDetails>
          </Accordion>
          <Divider />
        </Fragment>
      )}
    />
  );
};
