import { useState } from "react";
import { Box, Checkbox, FormControlLabel, Stack } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";
import { PhaseCard } from "../../components/Monitoring/PhaseCard";
import { useGetDescriptiveAnalyticsList } from "../../api/Monitoring/useGetDescriptiveAnalyticsList";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { Phase } from "../../types";
import { DescriptiveRawDataSelection } from "../../components/Monitoring/DescriptiveRawDataSelection";
import { DescriptiveRawDataChart } from "../../components/Monitoring/DescriptiveRawDataChart";

export const DescriptiveRawData = () => {
  const translate = useTranslate();
  const [allowMultiBatch, setAllowMultiBatch] = useState(false);
  const [selectedPhase, setSelectedPhase] = useState<Phase | null>(null);
  const [selectedPhaseId, setSelectedPhaseId] = useState<number | null>(null);
  const [selectedVariable, setSelectedVariable] = useState<number | null>(null);
  const { data, isLoading, error } = useGetDescriptiveAnalyticsList();

  const handleChangeMultiBatch = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setAllowMultiBatch(event.target.checked);
  };

  if (isLoading)
    return (
      <Box display="flex" gap={1}>
        {Array(4)
          .fill("")
          .map((_, i) => (
            <SkeletonCard key={i} height={180} width={180} />
          ))}
      </Box>
    );
  if (error) return <Card>{translate("user_feedback.an_error_occurred")}</Card>;
  if (!data) return <Card>{translate("user_feedback.no_data")}</Card>;

  const onSelectPhase = (phase: Phase) => {
    if (phase === selectedPhase) {
      setSelectedPhase(null);
      setSelectedPhaseId(null);
    } else {
      setSelectedPhase(phase);
      setSelectedPhaseId(
        data.analytics.descriptive_raw_data.find((el) => el.phase === phase)
          ?.phaseId || null,
      );
    }
    setSelectedVariable(null);
  };

  return (
    <Stack gap={2}>
      <Box display="flex" gap={1}>
        {data.analytics.descriptive_raw_data.map(({ phase, evaluations }) => (
          <PhaseCard
            key={`${phase}-card-descriptive-raw-data`}
            phase={phase}
            active={phase === selectedPhase}
            onSelectedPhase={onSelectPhase}
            goodBatches={evaluations.good}
            badBatches={evaluations.bad}
          />
        ))}
      </Box>
      {selectedPhase && (
        <Box display="flex" gap={1}>
          <Stack gap={1} width={320}>
            <Card>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allowMultiBatch}
                    onChange={handleChangeMultiBatch}
                  />
                }
                label="Multi-batch comparison"
              />
            </Card>
            <Stack display="flex" gap={1}>
              <DescriptiveRawDataSelection
                variables={
                  data.analytics.descriptive_raw_data.find(
                    (phase) => phase.phase === selectedPhase,
                  )?.analytics ?? []
                }
                selectedVariable={selectedVariable}
                onSelectVariable={(value) => setSelectedVariable(value)}
              />
            </Stack>
          </Stack>
          <Stack gap={2} width="calc(100% - 320px)">
            {selectedVariable && selectedPhaseId && (
              <DescriptiveRawDataChart
                analyticId={selectedVariable}
                phaseId={selectedPhaseId}
                allowMultiBatch={allowMultiBatch}
              />
            )}
          </Stack>
        </Box>
      )}
    </Stack>
  );
};
