import { HeatmapChart } from "./HeatmapChart";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { useGetShuttlesHeatMap } from "@/features/Acopostrak/api/Summary/useGetShuttlesHeatMap";
import { parseHeatmapData } from "@/features/Acopostrak/utils/parseHeatmapData";

export const ShuttlesHeatmap = () => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetShuttlesHeatMap();
  if (isLoading) return <SkeletonCard height={180} />;
  if (error) return <Card>{translate("user_feedback.an_error_occurred")}</Card>;
  if (!data) return <Card>{translate("user_feedback.no_data")}</Card>;

  const { seriesData, xCategories, yCategories } = parseHeatmapData({
    data: data.data,
  });

  return (
    <HeatmapChart
      title={`${translate("acopos.shuttle_other")} / ${translate(
        "acopos.run_other",
      )}`}
      xCategories={xCategories}
      yCategories={yCategories}
      seriesData={seriesData}
    />
  );
};
