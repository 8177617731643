import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { ImputationStatsResponse, ImputationStatsResult, imputationStatsResult } from "../types";
import { useLanguage } from "@/i18n/config";

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
  language_id: string;
};

export const useGetImputationStatsData = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const { timeSelection } = useTimeSelection();
  const language = useLanguage();

  return useQuery<ImputationStatsResult>({
    queryKey: ["imputation-stats-data", machine!.machine, timeSelection, language],
    queryFn: async () => {
      const response = await FetchClient<Payload, ImputationStatsResponse>({
        appKey,
        payload: {
          machineName: machine!.machine,
          timeSelection,
          language_id: language,
        },
        url: "dig.c.stopImputation_thing/Services/getImputationStatsData",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = imputationStatsResult.parse(response);

      return validatedResponse;
    },
  });
};
