import JSZip from "jszip";
import { z } from "zod";
import { REPORT_AUTH_TOKEN, REPORT_ENDPOINT } from "../api/report-endpoint";

export const ReportResult = z.object({
  file_content: z.object({
    data: z.array(z.number()),
    type: z.literal("Buffer"),
  }),
  response: z.literal(true),
});
export type ReportResult = z.infer<typeof ReportResult>;

export async function getReportData(filePath: string) {
  const payload = {
    filePath,
  };
  try {
    const res = await fetch(`${REPORT_ENDPOINT}/get-file`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Basic ${REPORT_AUTH_TOKEN}`,
      },
      body: JSON.stringify(payload),
    });
    const response = (await res.json()) as ReportResult;
    const validatedResponse = ReportResult.parse(response);
    displayReport(validatedResponse.file_content.data);
  } catch (error) {
    console.error("Something went wrong:", error);
  }
}
export function displayReport(bufferData: Array<number>) {
  new JSZip()
    .loadAsync(new Uint8Array(bufferData))
    .then((zipObject) => {
      const htmlFile = Object.values(zipObject.files)[0];
      return htmlFile.async("string");
    })
    .then((content) => {
      const linkBlob = URL.createObjectURL(
        new Blob([content], { type: "text/html" }),
      );
      window.open(linkBlob, "_blank");
      return;
    });
}
