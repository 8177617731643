import { create } from "zustand";

type PerformanceViewState = {
  view: "standard" | "configurable oee";
  toggleView: () => void;
};

export const performanceViewStore = create<PerformanceViewState>((set) => ({
  view: "standard",
  toggleView: () =>
    set((state) => ({
      view: state.view === "standard" ? "configurable oee" : "standard",
    })),
}));
