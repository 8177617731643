import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { Run, contextInfo, evaluation } from "../../types";
import { useAcoposContext } from "../../context/acopos-context";

export type GetNoveltyAnomalyEvaluationResponse =
  | ThingworxError
  | GetNoveltyAnomalyEvaluationResult;

const anomalyPoint = z.object({
  timestamp: z.number(),
  status: evaluation,
  runId: z.number(),
  lapId: z.number(),
  segmentId: z.number(),
  shuttleId: z.number(),
});

export type AnomalyPoint = z.infer<typeof anomalyPoint>;

const noveltyPoint = z.object({
  timestamp: z.number(),
  hasNovelty: z.boolean(),
  runId: z.number(),
  lapId: z.number(),
  segmentId: z.number(),
  shuttleId: z.number(),
});

export type NoveltyPoint = z.infer<typeof noveltyPoint>;

const resSchema = z.union([
  z.object({
    analyticName: z.string(),
    analyticId: z.number(),
    analyticType: z.literal("anomaly"),
    chartType: z.literal("scatter"),
    metricType: z.literal("timestamp"),
    data: z.array(anomalyPoint),
    contextInfo: contextInfo,
  }),
  z.object({
    analyticName: z.string(),
    analyticId: z.number(),
    analyticType: z.literal("novelty"),
    chartType: z.literal("scatter"),
    metricType: z.literal("timestamp"),
    data: z.array(noveltyPoint),
    contextInfo: contextInfo,
  }),
]);

export type ResSchema = z.infer<typeof resSchema>;

const noveltyAnomalyEvaluation = z.object({
  analyticName: z.string(),
  data: z.array(z.array(z.number())),
  contextInfo: contextInfo,
});

export type NoveltyAnomalyEvaluation = z.infer<typeof noveltyAnomalyEvaluation>;

type Payload = {
  machineName: string;
  runs: Run[];
  segment: number;
  shuttles: number[];
  analytics: number[];
  // noveltyAnalytics: number[];
  // anomalyAnalytics: number[];
};

const getNoveltyAnomalyEvaluationResult = z.object({
  response: z.literal(true),
  data: z.array(resSchema),
});

export type GetNoveltyAnomalyEvaluationResult = z.infer<
  typeof getNoveltyAnomalyEvaluationResult
>;

export const useGetNoveltyAndAnomalyEvaluation = ({
  noveltyAnalytics,
  anomalyAnalytics,
}: {
  noveltyAnalytics: number[];
  anomalyAnalytics: number[];
}) => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const { runs, activeSegment, shuttles } = useAcoposContext();
  const analytics = noveltyAnalytics.concat(anomalyAnalytics);

  return useQuery<GetNoveltyAnomalyEvaluationResult>({
    queryKey: [
      "novelty-anomaly-analytics-evaluation",
      machine!.machine,
      JSON.stringify(runs),
      activeSegment,
      shuttles,
      JSON.stringify(analytics),
    ],
    queryFn: async () => {
      const response = await FetchClient<
        Payload,
        GetNoveltyAnomalyEvaluationResponse
      >({
        appKey,
        payload: {
          machineName: machine!.machine,
          runs,
          segment: activeSegment!,
          shuttles,
          analytics,
        },
        // url: "dig.c.acopostrak_thing/Services/getNoveltyAndAnomalyEvaluation",
        url: "dig.c.acoposTrack_thing/Services/getNoveltyAndAnomalyEvaluation",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse =
        getNoveltyAnomalyEvaluationResult.parse(response);

      return validatedResponse;
    },
  });
};
