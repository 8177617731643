import { useState } from "react";
import { z } from "zod";
import { Box, Button, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Link, Navigate, useLocation } from "react-router-dom";
import { OverviewNavBar } from "@/pages/Overview/layout/OverviewNavBar/OverviewNavBar";
import { PlantDesign } from "../../components/PlantDesign";
import { LineEfficiencyTrend } from "../../components/LineEfficiencyTrend";
import { Card } from "@/components/Layout/Card";
import { GaugeLayout } from "@/features/MachineDetail/components/GaugeLayout";
import { ArrowBackOutlined } from "@mui/icons-material";
import { NavButton } from "@/components/NavButton";
import { Line } from "../../api/useGetUserProductionLines";
import { RadialDoubleBarGaugeLayout } from "@/features/BatchNavigator/components/RadialGaugeLayout";
import { colors } from "@/styles/colors";
import { TotalProductsSummary } from "./components/TotalProductsSummary";
import { SelectTimeSelection } from "@/components/SelectTimeSelection";
import { EditManualLineConfigurationButton } from "../../components/EditManualLineConfigurationButton/EditManualLineConfigurationButton";
import { useGetLineConfiguration } from "./api/useGetLineConfiguration";
import { ProductsHistogram } from "./components/ProductsHistogram";
import { LineProductsProjection } from "../../components/LineProductsProjection";
import { useGetLineMachinesEfficiencies } from "./api/useGetLineMachineEfficiencies";
import { useGetLineProductsProjection } from "./api/useGetLineProductsProjection";
import { LineConfig } from "./components/LineConfig";
import { DisconnectedCategory } from "@/components/CustomIcons/disconnectedCategory";
import { MaintenanceCategory } from "@/components/CustomIcons/maintenanceCategory";
import { NotProducingCategory } from "@/components/CustomIcons/notProducingCategory";
import { ProductionCategory } from "@/components/CustomIcons/productionCategory";
import { TogglePerformanceView } from "../../components/TogglePerformanceView";
import { ConfigurationModal } from "./components/ConfigurationModal/ConfigurationModal";
import { LineTopAlarmsLive } from "./components/LineTopAlarms";
import { LineLastProductionsLive } from "./components/LineLastProductions";
import { Loader } from "@/components/Layout/Loader";
import { EditAutomaticLineConfigurationButton } from "../../components/EditAutomaticLineConfigurationButton/EditAutomaticLineConfigurationButton";
import { AlternativeViewSwitch } from "../../components/AlternativeViewSwitch";
import { LineStatesLogHandler } from "./components/LineStatesLogHandler";
import { Translations, useTranslate } from "@/i18n/config";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";

const PageState = z.object({
  line: Line,
  view_mode: z.enum(["automatic", "manual"]),
});

type PageState = z.infer<typeof PageState>;

const LineDetail = ({ state }: { state: PageState }) => {
  const { line, view_mode } = state;
  const translate = useTranslate();

  const [view, setView] = useState(view_mode);
  const { data } = useGetLineConfiguration({
    line_id: line.line_id,
    config: view,
    has_view_from_first_product: line.has_view_from_first_product,
  });

  if (!data) {
    return <Loader isLoading />;
  }

  const { configured } = data;

  return (
    <>
      <OverviewNavBar hasTimezone>
        <ToggleButtonGroup
          color="primary"
          value={view}
          exclusive
          size="small"
          onChange={(_, value) => value !== null && setView(value)}
          aria-label="line view mode"
        >
          <ToggleButton size="small" value="automatic">
            {translate("automatic")}
          </ToggleButton>
          <ToggleButton size="small" value="manual">
            {translate("manual")}
          </ToggleButton>
        </ToggleButtonGroup>
        {line.has_view_from_first_product ? <AlternativeViewSwitch /> : null}
        {view === "automatic" ? (
          <SelectTimeSelection
            exclude={["Custom", "TwoWeeks", "Month", "ThreeMonths", "SixMonths", "Year", "Batch"]}
          />
        ) : null}

        <NavButton
          to="historic"
          text={translate("view_historic")}
          state={{ line, view_mode: view }}
          replace
        />

        <NavButton
          to="/overview/overall-line-efficiency"
          text={translate("go_back")}
          startIcon={<ArrowBackOutlined />}
          replace
        />
      </OverviewNavBar>
      <Stack gap={2}>
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
          }}
        >
          <Button variant="contained" disabled>
            {line.factory_name} - {line.line_name}
          </Button>

          <Box
            sx={{
              marginLeft: "auto",
            }}
          >
            <Typography display="inline-block" variant="body2">
              {translate("configuration")}
            </Typography>
            {configured && data.config_values.is_running ? (
              <ConfigurationModal config_values={data.config_values} line={line} view={view} />
            ) : view === "manual" ? (
              <EditManualLineConfigurationButton line={line} />
            ) : (
              <EditAutomaticLineConfigurationButton line={line} />
            )}
          </Box>
        </Box>

        {!configured ? (
          <LineNotConfigured />
        ) : (
          <>
            {data.config_values.is_running ? (
              <>
                <Grid2 container spacing={2} height={"fit-content"}>
                  <Grid2 xs={4} md={5}>
                    <Stack gap={5} direction="column" justifyContent="center">
                      <Stack gap={2} direction="row">
                        <GaugeLayout
                          dimension="large"
                          color={colors.kpi.oee}
                          min={0}
                          max={100}
                          type="percentage"
                          title="OEE"
                          value={data.config_values.data.line_efficiency}
                        />

                        <RadialDoubleBarGaugeLayout
                          color=""
                          realValue={data.config_values.data.products_gap.current_products}
                          targetValue={data.config_values.data.products_gap.ideal_current_products}
                          value={parseInt(
                            (
                              data.config_values.data.products_gap.current_products -
                              data.config_values.data.products_gap.ideal_current_products
                            ).toFixed(0),
                            10,
                          )}
                          max={data.config_values.data.products_gap.total}
                          title={
                            data.config_values.data.products_gap.current_products <
                            data.config_values.data.products_gap.ideal_current_products
                              ? translate("batch_navigator.delay")
                              : translate("batch_navigator.advanced")
                          }
                          dimension="large"
                        />
                      </Stack>
                      <TotalProductsSummary
                        output_products={data.config_values.data.output_products}
                        output_wastes={data.config_values.data.output_wastes}
                        products={data.config_values.data.products}
                      />
                    </Stack>
                  </Grid2>
                  <Grid2 xs={7} md={7}>
                    <Stack gap={2} direction="column">
                      <Stack direction="row" justifyContent="space-between" gap={2}>
                        <Card
                          sx={{
                            color: stateColor(data.config_values.data.state, translate).color,
                            flex: 1,
                          }}
                        >
                          <Grid2
                            columns={12}
                            container
                            alignItems="center"
                            spacing={1}
                            height="100%"
                          >
                            <Grid2
                              display="flex"
                              justifyContent="space-around"
                              alignContent="center"
                              flex={1}
                            >
                              <Stack direction="row" alignItems="center" gap={4}>
                                {stateColor(data.config_values.data.state, translate).icon}
                                <Typography textAlign="center" variant="h6">
                                  {stateColor(data.config_values.data.state, translate).title}
                                </Typography>
                              </Stack>
                            </Grid2>
                          </Grid2>
                        </Card>
                      </Stack>
                      <LineLastProductionsLive line_id={line.line_id} config={view} />
                    </Stack>
                  </Grid2>
                </Grid2>

                <Grid2 container spacing={2} alignItems="stretch">
                  <Grid2 xs={12}>
                    <LineTopAlarmsLive line_id={line.line_id} config={view} />
                  </Grid2>
                </Grid2>
                <Grid2 container spacing={2}>
                  <Grid2 xs={12}>
                    {line.line_configuration ? (
                      <Card>
                        <Stack
                          gap={2}
                          direction="row"
                          paddingBottom={2}
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography variant="body1">
                            {line.factory_name} - {line.line_name}
                          </Typography>
                          <TogglePerformanceView />
                        </Stack>

                        <PlantDesign config={line.line_configuration} lineId={line.line_id} />
                      </Card>
                    ) : null}
                  </Grid2>
                  <Grid2 xs={12}>
                    <LineStatesLogHandler
                      line_id={line.line_id}
                      view_mode={view}
                      has_view_from_first_product={!!line.has_view_from_first_product}
                    />
                  </Grid2>
                  <Grid2 xs={12}>
                    <LineEfficiencyTrendWrapper
                      line_id={line.line_id}
                      config={view}
                      config_id={data.config_values.id}
                      has_view_from_first_product={!!line.has_view_from_first_product}
                    />
                  </Grid2>
                  <Grid2 xs={6}>
                    <LineProductsProjectionWrapper
                      line_id={line.line_id}
                      config={view}
                      config_id={data.config_values.id}
                    />
                  </Grid2>
                  <Grid2 xs={6} alignItems="stretch">
                    <Card>
                      <ProductsHistogram
                        data={data.config_values.data.products.map((value) => ({
                          category: value.machine,
                          products: value.products,
                          wastes: value.wastes,
                        }))}
                      />
                    </Card>
                  </Grid2>
                </Grid2>
              </>
            ) : (
              <LineConfig config={data.config_values} />
            )}
          </>
        )}
      </Stack>
    </>
  );
};

const LineProductsProjectionWrapper = ({
  line_id,
  config,
  config_id,
}: {
  line_id: number;
  config: "automatic" | "manual";
  config_id: number;
}) => {
  const translate = useTranslate();
  const { data, isLoading, isError } = useGetLineProductsProjection({
    line_id,
    config,
    config_id,
  });

  if (isLoading) return <SkeletonCard height={328} />;
  if (!data) return <Card>{translate("user_feedback.no_data")}</Card>;
  if (isError) return <Card>{translate("user_feedback.an_error_occurred")}</Card>;

  return (
    <LineProductsProjection
      products={data.products}
      machine={data.machine_output}
      products_projection={data.products_projection}
    />
  );
};

const LineEfficiencyTrendWrapper = ({
  line_id,
  config,
  config_id,
  has_view_from_first_product,
}: {
  line_id: number;
  config: "automatic" | "manual";
  config_id: number;
  has_view_from_first_product: boolean;
}) => {
  const translate = useTranslate();
  const { data, isLoading, isError } = useGetLineMachinesEfficiencies({
    line_id,
    config,
    config_id,
    has_view_from_first_product,
  });

  if (isLoading) return <SkeletonCard height={300} />;
  if (!data) return <Card>{translate("user_feedback.no_data")}</Card>;
  if (isError) return <Card>{translate("user_feedback.an_error_occurred")}</Card>;

  return (
    <LineEfficiencyTrend
      data={data.data.map((value) => ({
        category: value.machine,
        values: value.values,
      }))}
    />
  );
};

const LineNotConfigured = () => {
  const translate = useTranslate();
  return <Card>{translate("line.no_configuration")}</Card>;
};

//! width e height prima erano 72
const stateColor = (state: string, translate: (name: Translations) => string) => {
  switch (state) {
    case "Producing":
      return {
        color: "#00FF00",
        icon: <ProductionCategory width={48} height={48} fill="#00FF00" />,
        title: translate("category.1"),
      };
    case "Not Producing":
      return {
        color: "#ff7a0d",
        icon: <NotProducingCategory width={48} height={48} fill="#ff7a0d" />,
        title: translate("category.2"),
      };
    case "Scheduled Downtime":
      return {
        color: "#8a8a8a",
        icon: <MaintenanceCategory width={48} height={48} fill="#8a8a8a" />,
        title: translate("line.category_scheduled_downtime"),
      };
    case "Idle":
      return {
        color: "#eeeeee",
        icon: <DisconnectedCategory width={48} height={48} fill="#eeeeee" />,
        title: translate("line.category_idle"),
      };
    default:
      return {
        color: "#8a8a8a",
        icon: <DisconnectedCategory width={48} height={48} fill="#8a8a8a" />,
        title: translate("line.category_scheduled_downtime"),
      };
  }
};

export const LineDetailScreen = () => {
  const location = useLocation();
  const translate = useTranslate();
  const data = location.state as {
    line: Line;
    view_mode: "automatic" | "manual";
  };

  if (!data) {
    return <Navigate to="/overview/overall-line-efficiency" replace />;
  }

  const result = PageState.safeParse(data);

  if (!result.success) {
    return (
      <Box>
        Invalid line
        <Link to="/overview/overall-line-efficiency" replace>
          <Button>{translate("go_back")}</Button>
        </Link>
      </Box>
    );
  }
  return <LineDetail state={result.data} />;
};
