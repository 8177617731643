import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { useLanguage } from "@/i18n/config";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type RejectCauseDetailResponse = ThingworxError | RejectCauseDetail;

const RejectCauseDetail = z.object({
  response: z.literal(true),
  data: z.array(z.array(z.number())),
  cause: z.string(),
  description: z.string(),
});

type RejectCauseDetail = z.infer<typeof RejectCauseDetail>;

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
  rejectCause: string;
  dateStart?: DateTime;
  dateEnd?: DateTime;
  language_id: string;
};

export const useGetRejectCauseDetail = ({ rejectCause }: { rejectCause: string }) => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const {
    timeSelection,
    dates: { dateStart, dateEnd },
  } = useTimeSelection();
  const language = useLanguage();

  const payload: Payload =
    timeSelection === "Custom"
      ? {
          machineName: machine?.machine || "",
          timeSelection,
          rejectCause,
          dateStart,
          dateEnd,
          language_id: language,
        }
      : {
          machineName: machine?.machine || "",
          timeSelection,
          rejectCause,
          language_id: language,
        };

  const queryKey =
    timeSelection === "Custom"
      ? [
          "reject-causes-detail",
          machine?.machine,
          timeSelection,
          rejectCause,
          dateStart,
          dateEnd,
          language,
        ]
      : ["reject-causes-detail", machine?.machine, timeSelection, rejectCause, language];

  return useQuery<RejectCauseDetail>({
    queryKey,
    queryFn: async () => {
      const response = await FetchClient<Payload, RejectCauseDetailResponse>({
        appKey,
        url: "dig.c.rejectCause_thing/Services/getSingleRejectsCauses",
        payload,
      });

      if (!response.response) throw new Error(response.errorString);

      return RejectCauseDetail.parse(response);
    },
    enabled: !!machine?.machine,
  });
};
