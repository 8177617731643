import { Box, Tooltip, Typography, Zoom } from "@mui/material";
import { Evaluation } from "../../types";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { EVALUATION_COLORS } from "../../utils/evaluationColors";
import { TimeHelpers } from "@/utils/TimeHelpers";

interface Props {
  title: string;
  subTitle?: string;
  content: string | number;
  status?: Evaluation;
}

export const InfoCard = ({ title, subTitle, content, status }: Props) => {
  const translate = useTranslate();

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      initial={{ opacity: 0.5, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0 }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginBottom: ".5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography>{title}</Typography>
          {status && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                cursor: "pointer",
              }}
            >
              <Typography variant="subtitle2">{translate("status")}</Typography>
              <Tooltip
                TransitionComponent={Zoom}
                title={translate(`evaluation.${status}`)}
                placement="top"
              >
                <Box
                  sx={{
                    width: ".75rem",
                    height: ".75rem",
                    backgroundColor: EVALUATION_COLORS[status].selected,
                    borderRadius: ".375rem",
                  }}
                ></Box>
              </Tooltip>
            </Box>
          )}
        </Box>
        {subTitle && (
          <Typography variant="caption" sx={{ color: "#ccc" }}>
            {subTitle}
          </Typography>
        )}
      </Box>
      {typeof content === "number" ? (
        <Typography variant="h6">
          {TimeHelpers.parseDurationToString({ duration: content })}
        </Typography>
      ) : content.length > 24 ? (
        <Tooltip TransitionComponent={Zoom} title={content}>
          <Typography
            variant="h6"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {`${content.slice(0, 20)}...`}
          </Typography>
        </Tooltip>
      ) : (
        <Typography
          variant="h6"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {content}
        </Typography>
      )}
    </Card>
  );
};
