import { z } from "zod";
import { itemHealthStatus } from "../../../types";
import { Box } from "@mui/material";
import { ItemsHealthStatus } from "./ItemsHealthStatus";
import { useTranslate } from "@/i18n/config";
import { useGetShuttlesAndSegmentsHealth } from "../../../api/Summary/useGetShuttlesAndSegmentsHealth";
import { Card } from "@/components/Layout/Card";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";

export const segmentsShuttlesHealthStatusData = z.object({
  segments: z.array(itemHealthStatus),
  shuttles: z.array(itemHealthStatus),
});

export type SegmentsShuttlesHealthStatusData = z.infer<
  typeof segmentsShuttlesHealthStatusData
>;

export const HealthStatus = () => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetShuttlesAndSegmentsHealth();
  if (isLoading) return <SkeletonCard height={150} />;
  if (error)
    return (
      <Card sx={{ height: 150 }}>
        {translate("user_feedback.an_error_occurred")}
      </Card>
    );
  if (!data)
    return (
      <Card sx={{ height: 150 }}>{translate("user_feedback.no_data")}</Card>
    );

  return (
    <Box
      sx={{
        display: "grid",
        width: "100%",
        gap: 1,
        gridTemplateColumns: "repeat(2, 1fr)",
      }}
    >
      <ItemsHealthStatus
        title={translate("health_status.segments_health_status")}
        items={data.data.segments}
        itemTranslation={translate("acopos.segment")}
      />
      <ItemsHealthStatus
        title={translate("health_status.shuttles_health_status")}
        items={data.data.shuttles}
        itemTranslation={translate("acopos.shuttle")}
      />
    </Box>
  );
};
