import { useEffect } from "react";
import { Handle, useNodeId, useUpdateNodeInternals } from "reactflow";
import { useHandleStyle } from "../hooks/useHandleStyle";

type CustomHandleProps = Partial<React.ComponentProps<typeof Handle>>;

export const CustomHandle = (
  props: Pick<React.ComponentProps<typeof Handle>, "type" | "id"> &
    CustomHandleProps,
) => {
  /** throws if "" gets passed */
  const { position, style } = useHandleStyle(props.id ?? "");
  const updateNodeInternals = useUpdateNodeInternals();
  const nodeId = useNodeId();

  useEffect(() => {
    if (nodeId) {
      updateNodeInternals(nodeId);
    }
  }, [nodeId, updateNodeInternals]);

  if (!props.id) return null;

  return (
    <Handle
      {...props}
      type={props.type}
      id={props.id}
      position={position}
      style={{
        ...style,
        background: props.type === "source" ? "none" : "white",
        border: props.type === "source" ? "1px solid white" : "none",
        boxShadow: "2px 2xp 2px 2px rgba(0,0,0,0.2)",
      }}
    />
  );
};
