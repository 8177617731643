/* eslint-disable @typescript-eslint/ban-ts-comment */
import { NivoSolidGauge } from "@/components/highcharts/gauge/NivoSolidGauge";
import { RadialBarCustomLayer } from "@nivo/radial-bar";
import { Duration } from "luxon";
import { GaugeCircleLayout } from "./CircleLayout";

export const PercentageCustomView: RadialBarCustomLayer = ({
  center,
  bars,
}) => {
  // @ts-ignore
  const tip = bars[0].data.tip;
  const value = bars[0].value;

  return (
    <>
      <text
        x="50%"
        y="40%"
        textAnchor="middle"
        dominantBaseline="middle"
        style={{ fill: "white", fontSize: tip === "OEE" ? "14pt" : "10pt" }}
      >
        {tip}
      </text>
      <text
        y={center[1] + 20}
        x="50%"
        textAnchor="middle"
        dominantBaseline="middle"
        style={{ fill: "white", fontSize: "12pt" }}
      >
        {value === 100 ? value : value.toFixed(2)}%
      </text>
    </>
  );
};

const DurationCustomView: RadialBarCustomLayer = ({ center, bars }) => {
  // @ts-ignore
  const tip = bars[0].data.tip;
  const value = bars[0].value;
  const duration = Duration.fromMillis(value).toFormat("hh:mm:ss");

  return (
    <>
      <text
        x="50%"
        y="40%"
        textAnchor="middle"
        dominantBaseline="middle"
        style={{ fill: "white", fontSize: "10pt" }}
      >
        {tip}
      </text>
      <text
        y={center[1] + 20}
        x="50%"
        textAnchor="middle"
        dominantBaseline="middle"
        style={{ fill: "white", fontSize: "12pt" }}
      >
        {duration}
      </text>
    </>
  );
};

const CountCustomView: RadialBarCustomLayer = ({ center, bars }) => {
  // @ts-ignore
  const tip = bars[0].data.tip;
  const value = bars[0].value;

  return (
    <>
      <text
        x="50%"
        y="40%"
        textAnchor="middle"
        dominantBaseline="middle"
        style={{ fill: "white", fontSize: "10pt" }}
      >
        {tip}
      </text>
      <text
        y={center[1] + 20}
        x="50%"
        textAnchor="middle"
        dominantBaseline="middle"
        style={{ fill: "white", fontSize: "12pt" }}
      >
        {value}
      </text>
    </>
  );
};

export const GaugeLayout = ({
  title,
  value = 0,
  type,
  min,
  max,
  color,
  dimension = "large",
  backgroundColor,
}: {
  title: string;
  value: number;
  min: number;
  max: number;
  type: "percentage" | "duration" | "count";
  dimension?: "small" | "large";
  color: string;
  backgroundColor?: string;
}) => {
  const _dimension = dimension === "large" ? "12vmax" : "9vmax";

  return (
    <GaugeCircleLayout
      color={backgroundColor}
      sx={{
        position: "relative",
        padding: 1,
        margin: "auto",
        width: _dimension,
        height: _dimension,
        aspectRatio: "1/1",
        textAlign: "center",
      }}
    >
      <NivoSolidGauge
        min={min}
        max={max}
        title={title}
        color={color}
        value={value}
        CustomLayer={
          type === "percentage"
            ? PercentageCustomView
            : type === "duration"
            ? DurationCustomView
            : CountCustomView
        }
      />
    </GaugeCircleLayout>
  );
};
