import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { Evaluation } from "../types";

const longTermDriftStatusResult = z.object({
  response: z.literal(true),
  evaluation: Evaluation,
});

export type LongTermDriftStatusResult = z.infer<
  typeof longTermDriftStatusResult
>;

export const useGetLongTermDriftStatus = ({ motor }: { motor: number }) => {
  return useQuery<LongTermDriftStatusResult>({
    queryKey: ["long-term-drift-status", motor],
    queryFn: async () => {
      return new Promise<LongTermDriftStatusResult>((resolve) => {
        setTimeout(() => {
          const response = {
            response: true,
            evaluation: "good",
          };
          resolve(longTermDriftStatusResult.parse(response)); // Valida la risposta con Zod
        }, 2000); // Simula il ritardo di 2 secondi
      });
    },
  });
};
