import { QueryStats } from "@mui/icons-material";
import {
  Stack,
  Button,
  Menu,
  FormControl,
  FormLabel,
  Checkbox,
  ListItem,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLyoContext } from "../../context/lyo-context";
import { Batch } from "../../types";
import { useTranslate } from "@/i18n/config";

export const RangeBatchMenu = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const [{ batches, filteredBatches }, dispatch] = useLyoContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [recipe, setRecipe] = useState("");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (checkbox: Batch) => {
    const foundIndex = filteredBatches.findIndex(
      (b) => b.batchId === checkbox.batchId,
    );

    if (foundIndex === -1) {
      dispatch({
        type: "filter batches",
        batches: [...filteredBatches, checkbox],
      });
    } else {
      dispatch({
        type: "filter batches",
        batches: filteredBatches.filter((b) => b.batchId !== checkbox.batchId),
      });
    }
  };

  const handleClear = () => {
    setRecipe("");
    dispatch({
      type: "filter batches",
      batches: [],
    });
  };

  const selectAll = () => {
    setRecipe("");
    dispatch({
      type: "filter batches",
      batches: batches,
    });
  };

  const filterByRecipe = (recipe: string) => {
    setRecipe(recipe);
    if (recipe === "") {
      return selectAll();
    }

    dispatch({
      type: "filter batches",
      batches: batches.filter((b) => b.recipe === recipe),
    });
  };

  const uniqueRecipes = batches
    .map((b) => b.recipe)
    .filter((v, i, a) => a.indexOf(v) === i);

  return (
    <Stack gap={4}>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="outlined"
      >
        {translate("analytics.batch_other")}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <FormControl sx={{ m: 3 }} component="fieldset" variant="filled">
          <FormLabel component="legend">
            {translate("select_batch_other")}
          </FormLabel>
          <Button onClick={selectAll}>{translate("actions.select_all")}</Button>
          <Button onClick={handleClear}>
            {translate("actions.deselect_all")}
          </Button>
          <Select
            value={recipe}
            variant="outlined"
            onChange={(e) => filterByRecipe(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="">
              <em>{translate("select_recipe")}</em>
            </MenuItem>
            {uniqueRecipes.map((recipe) => (
              <MenuItem key={recipe} value={recipe}>
                {recipe}
              </MenuItem>
            ))}
          </Select>
          <List>
            {batches.length > 0 &&
              batches.map((batch) => (
                <ListItem
                  key={batch.batchId}
                  secondaryAction={
                    <IconButton
                      onClick={() => {
                        const selectedBatch = batches.find(
                          (b) => b.batchId === batch.batchId,
                        );

                        if (selectedBatch) {
                          dispatch({
                            type: "pick batch",
                            selectedBatch: selectedBatch,
                          });
                          navigate("/analytics/lyophilizer/batch/diagnostic");
                        }
                      }}
                      edge="end"
                      aria-label="comments"
                    >
                      <QueryStats />
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemButton
                    role={undefined}
                    onClick={() => handleChange(batch)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        tabIndex={-1}
                        inputProps={{
                          "aria-labelledby": batch.batchName,
                        }}
                        disableRipple
                        checked={
                          filteredBatches.findIndex(
                            (b) => b.batchId === batch.batchId,
                          ) !== -1
                        }
                        name={batch.batchId.toString()}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={batch.batchName}
                      primary={batch.batchName + " - " + batch.recipe}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </FormControl>
      </Menu>
    </Stack>
  );
};
