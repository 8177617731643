import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export const NavButton = ({
  to,
  replace = false,
  text,
  state,
  endIcon,
  startIcon,
}: {
  to: string;
  replace?: boolean;
  text: string;
  state?: unknown;
  endIcon?: React.ReactNode;
  startIcon?: React.ReactNode;
}) => {
  return (
    <Button
      component={Link}
      to={to}
      replace={replace}
      state={state}
      sx={{ minWidth: "fit-content" }}
      variant="outlined"
      endIcon={endIcon}
      startIcon={startIcon}
    >
      {text}
    </Button>
  );
};
