import { useState } from "react";
import { toast } from "react-hot-toast";
import { DateTime } from "luxon";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Stack,
  Tooltip,
} from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { useDisclosure } from "@/hooks/useDisclosure";
import { PowerWord } from "../../types";
import { usePowerWord } from "../../api/usePowerWord";
import { chatStore } from "../../store/chat-store";
import { scrollChat } from "../../utilities/scrollChat";
import { Modal } from "@/components/Modal";
import { sentryModeStore } from "../../store/sentry-mode-store";
import { intellectaColors } from "../../utilities/colors";
import { userConfigurationStore } from "../../store/user-configuration-store";
import { DateTimeRangePicker } from "../DateTimeRangePicker";

export const PowerwordButtonMachineTimestamp = ({
  powerWord,
}: {
  powerWord: PowerWord;
}) => {
  const translate = useTranslate();
  const [machines, setMachines] = useState<string[]>([]);
  const [dateStart, setDateStart] = useState(
    DateTime.now().minus({ weeks: 1 }).toMillis(),
  );
  const [dateEnd, setDateEnd] = useState(DateTime.now().toMillis());
  const { addMessage, setChatId, setIsAnswering } = chatStore();
  const { version, profile, availableMachines } = userConfigurationStore();
  const { mutateAsync: callPowerWord } = usePowerWord();
  const { open, close, isOpen } = useDisclosure();
  const { resetTimer } = sentryModeStore();
  const filteredMachines = availableMachines.filter((machine) => {
    if (!profile || !version) return null;
    if (
      machine.profile.includes(profile) &&
      machine.service_version_access.includes(version)
    )
      return machine;
    return null;
  });

  const onConfirm = () => {
    if (!dateStart || !dateEnd)
      return toast.error(translate("user_feedback.an_error_occurred"));

    if (machines.length === 0)
      return toast.error(translate("intellecta.no_machine_selected"));

    if (!version || !profile)
      return toast.error(translate("user_feedback.an_error_occurred"));

    close();

    setIsAnswering(true);
    resetTimer();

    addMessage({
      sender: "User",
      message: powerWord.prompt,
    });

    scrollChat();

    callPowerWord({
      endpoint: powerWord.endpoint,
      params: { machine: machines, ts_start: dateStart, ts_end: dateEnd },
    })
      .then((response) => {
        addMessage({
          sender: "Intellecta",
          message: response.value,
          image: response.image,
        });
        setChatId(response.chat_id);
      })
      .finally(() => {
        scrollChat();
        setIsAnswering(false);
        resetTimer();
      });
  };

  const handleSelectMachine = (event: React.ChangeEvent<HTMLInputElement>) => {
    const machine = event.target.name;
    if (event.target.checked) {
      setMachines([...machines, machine]);
    } else {
      setMachines(machines.filter((m) => m !== machine));
    }
  };

  return (
    <>
      <Modal
        titleContent={translate("intellecta.insert_machine_and_dates")}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={isOpen}
        bodyContent={
          <Stack gap={2} p={2}>
            <FormControl
              required
              error={machines.length === 0}
              component="fieldset"
              variant="standard"
              sx={{ width: "fit-content" }}
            >
              <FormLabel component="legend">{translate("machine")}</FormLabel>
              <FormGroup>
                {filteredMachines.map((machine) => (
                  <FormControlLabel
                    key={machine.machine}
                    control={
                      <Checkbox
                        checked={machines.includes(machine.machine)}
                        onChange={handleSelectMachine}
                        name={machine.machine}
                      />
                    }
                    label={machine.machine}
                  />
                ))}
              </FormGroup>
            </FormControl>
            <DateTimeRangePicker
              onPickDates={(val) => {
                setDateStart(val.dateStart.toMillis());
                setDateEnd(val.dateEnd.toMillis());
              }}
            />
          </Stack>
        }
        actions={
          <>
            <Button variant="outlined" onClick={close}>
              {translate("actions.cancel")}
            </Button>
            <Button variant="contained" onClick={onConfirm}>
              {translate("actions.confirm")}
            </Button>
          </>
        }
      />
      <Tooltip title={powerWord.description}>
        <Button
          fullWidth
          variant="contained"
          onClick={open}
          sx={{
            backgroundColor: intellectaColors.powerWordButtonBackground,
            color: "white",
            justifyContent: "flex-start",
            ":hover": {
              backgroundColor: "#ffffff15",
            },
          }}
        >
          {powerWord.label}
        </Button>
      </Tooltip>
    </>
  );
};
