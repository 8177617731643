import {
  SeriesArearangeOptions,
  SeriesLineOptions,
  XAxisOptions,
} from "highcharts";
import { LineData } from "../types";
import { CHART_COLORS } from "./chartColors";

export const parseLineWithAreaData = ({
  lineData,
  areaData,
}: {
  lineData: LineData[];
  areaData: number[][];
}): {
  seriesData: (SeriesLineOptions | SeriesArearangeOptions)[];
  xAxesOptions: XAxisOptions[];
} => {
  const seriesData: (SeriesLineOptions | SeriesArearangeOptions)[] = [];
  const xAxesOptions: XAxisOptions[] = [];

  if (areaData.length === 0) return { seriesData, xAxesOptions };
  //? prendere durata da area
  //? settare durata come max su ogni asse x
  const tsStart = areaData[0][0];
  const tsEnd = areaData[areaData.length - 1][0];
  const timeRange = tsEnd - tsStart;

  //? asse x per area
  xAxesOptions.push({ type: "datetime" });
  //? assi x per le linee, uno per ognuna
  lineData.forEach((serie) => {
    const firstTs =
      serie.data && serie.data.length > 0 ? serie.data[0][0] : undefined;
    xAxesOptions.push({
      type: "datetime",
      visible: false,
      max: firstTs ? firstTs + timeRange : undefined,
    });
  });

  seriesData.push({
    type: "arearange",
    name: "range",
    data: areaData,
    xAxis: 0,
    // color: "#18e4f2",
    color: CHART_COLORS[4],
    opacity: 0.3,
  });

  lineData.forEach((dataset, i) => {
    seriesData.push({
      type: "line",
      name: dataset.name,
      data: dataset.data,
      xAxis: i + 1,
      // color: "#18e4f2",
      color: CHART_COLORS[i],
    });
  });

  return { seriesData, xAxesOptions };
};
