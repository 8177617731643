import { createContext, useContext, useReducer } from "react";
import { EvaluationColors, Lap, Run } from "../types";

export const DEFAULT_RUNS_NUMBER = 5;
export const MAX_RUNS_NUMBER = 2;
export const MAX_LAPS_NUMBER = 3;
export const MAX_SHUTTLES_NUMBER = 2;

export const EVALUATION_COLORS: EvaluationColors = {
  good: {
    selected: "#76A12B",
    notSelected: "#374a15",
    // notSelected: "#004400",
  },
  warning: {
    selected: "#e8c52a",
    notSelected: "#52450f",
    // notSelected: "#444000",
  },
  critical: {
    selected: "#D62422",
    notSelected: "#470e0d",
    // notSelected: "#440000",
  },
  lack_of_data: {
    selected: "#999999",
    notSelected: "#222",
  },
  no_data: {
    selected: "#CCCCCC",
    notSelected: "#444",
  },
  undefined: {
    selected: "#FFFFFF",
    notSelected: "#666",
  },
};

type AcoposContextType = {
  maxDaysCalendar: number;
  activeSegment: number | null;
  laps: { [key: string]: Lap[] };
  isLapsLimitActive: boolean;
  runs: Run[];
  selectedRun: string | null;
  isRunsLimitActive: boolean;
  shuttles: number[];
};

type Action =
  | {
      type: "SET_MAX_DAYS_CALENDAR";
      item: number;
    }
  | {
      type: "SET_ACTIVE_SEGMENT";
      item: number;
    }
  | {
      type: "SET_IS_LAPS_LIMIT_ACTIVE";
      item: boolean;
    }
  | {
      type: "ADD_LAP";
      item: Lap;
    }
  | {
      type: "REMOVE_LAP";
      item: Lap;
    }
  | {
      type: "TOGGLE_LAPS";
      item: Lap[];
    }
  | {
      type: "UPDATE_LAPS";
      item: Lap[];
    }
  | {
      type: "CLEAR_LAPS";
    }
  | {
      type: "SET_IS_RUNS_LIMIT_ACTIVE";
      item: boolean;
    }
  | {
      type: "SET_SELECTED_RUN";
      item: string;
    }
  | {
      type: "ADD_RUN";
      item: Run;
    }
  | {
      type: "REMOVE_RUN";
      item: Run;
    }
  | {
      type: "UPDATE_RUNS";
      item: Run[];
    }
  | {
      type: "CLEAR_RUNS";
    }
  | {
      type: "ADD_SHUTTLE";
      item: number;
    }
  | {
      type: "REMOVE_SHUTTLE";
      item: number[];
    }
  | {
      type: "REMOVE_SINGLE_SHUTTLE";
      item: number;
    }
  | {
      type: "CLEAR_SHUTTLES";
    };

const reducer: React.Reducer<AcoposContextType, Action> = (
  state,
  action,
): AcoposContextType => {
  switch (action.type) {
    case "SET_MAX_DAYS_CALENDAR": {
      return {
        ...state,
        maxDaysCalendar: action.item,
      };
    }
    case "SET_ACTIVE_SEGMENT": {
      return {
        ...state,
        activeSegment: action.item,
      };
    }

    case "SET_IS_LAPS_LIMIT_ACTIVE": {
      let totalLapsLength = 0;
      for (const [, val] of Object.entries(state.laps)) {
        totalLapsLength += val.length;
      }
      if (action.item && totalLapsLength >= MAX_LAPS_NUMBER) {
        let lapsCount = 0;
        for (const [key, val] of Object.entries(state.laps)) {
          const lapsLeft = MAX_LAPS_NUMBER - lapsCount;
          if (lapsCount < MAX_LAPS_NUMBER) {
            if (val.length > lapsLeft) {
              state.laps[key] = val.slice(0, lapsLeft);
            } else {
              state.laps[key] = val;
            }
            lapsCount += val.length;
          } else {
            state.laps[key] = [];
          }
        }
      }
      return {
        ...state,
        isLapsLimitActive: action.item,
      };
    }

    case "ADD_LAP": {
      if (!state.selectedRun) return state;
      const addLapClone: { [key: string]: Lap[] } = {};
      for (const [key, val] of Object.entries(state.laps)) {
        addLapClone[key] = val;
      }
      addLapClone[state.selectedRun] = [
        ...state.laps[state.selectedRun],
        action.item,
      ];
      return { ...state, laps: addLapClone };
    }

    case "REMOVE_LAP": {
      if (!state.selectedRun) return state;
      const removeLapClone: { [key: string]: Lap[] } = {};
      for (const [key, val] of Object.entries(state.laps)) {
        removeLapClone[key] = val;
      }
      removeLapClone[state.selectedRun] = state.laps[state.selectedRun].filter(
        (lap) =>
          `${lap.start}-${lap.end}` !==
          `${action.item.start}-${action.item.end}`,
      );
      return { ...state, laps: removeLapClone };
    }

    case "TOGGLE_LAPS": {
      if (!state.selectedRun) return state;
      const toggleLapsClone: { [key: string]: Lap[] } = {};
      for (const [key, val] of Object.entries(state.laps)) {
        toggleLapsClone[key] = val;
      }
      const toggleLapsRunClone: Lap[] = toggleLapsClone[state.selectedRun].map(
        (entry) => {
          return { ...entry };
        },
      );
      action.item.forEach((lap) => {
        const lapIndex = toggleLapsRunClone.findIndex(
          (obj) => `${obj.start}-${obj.end}` === `${lap.start}-${lap.end}`,
        );
        if (lapIndex > -1) {
          toggleLapsRunClone.splice(lapIndex, 1);
        } else {
          toggleLapsRunClone.push(lap);
        }
      });
      if (
        state.isLapsLimitActive &&
        toggleLapsRunClone.length > MAX_LAPS_NUMBER
      ) {
        toggleLapsClone[state.selectedRun] = toggleLapsRunClone.slice(
          0,
          MAX_LAPS_NUMBER,
        );
      } else {
        state.laps[state.selectedRun] = toggleLapsRunClone;
      }
      return { ...state, laps: toggleLapsClone };
    }

    case "UPDATE_LAPS": {
      if (state.selectedRun) state.laps[state.selectedRun] = action.item;
      return { ...state };
    }

    case "CLEAR_LAPS": {
      if (state.selectedRun) state.laps[state.selectedRun] = [];
      return { ...state };
    }

    case "SET_IS_RUNS_LIMIT_ACTIVE": {
      if (action.item && state.runs.length >= MAX_RUNS_NUMBER) {
        return {
          ...state,
          isRunsLimitActive: action.item,
          runs: state.runs.slice(0, MAX_RUNS_NUMBER),
        };
      } else {
        return {
          ...state,
          isRunsLimitActive: action.item,
        };
      }
    }

    case "SET_SELECTED_RUN": {
      return {
        ...state,
        selectedRun: action.item,
      };
    }

    case "ADD_RUN": {
      if (
        state.runs.find(
          (run) =>
            `${run.start}-${run.end}` ===
            `${action.item.start}-${action.item.end}`,
        )
      ) {
        return state;
      } else {
        if (state.runs.length === 0) {
          const addRunNewLaps: { [key: string]: Lap[] } = {};
          addRunNewLaps[action.item.name] = [];
          return {
            ...state,
            runs: [...state.runs, action.item],
            selectedRun: action.item.name,
            laps: addRunNewLaps,
          };
        } else {
          state.laps[action.item.name] = [];
          return {
            ...state,
            runs: [...state.runs, action.item],
          };
        }
      }
    }

    case "REMOVE_RUN": {
      if (
        state.runs.find(
          (run) =>
            `${run.start}-${run.end}` ===
            `${action.item.start}-${action.item.end}`,
        )
      ) {
        delete state.laps[action.item.name];
        if (state.runs.length > 1) {
          return {
            ...state,
            runs: state.runs.filter(
              (run) =>
                `${run.start}-${run.end}` !==
                `${action.item.start}-${action.item.end}`,
            ),
            selectedRun:
              action.item.name === state.runs[0].name
                ? state.runs[1].name
                : state.runs[0].name,
          };
        } else {
          return {
            ...state,
            runs: state.runs.filter(
              (run) =>
                `${run.start}-${run.end}` !==
                `${action.item.start}-${action.item.end}`,
            ),
          };
        }
      } else {
        return state;
      }
    }
    case "UPDATE_RUNS": {
      if (action.item.length === 0)
        return {
          ...state,
          runs: [],
          selectedRun: null,
          laps: {},
        };
      const updateRunsNewLaps: { [key: string]: Lap[] } = {};
      updateRunsNewLaps[action.item[0].name] = [];
      if (action.item.length > 1) updateRunsNewLaps[action.item[1].name] = [];
      return {
        ...state,
        runs: action.item,
        selectedRun: action.item[0].name,
        laps: updateRunsNewLaps,
      };
    }

    case "CLEAR_RUNS": {
      return {
        ...state,
        runs: [],
        laps: {},
      };
    }

    case "ADD_SHUTTLE": {
      if (!state.shuttles.find((obj) => obj === action.item)) {
        return {
          ...state,
          shuttles: [...state.shuttles, action.item],
        };
      } else {
        return state;
      }
    }

    case "REMOVE_SHUTTLE": {
      const idToBeRemoved = state.shuttles.find(
        (num) => !action.item.includes(num),
      );
      if (Number.isInteger(idToBeRemoved)) {
        if (
          Number.isInteger(state.shuttles.find((el) => el === idToBeRemoved))
        ) {
          return {
            ...state,
            shuttles: state.shuttles.filter((item) => item !== idToBeRemoved),
          };
        } else {
          return state;
        }
      } else {
        return state;
      }
    }

    case "REMOVE_SINGLE_SHUTTLE":
      return {
        ...state,
        shuttles: state.shuttles.filter((item) => item !== action.item),
      };

    case "CLEAR_SHUTTLES": {
      return {
        ...state,
        shuttles: [],
      };
    }

    default:
      return state;
  }
};

export const AcoposContext = createContext<AcoposContextType | null>(null);
const AcoposDispatchContext = createContext<React.Dispatch<Action> | null>(
  null,
);

export const useAcoposContext = () => {
  const context = useContext(AcoposContext);

  if (!context) {
    throw new Error(
      "You can only use this context inside AcoposContextProvider",
    );
  }
  return context;
};

export const useAcoposDispatchContext = () => {
  const context = useContext(AcoposDispatchContext);

  if (!context) {
    throw new Error(
      "You can only use this context inside AcoposDispatchContextProvider",
    );
  }
  return context;
};

export const AcoposContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, {
    maxDaysCalendar: 7,
    activeSegment: null,
    laps: {},
    isLapsLimitActive: true,
    runs: [],
    selectedRun: null,
    isRunsLimitActive: false,
    shuttles: [],
  });

  return (
    <AcoposContext.Provider
      value={{
        maxDaysCalendar: state.maxDaysCalendar,
        activeSegment: state.activeSegment,
        laps: state.laps,
        isLapsLimitActive: state.isLapsLimitActive,
        runs: state.runs,
        selectedRun: state.selectedRun,
        isRunsLimitActive: state.isRunsLimitActive,
        shuttles: state.shuttles,
      }}
    >
      <AcoposDispatchContext.Provider value={dispatch}>
        {children}
      </AcoposDispatchContext.Provider>
    </AcoposContext.Provider>
  );
};
