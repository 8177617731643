import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { run } from "../../types";
import {
  DEFAULT_RUNS_NUMBER,
  MAX_RUNS_NUMBER,
  useAcoposContext,
  useAcoposDispatchContext,
} from "../../context/acopos-context";

export type GetRunsResponse = ThingworxError | GetRunsResult;

type Payload = {
  machineName: string;
  dateStart: number;
  dateEnd: number;
};

const getRunsResult = z.object({
  response: z.literal(true),
  runs: z.array(run),
});

export type GetRunsResult = z.infer<typeof getRunsResult>;

export const useGetRuns = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const { dates } = useTimeSelection();
  const { isRunsLimitActive } = useAcoposContext();
  const dispatch = useAcoposDispatchContext();

  return useQuery<GetRunsResult>({
    queryKey: ["runs-data", machine!.machine, dates.dateEnd, dates.dateStart],
    queryFn: async () => {
      const response = await FetchClient<Payload, GetRunsResponse>({
        appKey,
        payload: {
          machineName: machine!.machine,
          dateStart: dates.dateStart.toMillis(),
          dateEnd: dates.dateEnd.toMillis(),
        },
        // url: "dig.c.acopostrak_thing/Services/getRuns",
        url: "dig.c.acoposTrack_thing/Services/getRuns",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = getRunsResult.parse(response);

      if (validatedResponse.runs.length > 0) {
        if (isRunsLimitActive) {
          if (validatedResponse.runs.length > MAX_RUNS_NUMBER) {
            dispatch({
              type: "UPDATE_RUNS",
              item: validatedResponse.runs.slice(0, MAX_RUNS_NUMBER),
            });
          } else {
            dispatch({ type: "UPDATE_RUNS", item: validatedResponse.runs });
          }
        } else {
          if (validatedResponse.runs.length >= DEFAULT_RUNS_NUMBER) {
            dispatch({
              type: "UPDATE_RUNS",
              item: validatedResponse.runs.slice(0, DEFAULT_RUNS_NUMBER),
            });
          } else {
            dispatch({ type: "UPDATE_RUNS", item: validatedResponse.runs });
          }
        }
      } else {
        dispatch({ type: "CLEAR_RUNS" });
      }

      return validatedResponse;
    },
    staleTime: Infinity,
  });
};
