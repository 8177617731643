import { Stack } from "@mui/material";
import { BellCurveAnalyses } from "./BellCurveAnalyses";
import { BalanceBoxPlot } from "./BalanceBoxPlot";
import { BalanceDoserColumn } from "./BalanceDoserColumn";
import { BalanceViolinPlot } from "./BalanceViolinPlot";

export const CategoryBasedView = () => {
  return (
    <Stack spacing={2} marginTop={2}>
      <BalanceBoxPlot />
      <BalanceViolinPlot />
      <BellCurveAnalyses />
      <BalanceDoserColumn />
    </Stack>
  );
};
