import { DateTime } from "luxon";
import { createContext, ReactElement, useContext } from "react";
import { useImmerReducer } from "use-immer";
import { Batch, PhaseData } from "../types";

interface State {
  from: Date;
  to: Date;
  batches: Batch[];
  filteredBatches: Batch[];
  selectedBatch: Batch | null;
  selectedPhase: PhaseData | null;
}

type Action =
  | { type: "pick date"; from: Date; to: Date }
  | { type: "pick batch"; selectedBatch: Batch }
  | { type: "reset batch" }
  | { type: "set batches"; batches: Batch[] }
  | { type: "filter batches"; batches: Batch[] }
  | { type: "pick phase"; selectedPhase: PhaseData };

const reducer = (draft: State, action: Action) => {
  switch (action.type) {
    case "pick batch": {
      draft.selectedBatch = action.selectedBatch;
      draft.selectedPhase = null;
      break;
    }

    case "reset batch": {
      draft.selectedBatch = null;
      draft.selectedPhase = null;
      break;
    }
    case "set batches": {
      draft.batches = action.batches;

      const filteringBy = [...action.batches]
        .sort((a, b) => b.batchId - a.batchId)
        .slice(0, 5);

      draft.filteredBatches = filteringBy;
      break;
    }
    case "filter batches": {
      draft.filteredBatches = action.batches;
      break;
    }

    case "pick date": {
      draft.from = action.from;
      draft.to = action.to;
      draft.selectedBatch = null;
      draft.selectedPhase = null;
      break;
    }

    case "pick phase": {
      if (draft.selectedPhase?.id === action.selectedPhase.id) {
        draft.selectedPhase = null;
        return;
      }
      draft.selectedPhase = action.selectedPhase;
      break;
    }

    default:
      break;
  }
};

const LyoContext = createContext<[State, React.Dispatch<Action>]>([
  {
    from: DateTime.now().minus({ years: 2 }).toJSDate(),
    to: new Date(),
    batches: [],
    filteredBatches: [],
    selectedBatch: null,
    selectedPhase: null,
  },
  () => {},
]);

export const LyoContextProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  return (
    <LyoContext.Provider
      value={useImmerReducer<State, Action>(reducer, {
        from: DateTime.fromISO("2021-12-10").toJSDate(),
        to: DateTime.fromISO("2022-03-07").toJSDate(),
        batches: [],
        filteredBatches: [],
        selectedBatch: null,
        selectedPhase: null,
      })}
    >
      {children}
    </LyoContext.Provider>
  );
};

export const useLyoContext = () => useContext(LyoContext);
