import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { descriptiveAnalytic } from "../../types";

export type GetDescriptiveAnalyticsResponse =
  | ThingworxError
  | GetDescriptiveAnalyticsResult;

type Payload = {
  machineName: string;
};

const getDescriptiveAnalyticsResult = z.object({
  response: z.literal(true),
  analyticsList: z.array(descriptiveAnalytic),
});

export type GetDescriptiveAnalyticsResult = z.infer<
  typeof getDescriptiveAnalyticsResult
>;

export const useGetDescriptiveAnalytics = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();

  return useQuery<GetDescriptiveAnalyticsResult>({
    queryKey: ["descriptive-analytics-list", machine!.machine],
    queryFn: async () => {
      const response = await FetchClient<
        Payload,
        GetDescriptiveAnalyticsResponse
      >({
        appKey,
        payload: {
          machineName: machine!.machine,
        },
        // url: "dig.c.acopostrak_thing/Services/getDescriptiveAnalytics",
        url: "dig.c.acoposTrack_thing/Services/getDescriptiveAnalytics",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = getDescriptiveAnalyticsResult.parse(response);

      return validatedResponse;
    },
  });
};
