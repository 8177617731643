import { BasicTable } from "@/components/BasicTable";
import { useTranslate } from "@/i18n/config";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

export const RecipeModalContent = ({
  recipeName,
  handleClose,
  openModal,
  recipeContent = [],
}: {
  recipeName: string;
  handleClose: () => void;
  openModal: boolean;
  recipeContent?: { [key: string]: unknown }[];
}) => {
  return (
    <Dialog onClose={handleClose} open={openModal} fullWidth>
      <DialogTitle>{recipeName}</DialogTitle>
      <DialogContent>
        {recipeContent ? <ModalContent values={recipeContent} /> : null}
      </DialogContent>
    </Dialog>
  );
};

type TableColumn = {
  field: string;
  headerName: string;
};

interface Props {
  values: { [key: string]: unknown }[];
}

function ModalContent({ values }: Props) {
  const translate = useTranslate();
  // if (values.length === 0) return null;
  const columns: TableColumn[] = values.length
    ? Object.keys(values[0]).map((key) => ({
        field: key,
        headerName:
          key === "Name"
            ? translate("name")
            : key === "Value"
            ? translate("value")
            : key === "Recipe"
            ? translate("machine.recipe")
            : key === "Duration"
            ? translate("duration")
            : key,
      }))
    : [];
  const rows = values.length
    ? values.map((value, i) => ({
        id: i,
        ...value,
      }))
    : [];

  return <BasicTable columns={columns} rows={rows} fileName="recipe_data" />;
}
