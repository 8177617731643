import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { decompositionAnalytic } from "../../types";

export type GetDecompositionAnalyticsResponse =
  | ThingworxError
  | GetDecompositionAnalyticsResult;

const getDecompositionAnalyticsResult = z.object({
  response: z.literal(true),
  analyticsList: z.array(decompositionAnalytic),
});

export type GetDecompositionAnalyticsResult = z.infer<
  typeof getDecompositionAnalyticsResult
>;

export const useGetDecompositionAnalytics = () => {
  const { appKey } = useFirebaseContext();

  return useQuery<GetDecompositionAnalyticsResult>({
    queryKey: ["decomposition-analytics-list"],
    queryFn: async () => {
      const response = await FetchClient<
        object,
        GetDecompositionAnalyticsResponse
      >({
        appKey,
        payload: {},
        // url: "dig.c.acopostrak_thing/Services/getDecompositionAnalytics",
        url: "dig.c.acoposTrack_thing/Services/getDecompositionAnalytics",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = getDecompositionAnalyticsResult.parse(response);

      return validatedResponse;
    },
  });
};
