import { useFirebaseContext } from "@/context/firebase-context";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { ThingworxError } from "src/types/error";
import { FetchClient } from "@/services/ApiClient";
type Response = ThingworxError | { response: true };

type Payload = {
  ruleName: string;
};

export const useDeleteRule = () => {
  const { appKey, user, platformLvl } = useFirebaseContext();
  const account = user?.email as string;
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (payload: Payload) => {
      return await FetchClient<Payload, Response>({
        appKey,
        payload,
        url: "dig.c.event_thing/Services/deleteAlertRuleEngine",
      });
    },
    onSuccess(data) {
      if (data.response) {
        toast.success("Rule engine deleted successfully");
        client.invalidateQueries({
          queryKey: ["list-of-rules", account, platformLvl],
        });
      }
    },
  });
};
