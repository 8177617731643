import { SeriesColumnOptions } from "highcharts";
import { StackedHistogramData } from "../types";
import { readablePhase } from "./readablePhase";

//! probabilmente da modificare perché posso avere sottofasi ripetute e mi si incasinano i dati
export const parseStackedHistogramData = (
  data: StackedHistogramData[],
): { categories: string[]; series: SeriesColumnOptions[] } => {
  const categories: string[] = [];
  const series: SeriesColumnOptions[] = [];
  data.forEach((batch, batchIndex) => {
    categories.push(batch.name);
    batch.phaseData.forEach((subphase, subphaseIndex) => {
      if (batchIndex === 0) {
        series.push({
          type: "column",
          name: readablePhase(subphase.name),
          data: [subphase.duration / 1000 / 60],
        });
      } else {
        series[subphaseIndex].data?.push(subphase.duration / 1000 / 60);
      }
    });
  });

  //? per avere in alto le fasi più recenti
  series.reverse();

  return { categories, series };
};
