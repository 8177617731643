import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { Shift } from "../../types";
import { QueryBuilder } from "@/utils/query-builder";

type KpiStreamResponse = ThingworxError | KpiStreamResult;

const KpiData = z.object({
  Availability: z.array(z.array(z.number())),
  Quality: z.array(z.array(z.number())),
  Performance: z.array(z.array(z.number())),
  Oee: z.array(z.array(z.number())),
});

type KpiData = z.infer<typeof KpiData>;

const KpiStreamResult = z.object({
  response: z.literal(true),
  data: z.array(KpiData),
  chartName: z.string(),
  configuration: z.string(),
  shiftList: z.array(Shift),
});

type KpiStreamResult = z.infer<typeof KpiStreamResult>;

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
  dateStart?: DateTime;
  dateEnd?: DateTime;
};

export const useGetKpiStreamSelector = () => {
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();
  const { timeSelection, dates } = useTimeSelection();

  const queryKey = QueryBuilder.buildWithCondition({
    baseQuery: [
      "machinePerformance-sentinel-KpiSelector",
      timeSelection,
      machine?.machine,
    ],
    condition: timeSelection === "Custom",
    query: [dates.dateStart, dates.dateEnd],
  });

  const payload =
    timeSelection === "Custom"
      ? {
          machineName: machine?.machine || "",
          dateStart: dates.dateStart,
          dateEnd: dates.dateEnd,
          timeSelection: timeSelection,
        }
      : {
          machineName: machine?.machine || "",
          timeSelection: timeSelection,
        };

  return useQuery<KpiStreamResult>({
    queryKey,
    staleTime: 0,
    queryFn: async () => {
      const response = await FetchClient<Payload, KpiStreamResponse>({
        appKey,
        payload,
        url: "dig.c.machinePerformance_thing/Services/getKpiStreamSelector",
      });

      if (!response.response) throw new Error(response.errorString);

      return KpiStreamResult.parse(response);
    },
    refetchInterval: timeSelection === "Custom" ? false : 1000 * 20,
  });
};
