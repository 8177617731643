import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type ProcessDataListResponse = ThingworxError | ProcessDataListResult;

const ProductionPeriodItem = z.object({
  dataStartTimeLapse: z.number(),
  dataEndTimeLapse: z.number(),
  timeZoneDifference: z.number(),
  id: z.number(),
  timeLapse: z.string(),
});

export type ProductionPeriodItem = z.infer<typeof ProductionPeriodItem>;

const ProcessDataListResult = z.object({
  Result: z.array(ProductionPeriodItem),
});

export type ProcessDataListResult = z.infer<typeof ProcessDataListResult>;

type Payload = {
  machineName: string;
  dateStart: DateTime;
  dateEnd: DateTime;
  timeSelection: TimeSelection;
};

export const useProcessGetDataList = () => {
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();
  const { timeSelection, dates } = useTimeSelection();

  return useQuery<ProcessDataListResult>({
    queryKey: [
      "machinedetail-historic",
      "shift-list",
      machine!.machine,
      timeSelection,
      dates.dateStart,
      dates.dateEnd,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, ProcessDataListResponse>({
        appKey,
        payload: {
          machineName: machine!.machine,
          dateStart: dates.dateStart,
          dateEnd: dates.dateEnd,
          timeSelection,
        },
        url: "dig.c.historicMachineDetailProcess_thing/Services/getDataList",
      });

      if ("errorString" in response) {
        throw new Error(response.errorString);
      }

      return ProcessDataListResult.parse(response);
    },
    notifyOnChangeProps: ["data"],
  });
};
