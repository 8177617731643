import { Outlet } from "react-router-dom";
import { LyoContextProvider } from "../context/lyo-context";

export const LyophylizerPage = () => {
  return (
    <LyoContextProvider>
      <Outlet />
    </LyoContextProvider>
  );
};
