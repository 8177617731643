import { Card } from "@/components/Layout/Card";
import { User, useGetColleagues } from "../../../lambda/useGetColleagues";
import { BasicTable } from "@/components/BasicTable";
import { TableParser } from "@/utils/TableParser";
import { GridRenderCellParams } from "@mui/x-data-grid-premium";
import { RoleSelectCell } from "./ManageUsersTableCells/RoleSelectCell";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { Typography } from "@mui/material";
import { ToggleEnabledCell } from "./ManageUsersTableCells/ToggleEnabledCell";
import { DeleteUserCell } from "./ManageUsersTableCells/DeleteUserCell";
import { useTranslate } from "@/i18n/config";

export const ROLE_NUMBER_TO_STRING = {
  0: "Super User Sentinel", // SUPER_USER_SENTINEL
  2: "Super User", // SUPER_USER
  3: "Admin", // USER_ADMIN
  4: "Base User", // USER
} as const;

export const ManageUsersTable = () => {
  const { data, error, isLoading } = useGetColleagues();
  const translate = useTranslate();

  if (isLoading) {
    return <SkeletonCard height={300} />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  const { columns, rows } = TableParser.parseData<User>({
    data: data,
    columnsMap: {
      email: translate("email"),
      isEnabled: translate("actions.toggle_switch"),
      role: translate("role"),
    },
    columnsOrder: ["email", "role", "isEnabled"],
    customColumns: [
      {
        field: "actions",
        type: "actions",
        headerName: "delete user",
        getActions: (params) => [
          params.row.role === 3 || params.row.role === 4 ? (
            <DeleteUserCell user={params.row.email} />
          ) : (
            <></>
          ),
        ],
      },
    ],
  });

  const isEnabledColumnDefinition = columns.findIndex(
    (item) => item.field === "isEnabled",
  );

  if (isEnabledColumnDefinition !== -1) {
    columns[isEnabledColumnDefinition].renderCell = (
      params: GridRenderCellParams<User>,
    ) => {
      const { row } = params;
      const { email, isEnabled, role } = row;
      return role === 3 || role === 4 ? (
        <ToggleEnabledCell user={email} isEnabled={isEnabled} />
      ) : null;
    };
  }

  const roleColumnDefinition = columns.findIndex(
    (item) => item.field === "role",
  );

  if (roleColumnDefinition !== -1) {
    columns[roleColumnDefinition].renderCell = (
      params: GridRenderCellParams<User>,
    ) => {
      const { row } = params;
      const { email, role } = row;

      return role === 3 || role === 4 ? (
        <RoleSelectCell role={role} user={email} />
      ) : (
        <Typography>{ROLE_NUMBER_TO_STRING[role]}</Typography>
      );
    };
  }

  return (
    <Card>
      <BasicTable columns={columns} rows={rows} />
    </Card>
  );
};
