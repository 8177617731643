import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { segment } from "../../types";

export type GetSegmentsResponse = ThingworxError | GetSegmentsResult;

type Payload = {
  machineName: string;
};

const getSegmentsResult = z.object({
  response: z.literal(true),
  segments: z.array(segment),
});

export type GetSegmentsResult = z.infer<typeof getSegmentsResult>;

export const useGetSegments = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();

  return useQuery<GetSegmentsResult>({
    queryKey: ["segments-data", machine!.machine],
    queryFn: async () => {
      const response = await FetchClient<Payload, GetSegmentsResponse>({
        appKey,
        payload: {
          machineName: machine!.machine,
        },
        // url: "dig.c.acopostrak_thing/Services/getSegments",
        url: "dig.c.acoposTrack_thing/Services/getSegments",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = getSegmentsResult.parse(response);

      return validatedResponse;
    },
  });
};
