import { DateTime } from "luxon";
import { create } from "zustand";

type TimeRangeStore = {
  dateStart: DateTime;
  dateEnd: DateTime;
};

export const useCustomTimeRange = create<TimeRangeStore>(() => ({
  dateStart: DateTime.now().minus({ days: 7 }),
  dateEnd: DateTime.now(),
}));
