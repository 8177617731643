import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { NavigationLink } from "../../../constants/sentinelNavigations";

interface NavProps extends NavigationLink {
  open: boolean;
}
export const Nav: React.FC<NavProps> = ({
  icon: PropsIcon,
  text,
  to,
  open,
}) => {
  const { palette } = useTheme();

  return (
    <NavLink
      to={to}
      key={text}
      children={({ isActive }) => (
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              className="transition-colors"
              sx={{
                minWidth: 0,
                mr: open ? 1 : "auto",
                justifyContent: "center",
                color: isActive ? palette.background.paper : palette.info.light,
              }}
            >
              <PropsIcon />
            </ListItemIcon>
            <ListItemText
              primary={text}
              sx={{
                opacity: open ? 1 : 0,
              }}
            />
          </ListItemButton>
        </ListItem>
      )}
      style={({ isActive }) => ({
        display: "block",
        textDecoration: "none",
        backgroundColor: isActive ? palette.primary.main : undefined,
        color: isActive ? palette.background.paper : palette.text.primary,
      })}
    />
  );
};
