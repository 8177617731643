import { Sunburst } from "@/components/highcharts/sunburst/Sunburst";
import { Card } from "@/components/Layout/Card";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import {
  RejectSunburstData,
  useGetRejectSunburstData,
} from "../api/useGetRejectSunburstData";
import { useTranslate } from "@/i18n/config";

const parseSunburstDataForHighcharts = (
  data: RejectSunburstData,
): {
  id: string;
  parent: string;
  name: string;
  value?: number;
}[] => {
  const sunburstData = data.sunburstData;
  if (Object.keys(sunburstData).length === 0) return [];

  // rimuovo zone macchina dove la somma di tutti i child è 0
  for (const [key, val] of Object.entries(sunburstData)) {
    const values = Object.values(val);

    const sum = values.reduce((accumulator, value) => {
      return accumulator + value;
    }, 0);

    if (sum === 0) {
      delete sunburstData[key];
    }
  }

  const timeSelectionName = data.timeSelectionName;
  const dataForHighcharts: {
    id: string;
    parent: string;
    name: string;
    value?: number;
  }[] = [];

  dataForHighcharts.push({
    parent: "",
    name: timeSelectionName,
    id: "0.0",
  });

  const zoneIdObj: { [key: string]: string } = {};

  Object.keys(sunburstData).forEach((zone, i) => {
    const id = `1.${i + 1}`;
    dataForHighcharts.push({
      parent: "0.0",
      name: zone,
      id,
    });
    zoneIdObj[zone] = id;
  });

  let totalValue = 0;
  Object.keys(sunburstData).forEach((zone) => {
    Object.keys(sunburstData[zone]).forEach((cause) => {
      totalValue += sunburstData[zone][cause];
    });
  });

  Object.keys(sunburstData).forEach((zone, zoneIndex) => {
    Object.keys(sunburstData[zone]).forEach((cause, causeIndex) => {
      dataForHighcharts.push({
        parent: zoneIdObj[zone],
        name: cause,
        id: `2.${zoneIndex}.${causeIndex}`,
        value: +((100 / totalValue) * sunburstData[zone][cause]).toFixed(2),
      });
    });
  });

  // filtro i parent in ordine decrescente per valore dei children
  const parentObjects = dataForHighcharts.filter((obj) => obj.parent === "0.0");

  parentObjects.sort((a, b) => {
    const sumOfChildrenA = dataForHighcharts
      .filter((obj) => obj.parent === a.id)
      .reduce((sum, obj) => sum + (obj.value || 0), 0);

    const sumOfChildrenB = dataForHighcharts
      .filter((obj) => obj.parent === b.id)
      .reduce((sum, obj) => sum + (obj.value || 0), 0);

    return sumOfChildrenB - sumOfChildrenA;
  });

  // create a new array to hold the sorted objects
  const sortedDataForHighcharts: {
    id: string;
    parent: string;
    name: string;
    value?: number;
  }[] = [];

  // push the sorted parent objects to the beginning of the array
  parentObjects.forEach((parentObj) => {
    sortedDataForHighcharts.push(parentObj);
  });

  // push the remaining objects in their original order
  dataForHighcharts.forEach((obj) => {
    if (obj.parent !== "0.0" && obj.value !== 0) {
      sortedDataForHighcharts.push(obj);
    }
  });

  return sortedDataForHighcharts;
};

export const RejectCausesSunburst = () => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetRejectSunburstData();

  if (isLoading) {
    return <AnalyticContainerSkeleton />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }
  const sunburstData = parseSunburstDataForHighcharts(data);

  return (
    <Card>
      <Sunburst
        title={translate("reject_causes.rejects_distribution")}
        data={sunburstData}
      />
    </Card>
  );
};
