import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

const HistoricTimeLapsesResult = z.object({
  availableTS: z.array(
    z.object({
      dateStart: z.number(),
      id: z.number(),
      timelapse: z.string(),
    }),
  ),
  response: z.literal(true),
});
type HistoricTimeLapsesResult = z.infer<typeof HistoricTimeLapsesResult>;

type HistoricTimeLapsesResponse = ThingworxError | HistoricTimeLapsesResult;

type Payload = {
  machineName: string;
  dateStart: DateTime;
  dateEnd: DateTime;
  timelapse: TimeSelection;
};
export const useGetHistoricTimeLapses = () => {
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();
  const { timeSelection, dates } = useTimeSelection();

  return useQuery<HistoricTimeLapsesResult>({
    queryKey: [
      "get-historic-timelapse",
      machine?.machine,
      timeSelection,
      dates.dateStart,
      dates.dateEnd,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, HistoricTimeLapsesResponse>({
        appKey,
        url: "dig.c.configurableOee_thing/services/getHistoricTimelapses",
        payload: {
          machineName: machine!.machine,
          dateStart: dates.dateStart,
          dateEnd: dates.dateEnd,
          timelapse: timeSelection,
        },
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }
      return HistoricTimeLapsesResult.parse(response);
    },
  });
};
