import { TimeHelpers } from "@/utils/TimeHelpers";

export const formatDuration = (durationInMillis: number): string => {
  if (durationInMillis === 0) {
    return "0s";
  }
  const durationString = TimeHelpers.parseDurationToString({
    duration: durationInMillis,
    fmt: "d'd' h'h' m'm' s's",
  });
  const durationStringSplit = durationString
    .split(" ")
    .filter((part) => !part.startsWith("0"))
    .join(" ");
  return durationStringSplit;
};
