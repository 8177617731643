import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

const IsActiveResult = z.object({
  isActiveSat: z.boolean(),
  hasActivatedRequestAT: z.boolean(),
  response: z.literal(true),
});
type IsActiveResult = z.infer<typeof IsActiveResult>;

type IsActiveResponse = ThingworxError | IsActiveResult;
/**
 * @description useGetIsActive dice se il SAT è già attivo
 */
export const useGetIsActive = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  return useQuery<IsActiveResult>({
    queryKey: ["sat-isActive", machine?.machine],
    queryFn: async () => {
      const response = await FetchClient<
        { machineName: string },
        IsActiveResponse
      >({
        appKey,
        payload: {
          machineName: machine!.machine,
        },
        url: "dig.c.acceptanceTest_thing/Services/isActive",
      });
      if (!response.response) throw new Error(response.errorString);

      return IsActiveResult.parse(response);
    },
    /**
     * @param data  questa api verra refetchata in due occasioni:
     * 1. in attesa che il testa parta quando
     * 2. dopo aver chiuso un test
     */
    refetchInterval(data) {
      return !data?.isActiveSat && !data?.hasActivatedRequestAT ? false : 10000;
    },
  });
};
