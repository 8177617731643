import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

const PauseCheckResult = z.object({
  isPauseSat: z.boolean(),
  response: z.literal(true),
});
type PauseCheckResult = z.infer<typeof PauseCheckResult>;
type PauseCheckResponse = ThingworxError | PauseCheckResult;

export const usePauseCheck = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  return useQuery<PauseCheckResult>({
    queryKey: ["isPause-check", machine?.machine],
    queryFn: async () => {
      const response = await FetchClient<
        { machineName: string },
        PauseCheckResponse
      >({
        appKey,
        payload: {
          machineName: machine!.machine,
        },
        url: "dig.c.acceptanceTest_thing/Services/isPauseCheck",
      });
      if (!response.response) throw new Error(response.errorString);

      return PauseCheckResult.parse(response);
    },
  });
};
