import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import * as z from "zod";

const UserMachineAccess = z.object({
  factory_id: z.number(),
  company_name: z.string(),
  machine_id: z.string(),
  factory_name: z.string(),
  has_access: z.boolean(),
  machine_name: z.string(),
});
type UserMachineAccess = z.infer<typeof UserMachineAccess>;

const User = z.object({
  user_id: z.number(),
  machines: z.array(UserMachineAccess),
  user: z.string(),
});
export type UserVisibility = z.infer<typeof User>;

const UserMachinesAccessResult = z.object({
  response: z.literal(true),
  users: z.array(User),
});

type UserMachinesAccessResult = z.infer<typeof UserMachinesAccessResult>;

type UserMachinesAccessResponse = ThingworxError | UserMachinesAccessResult;
type Payload = {
  account: string;
};
export const useGetColleaguesMachinesAccess = () => {
  const { appKey, user } = useFirebaseContext();

  const email = user?.email as string;
  return useQuery<UserVisibility[]>({
    queryKey: ["colleagues-machines-access", email],
    queryFn: async () => {
      const response = await FetchClient<Payload, UserMachinesAccessResponse>({
        appKey,
        payload: { account: email },
        url: "dig.c.usersAndMachinesManagement_thing/Services/getUserCompanyMachines",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return UserMachinesAccessResult.parse(response).users ?? [];
    },
  });
};
