import { useContext } from "react";
import { MotorsDispatchContext } from "./motors-context";

export const useMotorsDispatchContext = () => {
  const context = useContext(MotorsDispatchContext);

  if (!context) {
    throw new Error(
      "You can only use this context inside MotorsDispatchContextProvider",
    );
  }
  return context;
};
