/* eslint-disable @typescript-eslint/no-explicit-any */
export const sortObjectByKeys = (unordered: {
  [key: string]: any;
}): { [key: string]: any } => {
  const ordered = Object.keys(unordered)
    .sort()
    .reduce((obj: { [key: string]: any }, key) => {
      obj[key] = unordered[key];
      return obj;
    }, {});
  return ordered;
};
