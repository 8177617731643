import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

export const Modal = ({
  titleContent,
  bodyContent,
  actions,
  ...dialogProps
}: React.ComponentProps<typeof Dialog> & {
  titleContent: React.ReactNode;
  bodyContent: React.ReactNode;
  actions?: React.ReactNode;
}) => {
  return (
    <Dialog {...dialogProps}>
      <DialogTitle>{titleContent}</DialogTitle>
      <DialogContent>{bodyContent}</DialogContent>
      {actions ? <DialogActions>{actions}</DialogActions> : null}
    </Dialog>
  );
};
