import { Run } from "../../types";
import { ListItem, Typography } from "@mui/material";
import { useAcoposDispatchContext } from "../../context/acopos-context";
import { DeleteOutline } from "@mui/icons-material";

export const SelectedRun = ({ run }: { run: Run }) => {
  const dispatch = useAcoposDispatchContext();

  return (
    <ListItem sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography>{run.name}</Typography>
      <DeleteOutline
        sx={{ cursor: "pointer" }}
        onClick={() => dispatch({ type: "REMOVE_RUN", item: run })}
      />
    </ListItem>
  );
};
