import { z } from "zod";
import { Box, Stack } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";
import { useGetDescriptiveAnalytics } from "../../api/Monitoring/useGetDescriptiveAnalytics";
import { ContextInformation } from "@/features/Motors/components/Layouts/ContextInformation";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { PieChart } from "@/components/highcharts/piechart/Piechart";
import { parsePieData } from "../../utils/parsePieData";
import { ColumnChart } from "@/components/highcharts/columnchart/Columnchart";
import { parseGroupedHistogramData } from "../../utils/parseGroupedHistogramData";
import { parseHistogramData } from "../../utils/parseHistogramData";
import { parseStackedHistogramData } from "../../utils/parseStackedHistogramData";
import { LineAndAreaRange } from "@/components/highcharts/linechart/LineAndAreaRange";
import { parseLineWithAreaData } from "../../utils/parseLineWithAreaData";
import { StackedColumnchart } from "@/components/highcharts/columnchart/StackedColumnchart";
import { CHART_COLORS } from "../../utils/chartColors";
import { StatesLogLegend } from "@/features/OverallLineEfficiency/components/StatesLogLegend";
import { EVALUATION_COLORS } from "../../utils/evaluationColors";
import { parseUom } from "../../utils/parseUom";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { parseUomAxisLabel } from "../../utils/parseUomAxisLabel";

export const DescriptiveAnalyticsChart = ({
  analyticId,
  phaseId,
}: {
  analyticId: number;
  phaseId: number;
}) => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetDescriptiveAnalytics({
    analyticId,
    phaseId,
  });

  if (isLoading) return <SkeletonCard width="calc(100% - 320px)" height={400} />;

  if (error)
    return (
      <Card sx={{ width: "calc(100% - 320px)", height: 400 }}>
        {translate("user_feedback.an_error_occurred")}
      </Card>
    );

  if (!data)
    return (
      <Card sx={{ width: "calc(100% - 320px)", height: 400 }}>
        {translate("user_feedback.no_data")}
      </Card>
    );

  if (data.chartData.length === 0)
    return (
      <Card sx={{ width: "calc(100% - 320px)", height: 400 }}>
        {translate("user_feedback.no_data")}
      </Card>
    );

  return (
    <Stack gap={2} width="calc(100% - 320px)">
      {data.chartData.map((chart, i) => (
        <Card key={JSON.stringify(chart.contextInfo) + i}>
          <ContextInformation props={chart.contextInfo} />
          {chart.chartType === "pie" && (
            <PieChart.Custom
              title=""
              chartOptions={{ height: 400 }}
              tooltipOptions={{
                formatter: function () {
                  const clonedData = [...chart.data];
                  const elementsByEvaluation = clonedData.filter((el) =>
                    this.key === "Passed" ? el.evaluation === "good" : el.evaluation === "bad",
                  );
                  if (elementsByEvaluation.length > 0) {
                    let htmlList = "";
                    elementsByEvaluation.forEach((el) => (htmlList += `${el.name}<br>`));
                    return `
                      <b>${this.key}</b>: ${this.y}%<br>
                      ${htmlList}
                    `;
                  } else {
                    return `<b>${this.key}</b>: ${this.y}%`;
                  }
                },
              }}
              seriesOptions={{
                type: "pie",
                data: parsePieData({ data: chart.data, translate }),
              }}
            />
          )}
          {chart.chartType === "histogram" && (
            <Stack>
              <ColumnChart.Custom
                title=""
                height={400}
                uom=""
                tooltip={{
                  enabled: true,
                  options: {
                    formatter: function () {
                      const parseResult = z
                        .object({
                          points: z.array(
                            z.object({
                              point: z.object({
                                category: z.string(),
                                y: z.number(),
                                color: z.string(),
                              }),
                              series: z.object({
                                name: z.string(),
                              }),
                            }),
                          ),
                        })
                        .safeParse(this);
                      if (!parseResult.success) return "";

                      const {
                        point: { category, y, color },
                        series: { name },
                      } = parseResult.data.points[0];
                      return `
                        ${category}<br>
                        <span style="color:${color}">● </span>${name}: <b>${
                          chart.analyticName === "Leak rate"
                            ? y.toFixed(2) + "%"
                            : TimeHelpers.parseDurationToString({
                                duration: y * 60 * 1000,
                              })
                        }</b>
                      `;
                    },
                  },
                }}
                xAxisOptions={{
                  categories: parseHistogramData({
                    data: chart.data,
                    isDuration: chart.analyticName !== "Leak rate",
                  }).categories,
                }}
                yAxisOptions={{
                  max:
                    chart.analyticName === "Leak rate"
                      ? Math.max(...chart.data.map((batch) => batch.value)) < 1
                        ? 1.1
                        : Math.max(...chart.data.map((batch) => batch.value))
                      : undefined,
                  plotLines:
                    chart.analyticName === "Leak rate"
                      ? [
                          {
                            value: 1,
                            color: "red",
                            dashStyle: "Dash",
                            width: 2,
                            zIndex: 5,
                            acrossPanes: true,
                            label: {
                              text: "critical",
                              align: "right",
                              x: -10,
                              style: {
                                color: "red",
                              },
                            },
                          },
                        ]
                      : [],
                }}
                yAxisTitleOptions={{
                  text: chart.analyticName === "Leak rate" ? "% vol/hr" : "Duration (minutes)",
                  style: { color: "white" },
                }}
                series={[
                  {
                    type: "column",
                    name: chart.analyticName,
                    data: parseHistogramData({
                      data: chart.data,
                      isDuration: chart.analyticName !== "Leak rate",
                    }).seriesData,
                  },
                ]}
                legend={{ enabled: false }}
              ></ColumnChart.Custom>
              <Box alignSelf="center">
                <StatesLogLegend
                  legendData={{
                    Passed: EVALUATION_COLORS.good.selected,
                    Failed: EVALUATION_COLORS.aborted.selected,
                  }}
                />
              </Box>
            </Stack>
          )}
          {chart.chartType === "grouped_histogram" && (
            <ColumnChart.Custom
              title={chart.analyticName}
              height={400}
              uom=""
              yAxisTitleOptions={{
                text: parseUomAxisLabel("pascal"),
                style: { color: "white" },
              }}
              tooltip={{ enabled: true, options: { valueSuffix: " Pa" } }}
              xAxisOptions={{
                categories: parseGroupedHistogramData(chart.data).categories,
              }}
              series={parseGroupedHistogramData(chart.data).series}
              legend={{ enabled: true }}
            ></ColumnChart.Custom>
          )}
          {chart.chartType === "stacked_histogram" && (
            <StackedColumnchart
              title={chart.analyticName}
              height={400}
              uom=""
              tooltip={{
                enabled: true,
                options: {
                  formatter: function () {
                    const parseResult = z
                      .object({
                        point: z.object({
                          category: z.string(),
                          y: z.number(),
                          color: z.string(),
                        }),
                        series: z.object({
                          name: z.string(),
                        }),
                      })
                      .safeParse(this);
                    if (!parseResult.success) return "";

                    const {
                      point: { category, y, color },
                      series: { name },
                    } = parseResult.data;
                    return `
                      ${category}<br>
                      <span style="color:${color}">● </span>${name}: <b>${TimeHelpers.parseDurationToString(
                        { duration: y * 60 * 1000 },
                      )}</b>
                    `;
                  },
                },
              }}
              highchartsChartOptions={{ colors: CHART_COLORS }}
              yAxisTitleOptions={{
                text: "Duration (minutes)",
                style: { color: "white" },
              }}
              xAxisOptions={{
                categories: parseStackedHistogramData(chart.data).categories,
              }}
              series={parseStackedHistogramData(chart.data).series}
              legend={{ enabled: true, options: { reversed: true } }}
            ></StackedColumnchart>
          )}
          {chart.chartType === "line_with_area" && (
            <LineAndAreaRange
              title=""
              chartOptions={{ height: 400 }}
              yAxis={[
                {
                  uom: "",
                  series: parseLineWithAreaData({
                    lineData: chart.lineData,
                    areaData: chart.areaData,
                  }).seriesData,
                  options: {
                    title: { text: "ppm", style: { color: "white" } },
                  },
                },
              ]}
              legend={{ enabled: true }}
              xAxisOptions={
                parseLineWithAreaData({
                  lineData: chart.lineData,
                  areaData: chart.areaData,
                }).xAxesOptions
              }
              showMarkerOnEachEntry={false}
              tooltip={{
                enabled: true,
                options: { shared: true, valueSuffix: " " + parseUom("ppm") },
              }}
            />
          )}
        </Card>
      ))}
    </Stack>
  );
};
