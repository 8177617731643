import { useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { ArrowUpward, AttachFile, Close } from "@mui/icons-material";
import { TextField, IconButton, Box, Typography, Stack } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { intellectaColors } from "../../utilities/colors";
import { colors } from "@/styles/colors";
import { getImageFromFileType } from "../../utilities/getImageFromFileType";
import { userConfigurationStore } from "../../store/user-configuration-store";

const isEnabledUploadFiles = false;

export const ChatInput = ({
  onSubmit,
  value,
  onChange,
  disabled = false,
}: {
  onSubmit: () => void;
  value: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}) => {
  const translate = useTranslate();
  const { version, machine } = userConfigurationStore();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleUploadFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (!file.type.startsWith("image/")) {
        toast.error(translate("intellecta.error_upload_file"));
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        return;
      }
      setSelectedFile(file);
      // Gestisci l'upload del file qui
      console.log("File selezionato:", file);
      // Puoi aggiungere qui la logica per caricare il file al server o gestirlo come necessario
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center">
      {selectedFile && (
        <Box
          sx={{
            width: "80%",
            backgroundColor: intellectaColors.chatInputBackground,
            p: 0.5,
            border: "1px solid rgba(255 ,255, 255, 0.23)",
            borderBottom: 0,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            p={1}
            borderRadius={1}
            width="fit-content"
            sx={{
              backgroundColor: colors.palette.darkBlue,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: intellectaColors.unreadNotificationBackground,
                borderRadius: 1,
                height: 40,
                width: 40,
              }}
            >
              {getImageFromFileType(selectedFile.type)}
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography variant="caption" sx={{ flexGrow: 1 }}>
                {selectedFile.name}
              </Typography>
              <Typography variant="caption" sx={{ flexGrow: 1, color: "#aaa" }}>
                ({selectedFile.type})
              </Typography>
            </Box>
            <IconButton
              size="small"
              onClick={handleRemoveFile}
              sx={{
                alignSelf: "flex-start",
                justifySelf: "flex-end",
              }}
            >
              <Close />
            </IconButton>
          </Stack>
        </Box>
      )}
      <TextField
        placeholder={translate("intellecta.type_a_message")}
        variant="outlined"
        disabled={disabled}
        value={value}
        multiline
        maxRows={10}
        onKeyDown={(e) => {
          const { key, shiftKey } = e.nativeEvent;

          if (key === "Enter" && !shiftKey) {
            e.preventDefault();
            if (value === "") return;
            if (version === "troubleshooting" && !machine) {
              toast.error(translate("intellecta.no_machine_selected"));
            } else {
              onSubmit();
            }
          }
        }}
        onChange={onChange}
        size="small"
        InputProps={{
          sx: {
            backgroundColor: intellectaColors.chatInputBackground,
            p: 1,
            pl: 2,
            borderTopLeftRadius: selectedFile ? 0 : 4,
            borderTopRightRadius: selectedFile ? 0 : 4,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
          },
          startAdornment: isEnabledUploadFiles && (
            <IconButton
              onClick={handleUploadFile}
              size="small"
              sx={{ alignSelf: "flex-start" }}
            >
              <AttachFile />
            </IconButton>
          ),
          endAdornment: (
            <IconButton
              disabled={disabled || value === ""}
              onClick={onSubmit}
              type="submit"
              size="small"
              sx={{ alignSelf: "flex-end", m: 0, p: 0 }}
            >
              <ArrowUpward />
            </IconButton>
          ),
        }}
        sx={{ width: "80%" }}
      />
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </Box>
  );
};
