import { useMachineContext } from "@/context/machine-context";
import { useEffect, useState } from "react";
import { PageName } from "src/constants/sentinelNavigations";

export const useCheckMachinePage = (page: PageName) => {
  const { allPages } = useMachineContext();
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    const hasAccess = allPages.length && allPages.find((p) => p === page);

    setHasAccess(!!hasAccess);
  }, [page, allPages]);

  return {
    hasAccess,
  };
};
