import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { Stack, useMediaQuery } from "@mui/material";
import { useAcceptanceTestContext } from "../context/acceptanceTest-context";

export const AcceptanceTestLoader = () => {
  const { viewMode } = useAcceptanceTestContext();
  const smallScreen = useMediaQuery("(min-width:900px)");
  return (
    <>
      {smallScreen ? (
        <Stack direction="column" gap={2}>
          <Stack direction="row" gap={2} width="100%">
            <Stack direction="column" gap={1} width="25%">
              <SkeletonCard width={"100%"} height={"100%"} />
              <SkeletonCard width={"100%"} height={"100%"} />
              <SkeletonCard width={"100%"} height={"100%"} />
            </Stack>
            <Stack direction="column" gap={2} width="75%">
              <Stack direction="row" gap={2} width="100%">
                <SkeletonCard />
                <Stack direction="column" gap={1} width="auto">
                  {viewMode === "Live" ? <SkeletonCard width={300} /> : null}
                  <SkeletonCard width={300} />
                  <SkeletonCard width={300} />
                </Stack>
              </Stack>
              <SkeletonCard height={150} />
            </Stack>
          </Stack>
          <SkeletonCard height={200} />
          <SkeletonCard height={200} />
        </Stack>
      ) : (
        <Stack direction="column" gap={2}>
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard height={100} />
          <SkeletonCard height={200} />
          <SkeletonCard height={100} />
          <SkeletonCard height={200} />
          <SkeletonCard height={200} />
          <SkeletonCard height={200} />
        </Stack>
      )}
    </>
  );
};
