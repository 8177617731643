import { SVGProps } from "react";

const LineCategory = ({
  height = 24,
  fill = "#1A98C9",
  ...rest
}: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    fill={fill}
    {...rest}
  >
    <g clipPath="url(#clip0_1249_5504)">
      <g>
        <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM0.718716 12C0.718716 18.2305 5.76952 23.2813 12 23.2813C18.2305 23.2813 23.2813 18.2305 23.2813 12C23.2813 5.76952 18.2305 0.718716 12 0.718716C5.76952 0.718716 0.718716 5.76952 0.718716 12Z" />
        <path d="M21.7031 8.27532C21.8685 8.21181 22.0547 8.29438 22.1132 8.46164C22.9401 10.825 22.9131 13.4039 22.0368 15.7494C21.9748 15.9154 21.787 15.994 21.6229 15.927C21.4589 15.8601 21.3806 15.6729 21.4424 15.5067C22.2573 13.3126 22.2826 10.9025 21.5137 8.69175C21.4555 8.52435 21.5376 8.33883 21.7031 8.27532Z" />
        <path d="M2.50516 16.2273C2.34326 16.2995 2.15308 16.2267 2.08587 16.0627C1.13639 13.746 1.02839 11.1691 1.78067 8.78105C1.83393 8.61204 2.01735 8.52366 2.18472 8.58196C2.35209 8.64025 2.43998 8.8231 2.38705 8.99223C1.68806 11.226 1.78898 13.6341 2.67245 15.8016C2.73934 15.9657 2.66706 16.1553 2.50516 16.2273Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.53955 8.20405L9.36737 3.30612L12.1952 8.20405H9.85719V14.9388C9.85719 15.2093 9.63792 15.4286 9.36737 15.4286C9.09688 15.4286 8.87761 15.2093 8.87761 14.9388V8.20405H6.53955Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5714 8.63263C14.8419 8.63263 15.0612 8.8519 15.0612 9.12246V15.8571H17.3992L14.5714 20.7551L11.7436 15.8571H14.0816V9.12246C14.0816 8.8519 14.3009 8.63263 14.5714 8.63263Z"
        />
      </g>
    </g>
    <defs>
      <clipPath>
        <rect width={24} height={24} />
      </clipPath>
    </defs>
  </svg>
);

export { LineCategory };
