import { SeriesScatterOptions } from "highcharts";
import { useTranslate } from "@/i18n/config";
import { ScatterPlot } from "@/components/highcharts/scatter/Scatter";

interface MeanPoint {
  x: number;
  y: number;
  color: string;
}

export const DailyMeanCurrentChart = ({
  title,
  seriesData,
}: {
  title: string;
  seriesData: SeriesScatterOptions["data"];
}) => {
  const translate = useTranslate();

  return (
    <ScatterPlot.Custom
      title={title}
      zoomType="xy"
      yAxis={[
        {
          uom: "",
          series: [
            {
              type: "scatter",
              name: translate("motors.mean_current"),
              color: "#2AC3FF",
              data: seriesData,
            },
          ],
          options: { title: { text: "ampere", style: { color: "#fff" } } },
        },
      ]}
      tooltip={{
        enabled: true,
        options: {
          formatter: function () {
            const point = this.point as unknown as MeanPoint;
            if (point) {
              return `
                  <span style="color:${point.color}">●</span> ${translate(
                    "motors.mean_current",
                  )}<br>
                  ${new Date(point.x).toLocaleDateString()}<br>
                  ${translate("value")}: <b>${point.y}</b>
                `;
            }
            return `
                ${translate("motors.mean_current")}<br>
                ${new Date(this.x!).toLocaleDateString()}<br>
              `;
          },
        },
      }}
      scrollbarEnabled={false}
    />
  );
};
