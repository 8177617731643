import { Box, Typography } from "@mui/material";
import { ResponsiveRadialBar } from "@nivo/radial-bar";

/**
 *
 * NOTE: specify height in parent component
 * @param title (string): Chart title
 * @param max (number): Max possible value
 * @param value (number): Actual value
 * @param label (string): Label to be shown
 * @param color (string): Chart color
 * @returns
 */
export const CircularGauge = ({
  title,
  max,
  value,
  label,
  color = "#ff00aa",
}: {
  title: string;
  max: number;
  value: number;
  label: string;
  color?: string;
}) => {
  return (
    <Box
      sx={{
        width: "100%", // Si adatta alla larghezza del parent
        height: "100%", // Si adatta all'altezza del parent
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 2,
        backgroundColor: "transparent",
      }}
    >
      {/* Titolo */}
      <Typography
        sx={{
          color: "white",
          fontSize: "1rem",
          textAlign: "center",
          marginBottom: "8px",
        }}
      >
        {title}
      </Typography>

      {/* Contenitore del grafico */}
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ResponsiveRadialBar
          data={[
            {
              id: "Current",
              data: [
                {
                  x: "blue",
                  y: value,
                  color,
                  tip: title,
                },
              ],
            },
          ]}
          startAngle={0}
          isInteractive={false}
          endAngle={360}
          circularAxisOuter={null}
          enableLabels={false}
          cornerRadius={90}
          innerRadius={0.8}
          colors={(dat) => dat.data.color}
          maxValue={max}
          enableRadialGrid={false}
          enableCircularGrid={false}
          radialAxisStart={null}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        >
          <Typography sx={{ fontSize: "1.5rem", fontWeight: "bold" }}>
            {label}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
