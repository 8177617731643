import { Card } from "@/components/Layout/Card";
import { Stack } from "@mui/material";
import { TableTitle } from "../layout/StyledText";
import { JsonViewer } from "@textea/json-viewer";
import { PcA } from "../api/useGetEdgePcData";
import { useTranslate } from "@/i18n/config";

export const Configuration = ({
  configurationData,
}: {
  configurationData: {
    jsonPcA: PcA["conf"];
    jsonPcB: PcA["conf"];
  };
}) => {
  const translate = useTranslate();
  const { jsonPcA, jsonPcB } = configurationData;
  return (
    <Stack gap={2} direction={{ md: "row", xs: "column" }}>
      <Card sx={{ height: 515 }}>
        <TableTitle>PC A {translate("configuration")}</TableTitle>
        <JsonViewer
          sx={{ height: "95%", overflow: "scroll" }}
          value={jsonPcA}
          style={{ backgroundColor: "transparent" }}
          theme={"dark"}
          displayDataTypes={false}
        />
      </Card>
      <Card sx={{ height: 515 }}>
        <TableTitle>PC B {translate("configuration")}</TableTitle>
        <JsonViewer
          sx={{ height: "95%", overflow: "scroll" }}
          value={jsonPcB}
          style={{ backgroundColor: "transparent" }}
          theme={"dark"}
          displayDataTypes={false}
        />
      </Card>
    </Stack>
  );
};
