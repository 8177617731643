import { PointOptionsObject, SeriesLineOptions } from "highcharts";
import { Box } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";
import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { ContextInformation } from "../Layouts/ContextInformation";
import {
  DataSchema1,
  ResSchema,
} from "../../api/useGetConditionMonitoringData";
import { ScatterPlot } from "@/components/highcharts/scatter/Scatter";
import { mapProfilingStatus } from "../../utils/mapProfilingStatus";
import { ScatterAndLine } from "@/components/highcharts/scatter/ScatterAndLine";
import { fileNameFactory } from "@/utils/fileNameFactory";

const ProcessProfilingData = ({ analyticName, data }: ResSchema) => {
  const translate = useTranslate();
  const maxLineData: number[][] = [];
  const meanLineData: number[][] = [];
  const minLineData: number[][] = [];
  const maxOutliers: PointOptionsObject[] = [];
  const meanOutliers: PointOptionsObject[] = [];
  const minOutliers: PointOptionsObject[] = [];

  if (analyticName !== "Current Profiling")
    return (
      <ScatterAndLine
        title={analyticName}
        lineSeries={[]}
        yAxis={[{ uom: "", series: [] }]}
      />
    );

  data.forEach((item) => {
    const {
      metric,
      maximum,
      minimum,
      mean,
      maximumStatus,
      minimumStatus,
      meanStatus,
    } = item;

    if (maximumStatus !== "good") {
      maxOutliers.push({
        x: metric,
        y: maximum,
        color: mapProfilingStatus(maximumStatus),
        marker: { symbol: "circle" },
      });
    } else {
      maxLineData.push([metric, maximum]);
    }
    if (meanStatus !== "good") {
      meanOutliers.push({
        x: metric,
        y: mean,
        color: mapProfilingStatus(meanStatus),
        marker: { symbol: "diamond" },
        // marker: { symbol: "circle" },
      });
    } else {
      meanLineData.push([metric, mean]);
    }
    if (minimumStatus !== "good") {
      minOutliers.push({
        x: metric,
        y: minimum,
        color: mapProfilingStatus(minimumStatus),
        marker: { symbol: "square" },
        // marker: { symbol: "circle" },
      });
    } else {
      minLineData.push([metric, minimum]);
    }
  });

  return (
    <ScatterAndLine
      title={analyticName}
      scrollbarEnabled={false}
      legend={{ enabled: true }}
      tooltip={{
        enabled: true,
        options: {
          pointFormatter: function () {
            if (this.series.type === "scatter") {
              return `
            date: <b>${new Date(this.x).toLocaleString()}</b><br>
            value: <b>${this.y}</b>
          `;
            }
            return `<span style='color: ${this.color}'>● </span>${this.series.name}: <b>${this.y}</b><br>`;
          },
        },
      }}
      lineSeries={[
        {
          type: "line",
          color: "#7cb5ec",
          lineWidth: 1,
          name: translate("math.maximum"),
          data: maxLineData,
        },
        {
          type: "line",
          color: "#7cb5ec",
          lineWidth: 1,
          name: translate("math.mean"),
          data: meanLineData,
        },
        {
          type: "line",
          color: "#7cb5ec",
          lineWidth: 1,
          name: translate("math.minimum"),
          data: minLineData,
        },
      ]}
      yAxis={[
        {
          uom: "",
          series: [
            {
              type: "scatter",
              color: "#ddd",
              zIndex: 1,
              name: `${translate("math.maximum")} ${translate(
                "statistics.outlier_other",
              )}`,
              marker: { symbol: "circle" },
              data: maxOutliers,
            },
            {
              type: "scatter",
              color: "#ddd",
              zIndex: 1,
              name: `${translate("math.mean")} ${translate(
                "statistics.outlier_other",
              )}`,
              marker: { symbol: "circle" },
              data: meanOutliers,
            },
            {
              type: "scatter",
              color: "#ddd",
              zIndex: 1,
              name: `${translate("math.minimum")} ${translate(
                "statistics.outlier_other",
              )}`,
              marker: { symbol: "circle" },
              data: minOutliers,
            },
          ],
        },
      ]}
    />
  );
};

const processLineData = (data: DataSchema1): SeriesLineOptions[] => {
  const seriesData: { [key: string]: number[][] } = {};
  const series: SeriesLineOptions[] = [];

  data.forEach((item) => {
    const { metric, value, threshold } = item;

    const keys = Object.keys(threshold);
    keys.push("value"); // aggiungo la chiave 'value'

    keys.forEach((key) => {
      if (!seriesData[key]) {
        seriesData[key] = [];
      }
      const valueToPush = key === "value" ? value : threshold[key];
      seriesData[key].push([metric, valueToPush]);
    });
  });

  for (const [key, val] of Object.entries(seriesData)) {
    let color = "#7cb5ec";
    if (key.toLowerCase().includes("warning")) {
      color = "#e8c52a";
    } else if (key.toLowerCase().includes("critical")) {
      color = "#D62422";
    }
    series.push({
      type: "line",
      name: key,
      data: val,
      color,
    });
  }

  return series;
};

export const ConditionMonitoringAnalytic = ({
  props,
}: {
  props: ResSchema;
}) => {
  const { analyticName, chartType, metricType, data, contextInfo, motorName } =
    props;

  return (
    <Card>
      <Box>
        <ContextInformation props={contextInfo} />
        {chartType === "line with limits" && (
          <LineChart.Custom
            title={analyticName}
            filename={fileNameFactory({
              name: analyticName,
              machine: motorName,
            })}
            xAxisType={metricType === "timestamp" ? "datetime" : "linear"}
            yAxis={[{ uom: "", series: processLineData(data) }]}
            // zoomType="x"
            legend={{ enabled: true }}
          ></LineChart.Custom>
        )}
        {chartType === "scatter" &&
          (analyticName === "Current Profiling" ? (
            <ProcessProfilingData {...props} />
          ) : (
            <ScatterPlot.Custom
              title={analyticName}
              scrollbarEnabled={false}
              legend={{
                enabled: true,
                options: {
                  itemHoverStyle: { color: "#aac47c" },
                },
              }}
              tooltip={{
                enabled: true,
                options: {
                  pointFormatter: function () {
                    return `
                    date: <b>${new Date(this.x).toLocaleString()}</b><br>
                    value: <b>${this.y}</b>
                  `;
                  },
                },
              }}
              yAxis={[
                {
                  uom: "",
                  series: [
                    {
                      type: "scatter",
                      name: analyticName,
                      data,
                      color: "#7cb5ec",
                    },
                  ],
                },
              ]}
              xAxisType={"linear"}
            ></ScatterPlot.Custom>
          ))}
        {chartType === "line" && (
          <LineChart.Custom
            title={analyticName}
            legend={{ enabled: true }}
            yAxis={[
              { uom: "", series: [{ type: "line", name: analyticName, data }] },
            ]}
          ></LineChart.Custom>
        )}
        {chartType === "histogram" && <p>istogramma (todo)</p>}
        {chartType === "area range with line" && <p>profiling 2 (todo)</p>}
      </Box>
    </Card>
  );
};
