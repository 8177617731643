import { useMachineContext } from "@/context/machine-context";
import { Navigate } from "react-router-dom";
import "./styles/styles.css";

export const MachineDetail = () => {
  const state = useMachineContext();

  if (!state.machine) return null;

  return (
    <>
      {state.machine.machineType === "cyclic" ? (
        <Navigate to={"/machine-detail/sentinel/live"} replace />
      ) : (
        <Navigate to={"/machine-detail/process/live"} replace />
      )}
    </>
  );
};
