import { Box, Stack } from "@mui/material";
import { BatchAnalytic } from "../BatchAnalytic/BatchAnalytic";
import { Batch } from "../../types";
import { Card } from "@/components/Layout/Card";

export const AnalyticContainer = (props: {
  name: string;
  batchId: number;
  selectedPhaseId: number;
  selectedPhaseName: string;
  batches: Batch[];
}) => {
  return (
    <Stack gap={2}>
      <Card
        key={props.batchId}
        initial={{ opacity: 0.5, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
      >
        <Stack direction="row" gap={6} height={40} alignItems="center">
          <Box sx={{ fontWeight: "bold" }}>{props.name}</Box>
        </Stack>
        <BatchAnalytic
          batches={props.batches}
          batchId={props.batchId}
          name={props.name}
          selectedPhaseId={props.selectedPhaseId}
          selectedPhaseName={props.selectedPhaseName}
        />
      </Card>
    </Stack>
  );
};
