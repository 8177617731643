import { useState } from "react";
import { Stack } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";
import { AnalyticCard } from "../components/Layouts/AnalyticCard";
import { useGetDataSummarySpecific } from "../api/useGetDataSummarySpecific";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { SummarySpecificAnalytics } from "../components/Summary/SummarySpecificAnalytics";

export interface BarSeriesData {
  name: string;
  color: string;
  data: number[];
}

export const SummarySpecific = ({
  motorId,
  motorName,
}: {
  motorId: number;
  motorName: string;
}) => {
  const [selectedAnalytic, setSelectedAnalytic] = useState<string | null>(null);
  const { data, isLoading, error } = useGetDataSummarySpecific();
  const translate = useTranslate();

  if (isLoading) {
    return (
      <Card sx={{ display: "flex" }}>
        <SkeletonCard height={160}></SkeletonCard>
      </Card>
    );
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  if (Object.keys(data).length === 0) {
    return (
      <Card sx={{ height: 160 }}>
        <div>{translate("motors.no_analytics_to_display")}</div>
      </Card>
    );
  }

  return (
    <Stack gap={1}>
      <Card sx={{ display: "flex", gap: "1rem", overflowX: "auto" }}>
        {data[motorId] &&
          Object.entries(data[motorId]).map(([key, value]) => (
            <AnalyticCard
              key={key}
              name={key}
              active={key === selectedAnalytic}
              setAnalytic={setSelectedAnalytic}
              info={value}
            />
          ))}
      </Card>
      {selectedAnalytic && (
        <SummarySpecificAnalytics
          motorId={motorId}
          motorName={motorName}
          analytic={selectedAnalytic}
        />
      )}
    </Stack>
  );
};
