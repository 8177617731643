import { Translations } from "@/i18n/config";
import { DateRange, PickersShortcutsItem } from "@mui/x-date-pickers-pro";
import { DateTime } from "luxon";

export type ShortCutsRange = "24h" | "3d" | "7d" | "14d" | "30d";

/**
 *
 * @param translate callback for translations
 * @param unavailableShortcuts array of shortcuts to disable
 * @description to disable a shortcut you have return non validate pairs of dates
 */
export const shortcutsItems = (
  translate: (arg: Translations) => string,
  unavailableShortcuts?: ShortCutsRange[],
): PickersShortcutsItem<DateRange<DateTime>>[] => {
  const allShortcuts = [
    {
      key: "24h" as ShortCutsRange,
      label: translate("date.last_24_hours"),
      getValue: (): DateRange<DateTime> => {
        const today = DateTime.now();
        const lastDay = today.minus({ days: 1 });
        return unavailableShortcuts?.includes("24h")
          ? [today, lastDay]
          : [lastDay, today];
      },
    },
    {
      key: "3d" as ShortCutsRange,
      label: translate("date.last_3_days"),
      getValue: (): DateRange<DateTime> => {
        const today = DateTime.now();
        const last3Days = today.minus({ days: 3 });
        return unavailableShortcuts?.includes("3d")
          ? [today, last3Days]
          : [last3Days, today];
      },
    },
    {
      key: "7d" as ShortCutsRange,
      label: translate("date.last_7_days"),
      getValue: (): DateRange<DateTime> => {
        const today = DateTime.now();
        const last7Days = today.minus({ days: 7 });
        return unavailableShortcuts?.includes("7d")
          ? [today, last7Days]
          : [last7Days, today];
      },
    },
    {
      key: "14d" as ShortCutsRange,
      label: translate("date.last_14_days"),
      getValue: (): DateRange<DateTime> => {
        const today = DateTime.now();
        const last14Days = today.minus({ weeks: 2 });
        return unavailableShortcuts?.includes("14d")
          ? [today, last14Days]
          : [last14Days, today];
      },
    },
    {
      key: "30d" as ShortCutsRange,
      label: translate("date.last_30_days"),
      getValue: (): DateRange<DateTime> => {
        const today = DateTime.now();
        const last30Days = today.minus({ months: 1 });
        return unavailableShortcuts?.includes("30d")
          ? [today, last30Days]
          : [last30Days, today];
      },
    },
  ];
  return allShortcuts;
};
