import { Button } from "@mui/material";
import { forwardRef } from "react";

export const SquaredRadioButton = forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button> & { activeValue: unknown }
>(({ children, value, activeValue, ...props }, ref) => {
  const active = value === activeValue;

  return (
    <Button
      ref={ref}
      sx={{
        width: "128px",
        height: "128px",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        border: "2px solid #ffffff50",
        backgroundColor: active ? "#ffffff50" : "#ffffff05",
        cursor: "pointer",
        transition: "all 0.3s ease",
        "&:hover": {
          backgroundColor: active ? "#ffffff50" : "#ffffff10",
        },
      }}
      {...props}
    >
      {children}
    </Button>
  );
});
