import { Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { QueryStats } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { useTranslate } from "@/i18n/config";
import { useTimeSelection } from "@/store/useTimeSelection";
import { useCheckMachinePage } from "@/hooks/check-machine-page";
import { BasicTable } from "@/components/BasicTable";
import { Card } from "@/components/Layout/Card";
import { TableParser } from "@/utils/TableParser";
import { ShowEmptyData } from "@/components/ShowEmptyData";
import { AlarmsTableData } from "../api/useGetAlarmsTableData";
import { useFormatTimestamp } from "@/utils/TimeHelpers";

export const AlarmsDetailTable = ({ data }: { data: AlarmsTableData[] }) => {
  const { hasAccess } = useCheckMachinePage("stateLog");
  const translate = useTranslate();
  const { formatTimestamp } = useFormatTimestamp();
  const navigate = useNavigate();

  if (!data.length) return <ShowEmptyData title={translate("alarms.alarms_detail")} />;

  const { rows, columns } = TableParser.parseData<AlarmsTableData>({
    data: data,
    columnsMap: {
      EfficiencyFamilyDescription: translate("losses.efficiency_losses"),
      durationms: translate("duration"),
      ErrorDescription: translate("description"),
      Error: translate("code"),
      Family: translate("family"),
      FamilyDescription: translate("family_description"),
      Timestamp: translate("start"),
      timestampEnd: translate("end"),
      Zone: "zone",
      ZoneDescription: translate("zone_description"),
    },
    durationFormat: "hh:mm:ss.SSS",
    dateFormatter: formatTimestamp,
    columnsOrder: [
      "Error",
      "Timestamp",
      "timestampEnd",
      "durationms",
      "ErrorDescription",
      "ZoneDescription",
      "Zone",
      "FamilyDescription",
      "EfficiencyFamilyDescription",
    ],
    omitColumns: ["Family", "Zone"],
    customColumns: hasAccess
      ? [
          {
            field: "actions",
            type: "actions",
            headerName: translate("details"),
            getActions: ({ row }) => {
              return [
                <GridActionsCellItem
                  icon={<QueryStats />}
                  label="Detail"
                  onClick={() => {
                    useTimeSelection.setState((prevState) => ({
                      ...prevState,
                      dates: {
                        dateStart: DateTime.fromMillis(row.Timestamp).minus({
                          minutes: 20,
                        }),
                        dateEnd: DateTime.fromMillis(row.timestampEnd).plus({
                          minutes: 20,
                        }),
                      },
                    }));
                    navigate("/state-log");
                  }}
                />,
              ];
            },
          },
        ]
      : [],
  });

  return (
    <Card>
      <Typography variant="h5" mb={2}>
        {translate("alarms.alarms_detail")}
      </Typography>
      <BasicTable columns={columns} rows={rows} fileName="alarms_detail_data" />
    </Card>
  );
};
