import {
  Typography,
  Stack,
  Box,
  IconButton,
  Tooltip,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import { Notification } from "../../types";
import { intellectaColors } from "../../utilities/colors";
import {
  FiberManualRecord,
  PushPin,
  PushPinOutlined,
  MarkunreadOutlined,
  DraftsOutlined,
  ShortText,
} from "@mui/icons-material";
import { useTranslate } from "@/i18n/config";
import { useState } from "react";

export const SocialNotification = ({
  notification,
  onUsePrompt,
}: {
  notification: Notification;
  onUsePrompt: (prompt: string) => void;
}) => {
  const translate = useTranslate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handlePin = (event: React.MouseEvent) => {
    event.stopPropagation();
    console.log("pinnare notification con id", notification.id);
  };

  const handleUnpin = (event: React.MouseEvent) => {
    event.stopPropagation();
    console.log("unpinnare notification con id", notification.id);
  };

  const handleRead = () => {
    console.log("segnare come letta notification con id", notification.id);
  };

  const handleUnread = () => {
    console.log("segnare come non letta notification con id", notification.id);
  };

  const handleUsePrompt = () => {
    onUsePrompt(notification.query);
    handleCloseMenu();
  };

  return (
    <>
      <Stack
        bgcolor={intellectaColors.unreadNotificationBackground}
        borderRadius={1}
        p={2}
        pt={1}
        sx={{
          cursor: "pointer",
          ":hover": {
            bgcolor: `${intellectaColors.unreadNotificationBackground}80`,
          },
        }}
        onClick={handleClickMenu}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="caption">
            {new Date(notification.timestamp * 1000).toLocaleString()}
          </Typography>
          <Box display="flex" alignItems="center">
            {!notification.is_read && (
              <FiberManualRecord
                sx={{
                  width: "16px",
                  mr: "4px",
                  color: intellectaColors.unreadNotificationDot,
                }}
              />
            )}
            {notification.is_pinned ? (
              <Tooltip placement="top" title={translate("actions.unpin")}>
                <IconButton onClick={handleUnpin}>
                  <PushPin fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip placement="top" title={translate("actions.pin")}>
                <IconButton onClick={handlePin}>
                  <PushPinOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
        <Typography variant="caption">
          <Typography variant="caption" component="span" fontWeight="bold">
            {notification.sending_user}
          </Typography>{" "}
          shared a response
        </Typography>
        <Typography pt={1}>{notification.answer}</Typography>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        id="historic-chat-menu"
        open={open}
        onClose={handleCloseMenu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "top" }}
      >
        {notification.is_pinned ? (
          <MenuItem onClick={handleUnpin}>
            <ListItemIcon>
              <PushPinOutlined fontSize="small" />
            </ListItemIcon>
            {translate("actions.unpin")}
          </MenuItem>
        ) : (
          <MenuItem onClick={handlePin}>
            <ListItemIcon>
              <PushPin fontSize="small" />
            </ListItemIcon>
            {translate("actions.pin")}
          </MenuItem>
        )}
        {notification.is_read ? (
          <MenuItem onClick={handleUnread}>
            <ListItemIcon>
              <MarkunreadOutlined fontSize="small" />
            </ListItemIcon>
            {translate("actions.mark_as_unread")}
          </MenuItem>
        ) : (
          <MenuItem onClick={handleRead}>
            <ListItemIcon>
              <DraftsOutlined fontSize="small" />
            </ListItemIcon>
            {translate("actions.mark_as_read")}
          </MenuItem>
        )}
        <Tooltip placement="top" title={notification.query}>
          <MenuItem onClick={handleUsePrompt}>
            <ListItemIcon>
              <ShortText fontSize="small" />
            </ListItemIcon>
            {translate("intellecta.use_prompt")}
          </MenuItem>
        </Tooltip>
      </Menu>
    </>
  );
};
