import { gCloudClient } from "@/services/gCloudClient";
import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { SERVER_ENUMERATOR } from "../config";

type ColleaguesResponse = { error: string } | ColleaguesResult;

export type ColleaguesResult = z.infer<typeof colleaguesResult>;

const userRole = z
  .union([z.literal(0), z.literal(2), z.literal(3), z.literal(4)])
  .transform((value) => value);

const user = z.object({
  email: z.string(),
  isEnabled: z.boolean(),
  role: userRole,
});

export type User = z.infer<typeof user>;
export type UserRole = z.infer<typeof userRole>;

const users = z
  .array(user)
  .transform((users) => users.filter((user) => user.role !== 0));

export type Users = z.infer<typeof users>;

const colleaguesResult = z.object({
  users: users,
});

export const useGetColleagues = () => {
  return useQuery<Users>({
    queryKey: ["get-colleagues"],
    queryFn: async () => {
      const response = await gCloudClient<
        { server: number },
        ColleaguesResponse
      >({
        service: "GET_COLLEAGUES",
        data: {
          server: parseInt(SERVER_ENUMERATOR, 10),
        },
      });

      if ("error" in response) throw new Error(response.error);

      return colleaguesResult.parse(response).users;
    },
  });
};
