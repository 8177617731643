import { useDisclosure } from "@/hooks/useDisclosure";
import { Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { TargetHoursModalContent } from "./SetTimeModal";
import { useSetTargetBatch } from "../api/useSetTargetBatch";
import { useSetBatchStop } from "../api/useSetBatchStop";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { z } from "zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useTranslate } from "@/i18n/config";
import { useIsAllowed } from "@/hooks/useIsAllowed";

const FormValuesSchema = z.object({
  targetProducts: z.number().min(0).nullish(),
  targetProductsPerMinute: z.number().min(0).nullish(),
  targetHours: z.string(),
  batchName: z.string(),
  targetHoursInMinutes: z.number(),
});
type FormValuesType = z.infer<typeof FormValuesSchema>;

export const ProductsTargetForm = ({
  showNotification,
  stopForced,
}: {
  showNotification: () => void;
  stopForced: boolean;
}) => {
  const translate = useTranslate();
  const [isStop, setIsStop] = useState<boolean>(false);
  const { mutate: setTarget } = useSetTargetBatch();
  const { mutate: setStopTarget } = useSetBatchStop();

  const isRoleAllow = useIsAllowed([0, 2, 3]);
  const { open, isOpen, close } = useDisclosure();

  //CONTROLLO LA CHIAVE DI RESPONSE FORCESTOPBATCH -- PARLARE CON MANU
  isStop !== stopForced ? setIsStop(stopForced) : null;

  const {
    handleSubmit: handleSubmitTarget,
    register,
    reset,
    formState: { errors },
    getValues,
    setValue,
    control,
  } = useForm<FormValuesType>({
    defaultValues: {
      targetProducts: null,
      targetProductsPerMinute: null,
      targetHours: "",
      batchName: "",
      targetHoursInMinutes: 0,
    },
  });

  const onSubmitTarget: SubmitHandler<FormValuesType> = (values) => {
    if (!values) {
      return;
    }
    const {
      targetProducts,
      batchName,
      targetProductsPerMinute,
      targetHoursInMinutes,
    } = values;
    //*mutate SEND UPDATE
    if (
      typeof targetProducts === "number" &&
      typeof targetProductsPerMinute === "number"
    ) {
      setTarget(
        {
          targetBatch: targetProducts.toString(),
          nameBatch: batchName,
          hourTargetBatch: targetHoursInMinutes * 60,
          targetBatchProductHour: targetProductsPerMinute.toString(),
          startChangedFormat: true,
        },
        {
          onSuccess() {
            setIsStop(false);
            reset();
            showNotification();
          },
        },
      );
      return;
    }
  };

  const stopBatchHandler = () => {
    setStopTarget({
      stopBatchForced: true,
    });
    setIsStop(true);
  };

  const handleTargetTimeSubmitted = ({
    timeInMillis,
  }: {
    timeInMillis: number;
  }) => {
    const targetHours = formatDuration(timeInMillis);
    const targetHoursInMinutes = timeInMillis / 1000 / 60;
    fromTargetHours(targetHoursInMinutes);
    setValue("targetHours", targetHours);
    setValue("targetHoursInMinutes", targetHoursInMinutes);
  };

  // calcolo del terzo input dato i primi due
  const fromTargetProducts = (value: number) => {
    const { targetHoursInMinutes, targetProductsPerMinute } = getValues();
    if (
      typeof targetProductsPerMinute === "number" &&
      targetProductsPerMinute >= 0 &&
      value >= 0
    ) {
      const timeFormatted =
        value / targetProductsPerMinute > 0
          ? formatDuration((value / targetProductsPerMinute) * 60 * 1000)
          : "";
      setValue("targetHours", timeFormatted),
        setValue("targetHoursInMinutes", +(value / targetProductsPerMinute));
      return;
    }
    if (targetHoursInMinutes > 0 && value > 0) {
      return setValue(
        "targetHoursInMinutes",
        +(value / targetHoursInMinutes).toFixed(),
      );
    }
  };

  const fromProductsPerMinutes = (value: number) => {
    const { targetProducts, targetHoursInMinutes } = getValues();
    if (
      typeof targetProducts === "number" &&
      targetProducts >= 0 &&
      value >= 0
    ) {
      const timeFormatted =
        targetProducts / value === Infinity
          ? ""
          : formatDuration((targetProducts / value) * 60 * 1000);

      setValue("targetHours", timeFormatted),
        setValue("targetHoursInMinutes", +(targetProducts / value).toFixed());
      return;
    }
    if (
      typeof targetHoursInMinutes === "number" &&
      targetHoursInMinutes > 0 &&
      value > 0
    ) {
      setValue("targetProducts", +(value * targetHoursInMinutes).toFixed());
      return;
    }
  };

  const fromTargetHours = (value: number) => {
    const { targetProductsPerMinute, targetProducts } = getValues();
    if (
      typeof targetProductsPerMinute === "number" &&
      targetProductsPerMinute > 0 &&
      value > 0
    ) {
      return setValue(
        "targetProducts",
        +(value * targetProductsPerMinute).toFixed(),
      );
    }
    if (typeof targetProducts === "number" && targetProducts > 0 && value > 0) {
      return setValue(
        "targetProductsPerMinute",
        +(targetProducts / value).toFixed(),
      );
    }
  };

  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { width: "25rem" },
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
      }}
      gap={2}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmitTarget(onSubmitTarget)}
    >
      <Controller
        name="targetProducts"
        control={control}
        rules={{
          required: {
            value: true,

            message: translate("batch_navigator.target_products_is_required"),
          },
          min: {
            value: 1,
            message: translate("batch_navigator.value_must_be_greater_than_0"),
          },
        }}
        render={({ field: { onChange, value } }) => {
          return (
            <NumericFormat
              customInput={TextField}
              value={value || ""}
              label={translate("batch_navigator.target_products")}
              InputProps={{ inputProps: { inputMode: "numeric", min: 0 } }}
              error={!!errors.targetProducts}
              allowNegative={false}
              helperText={
                errors.targetProducts && errors.targetProducts?.message
              }
              variant="filled"
              size="small"
              disabled={isRoleAllow ? false : true}
              onValueChange={(e) => {
                onChange(Number(e.value)), fromTargetProducts(Number(e.value));
              }}
            />
          );
        }}
      />
      <Controller
        name="targetProductsPerMinute"
        control={control}
        rules={{
          required: {
            value: true,
            message: translate(
              "batch_navigator.products_per_minute_is_required",
            ),
          },
          min: {
            value: 1,
            message: translate("batch_navigator.value_must_be_greater_than_0"),
          },
        }}
        render={({ field: { onChange, value } }) => {
          return (
            <NumericFormat
              customInput={TextField}
              value={value || ""}
              label={translate("batch_navigator.products_per_minute")}
              InputProps={{ inputProps: { inputMode: "numeric", min: 0 } }}
              error={!!errors.targetProductsPerMinute}
              allowNegative={false}
              helperText={errors.targetProductsPerMinute?.message}
              variant="filled"
              size="small"
              disabled={isRoleAllow ? false : true}
              onValueChange={(e) => {
                onChange(Number(e.value)),
                  fromProductsPerMinutes(Number(e.value));
              }}
            />
          );
        }}
      />
      <Controller
        name="targetHours"
        control={control}
        rules={{
          required: {
            value: true,
            message: translate("batch_navigator.target_hours_is_required"),
          },
          min: {
            value: 1,
            message: translate("batch_navigator.invalid_time"),
          },
        }}
        render={({ field: { value } }) => {
          return (
            <TextField
              label={translate("batch_navigator.target_hours")}
              type="string"
              value={value}
              error={!!errors.targetHours}
              helperText={errors.targetHours?.message}
              onClick={() => {
                if (!isRoleAllow) {
                  return;
                }
                open();
              }}
              variant="filled"
              size="small"
              disabled={isRoleAllow ? false : true}
            />
          );
        }}
      />
      <TextField
        {...register("batchName", {
          required: {
            value: true,
            message: translate("batch_navigator.batch_name_is_required"),
          },
        })}
        type="string"
        label={translate("batch_navigator.batch_name")}
        error={!!errors.batchName}
        helperText={errors.batchName?.message}
        variant="filled"
        size="small"
        disabled={isRoleAllow ? false : true}
      />
      {isStop ? (
        <Button
          type="submit"
          variant="contained"
          color={"success"}
          sx={{ padding: ".69rem", color: "white" }}
          size="large"
          disabled={isRoleAllow ? false : true}
        >
          {translate("actions.start")}
        </Button>
      ) : null}
      {!isStop ? (
        <Button
          type="button"
          onClick={stopBatchHandler}
          variant="contained"
          color={"error"}
          sx={{ padding: ".69rem", color: "white" }}
          size="large"
          disabled={isRoleAllow ? false : true}
        >
          {translate("actions.stop")}
        </Button>
      ) : null}
      <TargetHoursModalContent
        closeModal={() => close()}
        openModal={isOpen}
        setTargetTimeInput={handleTargetTimeSubmitted}
      />
    </Box>
  );
};

export const formatDuration = (durationInMillis: number): string => {
  if (durationInMillis === 0) {
    return "0";
  }
  const durationString = TimeHelpers.parseDurationToString({
    duration: durationInMillis,
    fmt: "d'd' h'h' m'm'",
  });
  const durationStringSplit = durationString
    .split(" ")
    .filter((part) => !part.startsWith("0"))
    .join(" ");
  return durationStringSplit;
};
