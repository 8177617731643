import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { Line } from "../../api/useGetUserProductionLines";
import styles from "./EditLineConfigurationButton.module.css";
import { useOeeLineContextDispatch } from "../../Pages/LineOverview/context/useOeeLineContextDispatch";

export const AutomaticConfigureLineButton = ({ line }: { line: Line }) => {
  const dispatch = useOeeLineContextDispatch();
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch({ type: "select line", line });
  };

  return (
    <IconButton
      edge="start"
      color="inherit"
      aria-label="close"
      className={styles.pulse}
      onClick={handleClick}
    >
      <Edit />
    </IconButton>
  );
};
