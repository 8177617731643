import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { SummaryHeatmap } from "./SummaryHeatmap";
import { SummarySpecificBarChart } from "./SummarySpecificBarChart";
import { SeriesColumnOptions } from "highcharts";
import {
  SummarySpecificAnalyticData,
  useGetSummarySpecificAnalytics,
} from "../../api/useGetSummarySpecificAnalytics";
import { HeatmapSeriesData } from "../../types";
import { humanizeString } from "../../utils/humanizeString";
import { useTranslate } from "@/i18n/config";

const processData = (
  data: SummarySpecificAnalyticData,
): {
  heatmapSeriesData: HeatmapSeriesData[];
  barSeriesData: SeriesColumnOptions[];
  xCategories: string[];
  yCategories: string[];
} => {
  // Funzione per ottenere l'ordinamento delle chiavi primarie
  const getSortedKeys = (obj: { [key: string]: number }) => {
    return Object.keys(obj).sort((a, b) => {
      // Definizione dei pattern desiderati
      const patterns = [
        "max critical",
        "critical_max_upper",
        "max warning",
        "warning_max_upper",
        "warning_max_lower",
        "critical_max_lower",
        "critical_mean_upper",
        "warning_mean_upper",
        "warning_mean_lower",
        "critical_mean_lower",
        "critical_min_upper",
        "warning_min_upper",
        "warning_min_lower",
        "min warning",
        "critical_min_lower",
        "min critical",
      ];

      // Estrazione degli indici dei pattern nelle chiavi primarie
      const indexA = patterns.findIndex((pattern) => a.includes(pattern));
      const indexB = patterns.findIndex((pattern) => b.includes(pattern));

      // Confronto degli indici e restituzione del risultato
      return indexA - indexB;
    });
  };

  // Ricreazione dell'oggetto "data" con le chiavi primarie ordinate
  const sortedData: SummarySpecificAnalyticData = {};

  for (const frame in data) {
    const sortedKeys = getSortedKeys(data[frame]);
    sortedData[frame] = {};

    sortedKeys.forEach((key) => {
      sortedData[frame][key] = data[frame][key];
    });
  }

  const xCategories = Object.keys(sortedData).sort();
  const heatmapSeriesData: HeatmapSeriesData[] = [];
  const barSeriesData: SeriesColumnOptions[] = [];
  const categoryKeys = Object.keys(sortedData[xCategories[0]]);

  for (let y = 0; y < categoryKeys.length; y++) {
    const name = categoryKeys[y];
    const dataArray = [];

    for (let x = 0; x < xCategories.length; x++) {
      const value = sortedData[xCategories[x]][name];

      dataArray.push({
        x,
        y,
        value,
        borderColor: "transparent",
      });
    }

    let colorAxis = 0;
    if (name.toLowerCase().includes("warning")) {
      colorAxis = 1;
    } else if (name.toLowerCase().includes("critical")) {
      colorAxis = 2;
    }

    const allValuesAreZero = dataArray.every((item) => item.value === 0);
    if (allValuesAreZero) {
      colorAxis = 3;
    }

    heatmapSeriesData.push({
      name: humanizeString(name),
      borderWidth: 1,
      data: dataArray,
      dataLabels: {
        enabled: true,
        color: "#FFF",
        borderColor: "#000",
      },
      colorAxis,
    });
  }

  heatmapSeriesData.forEach((dataset) => {
    let color = "#2294d6";
    if (dataset.name.toLowerCase().includes("warning")) {
      color = "#e8c52a";
    } else if (dataset.name.toLowerCase().includes("critical")) {
      color = "#D62422";
    }

    barSeriesData.push({
      type: "column",
      name: dataset.name,
      data: dataset.data.map((obj) => obj.value),
      color,
      borderRadius: 4,
    });
  });

  return {
    heatmapSeriesData,
    barSeriesData,
    xCategories: xCategories,
    yCategories: categoryKeys.map((str) => humanizeString(str)),
  };
};

export const SummarySpecificAnalytics = ({
  motorId,
  motorName,
  analytic,
}: {
  motorId: number;
  motorName: string;
  analytic: string;
}) => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetSummarySpecificAnalytics({
    motorId,
    analytic,
  });

  if (isLoading) {
    return (
      <>
        <AnalyticContainerSkeleton />
        <AnalyticContainerSkeleton />
      </>
    );
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data || Object.keys(data).length === 0) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  const { heatmapSeriesData, barSeriesData, xCategories, yCategories } =
    processData(data);

  return (
    <>
      <SummaryHeatmap
        title={`${motorName} - ${analytic}`}
        seriesData={heatmapSeriesData}
        xCategories={xCategories}
        yCategories={yCategories}
      />
      <SummarySpecificBarChart
        title={`${motorName} - ${analytic}`}
        seriesData={barSeriesData}
        categories={xCategories}
      />
    </>
  );
};
