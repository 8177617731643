import { z } from "zod";
import { Box } from "@mui/material";
import { toast } from "react-hot-toast";
import { XrangePointOptionsObject } from "highcharts";
import { useTranslate } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";
import { XRangeChart } from "@/components/highcharts/xrange/XRange";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { TimeHelpers, useFormatTimestamp } from "@/utils/TimeHelpers";
import { useGetRuns } from "../../api/useGetRuns";
import { Evaluation, Run } from "../../types";
import { useMotorsContext } from "../../context/useMotorsContext";
import { useMotorsDispatchContext } from "../../context/useMotorsDispatchContext";
import { MAX_RUNS_NUMBER } from "../../context/motors-context";
import { FramesSelection } from "./FramesSelection";
import { EVALUATION_COLORS } from "../../utils/evaluationColors";
import { humanizeString } from "../../utils/humanizeString";

const parseData = ({
  data,
  selectedRuns,
}: {
  data: Run[];
  selectedRuns: Run[];
}): XrangePointOptionsObject[] => {
  return data.map((occurrence) => {
    const { name, start, end, id, evaluation } = occurrence;
    const isSelected = selectedRuns.find((run) => run.id === id);
    const color =
      EVALUATION_COLORS[evaluation][isSelected ? "selected" : "notSelected"];

    return {
      x: start,
      x2: end,
      y: 0,
      color,
      name,
      custom: {
        evaluation,
      },
    };
  });
};

export const RunsSelection = () => {
  const translate = useTranslate();
  const { formatTimestamp } = useFormatTimestamp();
  const { data: apiData, error, isLoading } = useGetRuns();
  const { runs } = useMotorsContext();
  const dispatch = useMotorsDispatchContext();

  if (isLoading) return <SkeletonCard height={180} />;
  if (error) return <Card>{translate("user_feedback.an_error_occurred")}</Card>;
  if (!apiData) return <Card>{translate("user_feedback.no_data")}</Card>;

  const data = parseData({ data: apiData.runs, selectedRuns: runs });

  const onClickRun = ({ start, end }: { start: number; end: number }) => {
    const clonedApiData = [...apiData.runs];
    const selectedRun = clonedApiData.find(
      (item) => item.start === start && item.end === end,
    );
    if (selectedRun) {
      const isAlreadySelected =
        runs.findIndex((item) => item.id === selectedRun.id) > -1;
      if (isAlreadySelected) {
        dispatch({ type: "REMOVE_RUN", run: selectedRun });
      } else {
        if (runs.length >= MAX_RUNS_NUMBER) {
          toast.error(
            translate("user_feedback.max_n_selected", {
              max: MAX_RUNS_NUMBER,
            }),
          );
        } else {
          dispatch({ type: "ADD_RUN", run: selectedRun });
        }
      }
    }
  };

  return (
    <>
      <Box height={100}>
        <XRangeChart
          customHeight={100}
          allowExporting={false}
          categories={[translate("acopos.run_other")]}
          title=""
          data={data}
          tooltipFormatter={function () {
            const parseResult = z
              .object({
                x: z.number(),
                x2: z.number(),
                color: z.string(),
                key: z.string(),
                point: z.object({
                  custom: z.object({
                    evaluation: Evaluation,
                  }),
                }),
              })
              .safeParse(this);
            if (!parseResult.success) return;

            const {
              color,
              key,
              x,
              x2,
              point: {
                custom: { evaluation },
              },
            } = parseResult.data;

            const dateStart = formatTimestamp({
              timestamp: x,
            });
            const dateEnd = formatTimestamp({
              timestamp: x2,
            });
            const durationString = TimeHelpers.parseDurationToString({
              duration: x2 - x,
            });

            const category = key;
            return `<span style="color:${color}">●</span> ${category}<br>
                    ${translate("start")}: <b>${dateStart}</b><br>
                    ${translate("end")}: <b>${dateEnd}</b><br>
                    ${translate("duration")}: <b>${durationString}</b><br>
                    Evaluation: <b>${humanizeString(evaluation)}</b>
                  `;
          }}
          seriesOptions={{
            cursor: "pointer",
            events: {
              click: function (event) {
                const point: XrangePointOptionsObject = event.point;
                onClickRun({ start: point.x!, end: point.x2! });
              },
            },
          }}
        />
      </Box>
      {runs.length > 0 && <FramesSelection />}
    </>
  );
};
