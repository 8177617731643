import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { contextInfo } from "../../types";

export type GetSingleDescriptiveDataResponse =
  | ThingworxError
  | GetSingleDescriptiveDataResult;

const descriptiveData = z.object({
  analyticName: z.string(),
  data: z.array(
    z.object({
      name: z.string(),
      data: z.array(z.array(z.number())),
    }),
  ),
  contextInfo: contextInfo,
});

export type DescriptiveData = z.infer<typeof descriptiveData>;

type Payload = {
  machineName: string;
  runId: number;
  lapId: number;
  segmentId: number;
  shuttleId: number;
  analyticId: number;
};

const getSingleDescriptiveDataResult = z.object({
  response: z.literal(true),
  data: descriptiveData,
});

export type GetSingleDescriptiveDataResult = z.infer<
  typeof getSingleDescriptiveDataResult
>;

export const useGetSingleDescriptiveData = ({
  runId,
  lapId,
  segmentId,
  shuttleId,
  analyticId,
}: {
  runId: number;
  lapId: number;
  segmentId: number;
  shuttleId: number;
  analyticId: number;
}) => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();

  return useQuery<GetSingleDescriptiveDataResult>({
    queryKey: [
      "single-descriptive-data",
      machine!.machine,
      runId,
      lapId,
      segmentId,
      shuttleId,
      analyticId,
    ],
    queryFn: async () => {
      const response = await FetchClient<
        Payload,
        GetSingleDescriptiveDataResponse
      >({
        appKey,
        payload: {
          machineName: machine!.machine,
          runId,
          lapId,
          segmentId,
          shuttleId,
          analyticId,
        },
        // url: "dig.c.acopostrak_thing/Services/getSingleDescriptiveData",
        url: "dig.c.acoposTrack_thing/Services/getSingleDescriptiveData",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = getSingleDescriptiveDataResult.parse(response);

      return validatedResponse;
    },
  });
};
