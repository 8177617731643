import { ProfilingStatus } from "../api/useGetConditionMonitoringData";

export const mapProfilingStatus = (status: ProfilingStatus): string => {
  const colors = {
    good: "#76A12B",
    "minimum warning": "#e8c52a",
    "minimum critical": "#D62422",
    "maximum warning": "#e8c52a",
    "maximum critical": "#D62422",
  };
  return colors[status];
};
