import { useTranslate } from "@/i18n/config";
import { CompareLapsItem } from "@/features/Acopostrak/types";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { Card } from "@/components/Layout/Card";
import { useGetLapsComparison } from "@/features/Acopostrak/api/Diagnostic/useGetLapsComparison";
import { ContextInformation } from "@/features/Motors/components/Layouts/ContextInformation";
import { ScatterAndLine } from "@/components/highcharts/scatter/ScatterAndLine";
import { generateColorScale } from "@/features/Acopostrak/utils/dynamic-colors";

export const LapsComparisonChart = ({
  analyticId,
  firstLapToCompare,
  secondLapToCompare,
}: {
  analyticId: number;
  firstLapToCompare: CompareLapsItem;
  secondLapToCompare: CompareLapsItem;
}) => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetLapsComparison({
    analyticId,
    firstLapToCompare,
    secondLapToCompare,
  });

  if (isLoading) return <SkeletonCard height={400}></SkeletonCard>;

  if (error)
    return (
      <Card sx={{ height: 400 }}>
        {translate("user_feedback.an_error_occurred")}
      </Card>
    );

  if (!data)
    return (
      <Card sx={{ height: 400 }}>{translate("user_feedback.no_data")}</Card>
    );

  const { analyticName, contextInfo, data: chartData } = data.data;
  const lineSeries = chartData.map((entry) => entry.lineData);
  const scatterSeries = chartData.map((entry) => entry.scatterData);
  const seriesNames = chartData.map((entry) => entry.name);
  const scatterSeriesName = analyticName.toLowerCase().includes("anomal")
    ? "Anomalies"
    : analyticName.toLowerCase().includes("novelt")
    ? "Novelties"
    : "Novelties / Anomalies";
  const scatterSeriesColor = analyticName.toLowerCase().includes("anomal")
    ? "red"
    : "orange";
  const colors = generateColorScale(
    {
      r: 3,
      g: 244,
      b: 252,
    },
    {
      r: 234,
      g: 12,
      b: 250,
    },
    chartData.length,
  );

  return (
    <Card>
      <ContextInformation props={contextInfo} />
      <ScatterAndLine
        title={analyticName}
        scrollbarEnabled={false}
        legend={{ enabled: true }}
        yAxis={[
          {
            uom: "",
            series: scatterSeries.map((data, i) => {
              return {
                type: "scatter",
                data,
                name: `${scatterSeriesName} - ${seriesNames[i]}`,
                color: scatterSeriesColor,
                xAxis: i,
              };
            }),
          },
        ]}
        xAxisType="linear"
        xAxes={Array(lineSeries.length + 1)
          .fill("")
          .map((_, i) => ({
            id: "x-axis-" + i,
            type: "datetime",
            visible: false,
          }))}
        lineSeries={lineSeries.map((data, i) => {
          return {
            type: "line",
            data,
            name: seriesNames[i],
            color: colors[i],
            xAxis: i,
          };
        })}
      />
    </Card>
  );
};
