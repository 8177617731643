import { useTranslate } from "@/i18n/config";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { useState } from "react";
import { z } from "zod";

export const EmailInput = ({
  emailList,
  onChangeEmailList,
  onAddEmail,
  hasError,
  defaultValue,
}: {
  emailList: string[];
  onChangeEmailList: (emailList: string[]) => void;
  onAddEmail: (newMail: string) => void;
  hasError: (error: boolean) => void;
  defaultValue?: string[];
}) => {
  const [error, setError] = useState(false);
  const translate = useTranslate();

  return (
    <Autocomplete
      multiple
      id="multiple-email-input"
      options={[] as string[]}
      onChange={(_, newValue) => {
        const isError = !z.array(z.string().email()).safeParse(newValue)
          .success;
        setError(isError);
        hasError(isError);
        onChangeEmailList(newValue);
      }}
      onClose={(event, reason) => {
        if (reason !== "createOption") {
          const target = event.target as HTMLButtonElement;
          const newValue = target.value;
          const isError = !z.string().email().safeParse(newValue).success;
          setError(isError);
          hasError(isError);
          onAddEmail(newValue);
        }
      }}
      renderTags={() =>
        emailList.map((email) => <Chip key={email} label={email} />)
      }
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
      defaultValue={defaultValue}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={translate("email")}
          placeholder="mail@example.com"
          error={error}
          helperText={
            error ? translate("error.not_valid_email_address") : undefined
          }
        />
      )}
    />
  );
};
