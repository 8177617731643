import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCXPF1xrp6ARXt9QKMyREGNqs-D7huNRRs",
  authDomain: "dnamap-91d2a.firebaseapp.com",
  databaseURL: "https://dnamap-91d2a.firebaseio.com",
  projectId: "dnamap-91d2a",
  storageBucket: "dnamap-91d2a.appspot.com",
  messagingSenderId: "339474690918",
  appId: "1:339474690918:web:47b1f578c555d79991cea7",
  measurementId: "G-KCHW1XSYRE",
};

const sentinelFirebaseApp = initializeApp(firebaseConfig);
const storage = getStorage(sentinelFirebaseApp);
const analytics = getAnalytics(sentinelFirebaseApp);

const auth = getAuth(sentinelFirebaseApp);

const db = getDatabase(sentinelFirebaseApp);

const log = (event: string) => logEvent(analytics, event);

export { sentinelFirebaseApp, storage, analytics, log, auth, db };
