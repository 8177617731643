import { Tabs, Tab, Stack } from "@mui/material";
import { useState } from "react";
import { useTranslate } from "@/i18n/config";
import { Descriptive } from "./Descriptive";
import { ConditionMonitoring } from "./ConditionMonitoring";
import { DeviancesMonitoring } from "./DeviancesMonitoring";

const SECTIONS = {
  DESCRIPTIVE: 0,
  CONDITION_MONITORING: 1,
  DEVIANCES_MONITORING: 2,
};

export const Monitoring = () => {
  const [section, setSection] = useState(SECTIONS.DESCRIPTIVE);
  const translate = useTranslate();

  return (
    <>
      <Tabs
        value={section}
        aria-label="acopostrak monitoring tabs"
        onChange={(_: React.SyntheticEvent, newValue: number) => {
          setSection(newValue);
        }}
      >
        <Tab
          value={SECTIONS.DESCRIPTIVE}
          label={translate("monitoring.descriptive")}
        />
        <Tab
          value={SECTIONS.CONDITION_MONITORING}
          label={translate("monitoring.condition_monitoring")}
        />
        <Tab
          value={SECTIONS.DEVIANCES_MONITORING}
          label={translate("monitoring.deviances_monitoring")}
        />
      </Tabs>
      <Stack gap={1}>
        {section === SECTIONS.DESCRIPTIVE && <Descriptive />}
        {section === SECTIONS.CONDITION_MONITORING && <ConditionMonitoring />}
        {section === SECTIONS.DEVIANCES_MONITORING && <DeviancesMonitoring />}
      </Stack>
    </>
  );
};
