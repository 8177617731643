import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { NavButton } from "@/components/NavButton";
import { SelectTimeSelection } from "@/components/SelectTimeSelection";
import {
  TestCircleLayoutLoader,
  SmallCircleLayoutLoader,
  GaugeSkeleton,
} from "@/features/MachineDetail/components/CircleLayout";
import { SpeedInfo } from "@/features/MachineDetail/components/SpeedInfo";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { Box, Stack, Grid } from "@mui/material";
import { useCheckMachinePage } from "@/hooks/check-machine-page";
import { useTranslate } from "@/i18n/config";

export const LiveLoadingView = () => {
  const { hasAccess } = useCheckMachinePage("historicMachineDetail");
  const translate = useTranslate();

  return (
    <>
      <MachineViewNavBar>
        <Stack
          direction={{ sm: "row", xs: "column" }}
          gap="1rem"
          alignItems="center"
        >
          <SelectTimeSelection exclude={["Custom"]} />
          {hasAccess ? (
            <NavButton
              to="/machine-detail/sentinel/historic"
              text={translate("view_historic")}
            />
          ) : null}
        </Stack>
      </MachineViewNavBar>
      <Stack gap={2}>
        <Grid container columns={12} alignItems="center" spacing={2}>
          <Grid item xs={6} md={2}>
            <TestCircleLayoutLoader height={"35%"} />
          </Grid>
          <Grid justifyContent="center" item xs={6} md={2}>
            <SmallCircleLayoutLoader />
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack gap={2}>
              <SkeletonCard height={100} />
              <SkeletonCard height={100} />
            </Stack>
          </Grid>
          <Grid alignItems="center" justifyContent="center" item xs={12} md={4}>
            <Box
              sx={{
                paddingX: "15%",
                height: {
                  sx: "200px",
                  md: "auto",
                },
              }}
            >
              <SmallCircleLayoutLoader />
            </Box>
          </Grid>
        </Grid>
        <Grid
          id="machine-detail-gauge"
          container
          columns={8}
          alignItems="center"
        >
          <Grid
            item
            sx={{ margin: "auto" }}
            md={1}
            alignItems="center"
            flex={1}
            justifyContent="center"
          >
            <GaugeSkeleton
              sx={{
                padding: 1,
                margin: "auto",
                width: "9vmax",
                height: "9vmax",
              }}
            />
          </Grid>

          <Grid
            item
            sx={{ margin: "auto" }}
            md={1}
            alignItems="center"
            flex={1}
            justifyContent="center"
          >
            <GaugeSkeleton
              sx={{
                padding: 1,
                margin: "auto",
                width: "9vmax",
                height: "9vmax",
              }}
            />
          </Grid>
          <Grid
            item
            sx={{ margin: "auto" }}
            md={1}
            alignItems="center"
            flex={1}
            justifyContent="center"
          >
            <GaugeSkeleton
              sx={{
                padding: 1,
                margin: "auto",
                width: "9vmax",
                height: "9vmax",
              }}
            />
          </Grid>
          <Grid
            item
            sx={{ margin: "auto" }}
            md={2}
            alignItems="center"
            flex={1}
            justifyContent="center"
          >
            <GaugeSkeleton
              sx={{
                padding: 1,
                margin: "auto",
                width: "12vmax",
                height: "12vmax",
              }}
            />
          </Grid>
          <Grid
            item
            sx={{ margin: "auto" }}
            md={1}
            alignItems="center"
            flex={1}
            justifyContent="center"
          >
            <GaugeSkeleton
              sx={{
                padding: 1,
                margin: "auto",
                width: "9vmax",
                height: "9vmax",
              }}
            />
          </Grid>
          <Grid
            item
            sx={{ margin: "auto" }}
            md={1}
            alignItems="center"
            flex={1}
            justifyContent="center"
          >
            <GaugeSkeleton
              sx={{
                padding: 1,
                margin: "auto",
                width: "9vmax",
                height: "9vmax",
              }}
            />
          </Grid>
          <Grid
            item
            sx={{ margin: "auto" }}
            md={1}
            alignItems="center"
            flex={1}
            justifyContent="center"
          >
            <GaugeSkeleton
              sx={{
                padding: 1,
                margin: "auto",
                width: "9vmax",
                height: "9vmax",
              }}
            />
          </Grid>
        </Grid>
        <SpeedInfo />
      </Stack>
    </>
  );
};
