import { CreateOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { sentryModeStore } from "../../store/sentry-mode-store";
import { chatStore } from "../../store/chat-store";
import { useQueryClient } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";
import styles from "./NewChatButton.module.css";

export const NewChatButton = () => {
  const translate = useTranslate();
  const { user } = useFirebaseContext();
  const email = user?.email || "";
  const queryClient = useQueryClient();
  const { resetTimer } = sentryModeStore();
  const { clearChat } = chatStore();

  const handleClick = () => {
    clearChat();
    resetTimer();
    queryClient.invalidateQueries({
      queryKey: ["intellecta-historic", email],
    });
  };

  return (
    <Tooltip
      title={translate("intellecta.new_chat")}
      enterDelay={2000}
      leaveDelay={200}
    >
      <IconButton
        color="inherit"
        aria-label="new chat"
        onClick={handleClick}
        className={styles.pulse}
      >
        <CreateOutlined />
      </IconButton>
    </Tooltip>
  );
};
