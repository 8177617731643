import { useFirebaseContext } from "@/context/firebase-context";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { ThingworxError } from "src/types/error";
import { FetchClient } from "@/services/ApiClient";
import { useMachineContext } from "@/context/machine-context";
import { useTimeSelection } from "@/store/useTimeSelection";

type Response = ThingworxError | { response: true };

type Payload = {
  machineName: string;
  subCategoryId: number;
  subCategoryValue: string;
};

export const useUpdateSubCategory = () => {
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();
  const { timeSelection } = useTimeSelection();
  const client = useQueryClient();

  return useMutation({
    mutationFn: async ({
      subCategoryId,
      subCategoryValue,
    }: {
      subCategoryId: number;
      subCategoryValue: string;
    }) => {
      if (!machine) {
        throw new Error("Machine is not selected");
      }
      const payload: Payload = {
        machineName: machine.machine,
        subCategoryId,
        subCategoryValue,
      };
      return await FetchClient<Payload, Response>({
        appKey,
        payload,
        url: "dig.c.stopImputation_thing/Services/updateSubCategory",
      });
    },
    onSuccess(data) {
      if (data.response) {
        toast.success("Sub-Category updated successfully");
        client.invalidateQueries({
          queryKey: ["imputation-categories-data", machine!.machine],
        });
        client.invalidateQueries({
          queryKey: ["imputation-log-data", machine!.machine, timeSelection],
        });
        client.invalidateQueries({
          queryKey: ["imputation-stats-data", machine!.machine, timeSelection],
        });
        client.invalidateQueries({
          queryKey: ["states-log-data", machine!.machine],
        });
      } else {
        toast.error("Failed to update Sub-Category");
      }
    },
  });
};
