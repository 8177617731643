import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { useQuery } from "@tanstack/react-query";
import { FetchClient } from "@/services/ApiClient";
import { ThingworxError } from "src/types/error";
import { DateTime } from "luxon";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { z } from "zod";

import { Shift } from "../../types/index";
import { QueryBuilder } from "@/utils/query-builder";
export type EfficencyResponse = ThingworxError | ThingworxResultEfficiency;
/**
 * type definitions used as the shape in which
 * the data that comes from the API needs to be transformed.
 * This problems arises because the API returns the data in a different shape, with keys that have blank spaces.
 *
 * @todo refactor thingworx API to return data in a more usable shape so that we can remove this transformation step
 */

const EfficencyLossesData = z.object({
  machineLossesPercentage: z.array(z.array(z.number())),
  processLossesPercentage: z.array(z.array(z.number())),
  extraCapacityPercentage: z.array(z.array(z.number())),
  lineLossesPercentage: z.array(z.array(z.number())),
});
type EfficencyLossesData = z.infer<typeof EfficencyLossesData>;

const ResultEfficiency = z.object({
  data: z.array(EfficencyLossesData),
  chartName: z.string(),
  configuration: z.string(),
  response: z.literal(true),
  shiftList: z.array(Shift),
});

type ResultEfficiency = z.infer<typeof ResultEfficiency>;

/**
 * Type definitions for data that comes from the API.
 */

const ThingworxEfficencyLossesData = z.object({
  "Machine Losses Percentage": z.array(z.array(z.number())),
  "Process Losses Percentage": z.array(z.array(z.number())),
  "Extra Capacity Percentage": z.array(z.array(z.number())),
  "Line Losses Percentage": z.array(z.array(z.number())),
});

type ThingworxEfficencyLossesData = z.infer<
  typeof ThingworxEfficencyLossesData
>;

const ThingworxResultEfficiency = z.object({
  data: z.array(ThingworxEfficencyLossesData),
  chartName: z.string(),
  configuration: z.string(),
  response: z.literal(true),
  shiftList: z.array(Shift),
});

export type ThingworxResultEfficiency = z.infer<
  typeof ThingworxResultEfficiency
>;

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
  dateStart?: DateTime;
  dateEnd?: DateTime;
};

export const useGetEfficencyLosses = () => {
  const { timeSelection, dates } = useTimeSelection();
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();

  const queryKey = QueryBuilder.buildWithCondition({
    baseQuery: [
      "machinePerformance-sentinel-EfficiencyLossesSelector",
      timeSelection,
      machine?.machine,
    ],
    condition: timeSelection === "Custom",
    query: [dates.dateStart, dates.dateEnd],
  });

  const payload =
    timeSelection === "Custom"
      ? {
          machineName: machine?.machine || "",
          dateStart: dates.dateStart,
          dateEnd: dates.dateEnd,
          timeSelection: timeSelection,
        }
      : {
          machineName: machine?.machine || "",
          timeSelection: timeSelection,
        };

  return useQuery<ResultEfficiency>({
    queryKey,
    staleTime: 0,
    queryFn: async () => {
      const response = await FetchClient<Payload, EfficencyResponse>({
        appKey,
        payload,
        url: "dig.c.machinePerformance_thing/Services/getEfficiencyLossesSelector",
      });

      if (!response.response) throw new Error(response.errorString);
      const validatedResponse = ThingworxResultEfficiency.parse(response);

      return {
        ...validatedResponse,
        data: validatedResponse.data.map((d) => ({
          machineLossesPercentage: d["Machine Losses Percentage"],
          processLossesPercentage: d["Process Losses Percentage"],
          extraCapacityPercentage: d["Extra Capacity Percentage"],
          lineLossesPercentage: d["Line Losses Percentage"],
        })),
      };
    },
    refetchInterval: timeSelection === "Custom" ? false : 1000 * 20,
  });
};
