import { StateInfoLayout } from "./StateInfo";
import { useOeeConfigurationState } from "../context/ConfigurationContext";
import { CardsContainer, NoStates } from "./Layout/CardsContainer";
import { Views } from "../types";

export const StatesConfigurationDetail = ({ view }: { view: Views }) => {
  const { displayingConfiguration, configuredView, notConfiguredView } =
    useOeeConfigurationState();

  const states =
    view === "configured"
      ? configuredView
      : view === "notConfigured"
      ? notConfiguredView
      : displayingConfiguration;

  return (
    <CardsContainer>
      {states.length === 0 && <NoStates content={"No state configured"} />}
      {states.map((state) => (
        <StateInfoLayout
          key={state.id}
          id={state.id}
          stateName={state.stateName}
          duration={state.duration}
          oeeGain={state.oeeGain}
          performanceGain={state.performanceGain}
          availabilityGain={state.availabilityGain}
          isClientRelevance={state.isClientRelevance}
          isEfficiencyRelevance={state.isEfficiencyRelevance}
        />
      ))}
    </CardsContainer>
  );
};
