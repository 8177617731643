import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useCustomTimeRange } from "../store/useCustomTimeRange";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { useLanguage } from "@/i18n/config";

type RejectParetoResponse = ThingworxError | RejectParetoResult;

const RejectData = z.object({
  cause: z.string(),
  description: z.string(),
  occurrences: z.number(),
});

type RejectData = z.infer<typeof RejectData>;

const RejectParetoResult = z.object({
  response: z.literal(true),
  data: z.array(RejectData),
});

type RejectParetoResult = z.infer<typeof RejectParetoResult>;

type Payload = {
  machineName: string;
  dateStart: DateTime;
  dateEnd: DateTime;
  language_id: string;
};

export const useGetRejectParetoData = () => {
  const { machine } = useMachineContext();

  const { dateStart, dateEnd } = useCustomTimeRange();

  const { appKey } = useFirebaseContext();
  const language = useLanguage();

  return useQuery<RejectData[]>({
    queryKey: ["pareto-rejects", machine?.machine, dateStart, dateEnd, language],
    queryFn: async () => {
      const response = await FetchClient<Payload, RejectParetoResponse>({
        appKey,
        payload: {
          dateStart,
          dateEnd,
          machineName: machine?.machine || "",
          language_id: language,
        },
        url: "dig.c.fillerAdvancedAnalytics_thing/Services/getParetoData",
      });

      if (!response.response) throw new Error(response.errorString);

      return RejectParetoResult.parse(response).data;
    },
  });
};
