import { FetchClient } from "@/services/ApiClient";
import { z } from "zod";

interface Payload {
  account: string;
}

export const getMachineListResult = z.object({
  machineArray: z.array(z.string()),
});

export type GetMachineListResult = z.infer<typeof getMachineListResult>;

export type GetMachineListResponse = GetMachineListResult;

export const getMachines = ({
  appKey,
  account,
}: {
  appKey: string;
  account: string;
}) => {
  return FetchClient<Payload, GetMachineListResponse>({
    appKey,
    payload: {
      account,
    },
    url: "dig.c.landingPage_thing/Services/getMachineList",
  });
};
