import Stack from "@mui/material/Stack";
import { useMediaQuery } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { GaugeSkeleton } from "@/features/MachineDetail/components/CircleLayout";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";

export const SkeletonLoaderLayout = () => {
  const smallScreen = useMediaQuery("(min-width:728px)");
  return (
    <Stack gap={2}>
      <Grid2 display={"flex"} gap={2}>
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
      </Grid2>
      <Grid2 container spacing={smallScreen ? 2 : 1} textAlign="center">
        <Grid2 md={2.4} xs={6}>
          <SkeletonCard />
        </Grid2>
        <Grid2 md={2.4} xs={6}>
          <SkeletonCard />
        </Grid2>
        <Grid2 md={2.4} xs={6}>
          <SkeletonCard />
        </Grid2>
        <Grid2 md={2.4} xs={6}>
          <SkeletonCard />
        </Grid2>
        <Grid2 md={2.4} xs={6}>
          <SkeletonCard />
        </Grid2>
        <Grid2 md={2.4} xs={6}>
          <SkeletonCard />
        </Grid2>
        <Grid2 md={2.4} xs={6}>
          <SkeletonCard />
        </Grid2>
        <Grid2 md={2.4} xs={6}>
          <SkeletonCard />
        </Grid2>
        <Grid2 md={2.4} xs={6}>
          <SkeletonCard />
        </Grid2>
        <Grid2 md={2.4} xs={6}>
          <SkeletonCard />
        </Grid2>
      </Grid2>
      <Grid2 container justifyContent={"space-between"} spacing={2}>
        <Grid2 md={4} xs={12} spacing={2} direction={"column"}>
          <Stack
            gap={2}
            justifyContent="space-around"
            alignItems="center"
            height="100%"
          >
            <SkeletonCard />
            <SkeletonCard />
          </Stack>
        </Grid2>
        <Grid2
          flex={1}
          display="flex"
          alignItems="center"
          justifyContent={"space-around"}
          direction="row"
          spacing={1}
        >
          <GaugeSkeleton
            width={smallScreen ? 150 : 100}
            height={smallScreen ? 150 : 100}
          />
          <GaugeSkeleton
            width={smallScreen ? 150 : 100}
            height={smallScreen ? 150 : 100}
          />
          <GaugeSkeleton width={100} height={100} />
          <GaugeSkeleton width={100} height={100} />
        </Grid2>
      </Grid2>
      <SkeletonCard height={300} />
    </Stack>
  );
};
