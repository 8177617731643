import {
  ContentPaste,
  ThumbsUpDownOutlined,
  ShareOutlined,
} from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { useDisclosure } from "@/hooks/useDisclosure";
import { ChatMessageAction } from "./ChatMessageAction";
import { FeedbackModal } from "./FeedbackModal";
import { ShareModal } from "./ShareModal";
import { useGetUserConfiguration } from "../../api/useGetUserConfiguration";

export const ChatMessageActionListIntellecta = ({
  onCopy,
  messageId,
}: {
  onCopy: () => void;
  messageId?: number;
}) => {
  const translate = useTranslate();
  const { data } = useGetUserConfiguration();
  const {
    open: openFeedbackModal,
    isOpen: isOpenFeedbackModal,
    close: closeFeedbackModal,
  } = useDisclosure();
  const {
    open: openShareModal,
    isOpen: isOpenShareModal,
    close: closeShareModal,
  } = useDisclosure();

  return (
    <>
      <Stack direction="row" gap={1}>
        <ChatMessageAction
          onClick={onCopy}
          popoverText={translate("actions.copy")}
          icon={<ContentPaste fontSize="small" />}
        ></ChatMessageAction>
        <ChatMessageAction
          onClick={openFeedbackModal}
          popoverText={translate("intellecta.leave_feedback")}
          icon={<ThumbsUpDownOutlined fontSize="small" />}
        ></ChatMessageAction>
        {data && data.services.includes("social") && (
          <ChatMessageAction
            onClick={openShareModal}
            popoverText={translate("actions.share")}
            icon={<ShareOutlined fontSize="small" />}
          ></ChatMessageAction>
        )}
      </Stack>
      <FeedbackModal
        isOpen={isOpenFeedbackModal}
        close={closeFeedbackModal}
        messageId={messageId}
      />
      <ShareModal isOpen={isOpenShareModal} close={closeShareModal} />
    </>
  );
};
