import { styled } from "@mui/material";
import { Box } from "@mui/system";

export const ContainerLayout = styled(Box)({
  backgroundColor: "#0D1626",
  backgroundImage: "none",
  borderRadius: ".25rem",
  padding: "1rem",
  paddingTop: "0.75rem",
});
