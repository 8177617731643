import { GridColDef, GridRowsProp } from "@mui/x-data-grid-premium";

type ColumnDefinition = {
  idName: string;
  displayName: string;
};
type ObjectValue = {
  [key: string]: {
    mac: string;
    rtt: number;
  };
};
type RowValues = Array<string | ObjectValue>;

export class TableRowsGroupParser {
  static parse({
    columnsDefinitions,
    rowValues,
  }: {
    columnsDefinitions: ColumnDefinition[];
    rowValues: RowValues;
  }): {
    columns: GridColDef[];
    rows: GridRowsProp;
  } {
    const parsedColumns: GridColDef[] = columnsDefinitions.map((item) => ({
      field: item.idName,
      headerName: item.displayName,
      flex: 1,
    }));

    const parsedRows: GridRowsProp = formatRowsData(rowValues);

    return {
      columns: parsedColumns,
      rows: parsedRows,
    };
  }
}

function formatRowsData(rawData: RowValues): GridRowsProp {
  let id = 0;

  const formattedArray = rawData.flatMap((element, i) => {
    if (typeof element === "string") {
      const ip = element;
      const dataObj = rawData[i + 1];

      if (typeof dataObj === "object") {
        return Object.entries(dataObj).map(([host, data]) => ({
          id: id++,
          ip,
          host,
          ...data,
        }));
      }
    }

    return [];
  });

  return formattedArray;
}
