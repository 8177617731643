import { Card } from "@/components/Layout/Card";
import { useGetEfficencyLosses } from "../../api/efficiency/useGetEfficencyLosses";
import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { useTranslate } from "@/i18n/config";
import { fileNameFactory } from "@/utils/fileNameFactory";
import { useMachineContext } from "@/context/machine-context";

export const EfficiencyLosses = () => {
  const { data, error, isLoading } = useGetEfficencyLosses();
  const { machine } = useMachineContext();
  const machineName = machine?.machine;

  const translate = useTranslate();

  if (isLoading) {
    return <SkeletonCard height={300} />;
  }

  if (!data) {
    return null;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  return (
    <>
      <Card>
        <LineChart.Custom
          title={translate("losses.efficiency_losses")}
          filename={fileNameFactory({
            name: "efficiency_losses",
            machine: machineName,
          })}
          legend={{ enabled: true }}
          xAxisType="datetime"
          xAxisOptions={{
            scrollbar: { enabled: false },
          }}
          yAxis={data?.data.map((result, i) => ({
            min: 0,
            max: 100,
            uom: "%",
            options: {
              opposite: i % 2 === 0,
              type: "linear",
              min: 0,
              max: 100,
              scrollbar: { enabled: false },
            },
            series: [
              {
                name: translate("losses.machine_losses_percentage"),
                type: "line",
                data: result.machineLossesPercentage,
                color: "#D62422",
              },
              {
                name: translate("losses.process_losses_percentage"),
                type: "line",
                data: result.processLossesPercentage,
                color: "#707070",
              },
              {
                name: translate("losses.extra_capacity_percentage"),
                type: "line",
                data: result.extraCapacityPercentage,
                color: "#ED8526",
              },
              {
                name: translate("losses.line_losses_percentage"),
                type: "line",
                data: result.lineLossesPercentage,
                color: "#16A99C",
              },
            ],
          }))}
        />
      </Card>
    </>
  );
};
