import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { FetchClient } from "@/services/ApiClient";
import { useFirebaseContext } from "@/context/firebase-context";
import { TimeSelection, useTimeSelection } from "@/store/useTimeSelection";
import { useMachineContext } from "@/context/machine-context";
import { ThingworxError } from "src/types/error";

const hasAnomaliesResult = z.object({
  hasAnomalies: z.boolean(),
  response: z.literal(true),
});

export type HasAnomaliesResult = z.infer<typeof hasAnomaliesResult>;

export type HasAnomaliesResponse = ThingworxError | HasAnomaliesResult;

interface Payload {
  machineName: string;
  dateStart: number;
  dateEnd: number;
  timeSelection: TimeSelection;
}

export const useCheckAnomalies = () => {
  const { machine } = useMachineContext();
  const { timeSelection, dates } = useTimeSelection();
  const { appKey } = useFirebaseContext();

  return useQuery<HasAnomaliesResult>({
    queryKey: [
      "hasAnomalies",
      machine!.machine,
      timeSelection,
      JSON.stringify(dates),
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, HasAnomaliesResponse>({
        appKey,
        payload: {
          machineName: machine!.machine,
          dateStart: dates.dateStart.toMillis(),
          dateEnd: dates.dateEnd.toMillis(),
          timeSelection,
        },
        url: "dig.c.dataConsistency_thing/Services/hasAnomalies",
      });
      if (!response.response) throw new Error(response.errorString);
      return response;
    },
  });
};
