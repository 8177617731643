import { useEffect, useState } from "react";
import { ref, onValue } from "firebase/database";
import { environment } from "../config";
import { db } from "@/lib/sentinel-app";

export const useCheckIfMaintenance = (): {
  isMaintenance: boolean;
} => {
  const [isMaintenance, setIsMaintenance] = useState(false);
  const path: "maintenance-dev" | "maintenance-qa" | "maintenance-prod" =
    environment === "development"
      ? "maintenance-dev"
      : environment === "staging"
      ? "maintenance-qa"
      : "maintenance-prod";

  useEffect(() => {
    const maintenanceRef = ref(db, path);
    const unsubscribe = onValue(maintenanceRef, (snapshot) => {
      const data = snapshot.val();
      setIsMaintenance(data);
    });

    return unsubscribe;
  }, []);

  return {
    isMaintenance,
  };
};
