import { TileLayer, Marker, MapContainer } from "react-leaflet";
import { LatLng } from "leaflet";

export const LocationMap = ({ marker }: { marker: LatLng }) => {
  return (
    <MapContainer
      zoom={16}
      center={marker}
      scrollWheelZoom={true}
      style={{ height: "100%", width: "100%" }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={marker}></Marker>
    </MapContainer>
  );
};
