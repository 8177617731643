import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useMachineContext } from "@/context/machine-context";
import { toast } from "react-hot-toast";
import { ref, set } from "firebase/database";
import { db } from "@/lib/sentinel-app";

export const useSetOeeRange = () => {
  const client = useQueryClient();
  const { machine } = useMachineContext();

  const machineName = machine?.machine as string;

  return useMutation({
    mutationFn: async (data: { lowerBound: number; upperBound: number }) => {
      const { lowerBound, upperBound } = data;
      if (!machineName) {
        throw new Error("Machine name is not defined");
      }

      await set(ref(db, `/ranges/${machineName}`), {
        maxBad: lowerBound,
        maxMedium: upperBound,
      });
    },
    onSuccess: async () => {
      await client.invalidateQueries(["getMinMaxOeeRanges", machineName]);
      toast.success("Range saved successfully");
    },
    onError: () => {
      toast.error("Failed to update OEE ranges");
    },
  });
};
