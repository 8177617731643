import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { ThingworxError } from "src/types/error";

type Payload = {
  user_id: number;
  add: string[];
  remove: string[];
};
export const useSaveUserConfiguration = () => {
  const { appKey } = useFirebaseContext();
  const { user } = useFirebaseContext();
  const email = user?.email as string;

  const client = useQueryClient();
  return useMutation({
    mutationFn: async (payload: Payload) => {
      const response = await FetchClient<
        Payload,
        { response: true; message: string } | ThingworxError
      >({
        appKey,
        payload,
        url: "dig.c.usersAndMachinesManagement_thing/Services/assignMachinesToUser",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return response;
    },
    onSuccess: () => {
      toast.success("User configuration saved!");
      client.invalidateQueries(["colleagues-machines-access", email]);
    },
    onError: () => {
      toast.error("Something went wrong!");
    },
  });
};
