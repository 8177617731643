import { useEffect } from "react";
import { Stack } from "@mui/material";
import { useAcoposDispatchContext } from "../context/acopos-context";
import { SelectRuns } from "../components/Selections/SelectRuns";
import { HealthStatus } from "../components/Summary/HealthStatus/HealthStatus";
import { HeatmapSection } from "../components/Summary/HeatmapSection/HeatmapSection";

export const Summary = () => {
  const dispatch = useAcoposDispatchContext();

  useEffect(() => {
    dispatch({ type: "SET_MAX_DAYS_CALENDAR", item: 7 });
    dispatch({ type: "SET_IS_RUNS_LIMIT_ACTIVE", item: false });

    return () => {
      dispatch({ type: "SET_IS_RUNS_LIMIT_ACTIVE", item: true });
    };
  }, [dispatch]);

  return (
    <Stack gap={1}>
      <HealthStatus />
      <SelectRuns enableFilter={true} />
      <HeatmapSection />
    </Stack>
  );
};
