import { SVGProps } from "react";

export const DisconnectedCategory = ({
  width = 24,
  height = 24,
  fill = "#EEEEEE",
  ...rest
}: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    {...rest}
  >
    <g clipPath="url(#clip0_1249_5505)">
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.3522 1.91898C17.7936 -0.639665 13.6452 -0.639661 11.0866 1.91899L7.98257 5.02307L18.9769 16.0175L22.081 12.9134C24.6397 10.3548 24.6397 6.20639 22.081 3.64775L21.5602 3.1269L23.7935 0.893561L23.1064 0.206489L20.8731 2.43984L20.3522 1.91898ZM11.7737 2.60605C13.9528 0.426869 17.486 0.426866 19.6652 2.60605L21.394 4.33482C23.5731 6.51401 23.5731 10.0471 21.3939 12.2263L18.9769 14.6434L9.35671 5.02307L11.7737 2.60605Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.02304 7.98248L1.91897 11.0866C-0.639657 13.6452 -0.639659 17.7936 1.91897 20.3523L2.43991 20.8732L0.206724 23.1064L0.893793 23.7935L3.12697 21.5603L3.64773 22.0811C6.20636 24.6396 10.3548 24.6397 12.9133 22.081L16.0174 18.9769L13.5865 16.5459L15.8198 14.3126L15.1328 13.6255L12.8994 15.8589L8.14106 11.1005L10.3744 8.86713L9.68737 8.18006L7.454 10.4134L5.02304 7.98248ZM2.60604 11.7736L5.02304 9.35662L14.6433 18.9769L12.2263 21.3939C10.0472 23.5731 6.51398 23.5731 4.33481 21.394L2.60604 19.6652C0.426864 17.486 0.426864 13.9528 2.60604 11.7736Z"
        />
      </g>
    </g>
    <defs>
      <clipPath>
        <rect width={24} height={24} />
      </clipPath>
    </defs>
  </svg>
);
