import { RangePicker } from "@/components/Calendars/RangePicker";
import { useTimeSelection } from "@/store/useTimeSelection";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect } from "react";
import {
  useA4GateContext,
  useA4GateContextDispatch,
} from "../context/a4gate-context";

export type A4GateTimeSelection = "Hour" | "Day" | "Week" | "Month" | "Custom";

const timeSelectionList = ["Hour", "Day", "Week", "Month", "Custom"];

export const TimeSelection = ({
  forceCalendar = false,
}: {
  forceCalendar?: boolean;
}) => {
  const { timeSelection } = useA4GateContext();
  const { setTimeSelection } = useA4GateContextDispatch();
  const end = DateTime.now();
  const start =
    timeSelection === "Day"
      ? end.minus({ days: 1 })
      : timeSelection === "Week"
      ? end.minus({ weeks: 1 })
      : timeSelection === "Month"
      ? end.minus({ months: 1 })
      : end.minus({ hour: 1 });

  useEffect(() => {
    useTimeSelection.setState((prevState) => ({
      ...prevState,
      dates: {
        dateStart: start,
        dateEnd: end,
      },
    }));
  }, [timeSelection]);

  return (
    <>
      <FormControl>
        <InputLabel id="server">View</InputLabel>
        <Select
          labelId="server"
          label="View"
          value={timeSelection}
          id="server"
          name="server"
          size="small"
          onChange={(e) =>
            setTimeSelection(e.target.value as A4GateTimeSelection)
          }
          sx={{
            fontWeight: "bold",
            width: "10rem",
          }}
        >
          {timeSelectionList.map((selection) => (
            <MenuItem key={selection} value={selection}>
              {selection}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {forceCalendar ? (
        <RangePicker onPickDate={() => {}} />
      ) : timeSelection === "Custom" ? (
        <RangePicker onPickDate={() => {}} />
      ) : null}
    </>
  );
};
