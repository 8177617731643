import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { useState } from "react";

import { useMachineContext } from "@/context/machine-context";
import { SelectTimeSelection } from "@/components/SelectTimeSelection";
import { NavLink } from "react-router-dom";
import { Grid, Tab, Tabs, Stack } from "@mui/material";
import PrecisionManufacturingOutlinedIcon from "@mui/icons-material/PrecisionManufacturingOutlined";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import { EfficiencyLosses } from "../../components/viewer/EfficiencyLosses";
import { KpiSelector } from "../../components/viewer/KpiSelector";
import { SpeedLosses } from "../../components/viewer/SpeedLosses";
import { SpeedSelector } from "../../components/viewer/SpeedSelector";
import { ProductSelector } from "../../components/viewer/ProductSelector";
import { useTranslate } from "@/i18n/config";

export const MachinePerformanceCyclic = () => {
  const { machine } = useMachineContext();
  const [page, setPage] = useState<"Efficiency" | "Speed" | "Production">(
    "Efficiency",
  );
  const translate = useTranslate();

  if (!machine)
    return <label>{translate("user_feedback.machine_not_found")}</label>;

  const viewer =
    page === "Efficiency" ? (
      <>
        <KpiSelector />
        <EfficiencyLosses />
      </>
    ) : page === "Speed" ? (
      <>
        <SpeedSelector />
        <SpeedLosses />
      </>
    ) : (
      <ProductSelector />
    );

  return (
    <>
      <NavLink to="/machinerPerformance/cyclic" />
      <MachineViewNavBar>
        <SelectTimeSelection />
      </MachineViewNavBar>
      <Stack spacing={5}>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Tabs
            value={page}
            onChange={(
              event: React.SyntheticEvent,
              newValue: "Efficiency" | "Speed" | "Production",
            ) => {
              setPage(newValue);
            }}
          >
            <Tab
              sx={{ mr: 7 }}
              icon={<InsightsOutlinedIcon fontSize="large" />}
              iconPosition="top"
              label={translate("efficiency")}
              value="Efficiency"
            ></Tab>
            <Tab
              sx={{ mr: 7 }}
              icon={<PrecisionManufacturingOutlinedIcon fontSize="large" />}
              iconPosition="top"
              label={translate("production")}
              value="Production"
            ></Tab>
            <Tab
              sx={{ mr: 7 }}
              icon={<SpeedOutlinedIcon fontSize="large" />}
              iconPosition="top"
              label={translate("speed")}
              value="Speed"
            ></Tab>
          </Tabs>
        </Grid>
        {viewer}
      </Stack>
    </>
  );
};
