import { z } from "zod";

export const PhaseData = z.object({
  name: z.string(),
  id: z.number(),
  from: z.number(),
  to: z.number(),
  status: z.array(
    z.object({
      statusName: z.string(),
      status: z.enum(["Bad", "Good"]),
    }),
  ),
  current: z.boolean().optional(),
  order: z.number(),
  info: z.array(z.string()).optional(),
});
export type PhaseData = z.infer<typeof PhaseData>;

export const SubphaseData = z.object({
  name: z.string(),
  subphase_id: z.number(),
  status: z.enum(["Bad", "Good"]),
  assets: z.array(
    z.object({
      assetName: z.string(),
      asset_id: z.number(),
      status: z.enum(["Bad", "Good"]),
    }),
  ),
  info: z.array(z.string()).optional(),
});

export type SubphaseData = z.infer<typeof SubphaseData>;

export const Batch = z.object({
  batchId: z.number(),
  batchName: z.string(),
  recipe: z.string(),
  from: z.number(),
  to: z.number(),
  aborted: z.boolean(),
  batch_duration: z.number(),
});
export type Batch = z.infer<typeof Batch>;

export type Phases =
  | "CIP"
  | "Sterilization"
  | "Leak Test"
  | "Freeze Drying"
  | "Bellows Test";

export const LineData = z.object({
  uom: z.string(),
  name: z.string(),
  type: z.string().optional(),
  data: z.array(z.array(z.number())),
  color: z.string().optional(),
});

export type LineData = z.infer<typeof LineData>;
