import { Box } from "@mui/material";
import { pink } from "@mui/material/colors";
import { Sender } from "../../store/chat-store";

const COLORS = {
  intellecta: "#2196f3",
  user: "#4caf50",
  sentryMode: pink[300],
};

export const ChatIcon = ({ sender }: { sender: Sender }) => {
  return (
    <Box
      sx={{
        borderRadius: 10000,
        width: 24,
        height: 24,
        bgcolor:
          sender === "Intellecta"
            ? COLORS.intellecta
            : sender === "User"
            ? COLORS.user
            : COLORS.sentryMode,
      }}
    />
  );
};
