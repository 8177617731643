import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { shuttle, Run } from "../../types";
import { useAcoposContext } from "../../context/acopos-context";

export type GetShuttlesResponse = ThingworxError | GetShuttlesResult;

type Payload = {
  machineName: string;
  runs: Run[];
};

const getShuttlesResult = z.object({
  response: z.literal(true),
  shuttles: z.array(shuttle),
});

export type GetShuttlesResult = z.infer<typeof getShuttlesResult>;

export const useGetShuttles = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const { runs } = useAcoposContext();

  return useQuery<GetShuttlesResult>({
    queryKey: ["shuttles-data", machine!.machine, JSON.stringify(runs)],
    queryFn: async () => {
      const response = await FetchClient<Payload, GetShuttlesResponse>({
        appKey,
        payload: {
          machineName: machine!.machine,
          runs,
        },
        // url: "dig.c.acopostrak_thing/Services/getShuttles",
        url: "dig.c.acoposTrack_thing/Services/getShuttles",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = getShuttlesResult.parse(response);

      return validatedResponse;
    },
  });
};
