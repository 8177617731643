import { Box, Typography } from "@mui/material";
import { LegendData } from "../Pages/LineDetail/components/LineStatesLog";

export const StatesLogLegend = ({ legendData }: { legendData: LegendData }) => {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      {Object.entries(legendData).map(([state, color]) => (
        <Box key={state} sx={{ display: "flex", alignItems: "center" }}>
          <Typography color={color}>●&nbsp;</Typography>
          <Typography variant="caption">{state}&nbsp;&nbsp;</Typography>
        </Box>
      ))}
    </Box>
  );
};
