import { Uom } from "../types";

export const parseUom = (uom: Uom): string => {
  switch (uom) {
    case "celsius":
      return "°C";
    case "deg":
      return "°";
    case "grade":
      return "°";
    case "meter":
      return "m";
    case "pascal":
      return "Pa";
    case "percentage":
      return "%";
    case "timestamp":
      return "";
    case "ppm":
      return "ppm";
    case "unit":
      return "";
    case "none":
      return "";
  }
};
