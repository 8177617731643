import { useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { message } from "../types";
import { intellectaClient } from "./intellectaClient";

type Payload = {
  user: string;
  chat_id: number;
};

const Response = z.object({
  chat_id: z.number(),
  chat_messages: z.array(message),
  last_machine_id: z.string().optional(),
});

type Response = z.infer<typeof Response>;

export const useGetHistoricChatById = () => {
  const { user } = useFirebaseContext();
  const email = user?.email || "";

  return useMutation({
    mutationFn: async ({ chat_id }: { chat_id: number }) => {
      const response = await intellectaClient<Payload, Response>({
        url: "/user/historic/chat",
        payload: { user: email, chat_id },
      });

      return Response.parse(response);
    },
  });
};
