import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { Run, contextInfo, thresholds } from "../../types";
import { useAcoposContext } from "../../context/acopos-context";

export type GetDecompositionDataResponse =
  | ThingworxError
  | GetDecompositionDataResult;

const decompositionPoint = z.object({
  timestamp: z.number(),
  value: z.number(),
  runId: z.number(),
  lapId: z.number(),
  segmentId: z.number(),
  shuttleId: z.number(),
});

export type DecompositionPoint = z.infer<typeof decompositionPoint>;

const resSchema = z.union([
  z.object({
    analyticName: z.literal("Entropic Factor by Lap"),
    analyticId: z.number(),
    chartType: z.literal("scatter"),
    metricType: z.literal("timestamp"),
    data: z.array(decompositionPoint),
    thresholds,
    contextInfo: contextInfo,
  }),
  z.object({
    analyticName: z.literal("Decomposition by Lap"),
    analyticId: z.number(),
    chartType: z.literal("scatter"),
    metricType: z.literal("timestamp"),
    data: z.array(decompositionPoint),
    thresholds,
    contextInfo: contextInfo,
  }),
]);

export type ResSchema = z.infer<typeof resSchema>;

const decompositionData = z.object({
  analyticName: z.string(),
  data: z.array(z.array(z.number())),
  contextInfo: contextInfo,
});

export type DecompositionData = z.infer<typeof decompositionData>;

type Payload = {
  machineName: string;
  runs: Run[];
  segment: number;
  shuttles: number[];
  analytics: number[];
};

const getDecompositionDataResult = z.object({
  response: z.literal(true),
  data: z.array(resSchema),
});

export type GetDecompositionDataResult = z.infer<
  typeof getDecompositionDataResult
>;

export const useGetDecompositionData = ({
  analytics,
}: {
  analytics: number[];
}) => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const { runs, activeSegment, shuttles } = useAcoposContext();

  return useQuery<GetDecompositionDataResult>({
    queryKey: [
      "decomposition-analytics-data",
      machine!.machine,
      JSON.stringify(runs),
      activeSegment,
      shuttles,
      JSON.stringify(analytics),
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, GetDecompositionDataResponse>(
        {
          appKey,
          payload: {
            machineName: machine!.machine,
            runs,
            segment: activeSegment!,
            shuttles,
            analytics,
          },
          // url: "dig.c.acopostrak_thing/Services/getDecompositionData",
          url: "dig.c.acoposTrack_thing/Services/getDecompositionData",
        },
      );

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = getDecompositionDataResult.parse(response);

      return validatedResponse;
    },
  });
};
