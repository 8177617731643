import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type Payload = {
  account: string;
};

type Response = ThingworxError | Result;

const Result = z.object({
  response: z.literal(true),
  users: z.array(z.string()),
});

type Result = z.infer<typeof Result>;

export const useGetSharableUsersList = () => {
  const { appKey, user } = useFirebaseContext();
  const email = user?.email as string;

  return useQuery<string[]>({
    queryKey: ["custom-chart-sharable-list", email],
    queryFn: async () => {
      const response = await FetchClient<Payload, Response>({
        appKey,
        payload: {
          account: email,
        },
        url: "dig.c.customChartsDev_thing/services/getSharableUsersList",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return Result.parse(response).users;
    },
  });
};
