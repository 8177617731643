import { Dispatch, SetStateAction } from "react";
import { TimePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { z } from "zod";
import { useTranslate } from "@/i18n/config";

export const Duration = z.object({
  hours: z.number(),
  minutes: z.number(),
  seconds: z.number(),
});

export type Duration = z.infer<typeof Duration>;

interface DurationInputProps {
  setDuration: Dispatch<SetStateAction<Duration>>;
  size?: "small" | "medium";
  defaultValue?: Duration;
}

export const DurationInput = ({
  setDuration,
  size = "medium",
  defaultValue,
}: DurationInputProps) => {
  const translate = useTranslate();
  const handleChange = (newValue: DateTime | null) => {
    const hours = newValue?.hour || 0;
    const minutes = newValue?.minute || 0;
    const seconds = newValue?.second || 0;
    setDuration({ hours, minutes, seconds });
  };

  return (
    <TimePicker
      label={translate("duration")}
      views={["hours", "minutes", "seconds"]}
      slotProps={{ textField: { size } }}
      timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
      ampm={false}
      defaultValue={
        defaultValue &&
        DateTime.now().set({
          hour: defaultValue?.hours,
          minute: defaultValue?.minutes,
          second: defaultValue?.seconds,
        })
      }
      onChange={handleChange}
    />
  );
};
