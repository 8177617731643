import { useState } from "react";
import { Box, Stack } from "@mui/material";
import { NoveltyAnomalyAnalyticsSelection } from "../components/Diagnostic/NoveltyAnomalyAnalyticsSelection";
import { NoveltyAnomalyAnalytics } from "../components/Diagnostic/NoveltyAnomalyAnalytics";
import { BatchRecipeSelection } from "../components/Selections/BatchRecipeSelection";
import { MotorSelection } from "../components/Selections/MotorSelection";

export const NoveltyAnomalyDetection = () => {
  const [noveltyAnalytics, setNoveltyAnalytics] = useState<string[]>([]);
  const [anomalyAnalytics, setAnomalyAnalytics] = useState<string[]>([]);

  return (
    <Stack gap={1} pt="1rem">
      <BatchRecipeSelection />
      <MotorSelection />
      <Box sx={{ display: "flex", gap: 1 }}>
        <NoveltyAnomalyAnalyticsSelection
          noveltyAnalytics={noveltyAnalytics}
          anomalyAnalytics={anomalyAnalytics}
          setNoveltyAnalytics={setNoveltyAnalytics}
          setAnomalyAnalytics={setAnomalyAnalytics}
        />
        <NoveltyAnomalyAnalytics
          noveltyAnalytics={noveltyAnalytics}
          anomalyAnalytics={anomalyAnalytics}
        />
      </Box>
    </Stack>
  );
};
