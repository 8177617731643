import { useContext } from "react";
import { QuantizationContext } from "./quantization-context";

export const useQuantizationContext = () => {
  const context = useContext(QuantizationContext);

  if (!context) {
    throw new Error(
      "You can only use this context inside QuantizationContextProvider",
    );
  }
  return context;
};
