import { SelectTimeSelection } from "@/components/SelectTimeSelection";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { Tabs, Tab, Button } from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";
import { RejectCausesAdvancedView } from "./components/RejectCausesAdvancedView";
import { RejectCausesBaseView } from "./components/RejectCausesBaseView";
import { useTranslate } from "@/i18n/config";
import { useMachineContext } from "@/context/machine-context";

export interface Period {
  start: DateTime;
  end: DateTime;
}

const SECTION_NAMES = {
  first: 0,
  second: 1,
};

export const RejectCauses = () => {
  const translate = useTranslate();
  const [section, setSection] = useState(SECTION_NAMES.first);
  const [selectedPeriod, setSelectedPeriod] = useState<Period | null>(null);
  const { machine } = useMachineContext();

  return (
    <>
      <MachineViewNavBar>
        {section === SECTION_NAMES.first ? (
          <SelectTimeSelection />
        ) : section === SECTION_NAMES.second ? (
          <>
            <SelectTimeSelection forceCalendar exclude={["Custom"]} />
            {selectedPeriod ? (
              <Button onClick={() => setSelectedPeriod(null)}>
                {translate("go_back_to_select")}
              </Button>
            ) : null}
          </>
        ) : null}
      </MachineViewNavBar>
      <Tabs
        value={section}
        aria-label="batch detail tabs"
        onChange={(_: React.SyntheticEvent, newValue: number) => {
          setSection(newValue);
        }}
      >
        <Tab value={SECTION_NAMES.first} label="Default" />
        <Tab
          value={SECTION_NAMES.second}
          label="Advanced"
          disabled={machine && machine.machine === "SL1053" ? false : true}
        />
      </Tabs>
      {section === SECTION_NAMES.first ? (
        <RejectCausesBaseView />
      ) : section === SECTION_NAMES.second ? (
        <RejectCausesAdvancedView
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod}
        />
      ) : null}
    </>
  );
};
