import { Stack, Typography } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { useGetNoveltyAndAnomalyData } from "../../api/useGetNoveltyAndAnomalyData";
import { NoveltyAnomalyAnalytic } from "./NoveltyAnomalyAnalytic";
import { useMotorsContext } from "../../context/useMotorsContext";

interface NoveltyAnomalyAnalyticsProps {
  noveltyAnalytics: string[];
  anomalyAnalytics: string[];
}

export const NoveltyAnomalyAnalytics = ({
  noveltyAnalytics,
  anomalyAnalytics,
}: NoveltyAnomalyAnalyticsProps) => {
  const { data, isLoading, error } = useGetNoveltyAndAnomalyData({
    noveltyAnalytics,
    anomalyAnalytics,
  });
  const { frames, motors } = useMotorsContext();
  const translate = useTranslate();

  if (isLoading) {
    return <AnalyticContainerSkeleton width="calc(100% - 320px)" />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  return (
    <Stack gap={1} width="calc(100% - 320px)">
      {data.length > 0 ? (
        data.map((analytic) => (
          <NoveltyAnomalyAnalytic
            key={`${analytic.analysisName}-${analytic.motorName}-${analytic.frameName}`}
            props={analytic}
          />
        ))
      ) : (noveltyAnalytics.length > 0 || anomalyAnalytics.length > 0) &&
        frames.length > 0 &&
        motors.length > 0 ? (
        <Card sx={{ height: 400 }}>
          <Typography variant="h4">
            {translate("motors.no_data_try_another")}
          </Typography>
          <Typography variant="h6">
            {translate("motors.select_at_least")}
          </Typography>
        </Card>
      ) : (
        <Card sx={{ height: 400 }}>
          <Typography sx={{ color: "red" }} variant="h4">
            {translate("motors.no_selection")}
          </Typography>
          <Typography variant="h6">
            {translate("motors.select_at_least")}
          </Typography>
        </Card>
      )}
    </Stack>
  );
};
