import { PointOptionsObject } from "highcharts";
import { HistogramData } from "../types";
import { EVALUATION_COLORS } from "./evaluationColors";

export const parseHistogramData = ({
  data,
  isDuration,
}: {
  data: HistogramData[];
  isDuration: boolean;
}): { categories: string[]; seriesData: PointOptionsObject[] } => {
  const seriesData: PointOptionsObject[] = [];
  const categories: string[] = [];

  data.forEach((entry) => {
    categories.push(entry.name);
    seriesData.push({
      y: isDuration ? entry.value / 1000 / 60 : entry.value,
      color: entry.aborted
        ? EVALUATION_COLORS.aborted.selected
        : EVALUATION_COLORS.good.selected,
    });
  });

  return { seriesData, categories };
};
