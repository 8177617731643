import { useTranslate } from "@/i18n/config";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { Card } from "@/components/Layout/Card";
import { useGetSingleNoveltyAndAnomalyData } from "../../api/Diagnostic/useGetSingleNoveltyAndAnomalyData";
import { ContextInformation } from "@/features/Motors/components/Layouts/ContextInformation";
import { ScatterAndLine } from "@/components/highcharts/scatter/ScatterAndLine";
import { DateTime } from "luxon";
import { generateColorScale } from "../../utils/dynamic-colors";

export const NoveltyAnomalyDetailModal = ({
  info,
  analyticId,
  isOpen,
  close,
}: {
  info: { runId: number; lapId: number; segmentId: number; shuttleId: number };
  analyticId: number;
  isOpen: boolean;
  close: () => void;
}) => {
  const translate = useTranslate();
  const { runId, lapId, segmentId, shuttleId } = info;
  const { data, isLoading, error } = useGetSingleNoveltyAndAnomalyData({
    analyticId,
    runId,
    lapId,
    segmentId,
    shuttleId,
  });

  if (isLoading)
    return (
      <Dialog onClose={close} open={isOpen} maxWidth="xl" fullWidth>
        <DialogTitle>
          {translate("acopos.analytics_raw_data_detail")}
        </DialogTitle>
        <DialogContent>
          <SkeletonCard height={400} />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={close}>
            {translate("actions.back")}
          </Button>
        </DialogActions>
      </Dialog>
    );

  if (error)
    return (
      <Dialog onClose={close} open={isOpen} maxWidth="xl" fullWidth>
        <DialogTitle>
          {translate("acopos.analytics_raw_data_detail")}
        </DialogTitle>
        <DialogContent>
          <Card sx={{ height: 400 }}>
            {translate("user_feedback.an_error_occurred")}
          </Card>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={close}>
            {translate("actions.back")}
          </Button>
        </DialogActions>
      </Dialog>
    );

  if (!data)
    return (
      <Dialog onClose={close} open={isOpen} maxWidth="xl" fullWidth>
        <DialogTitle>
          {translate("acopos.analytics_raw_data_detail")}
        </DialogTitle>
        <DialogContent>
          <Card sx={{ height: 400 }}>{translate("user_feedback.no_data")}</Card>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={close}>
            {translate("actions.back")}
          </Button>
        </DialogActions>
      </Dialog>
    );

  const { analyticName, contextInfo, data: chartData } = data.data;
  const lineSeries = chartData.map((entry) => entry.lineData);
  const scatterSeries = chartData.map((entry) => entry.scatterData);
  const seriesNames = chartData.map((entry) => entry.name);
  const scatterSeriesName = analyticName.toLowerCase().includes("anomal")
    ? "Anomalies"
    : analyticName.toLowerCase().includes("novelt")
    ? "Novelties"
    : "Novelties / Anomalies";
  const scatterSeriesColor = analyticName.toLowerCase().includes("anomal")
    ? "red"
    : "orange";
  const colors = generateColorScale(
    {
      r: 3,
      g: 244,
      b: 252,
    },
    {
      r: 234,
      g: 12,
      b: 250,
    },
    chartData.length,
  );

  return (
    <Dialog onClose={close} open={isOpen} maxWidth="xl" fullWidth>
      <DialogTitle>{translate("acopos.analytics_raw_data_detail")}</DialogTitle>
      <DialogContent>
        <Card>
          <ContextInformation props={contextInfo} />
          <ScatterAndLine
            title={analyticName}
            scrollbarEnabled={false}
            zoomType="xy"
            legend={{ enabled: true }}
            tooltip={{
              enabled: true,
              options: {
                pointFormatter: function () {
                  if (this.series.type === "scatter") {
                    return `
                  date: <b>${DateTime.fromMillis(this.x).toLocaleString(
                    DateTime.DATETIME_MED_WITH_SECONDS,
                  )}</b><br>
                  value: <b>${this.y}</b>
                `;
                  }
                  return `<span style='color: ${this.color}'>● </span>${this.series.name}: <b>${this.y}</b><br>`;
                },
              },
            }}
            yAxis={[
              {
                uom: "",
                series: scatterSeries.map((data) => {
                  return {
                    type: "scatter",
                    name: scatterSeriesName,
                    data,
                    color: scatterSeriesColor,
                    zIndex: 1,
                  };
                }),
              },
            ]}
            lineSeries={lineSeries.map((data, i) => {
              return {
                type: "line",
                name: seriesNames[i],
                data,
                color: colors[i],
              };
            })}
          />
        </Card>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={close}>
          {translate("actions.back")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
