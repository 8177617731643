import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { Loader } from "@/components/Layout/Loader";
import { useDeleteHistoricChat } from "../../api/useDeleteHistoricChat";
import { Chat } from "../../types";

export const DeleteHistoricChatModal = ({
  chat,
  isOpen,
  close,
}: {
  chat: Chat;
  isOpen: boolean;
  close: () => void;
}) => {
  const translate = useTranslate();
  const { mutate: deleteHistoricChat, isLoading } = useDeleteHistoricChat();

  if (isLoading) {
    return <Loader isLoading />;
  }

  const handleClose = () => {
    close();
  };

  const handleConfirm = () => {
    deleteHistoricChat(
      { chat_id: chat.id },
      {
        onSettled: (data) => {
          if (data === "OK") close();
        },
      },
    );
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={handleClose}>
      <DialogTitle> {translate("intellecta.delete_chat")}</DialogTitle>
      <DialogContent>
        <Stack gap={2} p={1}>
          <Typography variant="h6">
            {translate("intellecta.delete_chat_2")}
          </Typography>
          <Typography>{chat.title}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {translate("actions.cancel")}
        </Button>
        <Button variant="contained" onClick={handleConfirm}>
          {translate("actions.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
