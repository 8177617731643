import {
  RadialBarCustomLayer,
  RadialBarLayerId,
  ResponsiveRadialBar,
} from "@nivo/radial-bar";

export const NivoDoubleBarGauge = ({
  title,
  max,
  targetValue,
  realValue,
  CustomLayer,
  enableTrack = false,
}: {
  title: string;
  value: number;
  max: number;
  targetValue: number;
  realValue: number;
  enableTrack?: boolean;
  CustomLayer?: RadialBarCustomLayer;
  color: string;
}) => {
  const defaultLayers: (RadialBarCustomLayer | RadialBarLayerId)[] | undefined =
    ["tracks", "bars"];
  if (CustomLayer) {
    defaultLayers?.push(CustomLayer);
  }

  return (
    <ResponsiveRadialBar
      data={[
        {
          id: "Target",
          data: [
            {
              x: "blue",
              y: max, // qua sarà il valore massimo
              color: "rgba(27, 166, 157, 0.3)",
              tip: title,
            },
          ],
        },
        {
          id: "Target",
          data: [
            {
              x: "blue1",
              y: targetValue, // qua il valore effettivo
              color: "#1BA69D",
              tip: title,
            },
          ],
        },
        {
          id: "Real",
          data: [
            {
              x: "cyan",
              y: max,
              color: "rgba(1, 82, 145, 0.3)",
              tip: title,
            },
          ],
        },
        {
          id: "Real",
          data: [
            {
              x: "cyan1",
              y: realValue,
              color: "#015291",
              tip: title,
            },
          ],
        },
      ]}
      startAngle={0}
      isInteractive={true}
      endAngle={360}
      circularAxisOuter={null}
      enableTracks={enableTrack}
      enableLabels={false}
      cornerRadius={90}
      innerRadius={0.6}
      colors={(dat) => dat.data.color}
      //borderColor={{ from: color, modifiers: [["opacity", 0.5]] }} //non funziona
      maxValue={max > 0 ? max : "auto"}
      padding={0}
      padAngle={1}
      enableRadialGrid={false}
      enableCircularGrid={false}
      radialAxisStart={null}
      layers={[...defaultLayers]}
      theme={{
        tooltip: {
          container: {
            display: "none",
          },
        },
      }}
    />
  );
};
