import { useFirebaseContext } from "@/context/firebase-context";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { FetchClient } from "@/services/ApiClient";
import { RuleEngineJson } from "../types";

type Response = ThingworxError | { response: true };

type Payload = {
  platformLvl: number;
  newRuleName: string;
  json: RuleEngineJson;
};

export const useUpdateRule = () => {
  const { appKey, user, platformLvl } = useFirebaseContext();
  const account = user?.email as string;
  const client = useQueryClient();

  return useMutation({
    mutationFn: async ({
      newRuleName,
      json,
    }: {
      newRuleName: string;
      json: RuleEngineJson;
    }) => {
      const payload: Payload = { platformLvl, newRuleName, json };
      return await FetchClient<Payload, Response>({
        appKey,
        payload,
        url: "dig.c.event_thing/Services/updateAlertRuleEngine",
      });
    },
    onSuccess(data) {
      if (data.response) {
        client.invalidateQueries({
          queryKey: ["list-of-rules", account, platformLvl],
        });
      }
    },
  });
};
