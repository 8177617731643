import { Card } from "@/components/Layout/Card";
import { Solidgauge } from "@/components/highcharts/gauge/SolidGauge";
import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { Box, Slider, Stack, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";
import { useQuantizationContext } from "../../context/useQuantizationContext";
import { useQuantizationDispatchContext } from "../../context/useQuantizationDispatchContext";

export const QuantizazionErrorChart = () => {
  const { baseData, runtimeData } = useQuantizationContext();
  const dispatch = useQuantizationDispatchContext();

  const [attentionThreshold, setAttentionThreshold] = useState<number>(3.2);
  const [criticalThreshold, setCriticalThreshold] = useState<number>(4);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      dispatch({ type: "ADD_NEW_QUANTIZATION_POINT" });
    }, 1000);

    return () => clearInterval(refreshInterval);
  }, []);

  const latestValue = runtimeData.at(-1) ? runtimeData.at(-1)![1] : 0;

  return (
    <Card>
      <Grid2 container>
        <Grid2 xs={10}>
          <Box flex={1}>
            <LineChart.Custom
              title="Quantization Error"
              xAxisOptions={{
                scrollbar: {
                  enabled: false,
                },
              }}
              exportingEnabled={false}
              yAxis={[
                {
                  options: {
                    plotLines: [
                      {
                        value: attentionThreshold,
                        color: "green",
                        dashStyle: "ShortDash",
                      },
                      {
                        value: criticalThreshold,
                        color: "red",
                        dashStyle: "ShortDash",
                      },
                    ],
                  },
                  uom: "",
                  series: [
                    {
                      type: "line",
                      name: "Train Data",
                      data: baseData,
                      color: "#0066ff",
                    },
                    {
                      type: "line",
                      name: "Runtime Data",
                      data: runtimeData,
                      color: "#ff6600",
                    },
                  ],
                },
              ]}
            />

            <Box width={800}>
              <Stack direction="row" spacing={2}>
                <Typography sx={{ minWidth: "20ch" }}>
                  Attention threshold
                </Typography>
                <Slider
                  size="small"
                  sx={{ width: 300 }}
                  min={0}
                  max={5}
                  step={0.1}
                  value={attentionThreshold}
                  onChange={(event) => {
                    const { target } = event;

                    const val = (target as HTMLInputElement).value;

                    if (val === "") return;

                    const value = Number(val);
                    setAttentionThreshold(value);
                  }}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                />
                <Typography>{attentionThreshold}</Typography>
              </Stack>

              <Stack direction="row" spacing={2}>
                <Typography sx={{ minWidth: "20ch" }}>
                  Critical threshold
                </Typography>
                <Slider
                  size="small"
                  min={0}
                  max={5}
                  step={0.1}
                  value={criticalThreshold}
                  sx={{ width: 300 }}
                  onChange={(event) => {
                    const { target } = event;

                    const val = (target as HTMLInputElement).value;

                    if (val === "") return;

                    const value = Number(val);
                    setCriticalThreshold(value);
                  }}
                  aria-label="Small"
                  valueLabelDisplay="auto"
                />
                <Typography>{criticalThreshold}</Typography>
              </Stack>
            </Box>
          </Box>
        </Grid2>
        <Grid2 xs={2}>
          <Stack
            sx={{
              width: "100%",
            }}
            justifyItems="center"
            alignItems="center"
          >
            <Typography>Health state</Typography>
            <Gauge
              latestValue={latestValue}
              criticalThreshold={criticalThreshold}
            />
          </Stack>
        </Grid2>
      </Grid2>
    </Card>
  );
};

function Gauge({
  latestValue,
  criticalThreshold,
}: {
  latestValue: number;
  criticalThreshold: number;
}) {
  return (
    <Box width={150} position="relative">
      <Typography
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {latestValue.toFixed(2)}
      </Typography>
      <Solidgauge.Custom
        title=""
        plotOptions={{
          solidgauge: {
            innerRadius: "80%",
            dataLabels: {
              y: 5,
              borderWidth: 0,
              useHTML: true,
              enabled: false,
            },
          },
        }}
        legend={{
          enabled: false,
        }}
        tooltip={{
          enabled: false,
        }}
        yAxis={{
          options: {
            min: 0,
            max: criticalThreshold,
            stops: [
              [0.1, "#55BF3B"],
              [0.5, "#DDDF0D"],
              [0.9, "#DF5353"],
            ],
            lineWidth: 0,
            minorTickInterval: 0,
            tickAmount: 0,
            labels: {
              enabled: false,
            },
          },
          series: [
            {
              type: "solidgauge",
              data: [latestValue],
              rounded: true,
            },
          ],
        }}
      />
    </Box>
  );
}
