import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import {
  TestCircleLayoutLoader,
  GaugeSkeleton,
} from "@/features/MachineDetail/components/CircleLayout";
import { Stack, Grid } from "@mui/material";

export const ProcessHistoricLoadingView = () => {
  return (
    <Stack gap={2}>
      <Grid container columns={12} alignItems="center" spacing={2}>
        <Grid item xs={6} md={4}>
          <TestCircleLayoutLoader height={"12vmax"} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack gap={2}>
            <SkeletonCard height={100} />
          </Stack>
        </Grid>
        <Grid alignItems="center" justifyContent="center" item xs={12} md={4}>
          <TestCircleLayoutLoader height={"12vmax"} />
        </Grid>
      </Grid>
      <Grid
        id="machine-detail-gauge"
        container
        columns={12}
        alignItems="center"
      >
        <Grid
          item
          sx={{ margin: "auto" }}
          md={1}
          alignItems="center"
          flex={1}
          justifyContent="center"
        >
          <GaugeSkeleton
            sx={{
              padding: 1,
              margin: "auto",
              width: "9vmax",
              height: "9vmax",
            }}
          />
        </Grid>

        <Grid
          item
          sx={{ margin: "auto" }}
          md={1}
          alignItems="center"
          flex={1}
          justifyContent="center"
        >
          <GaugeSkeleton
            sx={{
              padding: 1,
              margin: "auto",
              width: "9vmax",
              height: "9vmax",
            }}
          />
        </Grid>
        <Grid
          item
          sx={{ margin: "auto" }}
          md={1}
          alignItems="center"
          flex={1}
          justifyContent="center"
        >
          <GaugeSkeleton
            sx={{
              padding: 1,
              margin: "auto",
              width: "9vmax",
              height: "9vmax",
            }}
          />
        </Grid>
        <Grid
          item
          sx={{ margin: "auto" }}
          md={1}
          alignItems="center"
          flex={1}
          justifyContent="center"
        >
          <GaugeSkeleton
            sx={{
              padding: 1,
              margin: "auto",
              width: "9vmax",
              height: "9vmax",
            }}
          />
        </Grid>
        <Grid
          item
          sx={{ margin: "auto" }}
          md={1}
          alignItems="center"
          flex={1}
          justifyContent="center"
        >
          <GaugeSkeleton
            sx={{
              padding: 1,
              margin: "auto",
              width: "9vmax",
              height: "9vmax",
            }}
          />
        </Grid>
      </Grid>
      <SkeletonCard height={300} />
    </Stack>
  );
};
