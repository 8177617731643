import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { DateTime } from "luxon";
import { Button, Grid, Stack, Typography, Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
  AreaSeries,
  Chart,
  HighchartsChart,
  Legend,
  LineSeries,
  Title,
  Tooltip,
  XAxis,
  YAxis,
} from "react-jsx-highcharts";
import { useTranslate } from "@/i18n/config";
import { HighchartsCustomProvider } from "@/components/highcharts/highcharts";
import { Piechart } from "@/components/highcharts/piechart/Piechart";
import { ItemCard } from "@/components/ItemCard";
import { Card } from "@/components/Layout/Card";
import { NavButton } from "@/components/NavButton";
import { SelectTimeSelection } from "@/components/SelectTimeSelection";
import { AlarmColumns } from "@/features/Alarms/components/AlarmColumns";
import {
  useProcessGetDataList,
  ProductionPeriodItem,
} from "@/features/MachineDetail/api/ProcessHistoric/useProcessGetDataList";
import { useProcessGetHistoricData } from "@/features/MachineDetail/api/ProcessHistoric/useProcessGetHistoricData";
import { GaugeLayout } from "@/features/MachineDetail/components/GaugeLayout";
import { GridItem } from "@/features/MachineDetail/components/GridItem";
import { Recipe } from "@/features/MachineDetail/components/Recipe";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { colors } from "@/styles/colors";
import { TimeHelpers, useFormatTimestamp } from "@/utils/TimeHelpers";
import { generatePlotBands } from "../../../utils/generatePLotBands";
import { ProcessHistoricLoadingView } from "./ProcessHistoricLoadingView";
import { LineDetailButton } from "@/features/MachineDetail/components/LineDetailButton";
import { useMachineContext } from "@/context/machine-context";
import { translateItemTitle } from "@/utils/itemCardTranslate";

export const ProcessHistoricView = () => {
  const { formatTimestamp } = useFormatTimestamp();
  const [selectedPeriod, setSelectedPeriod] = useState<ProductionPeriodItem | null>(null);
  const { data: list } = useProcessGetDataList();
  const translate = useTranslate();
  const { machine } = useMachineContext();

  useEffect(() => {
    setSelectedPeriod(null);
  }, [list]);

  return (
    <>
      <MachineViewNavBar>
        <SelectTimeSelection forceCalendar exclude={["Custom", "SixMonths", "Year"]} />

        {selectedPeriod ? (
          <Button onClick={() => setSelectedPeriod(null)}>{translate("go_back_to_select")}</Button>
        ) : null}

        <NavButton to="/machine-detail/process/live" text={translate("view_live")} />
        {machine?.lineId && <LineDetailButton lineId={machine.lineId} />}
      </MachineViewNavBar>

      {selectedPeriod ? (
        <Stack gap={2}>
          <HistoricSummaryContent item={selectedPeriod} />
        </Stack>
      ) : (
        <Stack spacing={2}>
          <Typography variant="h6" fontWeight="bold">
            {translate("please_select_a_period")}
          </Typography>
          <AnimatePresence presenceAffectsLayout mode="wait">
            <motion.div
              key={JSON.stringify(list?.Result)}
              variants={{
                hidden: { opacity: 0 },
                show: {
                  opacity: 1,
                },
              }}
              initial="hidden"
              animate="show"
            >
              <Grid container spacing={2}>
                {list?.Result.map((item) => (
                  <Grid item xs={6} md={3} key={item.id}>
                    <ItemCard
                      item={item}
                      onClick={(i) => setSelectedPeriod(i)}
                      subtitle={`${translate("durations.hour_other")} ${formatTimestamp({
                        timestamp: item.dataStartTimeLapse,
                        fmt: "HH:mm ",
                      })}`}
                      title={translateItemTitle(item.timeLapse, translate)}
                      optionalLabel={formatTimestamp({
                        timestamp: item.dataStartTimeLapse,
                        fmt: "DDD",
                      })}
                    />
                  </Grid>
                ))}
              </Grid>
            </motion.div>
          </AnimatePresence>
        </Stack>
      )}
    </>
  );
};

function HistoricSummaryContent({ item }: { item: ProductionPeriodItem }) {
  const { data, isLoading, error } = useProcessGetHistoricData({ item });
  const translate = useTranslate();

  if (isLoading) {
    return <ProcessHistoricLoadingView />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  const minimumDate = data.efficiencyData.oee[0]?.[0] ?? DateTime.now().toMillis();

  const maxDate =
    data.efficiencyData.oee[data.efficiencyData.oee.length - 1]?.[0] ??
    DateTime.now().minus({ days: 1 }).toMillis();

  const plotBands = generatePlotBands({
    data: data.efficiencyData.plotBands.data,
    currentState: "", //! non viene passata la chiave
    minimumDate: minimumDate,
    maxDate: maxDate,
  });

  return (
    <Stack gap={2}>
      <Grid2 container columns={12} alignItems="center" spacing={2}>
        <Grid2 xs={6} md={4}>
          <GaugeLayout
            min={0}
            color={colors.kpi.oee}
            max={100}
            dimension="large"
            title={translate("kpi.poee")}
            type="percentage"
            value={data.kpiData.oee}
          ></GaugeLayout>
        </Grid2>

        <Grid2 xs={12} md={4}>
          <Stack gap={2}>
            <Recipe
              recipe={translate("machine.recipe", {
                count: data.recipes.length,
              })}
              recipeChanged
              recipeClient={""}
              recipeContent={data.recipes.map((item) => ({
                ...item,
                Duration: TimeHelpers.parseDurationToString({
                  duration: item.Duration * 1000,
                }),
              }))}
            />
          </Stack>
        </Grid2>
        <Grid2 xs={12} md={4}>
          <Box
            sx={{
              paddingX: "15%",
              height: {
                sx: "200px",
                md: "auto",
              },
            }}
          >
            <Piechart data={data.pieChartData} label="" title="" version="machineDetail" />
          </Box>
        </Grid2>
      </Grid2>

      <Grid2 container columns={12} alignItems="center" spacing={2}>
        <GridItem>
          <GaugeLayout
            dimension="small"
            title={translate("kpi.process_quality")}
            value={data.kpiData.processQuality}
            min={0}
            max={100}
            type={"percentage"}
            color={colors.kpi.availability}
          />
        </GridItem>
        <GridItem>
          <GaugeLayout
            dimension="small"
            title={translate("machine.elapsed_time")}
            value={data.kpiData.plannedProductionTime}
            min={0}
            max={data.kpiData.totalTime}
            type={"duration"}
            color={colors.kpi.uptime}
          />
        </GridItem>
        <GridItem>
          <GaugeLayout
            dimension="small"
            title={translate("machine.planned_time")}
            value={data.kpiData.plannedProductionTime}
            min={0}
            max={data.kpiData.totalTime}
            type={"duration"}
            color={colors.kpi.quality}
          />
        </GridItem>
        <GridItem>
          <GaugeLayout
            dimension="small"
            title={translate("machine.net_uptime")}
            value={data.kpiData.netUptime}
            min={0}
            max={data.kpiData.totalTime}
            type={"duration"}
            color={colors.kpi.elapsedTime}
          />
        </GridItem>
        <GridItem>
          <GaugeLayout
            dimension="small"
            title={translate("machine.inactive_time")}
            value={data.kpiData.inactiveTime}
            min={0}
            max={data.kpiData.totalTime}
            type={"duration"}
            color={colors.kpi.inactiveTime}
          />
        </GridItem>
      </Grid2>

      <Card>
        <HighchartsCustomProvider>
          <HighchartsChart
            exporting={{
              enabled: false,
            }}
            accessibility={{ enabled: false }}
            plotOptions={{}}
          >
            <Chart
              backgroundColor={"transparent"}
              type=""
              zooming={{ type: "x" }}
              style={{
                color: "white",
              }}
            />
            <Title style={{ color: "white" }} align="left">
              {translate("kpis_performance")}
            </Title>
            <Tooltip shared useHTML />

            <Legend
              itemStyle={{
                color: "white",
              }}
            ></Legend>
            <XAxis
              labels={{ style: { color: "white" } }}
              scrollbar={{
                enabled: false,
              }}
              type={"datetime"}
            ></XAxis>

            <YAxis
              labels={{ style: { color: "white" }, format: "{value} " + "%" }}
              showEmpty
              type={"linear"}
              opposite
              max={100}
              min={0}
              alignTicks={false}
            >
              <LineSeries
                marker={{
                  enabled: false,
                  states: {
                    hover: {
                      enabled: false,
                    },
                  },
                }}
                borderColor="none"
                color={colors.kpi.processOee}
                dashStyle={"Solid"}
                type={"line"}
                name={translate("kpi.process_oee")}
                data={data.efficiencyData.oee}
              />
              <LineSeries
                marker={{
                  enabled: false,
                  states: {
                    hover: {
                      enabled: false,
                    },
                  },
                }}
                dashStyle={"LongDash"}
                color={colors.kpi.processQuality}
                name={translate("kpi.process_quality")}
                data={data.efficiencyData.quality}
              />
              <AreaSeries
                color={"#0ab126"}
                opacity={0.4}
                name={data.efficiencyData.plotBands.name ?? translate("production")}
                data={plotBands}
                enableMouseTracking={false}
              />
            </YAxis>
          </HighchartsChart>
        </HighchartsCustomProvider>
      </Card>
      <AlarmColumns alarmData={data.alarms} />
    </Stack>
  );
}
