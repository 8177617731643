import { useMachineContext } from "@/context/machine-context";
import { useTranslate } from "@/i18n/config";
import {
  Stack,
  Button,
  Menu,
  FormControl,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useState } from "react";
import { PhysicalData } from "../api/useGetDataListExternal";

export const PhysicalDropdownList = ({
  asset_list,
  selectedAssets,
  setSelectedAssets,
}: {
  asset_list: PhysicalData[];
  selectedAssets: string[];
  setSelectedAssets: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { machine } = useMachineContext();
  const translate = useTranslate();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Stack gap={4}>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="outlined"
        disabled={asset_list.length === 0}
      >
        {asset_list.length > 0
          ? translate("physicals.select_physicals")
          : translate("physicals.no_physical_assets_available")}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <FormControl sx={{ m: 3 }} component="fieldset" variant="filled">
          <List sx={{ maxHeight: 300, overflowY: "auto" }}>
            {asset_list.map((asset) => (
              <ListItem key={asset.id} disablePadding>
                <ListItemButton
                  role={undefined}
                  dense
                  onClick={() =>
                    setSelectedAssets((prevAssets) => {
                      const existingAsset = prevAssets.includes(asset.id);
                      if (existingAsset) {
                        const newAssets = prevAssets.filter((a) => a !== asset.id);
                        setAssetsForMachine({
                          machine: machine!.machine,
                          assets: newAssets,
                        });
                        return newAssets;
                      }
                      const newAssets = [...prevAssets, asset.id];
                      setAssetsForMachine({
                        machine: machine!.machine,
                        assets: newAssets,
                      });
                      return newAssets;
                    })
                  }
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={selectedAssets.includes(asset.id)}
                      edge="start"
                      tabIndex={-1}
                      disableRipple
                      name={asset.name}
                    />
                  </ListItemIcon>
                  <ListItemText id={asset.id} primary={asset.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </FormControl>
      </Menu>
    </Stack>
  );
};

function setAssetsForMachine({ machine, assets }: { machine: string; assets: string[] }) {
  let localStorageData: { [key: string]: string[] } = JSON.parse(
    localStorage.getItem("physicalsFavorites") || "{}",
  );
  if (Object.keys(localStorageData).includes(machine)) {
    localStorageData[machine] = assets;
  } else {
    localStorageData = {
      ...localStorageData,
      [machine]: assets,
    };
  }
  localStorage.setItem("physicalsFavorites", JSON.stringify(localStorageData));
}
