import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { AdvancedAnalyticEvaluationInfo } from "./useGetAdvancedAnalysisList";
import { z } from "zod";

type EnvelopeAnalyticResponse = ThingworxError | EnvelopeAnalyticDataResult;

const EnvelopeAnalyticDataResult = z.object({
  response: z.literal(true),
  datapoints: z.array(z.array(z.number())),
  envelopePoints: z.array(z.array(z.number())),
});

type EnvelopeAnalyticDataResult = z.infer<typeof EnvelopeAnalyticDataResult>;

type Payload = {
  asset_id: number;
  from: number;
  to: number;
  envelope_id?: number;
};

export const useGetEnvelopeAnalyticData = ({
  analytic,
}: {
  analytic: AdvancedAnalyticEvaluationInfo;
}) => {
  const { appKey } = useFirebaseContext();

  return useQuery<EnvelopeAnalyticDataResult>({
    queryKey: ["lyo-envelope-analytic", analytic.id],
    queryFn: async () => {
      const response = await FetchClient<Payload, EnvelopeAnalyticResponse>({
        appKey,
        payload: {
          asset_id: analytic.asset_id,
          from: analytic.from,
          to: analytic.to,
          envelope_id: analytic.envelope_id,
        },
        url: "dig.c.lyophilizer_thing/Services/getEnvelopeAnalyticData",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return EnvelopeAnalyticDataResult.parse(response);
    },
    refetchInterval: false,
  });
};
