import { Button, CircularProgress } from "@mui/material";
import { DownloadableDocument as Props } from "../api/useGetManualList";
import { useDownloadDocument } from "../api/useDownloadDocument";

export const DownloadableDocument = ({ name, filesPath }: Props) => {
  const { downloadDocument, isLoading } = useDownloadDocument({
    filePath: filesPath,
    fileName: name,
  });

  return (
    <Button variant="outlined" disabled={isLoading} onClick={downloadDocument}>
      {isLoading ? <CircularProgress size={24} color="inherit" /> : name}
    </Button>
  );
};
