import { z } from "zod";

export const variableSchema = z.object({
  id: z.number(),
  display_name: z.string(),
  description: z.string().optional(),
  category: z.string(),
  uom: z.string().optional(),
  machine_id: z.string(),
  y_axis: z.string(),
  series_type: z.enum(["line", "bar", "xrange", "scatter", "pie"]),
});

export type Variable = z.infer<typeof variableSchema>;

export const ChartType = z.enum(["timeseries", "aggregated"]);
export type ChartType = z.infer<typeof ChartType>;

export const dataVariableSchema = z.discriminatedUnion("series_type", [
  variableSchema.extend({
    series_type: z.literal("line"),
    data: z.object({
      name: z.string(),
      values: z.array(z.array(z.number()).length(2)),
    }),
  }),
  variableSchema.extend({
    series_type: z.literal("bar"),
    data: z.object({
      name: z.string(),
      categories: z.array(z.string()),
      series: z.array(
        z.object({
          name: z.string(),
          values: z.array(z.number()),
        })
      ),
    }),
  }),
  variableSchema.extend({
    series_type: z.literal("scatter"),
    data: z.object({
      name: z.string(),
      values: z.array(
        z.object({
          x: z.number(),
          y: z.number(),
          color: z.string().optional(),
        })
      ),
      categories: z.array(z.string()).optional(),
    }),
  }),
  variableSchema.extend({
    series_type: z.literal("xrange"),
    data: z.object({
      name: z.string(),
      values: z.array(
        z.object({
          x: z.number(),
          x2: z.number(),
          y: z.number(),
          color: z.string().optional(),
        })
      ),
      categories: z.array(z.string()),
    }),
  }),
  variableSchema.extend({
    series_type: z.literal("pie"),
    data: z.object({
      name: z.string(),
      values: z.array(
        z.object({
          z: z.number(),
          name: z.string(),
          y: z.number(),
          color: z.string().optional(),
        })
      ),
    }),
  }),
]);

export type DataVariable = z.infer<typeof dataVariableSchema>;
