import { XrangePointOptionsObject } from "highcharts";
import { StateLogAlarm } from "../api/useGetAlarmsByRange";

export const parseAlarmsStatesLog = ({
  data,
  color,
}: {
  color: string;
  data: StateLogAlarm[];
}): {
  categories: string[];
  chartData: XrangePointOptionsObject[];
} => {
  const categories: string[] = [];
  const chartData: XrangePointOptionsObject[] = [];

  data.forEach((occurrence) => {
    const { start, end, alarmCode, alarmDescription } = occurrence;
    const alarmCodeStr = alarmCode.toString();
    if (!categories.includes(alarmCodeStr)) categories.push(alarmCodeStr);
    chartData.push({
      name: `${alarmCodeStr} - ${alarmDescription}`,
      x: start,
      x2: end,
      y: categories.indexOf(alarmCodeStr),
      color,
    });
  });

  return { categories, chartData };
};
