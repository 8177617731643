import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { Run, evaluation, heatmapData } from "../../types";

type HeatmapResponse = ThingworxError | HeatmapResult;

const heatmapResult = z.object({
  response: z.literal(true),
  data: z.record(heatmapData),
  evaluations: z.record(z.record(evaluation)),
});

export type HeatmapResult = z.infer<typeof heatmapResult>;

interface Payload {
  machineName: string;
  runs: Run[];
}

export const useGetShuttlesAndSegmentsHeatMap = ({ runs }: { runs: Run[] }) => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();

  return useQuery<HeatmapResult>({
    queryKey: [
      "shuttles-and-segments-heatmap",
      machine?.machine,
      JSON.stringify(runs),
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, HeatmapResponse>({
        appKey,
        payload: {
          machineName: machine?.machine || "",
          runs,
        },
        // url: "dig.c.acopostrak_thing/Services/getShuttlesAndSegmentsHeatMap",
        url: "dig.c.acoposTrack_thing/Services/getShuttlesAndSegmentsHeatMap",
      });

      if (!response.response) throw new Error(response.errorString);

      const validatedResponse = heatmapResult.parse(response);
      return validatedResponse;
    },
    // refetchInterval: ONE_MINUTE,
  });
};
