import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  TextField,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslate } from "@/i18n/config";
import { Loader } from "@/components/Layout/Loader";
import { useRenameHistoricChat } from "../../api/useRenameHistoricChat";
import { Chat } from "../../types";

interface IFormInput {
  title: string;
}

export const RenameHistoricChatModal = ({
  chat,
  isOpen,
  close,
}: {
  chat: Chat;
  isOpen: boolean;
  close: () => void;
}) => {
  const translate = useTranslate();
  const { mutate: renameHistoricChat, isLoading } = useRenameHistoricChat();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: {
      title: chat.title,
    },
  });

  if (isLoading) {
    return <Loader isLoading />;
  }

  const handleClose = () => {
    close();
  };

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    renameHistoricChat(
      {
        chat_id: chat.id,
        title: data.title,
      },
      {
        onSettled: (data) => {
          if (data === "OK") close();
        },
      },
    );
  };

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogTitle> {translate("intellecta.rename_chat")}</DialogTitle>
        <DialogContent>
          <Stack gap={2} p={1}>
            <Controller
              name="title"
              control={control}
              rules={{
                required: "Please enter a title",
                maxLength: {
                  value: 35,
                  message: "Maximum 35 characters",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="title"
                  size="small"
                  placeholder="Add a title"
                  type="text"
                  onChange={onChange}
                  error={!!errors.title}
                  helperText={errors.title?.message}
                  value={value}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {translate("actions.cancel")}
          </Button>
          <Button variant="contained" type="submit">
            {translate("actions.confirm")}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
