import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { contextInfo } from "../../types";

export type GetSingleNoveltyAndAnomalyDataResponse =
  | ThingworxError
  | GetSingleNoveltyAndAnomalyDataResult;

export const noveltyAndAnomalyData = z.object({
  analyticName: z.string(),
  data: z.array(
    z.object({
      name: z.string(),
      lineData: z.array(z.array(z.number())),
      scatterData: z.array(z.array(z.number())),
    }),
  ),
  contextInfo: contextInfo,
});

export type NoveltyAndAnomalyData = z.infer<typeof noveltyAndAnomalyData>;

type Payload = {
  machineName: string;
  runId: number;
  lapId: number;
  segmentId: number;
  shuttleId: number;
  analyticId: number;
};

const getSingleNoveltyAndAnomalyDataResult = z.object({
  response: z.literal(true),
  data: noveltyAndAnomalyData,
});

export type GetSingleNoveltyAndAnomalyDataResult = z.infer<
  typeof getSingleNoveltyAndAnomalyDataResult
>;

export const useGetSingleNoveltyAndAnomalyData = ({
  runId,
  lapId,
  segmentId,
  shuttleId,
  analyticId,
}: {
  runId: number;
  lapId: number;
  segmentId: number;
  shuttleId: number;
  analyticId: number;
}) => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();

  return useQuery<GetSingleNoveltyAndAnomalyDataResult>({
    queryKey: [
      "single-novelty-anomaly-data",
      machine!.machine,
      runId,
      lapId,
      segmentId,
      shuttleId,
      analyticId,
    ],
    queryFn: async () => {
      const response = await FetchClient<
        Payload,
        GetSingleNoveltyAndAnomalyDataResponse
      >({
        appKey,
        payload: {
          machineName: machine!.machine,
          runId,
          lapId,
          segmentId,
          shuttleId,
          analyticId,
        },
        // url: "dig.c.acopostrak_thing/Services/getSingleNoveltyAndAnomalyData",
        url: "dig.c.acoposTrack_thing/Services/getSingleNoveltyAndAnomalyData",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse =
        getSingleNoveltyAndAnomalyDataResult.parse(response);

      return validatedResponse;
    },
  });
};
