import { createContext, PropsWithChildren, useContext } from "react";
import { useImmerReducer } from "use-immer";
import { useGetIsActive } from "../api/useGetIsActive";
import { usePauseCheck } from "../api/usePauseCheck";

interface AcceptanceTestContextState {
  isSatActive: boolean;
  hasActivatedRequestAT: boolean;
  satConfiguration: "Blocked" | "Free";
  satConfigurationDisabled: boolean;
  isPauseSat: boolean;
  isLimitedAT: boolean;
  viewMode: "Live" | "Historic";
  startUtc: number;
  historicTimeLapse: {
    startTime: number;
    endTime: number;
  };
  isSelectedPeriod: boolean;
  isClosedSat: boolean;
}

export type Action =
  | { type: "test-is-active"; isSatActive: boolean }
  | { type: "sat-configuration"; satConfiguration: "Blocked" | "Free" }
  | { type: "sat-configuration-disabled"; satConfigurationDisabled: boolean }
  | { type: "check-sat-isPaused"; isPauseSat: boolean }
  | { type: "get-isLimitedAT"; isLimitedAT: boolean }
  | { type: "view-mode"; viewMode: "Live" | "Historic" }
  | { type: "get-startUtc"; startUtc: number }
  | {
      type: "get-historicTimeLapse";
      historicTimeLapse: {
        startTime: number;
        endTime: number;
      };
    }
  | {
      type: "selected-period";
      isSelectedPeriod: boolean;
    }
  | { type: "close-sat"; isClosedSat: boolean };

const reducer = (draft: AcceptanceTestContextState, action: Action) => {
  switch (action.type) {
    case "test-is-active":
      draft.isSatActive = action.isSatActive;
      break;
    case "sat-configuration":
      draft.satConfiguration = action.satConfiguration;
      break;
    case "sat-configuration-disabled":
      draft.satConfigurationDisabled = action.satConfigurationDisabled;
      break;
    case "check-sat-isPaused":
      draft.isPauseSat = action.isPauseSat;
      break;
    case "get-isLimitedAT":
      draft.isLimitedAT = action.isLimitedAT;
      break;
    case "view-mode":
      draft.viewMode = action.viewMode;
      break;
    case "get-startUtc":
      draft.startUtc = action.startUtc;
      break;
    case "get-historicTimeLapse":
      draft.historicTimeLapse = action.historicTimeLapse;
      break;
    case "selected-period":
      draft.isSelectedPeriod = action.isSelectedPeriod;
      break;
    case "close-sat":
      draft.isClosedSat = action.isClosedSat;
      break;
    default:
      return draft;
  }
};

const AcceptanceTestContext = createContext<AcceptanceTestContextState | null>(
  null,
);
const AcceptanceTestDispatch = createContext<React.Dispatch<Action> | null>(
  null,
);
export const AcceptanceTestContextProvider = ({
  children,
}: PropsWithChildren) => {
  const { data: pauseResponse = { isPauseSat: false } } = usePauseCheck();
  const { isPauseSat } = pauseResponse;
  const {
    data: isSatActiveResponse = {
      isActiveSat: false,
      hasActivatedRequestAT: false,
    },
  } = useGetIsActive();
  const { isActiveSat, hasActivatedRequestAT } = isSatActiveResponse;

  const [state, dispatch] = useImmerReducer<AcceptanceTestContextState, Action>(
    reducer,
    {
      isSatActive: false,
      hasActivatedRequestAT: false,
      satConfiguration: "Blocked",
      satConfigurationDisabled: false,
      isPauseSat: false,
      isLimitedAT: false,
      viewMode: "Live",
      startUtc: 0,
      historicTimeLapse: {
        startTime: 0,
        endTime: 0,
      },
      isSelectedPeriod: false,
      isClosedSat: false,
    },
  );

  return (
    <AcceptanceTestContext.Provider
      value={{
        ...state,
        isPauseSat,
        isSatActive: isActiveSat,
        hasActivatedRequestAT,
        satConfigurationDisabled: isActiveSat ? true : false,
      }}
    >
      <AcceptanceTestDispatch.Provider value={dispatch}>
        {children}
      </AcceptanceTestDispatch.Provider>
    </AcceptanceTestContext.Provider>
  );
};

export const useAcceptanceTestContext = () => {
  const context = useContext(AcceptanceTestContext);
  if (!context) {
    throw new Error(
      "useAcceptanceTestContext can only be used inside a AcceptanceTestContext",
    );
  }
  return context;
};

export const useAcceptanceTestContextDispatch = () => {
  const context = useContext(AcceptanceTestDispatch);
  if (!context) {
    throw new Error(
      "useAcceptanceTestContextDispatch can only be used inside a AcceptanceTestDispatch",
    );
  }
  return context;
};
