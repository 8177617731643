import { OverviewNavBar } from "@/pages/Overview/layout/OverviewNavBar/OverviewNavBar";
import {
  CreateOutlined,
  NotificationsOutlined,
  WarningAmberRounded,
  EventAvailableOutlined,
  EventRepeatOutlined,
} from "@mui/icons-material";
import { Stack, Grid, Tabs, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { EventsRouter } from "./components/EventsRouter";
import { SearchBar } from "./components/SearchBar";
import { useGetEventsData } from "./api/useGetEventsData";
import { useFirebaseContext } from "@/context/firebase-context";
import { filterRules } from "./utils/filterRules";
import { filterAlerts } from "./utils/filterAlerts";
import { useTranslate } from "@/i18n/config";
import { timezoneStore } from "@/store/useTimezoneStore";

export type EventsPage =
  | "Rule Definition"
  | "Events"
  | "Open"
  | "In Process"
  | "Completed";

export const Events = () => {
  const { user } = useFirebaseContext();
  const { setTimezone } = timezoneStore();
  const account = user?.email as string;
  const [page, setPage] = useState<EventsPage>("Rule Definition");
  const [machine, setMachine] = useState("---");
  const [query, setQuery] = useState("");
  const [showPersonal, setShowPersonal] = useState(false);
  const [
    { data: dataRules, isLoading: isLoadingRules, error: errorRules },
    { data: dataAlerts, isLoading: isLoadingAlerts, error: errorAlerts },
    { data: dataMachines, isLoading: isLoadingMachines },
  ] = useGetEventsData();

  const translate = useTranslate();

  useEffect(() => {
    setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, [setTimezone]);

  if (isLoadingAlerts || isLoadingRules || isLoadingMachines) {
    return (
      <>
        <OverviewNavBar hasTimezone />
        <Stack spacing={1}>
          <SkeletonCard height={80} />
          <SkeletonCard height={80} />
          <SkeletonCard height={400} />
        </Stack>
      </>
    );
  }

  if (errorAlerts || errorRules) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!dataAlerts || !dataRules) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  const filteredRules = filterRules({
    rules: dataRules,
    machine,
    query,
    showPersonal,
    account,
  });

  const filteredAlerts = filterAlerts({
    alerts: dataAlerts,
    machine,
    query,
    showPersonal,
    account,
  });

  const totalEventsNumber = filteredAlerts.length;
  const openEventsNumber = filteredAlerts.filter(
    (event) => event.stateOfTicket === 0,
  ).length;
  const inProcessEventsNumber = filteredAlerts.filter(
    (event) => event.stateOfTicket === 1,
  ).length;
  const completedEventsNumber = filteredAlerts.filter(
    (event) => event.stateOfTicket === 2,
  ).length;

  return (
    <>
      <OverviewNavBar hasTimezone />
      <Stack spacing={1}>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Tabs
            value={page}
            onChange={(_: React.SyntheticEvent, newValue: EventsPage) => {
              setPage(newValue);
            }}
          >
            <Tab
              sx={{ mr: 7 }}
              icon={<CreateOutlined fontSize="large" />}
              iconPosition="top"
              label={translate("events.rule_definition")}
              value="Rule Definition"
            />
            <Tab
              sx={{ mr: 7 }}
              icon={<NotificationsOutlined fontSize="large" />}
              iconPosition="top"
              label={`${translate("events.events")} (${totalEventsNumber})`}
              value="Events"
            />
            <Tab
              sx={{ mr: 7 }}
              icon={<WarningAmberRounded fontSize="large" />}
              iconPosition="top"
              label={`${translate("events.open")} (${openEventsNumber})`}
              value="Open"
            />
            <Tab
              sx={{ mr: 7 }}
              icon={<EventRepeatOutlined fontSize="large" />}
              iconPosition="top"
              label={`${translate(
                "events.in_process",
              )} (${inProcessEventsNumber})`}
              value="In Process"
            />
            <Tab
              sx={{ mr: 7 }}
              icon={<EventAvailableOutlined fontSize="large" />}
              iconPosition="top"
              label={`${translate(
                "events.completed",
              )} (${completedEventsNumber})`}
              value="Completed"
            />
          </Tabs>
        </Grid>
        <SearchBar
          machines={dataMachines ?? []}
          machine={machine}
          setMachine={setMachine}
          setQuery={setQuery}
          setShowPersonal={setShowPersonal}
        />
        <EventsRouter
          page={page}
          rules={filteredRules}
          alerts={filteredAlerts}
          machines={dataMachines ?? []}
        />
      </Stack>
    </>
  );
};
