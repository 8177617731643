import { QueryKey } from "@tanstack/react-query";

export class QueryBuilder {
  static buildWithCondition({
    baseQuery,
    condition,
    query,
  }: {
    baseQuery: QueryKey;
    condition: boolean;
    query: QueryKey;
  }) {
    return condition ? [...baseQuery, ...query] : baseQuery;
  }
}
