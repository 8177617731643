import { ThingworxError } from "src/types/error";
import { z } from "zod";

//* IMPUTATION LOG
export const imputationLogData = z.object({
  start: z.number(),
  end: z.number(),
  color: z.string(),
  category: z.string(),
  subCategory: z.optional(z.string()),
  shift: z.string(),
  id: z.number(),
  whoImputed: z.enum(["HMI", "Mobile"]),
  hasExpired: z.boolean(),
});

export type ImputationLogData = z.infer<typeof imputationLogData>;

export const timelapseDates = z.object({
  dateStart: z.number(),
  dateEnd: z.number(),
});

export type TimelapseDates = z.infer<typeof timelapseDates>;

export const imputationLogResult = z.object({
  data: z.array(imputationLogData),
  dates: timelapseDates,
  response: z.literal(true),
});

export type ImputationLogResult = z.infer<typeof imputationLogResult>;

export type ImputationLogResponse = ThingworxError | ImputationLogResult;

//* STATES LOG
export const statesLogData = z.object({
  start: z.number(),
  end: z.number(),
  state: z.string(),
  color: z.string(),
});

export type StatesLogData = z.infer<typeof statesLogData>;

export const statesLogResult = z.object({
  data: z.array(statesLogData),
  response: z.literal(true),
});

export type StatesLogResult = z.infer<typeof statesLogResult>;

export type StatesLogResponse = ThingworxError | StatesLogResult;

//* IMPUTATION STATS
export const imputationStatsData = z.object({
  category: z.string(),
  occurrences: z.number(),
  percentage: z.number(),
});

export type ImputationStatsData = z.infer<typeof imputationStatsData>;

export const imputationCountData = z.object({
  totalOccurrences: z.number(),
  totalImputed: z.number(),
  stopOccurrences: z.number(),
  stopImputed: z.number(),
  shutdownOccurrences: z.number(),
  shutdownImputed: z.number(),
});

export type ImputationCountData = z.infer<typeof imputationCountData>;

// export const percentageData = z.object({
//   total: z.number(),
//   stop: z.number(),
//   shutdown: z.number(),
// });

// export type percentageData = z.infer<typeof percentageData>;

export const imputationStatsResult = z.object({
  data: z.record(z.array(imputationStatsData)),
  count: imputationCountData,
  // percentage: percentageData,
  response: z.literal(true),
});

export type ImputationStatsResult = z.infer<typeof imputationStatsResult>;

export type ImputationStatsResponse = ThingworxError | ImputationStatsResult;

//* IMPUTATION CATEGORIES
export const imputationCategoriesData = z.object({
  category: z.string(),
  categoryId: z.number(),
  subCategory: z.string(),
  subCategoryId: z.number(),
  isEditable: z.boolean(),
});

export type ImputationCategoriesData = z.infer<typeof imputationCategoriesData>;

export const imputationCategory = z.object({
  category: z.string(),
  categoryId: z.number(),
});

export type ImputationCategory = z.infer<typeof imputationCategory>;

export const imputationCategoriesResult = z.object({
  data: z.array(imputationCategoriesData),
  categories: z.array(imputationCategory),
  response: z.literal(true),
});

export type ImputationCategoriesResult = z.infer<
  typeof imputationCategoriesResult
>;

export type ImputationCategoriesResponse =
  | ThingworxError
  | ImputationCategoriesResult;
