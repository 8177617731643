export const SentinelLoader = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      cx="50"
      cy="50"
      r="32"
      d="M60.2571 79.061C55.5682 80.8055 50.527 81.3899 45.5633 80.7646C40.5997 80.1392 35.8609 78.3226 31.7512 75.4696C27.6415 72.6167 24.2828 68.8121 21.9615 64.3803C19.6403 59.9485 18.4254 55.021 18.4204 50.0182C18.4204 32.9018 32.6592 18.9365 49.7755 18.9365L49.8837 28.8345H49.4755C46.3738 28.8351 43.3101 29.5175 40.5013 30.8333C37.6925 32.1491 35.2073 34.0661 33.2214 36.4488C31.2355 38.8314 29.7976 41.6214 29.0094 44.6213C28.2212 47.6212 28.102 50.7577 28.6602 53.8088C29.2184 56.8598 30.4404 59.7509 32.2396 62.2775C34.0389 64.804 36.3715 66.9042 39.0723 68.4294C41.7731 69.9546 44.7761 70.8676 47.8688 71.1037C50.9615 71.3398 54.0684 70.8933 56.9694 69.7957L60.2653 79.0549"
      fill="white"
      transform="rotate(113.243 245 245)"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        dur="1.7857142857142856s"
        repeatCount="indefinite"
        keyTimes="0;1"
        values="0 50 50;-360 50 50"
      ></animateTransform>
    </path>
    <path
      cx="50"
      cy="50"
      r="23"
      d="M50 14.302C57.0609 14.302 63.9632 16.3959 69.8341 20.3187C75.7049 24.2416 80.2807 29.8173 82.9827 36.3407C85.6847 42.8642 86.3915 50.0423 85.0139 56.9675C83.6362 63.8927 80.2359 70.2538 75.243 75.2465C70.2501 80.2391 63.8887 83.6391 56.9635 85.0163C50.0382 86.3936 42.8601 85.6863 36.3368 82.984C29.8135 80.2816 24.2381 75.7055 20.3155 69.8344C16.393 63.9633 14.2996 57.0609 14.3 50L14.5041 46.1837L0.255102 44.9592C0.0856053 46.6341 0.00046373 48.3165 0 50C0 77.6143 22.3857 100 50 100C77.6143 100 100 77.6143 100 50C100 22.3857 77.6143 0 50 0C50 0 49.9163 14.3 50 14.3V14.302Z"
      fill="white"
      transform="rotate(-113.243 50 50)"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        dur="1.7857142857142856s"
        repeatCount="indefinite"
        keyTimes="0;1"
        values="0 50 50;360 50 50"
      ></animateTransform>
    </path>
  </svg>
);
