import { useTranslate } from "@/i18n/config";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
  DialogActions,
  TextField,
} from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import { z } from "zod";

const TargetTimeSchema = z.object({
  dayInput: z.number().min(0),
  hourInput: z.number().min(0).max(24),
  minuteInput: z.number().min(0),
});
type TargetTimeSchemaType = z.infer<typeof TargetTimeSchema>;

function formatTimeInMillis(timeObj: TargetTimeSchemaType): number {
  const { dayInput, hourInput, minuteInput } = timeObj;
  const millis =
    (Number(dayInput * 1440) + Number(hourInput * 60) + Number(minuteInput)) *
    60 *
    1000;
  return millis;
}

export const TargetHoursModalContent = ({
  closeModal,
  openModal,
  setTargetTimeInput,
}: {
  closeModal: () => void;
  openModal: boolean;

  setTargetTimeInput: ({ timeInMillis }: { timeInMillis: number }) => void;
}) => {
  const {
    handleSubmit: handleSubmitTime,
    register,
    formState: { errors },
    reset,
  } = useForm<TargetTimeSchemaType>({
    defaultValues: {
      dayInput: 0,
      hourInput: 0,
      minuteInput: 0,
    },
  });

  const onSubmitTime: SubmitHandler<TargetTimeSchemaType> = (values) => {
    if (!values) {
      return;
    }
    setTargetTimeInput({
      timeInMillis: formatTimeInMillis(values),
    });
    reset();
    closeModal();
  };
  const translate = useTranslate();

  return (
    <Dialog onClose={closeModal} open={openModal} fullWidth>
      <DialogTitle>{translate("batch_navigator.set_time")}</DialogTitle>
      <DialogContent sx={{ padding: "0" }}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          gap={1}
          sx={{ display: "flex", flexWrap: "nowrap", padding: "1rem" }}
        >
          <TextField
            fullWidth
            {...register("dayInput", {
              min: {
                value: 0,
                message: translate("required"),
              },
            })}
            label={translate("durations.day_other")}
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            error={!!errors.dayInput}
          />
          <TextField
            {...register("hourInput", {
              min: {
                value: 0,
                message: translate("required"),
              },
            })}
            fullWidth
            label={translate("durations.hour_other")}
            type="number"
            InputProps={{ inputProps: { min: 0, max: 24 } }}
            error={!!errors.hourInput}
          />
          <TextField
            {...register("minuteInput", {
              min: {
                value: 0,
                message: translate("required"),
              },
            })}
            fullWidth
            label={translate("durations.minute_other")}
            type="number"
            InputProps={{ inputProps: { min: 0, max: 60 } }}
            error={!!errors.minuteInput}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>{translate("actions.cancel")}</Button>
        <Button onClick={handleSubmitTime(onSubmitTime)} type="submit">
          {translate("actions.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
