import { useState } from "react";
import { Stack } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { Card } from "@/components/Layout/Card";
import { useGetAnomalies } from "../api/getAnomaliesData";
import { AnomaliesOverview } from "../components/AnomaliesOverview";
import { AnomaliesXRange } from "../components/AnomaliesXRange";
import { AnomaliesTable } from "../components/table_anomalies";

export function AnomaliesPage({ machineName }: { machineName: string }) {
  const translate = useTranslate();
  const [dateRange, setDateRange] = useState<{
    min: number;
    max: number;
  } | null>(null);
  const { data, isLoading, error } = useGetAnomalies({ machineName });

  const handleSetExtremes = ({ min, max }: { min: number; max: number }) => {
    setDateRange({ min, max });
  };

  if (isLoading) {
    return (
      <>
        <AnalyticContainerSkeleton sx={{ minHeight: 300 }} />
        <AnalyticContainerSkeleton />
      </>
    );
  }

  if (error) {
    return <Card>{translate("user_feedback.an_error_occurred")}</Card>;
  }

  if (!data) {
    return <Card>{translate("user_feedback.no_data")}</Card>;
  }

  return (
    <Stack gap={1}>
      <AnomaliesOverview data={data} />
      <AnomaliesXRange data={data.data} onSetExtremes={handleSetExtremes} />
      <AnomaliesTable data={data.data} extremes={dateRange} />
    </Stack>
  );
}
