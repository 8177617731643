import { NivoSolidGauge } from "@/components/highcharts/gauge/NivoSolidGauge";
import { GaugeCircleLayout } from "@/features/MachineDetail/components/CircleLayout";
import { useMediaQuery } from "@mui/material";
import { RadialBarCustomLayer } from "@nivo/radial-bar";

const PercentageCustomView: RadialBarCustomLayer = ({ center, bars }) => {
  const tip = "tip" in bars[0].data ? (bars[0].data.tip as string) : "";
  const value = bars[0].value;
  const smallScreen = useMediaQuery("(max-width:1180px) and (min-width:900px)");
  return (
    <>
      <text
        x="50%"
        y="40%"
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          fill: "white",
          fontSize: tip === "OEE" ? "14pt" : smallScreen ? "7pt" : "10pt",
        }}
      >
        {tip}
      </text>
      <text
        y={center[1] + 20}
        x="50%"
        textAnchor="middle"
        dominantBaseline="middle"
        style={{ fill: "white", fontSize: smallScreen ? "9pt" : "12pt" }}
      >
        {value === 100 ? value : value.toFixed(2)}%
      </text>
    </>
  );
};

export const ConfigurableGaugeLayout = ({
  title,
  value = 0,
  min,
  max,
  color,
  dimension = "large",
  backgroundColor,
}: {
  title: string;
  value: number;
  min: number;
  max: number;
  type: "percentage" | "duration" | "count";
  dimension?: "small" | "large" | "extrasmall" | "extralarge";
  color: string;
  backgroundColor?: string;
}) => {
  const _dimension =
    dimension === "large"
      ? "12vmax"
      : dimension === "small"
      ? "8vmax"
      : dimension === "extralarge"
      ? "15vmax"
      : "9vmax";

  return (
    <GaugeCircleLayout
      color={backgroundColor}
      sx={{
        position: "relative",
        padding: 1,
        margin: "auto",
        width: _dimension,
        height: _dimension,
        aspectRatio: "1/1",
        textAlign: "center",
      }}
    >
      <NivoSolidGauge
        min={min}
        max={max}
        title={title}
        color={color}
        value={value}
        CustomLayer={PercentageCustomView}
      />
    </GaugeCircleLayout>
  );
};
