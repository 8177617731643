import { Run } from "../../../types";
import { useGetShuttlesAndSegmentsHeatMap } from "../../../api/Summary/useGetShuttlesAndSegmentsHeatMap";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { useTranslate } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";
import { HeatmapChart } from "./HeatmapChart";
import { parseHeatmapData } from "../../../utils/parseHeatmapData";
import { EVALUATION_COLORS } from "../../../context/acopos-context";

export const ModalRunsHeatmaps = ({
  selectedRuns,
}: {
  selectedRuns: Run[];
}) => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetShuttlesAndSegmentsHeatMap({
    runs: selectedRuns,
  });

  if (isLoading) return <SkeletonCard height={180} />;
  if (error)
    return (
      <Card sx={{ height: 180 }}>
        {translate("user_feedback.an_error_occurred")}
      </Card>
    );
  if (!data)
    return (
      <Card sx={{ height: 180 }}>{translate("user_feedback.no_data")}</Card>
    );

  return (
    <Card>
      {Object.keys(data.data).map((run) => {
        const { seriesData, xCategories, yCategories } = parseHeatmapData({
          data: data.data[run],
        });
        const evaluations = data.evaluations[run];
        const xCategoriesWithEvaluations = xCategories.map(
          (x) =>
            `<p><span style='color:${
              EVALUATION_COLORS[evaluations[x]].selected
            }'>● </span>${x}</p>`,
        );
        const yCategoriesWithEvaluations = yCategories.map(
          (y) =>
            `<p><span style='color:${
              EVALUATION_COLORS[evaluations[y]].selected
            }'>● </span>${y}</p>`,
        );
        return (
          <HeatmapChart
            key={run}
            title={run}
            seriesData={seriesData}
            xCategories={xCategoriesWithEvaluations}
            yCategories={yCategoriesWithEvaluations}
          />
        );
      })}
    </Card>
  );
};
