import { SeriesLineOptions } from "highcharts";
import { DateTime } from "luxon";
import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { useTranslate } from "@/i18n/config";

export const DailyMeanCurrentChartComparison = ({
  title,
  seriesData,
  comparisonSeriesData,
  dates,
  comparisonDates,
}: {
  title: string;
  seriesData: SeriesLineOptions["data"];
  comparisonSeriesData: SeriesLineOptions["data"];
  dates?: [DateTime<true> | null, DateTime<true> | null];
  comparisonDates?: [DateTime<true> | null, DateTime<true> | null];
}) => {
  const translate = useTranslate();

  return (
    <LineChart.Custom
      title={title}
      zoomType="xy"
      yAxis={[
        {
          uom: "",
          series: [
            {
              type: "line",
              name: translate("durations.current_year"),
              color: "#2AC3FF",
              data: seriesData,
            },
            {
              type: "line",
              name: translate("durations.last_year"),
              color: "#D354A6",
              data: comparisonSeriesData,
              xAxis: 1,
            },
          ],
          options: { title: { text: "ampere", style: { color: "#fff" } } },
        },
      ]}
      xAxes={[
        {
          type: "datetime",
          min: dates && dates[0] ? dates[0].toMillis() : undefined,
          max: dates && dates[1] ? dates[1].toMillis() : undefined,
          labels: {
            format: "{value:%e. %b %Y}",
            style: { color: "white" },
          },
        },
        {
          type: "datetime",
          min:
            comparisonDates && comparisonDates[0]
              ? comparisonDates[0].toMillis()
              : undefined,
          max:
            comparisonDates && comparisonDates[1]
              ? comparisonDates[1].toMillis()
              : undefined,
          labels: {
            format: "{value:%e. %b %Y}",
            style: { color: "white" },
          },
          opposite: true,
        },
      ]}
      height={400}
      legend={{ enabled: true }}
    />
  );
};
