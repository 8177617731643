import { useState } from "react";
import { Drawer, IconButton, Box, Tooltip } from "@mui/material";
import { ChevronRight, ChevronLeft } from "@mui/icons-material";
import { Loader } from "@/components/Layout/Loader";
import { useGetUserConfiguration } from "./api/useGetUserConfiguration";
import { ChatPanel } from "./components/ChatPanel";
import { LeftDrawer } from "./components/LeftDrawer";
import { RightDrawer } from "./components/RightDrawer";
import { InfoDialog } from "./components/InfoDialog";
import { NoVersionsMessage } from "./components/ChatPanel/NoVersionsMessage";
import { useCheckIfMessageBanner } from "@/hooks/useCheckIfInformationBanner";
import { useTranslate } from "@/i18n/config";

export const LEFT_DRAWER_WIDTH = 270;
export const RIGHT_DRAWER_WIDTH = 330;
export const ICON_WIDTH = 40;

export const Intellecta = () => {
  const [userInput, setUserInput] = useState("");
  const [isShownAlert, setIsShownAlert] = useState(true);
  const [isLeftDrawerOpen, setIsLeftDrawerOpen] = useState(true);
  const [isRightDrawerOpen, setIsRightDrawerOpen] = useState(true);
  const { isLoading, data } = useGetUserConfiguration();
  const { showBanner } = useCheckIfMessageBanner();
  const translate = useTranslate();

  if (isLoading || !data) {
    return <Loader isLoading />;
  }

  const toggleLeftDrawer = () => {
    setIsLeftDrawerOpen(!isLeftDrawerOpen);
  };

  const toggleRightDrawer = () => {
    setIsRightDrawerOpen(!isRightDrawerOpen);
  };

  return (
    <>
      {/* MODALE DISCLAIMER */}
      <InfoDialog isOpen={isShownAlert} close={() => setIsShownAlert(false)} />
      {/* APERTURA LEFT DRAWER */}
      <Tooltip
        title={translate("intellecta.show_hide_sidebar")}
        enterDelay={2000}
        leaveDelay={200}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleLeftDrawer}
          sx={{
            position: "absolute",
            top: showBanner ? 44 : 12,
            left: 12,
            zIndex: 1,
            transform: isLeftDrawerOpen
              ? `translateX(${LEFT_DRAWER_WIDTH}px)`
              : "translateX(0)",
            transition: "transform 0.16s ease-in-out",
          }}
        >
          {isLeftDrawerOpen ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </Tooltip>
      {/* APERTURA RIGHT DRAWER */}
      <Tooltip
        title={translate("intellecta.show_hide_sidebar")}
        enterDelay={2000}
        leaveDelay={200}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleRightDrawer}
          sx={{
            position: "absolute",
            top: showBanner ? 44 : 12,
            right: 12,
            zIndex: 1,
            transform: isRightDrawerOpen
              ? `translateX(-${RIGHT_DRAWER_WIDTH}px)`
              : "translateX(0)",
            transition: "transform 0.16s ease-in-out",
          }}
        >
          {isRightDrawerOpen ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </Tooltip>

      {/* ROOT */}
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
          transition: (theme) =>
            theme.transitions.create(["width"], {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
        }}
      >
        {/* LEFT DRAWER */}
        <Drawer
          variant="persistent"
          open={isLeftDrawerOpen}
          sx={{
            width: LEFT_DRAWER_WIDTH,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: LEFT_DRAWER_WIDTH,
              boxSizing: "border-box",
              marginLeft: "64px",
            },
          }}
        >
          <LeftDrawer onPromptClick={(prompt) => setUserInput(prompt)} />
        </Drawer>

        {/* CHAT */}
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            maxWidth: isLeftDrawerOpen || isRightDrawerOpen ? "50vw" : "60vw",
            width: "100%",
            mx:
              isLeftDrawerOpen || isRightDrawerOpen
                ? `${ICON_WIDTH + 24}px`
                : "auto",
            height: "100%",
            transition: (theme) =>
              theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
          }}
        >
          {data.versions.length > 0 ? (
            <ChatPanel
              userInput={userInput}
              setUserInput={setUserInput}
              isLeftDrawerOpen={isLeftDrawerOpen}
              isRightDrawerOpen={isRightDrawerOpen}
            />
          ) : (
            <NoVersionsMessage />
          )}
        </Box>

        {/* RIGHT DRAWER */}
        <Drawer
          variant="persistent"
          anchor="right"
          open={isRightDrawerOpen}
          sx={{
            width: RIGHT_DRAWER_WIDTH,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: RIGHT_DRAWER_WIDTH,
              boxSizing: "border-box",
            },
          }}
        >
          <RightDrawer onUsePrompt={(prompt) => setUserInput(prompt)} />
        </Drawer>
      </Box>
    </>
  );
};
