import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import {
  ImputationCategoriesResponse,
  imputationCategoriesResult,
  ImputationCategoriesResult,
} from "../types";

type Payload = {
  machineName: string;
};

export const useGetImputationCategories = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();

  return useQuery<ImputationCategoriesResult>({
    queryKey: ["imputation-categories-data", machine!.machine],
    queryFn: async () => {
      const response = await FetchClient<Payload, ImputationCategoriesResponse>(
        {
          appKey,
          payload: {
            machineName: machine!.machine,
          },
          url: "dig.c.stopImputation_thing/Services/getImputationCategories",
        },
      );

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = imputationCategoriesResult.parse(response);

      return validatedResponse;
    },
  });
};
