import { Card } from "@/components/Layout/Card";
import { formatDuration } from "@/features/A4GateMonitoring/utils/convert-duration";
import {
  Stack,
  Typography,
  Divider,
  styled,
  TextField,
  Button,
  Box,
} from "@mui/material";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useFormatTimestamp } from "@/utils/TimeHelpers";
import { useAcceptanceTestContext } from "../context/acceptanceTest-context";
import { useSetManualCorrection } from "../api/useSetManualCorrection";
import { useTranslate } from "@/i18n/config";

type Info = {
  setpointSpeed: number;
  goodProducts: number;
  startTime: number;
  duration: number;
};

type MachineCustomerKeys = {
  downtime: number;
  speedLosses: number;
  rejects: number;
  manualCorrection: number;
};

export const LeftSide = ({
  info,
  machine,
  customer,
}: {
  info: Info;
  machine: MachineCustomerKeys;
  customer: MachineCustomerKeys;
}) => {
  const translate = useTranslate();
  const { formatTimestamp } = useFormatTimestamp();
  return (
    <Stack gap={1}>
      <Card>
        <Stack direction="column" gap={0.4}>
          <Typography>{translate("info")}</Typography>
          <Divider />
          <RowItem
            property={translate("acceptance_test.setpoint_speed")}
            value={info.setpointSpeed.toString() + " prod/min"}
          />
          <RowItem
            property={translate("acceptance_test.good_product", {
              count: info.goodProducts,
            })}
            value={info.goodProducts}
          />
          <RowItem
            property={translate("acceptance_test.start_time")}
            value={formatTimestamp({
              timestamp: info.startTime - 7200000,
            })}
          />
          <RowItem
            property={translate("duration")}
            value={formatDuration(info.duration)}
          />
        </Stack>
      </Card>
      <Entity customer={customer} machine={machine} />
    </Stack>
  );
};

const Entity = ({
  machine,
  customer,
}: {
  machine: MachineCustomerKeys;
  customer: MachineCustomerKeys;
}) => {
  const { mutate: setManualCorrection } = useSetManualCorrection();
  const { viewMode } = useAcceptanceTestContext();
  const [corrections, setCorrections] = useState<{
    machine: string;
    customer: string;
  }>({
    machine: machine.manualCorrection.toString(),
    customer: customer.manualCorrection.toString(),
  });
  const [isNewCorrection, setIsNewCorrection] = useState(true);

  const handleValueChange = ({
    newValue,
    prevValue,
    dataKey,
  }: {
    newValue: string;
    prevValue: string;
    dataKey: string;
  }) => {
    // per gestire la parte dell'icona che si attiva al cambio del valore
    setIsNewCorrection(true);
    if (dataKey === "machine" && newValue !== prevValue) {
      setIsNewCorrection(false);
    } else if (dataKey === "customer" && newValue !== prevValue) {
      setIsNewCorrection(false);
    }
    setCorrections((prevState) => ({
      ...prevState,
      [dataKey]: newValue,
    }));
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setManualCorrection({
      related: corrections.machine,
      unrelated: corrections.customer,
    });
    setIsNewCorrection(true);
  };

  const translate = useTranslate();

  return (
    <Card>
      <Box component="form" onSubmit={handleSubmit}>
        <Stack direction={"column"} gap={3}>
          <Stack direction={"column"} gap={0.4}>
            <Typography>{`${translate("machine")} (${translate(
              "acceptance_test.related",
            )})`}</Typography>
            <Divider />
            <RowItem
              property={translate("machine.downtime")}
              value={formatDuration(machine.downtime * 1000)}
            />
            <RowItem
              property={translate("losses.speed_losses")}
              value={machine.speedLosses.toFixed(2) + " prod/min"}
            />
            <RowItem
              property={translate("reject", { count: machine.rejects })}
              value={machine.rejects + " prod"}
            />
            <InputRowItem
              property={translate("acceptance_test.manual_correction", {
                count: machine.manualCorrection,
              })}
              input={machine.manualCorrection.toString()}
              onValueChange={handleValueChange}
              dataKey="machine"
            />
          </Stack>
          <Stack direction={"column"} gap={0.4}>
            <Typography>{`${translate("acceptance_test.customer")} (${translate(
              "acceptance_test.unrelated",
            )})`}</Typography>
            <Divider />
            <RowItem
              property={translate("machine.downtime")}
              value={formatDuration(customer.downtime * 1000)}
            />
            <RowItem
              property={translate("losses.speed_losses")}
              value={customer.speedLosses.toFixed(2) + " prod/min"}
            />
            <RowItem
              property={translate("reject", { count: customer.rejects })}
              value={customer.rejects + " prod"}
            />
            <InputRowItem
              property={translate("acceptance_test.manual_correction", {
                count: customer.manualCorrection,
              })}
              input={customer.manualCorrection.toString()}
              onValueChange={handleValueChange}
              dataKey="customer"
            />
          </Stack>
          {viewMode === "Live" && (
            <Button disabled={isNewCorrection} type="submit" variant="outlined">
              {translate("acceptance_test.set_correction")}
            </Button>
          )}
        </Stack>
      </Box>
    </Card>
  );
};

const CustomTypography = styled(
  Typography,
  {},
)(() => ({
  fontWeight: "lighter",
  color: "#bbbbbb",
}));

const RowItem = ({
  property,
  value,
}: {
  property: string;
  value: string | number;
}) => {
  return (
    <Stack
      direction={"row"}
      justifyContent="space-between"
      spacing={1}
      alignItems="flex-end"
    >
      <CustomTypography>{property}</CustomTypography>
      <CustomTypography textAlign="end">{value}</CustomTypography>
    </Stack>
  );
};
const InputRowItem = ({
  property,
  input,
  onValueChange,
  dataKey,
}: {
  property: string;
  input: string;
  dataKey: string;
  onValueChange: ({
    newValue,
    prevValue,
    dataKey,
  }: {
    newValue: string;
    prevValue: string;
    dataKey: string;
  }) => void;
}) => {
  const { viewMode } = useAcceptanceTestContext();

  const [value, setValue] = useState<string>(input);
  const prevValue = useRef<string>(input);

  useEffect(() => {
    prevValue.current = value;
  }, [value]);
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing={1}
      alignItems="flex-end"
    >
      <CustomTypography>{property}</CustomTypography>
      <TextField
        disabled={viewMode === "Live" ? false : true}
        sx={{ width: "40%" }}
        id="standard-number"
        onChange={(e) => {
          setValue(e.target.value);
          onValueChange({
            newValue: e.target.value,
            prevValue: prevValue.current,
            dataKey,
          });
          // ! se l'utente non conferma la correzione con il submit e lascia il numero
          setTimeout(() => {
            setValue("0");
          }, 180000);
        }}
        InputProps={{ inputProps: { min: 0, sx: { padding: 0.5 } } }}
        type="number"
        variant="filled"
        value={value}
      />
    </Stack>
  );
};
