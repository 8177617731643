import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useCustomTimeRange } from "../store/useCustomTimeRange";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type BellCurveResponse = ThingworxError | BellCurveResult;

const NumberArrayRecord = z.record(z.array(z.number()));

const BellCurveData = z.object({
  bellCurveData: z.array(
    z.object({
      name: z.string(),
      data: NumberArrayRecord,
    }),
  ),
  benchmark: z
    .object({
      setpoint: z.number().optional(),
      minWarn: z.number().optional(),
      minErr: z.number().optional(),
      maxWarn: z.number().optional(),
      maxErr: z.number().optional(),
    })
    .optional(),
});

export type BellCurveData = z.infer<typeof BellCurveData>;

const ChartsData = z.object({
  tareData: BellCurveData,
  netData: BellCurveData,
  modalities: z.array(z.string()),
  doserData: z.record(BellCurveData),
});

type ChartsData = z.infer<typeof ChartsData>;

const BellCurveResult = z.object({
  response: z.literal(true),
  data: ChartsData,
});
export type BellCurveResult = z.infer<typeof BellCurveResult>;

interface Payload {
  machineName: string;
  dateStart: DateTime;
  dateEnd: DateTime;
}

export const useGetBellCurveData = () => {
  const { machine } = useMachineContext();

  const { dateStart, dateEnd } = useCustomTimeRange();

  const { appKey } = useFirebaseContext();

  return useQuery<ChartsData>({
    queryKey: ["gauss-weights", machine?.machine, dateStart, dateEnd],
    queryFn: async () => {
      const response = await FetchClient<Payload, BellCurveResponse>({
        appKey,
        payload: {
          dateStart,
          dateEnd,
          machineName: machine?.machine || "",
        },
        url: "dig.c.fillerAdvancedAnalytics_thing/Services/getBellCurveData",
      });

      if (!response.response) throw new Error(response.errorString);

      const validatedResponse = BellCurveResult.parse(response);

      return validatedResponse.data;
    },
  });
};
