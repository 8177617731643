import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { QueryBuilder } from "@/utils/query-builder";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { FetchClient } from "@/services/ApiClient";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type ChangeOverSummaryResponse = ThingworxError | ChangeOverHomingResult;

const HomingData = z.object({
  timestampEnd: z.number(),
  Timestamp: z.number(),
  durationms: z.number(),
});

export type HomingData = z.infer<typeof HomingData>;

const ChangeOverHomingResult = z.object({
  response: z.literal(true),
  Result: z.array(HomingData),
});
export type ChangeOverHomingResult = z.infer<typeof ChangeOverHomingResult>;

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
  dateEnd?: DateTime;
  dateStart?: DateTime;
};

/**
 * returns data for the big table in the lower section of the original page
 */
export const useGetHomingStreamSelector = () => {
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();

  const { timeSelection, dates } = useTimeSelection();

  const query = QueryBuilder.buildWithCondition({
    baseQuery: ["change-over-homing-data", machine?.machine, timeSelection],
    condition: timeSelection === "Custom",
    query: [dates.dateStart, dates.dateEnd],
  });

  const payload = {
    machineName: machine?.machine || "",
    timeSelection,
  };

  return useQuery<HomingData[]>({
    queryKey: query,
    queryFn: async () => {
      const response = await FetchClient<Payload, ChangeOverSummaryResponse>({
        appKey,
        payload:
          timeSelection === "Custom"
            ? { ...payload, dateStart: dates.dateStart, dateEnd: dates.dateEnd }
            : payload,
        url: "dig.c.changeOver_thing/Services/getHomingStreamSelector",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = ChangeOverHomingResult.parse(response);

      return validatedResponse.Result;
    },
  });
};
