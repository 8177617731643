import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";

type UpdateBenchmarkPayload = {
  machineName: string;
  bmValue: number;
  stateNumber: number;
};

type UpdateBenchmarkResponse = ThingworxError | UpdateBenchmarkResult;

export interface UpdateBenchmarkResult {
  processingStateStatusDictionary: {
    [key: string]: ProcessingStateStatusDictionary;
  };
  response: true;
}

export interface ProcessingStateStatusDictionary {
  fail: number[];
  bm: number;
  ok: number[];
  hasEndOk: boolean;
}

export const useSetBenchmark = () => {
  const queryClient = useQueryClient();
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const machineName = machine?.machine || "";

  return useMutation({
    mutationFn: async ({
      newBenchmark,
      state,
    }: {
      newBenchmark: number;
      state: number;
    }) => {
      const response = await FetchClient<
        UpdateBenchmarkPayload,
        UpdateBenchmarkResponse
      >({
        appKey,
        payload: {
          machineName,
          bmValue: newBenchmark,
          stateNumber: state,
        },
        url: "dig.c.processingStatesStatus_thing/Services/updateBm",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return response;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["processingStatus", machineName],
      });
    },
  });
};
