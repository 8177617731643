import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useTranslate } from "@/i18n/config";

export const InfoDialogButton = () => {
  const { open, isOpen, close } = useDisclosure();

  return (
    <>
      <IconButton onClick={open}>
        <InfoOutlined />
      </IconButton>
      <InfoDialog isOpen={isOpen} close={close} />
    </>
  );
};

export const InfoDialog = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const translate = useTranslate();

  return (
    <Dialog fullWidth open={isOpen}>
      <DialogTitle display="flex" alignItems="center">
        {translate("disclaimer")}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          {translate("intellecta.disclaimer")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={close}>
          {translate("actions.got_it")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
