import { useFirebaseContext } from "@/context/firebase-context";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { ThingworxError } from "src/types/error";
import { FetchClient } from "@/services/ApiClient";

type Response = ThingworxError | { response: true };

type Payload = {
  platformLvl: number;
  id: number;
  stateOfTicket: 0 | 1 | 2;
  account: string;
};

export const useUpdateAlert = () => {
  const { appKey, user, platformLvl } = useFirebaseContext();
  const account = user?.email as string;
  const client = useQueryClient();

  return useMutation({
    mutationFn: async ({
      id,
      stateOfTicket,
    }: {
      id: number;
      stateOfTicket: 0 | 1 | 2;
    }) => {
      const payload: Payload = {
        account,
        platformLvl,
        id,
        stateOfTicket,
      };
      return await FetchClient<Payload, Response>({
        appKey,
        payload,
        url: "dig.c.event_thing/Services/updateAlertStatus",
      });
    },
    onSuccess(data) {
      if (data.response) {
        toast.success("Event upgraded successfully");
        client.invalidateQueries({
          queryKey: ["list-of-alerts", account, platformLvl],
        });
      }
    },
  });
};
