import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { Button, Tabs, Tab } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import { StateInfo } from "../api/useGetConfigurableOeeData";
import { useEditStore } from "../store/edit-management-store";
import { Views } from "../types";
import { StatesDetailContainer } from "./OverviewStateDetail";

export const ViewStatesContainer = ({
  defaultStates,
}: {
  defaultStates: StateInfo[];
}) => {
  const [view, setView] = useState<Views>("all");
  const { isEditable, toggle } = useEditStore((state) => state);

  const viewHandler = (_: React.SyntheticEvent, view: Views) => setView(view);
  const translate = useTranslate();

  return (
    <Card>
      <Grid2 container justifyContent="space-between" alignItems={"center"}>
        <Grid2>
          <Button
            sx={{
              marginLeft: "3.5rem",
              backgroundColor: isEditable ? "red" : "",
            }}
            variant="outlined"
            onClick={() => {
              toggle();
            }}
          >
            {translate("configurable_oee.edit_mode")}
          </Button>
        </Grid2>
        <Tabs
          value={view}
          onChange={viewHandler}
          textColor="primary"
          indicatorColor="primary"
          aria-label="secondary tabs example"
        >
          <Tab value="all" label={translate("configurable_oee.all")} />
          <Tab
            value="configured"
            label={translate("configurable_oee.configured")}
          />
          <Tab
            value="notConfigured"
            label={translate("configurable_oee.not_configured")}
          />
        </Tabs>
      </Grid2>
      <StatesDetailContainer view={view} statesList={defaultStates} />
    </Card>
  );
};
