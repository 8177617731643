import { useFirebaseContext } from "@/context/firebase-context";
import { useTranslate } from "@/i18n/config";
import { FetchClient } from "@/services/ApiClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { ThingworxError } from "src/types/error";

type Payload = {
  chart_id: number;
  account: string;
};

export const useDeleteChart = () => {
  const translate = useTranslate();
  const { appKey, user } = useFirebaseContext();

  const client = useQueryClient();

  const email = user?.email as string;
  return useMutation({
    mutationFn: async (chart_id: number) => {
      const response = await FetchClient<
        Payload,
        ThingworxError | { response: true }
      >({
        appKey,
        url: "dig.c.customChartsDev_thing/services/deleteChart",
        payload: {
          chart_id,
          account: email,
        },
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }
      return response;
    },
    onSuccess() {
      toast.success(translate("custom_charts.chart_deleted_successfully"));
      client.invalidateQueries(["user-chart", email]);
    },
    onError() {
      toast.error(translate("custom_charts.failed_to_delete_chart"));
    },
  });
};
