import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { Views } from "../../types";
import { CardsContainer, NoStates } from "../Layout/CardsContainer";
import { HistoricStateInfoLayout } from "../StateInfo";

export const HistoricViewStatesContainer = ({
  historicStatesList,
}: {
  historicStatesList: {
    stateName: string;
    duration: number;
    isClientRelevance: boolean;
    isEfficiencyRelevance: boolean;
  }[];
}) => {
  const [statesList, setStatesList] = useState<
    {
      stateName: string;
      duration: number;
      isClientRelevance: boolean;
      isEfficiencyRelevance: boolean;
    }[]
  >(historicStatesList);
  const [view, setView] = useState<Views>("all");

  const viewHandler = (_: React.SyntheticEvent, view: Views) => {
    const mappedStatesList =
      view === "configured"
        ? historicStatesList.filter(
            (s) => s.isClientRelevance != s.isEfficiencyRelevance,
          )
        : view === "notConfigured"
        ? historicStatesList.filter(
            (s) => s.isClientRelevance === s.isEfficiencyRelevance,
          )
        : historicStatesList;
    setStatesList(mappedStatesList);
    setView(view);
  };
  const translate = useTranslate();

  return (
    <Card sx={{ display: "flex", flexDirection: "column" }}>
      <Tabs
        sx={{ display: "block", alignSelf: "end" }}
        value={view}
        onChange={viewHandler}
        textColor="primary"
        indicatorColor="primary"
        aria-label="secondary tabs example"
      >
        <Tab value="all" label={translate("configurable_oee.all")} />
        <Tab
          value="configured"
          label={translate("configurable_oee.configured")}
        />
        <Tab
          value="notConfigured"
          label={translate("configurable_oee.not_configured")}
        />
      </Tabs>
      <CardsContainer>
        {statesList.length === 0 && (
          <NoStates
            content={translate("configurable_oee.no_state_configured")}
          />
        )}
        {statesList.map((state) => (
          <HistoricStateInfoLayout
            key={state.stateName}
            stateName={state.stateName}
            duration={state.duration}
            isClientRelevance={state.isClientRelevance}
            isEfficiencyRelevance={state.isEfficiencyRelevance}
          />
        ))}
      </CardsContainer>
    </Card>
  );
};
