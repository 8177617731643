import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { itemHealthStatus } from "../../types";

export type GetShuttlesAndSegmentsHealthResponse =
  | ThingworxError
  | GetShuttlesAndSegmentsHealthResult;

type Payload = {
  machineName: string;
};

const segmentsShuttlesHealthStatusData = z.object({
  segments: z.array(itemHealthStatus),
  shuttles: z.array(itemHealthStatus),
});

const getShuttlesAndSegmentsHealthResult = z.object({
  response: z.literal(true),
  data: segmentsShuttlesHealthStatusData,
});

export type GetShuttlesAndSegmentsHealthResult = z.infer<
  typeof getShuttlesAndSegmentsHealthResult
>;

export const useGetShuttlesAndSegmentsHealth = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();

  return useQuery<GetShuttlesAndSegmentsHealthResult>({
    queryKey: ["shuttles-and-segments-health-status", machine!.machine],
    queryFn: async () => {
      const response = await FetchClient<
        Payload,
        GetShuttlesAndSegmentsHealthResponse
      >({
        appKey,
        payload: {
          machineName: machine!.machine,
        },
        // url: "dig.c.acopostrak_thing/Services/getShuttlesAndSegmentsHealth",
        url: "dig.c.acoposTrack_thing/Services/getShuttlesAndSegmentsHealth",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse =
        getShuttlesAndSegmentsHealthResult.parse(response);

      return validatedResponse;
    },
  });
};
