import { useState } from "react";
import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DeleteOutlined,
  DriveFileRenameOutlineOutlined,
  MoreVert,
} from "@mui/icons-material";
import { useTranslate } from "@/i18n/config";
import { useDisclosure } from "@/hooks/useDisclosure";
import { Prompt } from "../../types";
import { EditPromptModal } from "./EditPromptModal";
import { DeletePromptModal } from "./DeletePromptModal";
import { intellectaColors } from "../../utilities/colors";

export const PromptButton = ({
  prompt,
  onPromptClick,
}: {
  prompt: Prompt;
  onPromptClick: (prompt: Prompt) => void;
}) => {
  const translate = useTranslate();
  const {
    open: openEditPromptModal,
    isOpen: isOpenEditPromptModal,
    close: closeEditPromptModal,
  } = useDisclosure();
  const {
    open: openDeletePromptModal,
    isOpen: isOpenDeletePromptModal,
    close: closeDeletePromptModal,
  } = useDisclosure();
  const { close: hide, open: show, isOpen: isHover } = useDisclosure();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);

    if (!isHover) {
      hide();
    }
  };

  return (
    <>
      <Tooltip title={prompt.description}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          onMouseEnter={show}
          onMouseLeave={hide}
          width="100%"
          // border="1px solid #ffffff80"
          sx={{
            backgroundColor: intellectaColors.powerWordButtonBackground,
            transition: "background-color 0.3s",
            borderRadius: 1,
            cursor: "pointer",
            padding: ".375rem 1rem",
            // padding: ".25rem .5rem",
            "&:hover": {
              backgroundColor: "#ffffff15",
            },
          }}
        >
          <Typography
            variant="subtitle2"
            // sx={{ justifyContent: "flex-start" }}
            onClick={() => onPromptClick(prompt)}
          >
            {prompt.label}
          </Typography>
          <IconButton
            sx={{
              visibility: isHover ? "visible" : "hidden",
            }}
            size="small"
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClickMenu}
          >
            <MoreVert />
          </IconButton>
        </Box>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="historic-chat-menu"
        open={open}
        onClose={handleCloseMenu}
        transformOrigin={{ horizontal: "left", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "left", vertical: "center" }}
      >
        <MenuItem
          onClick={() => {
            handleCloseMenu();
            openEditPromptModal();
          }}
        >
          <ListItemIcon>
            <DriveFileRenameOutlineOutlined fontSize="small" />
          </ListItemIcon>
          {translate("actions.edit")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseMenu();
            openDeletePromptModal();
          }}
        >
          <ListItemIcon>
            <DeleteOutlined fontSize="small" />
          </ListItemIcon>
          {translate("actions.delete")}
        </MenuItem>
      </Menu>
      {isOpenEditPromptModal && (
        <EditPromptModal
          isOpen={isOpenEditPromptModal}
          close={closeEditPromptModal}
          prompt={prompt}
        />
      )}
      {isOpenDeletePromptModal && (
        <DeletePromptModal
          isOpen={isOpenDeletePromptModal}
          close={closeDeletePromptModal}
          prompt={prompt}
        />
      )}
    </>
  );
};
