/* eslint-disable import/default */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import HighchartsReactOfficial from "highcharts-react-official";
import Highcharts, { PointOptionsObject } from "highcharts";
import addHeatmap from "highcharts/modules/heatmap";
import addNoDataToDisplay from "highcharts/modules/no-data-to-display";
import { Box, Fade } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { Evaluation } from "../../../types";

addHeatmap(Highcharts);
addNoDataToDisplay(Highcharts);

const getPointCategoryName = (
  point: Highcharts.Point,
  dimension: "x" | "y",
) => {
  const series = point.series,
    isY = dimension === "y",
    axis = series[isY ? "yAxis" : "xAxis"];
  return axis.categories[point[isY ? "y" : "x"] || 0];
};

interface SummaryHeatmapProps {
  title: string;
  xCategories: string[];
  yCategories: string[];
  seriesData: PointOptionsObject[];
}

export const HeatmapChart = ({
  title,
  xCategories,
  yCategories,
  seriesData,
}: SummaryHeatmapProps) => {
  const translate = useTranslate();
  const n = yCategories.length;
  const chartHeight = n < 3 ? 150 : n < 5 ? 200 : undefined;
  const yAxisLabelsStep = n > 40 ? 3 : n > 20 ? 2 : 1;

  return (
    <Fade in easing="ease-in">
      <Box>
        <HighchartsReactOfficial
          highcharts={Highcharts}
          options={{
            chart: {
              type: "heatmap",
              plotBorderWidth: 0,
              height: chartHeight,
              backgroundColor: "transparent",
              style: {
                fontFamily: "Montserrat, Helvetica Neue,Arial, sans-serif",
                color: "white",
              },
            },
            title: {
              text: title,
              align: "left",
              style: {
                color: "white",
              },
            },
            lang: {
              noData: translate("user_feedback.no_data_to_display"),
            },
            xAxis: {
              categories: xCategories,
              gridLineColor: "transparent",
              lineColor: "transparent",
              labels: {
                style: {
                  color: "white",
                },
              },
            },
            yAxis: {
              categories: yCategories,
              gridLineColor: "transparent",
              lineColor: "transparent",
              title: null,
              reversed: true,
              labels: {
                step: yAxisLabelsStep,
                style: {
                  color: "white",
                  //? non capisco perché non funziona
                  width: 200,
                },
              },
            },
            legend: {
              enabled: false,
            },
            tooltip: {
              formatter: function () {
                // @ts-ignore

                const tooltipContextObject: Highcharts.TooltipFormatterContextObject =
                  this;
                const point: Highcharts.Point = tooltipContextObject.point;
                const xCategory = getPointCategoryName(point, "x");
                const yCategory = getPointCategoryName(point, "y");
                // @ts-ignore
                const evaluation = point.custom.evaluation as Evaluation;
                // @ts-ignore
                return `${xCategory}<br>
            ${yCategory}<br>
            ${translate("evaluation")}: <b>${translate(
              `evaluation.${evaluation}`,
            )}</b>`;
              },
            },
            series: [
              {
                data: seriesData,
                turboThreshold: 0,
              },
            ],
            credits: {
              enabled: false,
            },
            exporting: {
              enabled: false,
            },
          }}
        />
      </Box>
    </Fade>
  );
};
