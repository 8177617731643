import { ThingworxError } from "src/types/error";
import { FetchClient } from "@/services/ApiClient";
import { ConditionConfig } from "../../types";
import { z } from "zod";

export type GetRuleListResponse = ThingworxError | GetRuleListResult;

interface Payload {
  account: string;
  platformLvl: number;
}

const RuleSchema = z.object({
  account: z.string(),
  company: z.string(),
  data: z.array(ConditionConfig),
  eventLooping: z.boolean(), //z.number().transform((val) => val === 1),
  isEnabled: z.boolean(), //z.number().transform((val) => val === 1),
  machine: z.string(),
  mailList: z.array(z.string()),
  platformLvl: z.number(),
  priority: z.number(),
  recapTime: z.number(),
  ruleDescription: z.string(),
  ruleName: z.string(),
  timeRange: z.number(),
  timestamp: z.number(),
  machineTimezone: z.string(),
});

export type RuleSchema = z.infer<typeof RuleSchema>;

export const getRuleListResult = z.object({
  response: z.literal(true),
  data: z.array(RuleSchema),
});

export type GetRuleListResult = z.infer<typeof getRuleListResult>;

export const getRules = ({
  appKey,
  account,
  platformLvl,
}: {
  appKey: string;
  account: string;
  platformLvl: number;
}) => {
  return FetchClient<Payload, GetRuleListResponse>({
    appKey,
    payload: {
      account,
      platformLvl,
    },
    url: "dig.c.event_thing/Services/getListOfRule",
  });
};

// export const getRules = async ({
//   appKey,
//   account,
//   platformLvl,
// }: {
//   appKey: string;
//   account: string;
//   platformLvl: number;
// }) => {
//   const response = await FetchClient<Payload, GetRuleListResponse>({
//     appKey,
//     payload: {
//       account,
//       platformLvl,
//     },
//     url: "dig.c.event_thing/Services/getListOfRule",
//   });

//   if (!response.response) throw new Error(response.errorString);

//   return getRuleListResult.parse(response).data;
// };
