import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { GaugeSkeleton } from "@/features/MachineDetail/components/CircleLayout";
import { Stack } from "@mui/material";

export const EfficiencyNavigatorLoader = () => {
  return (
    <Stack gap={2}>
      <Stack
        direction={{ md: "row" }}
        gap={2}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack
          direction={"column"}
          gap={1}
          width={400}
          order={{ md: 0, xs: 1 }}
        >
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
        </Stack>
        <GaugeSkeleton width={200} height={200} />
        <Stack direction={"column"} gap={1} width={400}>
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
        </Stack>
      </Stack>
      <Stack direction={"column"} gap={2}>
        <Stack
          direction={{ md: "row" }}
          gap={{ md: 6, xs: 2 }}
          justifyContent={{ md: "space-between" }}
          height={200}
        >
          <SkeletonCard />
          <SkeletonCard />
        </Stack>
        <Stack
          direction={{ md: "row" }}
          gap={{ md: 6, xs: 2 }}
          justifyContent={{ md: "space-between" }}
          height={200}
        >
          <SkeletonCard />
          <SkeletonCard />
        </Stack>
      </Stack>
    </Stack>
  );
};
