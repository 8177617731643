import { useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useMachineContext } from "@/context/machine-context";
import { useTimeSelection, TimeSelection } from "../../store/useTimeSelection";
import { DateTimeRangePicker } from "../Calendars/DateTimeRangePicker";
import { useTranslate } from "@/i18n/config";

const LONG_TIME_SELECTIONS: TimeSelection[] = [
  "TwoWeeks",
  "Month",
  "ThreeMonths",
  "SixMonths",
  "Year",
];

export const SelectTimeSelection = ({
  forceCalendar = false,
  exclude = [],
  isMachinePage = true,
}: {
  forceCalendar?: boolean;
  exclude?: TimeSelection[];
  isMachinePage?: boolean;
}) => {
  const { machine } = useMachineContext();
  const {
    timeSelection,
    setTimeSelection,
    setAvailableSelection,
    availableSelection,
  } = useTimeSelection();
  const translate = useTranslate();

  useEffect(() => {
    if (machine?.machineLogic === "static") {
      LONG_TIME_SELECTIONS.forEach((longTimeSelection) => {
        if (!exclude.includes(longTimeSelection))
          exclude.push(longTimeSelection);
      });
    }
    if (exclude.includes(timeSelection)) {
      setTimeSelection("Shift");
    }
    setAvailableSelection({ filterBy: exclude });
  }, []);

  return (
    <>
      <FormControl>
        <InputLabel id="server">{translate("view")}</InputLabel>
        <Select
          labelId="server"
          label={translate("view")}
          value={timeSelection}
          id="server"
          name="server"
          size="small"
          onChange={(e) => setTimeSelection(e.target.value as TimeSelection)}
          sx={{
            fontWeight: "bold",
            width: "10rem",
          }}
        >
          {availableSelection.map((selection) => (
            <MenuItem
              key={selection}
              value={selection}
              disabled={
                isMachinePage &&
                selection === "Batch" &&
                machine?.hasBatch === false
                  ? true
                  : false
              }
            >
              {translate(`time_selection.${selection}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {forceCalendar ? (
        <DateTimeRangePicker />
      ) : timeSelection === "Custom" ? (
        <DateTimeRangePicker />
      ) : null}
    </>
  );
};
