import { ThemeOptions } from "@mui/material";

export const defaultTheme: ThemeOptions = {
  palette: {
    mode: "dark",
    primary: {
      main: "#FFFFFF",
      contrastText: "#1F2B56",
    },
    secondary: {
      main: "#FF0000",
      contrastText: "#2C3D7A",
    },
    info: {
      main: "#7F899E", // time selection
      light: "#A4BBE8", // icone
      dark: "#3E4756", // logout
      contrastText: "#E2ECFF", // calendario
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#b3b1b1",
      disabled: "#BBBBBB",
    },
    background: {
      default: "#1F293F",
      paper: "#0D1626",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    button: {
      textTransform: "none",
    },
  },
};
