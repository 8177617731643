import { useTranslate } from "@/i18n/config";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { Card } from "@/components/Layout/Card";
import { useGetRunsLapsShuttlesList } from "../../../api/Diagnostic/useGetRunsLapsShuttlesList";
import { useState } from "react";
import { LapSelection } from "./LapSelection";
import { LapsComparisonChart } from "./LapsComparisonChart";
import { CompareLapsItem } from "@/features/Acopostrak/types";
import { AnalyticSelection } from "./AnalyticSelection";

export const CompareLapsModal = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetRunsLapsShuttlesList();
  const [analyticId, setAnalyticId] = useState<number | undefined>(undefined);
  const [el1, setEl1] = useState<CompareLapsItem | undefined>(undefined);
  const [el2, setEl2] = useState<CompareLapsItem | undefined>(undefined);

  if (isLoading)
    return (
      <Dialog onClose={close} open={isOpen} maxWidth="xl" fullWidth>
        <DialogTitle>
          {translate("acopos.select_two_laps_to_compare")}
        </DialogTitle>
        <DialogContent>
          <SkeletonCard height={400} />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={close}>
            {translate("actions.back")}
          </Button>
        </DialogActions>
      </Dialog>
    );

  if (error)
    return (
      <Dialog onClose={close} open={isOpen} maxWidth="xl" fullWidth>
        <DialogTitle>
          {translate("acopos.select_two_laps_to_compare")}
        </DialogTitle>
        <DialogContent>
          <Card sx={{ height: 400 }}>
            {translate("user_feedback.an_error_occurred")}
          </Card>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={close}>
            {translate("actions.back")}
          </Button>
        </DialogActions>
      </Dialog>
    );

  if (!data)
    return (
      <Dialog onClose={close} open={isOpen} maxWidth="xl" fullWidth>
        <DialogTitle>
          {translate("acopos.select_two_laps_to_compare")}
        </DialogTitle>
        <DialogContent>
          <Card sx={{ height: 400 }}>{translate("user_feedback.no_data")}</Card>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={close}>
            {translate("actions.back")}
          </Button>
        </DialogActions>
      </Dialog>
    );

  if (data.data.length === 0 || data.analyticsList.length === 0)
    return (
      <Dialog onClose={close} open={isOpen} maxWidth="xl" fullWidth>
        <DialogTitle>
          {translate("acopos.select_two_laps_to_compare")}
        </DialogTitle>
        <DialogContent>
          <Card sx={{ height: 400 }}>{translate("user_feedback.no_data")}</Card>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={close}>
            {translate("actions.back")}
          </Button>
        </DialogActions>
      </Dialog>
    );

  return (
    <Dialog onClose={close} open={isOpen} maxWidth="xl" fullWidth>
      <DialogTitle>
        {translate("acopos.select_two_laps_to_compare")}
      </DialogTitle>
      <DialogContent>
        {/* <Card sx={{ minHeight: 400 }}> */}
        <Box display="flex" flexDirection="column">
          <Box display="flex">
            <AnalyticSelection
              analyticsList={data.analyticsList}
              setAnalyticId={setAnalyticId}
            />
            <LapSelection
              title={translate("compare")}
              data={data.data}
              currentlySelectedItem={el1}
              setElement={setEl1}
            />
            <LapSelection
              title={translate("with")}
              data={data.data}
              currentlySelectedItem={el2}
              setElement={setEl2}
            />
          </Box>
          {analyticId && el1 && el2 ? (
            <LapsComparisonChart
              analyticId={analyticId}
              firstLapToCompare={el1}
              secondLapToCompare={el2}
            />
          ) : (
            <Typography>
              {translate("acopos.select_x_to_fetch_data", {
                action: translate("actions.confirm"),
              })}
            </Typography>
          )}
        </Box>
        {/* </Card> */}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={close}>
          {translate("actions.back")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
