import { useAskConfirmAction } from "@/hooks/ask-confirm-action";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { AccountCircleOutlined, NoAccountsOutlined } from "@mui/icons-material";
import { useToggleEnableUser } from "../../../../lambda/useToggleEnableUser";
import { useTranslate } from "@/i18n/config";

export const ToggleEnabledCell = ({
  isEnabled,
  user,
}: {
  isEnabled: boolean;
  user: string;
}) => {
  const translate = useTranslate();
  const { mutate: toggleEnableUser } = useToggleEnableUser();
  const { askConfirm, renderConfirmModal } = useAskConfirmAction(
    `${isEnabled ? "Disable" : "Enable"} the user ${user}?`,
    () => toggleEnableUser({ email: user, isDisabled: !isEnabled })
  );

  return (
    <>
      <GridActionsCellItem
        icon={
          isEnabled ? (
            <AccountCircleOutlined />
          ) : (
            <NoAccountsOutlined color="error" />
          )
        }
        label={translate("actions.toggle_switch")}
        onClick={askConfirm}
      />
      {renderConfirmModal()}
    </>
  );
};
