import { useMachineContext } from "@/context/machine-context";
import { useTimeSelection } from "@/store/useTimeSelection";
import { Box, Grid, Stack } from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { MachineArray } from "../api/useGetLandingData";
import { SyncProblem } from "@mui/icons-material";
import { timezoneStore } from "@/store/useTimezoneStore";

type CategoryCodeType = 1 | 2 | 3 | 4 | 5 | 6;

type CategoryColorType = {
  [K in CategoryCodeType]: {
    textColor: string;
    bgColor: string;
  };
};

const CategoryColor: CategoryColorType = {
  6: { textColor: "#000", bgColor: "#eeeeee" },
  1: { textColor: "#fff", bgColor: "#0ab126" },
  2: { textColor: "#fff", bgColor: "#ff7a0d" },
  3: { textColor: "#fff", bgColor: "#1a98c9" },
  4: { textColor: "#fff", bgColor: "#B277E2" },
  5: { textColor: "#fff", bgColor: "#8a8a8a" },
};

export const MachineCard = (props: MachineArray) => {
  const {
    machine,
    categoryCode,
    state,
    oee,
    machineType,
    machineLogic,
    factory,
    hasBatch,
    hasSpeed,
    line_id,
    machineTimezone,
    hasAnomalies,
  } = props;

  const { setMachine } = useMachineContext();
  const { setTimeSelection, timeSelection } = useTimeSelection();
  const { setMachineTimezone } = timezoneStore();
  const navigate = useNavigate();
  return (
    <Grid
      item
      xs={1}
      sm={1}
      md={1}
      component={motion.div}
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      whileHover={{ scale: 1.05 }}
    >
      <Stack
        direction="column"
        sx={{ overflow: "hidden", cursor: "pointer" }}
        borderRadius={1}
        component={Box}
        role="button"
        tabIndex={0}
        onClick={() => {
          setMachine({
            machine,
            machineType,
            machineLogic,
            hasBatch,
            hasSpeed,
            lineId: line_id,
            machineTimezone,
          });
          timeSelection === "Batch" && hasBatch === false
            ? setTimeSelection("Shift")
            : null;
          setMachineTimezone(machineTimezone);
          navigate("/machine-detail");
        }}
      >
        <Box
          sx={{
            minHeight: "2.5rem",
            padding: ".5rem",
            transition: "all 0.2s ease-in-out",
            opacity: timeSelection === "Batch" ? (hasBatch ? "1" : "0.4") : "1",
            backgroundColor:
              CategoryColor[categoryCode as CategoryCodeType].bgColor,
            color: CategoryColor[categoryCode as CategoryCodeType].textColor,
            fontWeight: "500",
            whiteSpace: "nowrap",
            overflow: "hidden",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            <b>{state}</b>
            <Box>
              {machineType === "cyclic" ? "OEE " : "POEE "}
              {oee.toFixed(2).replace(/[.,]00$/, "")}
              <span dangerouslySetInnerHTML={{ __html: "&percnt;" }} />
            </Box>
          </Box>
          {hasAnomalies && <SyncProblem />}
        </Box>
        <Box
          sx={{
            minHeight: "2.5rem",
            padding: ".5rem",
            backgroundColor: "#444D5A",
            opacity: timeSelection === "Batch" ? (hasBatch ? "1" : "0.4") : "1",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "70%",
              }}
            >
              {factory}
            </Box>
            <Box
              sx={{
                fontWeight: "bold",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "70%",
              }}
            >
              {machine}
            </Box>
          </Box>
        </Box>
      </Stack>
    </Grid>
  );
};
