import { Box, useTheme } from "@mui/material";
import { SentinelLogo } from "../../components/Icons/SentinelLogo";
import { LoginForm } from "./components/LoginForm";
import { useFirebaseContext } from "../../context/firebase-context";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "@/i18n/config";
import { toast } from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";
import { signInWithEmailAndPassword } from "firebase/auth";
import { AuthParams } from "src/types";
import { useCallback } from "react";
import { useCheckIfMessageBanner } from "@/hooks/useCheckIfInformationBanner";
import { InformationBanner } from "@/components/Layout/InformationBanner";

export const Login = () => {
  const { palette } = useTheme();
  const firebaseCtx = useFirebaseContext();
  const { showBanner } = useCheckIfMessageBanner();
  const navigate = useNavigate();
  const translate = useTranslate();

  const { mutate } = useMutation((params: AuthParams) =>
    signInWithEmailAndPassword(
      firebaseCtx.authService,
      params.email,
      params.password,
    ),
  );

  const onSubmitLogin = useCallback(
    ({ email, password }: { email: string; password: string }) => {
      if (!email && !password) {
        return;
      }
      mutate(
        { email, password },
        {
          onSuccess() {
            navigate("/overview/home");
          },
          onError() {
            toast.error(translate("user_feedback.failed_to_login"));
          },
        },
      );
    },
    [mutate, navigate, translate],
  );

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      {showBanner && <InformationBanner />}
      <Box
        sx={{
          width: "30vw",
          minWidth: "400px",
          minHeight: "500px",
          height: "70vh",
          borderRadius: "6px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SentinelLogo dimension={120} color={palette.primary.main} />
        <LoginForm onSubmit={onSubmitLogin} />
      </Box>
    </Box>
  );
};
