import { useContext } from "react";
import { IsolatorDispatchContext } from "./isolator-context";

export const useIsolatorDispatchContext = () => {
  const context = useContext(IsolatorDispatchContext);

  if (!context) {
    throw new Error(
      "You can only use this context inside IsolatorDispatchContextProvider",
    );
  }
  return context;
};
