import { ExpandMore } from "@mui/icons-material";
import {
  Container,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  List,
  ListItem,
} from "@mui/material";
import { useGetGoldenInfo } from "../api/useGetGoldenInfo";
import { InfoTable } from "./InfoTable";

export const InfoHelpPage = () => {
  const { data, isLoading } = useGetGoldenInfo();

  return (
    <Container sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="h5">
            Determining the End of Primary Drying
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">
            The end of primary drying is determined by analyzing data, rather
            than relying solely on messages from the PLC. Specifically, primary
            drying is considered complete when the chamber pressure reading, as
            measured by the Pirani gauge, matches that of the chamber
            capacitance manometer.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography variant="h5">Golden Batch</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">
            The golden batch is a reference cycle of the freeze dryer that has
            been selected by experts as an example of optimal machine behavior.
          </Typography>
          {isLoading ? (
            <Typography variant="body2">Loading...</Typography>
          ) : data?.goldenSummary.length ? (
            <InfoTable goldenInfo={data.goldenSummary} />
          ) : null}
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Typography variant="h5">
            Calculating the Distance from the Golden Batch
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">
            The distance between a sensor curve and the golden batch is
            calculated by multiplying the standardised discrete Frechet distance
            by the standardised Euclidean Dynamic Time Warping distance.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Typography variant="h5">
            Determining "Traffic Light" Colors
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">
            A red color is assigned when the distance from the golden batch
            exceeds a certain threshold for that sensor. An orange color is
            assigned when the distance is close to the threshold, and a green
            color is assigned when the distance is lower than the threshold.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel4-content"
          id="panel4-header"
        >
          <Typography variant="h5">How is shelf heat load computed?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">
            Shelf Heat Load is calculated as: QN = mCp(Tinlet − Toutlet) where m
            is the mass of the cooling fluid used, Cp is the heat capacity at
            constant pressure, Tinlet is the shelf fluid inlet temperature, and
            Toutlet is the shelf outlet fluid temperature.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel5-content"
          id="panel5-header"
        >
          <Typography variant="h5">
            What do “rule breakages” represent?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem>
              <Typography variant="body2">
                Rule breakage #1 represents cases in which the distance of an
                asset from its golden batch exceeded the distance threshold.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">
                Rule breakage #2 represents cases where for 8 consecutive times
                the distance of an asset from its golden batch during the same
                phase and sub-phase was above or under the rolling average of
                the distance. The rolling average is only calculated using
                batches that did not break Rule 1.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">
                Rule breakage #3 represents cases where 7 consecutive measures
                of distance from the golden batch were increasing or decreasing
                during the same phase and sub-phase.{" "}
              </Typography>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel6-content"
          id="panel6-header"
        >
          <Typography variant="h5">
            How does the Condenser Performance advanced analytic work?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">
            The Condenser Performance analytic consists of an unsupervised
            anomaly detection which is performed on the residual of the average
            of the condenser coil midpoint temperatures. Once the average
            temperature of the condenser coils is obtained, a Savitsky-Golay
            filter is used to obtain the trend of the curve. The residuals are
            the result of a subtraction between the original average temperature
            and the trend.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel7-content"
          id="panel7-header"
        >
          <Typography variant="h5">
            How does the Refrigeration Module advanced analytic work?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">
            The refrigeration module consists of a novelty detection based on
            the following variables:
          </Typography>
          <List>
            <ListItem>
              <Typography variant="body2">
                Subcooled Refrigerant Temperature Compressor 1
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">
                Oil Return Temperature Compressor 1
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">
                Subcooled Refrigerant Temperature Compressor 2
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">
                Oil Return Temperature Compressor 2
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">
                Condenser Coil Midpoint Temperature 2.
              </Typography>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel8-content"
          id="panel8-header"
        >
          <Typography variant="h5">
            How does the Pressure Excursions analytic work?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">
            The Pressure Excursions analytic consists of an unsupervised anomaly
            detection which is performed on the residual of the pressure
            measured by the chamber Pirani during drying.
          </Typography>

          <br />

          <Typography variant="body2">
            Once the average temperature of the condenser coils is obtained, the
            running average is used to obtain the trend of the curve. The
            residuals are the result of a subtraction between the original
            average temperature and the trend.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel9-content"
          id="panel9-header"
        >
          <Typography variant="h5">
            How does the Vacuum Pump Module analytic work?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">
            This analytic compares the trend of vacuum pump currents during
            evacuation with an envelope obtained by comparing historical
            batches.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};
