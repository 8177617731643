import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { useLanguage } from "@/i18n/config";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type ProcessingDataResponse = ThingworxError | ProcessingDataResult;

const CandleChartData = z.object({
  badState: z.number(),
  sumDuration: z.number(),
  efficiency: z.number(),
  min: z.number(),
  avg: z.number(),
  comparisonToBm: z.number(),
  max: z.number(),
  stateNumber: z.number(),
  occurences: z.number(),
  bm: z.number(),
  state: z.string(),
  goodState: z.number(),
});

export type CandleChartData = z.infer<typeof CandleChartData>;

const MachineState = z.object({
  color: z.string(),
  name: z.string(),
  y: z.number(),
  z: z.number(),
});
export type MachineState = z.infer<typeof MachineState>;

const ProcessingDataResult = z.object({
  pieData: z.array(MachineState),
  data: z.array(CandleChartData),
  response: z.literal(true),
});

export type ProcessingDataResult = z.infer<typeof ProcessingDataResult>;

type RequestPayload = {
  machineName: string;
  timeSelection: TimeSelection;
  language_id: string;
};

export const useGetProcessingStatus = () => {
  const { machine } = useMachineContext();
  const { timeSelection } = useTimeSelection();
  const { appKey } = useFirebaseContext();
  const language = useLanguage();
  const machineName = machine?.machine || "";

  return useQuery<ProcessingDataResult>({
    queryKey: ["processingStatus", machineName, timeSelection, language],
    queryFn: async () => {
      const response = await FetchClient<RequestPayload, ProcessingDataResponse>({
        appKey,
        payload: {
          machineName,
          timeSelection,
          language_id: language,
        },
        url: "dig.c.processingStatesStatus_thing/Services/getData",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return ProcessingDataResult.parse(response);
    },
  });
};
