import { useState } from "react";
import { DateTime } from "luxon";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  Divider,
} from "@mui/material";
import { useCheckIfReleaseMessage } from "@/hooks/useCheckIfReleaseMessage";
import { useTranslate } from "@/i18n/config";

export const ReleaseMessageModal = () => {
  const translate = useTranslate();
  const { showMessage, title, content, timestamp, dismissMessage } =
    useCheckIfReleaseMessage();
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);

  if (!showMessage) {
    return null;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDoNotShowAgain(event.target.checked);
  };

  const handleOkClick = () => {
    dismissMessage(doNotShowAgain);
  };

  const parsedContent: string[] = JSON.parse(content);
  // Converto il timestamp in un oggetto DateTime
  const date = DateTime.fromSeconds(timestamp);

  // Formatto la data nel formato "dd LLL yyyy"
  const formattedDate = date.toFormat("dd LLL yyyy");

  return (
    <Dialog fullWidth open={showMessage} onClose={handleOkClick}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack>
          <Divider />
          <Typography sx={{ color: "#ccc", alignSelf: "end", pb: 1 }}>
            {formattedDate}
          </Typography>
          <Stack gap={1}>
            {parsedContent.map((line) => (
              <Typography>{line}</Typography>
            ))}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          control={
            <Checkbox
              checked={doNotShowAgain}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={translate("user_feedback.dont_show_again")}
        />
        <Button variant="outlined" onClick={handleOkClick}>
          {translate("actions.got_it")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
