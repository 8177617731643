import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import {
  RejectComparisonData,
  useGetRejectComparison,
} from "../api/useGetRejectComparison";
import { useTranslate } from "@/i18n/config";
import { fileNameFactory } from "@/utils/fileNameFactory";
import { useMachineContext } from "@/context/machine-context";

const COLORS = ["#F7B500", "#14a5e3", "#F50AC6", "#1db847", "#8321cf"];

// filtro gli elementi dove ho 0 in tutte le serie
const removeEmptyData = (
  data: RejectComparisonData[],
): {
  rejectData: {
    name: string;
    data: {
      cause: string;
      description: string;
      y: number;
    }[];
  }[];
  categories: string[];
} => {
  const filteredData: {
    rejectData: {
      name: string;
      data: {
        cause: string;
        description: string;
        y: number;
      }[];
    }[];
    categories: string[];
  } = { categories: [], rejectData: [] };

  const nonEmptyData = data.filter((dataset) => dataset.data.length !== 0);
  if (nonEmptyData.length === 0) return filteredData;

  const seriesNumber = nonEmptyData.length;
  if (seriesNumber === 0) return filteredData;

  const categories = nonEmptyData[0].data.map((cause) => cause.description);

  for (let i = 0; i < seriesNumber; i++) {
    filteredData.rejectData[i] = {
      name: nonEmptyData[i].name,
      data: [],
    };
  }

  const arrays: {
    cause: string;
    description: string;
    occurrences: number;
  }[][] = [];
  nonEmptyData.forEach((dataset) => arrays.push(dataset.data));

  categories.forEach((category, index) => {
    const allZeroes = arrays.reduce(
      (acc, curr) => acc && curr[index].occurrences === 0,
      true,
    );
    if (!allZeroes) {
      filteredData.categories.push(category);
      for (let i = 0; i < seriesNumber; i++) {
        const dataset = nonEmptyData[i].data[index];
        const datasetForHighcharts = {
          cause: dataset.cause,
          description: dataset.description,
          y: dataset.occurrences,
        };
        filteredData.rejectData[i].data.push(datasetForHighcharts);
      }
    }
  });

  return filteredData;
};

export const ColumnComparisonChart = () => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetRejectComparison();
  const { machine } = useMachineContext();
  const machineName = machine?.machine;

  if (isLoading) {
    return <AnalyticContainerSkeleton />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  const filteredData = removeEmptyData(data);

  return (
    <BarChart.Custom
      filename={fileNameFactory({
        name: "reject_causes",
        machine: machineName,
      })}
      height={400}
      categories={filteredData.categories}
      title={translate(
        "reject_causes.reject_occurrences_comparison_between_time_frames",
      )}
      uom=""
      scrollbarEnabled={true}
      xAxisMax={9}
      series={filteredData.rejectData.map((dataset, index) => {
        return {
          type: "column",
          name: dataset.name,
          data: dataset.data,
          color: COLORS[index],
        };
      })}
      legend={{
        enabled: true,
        options: { itemHoverStyle: { color: "lightblue" } },
      }}
      tooltip={{
        enabled: true,
        options: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormatter: function () {
            return `<tr><td style="padding:0"><span style="color: ${this.color}">● </span>${this.series.name}: </td><td style="padding:0"><b>${this.y}</b></td></tr>`;
          },
          footerFormat: "</table>",
        },
      }}
    />
  );
};
