import { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { DateTimeRangePicker } from "@/components/Calendars/DateTimeRangePicker";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { Summary } from "./pages/Summary";
import { Monitoring } from "./pages/Monitoring";
import { Diagnostic } from "./pages/Diagnostic";
import { MotorsContextProvider } from "./context/motors-context";

const SECTIONS = {
  SUMMARY: 0,
  MONITORING: 1,
  DIAGNOSTIC: 2,
};

export const Motors = () => {
  const [section, setSection] = useState(SECTIONS.SUMMARY);
  const [showCalendar, setShowCalendar] = useState(true);
  const translate = useTranslate();

  return (
    <MotorsContextProvider>
      <MachineViewNavBar>
        {showCalendar && <DateTimeRangePicker maxDays={30} />}
      </MachineViewNavBar>
      <Tabs
        value={section}
        aria-label="motors tabs"
        onChange={(_: React.SyntheticEvent, newValue: number) => {
          setSection(newValue);
        }}
      >
        <Tab value={SECTIONS.SUMMARY} label={translate("summary")} />
        <Tab value={SECTIONS.MONITORING} label={translate("monitoring")} />
        <Tab value={SECTIONS.DIAGNOSTIC} label={translate("diagnostic")} />
      </Tabs>
      {section === SECTIONS.SUMMARY && <Summary />}
      {section === SECTIONS.MONITORING && <Monitoring />}
      {section === SECTIONS.DIAGNOSTIC && (
        <Diagnostic
          onRender={() => setShowCalendar(false)}
          onUnmount={() => setShowCalendar(true)}
        />
      )}
    </MotorsContextProvider>
  );
};
