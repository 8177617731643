import { create } from "zustand";

export const alternativeViewStore = create<{
  viewFromFirstProduct: boolean;
  toggle: () => void;
}>((set) => ({
  viewFromFirstProduct: false,
  toggle: () =>
    set((state) => ({ viewFromFirstProduct: !state.viewFromFirstProduct })),
}));
