import { List, Button, Typography } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { Batch } from "../../../types";
import { SelectedBatch } from "./SelectedBatch";
import { useDisclosure } from "@/hooks/useDisclosure";
import { Modal } from "@/components/Modal";
import { FILTER_COLOR } from "../../../context/isolator-context";

export const ListWithShowMore = ({ batches }: { batches: Batch[] }) => {
  const translate = useTranslate();
  const { open, close, isOpen } = useDisclosure();

  return (
    <>
      {isOpen && (
        <Modal
          open={isOpen}
          titleContent={translate("isolator.selected_batches")}
          fullWidth
          maxWidth="sm"
          bodyContent={
            <List
              sx={{
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
              }}
              component="nav"
            >
              {batches.map((batch) => (
                <SelectedBatch key={batch.id} batch={batch} />
              ))}
            </List>
          }
          actions={
            <Button variant="outlined" onClick={close}>
              {translate("actions.cancel")}
            </Button>
          }
        />
      )}

      <SelectedBatch key={batches[0].id} batch={batches[0]} />
      <SelectedBatch key={batches[1].id} batch={batches[1]} />
      <Typography
        sx={{
          cursor: "pointer",
          transition: "transform .2s, color .2s",
          ":hover": {
            color: FILTER_COLOR,
            transform: "scale(1.1)",
          },
        }}
        onClick={open}
        variant="caption"
      >
        {translate("actions.show_more")}
      </Typography>
    </>
  );
};
