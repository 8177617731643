import { Backdrop } from "@mui/material";
import { SentinelLoader } from "./SentinelLoader";

interface LoaderProps {
  isLoading: boolean;
}

export const Loader: React.FC<LoaderProps> = ({ isLoading }) => (
  <Backdrop
    open={isLoading}
    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
  >
    <SentinelLoader />
  </Backdrop>
);
