import { Card } from "@/components/Layout/Card";
import { formatNumber } from "@/utils/formatNumber";
import { Stack, styled, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ReactNode } from "react";
import { AISuggest } from "../api/useGetEfficiencyNavigatorData";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { GridItem } from "@/features/MachineDetail/components/GridItem";

const AIColorCard = styled(
  Grid2,
  {},
)<{ categorycolor: string }>(({ categorycolor }) => ({
  borderRadius: 0,
  flex: 0.3,
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  backgroundColor:
    categorycolor === "Machine"
      ? "#D62422"
      : categorycolor === "Process"
      ? "#ED8526"
      : categorycolor === "Line"
      ? "#16A99C"
      : "#4d4d4d",
  color: "#ffffff",
  alignItems: "center",
}));

export const AiSuggestItem = ({ data }: { data: AISuggest }) => {
  return (
    <Grid2 container direction="row">
      <AIColorCard md={2} categorycolor={data.category}>
        {formatNumber(data.efficiencyLost) + "%"}
      </AIColorCard>
      <GridItem>
        <Card>
          <Stack direction="column">
            <Typography>{data.error}</Typography>
            <Grid2 container direction="row" justifyContent="space-between">
              <Typography>
                {data.nEvents + "/" + data.nEventsBm.toFixed(0)}
              </Typography>
              <Typography>
                {TimeHelpers.parseDurationToString({
                  duration: data.mttr * 60 * 1000,
                }) +
                  "/" +
                  TimeHelpers.parseDurationToString({
                    duration: data.mttrBm * 60 * 1000,
                  })}
              </Typography>
            </Grid2>
          </Stack>
        </Card>
      </GridItem>
    </Grid2>
  );
};

// FOR RECIPE PRODUCTION ELAPSED TIME
export const ItemLayout = ({
  icon,
  label,
  value,
}: {
  icon: ReactNode;
  label: string;
  value: string;
}) => {
  return (
    <Card>
      <Grid2
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid2 md={1}>{icon}</Grid2>
        <Grid2 md={10} container justifyContent={"space-between"}>
          <Typography>{label}</Typography>
          <Typography>{value}</Typography>
        </Grid2>
      </Grid2>
    </Card>
  );
};
