import { useDisclosure } from "@/hooks/useDisclosure";
import { HelpCenter } from "@mui/icons-material";
import { Button, Dialog, DialogTitle, DialogContent, Box } from "@mui/material";
import { InfoHelpPage } from "./InfoHelpPage";

export const HelpDialogButton = () => {
  const { isOpen, toggle } = useDisclosure(false);

  return (
    <Box
      sx={{
        marginLeft: "auto",
      }}
    >
      <Button
        onClick={() => toggle()}
        variant="outlined"
        endIcon={<HelpCenter />}
      >
        Info
      </Button>
      <Dialog
        open={isOpen}
        onClose={() => toggle()}
        scroll="paper"
        maxWidth="md"
      >
        <DialogTitle>Help and Information</DialogTitle>
        <DialogContent>
          <InfoHelpPage />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
