import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type GetDataTimeListResponse = ThingworxError | GetDataTimeListResult;

const GetDataTimeListResult = z.object({
  data: z.array(
    z.object({
      id: z.number(),
      timeZoneDifference: z.number(),
      timeLapse: z.string(),
      dataStartTimeLapse: z.number(),
    }),
  ),
  response: z.literal(true),
});

type GetDataTimeListResult = z.infer<typeof GetDataTimeListResult>;

type Payload = {
  machineName: string;
  dateStart: DateTime;
  dateEnd: DateTime;
  timeSelection: TimeSelection;
};

export const useGetDataTimeList = () => {
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();
  const { timeSelection, dates } = useTimeSelection();

  return useQuery<GetDataTimeListResult>({
    queryKey: [
      "efficiency-navigator-historic",
      "shift-list",
      machine!.machine,
      timeSelection,
      dates.dateStart,
      dates.dateEnd,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, GetDataTimeListResponse>({
        appKey,
        url: "dig.c.historicEfficiencyNavigator_thing/Services/getListOfTimeSelection",
        payload: {
          machineName: machine!.machine ?? "",
          dateStart: dates.dateStart,
          dateEnd: dates.dateEnd,
          timeSelection,
        },
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return GetDataTimeListResult.parse(response);
    },
    notifyOnChangeProps: ["data"],
  });
};
