import { DisconnectedCategory } from "@/components/CustomIcons/disconnectedCategory";
import { LineCategory } from "@/components/CustomIcons/lineCategory";
import { MaintenanceCategory } from "@/components/CustomIcons/maintenanceCategory";
import { NotProducingCategory } from "@/components/CustomIcons/notProducingCategory";
import { ProductionCategory } from "@/components/CustomIcons/productionCategory";
import { SetupPreProdCategory } from "@/components/CustomIcons/setupPreProdCategory";
import { CategoryCode } from "../api/CyclicLive/useGetSentinelLiveData";

export const CategoryIcon = ({
  categoryCode,
  width = 48,
  height = 48,
  fill,
}: {
  categoryCode: CategoryCode;
  width?: number;
  height?: number;
  fill?: string;
}) => {
  switch (categoryCode) {
    case 6:
      return <DisconnectedCategory width={width} height={height} fill={fill} />;
    case 3:
      return <LineCategory width={width} height={height} fill={fill} />;
    case 5:
      return <MaintenanceCategory width={width} height={height} fill={fill} />;
    case 2:
      return <NotProducingCategory width={width} height={height} fill={fill} />;
    case 1:
      return <ProductionCategory width={width} height={height} fill={fill} />;
    case 4:
      return <SetupPreProdCategory width={width} height={height} fill={fill} />;
    default:
      throw new Error("Invalid category");
  }
};
