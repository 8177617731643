import { RangePicker } from "@/components/Calendars/RangePicker";
import { ItemCard } from "@/components/ItemCard";
import { NavButton } from "@/components/NavButton";
import { useTranslate } from "@/i18n/config";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { useFormatTimestamp } from "@/utils/TimeHelpers";
import { Button, Stack, Typography, Grid } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { AcceptanceTestContentWrapper } from "../..";
import { useGetHistoricAcceptanceTestData } from "../../api/historic/useGetHistoricData";
import { useGetShiftList } from "../../api/useGetShiftList";
import {
  AcceptanceTestContextProvider,
  useAcceptanceTestContextDispatch,
} from "../../context/acceptanceTest-context";
import { AcceptanceTestLoader } from "../../layout/AcceptanceTestLoader";

export const AcceptanceTestHistoricView = () => {
  const { formatTimestamp } = useFormatTimestamp();
  const [selectedPeriod, setSelectedPeriod] = useState<{
    id: number;
    dataStartTimeLapse: number;
    dataEndTimeLapse: number;
    timeLapse: string;
  } | null>(null);

  const { data: list } = useGetShiftList();
  useEffect(() => {
    setSelectedPeriod(null);
  }, [list]);

  const translate = useTranslate();

  return (
    <>
      <MachineViewNavBar>
        <RangePicker onPickDate={() => {}} />
        {selectedPeriod ? (
          <Button onClick={() => setSelectedPeriod(null)}>{translate("go_back_to_select")}</Button>
        ) : null}
        <NavButton to="/acceptance-test" text={translate("view_live")} />
      </MachineViewNavBar>
      {selectedPeriod ? (
        <Stack gap={2} key={selectedPeriod.id}>
          <AcceptanceTestContextProvider>
            <HistoricView
              id={selectedPeriod.id.toString()}
              startTime={selectedPeriod.dataStartTimeLapse}
              endTime={selectedPeriod.dataEndTimeLapse}
            />
          </AcceptanceTestContextProvider>
        </Stack>
      ) : (
        <Stack spacing={2}>
          <Typography variant="h6" fontWeight="bold">
            {translate("please_select_a_period")}
          </Typography>
          <AnimatePresence presenceAffectsLayout mode="wait">
            <motion.div
              key={JSON.stringify(list?.Result.length)}
              variants={{
                hidden: { opacity: 0 },
                show: {
                  opacity: 1,
                },
              }}
              initial="hidden"
              animate="show"
            >
              <Grid container spacing={2}>
                {list?.Result.map((item) => (
                  <Grid item xs={6} md={3} key={item.Id}>
                    <ItemCard
                      item={item}
                      onClick={(i) => {
                        setSelectedPeriod({
                          dataStartTimeLapse: i.DataStartTimeLapse,
                          dataEndTimeLapse: i.DataEnd,
                          id: i.Id,
                          timeLapse: i.TimeLapse,
                        });
                      }}
                      subtitle={`${translate("acceptance_test.started")}: ${formatTimestamp({
                        timestamp: item.DataStartTimeLapse,
                        fmt: "DD HH:mm:ss",
                      })}`}
                      title={formatTimestamp({
                        timestamp: item.DataStartTimeLapse,
                        fmt: "DDD",
                      })}
                      optionalLabel={`${translate("acceptance_test.ended")}: ${formatTimestamp({
                        timestamp: item.DataEnd,
                        fmt: "DD HH:mm:ss",
                      })}`}
                      isAcceptanceTest
                    />
                  </Grid>
                ))}
              </Grid>
            </motion.div>
          </AnimatePresence>
        </Stack>
      )}
    </>
  );
};

export const HistoricView = ({
  id,
  startTime,
  endTime,
}: {
  id: string;
  startTime: number;
  endTime: number;
}) => {
  const { data: historicData, isLoading } = useGetHistoricAcceptanceTestData({
    id,
  });
  const dispatch = useAcceptanceTestContextDispatch();
  useEffect(() => {
    dispatch({ type: "view-mode", viewMode: "Historic" });
    dispatch({
      type: "get-historicTimeLapse",
      historicTimeLapse: { startTime: startTime, endTime: endTime },
    });
  }, [id]);
  return (
    <>
      {isLoading ? <AcceptanceTestLoader /> : null}
      {historicData ? (
        <AcceptanceTestContentWrapper
          data={{
            tableData: historicData.data.DatiTabella,
            generalInfos: historicData.data.ValoriGenerali,
            customerInfo: historicData.data.ValoriUnrelated,
            machineInfo: historicData.data.ValoriRelated,
            isMachineParametric: historicData.data.isMachineParametric,
            oeeStream: historicData.oeeStream,
            viewMode: "Historic",
          }}
        />
      ) : null}
    </>
  );
};
