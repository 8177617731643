import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Modal } from "@/components/Modal";
import { useDisclosure } from "@/hooks/useDisclosure";
import { useTranslate } from "@/i18n/config";
import {
  EVALUATION_COLORS,
  useAcoposContext,
} from "../../../context/acopos-context";
import { Run } from "../../../types";
import { ModalRunsHeatmaps } from "./ModalRunsHeatmaps";
import { ExpandLess, ExpandMore, Circle } from "@mui/icons-material";

const MAX_RUNS_NUMBER = 2;

export const RunsInspection = () => {
  const translate = useTranslate();
  const { open, close, isOpen } = useDisclosure();
  const [selectedRuns, setSelectedRuns] = useState<Run[]>([]);
  const [checked, setChecked] = useState<number[]>([]);
  const [expand, setExpand] = useState(true);
  const { runs } = useAcoposContext();

  useEffect(() => {
    const updatedRuns: Run[] = [];
    const updatedRunIds: number[] = [];
    selectedRuns.forEach((run) => {
      const isInContext = runs.find((el) => el.id === run.id);
      if (isInContext) {
        updatedRuns.push(run);
        updatedRunIds.push(run.id);
      }
    });
    setSelectedRuns(updatedRuns);
    setChecked(updatedRunIds);
  }, [runs]);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      if (checked.length >= MAX_RUNS_NUMBER) {
        toast.error(
          translate("user_feedback.max_n_selected", { max: MAX_RUNS_NUMBER })
        );
      } else {
        newChecked.push(value);
      }
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);

    const selection: Run[] = [];
    newChecked.forEach((runId) => {
      const foundRun = runs.find((run) => run.id === runId);
      if (foundRun) selection.push(foundRun);
    });
    setSelectedRuns(selection);
  };

  return (
    <>
      {isOpen && (
        <Modal
          fullWidth
          maxWidth="xl"
          open={isOpen}
          titleContent={translate("acopos.runs_inspection")}
          bodyContent={
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <List
                sx={{
                  bgcolor: "background.paper",
                  maxHeight: 400,
                  overflow: "auto",
                }}
              >
                <ListItemButton onClick={() => setExpand(!expand)}>
                  <ListItemText primary={translate("acopos.run_selection")} />
                  {expand ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={expand} timeout="auto" unmountOnExit>
                  {runs.map((run) => {
                    const labelId = `checkbox-list-label-${run.id}`;
                    const color = EVALUATION_COLORS[run.evaluation].selected;

                    return (
                      <ListItem key={run.id} disablePadding value={run.name}>
                        <ListItemButton
                          role={undefined}
                          onClick={handleToggle(run.id)}
                          dense
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={checked.indexOf(run.id) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>
                          {/* <ListItemText
                            id={labelId}
                            primary={`<p>${run.name}</p>`}
                          /> */}
                          <Typography key={labelId}>{run.name}</Typography>
                          <Circle sx={{ color, paddingLeft: "1rem" }} />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </Collapse>
              </List>
              {selectedRuns.length > 0 && (
                <ModalRunsHeatmaps selectedRuns={selectedRuns} />
              )}
            </Box>
          }
          actions={
            <Button variant="outlined" onClick={close}>
              {translate("actions.cancel")}
            </Button>
          }
        />
      )}
      <Box p={1} display="flex" justifyContent="start">
        <Button variant="outlined" onClick={open}>
          {translate("acopos.runs_inspection")}
        </Button>
      </Box>
    </>
  );
};
