import {
  BASE_HORIZONTAL_DIMENSION,
  BASE_HORIZONTAL_SPACING,
  BASE_VERTICAL_DIMENSION,
  BASE_VERTICAL_SPACING,
} from "../config";

const getHorizontalPosition = (pos_x: number) =>
  pos_x * (BASE_HORIZONTAL_DIMENSION + BASE_HORIZONTAL_SPACING);

const getVerticalPosition = (pos_y: number) =>
  pos_y * (BASE_VERTICAL_DIMENSION + BASE_VERTICAL_SPACING);

const getNodeWidth = (childNodesAmount: number) => {
  if (childNodesAmount === 0) return BASE_HORIZONTAL_DIMENSION;
  return (
    (BASE_HORIZONTAL_DIMENSION + BASE_HORIZONTAL_SPACING) *
      (childNodesAmount + 1) -
    BASE_HORIZONTAL_SPACING
  );
};

export { getHorizontalPosition, getVerticalPosition, getNodeWidth };
