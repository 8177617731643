import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { FetchClient } from "@/services/ApiClient";
import { useFirebaseContext } from "@/context/firebase-context";
import { ChartType, dataVariableSchema } from "../types/variable";
import { useLanguage } from "@/i18n/config";

type Response = ThingworxError | Result;

const Result = z.object({
  response: z.literal(true),
  chart_type: ChartType,
  machineTimezone: z.string(),
  data: z.array(dataVariableSchema),
  title: z.string(),
});

type Result = z.infer<typeof Result>;

type Payload = {
  chart_id: number;
  dateStart: number;
  dateEnd: number;
  language_id: string;
};

export const useGetChartData = ({
  chart_id,
  dateStart,
  dateEnd,
}: {
  chart_id: number;
  dateStart: number;
  dateEnd: number;
}) => {
  const { appKey } = useFirebaseContext();
  const language = useLanguage();

  return useQuery<Result>({
    queryKey: ["chart-data", chart_id, dateStart, dateEnd, language],
    queryFn: async () => {
      const response = await FetchClient<Payload, Response>({
        appKey,
        url: "dig.c.customChartsDev_thing/services/getChartData",
        payload: {
          chart_id,
          dateStart,
          dateEnd,
          language_id: language,
        },
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return Result.parse(response);
    },
  });
};
