import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { useLanguage } from "@/i18n/config";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

const LogState = z.object({
  TimestampStart: z.number(),
  TimestampEnd: z.number(),
  DurationSs: z.number(),
  State: z.string(),
  isPause: z.boolean(),
  color: z.string(),
});
export type LogState = z.infer<typeof LogState>;

const LogStateResult = z.object({
  response: z.literal(true),
  stateList: z.array(z.string()),
  isMachineParametric: z.boolean(),
  Result: z.array(LogState),
});
type LogStateResult = z.infer<typeof LogStateResult>;
type LogStateResponse = ThingworxError | LogStateResult;

export const useGetLogState = ({
  isSatActive,
  startTime,
  endTime,
}: {
  isSatActive?: boolean;
  startTime: number;
  endTime: number;
}) => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const language = useLanguage();

  return useQuery<LogStateResult>({
    queryKey: ["log-state", machine!.machine, isSatActive, startTime, language],
    queryFn: async () => {
      const response = await FetchClient<
        { dateStart: number; dateEnd: number; machineName: string; language_id: string },
        LogStateResponse
      >({
        appKey,
        payload: {
          // ! in in vanilla date start Corrisponde a ValoriGenerali[0].startTimeUTC
          dateStart: startTime,
          dateEnd: endTime,
          machineName: machine!.machine,
          language_id: language,
        },
        url: "dig.c.acceptanceTest_thing/Services/getLogState",
      });

      if (!response.response) throw new Error(response.errorString);
      return LogStateResult.parse(response);
    },
    enabled: isSatActive && !!startTime,
    refetchInterval: isSatActive ? 15 * 1000 : false,
  });
};
