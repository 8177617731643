/* eslint-disable @typescript-eslint/ban-ts-comment */
import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { Stack, Typography, Divider } from "@mui/material";
import { renderToString } from "react-dom/server";
import { FaultAnalysis, Loss } from "../api/useGetEfficiencyNavigatorData";
import { fileNameFactory } from "@/utils/fileNameFactory";
import { EffNavigatorColors } from "..";

export const ChartItem = ({
  data,
  title,
  categoryValue,
  color,
}: {
  data: Loss[];
  title: string;
  categoryValue: number;
  color: string;
}) => {
  const dataSorted = data.sort((a, b) =>
    a.value < b.value ? 1 : b.value < a.value ? -1 : 0,
  );

  const categories = dataSorted.map((element) => element.lossName);
  const realData = dataSorted.map((element) => ({
    name: element.lossName,
    y: +element.value.toFixed(2),
    custom: {
      benchmark: +element.valueBm.toFixed(2),
      mttr: TimeHelpers.parseDurationToString({
        duration: element.mttr * 60 * 1000,
      }),
      mttrBm: TimeHelpers.parseDurationToString({
        duration: element.mttrBm * 60 * 1000,
      }),
    },
  }));

  const targetData = dataSorted.map((element) => ({
    name: element.lossName,
    y: +element.valueBm.toFixed(2),
  }));

  const benchmarksSum = targetData
    .reduce((acc, curr) => acc + curr.y, 0)
    .toFixed(2);

  return (
    <Card>
      <Stack gap={1}>
        <Stack direction="row" justifyContent="space-between">
          <Typography color={color}>{title}</Typography>
          <Typography>
            {categoryValue.toFixed(2)}%/{benchmarksSum}%
          </Typography>
        </Stack>
        <Divider color={color} />
        <BarChart.Custom
          categories={categories}
          filename={fileNameFactory({
            name: "efficiency_navigator",
          })}
          series={[
            {
              name: "Target",
              type: "column",
              color: "rgb(158, 159, 163)",
              data: targetData,
              // @ts-ignore
              borderRadiusTopRight: 4,
              borderRadiusTopLeft: 4,
            },
            {
              name: "Real",
              type: "column",
              data: realData,
              color: color,
              pointPadding: 0.1,
              pointPlacement: -0.1,
              // @ts-ignore
              borderRadiusTopRight: 4,
              borderRadiusTopLeft: 4,
            },
          ]}
          uom=""
          xAxisMax={realData.length > 5 ? 4 : undefined}
          scrollbarEnabled={realData.length > 5 ? true : false}
          tooltip={{
            enabled: true,
            options: {
              useHTML: true,
              formatter: function (this) {
                const point = this.points?.[1].point as unknown as {
                  name: string;
                  custom: {
                    benchmark: number;
                    mttr: string;
                    mttrBm: string;
                  };
                };
                if (point) {
                  return renderToString(
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <b>{point.name}</b>
                      <b>Value: {this.points?.[1].y}%</b>
                      <b>MTTR: {point.custom.mttr}</b>
                      <b>Benchmark: {point.custom.benchmark}%</b>
                      <b>MTTR Benchmark: {point.custom.mttrBm}</b>
                    </div>,
                  );
                }
              },
            },
          }}
        />
      </Stack>
    </Card>
  );
};

export const FaultAnalysisItem = ({
  data,
  title,
  color,
}: {
  data: FaultAnalysis[];
  title: string;
  color: string;
}) => {
  // restituisce i primi 10 elementi in un nuovo un array di "data" ordinato in modo descrescente
  const dataSorted = data
    .sort((a, b) => (a.value < b.value ? 1 : b.value < a.value ? -1 : 0))
    .slice(0, 10);
  const categories = dataSorted.map((element) => element.lossName);
  const faultAnalysisData = dataSorted.map((element) => ({
    name: element.lossName,
    y: +element.value.toFixed(2),
    custom: {
      mttr: TimeHelpers.parseDurationToString({
        duration: element.mttr * 60 * 1000,
      }),
    },
    color: EffNavigatorColors.faultAnalysis[element.colorType],
  }));

  // somma i valori di "value"
  const benchmarksSum = faultAnalysisData
    .reduce((acc, curr) => acc + curr.y, 0)
    .toFixed(2);

  const translate = useTranslate();

  return (
    <Card>
      <Stack gap={1}>
        <Stack direction={"row"} justifyContent="space-between">
          <Typography color={color}>{title}</Typography>
          <Typography>{translate("efficiency_navigator.top")} 10</Typography>
          <Typography>{benchmarksSum}%</Typography>
        </Stack>
        <Divider color={color} />
        <BarChart.Custom
          uom=""
          categories={categories}
          series={[
            {
              name: translate("efficiency_navigator.fault_analysis"),
              type: "column",
              data: faultAnalysisData,
              // @ts-ignore
              borderRadiusTopRight: 4,
              borderRadiusTopLeft: 4,
            },
          ]}
          xAxisMax={faultAnalysisData.length > 5 ? 4 : undefined}
          scrollbarEnabled={faultAnalysisData.length > 5 ? true : false}
          tooltip={{
            enabled: true,
            options: {
              useHTML: true,
              formatter: function (this) {
                const point = this.points?.[0].point as unknown as {
                  name: string;
                  custom: {
                    mttr: string;
                  };
                };
                if (point) {
                  return renderToString(
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <b>{point.name}</b>
                      <b>Value: {this.points?.[0].y}%</b>
                      <b>MTTR: {point.custom.mttr}</b>
                    </div>,
                  );
                }
              },
            },
          }}
        />
      </Stack>
    </Card>
  );
};
