import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { Evaluation } from "../types";

type PolarChartResponse = ThingworxError | PolarChartResult;

const polarChartData = z.array(
  z.object({
    label: z.string(),
    lastYearLabel: z.string(),
    value: z.number(),
    lastYearValue: z.number(),
    evaluation: Evaluation,
  }),
);

export type PolarChartData = z.infer<typeof polarChartData>;

const data = z.object({
  motorName: z.string(),
  chartData: polarChartData,
});

export type Data = z.infer<typeof data>;

const polarChartResult = z.object({
  response: z.literal(true),
  data,
});

export type PolarChartResult = z.infer<typeof polarChartResult>;

interface Payload {
  machineName: string;
  motor: number;
}

export const useGetPolarChartData = ({ motor }: { motor: number }) => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();

  return useQuery<Data>({
    queryKey: ["get-polar-chart", machine?.machine, motor],
    queryFn: async () => {
      const response = await FetchClient<Payload, PolarChartResponse>({
        appKey,
        payload: {
          machineName: machine?.machine || "",
          motor,
        },
        url: "dig.c.motors_thing/Services/getPolarChart",
      });

      if (!response.response) throw new Error(response.errorString);

      const validatedResponse = polarChartResult.parse(response);
      return validatedResponse.data;
    },
  });
};
