import { Box, Stack, Typography } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { LineChart } from "@/components/highcharts/linechart/Linechart";
import {
  useGetDescriptiveData,
  DescriptiveData,
} from "../../api/Monitoring/useGetDescriptiveData";
import { Card } from "@/components/Layout/Card";
import { ContextInformation } from "@/features/Motors/components/Layouts/ContextInformation";
import { generateColorScale } from "../../utils/dynamic-colors";

export const DescriptiveAnalytics = ({
  analytics,
}: {
  analytics: string[];
}) => {
  const { data, isLoading, error } = useGetDescriptiveData({ analytics });
  const translate = useTranslate();

  if (isLoading)
    return <AnalyticContainerSkeleton width="calc(100% - 320px)" />;

  if (error)
    return (
      <Card sx={{ width: "calc(100% - 320px)" }}>
        {translate("user_feedback.an_error_occurred")}
      </Card>
    );

  if (!data)
    return (
      <Card sx={{ width: "calc(100% - 320px)" }}>
        {translate("user_feedback.no_data")}
      </Card>
    );

  return (
    <Stack gap={1} width="calc(100% - 320px)">
      {analytics.length > 0 ? (
        data.data.length > 0 ? (
          data.data.map((analytic, index) => (
            <Analytic
              key={`${analytic.analyticName}-${index}`}
              props={analytic}
            />
          ))
        ) : (
          <Card>{translate("motors.no_data_try_another")}</Card>
        )
      ) : (
        <Card sx={{ height: 400 }}>
          <Typography sx={{ color: "red" }} variant="h4">
            {translate("motors.no_selection")}
          </Typography>
          <Typography variant="h6">
            {translate("acopos.select_at_least_one_analytic")}
          </Typography>
        </Card>
      )}
    </Stack>
  );
};

const Analytic = ({ props }: { props: DescriptiveData }) => {
  const { analyticName, data, contextInfo, isAggregated } = props;
  const colors = generateColorScale(
    {
      r: 3,
      g: 244,
      b: 252,
    },
    {
      r: 234,
      g: 12,
      b: 250,
    },
    data.length,
  );

  return (
    <Card>
      <Box>
        {!isAggregated && (
          <ContextInformation props={contextInfo} key={analyticName} />
        )}
        {isAggregated ? (
          <LineChart.Custom
            key={`${analyticName}-${JSON.stringify(contextInfo)}`}
            title={analyticName}
            xAxisType="linear"
            legend={{ enabled: true }}
            yAxis={[
              {
                uom: "",
                series: data.map((serie, i) => {
                  return {
                    type: "line",
                    name: serie.name,
                    data: serie.data,
                    color: colors[i],
                  };
                }),
              },
            ]}
            zoomType="x"
          ></LineChart.Custom>
        ) : (
          <LineChart.Custom
            key={JSON.stringify(contextInfo)}
            title={analyticName}
            yAxis={[
              { uom: "", series: [{ type: "line", name: analyticName, data }] },
            ]}
            zoomType="x"
          ></LineChart.Custom>
        )}
      </Box>
    </Card>
  );
};
