import { Evaluation } from "../types";

const colors = {
  good: "Good",
  warning: "Warning",
  critical: "Critical",
  lack_of_data: "Lack of data",
  no_data: "No data",
  undefined: "Undefined",
};

export const formatStatus = (evaluation: Evaluation): string => {
  return colors[evaluation];
};
