import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { Card } from "@/components/Layout/Card";
import { LoaderCard } from "@/components/Layout/LoaderCard";
import { useGetVacuumPumpSummaryDurations } from "../api/useGetVacuumPumpSummaryDurations";

export const VacuumPumpOverview = () => {
  const { data = [], isLoading } = useGetVacuumPumpSummaryDurations();

  if (isLoading) {
    return <LoaderCard />;
  }

  if (!data.length) {
    return null;
  }

  return (
    <Card>
      <BarChart.DurationBarchart
        title="Evacuation Duration"
        uom=""
        categories={data.map((item) => item.batch_name)}
        data={data.map((item) => ({
          name: item.name,
          y: +new Date(item.to / 1000) - +new Date(item.from / 1000),
        }))}
      />
    </Card>
  );
};
