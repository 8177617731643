import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { ref, onValue } from "firebase/database";
import { db } from "@/lib/sentinel-app";

type Severity = "info" | "warning" | "critical";

const COLORS: { [key: string]: string } = {
  critical: "#ff4400",
  warning: "#c7a82c",
  info: "#2f9fd6",
};

export const InformationBanner = () => {
  const [message, setMessage] = useState<string | null>(null);
  const [severity, setSeverity] = useState<Severity | null>(null);

  useEffect(() => {
    const messageRef = ref(db, "message");
    const unsubscribe = onValue(messageRef, (snapshot) => {
      const message = snapshot.val();
      setMessage(message.content ? message.content : null);
      setSeverity(message.severity ? message.severity : null);
    });

    return unsubscribe;
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        display: "flex",
        width: "100%",
        height: "32px",
        maxHeight: "32px",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#0d1626",
      }}
    >
      <Box
        sx={{
          width: "24px",
          height: "8px",
          backgroundColor: severity ? COLORS[severity] : "#ff4400",
          borderRadius: "5px",
        }}
      ></Box>
      <Typography variant="subtitle2" sx={{ px: "1rem" }}>
        {message}
      </Typography>
      <Box
        sx={{
          width: "24px",
          height: "8px",
          backgroundColor: severity ? COLORS[severity] : "#ff4400",
          borderRadius: "5px",
        }}
      ></Box>
    </Box>
  );
};
