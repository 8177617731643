import { useState, useEffect } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Card } from "@/components/Layout/Card";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { useTranslate } from "@/i18n/config";
import { useAcoposContext } from "../../context/acopos-context";
import { useGetDescriptiveAnalytics } from "../../api/Monitoring/useGetDescriptiveAnalytics";

interface DescriptiveAnalyticsSelectionProps {
  setSelectedAnalytics: React.Dispatch<React.SetStateAction<string[]>>;
}

export const DescriptiveAnalyticsSelection = ({
  setSelectedAnalytics,
}: DescriptiveAnalyticsSelectionProps) => {
  const { data, isLoading, error } = useGetDescriptiveAnalytics();
  const { shuttles, isLapsLimitActive } = useAcoposContext();
  const [prevShuttlesLength, setPrevShuttlesLength] = useState(shuttles.length);
  const translate = useTranslate();

  useEffect(() => {
    if (
      (prevShuttlesLength === 1 && shuttles.length === 2) ||
      (prevShuttlesLength === 2 && shuttles.length === 1) ||
      shuttles.length === 0
    ) {
      setSelectedAnalytics([]);
    }
    setPrevShuttlesLength(shuttles.length);
  }, [shuttles.length, setSelectedAnalytics]);

  useEffect(() => {
    setSelectedAnalytics([]);
  }, [isLapsLimitActive]);

  if (isLoading) {
    return <AnalyticContainerSkeleton sx={{ width: 320, height: 400 }} />;
  }

  if (error)
    return (
      <Card sx={{ width: 320, height: 400 }}>
        {translate("user_feedback.an_error_occurred")}
      </Card>
    );

  if (!data)
    return (
      <Card sx={{ width: 320, height: 400 }}>
        {translate("motors.no_analytics_to_display")}
      </Card>
    );

  if (data.analyticsList.length === 0)
    return (
      <Card sx={{ width: 320, height: 400 }}>
        {translate("motors.no_analytics_to_display")}
      </Card>
    );

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: string,
  ) => {
    const {
      target: { checked },
    } = event;
    if (checked) {
      setSelectedAnalytics((prevState) => {
        return [...prevState, item];
      });
    } else {
      setSelectedAnalytics((prevState) => {
        return prevState.filter((el) => el !== item);
      });
    }
  };

  return (
    <Card sx={{ width: 320, height: 400, overflowY: "auto" }}>
      <Typography variant="h6">{translate("variable_other")}</Typography>
      {!isLapsLimitActive || shuttles.length > 1 ? (
        <FormControl>
          <RadioGroup
            onChange={(
              _: React.ChangeEvent<HTMLInputElement>,
              value: string,
            ) => {
              setSelectedAnalytics([value]);
            }}
          >
            {data.analyticsList.map((item) => (
              <FormControlLabel
                key={`radio-${item.name}`}
                control={<Radio />}
                value={item.name}
                label={item.clientName}
              />
            ))}
          </RadioGroup>
        </FormControl>
      ) : shuttles.length === 1 ? (
        <FormControl>
          <FormGroup>
            {data.analyticsList.map((item) => (
              <FormControlLabel
                key={`checkbox-${item.name}`}
                control={
                  <Checkbox
                    onChange={(event) => handleChange(event, item.name)}
                  />
                }
                label={item.clientName}
              />
            ))}
          </FormGroup>
        </FormControl>
      ) : (
        <div>{translate("acopos.no_shuttles_selected")}</div>
      )}
    </Card>
  );
};
