import { PhaseCardLayout } from "./PhaseCardLayout";
import { Box, Stack, Popover, Typography } from "@mui/material";
import { PhaseData } from "../../types";
import { NivoSolidGauge } from "@/components/highcharts/gauge/NivoSolidGauge";
import { Status } from "../../api/useGetHistoricData";
import { DateTime } from "luxon";
import { colors } from "@/styles/colors";
import { useState } from "react";

interface PhaseCardProps {
  batchData: PhaseData;
  onSelectedBatch: (batchId: number) => void;
  active: boolean;
}

const PhaseDiagnosticStatus = ({
  name,
  status,
}: {
  name: string;
  status: Status;
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
    >
      <Box sx={{ fontSize: "12px" }}>{name}</Box>

      <Box
        sx={{
          backgroundColor:
            status === "Good"
              ? colors.lyo.status.good
              : status === "Bad"
              ? colors.lyo.status.bad
              : status === "Not Analyzed"
              ? colors.lyo.status.not_analyzable
              : status === "Not Analyzable"
              ? colors.lyo.status.not_analyzed
              : null,
          aspectRatio: "1/1",
          width: ".75rem",
          height: ".75rem",
          borderRadius: "10000px",
        }}
      />
    </Stack>
  );
};

export const PhaseCard = ({
  batchData,
  onSelectedBatch,
  active,
}: PhaseCardProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <>
      <PhaseCardLayout
        // tabIndex={0}
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        sx={{
          backgroundColor: active ? "#444959" : "#151B2F",
          transition: "all .2s ease-in",
        }}
        role="button"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={() => onSelectedBatch(batchData.id)}
        onKeyDown={() => onSelectedBatch(batchData.id)}
        initial={{ opacity: 0, scale: 0.75, x: 20 }}
        animate={{ opacity: 1, scale: 1, x: 0 }}
        exit={{ opacity: 0 }}
      >
        <Box sx={{ fontWeight: "bold" }}>{batchData.name}</Box>
        <Box sx={{ fontSize: ".75rem" }}>
          {DateTime.fromMillis(batchData.from).toLocaleString({
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </Box>
        <Box>
          <Stack spacing={2}>
            {batchData.status.map(({ statusName, status }) => (
              <PhaseDiagnosticStatus
                key={batchData.id + statusName}
                name={statusName}
                status={status}
              />
            ))}
          </Stack>
        </Box>
      </PhaseCardLayout>
      {batchData.info ? (
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Stack spacing={2} direction="column">
            {batchData.info.map((info, i) => (
              <Typography key={info + i} sx={{ p: 1 }}>
                {info}
              </Typography>
            ))}
          </Stack>
        </Popover>
      ) : null}
    </>
  );
};

export const PhaseOverviewCard = ({
  item,
  onSelectedBatch,
  active,
}: {
  item: {
    phase_id: number;
    total: number;
    name: string;
    goodAmount: number;
  };
  onSelectedBatch: (phase_id: number) => void;
  active: boolean;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <PhaseCardLayout
      sx={{
        position: "relative",
        padding: 1,
        width: 150,
        height: 150,
        aspectRatio: "1/1",
        textAlign: "center",
        backgroundColor: active ? "#444959" : "#151B2F",
        transition: "all .2s ease-in",
      }}
      // tabIndex={0}
      aria-owns={open ? "mouse-over-popover" : undefined}
      aria-haspopup="true"
      role="button"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      onClick={() => onSelectedBatch(item.phase_id)}
      onKeyDown={() => onSelectedBatch(item.phase_id)}
      initial={{ opacity: 0, scale: 0.75, x: 20 }}
      animate={{ opacity: 1, scale: 1, x: 0 }}
      exit={{ opacity: 0 }}
    >
      <NivoSolidGauge
        min={0}
        max={item.total}
        title={"OEE"}
        color={colors.kpi.oee}
        enableTrack={true}
        value={item.goodAmount}
        trackColor="#333"
      />
      <Box
        sx={{
          position: "absolute",
          color: "white",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 10,
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <Box>{item.name}</Box>
        <Box>
          {item.goodAmount}/{item.total}
        </Box>
      </Box>
    </PhaseCardLayout>
  );
};
