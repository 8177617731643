import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { noveltyAnomalyAnalytic } from "../../types";

export type GetNoveltyAnomalyAnalyticsResponse =
  | ThingworxError
  | GetNoveltyAnomalyAnalyticsResult;

const getNoveltyAnomalyAnalyticsResult = z.object({
  response: z.literal(true),
  analyticsList: z.object({
    noveltyAnalytics: z.array(noveltyAnomalyAnalytic),
    anomalyAnalytics: z.array(noveltyAnomalyAnalytic),
  }),
});

export type GetNoveltyAnomalyAnalyticsResult = z.infer<
  typeof getNoveltyAnomalyAnalyticsResult
>;

export const useGetNoveltyAndAnomalyAnalytics = () => {
  const { appKey } = useFirebaseContext();

  return useQuery<GetNoveltyAnomalyAnalyticsResult>({
    queryKey: ["novelty-anomaly-analytics-list"],
    queryFn: async () => {
      const response = await FetchClient<
        object,
        GetNoveltyAnomalyAnalyticsResponse
      >({
        appKey,
        payload: {},
        // url: "dig.c.acopostrak_thing/Services/getNoveltyAndAnomalyAnalytics",
        url: "dig.c.acoposTrack_thing/Services/getNoveltyAndAnomalyAnalytics",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse =
        getNoveltyAnomalyAnalyticsResult.parse(response);

      return validatedResponse;
    },
  });
};
