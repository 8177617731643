import { tabsClasses, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import { Card } from "@/components/Layout/Card";

export const CardsContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        maxWidth: { xs: 320, sm: "100%" },
      }}
    >
      <Tabs
        value={false}
        variant="scrollable"
        visibleScrollbar
        scrollButtons
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            "&.Mui-disabled": { opacity: 0.3 },
          },
        }}
      >
        {children}
      </Tabs>
    </Box>
  );
};

export const NoStates = ({ content }: { content: string }) => {
  return (
    <Card
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      sx={{
        display: "flex",
        flex: 1,
        height: 210,
        margin: "1rem",
        backgroundColor: "#FFFFFF10",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "none",
      }}
    >
      {content}
    </Card>
  );
};
