import { Stack, Typography } from "@mui/material";
import { useTranslate } from "@/i18n/config";

export const NoVersionsMessage = () => {
  const translate = useTranslate();
  const width = "40%";

  return (
    <Stack
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      gap={1}
      flex={1}
    >
      <Typography variant="h4" width={width}>
        {translate("intellecta.no_versions_message_1")}
      </Typography>
      <Typography variant="body1" width={width}>
        {translate("intellecta.no_versions_message_2")}
      </Typography>
      <Typography mt={2} variant="body2" width={width}>
        {translate("intellecta.no_versions_message_3")}
      </Typography>
      <Typography variant="body2" width={width}>
        {`"${translate("intellecta.version_data-analysis")}", "${translate(
          "intellecta.version_troubleshooting",
        )}"`}
      </Typography>
    </Stack>
  );
};
