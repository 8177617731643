import {
  IconButton,
  Popover,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState, Dispatch, SetStateAction } from "react";
import { Card } from "@/components/Layout/Card";
import { useMachineContextDispatch } from "../../context/MachineSettingsContext";
import { TWeek } from "../../api/useTurnParametric";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { TimeField } from "@mui/x-date-pickers-pro";
import { useTheme } from "@mui/material/styles";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { useTranslate } from "@/i18n/config";

const CardStyle = {
  backgroundColor: "rgba(76, 141, 255, 0.5);",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  color: "white",
  padding: ".6rem",
};

interface ShiftEditProps {
  prevShift: TWeek | null;
  nextShift: TWeek | null;
  shift: TWeek;
  dayIndex: number;
  shiftIndex: number;
  weekIndex: number;
  last: boolean;
  setIsError: Dispatch<SetStateAction<boolean>>;
}

export const ShiftEdit = ({
  prevShift,
  nextShift,
  shift,
  dayIndex,
  shiftIndex,
  weekIndex,
  last,
  setIsError,
}: ShiftEditProps) => {
  const dispatch = useMachineContextDispatch();
  const translate = useTranslate();

  const [error, setError] = useState({
    error: false,
    messageError: "",
  });

  const [startValue, setStartValue] = useState(
    DateTime.local().set({
      hour: shift.HourStart,
      minute: shift.MinuteStart,
    }),
  );

  const [endValue, setEndValue] = useState(
    DateTime.local().set({
      hour: shift.HourEnd,
      minute: shift.MinuteEnd,
    }),
  );

  const validateValue = (value: DateTime): boolean => {
    const regex =
      /^([0-2][0-3]|[0-1]?[0-9]):[0-5][0-9]$|^([0-2][0-3]|[0-1]?[0-9]):[0-5][0-9]$/;

    if (
      !regex.test(value.toFormat("HH:mm")) &&
      !regex.test(value.toFormat("H:m"))
    ) {
      setError({
        error: true,
        messageError: translate("machine_settings.error_invalid_time_format"),
      });
      setIsError(true);
      return false;
      // controllo se non è l'ultimo, perché l'ultimo può essere 22-6
    } else if (startValue > endValue && !last) {
      setError({
        error: true,
        messageError: translate("machine_settings.error_start_later_than_end"),
      });
      setIsError(true);
      return false;
    } else {
      return true;
    }
  };

  const setShift = () => {
    const currentShift: TWeek = {
      HourStart: startValue.hour,
      MinuteStart: startValue.minute,
      HourEnd: endValue.hour,
      MinuteEnd: endValue.minute,
    };

    setError({ error: false, messageError: "" });
    setIsError(false);
    dispatch({
      type: "modify shift",
      DayIndex: dayIndex,
      ShiftIndex: shiftIndex,
      WeekIndex: weekIndex,
      shift: currentShift,
    });
  };

  //* START
  useEffect(() => {
    const isValid = validateValue(startValue);
    if (!isValid) return;

    //* se prevShift è null non importa fare controlli su start
    //* perché significa che il giorno prima non ha turni configurati
    //* quindi non ho vincoli di orari

    //* se prevShift ha un valore può riferirsi sia allo stesso giorno
    //* che al giorno precedente

    if (prevShift) {
      const prevStartTime: DateTime = DateTime.local().set({
        hour: prevShift.HourStart,
        minute: prevShift.MinuteStart,
      });
      const prevEndTime: DateTime = DateTime.local().set({
        hour: prevShift.HourEnd,
        minute: prevShift.MinuteEnd,
      });

      //* if (prevStartTime > prevEndTime && prevEndTime > startValue) ERRORE
      //* if (prevStartTime > prevEndTime && startValue > prevEndTime) OK
      // e.g.: turno precedente 22-6 e turno corrente: 5-14
      if (
        prevStartTime > prevEndTime &&
        prevEndTime > startValue.plus({ minutes: 1 })
      ) {
        setIsError(true);
        return setError({
          error: true,
          messageError: translate(
            "machine_settings.error_start_earlier_than_previous_end",
          ),
        });
      }

      //* if (shiftIndex === 0) allora il turno precedente è del giorno prima
      // e.g.: turno precedente 6-12 e turno corrente 8-14 (stesso giorno)
      // se ho sia ieri che oggi 6-18 non dà errore perché shiftIndex sarebbe 0
      if (prevEndTime > startValue.plus({ minutes: 1 }) && shiftIndex > 0) {
        setIsError(true);
        return setError({
          error: true,
          messageError: translate(
            "machine_settings.error_start_earlier_than_previous_end",
          ),
        });
      }
    }

    const setShiftTimeout = setTimeout(() => {
      setShift();
    }, 1200);

    return () => clearTimeout(setShiftTimeout);
  }, [startValue]);

  //* END
  useEffect(() => {
    const isValid = validateValue(endValue);
    if (!isValid) return;

    //* se nextShift è null allora non importa fare controlli su end
    //* perché significa che il giorno dopo non ha turni configurati
    //* quindi non ho vincoli di orari

    //* se nextShift ha un valore può riferirsi sia allo stesso giorno
    //* che al giorno seguente

    if (nextShift) {
      const nextStartTime: DateTime = DateTime.local().set({
        hour: nextShift.HourStart,
        minute: nextShift.MinuteStart,
      });

      // fine turno corrente 14:00:30 inizio successivo 14:00:00
      // senza questo 'plus' darebbe errore mentre setto fine turno
      // l'ultimo turno del giorno non viene controllato
      if (endValue > nextStartTime.plus({ minutes: 1 }) && !last) {
        setIsError(true);
        return setError({
          error: true,
          messageError: translate(
            "machine_settings.error_end_later_than_following_start",
          ),
        });
      }

      // controllo l'ultimo turno del giorno
      // e.g.: turno corrente 14-22 turno successivo 6-14
      // if (last && endValue > startValue) OK
      // e.g.: turno corrente 22-6 turno successivo 4-12
      if (
        last &&
        startValue > endValue &&
        endValue > nextStartTime.plus({ minutes: 1 })
      ) {
        setIsError(true);
        return setError({
          error: true,
          messageError: translate(
            "machine_settings.error_end_later_than_following_start",
          ),
        });
      }
    }

    const setShiftTimeout = setTimeout(() => {
      setShift();
    }, 1200);

    return () => clearTimeout(setShiftTimeout);
  }, [endValue]);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopover = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const deleteShift = () => {
    dispatch({
      type: "delete shift",
      DayIndex: dayIndex,
      ShiftIndex: shiftIndex,
      WeekIndex: weekIndex,
    });
  };

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("md"));
  const midView = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Card
      sx={CardStyle}
      style={{
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        width: "fit-content",
      }}
    >
      <Stack direction="column" spacing={1}>
        <TimeField
          style={
            mobileView
              ? {
                  padding: "0px 0px",
                  maxWidth: "150px",
                  overflow: "visible",
                }
              : midView
              ? {
                  padding: "0px 0px",
                  minWidth: "60px",
                  maxWidth: "80px",
                  overflow: "visible",
                }
              : {
                  padding: "0px 0px",
                  minWidth: "60px",
                  maxWidth: "150px",
                  overflow: "visible",
                }
          }
          shouldDisableTime={() => {
            return error.error;
          }}
          size="small"
          variant="standard"
          label={translate("start")}
          value={startValue}
          onChange={(newValue) => {
            if (newValue != null) {
              setStartValue(newValue);
            }
          }}
        />
        <TimeField
          style={
            mobileView
              ? {
                  minWidth: "40px",
                  maxWidth: "150px",
                  overflow: "visible",
                }
              : midView
              ? {
                  minWidth: "60px",
                  maxWidth: "80px",
                  overflow: "visible",
                }
              : {
                  minWidth: "60px",
                  maxWidth: "150px",
                  overflow: "visible",
                }
          }
          shouldDisableTime={() => {
            return error.error;
          }}
          size="small"
          variant="standard"
          label={translate("end")}
          value={endValue}
          onChange={(newValue) => {
            if (newValue != null) {
              setEndValue(newValue);
            }
          }}
        />
      </Stack>
      <Stack justifyContent="center">
        <IconButton onClick={deleteShift}>
          <DeleteOutlinedIcon />
        </IconButton>
        {error.error && (
          <IconButton
            style={{ color: "red" }}
            onClick={mobileView ? handlePopover : handlePopoverOpen}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <PriorityHighIcon fontSize="small" />
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography sx={{ p: 1 }}>{error.messageError}</Typography>
            </Popover>
          </IconButton>
        )}
      </Stack>
      {mobileView && (
        <>
          <label style={{ marginRight: "2px", fontSize: "small" }}>
            {" "}
            {shiftIndex + 1}{" "}
          </label>
        </>
      )}{" "}
    </Card>
  );
};
