import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { batchDiagnosticPhaseAnalytic } from "../../types";
import { useIsolatorContext } from "../../context/useIsolatorContext";

export type GetBatchDiagnosticAnalyticsListResponse =
  | ThingworxError
  | GetBatchDiagnosticAnalyticsListResult;

type Payload = {
  machineName: string;
  batchIds: number[];
};

const getBatchDiagnosticAnalyticsListResult = z.object({
  response: z.literal(true),
  analytics: z.array(batchDiagnosticPhaseAnalytic),
});

export type GetBatchDiagnosticAnalyticsListResult = z.infer<
  typeof getBatchDiagnosticAnalyticsListResult
>;

export const useGetBatchDiagnosticAnalyticsList = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const { batches } = useIsolatorContext();
  const batchIds = batches.map((batch) => batch.id);

  return useQuery<GetBatchDiagnosticAnalyticsListResult>({
    queryKey: [
      "batch-diagnostic-analytics-list",
      machine!.machine,
      JSON.stringify(batchIds),
    ],
    queryFn: async () => {
      const response = await FetchClient<
        Payload,
        GetBatchDiagnosticAnalyticsListResponse
      >({
        appKey,
        payload: {
          machineName: machine!.machine,
          batchIds,
        },
        url: "dig.c.isolator_thing/Services/getBatchDiagnosticAnalyticsList",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse =
        getBatchDiagnosticAnalyticsListResult.parse(response);

      return validatedResponse;
    },
  });
};
