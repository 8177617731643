import { Machine } from "../api/useGetUserProductionLines";

export const getMachinesByLayer = (
  data: Machine[],
): Record<string, string[]> => {
  const machinesByLayer: { [key: string]: string[] } = {
    // per ovviare strano bug che non permette selezione su primo elemento
    placeholder: [],
  };
  data.forEach((machine) => {
    if (machine.line_layer === null || machine.line_layer === undefined) return;
    //? lasciare ?
    if (machine.machine_type === "process") return;
    const layer = `Layer ${machine.line_layer}`;
    if (Object.keys(machinesByLayer).includes(layer)) {
      machinesByLayer[layer].push(machine.name);
    } else {
      machinesByLayer[layer] = [machine.name];
    }
  });

  // Separiamo "placeholder" dalle chiavi "Layer X"
  const nonLayerKeys = Object.keys(machinesByLayer).filter(
    (key) => !key.startsWith("Layer"),
  );
  const layerKeys = Object.keys(machinesByLayer)
    .filter((key) => key.startsWith("Layer"))
    .sort((a, b) => {
      // Estraiamo i numeri dopo "Layer " per confrontarli
      const numA = parseInt(a.split(" ")[1]);
      const numB = parseInt(b.split(" ")[1]);
      return numA - numB;
    });

  // Combiniamo "placeholder" prima e i layer dopo, ordinati
  const sortedKeys = [...nonLayerKeys, ...layerKeys];

  // Ritorniamo un nuovo oggetto con le chiavi ordinate
  return sortedKeys.reduce(
    (sortedObj, key) => {
      sortedObj[key] = machinesByLayer[key];
      return sortedObj;
    },
    {} as Record<string, string[]>,
  );
};
