import { Card } from "@/components/Layout/Card";
import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { useTranslate } from "@/i18n/config";
import { SeriesColumnOptions } from "highcharts";

interface SummarySpecificBarChartProps {
  title: string;
  categories: string[];
  seriesData: SeriesColumnOptions[];
}

export const SummarySpecificBarChart = ({
  title,
  categories,
  seriesData,
}: SummarySpecificBarChartProps) => {
  const translate = useTranslate();
  return (
    <Card>
      <BarChart.Custom
        title={`${title} - ${translate("event_occurrences")}`}
        uom=""
        legend={{ enabled: true }}
        categories={categories}
        yAxisTitleOptions={{
          text: translate("occurrences"),
          style: { color: "white" },
        }}
        series={seriesData}
        tooltip={{
          enabled: true,
          options: { shared: false },
        }}
      ></BarChart.Custom>
    </Card>
  );
};
