import { useContext } from "react";
import { HistoricLineSelectedFrame } from "./historic-line-selected-frame";

export const useHistoricLineSelectedFrame = () => {
  const context = useContext(HistoricLineSelectedFrame);
  if (!context) {
    throw new Error(
      "useHistoricLineSelectedFrame must be used within a HistoricLineSelectedFrame"
    );
  }
  return context;
};
