import { PointOptionsObject } from "highcharts";
import { useTranslate } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";
import {
  PolarChartData,
  useGetPolarChartData,
} from "../../api/useGetPolarChart";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { PolarChart } from "@/components/highcharts/polarchart/PolarChart";
import { Evaluation } from "../../types";
import { EVALUATION_COLORS } from "../../utils/evaluationColors";

interface MeanPoint {
  x: number;
  y: number;
  name: string;
  color: string;
  custom: {
    lastYearLabel: string;
    lastYearValue: number;
    evaluation: Evaluation;
  };
}

const parsePolarChartData = ({
  data,
}: {
  data: PolarChartData;
}): { categories: string[]; chartData: PointOptionsObject[] } => {
  const categories: string[] = [];
  const chartData: PointOptionsObject[] = [];

  // Mappa per definire l'ordine dei mesi
  const monthOrder = {
    "01": 1,
    "02": 2,
    "03": 3,
    "04": 4,
    "05": 5,
    "06": 6,
    "07": 7,
    "08": 8,
    "09": 9,
    "10": 10,
    "11": 11,
    "12": 12,
  };

  // Ordinamento dei dati in base al mese
  const sortedData = data.sort((a, b) => {
    const monthA = a.label.split("-")[1]; // Estrai il mese (es. "01")
    const monthB = b.label.split("-")[1]; // Estrai il mese (es. "01")

    if (monthA in monthOrder && monthB in monthOrder) {
      return (
        monthOrder[monthA as keyof typeof monthOrder] -
        monthOrder[monthB as keyof typeof monthOrder]
      );
    }

    // Se qualcosa va storto, restituisci 0 per mantenere l'ordine
    return 0;
  });
  // const sortedData = data.sort((a, b) => a.label.localeCompare(b.label));

  sortedData.forEach((month, i) => {
    categories.push(month.label);
    chartData.push({
      x: i,
      y: month.value,
      name: month.label,
      color: EVALUATION_COLORS[month.evaluation].selected,
      custom: {
        lastYearLabel: month.lastYearLabel,
        lastYearValue: month.lastYearValue,
        evaluation: month.evaluation,
      },
    });
  });

  return { categories, chartData };
};

export const MeanCurrentPolarChart = ({ motor }: { motor: number }) => {
  const translate = useTranslate();
  const { data, isLoading, error } = useGetPolarChartData({ motor });

  if (isLoading) {
    return <AnalyticContainerSkeleton />;
  }

  if (error) {
    return (
      <Card sx={{ height: 500 }}>
        {translate("user_feedback.an_error_occurred")}
      </Card>
    );
  }

  if (!data) {
    return (
      <Card sx={{ height: 500 }}>{translate("user_feedback.no_data")}</Card>
    );
  }

  const { categories, chartData } = parsePolarChartData({
    data: data.chartData,
  });

  return (
    <Card sx={{ height: 500 }}>
      <PolarChart
        title={translate("motors.mean_current_comparison")}
        data={chartData}
        xAxisOptions={{
          min: 0,
          max: 12,
          categories,
        }}
        seriesOptions={{ opacity: 0.6 }}
        tooltipOptions={{
          formatter: function () {
            const point = this.point as unknown as MeanPoint;
            if (point) {
              return `
                  <span style="color:${point.color}">●</span> ${point.name}<br>
                  ${translate("value")}: <b>${point.y}</b><br>
                  <span>${translate("value")} ${
                    point.custom.lastYearLabel
                  }</span>: <b>${point.custom.lastYearValue}</b><br>
                  ${translate("evaluation")}: <b>${translate(
                    `evaluation.${point.custom.evaluation}`,
                  )}</b>
                `;
            }
            return translate("user_feedback.no_tooltip_data_available");
          },
        }}
      />
    </Card>
  );
};
