import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useCustomTimeRange } from "../store/useCustomTimeRange";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type BoxPlotResponse = ThingworxError | BoxPlotResult;

const BoxPlorData = z.object({
  netData: z.object({
    categories: z.array(z.string()),
    boxplotData: z.array(z.array(z.number())),
    scatterData: z.array(z.array(z.number())),
  }),
  tareData: z.object({
    categories: z.array(z.string()),
    boxplotData: z.array(z.array(z.number())),
    scatterData: z.array(z.array(z.number())),
  }),
});
type BoxPlotData = z.infer<typeof BoxPlorData>;

const BoxPlotResult = z.object({
  response: z.literal(true),
  data: BoxPlorData,
});

export type BoxPlotResult = z.infer<typeof BoxPlotResult>;

interface Payload {
  machineName: string;
  dateStart: DateTime;
  dateEnd: DateTime;
}

export const useGetBoxPlotData = () => {
  const { machine } = useMachineContext();

  const { dateStart, dateEnd } = useCustomTimeRange();

  const { appKey } = useFirebaseContext();

  return useQuery<BoxPlotData>({
    queryKey: ["box-plot-weights", machine?.machine, dateStart, dateEnd],
    queryFn: async () => {
      const response = await FetchClient<Payload, BoxPlotResponse>({
        appKey,
        payload: {
          dateStart,
          dateEnd,
          machineName: machine?.machine || "",
        },
        url: "dig.c.fillerAdvancedAnalytics_thing/Services/getBoxPlotData",
      });

      if (!response.response) throw new Error(response.errorString);

      const validatedResponse = BoxPlotResult.parse(response);
      return validatedResponse.data;
    },
  });
};
