import { useMachineContext } from "@/context/machine-context";
import { AnalyticContainerLayout } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { useTranslate } from "@/i18n/config";
import { Stack, Typography, styled } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { LatLng } from "leaflet";
import { MachineLocationResult } from "../api/useGetMachineLocation";
import { LocationMap } from "./LocationMap";
import { timezoneStore } from "@/store/useTimezoneStore";

const MachineInfoLayout = styled(AnalyticContainerLayout)(() => ({
  minHeight: "auto",
  height: "100%",
}));

export const ShowMachineInfo = ({ machineInfo }: { machineInfo: MachineLocationResult }) => {
  const {
    infoMarker,
    infoAccountName,
    infoAddress,
    infoCity,
    infoCountry,
    infoDescription,
    infoEquipment,
    infoLine,
    infoSaleOrganization,
  } = machineInfo;

  const decodedInfoMarker = decodeURIComponent(infoMarker)
    .split(",")
    .map((x) => parseFloat(x));

  const { machineTimezone } = timezoneStore();

  const [lat, long] = decodedInfoMarker;

  const markerPosition = new LatLng(lat, long);

  const { machine } = useMachineContext();
  const translate = useTranslate();

  return (
    <Stack
      gap={2}
      sx={{
        height: "calc(100vh - 128px)",
      }}
    >
      <Grid2 container spacing={2} textAlign="center" justifyContent={"center"}>
        <Grid2 xs={6} md={4}>
          <MachineInfoLayout>
            <Typography fontWeight="bold">{translate("machine_info.description")}</Typography>
            <Typography>{infoDescription}</Typography>
          </MachineInfoLayout>
        </Grid2>

        <Grid2 xs={6} md={4}>
          <MachineInfoLayout>
            <Typography fontWeight="bold">{translate("machine_info.serial_number")}</Typography>
            <Typography>{machine?.machine ?? ""}</Typography>
          </MachineInfoLayout>
        </Grid2>

        <Grid2 xs={6} md={4}>
          <MachineInfoLayout>
            <Typography fontWeight="bold">{translate("machine_info.equipment")}</Typography>
            <Typography>{infoEquipment}</Typography>
          </MachineInfoLayout>
        </Grid2>

        <Grid2 xs={6} md={4}>
          <MachineInfoLayout>
            <Typography fontWeight="bold">{translate("line")}</Typography>
            <Typography>{infoLine}</Typography>
          </MachineInfoLayout>
        </Grid2>

        <Grid2 xs={6} md={4}>
          <MachineInfoLayout>
            <Typography fontWeight="bold">{translate("machine_info.address")}</Typography>
            <Typography>{infoAddress}</Typography>
          </MachineInfoLayout>
        </Grid2>

        <Grid2 xs={6} md={4}>
          <MachineInfoLayout>
            <Typography fontWeight="bold">{translate("machine_info.city")}</Typography>
            <Typography>{infoCity}</Typography>
          </MachineInfoLayout>
        </Grid2>

        <Grid2 xs={6} md={4}>
          <MachineInfoLayout>
            <Typography fontWeight="bold">{translate("machine_info.country")}</Typography>
            <Typography>{infoCountry}</Typography>
          </MachineInfoLayout>
        </Grid2>

        <Grid2 xs={6} md={4}>
          <MachineInfoLayout>
            <Typography fontWeight="bold">{translate("machine_info.sale_organization")}</Typography>
            <Typography>{infoSaleOrganization}</Typography>
          </MachineInfoLayout>
        </Grid2>

        {/* <Grid2 xs={6} md={6}>
          <MachineInfoLayout>
            <Typography fontWeight="bold">{translate("machine_info.account_number")}</Typography>
            <Typography>{infoAccountNumber}</Typography>
          </MachineInfoLayout>
        </Grid2> */}

        <Grid2 xs={12} md={4}>
          <MachineInfoLayout>
            <Typography fontWeight="bold">{translate("company")}</Typography>
            <Typography>{infoAccountName}</Typography>
          </MachineInfoLayout>
        </Grid2>
        <Grid2 xs={12} md={4}>
          <MachineInfoLayout>
            <Typography fontWeight="bold">{translate("timezone.machine_utc")}</Typography>
            <Typography>{machineTimezone}</Typography>
          </MachineInfoLayout>
        </Grid2>
      </Grid2>
      <AnalyticContainerLayout sx={{ height: "30vh", flex: 1 }}>
        <LocationMap marker={markerPosition} />
      </AnalyticContainerLayout>
    </Stack>
  );
};
