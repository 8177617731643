import { NivoDoubleBarGauge } from "@/components/highcharts/gauge/NIvoDoubleBarGauge";
import { GaugeCircleLayout } from "@/features/MachineDetail/components/CircleLayout";

//PROVA , DA SASSARE PERCHE MANU HA GIA' IL COMPONENTE PER IL COUNTER
interface RadialBarValueProps {
  title: string;
  value: number;
}
export const CountCustomView = ({ title, value }: RadialBarValueProps) => {
  return (
    <>
      <text
        x="50%"
        y="40%"
        textAnchor="middle"
        dominantBaseline="middle"
        style={{ fill: "white", fontSize: "14pt" }}
      >
        {title}
      </text>
      <text
        y={"55%"}
        x="50%"
        textAnchor="middle"
        dominantBaseline="middle"
        style={{ fill: value >= 0 ? "#56BC72" : "red", fontSize: "14pt" }}
      >
        {value}
      </text>
    </>
  );
};

export const RadialDoubleBarGaugeLayout = ({
  title,
  value,
  targetValue,
  realValue,
  max,
  color,
  dimension = "large",
}: {
  title: string;
  value: number;
  targetValue: number;
  realValue: number;
  max: number;
  type?: "count";
  dimension?: "small" | "large";
  color: string;
}) => {
  const _dimension = dimension === "large" ? "15vmax" : "10vmax";

  return (
    <GaugeCircleLayout
      sx={{
        position: "relative",
        padding: 1,
        margin: "auto",
        width: _dimension,
        height: _dimension,
        aspectRatio: "1/1",
        textAlign: "center",
      }}
    >
      <NivoDoubleBarGauge
        max={max}
        value={value}
        realValue={realValue}
        targetValue={targetValue}
        title={title}
        color={color}
        CustomLayer={() => {
          return CountCustomView({ title, value });
        }}
      />
    </GaugeCircleLayout>
  );
};
