import { Stack } from "@mui/material";
import { ImputationLog } from "./ImputationLog";
import { ImputationStats } from "./ImputationStats";

export const PageContent = () => {
  return (
    <Stack gap={2} marginTop={2}>
      <ImputationStats />
      <ImputationLog />
    </Stack>
  );
};
