import { SelectTimeSelection } from "@/components/SelectTimeSelection";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { Stack } from "@mui/material";
import { KpiSelectorProcess } from "../../components/viewer/KpiSelectorProcess";

export const MachinerPerformanceProcess = () => {
  return (
    <>
      <MachineViewNavBar>
        <SelectTimeSelection />
      </MachineViewNavBar>
      <Stack spacing={5}>
        <KpiSelectorProcess />
      </Stack>
    </>
  );
};
