import { useContext } from "react";
import { MotorsContext } from "./motors-context";

export const useMotorsContext = () => {
  const context = useContext(MotorsContext);

  if (!context) {
    throw new Error(
      "You can only use this context inside MotorsContextProvider",
    );
  }
  return context;
};
