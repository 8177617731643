import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { noveltyAndAnomalyData } from "./useGetSingleNoveltyAndAnomalyData";
import { useMachineContext } from "@/context/machine-context";
import { useAcoposContext } from "../../context/acopos-context";
import { CompareLapsItem } from "../../types";

export type GetLapsComparisonResponse =
  | ThingworxError
  | GetLapsComparisonResult;

type Payload = {
  machineName: string;
  analyticId: number;
  firstLapToCompare: CompareLapsItem;
  secondLapToCompare: CompareLapsItem;
  segmentId: number;
};

const getLapsComparisonResult = z.object({
  response: z.literal(true),
  data: noveltyAndAnomalyData,
});

export type GetLapsComparisonResult = z.infer<typeof getLapsComparisonResult>;

export const useGetLapsComparison = ({
  analyticId,
  firstLapToCompare,
  secondLapToCompare,
}: {
  analyticId: number;
  firstLapToCompare: CompareLapsItem;
  secondLapToCompare: CompareLapsItem;
}) => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const { activeSegment } = useAcoposContext();

  return useQuery<GetLapsComparisonResult>({
    queryKey: [
      "novelty-anomaly-laps-comparison",
      analyticId,
      JSON.stringify(firstLapToCompare),
      JSON.stringify(secondLapToCompare),
      activeSegment,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, GetLapsComparisonResponse>({
        appKey,
        payload: {
          machineName: machine!.machine,
          analyticId,
          firstLapToCompare,
          secondLapToCompare,
          segmentId: activeSegment!,
        },
        // url: "dig.c.acopostrak_thing/Services/getLapsComparison",
        url: "dig.c.acoposTrack_thing/Services/getLapsComparison",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = getLapsComparisonResult.parse(response);

      return validatedResponse;
    },
  });
};
