/* eslint-disable @typescript-eslint/ban-ts-comment */
import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { Card } from "@/components/Layout/Card";
import { Box, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { LastProductionsResult, useGetLastProductions } from "../api/useGetLastProductions";
import { TimeSelection } from "@/store/useTimeSelection";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { useTranslate } from "@/i18n/config";
import { fileNameFactory } from "@/utils/fileNameFactory";
import { translateIntervals } from "@/utils/itemCardTranslate";

export const LineLastProductionsLive = ({
  line_id,
  config,
}: {
  line_id: number;
  config: "automatic" | "manual";
}) => {
  const [selectTimeSelection, setSelectTimeSelection] = useState<TimeSelection>("Shift");
  const { data, isLoading } = useGetLastProductions({
    config,
    line_id,
    timeSelection: selectTimeSelection,
  });
  const translate = useTranslate();
  if (isLoading) return <SkeletonCard height={372} />;
  if (!data) return null;
  return (
    <Card>
      <Stack direction="row" mb={2}>
        {config === "automatic" ? (
          <LastProductionTimeSelection
            selectedTimeSelection={selectTimeSelection}
            onTimeSelection={(timeSelection) => {
              setSelectTimeSelection(timeSelection);
            }}
          />
        ) : null}
        <Box sx={{ flexGrow: 1, textAlign: "center" }}>
          <Typography variant="h5">
            {translate("overall_line_efficiency.last_week_shifts_production")}
          </Typography>
        </Box>
      </Stack>
      <LineLastProductionChart data={data} />
    </Card>
  );
};

export const LineLastProductionHistoric = ({
  historicData,
}: {
  historicData: LastProductionsResult;
}) => {
  const translate = useTranslate();
  return (
    <Card>
      <Typography variant="h5">
        {translate("overall_line_efficiency.last_week_shifts_production")}
      </Typography>
      <LineLastProductionChart data={historicData} />
    </Card>
  );
};

export const LineLastProductionChart = ({ data }: { data: LastProductionsResult }) => {
  const translate = useTranslate();
  const categories = data.products.map((item) => translateIntervals(item.timelapse, translate));
  const goodProducts = data.products.map((item) => item.good);
  const wasteProducts = data.products.map((item) => item.wastes);

  return (
    <BarChart.Custom
      filename={fileNameFactory({
        name: "last_production",
      })}
      uom=""
      plotOptions={{
        column: {
          stacking: "normal",
          dataLabels: {
            enabled: false,
            color: "white",
            borderColor: "transparent",
            formatter: function () {
              return `${this.y?.toFixed(0)}% ${this.series.name}`;
            },
            style: {
              textOutline: "none",
            },
          },
        },
      }}
      yAxisOptions={{
        plotLines: [
          {
            color: "green",
            value: data.averageProducts,
            width: 2,
            zIndex: 4,
          },
        ],
      }}
      categories={categories}
      legend={{
        enabled: true,
      }}
      tooltip={{
        enabled: true,
      }}
      series={[
        {
          type: "column",
          name: translate("machine.wastes"),
          data: wasteProducts,
          color: "#E74C3C",
          // @ts-ignore
          borderRadiusTopLeft: 4,
          // @ts-ignore
          borderRadiusTopRight: 4,
          maxPointWidth: 100,
        },
        {
          maxPointWidth: 100,
          type: "column",
          name: translate("machine.products"),
          data: goodProducts,
          color: "#F07803",
        },
      ]}
    />
  );
};

const timeSelectionList = ["Shift", "Day", "Week"] as TimeSelection[];

const LastProductionTimeSelection = ({
  onTimeSelection,
  selectedTimeSelection,
}: {
  onTimeSelection: (timeSelection: TimeSelection) => void;
  selectedTimeSelection: TimeSelection;
}) => {
  const translate = useTranslate();
  return (
    <>
      <FormControl>
        <InputLabel id="lastProduction">{translate("select_view")}</InputLabel>
        <Select
          labelId="lastProduction"
          label={translate("select_view")}
          value={selectedTimeSelection}
          id="lastProduction"
          name="lastProduction"
          size="small"
          onChange={(e) => {
            onTimeSelection(e.target.value as TimeSelection);
          }}
          sx={{
            fontWeight: "bold",
            width: "10rem",
          }}
        >
          {timeSelectionList.map((selection) => (
            <MenuItem key={selection} value={selection}>
              {translate(`time_selection.${selection}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
