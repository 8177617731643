import { AlertSchema } from "../api/useGetEventsData/getAlerts";
import { Stack, Typography } from "@mui/material";
import { Event } from "../components/Event";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";

export const Completed = ({ alerts }: { alerts: AlertSchema[] }) => {
  const translate = useTranslate();
  return (
    <>
      <Card sx={{ display: "flex", gap: 2 }}>
        <Typography sx={{ flex: 2 }} fontWeight="bold">
          {translate("machine")}
        </Typography>
        <Typography sx={{ flex: 3 }} fontWeight="bold">
          {translate("events.event_description")}
        </Typography>
        <Typography sx={{ flex: 3 }} fontWeight="bold">
          {translate("timestamp")}
        </Typography>
        <Typography sx={{ flex: 5 }} fontWeight="bold">
          {translate("events.event_type")}
        </Typography>
        <Typography sx={{ flex: 3 }} fontWeight="bold">
          {translate("events.modified_by")}
        </Typography>
        <Typography sx={{ flex: 1 }} fontWeight="bold">
          {translate("events.severity")}
        </Typography>
        <Typography sx={{ flex: 0.5 }}></Typography>
      </Card>
      <Stack gap={0.5}>
        {alerts.length === 0 && <p>{translate("events.no_events")}</p>}
        {alerts.map((alert) => (
          <Event key={alert.id} info={alert} isUpgradable={false} />
        ))}
      </Stack>
    </>
  );
};
