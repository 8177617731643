import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { conditionAnalytic } from "../../types";

export type GetConditionAnalyticsResponse =
  | ThingworxError
  | GetConditionAnalyticsResult;

const getConditionAnalyticsResult = z.object({
  response: z.literal(true),
  analyticsList: z.array(conditionAnalytic),
});

export type GetConditionAnalyticsResult = z.infer<
  typeof getConditionAnalyticsResult
>;

export const useGetConditionAnalytics = () => {
  const { appKey } = useFirebaseContext();

  return useQuery<GetConditionAnalyticsResult>({
    queryKey: ["condition-analytics-list"],
    queryFn: async () => {
      const response = await FetchClient<object, GetConditionAnalyticsResponse>(
        {
          appKey,
          payload: {},
          // url: "dig.c.acopostrak_thing/Services/getConditionAnalytics",
          url: "dig.c.acoposTrack_thing/Services/getConditionAnalytics",
        },
      );

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = getConditionAnalyticsResult.parse(response);

      return validatedResponse;
    },
  });
};
