import { useState, useEffect } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { Card } from "@/components/Layout/Card";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { useGetAvailableDescriptiveData } from "../../api/useGetAvailableDescriptiveData";
import { humanizeString } from "../../utils/humanizeString";
import { useMotorsContext } from "../../context/useMotorsContext";

interface DescriptiveAnalyticsSelectionProps {
  setSelectedAnalytics: React.Dispatch<React.SetStateAction<string[]>>;
}

export const DescriptiveAnalyticsSelection = ({
  setSelectedAnalytics,
}: DescriptiveAnalyticsSelectionProps) => {
  const { data, isLoading, error } = useGetAvailableDescriptiveData();
  const { motors } = useMotorsContext();
  const [prevMotorsLength, setPrevMotorsLength] = useState(motors.length);
  const translate = useTranslate();

  useEffect(() => {
    if (
      (prevMotorsLength === 1 && motors.length === 2) ||
      (prevMotorsLength === 2 && motors.length === 1) ||
      motors.length === 0
    ) {
      setSelectedAnalytics([]);
    }
    setPrevMotorsLength(motors.length);
  }, [motors.length, setSelectedAnalytics, prevMotorsLength]);

  if (isLoading) {
    return <AnalyticContainerSkeleton sx={{ width: 320, height: 400 }} />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: string,
  ) => {
    const {
      target: { checked },
    } = event;
    if (checked) {
      setSelectedAnalytics((prevState) => {
        return [...prevState, item];
      });
    } else {
      setSelectedAnalytics((prevState) => {
        return prevState.filter((el) => el !== item);
      });
    }
  };

  const renderSelection = () => {
    if (motors.length === 1) {
      return (
        <FormControl>
          <FormGroup>
            {data.map((item) => (
              <FormControlLabel
                key={`checkbox-${item}`}
                control={
                  <Checkbox onChange={(event) => handleChange(event, item)} />
                }
                label={humanizeString(item)}
              />
            ))}
          </FormGroup>
        </FormControl>
      );
    } else if (motors.length > 1) {
      return (
        <FormControl>
          <RadioGroup
            onChange={(
              _: React.ChangeEvent<HTMLInputElement>,
              value: string,
            ) => {
              setSelectedAnalytics([value]);
            }}
          >
            {data.map((item) => (
              <FormControlLabel
                key={`radio-${item}`}
                control={<Radio />}
                value={item}
                label={humanizeString(item)}
              />
            ))}
          </RadioGroup>
        </FormControl>
      );
    } else {
      return <div>{translate("motors.no_motors_selected")}</div>;
    }
  };

  return (
    <Card sx={{ width: 320, height: 400, overflowY: "auto" }}>
      <Typography variant="h6">{translate("variable_other")}</Typography>
      {renderSelection()}
    </Card>
  );
};
