import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { useTimeSelection } from "@/store/useTimeSelection";
import { useState } from "react";
import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { renderToString } from "react-dom/server";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";
import { useMachineContext } from "@/context/machine-context";
import { fileNameFactory } from "@/utils/fileNameFactory";
import { AlarmData } from "../api/useGetAlarmsColumnData";

export function AlarmColumns({ alarmData }: { alarmData: AlarmData[] }) {
  const [sortBy, setSortBy] = useState<"count" | "duration">("count");
  const translate = useTranslate();

  const { machine } = useMachineContext();
  const machineName = machine?.machine;

  const { timeSelection } = useTimeSelection();

  const data = alarmData.sort((a, b) => {
    if (sortBy === "count") {
      return b.Count - a.Count;
    } else {
      return b.Duration - a.Duration;
    }
  });

  const alarmCount = data.map((x) => ({
    y: x.CountPercentage,
    custom: {
      duration: TimeHelpers.parseDurationToString({
        duration: x.Duration * 1000,
      }),
      mttr: TimeHelpers.parseDurationToString({ duration: x.MTTR * 1000 }),
      mtbf: TimeHelpers.parseDurationToString({ duration: x.MTBF * 1000 }),
      description: x.ErrorDescription,
      count: x.Count,
    },
  }));
  const alarmDuration = alarmData.map((x) => x.DurationPercentage);
  const categories = alarmData.map((x) => x.Error.toString());

  return (
    <Card>
      <BarChart.Custom
        yAxisOptions={{ max: 100 }}
        showLogarithmicToggle={true}
        categories={categories}
        filename={fileNameFactory({
          machine: machineName,
          name: "alarms",
          date: timeSelection,
        })}
        title={translate("alarms.count_duration_percentage")}
        uom="%"
        legend={{
          enabled: true,
        }}
        tooltip={{
          enabled: true,
          options: {
            useHTML: true,

            formatter: function () {
              /**
               * this is a ts hack to get the custom property working,
               * in this case they would be inside only the first point
               */

              const point = this.points?.[0].point as unknown as {
                custom: {
                  duration: string;
                  mttr: string;
                  mtbf: string;
                  description: string;
                  count: number;
                };
              };

              const count = this.points?.[0];
              const duration = this.points?.[1];

              if (point) {
                return renderToString(
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <b>
                      {translate("count")} %: {count ? count.y?.toFixed(2) : 0}%
                    </b>
                    <b>
                      {translate("count")}: {point.custom.count}
                    </b>
                    <b>
                      {translate("duration_ratio")}: {duration ? duration.y?.toFixed(2) : 0}%
                    </b>
                    <b>
                      {translate("duration")}: {point.custom.duration}
                    </b>
                    <b>
                      {translate("mttr")}: {point.custom.mttr}
                    </b>
                    <b>
                      {translate("mtbf")}: {point.custom.mtbf}
                    </b>
                    <b>
                      {translate("description")}: {point.custom.description}
                    </b>
                  </div>,
                );
              }
            },
          },
        }}
        series={[
          {
            type: "column",
            name: translate("count"),
            data: alarmCount,
            color: "#F07802",
          },
          {
            type: "column",
            name: translate("duration"),
            data: alarmDuration,
            color: "#F4C604",
          },
        ]}
      />
      <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label">{translate("sort_by")}</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value as "count" | "duration")}
        >
          <FormControlLabel value="count" control={<Radio />} label={translate("count")} />
          <FormControlLabel value="duration" control={<Radio />} label={translate("duration")} />
        </RadioGroup>
      </FormControl>
    </Card>
  );
}
