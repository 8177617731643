import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { TimeSelection } from "@/store/useTimeSelection";
import { QueryBuilder } from "@/utils/query-builder";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

export const LineProducts = z.object({
  timelapse: z.string(),
  good: z.union([z.number(), z.object({ y: z.number(), color: z.string() })]),
  wastes: z.number(),
});
export type LineProducts = z.infer<typeof LineProducts>;

export const LastProductionsResult = z.object({
  response: z.literal(true),
  products: z.array(LineProducts),
  averageProducts: z.number(),
});
export type LastProductionsResult = z.infer<typeof LastProductionsResult>;

type LastProductionsResponse = ThingworxError | LastProductionsResult;

type Payload =
  | {
      line_id: number;
      config: "manual";
    }
  | {
      line_id: number;
      config: "automatic";
      timeSelection: TimeSelection;
    };

export const useGetLastProductions = ({
  timeSelection,
  line_id,
  config,
}: {
  timeSelection: TimeSelection;
  line_id: number;
  config: "automatic" | "manual";
}) => {
  const { appKey } = useFirebaseContext();
  const queryKey = QueryBuilder.buildWithCondition({
    baseQuery: ["line-last-productions", line_id, config],
    condition: config === "automatic",
    query: [timeSelection],
  });

  const payload: Payload =
    config === "automatic"
      ? {
          config,
          timeSelection,
          line_id,
        }
      : {
          config,
          line_id,
        };

  return useQuery<LastProductionsResult>({
    queryKey,
    queryFn: async () => {
      const response = await FetchClient<Payload, LastProductionsResponse>({
        appKey,
        payload,
        url: "dig.c.plantOverview_thing/Services/getLastProductions",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = LastProductionsResult.parse(response);
      return validatedResponse;
    },
    refetchInterval: 1000 * 20,
  });
};
