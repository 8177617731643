import { SelectChartType } from "../components/create-chart-steps/SelectChartType";
import { SelectVariables } from "../components/create-chart-steps/SelectVariables";
import { Step, useCreateChartContext } from "./create-chart-context";

const renderStep = (step: Step) => {
  switch (step) {
    case "select chart type":
      return <SelectChartType />;
    case "select variables":
      return <SelectVariables />;
  }

  step satisfies unknown;
};

export const CreateChartStepController = () => {
  const { step } = useCreateChartContext();

  return <>{renderStep(step)}</>;
};
