import { SelectTimeSelection } from "@/components/SelectTimeSelection";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { Stack } from "@mui/material";
import { MaterialsDetailedList } from "./components/MaterialsDetailedList";
import { MaterialsList } from "./components/MaterialsList";

export const Materials = () => {
  return (
    <>
      <MachineViewNavBar>
        <SelectTimeSelection />
      </MachineViewNavBar>
      <Stack gap={2}>
        <MaterialsDetailedList />
        <MaterialsList />
      </Stack>
    </>
  );
};
