import { useFirebaseContext } from "@/context/firebase-context";
import { useQuery } from "@tanstack/react-query";
import { Batch } from "../types";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { FetchClient } from "@/services/ApiClient";

export type SubphaseResponse = ThingworxError | SubphaseResult;

const Asset = z.object({
  asset_name: z.string(),
  asset_id: z.number(),
  asset_chart_type: z.enum(["linechart", "barchart"]),
});

export type Asset = z.infer<typeof Asset>;

const AssetComparisonSubphases = z.object({
  subphase_name: z.string(),
  assets: z.array(Asset),
});

export type AssetComparisonSubphases = z.infer<typeof AssetComparisonSubphases>;

const SubphaseResult = z.object({
  response: z.literal(true),
  data: z.array(AssetComparisonSubphases),
});
type SubphaseResult = z.infer<typeof SubphaseResult>;

type Payload = {
  phase_id: number;
  batch_ids: number[];
};

export const useGetAssetComparisonSubphases = ({
  phase_id,
  batches,
}: {
  phase_id: number;
  batches: Batch[];
}) => {
  const { appKey } = useFirebaseContext();

  const batchIds = batches.map((batch) => batch.batchId).sort((a, b) => a - b);

  return useQuery<AssetComparisonSubphases[]>({
    queryKey: ["asset-comparison", "subphases", phase_id, batchIds],
    queryFn: async () => {
      const response = await FetchClient<Payload, SubphaseResponse>({
        appKey,
        url: "dig.c.lyophilizer_thing/Services/getSubPhasesInfo",
        payload: {
          phase_id,
          batch_ids: batchIds,
        },
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = SubphaseResult.parse(response);

      return validatedResponse.data;
    },
  });
};
