import { Typography, Box } from "@mui/material";
import { Card } from "./Layout/Card";
import { useTranslate } from "@/i18n/config";

export const ShowEmptyData = ({ title }: { title: string }) => {
  const translate = useTranslate();

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h5">{title}</Typography>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">
          {translate("user_feedback.no_data_to_display")}
        </Typography>
      </Box>
    </Card>
  );
};
