import { create } from "zustand";
import { ONE_SECOND } from "@/utils/durationsInMilliseconds";

export const SENTRY_MODE_MESSAGES = [
  "Increased Alarm Frequency: The overall frequency of alarms on machine 79060C0 has increased in the last hour. Warning level: medium.",
  "Restart condition: The overall alarm frequency for machine 79060C0 is in line with the usual pattern.",
  "Increased Repair Time: The mean time to repair (MTTR) for alarms for machine 79260C0 has increased in the last 30 minutes. Warning level: high.",
  "Restart condition: The MTTR for machine 79260C0 is in line with the standard.",
  "Higher Alarm Activations: The number of activations for alarm 2301 on machine 79060C0 has been higher than usual in the last hour. Warning level: low.",
  "Restart condition: The number of activations for alarm 2301 for machine 79060C0 is below the threshold.",
  "Rising Alarm Frequency: The frequency of alarms on machine 79260C0 has risen by 15% compared to the weekly average in the last half hour. Warning level: high.",
  "Restart condition: The alarms frequency for machine 79260C0 is now below the threshold.",
  "Decreasing Time Between Failures: The mean time between failures (MTBF) for alarms has decreased significantly in the last hour. Warning level: medium.",
  "Restart condition: The MTBF for machine 79060C0 is back in line with the weekly standard.",
  "Machine 79260C0: The OEE of machine 79260C0 has been progressively decreasing during the last hour. Warning level: high.",
  "Restart condition: The OEE of machine 79260C0 is again in line with the expected standard.",
  "Machine 79060C0: The OEE of machine 79060C0 experienced a drop in the last 10 minutes. Warning level: low.",
  "Restart condition: The OEE of machine 79060C0 is in line with the weekly average.",
  "Machine 79260C0: The efficiency of machine 79260C0 has fallen below the expected threshold in the past two hours. Warning level: medium.",
  "Restart condition: The efficiency of machine 79260C0 is back above the threshold.",
  "Machine 79060C0: The performance of machine 79060C0 has decreased over the last 30 minutes. Warning level: medium.",
  "Restart condition: The performance of machine 79060C0 is back in line with the daily standard.",
  "Machine 79060C0: The efficiency of machine 79060C0 dropped by 15% during the last hour. Warning level: low.",
  "Restart condition: The availability of machine 79060C0 is again in line with the standard.",
  "Machine 79060C0: The number of anomalies detected in machine 79060C0 is above average in the last hour. Warning level: medium.",
  "Restart condition: The number of anomalies for machine 79060C0 is currently in line with the weekly average.",
  "Machine 79260C0: Machine 79260C0 has been disconnected for more than 5 minutes. Warning level: low.",
  "Restart condition: Machine 79260C0 is reconnected.",
  "Machine 79060C0: During the last 3 hours, machine 79060C0 was in the 'Error' state 10 times. Warning level: high.",
  "Restart condition: Machine 79060C0 had no “Error” states in the past hour.",
  "Machine 79060C0: The number of anomalous events for machine 79060C0 has increased by 25% in the last hour. Warning level: medium.",
  "Restart condition: The number of anomalies for machine 79060C0 has returned below the default threshold.",
  "Machine 79260C0: An increase in anomalies has been detected in machine 79260C0 during the last 30 minutes. Warning level: low.",
  "Restart condition: The number of anomalies for machine 79260C0 is in line with the daily standard.",
];

export const SENTRY_MODE_TIMER_DURATION_S = 300;
export const SENTRY_MODE_TIMER_BETWEEN_MESSAGES_S = 150;

type SentryModeStore = {
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
  secondsLeft: number;
  resetTimer: () => void;
  clearTimer: () => void;
};

export const sentryModeStore = create<SentryModeStore>((set) => {
  let timerRef: NodeJS.Timeout | null = null;

  const clearTimer = () => {
    if (timerRef) {
      clearInterval(timerRef);
      timerRef = null;
    }
  };

  const resetTimer = () => {
    clearTimer();
    set({ secondsLeft: SENTRY_MODE_TIMER_DURATION_S, isActive: false });
    timerRef = setInterval(() => {
      set((state) => ({ secondsLeft: state.secondsLeft - 1 }));
    }, ONE_SECOND);
  };

  return {
    isActive: false,
    setIsActive: (isActive) => set({ isActive }),
    secondsLeft: SENTRY_MODE_TIMER_DURATION_S,
    resetTimer,
    clearTimer,
  };
});
