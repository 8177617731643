import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { StateChangedInfo } from "../types";

interface Payload {
  machineName: string;
  customizedStates: StateChangedInfo[];
}
export const useSetConfiguration = () => {
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      states,
    }: {
      states: {
        id: number;
        isClientRelevance: boolean;
      }[];
    }) => {
      await FetchClient<Payload, null>({
        appKey,
        payload: { machineName: machine!.machine, customizedStates: states },
        url: "dig.c.configurableOee_thing/services/setCustomEfficiencyConfiguration",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["configurable-oee", machine?.machine],
      });
    },
  });
};
