import { useTranslate } from "@/i18n/config";
import { BasicTable } from "@/components/BasicTable";
import { Card } from "@/components/Layout/Card";
import { TableParser } from "@/utils/TableParser";
import { StateLogDataEntry } from "../api/useGetData";
import { useFormatTimestamp } from "@/utils/TimeHelpers";

export const StatesLogTable = ({
  data,
  extremes,
}: {
  data: StateLogDataEntry[];
  extremes: { min: number; max: number } | null;
}) => {
  const translate = useTranslate();
  const { formatTimestamp } = useFormatTimestamp();
  const filteredData = extremes
    ? data.filter(
        (occurrence) =>
          occurrence.start >= extremes.min && occurrence.end <= extremes.max,
      )
    : data;
  const { columns, rows } =
    filteredData.length > 0
      ? TableParser.parseData<StateLogDataEntry>({
          data: filteredData,
          columnsMap: {
            name: translate("state"),
            start: translate("start"),
            end: translate("end"),
            duration: translate("duration"),
            color: "State Color",
            percentage: translate("math.percentage"),
          },
          durationFormat: "hh:mm:ss.SSS",
          columnsOrder: [
            "start",
            "end",
            "name",
            "duration",
            "percentage",
            "color",
          ],
          omitColumns: ["color"],
          dateColumns: ["start", "end"],
          dateFormatter: formatTimestamp,
        })
      : {
          columns: [],
          rows: [],
        };

  return (
    <Card>
      <BasicTable columns={columns} rows={rows} fileName="state_log_data" />
    </Card>
  );
};
