import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Card } from "@/components/Layout/Card";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { useTranslate } from "@/i18n/config";

interface SearchBarProps {
  machines: string[];
  machine: string;
  setMachine: Dispatch<SetStateAction<string>>;
  setQuery: Dispatch<SetStateAction<string>>;
  setShowPersonal: Dispatch<SetStateAction<boolean>>;
}

export const SearchBar = ({
  machines,
  machine,
  setMachine,
  setQuery,
  setShowPersonal,
}: SearchBarProps) => {
  const [searchValue, setSearchValue] = useState("");
  const translate = useTranslate();

  useEffect(() => {
    const queryInputTimeout = setTimeout(() => {
      setQuery(searchValue);
    }, 500);
    return () => clearTimeout(queryInputTimeout);
  }, [searchValue]);

  const handleChangeMachine = (event: SelectChangeEvent) => {
    const machine = event.target.value as string;
    setMachine(machine);
  };

  return (
    <Card
      sx={{
        display: "flex",
        gap: 2,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box minWidth={280}>
        <FormControl fullWidth>
          <InputLabel id="select-machine-label">
            {translate("machine")}
          </InputLabel>
          <Select
            labelId="select-machine-label"
            id="select-machine"
            value={machine}
            label={translate("machine")}
            onChange={handleChangeMachine}
          >
            <MenuItem value="---">---</MenuItem>
            {machines.map((machine) => (
              <MenuItem key={machine} value={machine}>
                {machine}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <TextField
        id="search"
        label={translate("actions.search")}
        type="search"
        fullWidth
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox onChange={(e) => setShowPersonal(e.target.checked)} />
          }
          label={translate("events.show_personal")}
          sx={{ whiteSpace: "nowrap" }}
        />
      </FormGroup>
    </Card>
  );
};
