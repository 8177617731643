import {
  PropsWithChildren,
  createContext,
  useContext,
  useReducer,
} from "react";
import type { ChartType, Variable } from "../types/variable";

export type Step = "select chart type" | "select variables";

type ContextState = {
  step: Step;
  chart_type: ChartType | null;
  machine: string | null;
  chart_title: string;
  variables: Variable[];
};

const CreateChartContext = createContext<
  | (ContextState & {
      closeModal: () => void;
    })
  | null
>(null);

type Action =
  | {
      type: "set chart type";
      chart_type: ChartType;
      machine: string;
    }
  | {
      type: "set chart title";
      title: string;
    }
  | {
      type: "pick variables";
      variables: Variable[];
    }
  | {
      type: "back";
    };

const reducer = (state: ContextState, action: Action): ContextState => {
  switch (action.type) {
    case "set chart type":
      return {
        ...state,
        chart_type: action.chart_type,
        machine: action.machine,
        step: "select variables",
      };
    case "pick variables":
      return {
        ...state,
        variables: action.variables,
      };
    case "set chart title":
      return {
        ...state,
        chart_title: action.title,
      };
    case "back":
      if (state.step === "select variables") {
        return {
          ...state,
          step: "select chart type",
          machine: null,
          chart_type: null,
          variables: [],
        };
      }
      return state;

    default:
      return state;
  }

  action.type satisfies unknown;
};

const CreateChartDispatch = createContext<React.Dispatch<Action> | null>(null);

export const useCreateChartContext = () => {
  const context = useContext(CreateChartContext);
  if (!context) {
    throw new Error("useModalContext must be used within a ModalProvider");
  }
  return context;
};

export const useCreateChartDispatch = () => {
  const context = useContext(CreateChartDispatch);
  if (!context) {
    throw new Error("useModalContext must be used within a ModalProvider");
  }
  return context;
};

export const CreateChartContextProvider = ({
  closeModal,
  children,
}: PropsWithChildren<{
  closeModal: () => void;
}>) => {
  const [state, dispatch] = useReducer(reducer, {
    step: "select chart type",
    chart_type: null,
    machine: null,
    chart_title: "",
    variables: [],
  } satisfies ContextState);

  return (
    <CreateChartContext.Provider value={{ ...state, closeModal }}>
      <CreateChartDispatch.Provider value={dispatch}>
        {children}
      </CreateChartDispatch.Provider>
    </CreateChartContext.Provider>
  );
};
