import { HighchartsCustomProvider } from "@/components/highcharts/highcharts";
import {
  HighchartsChart,
  Title,
  XAxis,
  YAxis,
  BarSeries,
  Chart,
  Tooltip,
  Legend,
} from "react-jsx-highcharts";

interface Props {
  uom: string;
  title?: string | undefined;
  categories?: string[] | undefined;
  series?: Highcharts.SeriesBarOptions[];
  yAxisTitleOptions?: Highcharts.YAxisTitleOptions;
  legend?:
    | { enabled: false }
    | { enabled: true; options?: Partial<Highcharts.LegendOptions> };
  tooltip?:
    | { enabled: false }
    | { enabled: true; options?: Partial<Highcharts.TooltipOptions> };
  zooming?: Highcharts.ChartZoomingOptions;
  yAxisOptions?: Highcharts.YAxisOptions;
  height?: number;
  filename?: string;
}

export const StackedBarchart = ({
  title,
  categories,
  uom,
  series = [],
  yAxisTitleOptions,
  tooltip = { enabled: false },
  legend = { enabled: false },
  zooming = { type: "x" },
  yAxisOptions = {},
  height = 300,
  filename,
}: Props) => {
  return (
    <HighchartsCustomProvider>
      <HighchartsChart
        exporting={{
          filename,
        }}
        accessibility={{ enabled: false }}
        plotOptions={{
          bar: { stacking: "normal" },
        }}
        chart={{
          height: height,
        }}
      >
        <Chart
          backgroundColor={"transparent"}
          type="bar"
          zooming={zooming}
          style={{
            color: "white",
          }}
          height={height}
        />
        <Title style={{ color: "white" }} align="left">
          {title}
        </Title>
        {tooltip.enabled && <Tooltip useHTML {...tooltip.options} />}
        {legend.enabled && (
          <Legend
            itemStyle={{
              color: "white",
            }}
            {...legend.options}
          ></Legend>
        )}
        <XAxis
          labels={{
            style: {
              color: "white",
              /* test - aggiunto questa chiave perche in alcuni chart la label troppo lunga prende troppo spazio */
              whiteSpace: "nowrap",
            },
          }}
          type="category"
          categories={categories}
        ></XAxis>
        <YAxis
          min={0}
          labels={{ style: { color: "white" }, format: "{value} " + uom }}
          showEmpty
          title={yAxisTitleOptions}
          tickmarkPlacement="on"
          {...yAxisOptions}
        >
          {series.map((serie, i) => (
            <BarSeries key={i} {...serie} borderColor="none" />
          ))}
        </YAxis>
      </HighchartsChart>
    </HighchartsCustomProvider>
  );
};
