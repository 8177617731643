import { Stack } from "@mui/material";
import { Card } from "./Card";
import { SentinelLoader } from "./SentinelLoader";

export function LoaderCard({ text = "Loading ...." }: { text?: string }) {
  return (
    <Card>
      <Stack
        alignItems="center"
        sx={{ width: "100%", height: "100%" }}
        justifyContent="center"
        gap={4}
        flex={1}
        flexDirection="column"
      >
        <SentinelLoader />
        <span style={{ display: "block" }}>{text}</span>
      </Stack>
    </Card>
  );
}
