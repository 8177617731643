import { z } from "zod";

export const Operator = z.union([
  z.literal("Above"),
  z.literal("Below"),
  z.literal("EqualTo"),
  z.literal("NotEqualTo"),
  z.literal("InRange"),
  z.literal("OutOfRange"),
]);

export type Operator = z.infer<typeof Operator>;

export const PropertyType = z.union([
  z.literal("String"),
  z.literal("number"),
  z.literal("boolean"),
  z.literal("timestamp"),
]);

export type PropertyType = z.infer<typeof PropertyType>;

export const EventValues = z.object({
  value_1: z.number(),
  value_2: z.number().optional(),
});

export type EventValues = z.infer<typeof EventValues>;

export const ConditionConfig = z.object({
  alertType: Operator,
  //? da vedere se lasciare opzionale
  cumulative: z
    .boolean()
    .optional()
    .transform((val) => val ?? false),
  duration: z.number(),
  errorString: z.literal("No error"),
  //? da vedere se lasciare opzionale
  isActiveDuration: z
    .boolean()
    .optional()
    .transform((val) => val ?? false),
  name: z.string(),
  property: z.string(),
  propertyNameClient: z.string(),
  propertyType: PropertyType,
  rate: z.number(),
  response: z.literal(true),
  uom: z.array(z.string()),
  values: EventValues,
});

export type ConditionConfig = z.infer<typeof ConditionConfig>;

export const Condition = z.object({
  machine: z.string(),
  property: z.string(),
  propertyNameClient: z.string(),
  propertyType: PropertyType,
  operator: Operator,
  value1: z.number(),
  value2: z.optional(z.number()),
  isCumulative: z.boolean(),
  isActiveDuration: z.boolean(),
  duration: z.number(),
  uom: z.string(),
  rate: z.number(),
});

export type Condition = z.infer<typeof Condition>;

export const ConditionEdit = z.object({
  machine: z.string(),
  name: z.string(),
  property: z.string(),
  propertyNameClient: z.string(),
  propertyType: PropertyType,
  operator: Operator,
  value1: z.number(),
  value2: z.optional(z.number()),
  isCumulative: z.boolean(),
  isActiveDuration: z.boolean(),
  duration: z.number(),
  uom: z.string(),
  rate: z.number(),
});

export type ConditionEdit = z.infer<typeof ConditionEdit>;

export const TimeRange = z.union([
  z.literal("minutes"),
  z.literal("hours"),
  z.literal("days"),
  z.literal("weeks"),
]);

export type TimeRange = z.infer<typeof TimeRange>;

export const RuleEngineJson = z.object({
  account: z.string(),
  ruleName: z.string(),
  ruleDescription: z.string(),
  machine: z.string(),
  priority: z.number(),
  data: z.array(ConditionConfig),
  timerange: z.number(),
  isEnabled: z.boolean(),
  mailList: z.array(z.string()),
  recapTime: z.number(),
  eventLooping: z.boolean(),
});

export type RuleEngineJson = z.infer<typeof RuleEngineJson>;

export const CreateUpdateConditionResult = z.object({
  name: z.string(),
  alertType: Operator,
  property: z.string(),
  propertyNameClient: z.string(),
  propertyType: PropertyType,
  isActiveDuration: z.boolean(),
  cumulative: z.boolean(),
  uom: z.array(z.string()),
  values: EventValues,
  rate: z.number(),
  duration: z.number(),
  response: z.literal(true),
  errorString: z.string(),
});

export type CreateUpdateConditionResult = z.infer<
  typeof CreateUpdateConditionResult
>;
