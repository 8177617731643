import { BASE_VERTICAL_DIMENSION } from "@/features/OverallLineEfficiency/config";
import { Stack, Chip, Box } from "@mui/material";

export const PlaceholderMachineNode = ({ model }: { model: string }) => {
  return (
    <Stack
      height={BASE_VERTICAL_DIMENSION}
      gap={2}
      sx={{
        fontSize: ".8rem",
        backgroundColor: "#222",
        padding: ".5rem",
        borderRadius: "4px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          fontWeight: "bold",
        }}
      >
        <Chip
          sx={{ color: "inherit", fontWeight: "bold" }}
          size="small"
          label={model}
        />
      </Box>
    </Stack>
  );
};
