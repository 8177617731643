import { SummaryScatter } from "../components/Summary/SummaryScatter";
// import SummaryTable from "../components/Summary/SummaryTable";
// import { SummaryGenericBarChart } from "../components/Summary/SummaryGenericBarChart";

export const SummaryGeneric = () => {
  return (
    <>
      <SummaryScatter />
      {/* <SummaryTable />
      <SummaryGenericBarChart /> */}
    </>
  );
};
