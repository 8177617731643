import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { QueryBuilder } from "@/utils/query-builder";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

export const HistoricTimeFrame = z.object({
  dateStart: z.number(),
  dateEnd: z.number(),
  id: z.number(),
  timeLapse: z.string(),
});

export type HistoricTimeFrame = z.infer<typeof HistoricTimeFrame>;

const HistoricListResult = z.object({
  response: z.literal(true),
  data: z.array(HistoricTimeFrame),
});

type HistoricListResult = z.infer<typeof HistoricListResult>;

type HistoricListResponse = ThingworxError | HistoricListResult;

type Payload =
  | {
      id: number;
      timeSelection: TimeSelection;
      dateStart: number;
      dateEnd: number;
      config: "automatic";
    }
  | {
      id: number;
      dateStart: number;
      dateEnd: number;
      config: "manual";
    };

export const useGetHistoricLineList = ({
  id,
  config,
}: {
  id: number;
  config: "automatic" | "manual";
}) => {
  const { appKey } = useFirebaseContext();

  const { timeSelection, dates } = useTimeSelection();

  const query = QueryBuilder.buildWithCondition({
    baseQuery: [
      "historic-line-list",
      id,
      config,
      dates.dateStart,
      dates.dateEnd,
    ],
    condition: config === "automatic",
    query: [timeSelection],
  });

  const payload: Payload =
    config === "automatic"
      ? {
          id,
          timeSelection,
          dateStart: +dates.dateStart,
          dateEnd: +dates.dateEnd,
          config,
        }
      : {
          id,
          dateStart: +dates.dateStart,
          dateEnd: +dates.dateEnd,
          config,
        };

  return useQuery<HistoricListResult>({
    queryKey: query,
    queryFn: async () => {
      const response = await FetchClient<Payload, HistoricListResponse>({
        url: "dig.c.plantOverview_thing/Services/getHistoricLineList",
        appKey,
        payload,
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return HistoricListResult.parse(response);
    },
  });
};
