import { colors } from "@/styles/colors";
import { Skeleton } from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";

/**
 * @deprecated use components/Layout/Card instead
 */
export const AnalyticContainerLayout = styled(motion.div)(() => ({
  backgroundColor: colors.palette.darkBlue,
  color: "#fff",
  width: "100%",
  backgroundImage: "none",
  padding: "1rem",
  boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  minHeight: "400px",
  borderRadius: ".25rem",
}));

export const AnalyticContainerSkeleton = styled(Skeleton)(() => ({
  backgroundColor: colors.palette.darkBlue,
  color: "#fff",
  width: "100%",
  backgroundImage: "none",
  padding: "1rem",
  boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  minHeight: "400px",
  borderRadius: ".25rem",
  transform: "none",
}));

export const SmallAnalyticContainerLayout = styled(motion.div)(() => ({
  backgroundColor: colors.palette.darkBlue,
  color: "#fff",
  width: "100%",
  backgroundImage: "none",
  padding: "1rem",
  boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  borderRadius: ".25rem",
}));
