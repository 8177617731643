import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { useQuery } from "@tanstack/react-query";
import { FetchClient } from "@/services/ApiClient";
import { ThingworxError } from "src/types/error";
import { DateTime } from "luxon";
import { useTimeSelection, TimeSelection } from "@/store/useTimeSelection";
import { z } from "zod";
import { Shift } from "../../types";
import { QueryBuilder } from "@/utils/query-builder";

export type KpiProcessResponse = ThingworxError | ThingworxResultKPI;

/**
 * type definitions used as the shape in which
 * the data that comes from the API needs to be transformed.
 * This problems arises because the API returns the data in a different shape, with keys that have blank spaces.
 *
 * @todo refactor thingworx API to return data in a more usable shape so that we can remove this transformation step
 */

const KpiSelectorData = z.object({
  ProcessOee: z.array(z.array(z.number())),
  ProcessQuality: z.array(z.array(z.number())),
});

type KpiSelectorData = z.infer<typeof KpiSelectorData>;

const ResultKPI = z.object({
  data: z.array(KpiSelectorData),
  chartName: z.string(),
  configuration: z.string(),
  response: z.literal(true),
  shiftList: z.array(Shift),
  requestProcessingTime: z.number(),
});

type ResultKPI = z.infer<typeof ResultKPI>;

/**
 * Type definitions for data that comes from the API.
 */

const ThingworKpiSelectorData = z.object({
  "Process oee": z.array(z.array(z.number())),
  "Process quality": z.array(z.array(z.number())),
});

type ThingworKpiSelectorData = z.infer<typeof ThingworKpiSelectorData>;

const ThingworxResultKPI = z.object({
  data: z.array(ThingworKpiSelectorData),
  chartName: z.string(),
  configuration: z.string(),
  response: z.literal(true),
  shiftList: z.array(Shift),
  requestProcessingTime: z.number(),
});

type ThingworxResultKPI = z.infer<typeof ThingworxResultKPI>;

type Payload = {
  machineName: string;
  timeSelection: TimeSelection;
  dateStart?: DateTime;
  dateEnd?: DateTime;
};

export const useGetKpiStreamSelectorProcess = () => {
  const { timeSelection, dates } = useTimeSelection();
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();

  const queryKey = QueryBuilder.buildWithCondition({
    baseQuery: [
      "machinePerformance-sentinel-KpiSelectorProcess",
      timeSelection,
      machine?.machine,
    ],
    condition: timeSelection === "Custom",
    query: [dates.dateStart, dates.dateEnd],
  });

  const payload =
    timeSelection === "Custom"
      ? {
          machineName: machine?.machine || "",
          dateStart: dates.dateStart,
          dateEnd: dates.dateEnd,
          timeSelection: timeSelection,
        }
      : {
          machineName: machine?.machine || "",
          timeSelection: timeSelection,
        };

  return useQuery<ResultKPI>({
    queryKey,
    staleTime: 0,
    queryFn: async () => {
      const response = await FetchClient<Payload, KpiProcessResponse>({
        appKey,
        payload,
        url: "dig.c.machinePerformanceProcess_thing/Services/getKpiStream",
      });

      if (!response.response) throw new Error(response.errorString);
      const validatedResponse = ThingworxResultKPI.parse(response);

      return {
        chartName: validatedResponse.chartName,
        configuration: validatedResponse.configuration,
        response: validatedResponse.response,
        shiftList: validatedResponse.shiftList,
        requestProcessingTime: validatedResponse.requestProcessingTime,
        data: validatedResponse.data.map((value) => ({
          ProcessOee: value["Process oee"],
          ProcessQuality: value["Process quality"],
        })),
      };
    },
    refetchInterval: timeSelection === "Custom" ? false : 1000 * 20,
  });
};
