import { useTranslate } from "@/i18n/config";
import { StateInfo } from "../api/useGetConfigurableOeeData";
import { Views } from "../types";
import { CardsContainer, NoStates } from "./Layout/CardsContainer";
import { StateInfoLayout } from "./StateInfo";

export const StatesDetailContainer = ({
  view,
  statesList,
}: {
  view: Views;
  statesList: StateInfo[];
}) => {
  const states =
    view === "configured"
      ? statesList.filter((s) => s.isClientRelevance != s.isEfficiencyRelevance)
      : view === "notConfigured"
      ? statesList.filter(
          (s) => s.isClientRelevance === s.isEfficiencyRelevance,
        )
      : statesList;

  const translate = useTranslate();

  return (
    <CardsContainer>
      {states.length === 0 && (
        <NoStates content={translate("configurable_oee.no_state_configured")} />
      )}
      {states.map((state) => (
        <StateInfoLayout
          key={state.id}
          id={state.id}
          stateName={state.stateName}
          duration={state.duration}
          oeeGain={state.oeeGain}
          performanceGain={state.performanceGain}
          availabilityGain={state.availabilityGain}
          isClientRelevance={state.isClientRelevance}
          isEfficiencyRelevance={state.isEfficiencyRelevance}
        />
      ))}
    </CardsContainer>
  );
};
