import { Card } from "@/components/Layout/Card";
import { ScatterPlot } from "@/components/highcharts/scatter/Scatter";
import { AnalyticContainerSkeleton } from "@/features/Diagnostic/pages/LYO/components/AnalyticContainer/AnalyticContainerLayout";
import {
  FrameData,
  useGetDataSummaryGeneric,
} from "../../api/useGetDataSummaryGeneric";
import { formatStatus } from "../../utils/formatStatus";
import { mapColor } from "../../utils/mapColor";
import { mapStatus } from "../../utils/mapStatus";
import { Typography } from "@mui/material";
import { useTranslate } from "@/i18n/config";

interface Result {
  x: number;
  y: number;
  color: string;
  marker: Highcharts.PointMarkerOptionsObject;
}
[];

const processData = (
  data: FrameData[],
): {
  xCategories: string[];
  yCategories: string[];
  scatterData: Result[];
} => {
  const xCategories: string[] = [];
  const yCategories: string[] = [];
  const scatterData: Result[] = [];

  const sortedData = data.sort((a, b) => a.frameId - b.frameId);
  sortedData.forEach((item) => {
    const { frameName, motorName, status, timestampStart } = item;
    const xCategory = `Overall ${frameName} - ${new Date(
      timestampStart,
    ).toLocaleString()}`;
    if (!xCategories.includes(xCategory)) {
      xCategories.push(xCategory);
    }
    if (!yCategories.includes(motorName)) {
      yCategories.push(motorName);
    }
    const x = xCategories.indexOf(xCategory);
    const y = yCategories.indexOf(motorName);
    scatterData.push({
      x,
      y,
      color: mapStatus(status),
      marker: { radius: 6 },
    });
  });

  return { xCategories, yCategories, scatterData };
};

export const SummaryScatter = () => {
  const { data, isLoading, error } = useGetDataSummaryGeneric();
  const translate = useTranslate();

  if (isLoading) {
    return <AnalyticContainerSkeleton />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }

  const { xCategories, yCategories, scatterData } = processData(data.frameData);

  return (
    <Card>
      <Typography>
        {translate("motors.motors_summary_overall_frame")}
      </Typography>
      <ScatterPlot.Custom
        title=""
        yAxis={[
          {
            uom: "",
            series: [
              {
                type: "scatter",
                data: scatterData,
              },
            ],
            options: {
              categories: yCategories,
            },
          },
        ]}
        xAxisOptions={{ categories: xCategories }}
        scrollbarEnabled={false}
        tooltip={{
          enabled: true,
          options: {
            formatter: function () {
              const point = this as unknown as Result;
              return `
                ${translate("frame")}: <b>${point.x}</b><br>
                ${translate("motor")}: <b>${yCategories[point.y]}</b><br>
                ${translate("evaluation")}: <b>${formatStatus(
                  mapColor(point.color),
                )}</b>
              `;
            },
          },
        }}
      />
    </Card>
  );
};
