import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { IntellectaError } from "../types";
import { intellectaClient } from "./intellectaClient";

type Response = IntellectaError | Result;

type Payload = {
  user: string;
  chat_id: number;
  title: string;
};

const result = z.literal("OK");

type Result = z.infer<typeof result>;

export const useRenameHistoricChat = () => {
  const queryClient = useQueryClient();
  const { user } = useFirebaseContext();
  const email = user?.email || "";

  return useMutation({
    mutationFn: async ({
      chat_id,
      title,
    }: {
      chat_id: number;
      title: string;
    }) => {
      const response = await intellectaClient<Payload, Response>({
        url: "/user/historic/rename_chat",
        payload: { user: email, chat_id, title },
      });

      const validatedResponse = result.parse(response);
      return validatedResponse;
    },
    onSettled: (data) => {
      if (data === "OK") {
        toast.success("Chat renamed successfully");
        //* per scaricare di nuovo lo storico delle chat
        queryClient.invalidateQueries({
          queryKey: ["intellecta-historic", email],
        });
      } else {
        toast.error("An error occurred");
      }
    },
  });
};
