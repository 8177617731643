import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import * as z from "zod";

type TwTurnResponse = TwTurnConfiguration | ThingworxError;

export const DayShiftConfiguration = z.object({
  MinuteStart: z.number(),
  MinuteEnd: z.number(),
  HourStart: z.number(),
  HourEnd: z.number(),
});

export type TWeek = z.infer<typeof DayShiftConfiguration>;

export const TwTurnConfigurationSchema = z.object({
  nextWeek: z.record(z.string(), z.array(DayShiftConfiguration)),
  currentWeek: z.record(z.string(), z.array(DayShiftConfiguration)),
  StartWeek: z.number(),
  response: z.literal(true),
});

export type TwTurnConfiguration = z.infer<typeof TwTurnConfigurationSchema>;

type Payload = {
  machineName: string;
};

export const useTurnConfiguration = () => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();

  return useQuery<TwTurnConfiguration>({
    queryKey: [
      "machineSettings-sentinel-getTurnConfiguration",
      machine?.machine || "",
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, TwTurnResponse>({
        appKey,
        payload: {
          machineName: machine?.machine || "",
        },
        url: "dig.c.shift_thing/Services/getTurnConfiguration",
      });

      if (!response.response) throw new Error(response.errorString);
      const validatedResponse = TwTurnConfigurationSchema.parse(response);
      return validatedResponse;
    },
  });
};
