import { SERVER_URL } from "../config";

export const FetchClient = async <Input, Output>({
  appKey,
  payload,
  url = "",
}: {
  appKey: string;
  payload: Input;
  url: string;
}): Promise<Output> => {
  const response = await fetch(SERVER_URL + url, {
    method: "POST",
    headers: {
      appkey: appKey,
      "content-type": "application/json",
      accept: "application/json",
      "x-thingworx-session": "true",
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) throw new Error("Something went wrong!");

  return response.json() as Promise<Output>;
};
