import { useState } from "react";
import { Box, Divider, List, Stack, useTheme } from "@mui/material";
import { Nav } from "../../../../components/Layout/Navigations/Nav";
import { useMachineContext } from "../../../../context/machine-context";
import { Drawer } from "../../../../components/Layout/Drawer";
import { PersonalizableHeader } from "@/components/Layout/PersonalizableHeader/PersonalizableHeader";
import { Monitoring } from "@/components/Layout/Navigations/Monitoring";
import { baseDefaultPages } from "../../../../constants/sentinelNavigations";

export const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const { palette } = useTheme();

  return (
    <Drawer
      variant="permanent"
      open={open}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      sx={{
        boxShadow:
          "0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);",
        border: "none",
      }}
    >
      <Stack
        sx={{
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <PersonalizableHeader open={open} />
          <Divider
            variant="middle"
            sx={{ borderColor: palette.text.secondary }}
          />

          <List disablePadding>
            <BasePages open={open} />
            <Monitoring open={open} />
          </List>
        </Box>
      </Stack>
    </Drawer>
  );
};

function BasePages({ open }: { open: boolean }) {
  const { basePages } = useMachineContext();

  return (
    <>
      {baseDefaultPages.map((page) => (
        <Nav key={page.to} {...page} open={open} />
      ))}

      {basePages.map((page) => (
        <Nav key={page.to} {...page} open={open} />
      ))}
    </>
  );
}
