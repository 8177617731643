import { useState } from "react";
import { useTranslate } from "@/i18n/config";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { Tab, Tabs } from "@mui/material";
import { Summary } from "./pages/Summary";
import { Monitoring } from "./pages/Monitoring";
import { Diagnostic } from "./pages/Diagnostic";
import { DateTimeRangePicker } from "@/components/Calendars/DateTimeRangePicker";
import {
  AcoposContextProvider,
  useAcoposContext,
} from "./context/acopos-context";
import { SystemHealthStatus } from "./components/Summary/HealthStatus/SystemHealthStatus";
import { HelpDialogButton } from "./components/HelpDialog/HelpDialogButton";

const SECTIONS = {
  SUMMARY: 0,
  MONITORING: 1,
  DIAGNOSTIC: 2,
};

export const AcoposTrak = () => {
  return (
    <AcoposContextProvider>
      <PageContent />
    </AcoposContextProvider>
  );
};

const PageContent = () => {
  const [section, setSection] = useState(SECTIONS.SUMMARY);
  const translate = useTranslate();
  const { maxDaysCalendar } = useAcoposContext();

  return (
    <>
      <MachineViewNavBar>
        <DateTimeRangePicker maxDays={maxDaysCalendar} />
        <SystemHealthStatus />
        <HelpDialogButton />
      </MachineViewNavBar>
      <Tabs
        value={section}
        aria-label="acopostrak tabs"
        onChange={(_: React.SyntheticEvent, newValue: number) => {
          setSection(newValue);
        }}
      >
        <Tab value={SECTIONS.SUMMARY} label={translate("summary")} />
        <Tab value={SECTIONS.MONITORING} label={translate("monitoring")} />
        <Tab value={SECTIONS.DIAGNOSTIC} label={translate("diagnostic")} />
      </Tabs>
      {section === SECTIONS.SUMMARY && <Summary />}
      {section === SECTIONS.MONITORING && <Monitoring />}
      {section === SECTIONS.DIAGNOSTIC && <Diagnostic />}
    </>
  );
};
