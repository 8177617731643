import { AnomaliesData } from "../api/getAnomaliesData";
import { BasicTable } from "@/components/BasicTable";
import { useTranslate } from "@/i18n/config";
import { TableParser } from "@/utils/TableParser";
import { Card } from "@/components/Layout/Card";
import { useFormatTimestamp } from "@/utils/TimeHelpers";
import { GridRenderCellParams } from "@mui/x-data-grid-premium";
import { Box } from "@mui/material";

export function AnomaliesTable({
  data,
  extremes,
}: {
  data: AnomaliesData[];
  extremes: { min: number; max: number } | null;
}) {
  const translate = useTranslate();
  const { formatTimestamp } = useFormatTimestamp();

  const parsedData = data.map((entry) => ({
    is_resolved: entry.is_resolved,
    ts_start: entry.ts_start,
    ts_end: entry.ts_end,
    reason: entry.reason,
    duration: entry.duration,
    threshold: entry.time_threshold,
  }));

  const filteredData = extremes
    ? parsedData.filter(
        (occurrence) =>
          occurrence.ts_start >= extremes.min &&
          occurrence.ts_end <= extremes.max,
      )
    : parsedData;

  const { rows, columns } =
    filteredData.length > 0
      ? TableParser.parseData({
          data: filteredData,
          columnsMap: {
            is_resolved: translate("anomalies.solved_anomalies"),
            duration: translate("duration"),
            reason: translate("anomalies.reason"),
            ts_end: translate("end"),
            ts_start: translate("start"),
            threshold: translate("anomalies.watchdog_anomaly_threshold"),
          },
          columnsOrder: [
            "ts_start",
            "ts_end",
            "duration",
            "reason",
            "is_resolved",
            "threshold",
          ],
          dateColumns: ["ts_start", "ts_end"],
          durationFormat: "hh:mm:ss",
          dateFormatter: formatTimestamp,
        })
      : { rows: [], columns: [] };

  const anomaliesColumnDef = columns.findIndex(
    (item) => item.field === "is_resolved",
  );

  if (anomaliesColumnDef !== -1) {
    columns[anomaliesColumnDef].renderCell = (params: GridRenderCellParams) => (
      <Box
        sx={{
          width: "24px",
          height: "8px",
          backgroundColor: params.row.is_resolved ? "#00b00c" : "#D62422",
          borderRadius: "5px",
        }}
      ></Box>
    );
  }

  return (
    <Card>
      <BasicTable columns={columns} rows={rows} />
    </Card>
  );
}
