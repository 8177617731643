import { useFirebaseContext } from "@/context/firebase-context";
import { useQuery } from "@tanstack/react-query";
import { Phases, SubphaseData } from "../types";
import { FetchClient } from "@/services/ApiClient";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type PhaseDataResponse = ThingworxError | PhaseDataResult;

const PhaseDataResult = z.object({
  response: z.literal(true),
  data: z.array(SubphaseData),
});

type PhaseDataResult = z.infer<typeof PhaseDataResult>;

const MACHINE_NAME = "Demo";

type Payload = {
  batch_id: number;
  batch_phase_id: number;
  machineName: string;
};

export const useGetPhaseData = ({
  phase_id,
  batch_id,
}: {
  phase_id: number;
  phaseName: Phases;
  batch_id: number;
}) => {
  const { appKey } = useFirebaseContext();

  return useQuery<PhaseDataResult>({
    queryKey: ["phase-data", phase_id, batch_id],
    queryFn: async () => {
      const response = await FetchClient<Payload, PhaseDataResponse>({
        appKey,
        url: "dig.c.lyophilizer_thing/Services/getSubphases",
        payload: {
          batch_id,
          batch_phase_id: phase_id,
          machineName: MACHINE_NAME,
        },
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      return PhaseDataResult.parse(response);
    },

    refetchInterval: 1000 * 30,
  });
};
