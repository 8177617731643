import { BarChart } from "@/components/highcharts/barchart/Barchart";
import { Gant } from "@/components/highcharts/gant/Gant";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { PhaseData } from "../../api/useGetHistoricData";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLyoContext } from "../../context/lyo-context";
import { colors } from "@/styles/colors";
import { BreakagesTable } from "../LongTermKpi/BreakagesTable";
import { Card } from "@/components/Layout/Card";
import { useTranslate } from "@/i18n/config";

function Legend({ text, color }: { text: string; color: string }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
    >
      <Box sx={{ fontSize: "12px" }}>{text}</Box>
      <Box
        sx={{
          backgroundColor: color,
          aspectRatio: "1/1",
          width: ".75rem",
          height: ".75rem",
          borderRadius: "10000px",
        }}
      />
    </Stack>
  );
}

export const HistoricContainer = ({
  ganttData,
  categories,
}: {
  ganttData: PhaseData[];
  categories: string[];
}) => {
  const translate = useTranslate();
  const barChartData = ganttData.reduce(
    (acc, curr) => {
      const index = acc.findIndex((item) => item.name === curr.name);

      if (index === -1) {
        acc.push({ ...curr, y: curr.duration });
      } else {
        acc[index].y += curr.duration;
      }
      return acc;
    },
    [] as { y: number; name: string }[],
  );

  const [{ filteredBatches }] = useLyoContext();

  return (
    <Stack spacing={2}>
      <Card
        initial={{ opacity: 0.5, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
      >
        <Gant
          data={ganttData}
          categories={categories}
          title="Phase analytics results"
          plotBands={filteredBatches?.map((batch) => ({
            borderColor: "#ffffff10",
            from: +new Date(batch.from),
            to: +new Date(batch.to),
            color: "#ffffff00",
            label: {
              text: batch.batchName,

              style: {
                fontSize: "12px",
              },
            },
            zIndex: 5,
            borderWidth: 1,
          }))}
        />
        <Accordion sx={{ width: "fit-content" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{translate("show_legend")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Stack spacing={2} sx={{ width: "fit-content" }}>
                <Legend
                  text={translate("good")}
                  color={colors.lyo.status.good}
                />
                <Legend text={translate("bad")} color={colors.lyo.status.bad} />
                <Legend
                  text={translate(
                    "diagnostics.lyo.not_analyzed_or_missing_values",
                  )}
                  color={colors.lyo.status.not_analyzable}
                />
                <Legend
                  text={translate("diagnostics.lyo.not_analyzable")}
                  color={colors.lyo.status.not_analyzed}
                />
              </Stack>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Card>
      <BreakagesTable />
      <Card
        initial={{ opacity: 0.5, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
      >
        <BarChart.DurationBarchart
          title={translate("diagnostics.lyo.overall_phases_duration")}
          data={barChartData}
          categories={categories}
          uom="hh:mm:ss"
        />
      </Card>
    </Stack>
  );
};
