import { useContext } from "react";
import { LineEditorContext } from "./LineEditorContextProvider";

export const useLineEditorContext = () => {
  const context = useContext(LineEditorContext);
  if (context === null) {
    throw new Error(
      "useLineEditorContext must be used within a LineEditorContextProvider",
    );
  }
  return context;
};
