/* eslint-disable @typescript-eslint/ban-ts-comment */
import { HighchartsCustomProvider } from "../highcharts";
import HighchartsReactOfficial from "highcharts-react-official";
import * as Highcharts from "highcharts";
import addHeatmap from "highcharts/modules/heatmap";

addHeatmap(Highcharts);

import {
  HighchartsChart,
  Chart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Title,
  Subtitle,
  HeatmapSeries,
} from "react-jsx-highcharts";

export class Heatmap {
  static Custom({
    title,
    subtitle,
    yAxis,
    tooltip = { enabled: true },
    legend = { enabled: false },
    xAxisOptions,
    exportingEnabled = true,
    colorAxis = {
      stops: [
        [0, "#3060cf"],
        [0.5, "#ffe5bc"],
        [1, "#c4463a"],
      ],
      min: 0,
      max: 1,
      startOnTick: false,
      endOnTick: false,
    },
  }: {
    title: string;
    subtitle?: string;
    yAxis: {
      options?: Highcharts.YAxisOptions;
      series?: Highcharts.SeriesHeatmapOptions[];
      yAxisTitleOptions?: Highcharts.YAxisTitleOptions;
      thresholds?: {
        value: number;
        color: string;
        text: string;
      }[];
    };
    legend?:
      | { enabled: false }
      | { enabled: true; options?: Partial<Highcharts.LegendOptions> };
    tooltip?:
      | { enabled: false }
      | { enabled: true; options?: Partial<Highcharts.TooltipOptions> };
    xAxisType?: Highcharts.AxisTypeValue;
    xAxisOptions?: Highcharts.XAxisOptions;
    exportingEnabled?: boolean;
    colorAxis?: Highcharts.ColorAxisOptions | Highcharts.ColorAxisOptions[];
  }) {
    return (
      <HighchartsCustomProvider>
        <HighchartsChart
          exporting={{
            enabled: exportingEnabled,
          }}
          colorAxis={colorAxis}
        >
          <Chart
            backgroundColor={"transparent"}
            style={{
              color: "white",
            }}
          />
          <Title style={{ color: "white" }} align="left">
            {title}
          </Title>
          {subtitle ? (
            <Subtitle
              style={{
                color: "white",
              }}
              align="left"
            >
              {subtitle}
            </Subtitle>
          ) : null}
          {tooltip.enabled && <Tooltip shared useHTML {...tooltip.options} />}

          {legend.enabled && (
            <Legend
              itemStyle={{
                color: "white",
              }}
              {...legend.options}
            ></Legend>
          )}
          <XAxis {...xAxisOptions}></XAxis>

          <YAxis title={yAxis.yAxisTitleOptions} {...yAxis.options} reversed>
            {yAxis.series?.map((serie, i) => (
              <HeatmapSeries key={i} {...serie} />
            ))}
          </YAxis>
        </HighchartsChart>
      </HighchartsCustomProvider>
    );
  }

  static Demo({
    subtitle,
    data,
  }: {
    title: string;
    subtitle?: string;
    data: unknown[];
  }) {
    return (
      <HighchartsReactOfficial
        highcharts={Highcharts}
        options={{
          chart: {
            type: "heatmap",
            plotBorderWidth: 0,
            backgroundColor: "transparent",
            style: {
              fontFamily: "Montserrat, Helvetica Neue,Arial, sans-serif",
              color: "white",
            },
          },
          title: {
            text: "Self Organizing Map",
            align: "left",
            style: {
              color: "white",
            },
          },
          subtitle: {
            text: subtitle,
            align: "left",
            style: {
              color: "white",
            },
          },
          yAxis: {
            title: null,
            reversed: true, // true
          },
          colorAxis: {
            stops: [
              [0, "#3060cf"],
              [0.5, "#ffe5bc"],
              [1, "#c4463a"],
            ],
            min: 0,
            max: 1,
            startOnTick: false,
            endOnTick: false,
          },
          legend: {
            align: "right",
            layout: "vertical",
            margin: 0,
            verticalAlign: "top",
            y: 25,
            symbolHeight: 280,
          },
          tooltip: {
            // @ts-ignore
            formatter: function () {
              // @ts-ignore
              return this.point.value;
            },
          },
          series: [
            {
              name: "SOM Results",
              //marker: {
              //	symbol: 'square'
              //},
              borderWidth: 0,
              borderColor: "#FFFFFF",
              data: data,
              dataLabels: {
                enabled: false,
                color: "#000000",
                formatter: function (): string {
                  // @ts-ignore
                  return this.point.value.toFixed(2);
                },
                style: {
                  textOutline: "0px",
                },
              },
            },
          ],
          credits: {
            enabled: false,
          },
          exporting: {
            enabled: false,
          },
        }}
      />
    );
  }
  static ForecastAccuracy() {
    return (
      <HighchartsReactOfficial
        highcharts={Highcharts}
        options={{
          chart: {
            type: "heatmap",
            marginTop: 40,
            marginBottom: 80,
            plotBorderWidth: 1,
            backgroundColor: "transparent",
            style: {
              fontFamily: "Montserrat, Helvetica Neue,Arial, sans-serif",
              color: "white",
            },
          },

          title: {
            text: "Forecast Accuracy",
            style: {
              color: "white",
            },
          },

          xAxis: {
            categories: ["M3410", "M3420", "M3430", "M3440"],
            labels: {
              style: {
                color: "white",
              },
            },
            title: {
              enabled: true,
              text: "Predicted label",
              style: {
                color: "white",
              },
            },
          },

          yAxis: {
            categories: ["M3410", "M3420", "M3430", "M3440"],
            labels: {
              style: {
                color: "white",
              },
            },
            title: {
              enabled: true,
              text: "True label",
              style: {
                color: "white",
              },
            },
            reversed: true,
          },

          colorAxis: {
            min: 0,
            max: 100,
            stops: [
              [0.0, "#350380"], // violet
              [0.25, "#007FFF"], // azure
              [0.75, "#80c900"], // lime
              [0.95, "#ffd000"], // yellow
            ],
          },

          legend: {
            align: "right",
            layout: "vertical",
            margin: 0,
            verticalAlign: "top",
            y: 25,
            symbolHeight: 280,
          },

          series: [
            {
              name: "Accuracy",
              borderWidth: 1,
              data: [
                [0, 0, 91],
                [0, 1, 2],
                [0, 2, 2],
                [0, 3, 5],
                [1, 0, 0],
                [1, 1, 4],
                [1, 2, 1],
                [1, 3, 0],
                [2, 0, 1],
                [2, 1, 1],
                [2, 2, 54],
                [2, 3, 0],
                [3, 0, 1],
                [3, 1, 1],
                [3, 2, 1],
                [3, 3, 13],
              ],
              dataLabels: {
                enabled: true,
                style: {
                  textOutline: "0px",
                },
              },
            },
          ],

          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 500,
                },
                chartOptions: {
                  yAxis: {
                    labels: {
                      // @ts-ignore
                      formatter: function () {
                        // @ts-ignore
                        return this.value.charAt(0);
                      },
                    },
                  },
                },
              },
            ],
          },
          exporting: {
            enabled: false,
          },
          credits: {
            enabled: false,
          },
        }}
      />
    );
  }
}
