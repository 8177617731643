import { useFirebaseContext } from "@/context/firebase-context";
import { gCloudClient } from "@/services/gCloudClient";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { ThingworxError } from "src/types/error";
import { z } from "zod";
import { SERVER_ENUMERATOR } from "../config";
import { updatePassword } from "firebase/auth";
import { useState } from "react";
import { ref, set } from "firebase/database";
import { db } from "@/lib/sentinel-app";

type Payload = {
  userName: string;
  server: number;
};

const Result = z.object({
  keyID: z.string(),
  response: z.literal(true),
});

type Result = z.infer<typeof Result>;

type Response = ThingworxError | Result;

export const useChangePassword = () => {
  const { user, updateAppKey } = useFirebaseContext();
  const [sessionExpired, setSessionExpired] = useState(false);
  const mutation = useMutation({
    mutationFn: async ({ newPassword }: { newPassword: string }) => {
      if (!user) throw new Error("User not found");

      const response = await gCloudClient<Payload, Response>({
        data: {
          server: SERVER_ENUMERATOR,
          userName: user?.email ?? "",
        },
        service: "RENEW_APP_KEY",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validation = Result.safeParse(response);

      if (!validation.success) {
        throw new Error("Invalid response from server");
      }

      const { keyID } = validation.data;
      updateAppKey(keyID);

      // const { email } = user;
      // const password = await prompt("Please enter your password");
      // reauthenticateWithCredential(
      //   user,
      //   firebase.auth.EmailAuthProvider.credential(
      //     email as string,
      //     password as string
      //   )
      // );

      await updatePassword(user, newPassword).catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          setSessionExpired(true);
          throw new Error("Session expired");
        }
      });

      return;
    },

    onSuccess: async () => {
      if (!user) return;

      const uid = user.uid;

      await set(ref(db, `/users/${uid}/lastChange`), Date.now());

      toast.success("Password changed successfully");
    },
    onError: () => {
      toast.error("Error changing password");
    },
  });

  return { ...mutation, sessionExpired };

  // here we can save the new key in the state
};
