import { createContext, useContext, PropsWithChildren } from "react";
import { useImmerReducer } from "use-immer";
import { Handles } from "../types";

type NodeEditorConfigState = {
  handles: Handles;
};

type Action =
  | {
      type: "set edge type as source";
      handle: keyof NodeEditorConfigState["handles"];
    }
  | {
      type: "set edge type as target";
      handle: keyof NodeEditorConfigState["handles"];
    }
  | {
      type: "remove edge";
      handle: keyof NodeEditorConfigState["handles"];
    };

const NodeEditorContext = createContext<NodeEditorConfigState | null>(null);
const NodeEditDispatch = createContext<React.Dispatch<Action> | null>(null);

const reducer = (draft: NodeEditorConfigState, action: Action) => {
  switch (action.type) {
    case "set edge type as source":
      {
        const id = action.handle;
        if (!draft.handles[id]) return;

        draft.handles[id] = { enabled: true, type: "source" };
      }
      break;
    case "set edge type as target":
      {
        const id = action.handle;
        if (!draft.handles[id]) return;

        draft.handles[id] = { enabled: true, type: "target" };
      }
      break;

    case "remove edge": {
      const id = action.handle;
      draft.handles[id] = { enabled: false };
      break;
    }
  }
};

const generateHandles = (): Handles => {
  return [...Array(8)].reduce((acc, _, index) => {
    Object.assign(acc, {
      [index + 1]: {
        enabled: false,
      },
    });
    return acc;
  }, {});
};

export const NodeEditorContextProvider = ({
  children,
  handles = generateHandles(),
}: PropsWithChildren<{ handles?: Handles }>) => {
  const [state, dispatch] = useImmerReducer<NodeEditorConfigState, Action>(
    reducer,
    {
      handles,
    }
  );

  return (
    <NodeEditorContext.Provider value={state}>
      <NodeEditDispatch.Provider value={dispatch}>
        {children}
      </NodeEditDispatch.Provider>
    </NodeEditorContext.Provider>
  );
};

export const useNodeEditorContext = () => {
  const context = useContext(NodeEditorContext);
  if (!context)
    throw new Error(
      "useNodeEditorContext must be used inside a NodeEditorContextProvider"
    );
  return context;
};

export const useNodeEditDispatch = () => {
  const context = useContext(NodeEditDispatch);
  if (!context)
    throw new Error(
      "useNodeEditDispatch must be used inside a NodeEditorContextProvider"
    );
  return context;
};
