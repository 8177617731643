import {
  ChartOptions,
  SeriesPieOptions,
  TooltipOptions,
  TitleOptions,
  SubtitleOptions,
} from "highcharts/highstock";
import {
  HighchartsChart,
  Chart,
  Title,
  Subtitle,
  Tooltip,
  Legend,
  PieSeries,
  HighchartsChartProps,
} from "react-jsx-highcharts";
import { useTranslate } from "@/i18n/config";
import { HighchartsCustomProvider } from "@/components/highcharts/highcharts";

interface DonutChartProps {
  title: string;
  totalValue: number;
  subtitle?: string;
  seriesOptions: SeriesPieOptions;
  chartOptions?: Partial<ChartOptions>;
  highchartsChartOptions?: HighchartsChartProps;
  tooltipOptions?: TooltipOptions;
  titleOptions?: TitleOptions;
  subtitleOptions?: SubtitleOptions;
}

// Estensione di ChartOptions per aggiungere "custom"
interface ExtendedChartOptions extends ChartOptions {
  custom?: {
    label?: Highcharts.SVGElement;
  };
}

export const DonutChart = ({
  title,
  totalValue,
  subtitle,
  seriesOptions,
  chartOptions = {},
  highchartsChartOptions,
  tooltipOptions,
  titleOptions,
  subtitleOptions,
}: DonutChartProps) => {
  const translate = useTranslate();
  const renderCustomLabel = (chart: Highcharts.Chart) => {
    const series = chart.series[0];
    if (
      !series ||
      !("center" in series) ||
      !Array.isArray(series.center) ||
      series.center.length < 3 ||
      typeof series.center[0] !== "number" ||
      typeof series.center[1] !== "number" ||
      typeof series.center[2] !== "number"
    ) {
      return;
    }

    const options = chart.options as ExtendedChartOptions;
    const customLabel =
      options.custom?.label ??
      chart.renderer
        .label(
          `${translate("math.total")}<br/><strong>${totalValue}</strong>`,
          0,
          0,
        ) // Specifica x e y iniziali
        .css({
          color: "#fff",
          textAnchor: "middle",
        })
        .add();

    if (!options.custom) {
      options.custom = { label: customLabel };
    } else {
      options.custom.label = customLabel;
    }

    const x = series.center[0] + chart.plotLeft;
    const y =
      series.center[1] +
      chart.plotTop -
      ((customLabel.attr("height") as number) || 0) / 2;

    customLabel.attr({ x, y });
    customLabel.css({
      fontSize: `${series.center[2] / 12}px`,
    });
  };

  return (
    <HighchartsCustomProvider>
      <HighchartsChart
        accessibility={{ enabled: false }}
        exporting={{
          enabled: false,
        }}
        plotOptions={{
          // series: {
          //   allowPointSelect: true,
          //   cursor: "pointer",
          // },
          pie: {
            dataLabels: {
              style: {
                color: "#fff",
                textOutline: "none",
              },
              distance: 10,
            },
          },
        }}
        {...highchartsChartOptions}
      >
        <Chart
          type="pie"
          backgroundColor="transparent"
          events={{
            render: function () {
              renderCustomLabel(this as Highcharts.Chart);
            },
          }}
          {...chartOptions}
        />
        <Title style={{ color: "white" }} align="left" {...titleOptions}>
          {title}
        </Title>
        {subtitle && <Subtitle {...subtitleOptions}>{subtitle}</Subtitle>}
        <Tooltip {...tooltipOptions} />
        <Legend enabled={false} />
        <PieSeries borderColor="none" innerSize="80%" {...seriesOptions} />
      </HighchartsChart>
    </HighchartsCustomProvider>
  );
};
