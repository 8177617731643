import { AnalyticContainerSkeleton } from "../../LYO/components/AnalyticContainer/AnalyticContainerLayout";
import { Stack } from "@mui/material";
import { ScaleBellCurve } from "./ScaleBellCurve";
import { DoserBellCurve } from "./DoserBellCurve";
import { useGetBellCurveData } from "../api/useGetBellCurveData";
import { sortObjectByKeys } from "@/utils/sortObjectByKeys";
import { useTranslate } from "@/i18n/config";

export const BellCurveAnalyses = () => {
  const { data, isLoading, error } = useGetBellCurveData();
  const translate = useTranslate();

  if (isLoading) {
    return <AnalyticContainerSkeleton />;
  }

  if (error) {
    return <div>{translate("user_feedback.an_error_occurred")}</div>;
  }

  if (!data) {
    return <div>{translate("user_feedback.no_data")}</div>;
  }
  const sortedNetData = {
    bellCurveData: data.netData.bellCurveData.sort((a, b) =>
      a.name.localeCompare(b.name),
    ),
    benchmark: data.netData.benchmark,
  };
  const sortedTareData = {
    bellCurveData: data.tareData.bellCurveData.sort((a, b) =>
      a.name.localeCompare(b.name),
    ),
    benchmark: data.tareData.benchmark,
  };
  const sortedDoserData = sortObjectByKeys(data.doserData);

  return (
    <Stack spacing={2}>
      <ScaleBellCurve
        netData={sortedNetData}
        tareData={sortedTareData}
        modalities={data.modalities}
      />
      <DoserBellCurve data={sortedDoserData} modalities={data.modalities} />
    </Stack>
  );
};
