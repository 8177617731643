import { Piechart } from "@/components/highcharts/piechart/Piechart";
import { Card } from "@/components/Layout/Card";
import { PieData } from "@/features/MachineDetail/api/CyclicLive/useGetSentinelLiveData";
import { useTranslate } from "@/i18n/config";
import { colors } from "@/styles/colors";
import { Box, useMediaQuery } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { ConfigurableGaugeLayout } from "./Layout/ConfigurableGaugeLayout";

export const TopRow = ({
  standardAvailability,
  standardOee,
  pieData,
  customAvailability,
  customOee,
  standardPerformance,
  customPerformance,
}: {
  standardAvailability: number;
  standardOee: number;
  pieData: PieData[];
  customAvailability: number;
  customOee: number;
  standardPerformance: number;
  customPerformance: number;
}) => {
  const extraSmallScreen = useMediaQuery("(max-width:900px)");
  const smallScreen = useMediaQuery("(max-width:1190px)");
  const translate = useTranslate();
  return (
    <Grid2
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      md={12}
    >
      <Grid2
        container
        md={4}
        xs={12}
        justifyContent={"center"}
        marginBottom={extraSmallScreen ? 2 : 0}
        alignItems={"center"}
      >
        <Card
          style={{
            textAlign: "center",
            marginBottom: "1rem",
            borderRadius: "2rem",
          }}
        >
          {translate("configurable_oee.standard")}
        </Card>
        <Grid2
          container
          alignItems={"flex-end"}
          justifyContent={"center"}
          sx={{
            height: extraSmallScreen ? "200px" : null,
            width: extraSmallScreen ? "100%" : null,
          }}
        >
          <Grid2
            alignItems={"center"}
            xs={12}
            md={6}
            style={{ flexBasis: "fit-content" }}
          >
            <ConfigurableGaugeLayout
              dimension={
                extraSmallScreen
                  ? "extralarge"
                  : smallScreen
                  ? "extrasmall"
                  : "small"
              }
              type="percentage"
              min={0}
              max={100}
              color={colors.kpi.performance}
              title={translate("kpi.performance")}
              value={standardPerformance}
            />
          </Grid2>
          <Grid2 xs={6} md={6} style={{ flexBasis: "fit-content" }}>
            <ConfigurableGaugeLayout
              dimension={
                extraSmallScreen
                  ? "extralarge"
                  : smallScreen
                  ? "extrasmall"
                  : "large"
              }
              type="percentage"
              min={0}
              max={100}
              color={colors.kpi.oee}
              title={translate("kpi.oee")}
              value={standardOee}
            />
          </Grid2>
          <Grid2
            alignItems={"center"}
            xs={6}
            md={6}
            style={{ flexBasis: "fit-content" }}
          >
            <ConfigurableGaugeLayout
              dimension={
                extraSmallScreen
                  ? "extralarge"
                  : smallScreen
                  ? "extrasmall"
                  : "small"
              }
              type="percentage"
              min={0}
              max={100}
              color={colors.kpi.availability}
              title={translate("kpi.availability")}
              value={standardAvailability}
            />
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2
        xs={12}
        md={4}
        alignItems="center"
        justifyContent="center"
        order={extraSmallScreen ? 1 : 0}
        sx={{ margin: "-2rem 0" }}
      >
        <Box
          marginBottom={extraSmallScreen ? 4 : 0}
          paddingX={extraSmallScreen ? "18%" : "5%"}
        >
          <Piechart data={pieData} label="" title="" version="machineDetail" />
        </Box>
      </Grid2>

      <Grid2
        container
        md={4}
        xs={12}
        justifyContent={"center"}
        marginBottom={extraSmallScreen ? 4 : 0}
      >
        <Card
          style={{
            textAlign: "center",
            marginBottom: "1rem",
            borderRadius: "2rem",
          }}
        >
          {translate("configurable_oee.custom")}
        </Card>
        <Grid2
          container
          alignItems={"flex-end"}
          justifyContent={"center"}
          sx={{
            height: extraSmallScreen ? "200px" : null,
            width: extraSmallScreen ? "100%" : null,
          }}
        >
          <Grid2
            alignItems={"center"}
            xs={6}
            md={2}
            style={{ flexBasis: "fit-content" }}
          >
            <ConfigurableGaugeLayout
              dimension={
                extraSmallScreen
                  ? "extralarge"
                  : smallScreen
                  ? "extrasmall"
                  : "small"
              }
              type="percentage"
              min={0}
              max={100}
              color={colors.kpi.performance}
              title={translate("kpi.performance")}
              value={customPerformance}
            />
          </Grid2>
          <Grid2 xs={6} md={2} style={{ flexBasis: "fit-content" }}>
            <ConfigurableGaugeLayout
              dimension={
                extraSmallScreen
                  ? "extralarge"
                  : smallScreen
                  ? "extrasmall"
                  : "large"
              }
              type="percentage"
              min={0}
              max={100}
              color={colors.kpi.oee}
              title={translate("kpi.oee")}
              value={customOee}
            />
          </Grid2>
          <Grid2
            alignItems={"center"}
            xs={6}
            md={2}
            style={{ flexBasis: "fit-content" }}
          >
            <ConfigurableGaugeLayout
              dimension={
                extraSmallScreen
                  ? "extralarge"
                  : smallScreen
                  ? "extrasmall"
                  : "small"
              }
              type="percentage"
              min={0}
              max={100}
              color={colors.kpi.availability}
              title={translate("kpi.availability")}
              value={customAvailability}
            />
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};
