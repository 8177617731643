import { gCloudClient } from "@/services/gCloudClient";
import { encode } from "@/utils/b64_encoder";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";

export const useDeleteUser = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async ({ email }: { email: string }) => {
      await gCloudClient<{ email: string }, null>({
        service: "DELETE_USER",
        queryStrings: {
          email: encode(email),
        },
      });
    },
    onSuccess() {
      client.invalidateQueries(["get-colleagues"]);
      toast.success("User deleted");
    },
    onError() {
      toast.error("An error occurred");
    },
  });
};
