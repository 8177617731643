import { useMutation } from "@tanstack/react-query";
import { FetchClient } from "@/services/ApiClient";
import { useFirebaseContext } from "@/context/firebase-context";
import { ThingworxError } from "src/types/error";
import { toast } from "react-hot-toast";
import { useTranslate } from "@/i18n/config";

type Payload = {
  machinesOrder: string[];
};

export const useSetMachinesOrder = () => {
  const translate = useTranslate();
  const { appKey } = useFirebaseContext();

  return useMutation({
    mutationFn: async ({ machinesOrder }: Payload) => {
      return await FetchClient<Payload, { response: true } | ThingworxError>({
        appKey,
        payload: { machinesOrder },
        url: "dig.c.plantOverview_thing/Services/setMachinesOrder",
      });
    },
    onSuccess: (response) => {
      if (!response.response) {
        toast.error(
          translate(
            "overall_line_efficiency.failed_to_update_line_machines_order",
          ),
        );
        return;
      }
      toast.success(translate("overall_line_efficiency.line_machines_updated"));
    },
  });
};
