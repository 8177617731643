import { AlertSchema } from "../api/useGetEventsData/getAlerts";

export const queryAlerts = ({
  alerts,
  query,
}: {
  alerts: AlertSchema[];
  query: string;
}): AlertSchema[] => {
  const queryLower = query.toLowerCase();
  const matchingAlerts = alerts.filter(
    (alert) =>
      alert.account.toLowerCase().includes(queryLower) ||
      alert.machine.toLowerCase().includes(queryLower) ||
      alert.ruleDescription.toLowerCase().includes(queryLower) ||
      alert.ruleName.toLowerCase().includes(queryLower) ||
      alert.data[0].alertType.toLowerCase().includes(query) ||
      alert.data[0].values.value_1.toString().includes(query) ||
      alert.data[0].propertyNameClient.toLowerCase().includes(query)
  );
  return matchingAlerts;
};
