import { useEffect, useState } from "react";
import { ref, get, set } from "firebase/database";
import { db } from "@/lib/sentinel-app";
import { getAuth } from "firebase/auth";
import { environment } from "../config";

type ReleaseMessage = {
  id: string;
  title: string;
  content: string;
  isEnabled: boolean;
  timestamp: number;
};

export const useCheckIfReleaseMessage = (): {
  showMessage: boolean;
  title: string;
  content: string;
  timestamp: number;
  dismissMessage: (doNotShowAgain: boolean) => Promise<void>;
} => {
  const [showMessage, setShowMessage] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [timestamp, setTimestamp] = useState(0);
  const [releaseMessageId, setReleaseMessageId] = useState("");

  useEffect(() => {
    const checkReleaseMessage = async () => {
      if (environment !== "production") return;

      const releaseMessageRef = ref(db, "releaseMessage");
      const snapshot = await get(releaseMessageRef);
      const releaseMessage: ReleaseMessage = snapshot.val();

      if (releaseMessage && releaseMessage.isEnabled) {
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
          // Gestire il caso in cui l'utente non è autenticato
          return;
        }

        const userUID = user.uid;
        const releaseMsgId = releaseMessage.id;
        setReleaseMessageId(releaseMsgId);

        // Controllo se la modale è già stata mostrata in questa sessione
        const hasShownInSession = sessionStorage.getItem(
          `shownReleaseMessage_${releaseMsgId}`,
        );

        if (hasShownInSession) {
          // La modale è già stasta mostrata in questa sessione, non fare nulla
          return;
        }

        const userReleaseMessageRef = ref(
          db,
          `users/${userUID}/hasReadReleaseMessage/${releaseMsgId}`,
        );

        const userSnapshot = await get(userReleaseMessageRef);
        const hasReadCurrentReleaseMessage = userSnapshot.val();

        if (hasReadCurrentReleaseMessage === true) {
          // L'utente ha letto il messaggio, non fare nulla
        } else {
          if (hasReadCurrentReleaseMessage === null) {
            // La chiave non esiste, la creo e la imposto su false
            await set(userReleaseMessageRef, false);
          }
          // Mostro il messaggio all'utente
          setShowMessage(true);
          setTitle(releaseMessage.title);
          setContent(releaseMessage.content);
          setTimestamp(releaseMessage.timestamp);
          // Segno che la modale è stata mostrata in questa sessione
          sessionStorage.setItem(`shownReleaseMessage_${releaseMsgId}`, "true");
        }
      }
    };

    checkReleaseMessage();
  }, []);

  const dismissMessage = async (doNotShowAgain: boolean) => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      // Gestire il caso in cui l'utente non è autenticato
      return;
    }

    const userReleaseMessageRef = ref(
      db,
      `users/${user.uid}/hasReadReleaseMessage/${releaseMessageId}`,
    );

    if (doNotShowAgain) {
      // Imposto il valore a true per indicare che l'utente ha letto il messaggio e non vuole più vederlo
      await set(userReleaseMessageRef, true);
    }
    // Nascondo il messaggio
    setShowMessage(false);
  };

  return {
    showMessage,
    title,
    content,
    timestamp,
    dismissMessage,
  };
};
// import { useEffect, useState } from "react";
// import { ref, onValue, set } from "firebase/database";
// import { db } from "@/lib/sentinel-app";

// type ReleaseMessage = {
//   id: string;
//   title: string;
//   content: string;
//   isEnabled: boolean;
// };

// export const useCheckIfReleaseMessage = (): {
//   showMessage: boolean;
//   title: string;
//   content: string;
// } => {
//   const [showMessage, setShowMessage] = useState(false);
//   const [title, setTitle] = useState("");
//   const [content, setContent] = useState("");

//   useEffect(() => {
//     const releaseMessageRef = ref(db, "releaseMessage");
//     const unsubscribe = onValue(releaseMessageRef, (snapshot) => {
//       const releaseMessage: ReleaseMessage = snapshot.val();
//       if (releaseMessage && releaseMessage.isEnabled) {
//         // Il messaggio è attivo, prendo id del messaggio
//         const releaseMessageId = releaseMessage.id;
//         const userReleaseMessageRef = ref(
//           db,
//           `users/${localStorage.getItem("userUID")}/hasReadReleaseMessage`,
//         );
//         // Scarico config utente
//         onValue(userReleaseMessageRef, (snapshot) => {
//           const data: { [key: string]: string } = snapshot.val();
//           console.log(data);
//           // Controllo se la chiave esiste
//           if (data[releaseMessageId] !== undefined) {
//             // Esiste, capisco se l'utente l'ha letto in base all'id
//             const hasReadCurrentReleaseMessage = data[releaseMessageId];
//             if (hasReadCurrentReleaseMessage) {
//               // L'utente ha letto il messaggio, non devo più mostrarlo
//             } else {
//               // L'utente non ha letto il messaggio
//               setShowMessage(true);
//               setTitle(releaseMessage.title);
//               setContent(releaseMessage.content);
//             }
//           } else {
//             // Se NON esiste la creo e la metto a false
//             set(
//               ref(
//                 db,
//                 `users/${localStorage.getItem(
//                   "userUID",
//                 )}/hasReadReleaseMessage/${releaseMessageId}`,
//               ),
//               false,
//             );
//             // Poi ritorno tutto, perché se non esisteva non l'ha letta
//             setShowMessage(true);
//             setTitle(releaseMessage.title);
//             setContent(releaseMessage.content);
//           }
//         });
//       } else {
//         // Il messaggio non è attivo, non importa fare nulla
//       }
//     });

//     return unsubscribe;
//   }, []);

//   return {
//     showMessage,
//     title,
//     content,
//   };
// };
