import { Box, Button, Stack } from "@mui/material";
import { PropsWithChildren, useCallback, useRef, useState } from "react";
import {
  ReactFlow,
  Controls,
  Background,
  BackgroundVariant,
  Panel,
  ReactFlowProvider,
  ReactFlowInstance,
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
  updateEdge,
} from "reactflow";
import { useDisclosure } from "@/hooks/useDisclosure";
import { NodeEditorModal } from "./components/NodeEditorModal";

import { machineEditNodeTypes } from "./custom-nodes/LineEditorNode";
import { LineEditorContextProvider } from "./context/LineEditorContextProvider";
import { LineConfig } from "./types";
import { useSetLineConfiguration } from "../../api/useSetLineConfiguration";
import { AddOutlined, CancelOutlined, SaveOutlined } from "@mui/icons-material";
import { useOeeLineContextState } from "../LineOverview/context/useOeeLineContextState";
import { useOeeLineContextDispatch } from "../LineOverview/context/useOeeLineContextDispatch";
import { Machine } from "../../api/useGetUserProductionLines";
import { useLineEditorContext } from "./context/useLineEditorContext";
import { useLineEditorDispatch } from "./context/useLineEditorDispatch";
import { useTranslate } from "@/i18n/config";

const checkIfSaveDisabled = ({
  lineMachines,
  nodes,
}: {
  lineMachines: Machine[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nodes: any[];
}): boolean => {
  let isDisabled = false;
  const machinesInLine = lineMachines.map((machine) => machine.name);
  machinesInLine.forEach((machineInLine) => {
    const isConfigured = nodes.find((node) => node.data.name === machineInLine);
    if (isConfigured) {
      // tutto ok
    } else {
      isDisabled = true;
    }
  });
  return isDisabled;
};

export const Edit = ({
  initialConfig = { nodes: [], edges: [] },
}: PropsWithChildren<{
  initialConfig?: Pick<LineConfig, "edges" | "nodes">;
}>) => {
  const { open, isOpen, close } = useDisclosure(false);

  /**
   * todo: enable edit of an already existing line, maybe by having optional props to initialize the provider
   */
  return (
    <LineEditorContextProvider
      initialConfig={{ edges: initialConfig.edges, nodes: initialConfig.nodes }}
    >
      <LineEditor open={open} />
      <NodeEditorModal open={isOpen} onClose={close} close={close} fullWidth />
    </LineEditorContextProvider>
  );
};

const LineEditor = ({ open }: { open: () => void }) => {
  const edgeUpdateSuccessful = useRef(true);
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>();
  const { mutate: saveConfiguration } = useSetLineConfiguration();
  const { nodes, edges } = useLineEditorContext();

  const { selectedLine, machinesInfo } = useOeeLineContextState();

  const lineContextDispatch = useOeeLineContextDispatch();

  const dispatch = useLineEditorDispatch();

  const translate = useTranslate();

  const save = useCallback(() => {
    if (reactFlowInstance && selectedLine) {
      const flow = reactFlowInstance.toObject() as LineConfig;

      saveConfiguration({
        line_configuration: flow,
        line_id: selectedLine.line_id,
        machines_info: machinesInfo,
      });

      lineContextDispatch({ type: "disable edit mode" });
      const currSelectedLineMachines = selectedLine.line_machines;
      const newLineMachines: {
        name: string;
        machine_type: "cyclic" | "process";
        is_enabled: boolean;
        line_layer?: number | undefined;
      }[] = [];
      machinesInfo.forEach((machine) => {
        const previousConfig = currSelectedLineMachines.find(
          (m) => m.name === machine.machine_name,
        );
        if (previousConfig) {
          newLineMachines.push({
            is_enabled: machine.is_enabled,
            name: machine.machine_name,
            machine_type: previousConfig.machine_type,
            line_layer: machine.line_layer,
          });
        }
      });
      lineContextDispatch({
        type: "select line",
        line: { ...selectedLine, line_machines: newLineMachines },
      });
    }
  }, [reactFlowInstance, selectedLine, saveConfiguration, lineContextDispatch, machinesInfo]);

  const isSaveDisabled = checkIfSaveDisabled({
    lineMachines: selectedLine?.line_machines || [],
    nodes,
  });

  return (
    <Box
      sx={{
        height: 600,
      }}
    >
      <ReactFlowProvider>
        <ReactFlow
          defaultEdgeOptions={{
            type: "smoothstep",
            animated: true,
          }}
          nodeTypes={machineEditNodeTypes}
          fitView
          proOptions={{
            hideAttribution: true,
          }}
          snapToGrid
          style={{
            backgroundColor: "rgba(255, 255, 255, .1)",
          }}
          edges={edges}
          onNodesChange={(changes) =>
            dispatch({
              type: "update nodes",
              nodes: applyNodeChanges(changes, nodes),
            })
          }
          onEdgesChange={(changes) => {
            edgeUpdateSuccessful.current = true;
            dispatch({
              type: "update edges",
              edges: applyEdgeChanges(changes, edges),
            });
          }}
          onConnect={(connection) =>
            dispatch({
              type: "connect edge",
              edges: addEdge(connection, edges),
            })
          }
          nodes={nodes}
          nodesDraggable
          onEdgeUpdate={(oldEdge, newConnection) => {
            edgeUpdateSuccessful.current = true;
            dispatch({
              type: "update edges",
              edges: updateEdge(oldEdge, newConnection, edges),
            });
          }}
          onEdgeUpdateStart={() => (edgeUpdateSuccessful.current = false)}
          onEdgeUpdateEnd={(_, edge) => {
            if (!edgeUpdateSuccessful.current) {
              dispatch({ type: "remove edge", edgeId: edge.id });
              edgeUpdateSuccessful.current = true;
            }
          }}
          onInit={setReactFlowInstance}
        >
          <Controls showInteractive={false} />
          <Background lineWidth={1} color="#0000000f" variant={BackgroundVariant.Lines} />
          <Panel position="top-left">
            <Stack direction="row" gap={2}>
              <Button variant="outlined" startIcon={<AddOutlined />} onClick={() => open()}>
                {translate("line.create_node")}
              </Button>
            </Stack>
          </Panel>
          <Panel position="bottom-right">
            <Stack direction="row" gap={2}>
              <Button
                variant="outlined"
                color="error"
                startIcon={<CancelOutlined />}
                onClick={() => lineContextDispatch({ type: "disable edit mode" })}
              >
                {translate("actions.cancel")}
              </Button>
              <Button
                variant="outlined"
                color="success"
                startIcon={<SaveOutlined />}
                onClick={save}
                disabled={isSaveDisabled}
              >
                {translate("actions.save")}
              </Button>
            </Stack>
          </Panel>
        </ReactFlow>
      </ReactFlowProvider>
    </Box>
  );
};
