import { Evaluation } from "../types";

export const mapStatus = (evaluation: Evaluation): string => {
  const colors = {
    good: "#76A12B",
    warning: "#e8c52a",
    critical: "#D62422",
    lack_of_data: "#999999",
    no_data: "#CCCCCC",
    undefined: "#FFFFFF",
  };
  return colors[evaluation];
};
