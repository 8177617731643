import { Box } from "@mui/material";
import { Lap } from "../../types";
import {
  MAX_LAPS_NUMBER,
  useAcoposContext,
} from "../../context/acopos-context";

export const LapSelectionTracker = ({
  totalLaps,
  minVisible,
  maxVisible,
}: {
  totalLaps: Lap[];
  minVisible?: number;
  maxVisible?: number;
}) => {
  const { laps, selectedRun } = useAcoposContext();
  const singleLapWidth = 100 / totalLaps.length;

  if (Object.keys(laps).length === 0) return null;
  if (!selectedRun) return null;

  const sortedTotalLaps = totalLaps.sort((a, b) => a.start - b.start);

  return (
    <Box width="100%" height={10} display="flex">
      {sortedTotalLaps.map((lap) => {
        const { start, end, name } = lap;
        const isLapSelected =
          laps[selectedRun].findIndex(
            (el) => `${el.start}-${el.end}` === `${start}-${end}`
          ) > -1;
        const color = isLapSelected ? "cyan" : "#3f4961";

        const borderStyle = "1px solid #dd0000";
        let borderY: string | undefined = undefined;
        let borderRight: string | undefined = undefined;
        let borderLeft: string | undefined = undefined;
        if (totalLaps.length > MAX_LAPS_NUMBER && minVisible && maxVisible) {
          //? PRIMO
          //* se start del lap è <= minVisible e end del lap è compreso tra minVisible e maxVisible
          //! all'inizio non si vede perché lo start del primo lap è maggiore di minVisible
          if (start <= minVisible && minVisible < end && end < maxVisible) {
            borderLeft = borderStyle;
            borderY = borderStyle;
          }
          //? ULTIMO
          //* se end del lap è >= maxVisible e start del lap è compreso tra minVisible e maxVisible
          if (end >= maxVisible && minVisible < start && start < maxVisible) {
            borderRight = borderStyle;
            borderY = borderStyle;
          }
          //? TUTTI QUELLI NEL MEZZO
          //* start del lap > minVisible e end del lap < maxVisible
          if (start > minVisible && end < maxVisible) {
            borderY = borderStyle;
          }
        }

        return (
          <Box
            key={name}
            width={`${singleLapWidth}%`}
            height={10}
            borderTop={borderY}
            borderBottom={borderY}
            borderLeft={borderLeft}
            borderRight={borderRight}
            bgcolor={color}
          ></Box>
        );
      })}
    </Box>
  );
};
