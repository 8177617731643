import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { ThingworxError } from "src/types/error";
import { Frame } from "../types";
import { ONE_MINUTE } from "@/utils/durationsInMilliseconds";
import { useMotorsContext } from "../context/useMotorsContext";

type DataSummarySpecificResponse =
  | ThingworxError
  | SummarySpecificAnalyticResult;

const SummarySpecificAnalyticData = z.record(z.record(z.number()));

export type SummarySpecificAnalyticData = z.infer<
  typeof SummarySpecificAnalyticData
>;

const SummarySpecificAnalyticResult = z.object({
  response: z.literal(true),
  data: SummarySpecificAnalyticData,
});

export type SummarySpecificAnalyticResult = z.infer<
  typeof SummarySpecificAnalyticResult
>;

interface Payload {
  machineName: string;
  framesList: Frame[];
  motorId: number;
  analytic: string;
}

export const useGetSummarySpecificAnalytics = ({
  motorId,
  analytic,
}: {
  motorId: number;
  analytic: string;
}) => {
  const { machine } = useMachineContext();
  const { frames } = useMotorsContext();
  const { appKey } = useFirebaseContext();

  return useQuery<SummarySpecificAnalyticData>({
    queryKey: [
      "summary-specific-analytics",
      machine?.machine,
      JSON.stringify(frames),
      motorId,
      analytic,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, DataSummarySpecificResponse>({
        appKey,
        payload: {
          machineName: machine?.machine || "",
          framesList: frames,
          motorId,
          analytic,
        },
        url: "dig.c.motors_thing/Services/getSummarySpecificAnalytics",
      });

      if (!response.response) throw new Error(response.errorString);

      const validatedResponse = SummarySpecificAnalyticResult.parse(response);
      return validatedResponse.data;
    },
    refetchInterval: ONE_MINUTE,
  });
};
