import { z } from "zod";

export const evaluation = z.enum([
  "good",
  "warning",
  "critical",
  "lack_of_data",
  "no_data",
  "undefined",
]);

export type Evaluation = z.infer<typeof evaluation>;

export const batchEvaluation = z.enum([
  "good",
  "warning",
  "critical",
  "aborted",
  "undefined",
]);

export type BatchEvaluation = z.infer<typeof batchEvaluation>;

export const phase = z.enum([
  "leak_test",
  "vhp",
  "pre_production",
  "production",
]);

export type Phase = z.infer<typeof phase>;

export const subphase = z.enum([
  "lt_setup", // leak_test
  "inflating",
  "stabilization",
  "test",
  "lt_end", //? non visualizzabile
  "deflating", //! tolte dal db
  "maintenance", //! tolte dal db
  "vhp_setup", // vhp
  "preconditioning",
  "conditioning",
  "decontamination",
  "gen_aeration",
  "prel_aeration",
  "int_aeration",
  "fin_aeration",
  "vhp_abort", //? non visualizzabile
  "manual_setup", // pre_production
  "pre_production",
  "production", // production
]);

export type Subphase = z.infer<typeof subphase>;

export const batch = z.object({
  id: z.number(),
  name: z.string(),
  recipeName: z.string(),
  start: z.number(),
  end: z.number(),
  status: batchEvaluation,
  phases: z.array(phase),
});

export type Batch = z.infer<typeof batch>;

export const chartType = z.enum([
  "pie",
  "histogram",
  "grouped_histogram",
  "stacked_histogram",
  "lines",
  "line_with_area",
  "lines_with_limits",
  "heatmap",
]);

export type ChartType = z.infer<typeof chartType>;

export const phaseData = z.object({
  phase: z.union([phase, subphase]),
  duration: z.number(),
});

export type PhaseData = z.infer<typeof phaseData>;

export const barChartData = z.object({
  benchmark: z.array(phaseData),
  currentBatch: z.array(phaseData),
});

export type BarChartData = z.infer<typeof barChartData>;

export const pieData = z.object({
  id: z.number(),
  name: z.string(),
  evaluation: z.enum(["good", "bad"]),
});

export type PieData = z.infer<typeof pieData>;

export const histogramData = z.object({
  id: z.number(),
  name: z.string(),
  value: z.number(),
  aborted: z.boolean(),
});

export type HistogramData = z.infer<typeof histogramData>;

export const groupedHistogramData = z.object({
  id: z.number(),
  name: z.string(),
  min: z.number(),
  max: z.number(),
});

export type GroupedHistogramData = z.infer<typeof groupedHistogramData>;

export const stackedHistogramData = z.object({
  id: z.number(),
  name: z.string(),
  phaseData: z.array(
    z.object({
      id: z.number(),
      name: subphase,
      duration: z.number(),
    }),
  ),
});

export type StackedHistogramData = z.infer<typeof stackedHistogramData>;

export const lineData = z.object({
  id: z.number(),
  name: z.string(),
  data: z.array(z.array(z.number())),
});

export type LineData = z.infer<typeof lineData>;

export const heatmapData = z.object({
  name: z.string(),
  batches: z.array(
    z.object({
      batchName: z.string(),
      id: z.number(),
      evaluation: batchEvaluation,
      tsStart: z.number(),
    }),
  ),
});

export type HeatmapData = z.infer<typeof heatmapData>;

export const evaluationColors = z.object({
  good: z.object({
    selected: z.string(),
    notSelected: z.string(),
  }),
  warning: z.object({
    selected: z.string(),
    notSelected: z.string(),
  }),
  critical: z.object({
    selected: z.string(),
    notSelected: z.string(),
  }),
  lack_of_data: z.object({
    selected: z.string(),
    notSelected: z.string(),
  }),
  no_data: z.object({
    selected: z.string(),
    notSelected: z.string(),
  }),
  undefined: z.object({
    selected: z.string(),
    notSelected: z.string(),
  }),
  aborted: z.object({
    selected: z.string(),
    notSelected: z.string(),
  }),
});

export type EvaluationColors = z.infer<typeof evaluationColors>;

export const tag = z.object({
  name: z.string(),
  id: z.number(),
  evaluation: evaluation,
});

export type Tag = z.infer<typeof tag>;

export const subphaseEl = z.object({
  name: subphase,
  id: z.number(),
  hasWarn: z.boolean(),
  tagList: z.array(tag),
});

export type SubphaseEl = z.infer<typeof subphaseEl>;

export const phaseEl = z.object({
  name: phase,
  id: z.number(),
  hasWarn: z.boolean(),
  subphaseList: z.array(subphaseEl),
});

export type PhaseEl = z.infer<typeof phaseEl>;

export const batchEl = z.object({
  name: z.string(),
  id: z.number(),
  hasWarn: z.boolean(),
  phaseList: z.array(phaseEl),
});

export type BatchEl = z.infer<typeof batchEl>;

export const analytic = z.object({
  id: z.number(),
  chartName: z.string(),
  chartType: z.string(), //! da definire enum e da capire se fare 'analytic' generico o distinguere in base alle sezioni
  assets: z.array(z.string()),
});

export type Analytic = z.infer<typeof analytic>;

export const phaseAnalytics = z.object({
  phase: phase,
  phaseId: z.number(),
  evaluations: z.object({
    good: z.array(z.string()),
    bad: z.array(z.string()),
    // good: z.number(),
    // bad: z.number(),
  }),
  analytics: z.array(analytic),
});

export type PhaseAnalytics = z.infer<typeof phaseAnalytics>;

export const batchDiagnosticAsset = z.object({
  id: z.number(),
  name: z.string(),
  hasWarn: z.boolean(),
});

export type BatchDiagnosticAsset = z.infer<typeof batchDiagnosticAsset>;

export const batchDiagnosticPhaseAnalytic = z.object({
  analyticName: z.string(),
  id: z.number(),
  hasWarn: z.boolean(),
  assets: z.array(batchDiagnosticAsset),
});

export type BatchDiagnosticPhaseAnalytic = z.infer<
  typeof batchDiagnosticPhaseAnalytic
>;

export const batchDiagnosticAnalytic = z.object({
  name: phase,
  id: z.number(),
  phaseAnalytics: z.array(batchDiagnosticPhaseAnalytic),
});

export type BatchDiagnosticAnalytic = z.infer<typeof batchDiagnosticAnalytic>;

export const contextInfo = z.record(
  z.union([
    z.string(),
    z.number(),
    z.record(z.union([z.string(), z.number()])),
  ]),
);

export type ContextInfo = z.infer<typeof contextInfo>;

export const uom = z.enum([
  "deg",
  "grade",
  "meter",
  "timestamp",
  "pascal",
  "celsius",
  "percentage",
  "ppm",
  "unit",
  "none",
]);

export type Uom = z.infer<typeof uom>;

export const summaryTableData = z.object({
  batchName: z.string(),
  phaseTsStart: z.number(),
  phaseDuration: z.number(),
  phase,
  phaseStatus: evaluation,
  subphase,
  subphaseStatus: evaluation,
});

export type SummaryTableData = z.infer<typeof summaryTableData>;
