import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useCustomTimeRange } from "../store/useCustomTimeRange";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { ThingworxError } from "src/types/error";
import { z } from "zod";

type ViolinPlotResponse = ThingworxError | ViolinPlotResult;

const ViolinPlotData = z.object({
  netData: z.object({
    categories: z.array(z.string()),
    rawData: z.array(z.array(z.number())),
    violinData: z.array(z.array(z.number())),
  }),
  tareData: z.object({
    categories: z.array(z.string()),
    rawData: z.array(z.array(z.number())),
    violinData: z.array(z.array(z.number())),
  }),
});

type ViolinPlotData = z.infer<typeof ViolinPlotData>;

const ViolinPlotResult = z.object({
  response: z.literal(true),
  data: ViolinPlotData,
});

export type ViolinPlotResult = z.infer<typeof ViolinPlotResult>;

interface Payload {
  machineName: string;
  dateStart: DateTime;
  dateEnd: DateTime;
}

export const useGetViolinPlotData = () => {
  const { machine } = useMachineContext();

  const { dateStart, dateEnd } = useCustomTimeRange();

  const { appKey } = useFirebaseContext();

  return useQuery<ViolinPlotData>({
    queryKey: ["violin-plot-weights", machine?.machine, dateStart, dateEnd],
    queryFn: async () => {
      const response = await FetchClient<Payload, ViolinPlotResponse>({
        appKey,
        payload: {
          dateStart,
          dateEnd,
          machineName: machine?.machine || "",
        },
        url: "dig.c.fillerAdvancedAnalytics_thing/Services/getViolinPlotData",
      });

      if (!response.response) throw new Error(response.errorString);

      const validatedResponse = ViolinPlotResult.parse(response);
      return validatedResponse.data;
    },
  });
};
