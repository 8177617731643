import { RecipeIcon } from "@/components/CustomIcons/Recipe";
import { Card } from "@/components/Layout/Card";
import { useDisclosure } from "@/hooks/useDisclosure";
import { InfoOutlined } from "@mui/icons-material";
import { Stack, Divider, Typography, Tooltip, Zoom } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { RecipeModalContent } from "./RecipeModal";
import { useTranslate } from "@/i18n/config";

export const Recipe = ({
  recipe,
  recipeChanged,
  recipeClient,
  recipeContent = [],
  iconSize = 48,
}: {
  recipe: string;
  recipeChanged: boolean;
  recipeClient: string;
  recipeContent?: { [key: string]: unknown }[];
  iconSize?: number;
}) => {
  return (
    <>
      <Card
        sx={{
          height: 100,
        }}
      >
        <Stack justifyContent="center" alignContent="center" height="100%">
          <Grid2 columns={12} container alignItems="center" spacing={1}>
            <Grid2
              flex={1}
              display="flex"
              justifyContent="center"
              alignContent="center"
            >
              <RecipeIcon width={iconSize} height={iconSize} fill="white" />
            </Grid2>
            <RecipeContent
              recipe={recipe}
              recipeChanged={recipeChanged}
              recipeClient={recipeClient}
              recipeContent={recipeContent}
            />
          </Grid2>
        </Stack>
      </Card>
    </>
  );
};

function RecipeContent({
  recipe,
  recipeChanged,
  recipeClient,
  recipeContent = [],
}: {
  recipe: string;
  recipeChanged: boolean;
  recipeClient: string;
  recipeContent?: { [key: string]: unknown }[];
}) {
  const { open, isOpen, close } = useDisclosure();
  const translate = useTranslate();

  return (
    <>
      <Grid2
        flex={3}
        display="flex"
        justifyContent="center"
        alignContent="center"
      >
        <Stack divider={<Divider />} sx={{ textAlign: "center" }}>
          {recipe.length > 28 ? (
            <Tooltip TransitionComponent={Zoom} title={recipe}>
              <Typography
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {`${recipe.slice(0, 24)}...`}
              </Typography>
            </Tooltip>
          ) : (
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {recipe}
            </Typography>
          )}
          <Typography>
            {recipeChanged ? translate("changed") : translate("not_changed")}
          </Typography>
          {recipeClient ? (
            recipeClient.length > 28 ? (
              <Tooltip TransitionComponent={Zoom} title={recipeClient}>
                <Typography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {`${recipeClient.slice(0, 24)}...`}
                </Typography>
              </Tooltip>
            ) : (
              <Typography
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {recipeClient}
              </Typography>
            )
          ) : null}
        </Stack>
      </Grid2>
      <Grid2
        flex={1}
        display="flex"
        justifyContent="center"
        alignContent="center"
      >
        <InfoOutlined
          width={48}
          height={48}
          sx={{ cursor: "pointer" }}
          onClick={() => {
            open();
          }}
        />
      </Grid2>
      <RecipeModalContent
        recipeName={recipe}
        handleClose={() => {
          close();
        }}
        recipeContent={recipeContent}
        openModal={isOpen}
      />
    </>
  );
}
