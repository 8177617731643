import { useFirebaseContext } from "@/context/firebase-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { useLyoContext } from "../context/lyo-context";
import { z } from "zod";

type AnomalyCountOverviewResponse = ThingworxError | AnomalyCountOverviewResult;

const AnomalyCountOverview = z.object({
  recipe: z.string(),
  asset_id: z.number(),
  uom: z.string(),
  batch_name: z.string(),
  total: z.number(),
  anomalies: z.number(),
  asset_name: z.string(),
  from_timestamp: z.number(),
  to_timestamp: z.number(),
  batch_from_timestamp: z.number(),
  batch_to_timestamp: z.number(),
  anomaly_percentage: z.number(),
  name: z.string(),
  display_name: z.string(),
  analytic_base_id: z.number(),
});

export type AnomalyCountOverview = z.infer<typeof AnomalyCountOverview>;

const AnomalyCountOverviewResult = z.object({
  response: z.literal(true),
  data: z.array(AnomalyCountOverview),
});
type AnomalyCountOverviewResult = z.infer<typeof AnomalyCountOverviewResult>;

export const useGetAnomalyDetectionsCountOverview = () => {
  const { appKey } = useFirebaseContext();
  const [{ filteredBatches }] = useLyoContext();

  const batchIds = filteredBatches
    .map((batch) => batch.batchId)
    .sort((a, b) => a - b);

  return useQuery<AnomalyCountOverview[]>({
    queryKey: ["anomaly-detections-count-overview", batchIds],
    queryFn: async () => {
      const response = await FetchClient<
        {
          batch_ids: number[];
        },
        AnomalyCountOverviewResponse
      >({
        payload: {
          batch_ids: batchIds,
        },
        url: "dig.c.lyophilizer_thing/Services/getAnomaliesCountOverview",
        appKey,
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = AnomalyCountOverviewResult.parse(response);
      return validatedResponse.data;
    },
    enabled: !!filteredBatches.length,
    refetchInterval: false,
  });
};
