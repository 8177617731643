import { auth } from "@/lib/sentinel-app";

const BASE_URL = `https://us-central1-dnamap-91d2a.cloudfunctions.net`;

const GCLOUD_ENDPOINTS = {
  RENEW_APP_KEY: { service: "/renewAppKey", method: "POST" },
  DELETE_USER: { service: "/deleteUser", method: "GET" },
  TOGGLE_ENABLE_USER: { service: "/disableUser", method: "POST" },
  CREATE_USER: { service: "/createUserSentinel", method: "POST" },
  SET_GROUP_PERMISSIONS: { service: "/setGroupPermissions", method: "POST" },
  GET_COLLEAGUES: { service: "/getColleagues", method: "POST" },
  UPDATE_ROLE: { service: "/setRole", method: "POST" },
} as const;

export const gCloudClient = async <Input, Output>({
  data,
  service,
  queryStrings,
}: {
  data?: Input;
  service: keyof typeof GCLOUD_ENDPOINTS;
  queryStrings?: Record<string, string>;
}) => {
  const token = await auth.currentUser?.getIdToken();

  const qs = queryStrings ? queryStringBuilder(queryStrings) : "";

  const response = await fetch(
    BASE_URL + GCLOUD_ENDPOINTS[service].service + qs,
    {
      method: GCLOUD_ENDPOINTS[service].method,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data) ?? null,
    }
  );

  if (!response.ok) {
    throw new Error("Error from gCloudClient");
  }

  return response.json() as Promise<Output>;
};

const queryStringBuilder = (params: Record<string, string>) => {
  return "?" + new URLSearchParams(params).toString();
};
