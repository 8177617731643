import { z } from "zod";
import { toast } from "react-hot-toast";
import { useForm, Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  TextField,
  Button,
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { useSetManualConfig } from "../../Pages/LineOverview/api/useSetManualConfig";
import { getMachinesByLayer } from "../../utils/getMachinesByLayer";
import { Line } from "../../api/useGetUserProductionLines";

const MachinebasedConfigSchema = z.object({
  line_output: z.array(z.string()),
  reference_machine: z.array(z.string()),
  production_target: z.number().min(0),
  target_oee: z.number().min(0).max(100),
  reference_rate: z.number().min(0),
  output_rate: z.number().min(0),
});

type MachinebasedConfigSchema = z.infer<typeof MachinebasedConfigSchema>;

export const MachinebasedConfigForm = ({
  line,
  onSubmit,
}: {
  line: Line;
  onSubmit: () => void;
}) => {
  const translate = useTranslate();
  const { mutate: setManualConfig } = useSetManualConfig();

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<MachinebasedConfigSchema>({
    defaultValues: {
      line_output: [],
      reference_machine: [],
    },
  });

  const referenceMachine = watch("reference_machine");
  const outputMachine = watch("line_output");

  const machinesByLayer = getMachinesByLayer(line.line_machines);

  return (
    <form
      onSubmit={handleSubmit((data) => {
        setManualConfig(
          {
            config_type: "machine_based",
            line_id: line.line_id,
            reference_machines_ids: data.reference_machine,
            target_oee: data.target_oee,
            line_outputs_ids: data.line_output,
            production_target: data.production_target,
            output_rate: data.output_rate,
            reference_rate: data.reference_rate,
          },
          {
            onSuccess: () => onSubmit(),
          },
        );
      })}
    >
      <Stack gap={4} p={2}>
        <FormControl fullWidth>
          <InputLabel size="small" id="demo-simple-select-label2">
            {translate("overall_line_efficiency.machine_line_output")}
          </InputLabel>

          <Controller
            name="line_output"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Required",
              },
            }}
            defaultValue={outputMachine}
            render={({ field: { onChange, value, ...rest } }) => (
              <Select
                {...rest}
                multiple
                value={value || []} // Ensure value is an array for multiple selections
                onChange={(event) => {
                  const selectedValues = [...event.target.value].filter(Boolean); // Filtra per evitare undefined
                  // console.log("Selected values (from event):", selectedValues);

                  // Gestisci manualmente l'aggiornamento dello stato
                  onChange(selectedValues);
                }}
                size="small"
                label="Machine line output"
                error={!!errors.line_output}
                renderValue={(selected) => selected.join(", ")} // Show selected items
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300, // Imposta l'altezza massima del menu
                    },
                  },
                  disableScrollLock: true, // Questo evita che la scrollbar del body venga bloccata
                }}
              >
                {Object.keys(machinesByLayer).map((layer, i) => {
                  return (
                    <Box
                      key={`box-${layer}`}
                      // per ovviare strano bug che non permette selezione su primo elemento
                      sx={{ display: i === 0 ? "none" : undefined }}
                    >
                      <Typography key={layer} p={1}>
                        {layer}
                      </Typography>
                      <List component="div" disablePadding>
                        {machinesByLayer[layer].map((machine) => (
                          <ListItem key={layer + machine} disablePadding>
                            <ListItemButton
                              key={machine}
                              onClick={() => {
                                const selectedMachine = machine.trim(); // Trim per sicurezza
                                // console.log(
                                //   "Machine selezionata:",
                                //   selectedMachine,
                                // );
                                // console.log("Valori attuali:", value);

                                if (value.length === 0) {
                                  onChange([...value, selectedMachine]);
                                  // console.log(
                                  //   "Aggiungo macchina:",
                                  //   selectedMachine,
                                  // );
                                } else {
                                  if (value.includes(selectedMachine)) {
                                    onChange(
                                      value.filter((machine) => machine !== selectedMachine),
                                    );
                                    // console.log(
                                    //   "Rimuovo macchina:",
                                    //   selectedMachine,
                                    // );
                                  } else {
                                    const firstSelectedMachine = value[0]?.trim();
                                    const selectedLayerMachine = line.line_machines.find(
                                      (el) => el.name.trim() === firstSelectedMachine,
                                    );
                                    // console.log(
                                    //   "Macchina trovata:",
                                    //   selectedLayerMachine,
                                    // );

                                    if (selectedLayerMachine) {
                                      const selectedLayer = `Layer ${selectedLayerMachine.line_layer}`;
                                      if (selectedLayer === layer) {
                                        onChange([...value, selectedMachine]);
                                        // console.log(
                                        //   "Aggiungo macchina:",
                                        //   selectedMachine,
                                        // );
                                      } else {
                                        toast.error(
                                          "You can only select machines sharing the same line layer",
                                        );
                                        // console.log(
                                        //   "Non puoi selezionare macchine da diversi layer.",
                                        // );
                                      }
                                    }
                                  }
                                }
                              }}
                            >
                              <Checkbox checked={value?.indexOf(machine.trim()) > -1} />
                              <ListItemText primary={machine} />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  );
                })}
              </Select>
            )}
          />
          {errors.line_output ? (
            <FormHelperText error>{errors.line_output.message}</FormHelperText>
          ) : null}
          <FormHelperText>
            {translate("overall_line_efficiency.machine_line_output_description")}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth>
          <Controller
            name="production_target"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Required",
              },
              min: {
                value: 0,
                message: "Can't be less than 0",
              },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <NumericFormat
                  customInput={TextField}
                  size="small"
                  value={value}
                  name="production_target"
                  label={translate("line.production_target")}
                  helperText={errors.production_target && errors.production_target.message}
                  thousandSeparator
                  error={!!errors.production_target}
                  onValueChange={(v) => onChange(Number(v.value))}
                />
              );
            }}
          />
          <FormHelperText>
            {translate("overall_line_efficiency.production_target_description")}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel size="small" id="demo-simple-select-label">
            {translate("overall_line_efficiency.main_machine_reference")}
          </InputLabel>
          <Controller
            name="reference_machine"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Required",
              },
            }}
            defaultValue={referenceMachine}
            render={({ field: { onChange, value, ...rest } }) => (
              <Select
                {...rest}
                multiple
                value={value || []} // Ensure value is an array for multiple selections
                onChange={(event) => {
                  const selectedValues = [...event.target.value].filter(Boolean); // Filtra per evitare undefined
                  // console.log("Selected values (from event):", selectedValues);

                  // Gestisci manualmente l'aggiornamento dello stato
                  onChange(selectedValues);
                }}
                size="small"
                label="Main Machine Reference"
                error={!!errors.reference_machine}
                renderValue={(selected) => selected.join(", ")} // Show selected items
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300, // Imposta l'altezza massima del menu
                    },
                  },
                  disableScrollLock: true, // Questo evita che la scrollbar del body venga bloccata
                }}
              >
                {Object.keys(machinesByLayer).map((layer, i) => {
                  return (
                    <Box
                      key={`box-${layer}`}
                      // per ovviare strano bug che non permette selezione su primo elemento
                      sx={{ display: i === 0 ? "none" : undefined }}
                    >
                      <Typography key={layer} p={1}>
                        {layer}
                      </Typography>
                      <List component="div" disablePadding>
                        {machinesByLayer[layer].map((machine) => (
                          <ListItem key={layer + machine} disablePadding>
                            <ListItemButton
                              key={machine}
                              onClick={() => {
                                const selectedMachine = machine.trim(); // Trim per sicurezza
                                // console.log(
                                //   "Machine selezionata:",
                                //   selectedMachine,
                                // );
                                // console.log("Valori attuali:", value);

                                if (value.length === 0) {
                                  onChange([...value, selectedMachine]);
                                  // console.log(
                                  //   "Aggiungo macchina:",
                                  //   selectedMachine,
                                  // );
                                } else {
                                  if (value.includes(selectedMachine)) {
                                    onChange(
                                      value.filter((machine) => machine !== selectedMachine),
                                    );
                                    // console.log(
                                    //   "Rimuovo macchina:",
                                    //   selectedMachine,
                                    // );
                                  } else {
                                    const firstSelectedMachine = value[0]?.trim();
                                    const selectedLayerMachine = line.line_machines.find(
                                      (el) => el.name.trim() === firstSelectedMachine,
                                    );
                                    // console.log(
                                    //   "Macchina trovata:",
                                    //   selectedLayerMachine,
                                    // );

                                    if (selectedLayerMachine) {
                                      const selectedLayer = `Layer ${selectedLayerMachine.line_layer}`;
                                      if (selectedLayer === layer) {
                                        onChange([...value, selectedMachine]);
                                        // console.log(
                                        //   "Aggiungo macchina:",
                                        //   selectedMachine,
                                        // );
                                      } else {
                                        // console.log(
                                        //   "Non puoi selezionare macchine da diversi layer.",
                                        // );
                                      }
                                    }
                                  }
                                }
                              }}
                            >
                              <Checkbox checked={value?.indexOf(machine.trim()) > -1} />
                              <ListItemText primary={machine} />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  );
                })}
              </Select>
            )}
          />
          {errors.reference_machine ? (
            <FormHelperText error>{errors.reference_machine.message}</FormHelperText>
          ) : null}

          <FormHelperText error={!!errors.reference_machine}>
            {translate("overall_line_efficiency.main_machine_reference_description")}
          </FormHelperText>
        </FormControl>

        <FormControl fullWidth>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Controller
              name="reference_rate"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Required",
                },
                min: {
                  value: 0,
                  message: "Can't be less than 0",
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <NumericFormat
                    customInput={TextField}
                    size="small"
                    fullWidth
                    value={value}
                    name="reference_rate"
                    label={`${translate("line.reference_rate")} ${referenceMachine || ""}`}
                    helperText={errors.reference_rate && errors.reference_rate.message}
                    thousandSeparator
                    error={!!errors.reference_rate}
                    onValueChange={(v) => onChange(Number(v.value))}
                  />
                );
              }}
            />
            <Controller
              name="output_rate"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Required",
                },
                min: {
                  value: 0,
                  message: "Can't be less than 0",
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <NumericFormat
                    customInput={TextField}
                    size="small"
                    fullWidth
                    value={value}
                    name="output_rate"
                    label={`${translate("line.output_rate")} ${outputMachine || ""}`}
                    helperText={errors.output_rate && errors.output_rate.message}
                    thousandSeparator
                    error={!!errors.output_rate}
                    onValueChange={(v) => onChange(Number(v.value))}
                  />
                );
              }}
            />
          </Stack>

          <FormHelperText>
            {translate("overall_line_efficiency.output_rate_description")}
          </FormHelperText>
        </FormControl>

        <FormControl fullWidth>
          <Controller
            name="target_oee"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Required",
              },
              max: {
                value: 100,
                message: "Can't be more than 100",
              },
              min: {
                value: 0,
                message: "Can't be less than 0",
              },
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <NumericFormat
                  customInput={TextField}
                  size="small"
                  value={value}
                  name="target_oee"
                  label={translate("line.target_oee")}
                  helperText={errors.target_oee && errors.target_oee.message}
                  allowNegative={false}
                  max={100}
                  allowedDecimalSeparators={[".", ","]}
                  suffix="%"
                  error={!!errors.target_oee}
                  onValueChange={(v) => onChange(Number(v.value))}
                />
              );
            }}
          />
          <FormHelperText>
            {translate("overall_line_efficiency.target_oee_description")}
          </FormHelperText>
        </FormControl>

        <Stack direction="row" gap={4} justifyContent="end">
          <Button sx={{ alignSelf: "end" }} variant="outlined" onClick={onSubmit}>
            {translate("actions.cancel")}
          </Button>
          <Button sx={{ alignSelf: "end" }} variant="contained" type="submit">
            {translate("actions.save")}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
