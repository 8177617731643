import { useState } from "react";
import { XrangePointOptionsObject } from "highcharts/highstock";
import {
  Box,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { useDisclosure } from "@/hooks/useDisclosure";
import { XRangeChart } from "@/components/highcharts/xrange/XRange";
import { Card } from "@/components/Layout/Card";
import { StateLogDataEntry } from "../api/useGetData";
import { getCustomHeightFromN } from "../utils/getCustomHeightFromN";
import { ErrorStateModal } from "./ErrorStateModal";
import { DATA_BATCH_SIZE, getDataBatches } from "../utils/getDataBatches";

const mapXrangeData = ({
  data,
}: {
  data: StateLogDataEntry[];
}): { categories: string[]; chartData: XrangePointOptionsObject[] } => {
  // const chartData: XrangePointOptionsObject[] = [];

  // data.forEach((occurrence) => {
  //   const index = states.indexOf(occurrence.name);
  //   if (index !== -1) {
  //     chartData.push({
  //       name: occurrence.name,
  //       x: occurrence.start,
  //       x2: occurrence.end,
  //       y: index,
  //       color: occurrence.color,
  //     });
  //   }
  // });

  // return chartData;
  const chartData: XrangePointOptionsObject[] = [];
  const categories: string[] = [];

  data.forEach((occurrence) => {
    if (!categories.includes(occurrence.name)) categories.push(occurrence.name);
  });

  data.forEach((occurrence) => {
    const index = categories.indexOf(occurrence.name);
    if (index !== -1) {
      chartData.push({
        name: occurrence.name,
        x: occurrence.start,
        x2: occurrence.end,
        y: index,
        color: occurrence.color,
      });
    }
  });

  return { categories, chartData };
};

export const StatesLogChart = ({
  data,
  onSetExtremes,
}: {
  data: StateLogDataEntry[];
  onSetExtremes: ({ min, max }: { min: number; max: number }) => void;
}) => {
  const translate = useTranslate();
  const [errorStateTimestamp, setErrorStateTimestamp] = useState<{
    start: number | null;
    end: number | null;
  }>({ start: null, end: null });
  const { open, isOpen, close } = useDisclosure();
  const { categories, chartData } = mapXrangeData({
    data,
  });

  const isBigData = data.length > DATA_BATCH_SIZE;
  const dataBatches = getDataBatches(chartData);

  return isBigData ? (
    <StatesLogChartWithBatches
      dataBatches={dataBatches}
      onSetExtremes={onSetExtremes}
      categories={categories}
    />
  ) : (
    <>
      <Card>
        <Typography>{translate("state_log.machine_states_log")}</Typography>
        {categories.length > 0 ? (
          <XRangeChart
            key={"state-log-chart-" + chartData.length}
            enableStepZoomOut={true}
            categories={categories}
            customHeight={getCustomHeightFromN(categories.length)}
            data={chartData}
            title=""
            xAxisOptions={{
              events: {
                afterSetExtremes: (e) => {
                  onSetExtremes({ min: e.min, max: e.max });
                },
              },
            }}
            seriesOptions={{
              events: {
                click: function (event) {
                  const point: XrangePointOptionsObject = event.point;
                  const state = point.name;
                  if (state) {
                    if (
                      state.toLowerCase().includes("error") ||
                      state.toLowerCase().includes("alarm")
                    ) {
                      setErrorStateTimestamp({
                        start: point.x!,
                        end: point.x2!,
                      });
                      open();
                    }
                  }
                },
              },
            }}
          />
        ) : (
          <Box
            bgcolor={"#141E30"}
            marginY={2}
            border={"1px solid rgba(81, 81, 81, 1)"}
          >
            <Typography textAlign="center" padding={5}>
              {translate("state_log.no_state_selected")}
            </Typography>
          </Box>
        )}
      </Card>
      {isOpen && errorStateTimestamp.start && errorStateTimestamp.end && (
        <ErrorStateModal
          isOpen={isOpen}
          close={close}
          dateStart={errorStateTimestamp.start}
          dateEnd={errorStateTimestamp.end}
        />
      )}
    </>
  );
};

const StatesLogChartWithBatches = ({
  dataBatches,
  onSetExtremes,
  categories,
}: {
  dataBatches: { [key: string]: XrangePointOptionsObject[] };
  onSetExtremes: ({ min, max }: { min: number; max: number }) => void;
  categories: string[];
}) => {
  const translate = useTranslate();
  const [errorStateTimestamp, setErrorStateTimestamp] = useState<{
    start: number | null;
    end: number | null;
  }>({ start: null, end: null });
  const [selectedBatch, setSelectedBatch] = useState(
    Object.keys(dataBatches)[0],
  );
  const [batchData, setBatchData] = useState(dataBatches[selectedBatch]);
  const { open, isOpen, close } = useDisclosure();

  const handleSelectBatch = (
    _: React.MouseEvent<HTMLElement>,
    newBatch: string,
  ) => {
    if (!newBatch) return;
    setSelectedBatch(newBatch);
    setBatchData(dataBatches[newBatch]);
  };

  return (
    <>
      <Card>
        <Grid container>
          {/* <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}> */}
          <Grid item xs={6}>
            <Typography sx={{ textAlign: "left" }}>
              {translate("state_log.machine_states_log")}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <ToggleButtonGroup
              value={selectedBatch}
              exclusive
              onChange={handleSelectBatch}
              aria-label="Variables"
              sx={{ backgroundColor: "#1F293F" }}
            >
              {Object.keys(dataBatches).map((batch) => (
                <ToggleButton key={batch} value={batch}>
                  {batch}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Grid>
        </Grid>
        {categories.length > 0 ? (
          <XRangeChart
            key={"state-log-chart-batches-" + batchData.length}
            enableStepZoomOut={true}
            categories={categories}
            customHeight={getCustomHeightFromN(categories.length)}
            data={batchData}
            title=""
            xAxisOptions={{
              events: {
                afterSetExtremes: (e) => {
                  onSetExtremes({ min: e.min, max: e.max });
                },
              },
            }}
            seriesOptions={{
              events: {
                click: function (event) {
                  const point: XrangePointOptionsObject = event.point;
                  const state = point.name;
                  if (state) {
                    if (
                      state.toLowerCase().includes("error") ||
                      state.toLowerCase().includes("alarm")
                    ) {
                      setErrorStateTimestamp({
                        start: point.x!,
                        end: point.x2!,
                      });
                      open();
                    }
                  }
                },
              },
            }}
          />
        ) : (
          <Box
            bgcolor={"#141E30"}
            marginY={2}
            border={"1px solid rgba(81, 81, 81, 1)"}
          >
            <Typography textAlign="center" padding={5}>
              {translate("state_log.no_state_selected")}
            </Typography>
          </Box>
        )}
      </Card>
      {isOpen && errorStateTimestamp.start && errorStateTimestamp.end && (
        <ErrorStateModal
          isOpen={isOpen}
          close={close}
          dateStart={errorStateTimestamp.start}
          dateEnd={errorStateTimestamp.end}
        />
      )}
    </>
  );
};
