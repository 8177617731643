import { useState } from "react";
import { RunsLapsShuttles } from "@/features/Acopostrak/api/Diagnostic/useGetRunsLapsShuttlesList";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { CompareLapsItem } from "@/features/Acopostrak/types";

interface LapSelectionProps {
  title: string;
  data: RunsLapsShuttles;
  currentlySelectedItem: CompareLapsItem | undefined;
  setElement: React.Dispatch<React.SetStateAction<CompareLapsItem | undefined>>;
}

export const LapSelection = ({
  title,
  data,
  currentlySelectedItem,
  setElement,
}: LapSelectionProps) => {
  const translate = useTranslate();
  const [runIndex, setRunIndex] = useState(0);
  const [runName, setRunName] = useState(data[0].clientName);
  const [runId, setRunId] = useState(data[0].id);
  const [shuttleId, setShuttleId] = useState(
    data[0].shuttles.length > 0 ? data[0].shuttles[0].id : undefined,
  );
  const [lapId, setLapId] = useState(
    data[0].laps.length > 0 ? data[0].laps[0].id : undefined,
  );
  const isLatestVersion =
    currentlySelectedItem?.runId === runId &&
    currentlySelectedItem?.shuttleId === shuttleId &&
    currentlySelectedItem?.lapId === lapId;

  const handleChangeRun = (event: SelectChangeEvent) => {
    const newRunId = parseInt(event.target.value);
    setRunId(newRunId);
    const newRunIndex = data.findIndex((el) => el.id === newRunId);
    if (newRunIndex > -1) setRunIndex(newRunIndex);
    const newRun = data.find((el) => el.id === newRunId);
    if (newRun) setRunName(newRun.clientName);
  };

  const handleChangeShuttle = (event: SelectChangeEvent) => {
    setShuttleId(parseInt(event.target.value));
  };

  const handleChangeLap = (event: SelectChangeEvent) => {
    setLapId(parseInt(event.target.value));
  };

  const handleConfirm = () => {
    if (runId && shuttleId && lapId) {
      setElement({
        runName,
        runId,
        shuttleId,
        lapId,
      });
    }
  };

  return (
    <Stack width="100%" gap={2} padding={2}>
      <Typography>{title}</Typography>
      <FormControl fullWidth>
        <InputLabel id="run-select-label">{translate("acopos.run")}</InputLabel>
        <Select
          labelId="run-select-label"
          id="run-select"
          size="small"
          value={runId.toString()}
          label={translate("acopos.run")}
          onChange={handleChangeRun}
        >
          {data.map((run) => (
            <MenuItem key={run.id} value={run.id}>
              {run.clientName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="shuttle-select-label">
          {translate("acopos.shuttle")}
        </InputLabel>
        <Select
          labelId="shuttle-select-label"
          id="shuttle-select"
          size="small"
          value={shuttleId ? shuttleId.toString() : ""}
          label={translate("acopos.shuttle")}
          onChange={handleChangeShuttle}
        >
          {data[runIndex].shuttles.map((shuttle) => (
            <MenuItem key={shuttle.id} value={shuttle.id}>
              {shuttle.clientName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="lap-select-label">{translate("acopos.lap")}</InputLabel>
        <Select
          labelId="lap-select-label"
          id="lap-select"
          size="small"
          value={lapId ? lapId.toString() : ""}
          label={translate("acopos.lap")}
          onChange={handleChangeLap}
        >
          {data[runIndex].laps.map((lap) => (
            <MenuItem key={lap.id} value={lap.id}>
              {lap.clientName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="outlined"
        size="small"
        onClick={handleConfirm}
        sx={{ borderColor: isLatestVersion ? "green" : "orange" }}
      >
        {translate("actions.confirm")}
      </Button>
    </Stack>
  );
};
