type ITransform<TInput, TOutput> = {
  [key in keyof TInput]: keyof TOutput;
};

/**
 * Class for transforming data from one format to another.
 */
export class DataTransformer {
  /**
   * Transforms an input object to an output object based on a provided transform mapping.
   * @template TInput The input object's type.
   * @template TOutput The output object's type.
   * @param {object} param0 The input object and transform mapping.
   * @param {TInput} param0.input The input object to transform.
   * @param {ITransform<Partial<TInput>, TOutput>} param0.transform The transform mapping.
   * @returns {TOutput} The transformed output object.
   */
  static dataTransformer = <TInput, TOutput>({
    input,
    transform,
  }: {
    input: TInput;
    transform: ITransform<Partial<TInput>, TOutput>;
  }): TOutput => {
    // Create a new output object
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const output: any = {};
    const inputCopy = Object.assign({}, input);

    for (const key in transform) {
      const newKey = transform[key];
      if (!newKey) continue;

      output[newKey] = inputCopy[key];

      delete inputCopy[key];
    }

    return { ...inputCopy, ...output } as TOutput;
  };
}
