import { createContext, PropsWithChildren, useContext, useState } from "react";
type A4GateContextState = {
  selectedPC: string;
  timeSelection: string;
  resource: {
    id_value: number;
    display_name: string;
  };
};

const A4GateContext = createContext<A4GateContextState | null>(null);
const A4GateContextDispatch = createContext<{
  setSelectedPC: React.Dispatch<string>;
  setTimeSelection: React.Dispatch<string>;
  setResource: React.Dispatch<{ id_value: number; display_name: string }>;
} | null>(null);

export const A4GateContextProvider = ({ children }: PropsWithChildren) => {
  const [selectedPC, setSelectedPC] = useState<string>("");
  const [timeSelection, setTimeSelection] = useState<string>("Hour");
  const [resource, setResource] = useState<{
    id_value: number;
    display_name: string;
  }>({
    id_value: 0,
    display_name: "Health",
  });

  return (
    <A4GateContext.Provider value={{ selectedPC, timeSelection, resource }}>
      <A4GateContextDispatch.Provider
        value={{ setSelectedPC, setTimeSelection, setResource }}
      >
        {children}
      </A4GateContextDispatch.Provider>
    </A4GateContext.Provider>
  );
};

export const useA4GateContext = () => {
  const context = useContext(A4GateContext);

  if (!context) {
    throw new Error("useA4GateContext can only be used inside a A4GateContext");
  }

  return context;
};

export const useA4GateContextDispatch = () => {
  const context = useContext(A4GateContextDispatch);
  if (!context) {
    throw new Error(
      "useA4GateContextDispatch can only be used inside a A4GateContextDispatch"
    );
  }

  return context;
};
