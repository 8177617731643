import { BellCurve } from "@/components/highcharts/bellcurve/BellCurve";
import { Card } from "@/components/Layout/Card";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Tab,
  Tabs,
} from "@mui/material";
import { colors } from "@/styles/colors";
import { useState } from "react";
import { useTranslate } from "@/i18n/config";

const VIEWS = {
  net: 0,
  tare: 1,
};

interface ScaleBellCurveProps {
  netData: {
    bellCurveData: {
      name: string;
      data: {
        [key: string]: number[];
      };
    }[];
    benchmark?: {
      min?: number;
      setpoint?: number;
      max?: number;
    };
  };
  tareData: {
    bellCurveData: {
      name: string;
      data: {
        [key: string]: number[];
      };
    }[];
    benchmark?: {
      min?: number;
      setpoint?: number;
      max?: number;
    };
  };
  modalities: string[];
}

export const ScaleBellCurve = ({
  netData,
  tareData,
  modalities = [],
}: ScaleBellCurveProps) => {
  const [view, setView] = useState(VIEWS.net);
  const [showBenchmark, setShowBenchmark] = useState(true);
  const [customModalities, setCustomModalities] =
    useState<string[]>(modalities);
  const translate = useTranslate();

  const handleBenchmarkChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setShowBenchmark(event.target.checked);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, checked },
    } = event;
    if (checked) {
      setCustomModalities([...customModalities, value]);
    } else {
      setCustomModalities(customModalities.filter((item) => item !== value));
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <List
        subheader={
          <ListSubheader>{translate("filler.modality_other")}:</ListSubheader>
        }
        sx={{
          mr: 1,
          width: "21vw",
          overflowY: "auto",
          backgroundColor: colors.palette.darkBlue,
          color: "#fff",
          backgroundImage: "none",
          padding: "1rem",
          boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: ".25rem",
        }}
      >
        {modalities.map((modality) => (
          <ListItem key={modality} disablePadding>
            <FormControlLabel
              control={
                <Checkbox
                  checked={customModalities.includes(modality)}
                  onChange={handleChange}
                  value={modality}
                />
              }
              label={<ListItemText primary={modality} />}
            />
          </ListItem>
        ))}
      </List>
      <Box
        sx={{
          width: "79vw",
        }}
      >
        <Card sx={{ display: "flex", justifyContent: "space-between" }}>
          <Tabs
            value={view}
            aria-label="batch detail tabs"
            onChange={(_: React.SyntheticEvent, newValue: number) => {
              setView(newValue);
            }}
            sx={{ height: 48 }}
          >
            <Tab value={VIEWS.net} label={translate("filler.net")} />
            <Tab value={VIEWS.tare} label={translate("filler.tare")} />
          </Tabs>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showBenchmark}
                  onChange={handleBenchmarkChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={translate("benchmark")}
            />
          </FormGroup>
        </Card>
        <Card>
          {view === VIEWS.net && (
            <BellCurve
              key={`${view}_${customModalities}`}
              title={translate("filler.scales_weighings")}
              bellCurveData={netData.bellCurveData.map((scale) => {
                const weighingsArr = [];
                for (const key in scale.data) {
                  if (customModalities.includes(key)) {
                    weighingsArr.push(scale.data[key]);
                  }
                }
                return {
                  name: scale.name,
                  data: weighingsArr.flat(),
                };
              })}
              benchmark={showBenchmark ? netData.benchmark : undefined}
              histogram={true}
              uom="g"
              xAxisTitle={translate("weight")}
            />
          )}
          {view === VIEWS.tare && (
            <BellCurve
              key={`${view}_${customModalities}`}
              title={translate("filler.scales_weighings")}
              bellCurveData={tareData.bellCurveData.map((scale) => {
                const weighingsArr = [];
                for (const key in scale.data) {
                  if (customModalities.includes(key)) {
                    weighingsArr.push(scale.data[key]);
                  }
                }
                return {
                  name: scale.name,
                  data: weighingsArr.flat(),
                };
              })}
              benchmark={showBenchmark ? tareData.benchmark : undefined}
              histogram={true}
              uom="g"
              xAxisTitle={translate("weight")}
            />
          )}
        </Card>
      </Box>
    </Box>
  );
};
