import { styled, Tab, Tabs } from "@mui/material";
import { ReactNode } from "react";
import { Card } from "@/components/Layout/Card";

const StyledTab = styled(Tab, {
  shouldForwardProp: (prop) => prop !== "active",
})<{
  active: boolean;
}>(({ active }) => ({
  border: "none",
  backgroundColor: active ? "#444959" : "transparent",
  transition: "background-color 0.2s ease-in-out",
  color: "#fff",
  fontSize: ".75rem",
  padding: ".5rem 1rem",
  margin: "0 .25rem",
  display: "flex",
  borderRadius: ".25rem",
}));

const StyledCard = styled(
  Card,
  {},
)({
  padding: ".5rem",
});

export const AdvancedAnalyticsList = <
  TItem extends { id_value: string | number },
>({
  items,
  renderTextItem,
  onItemClicked,
  activeId,
}: {
  items: TItem[];
  renderTextItem: (item: TItem) => ReactNode;
  onItemClicked: (item: TItem) => void;
  activeId?: number;
}) => {
  return (
    <StyledCard>
      <Tabs variant="scrollable" value={false}>
        {items.map((item) => (
          <StyledTab
            value={item.id_value}
            key={item.id_value}
            active={item.id_value === activeId}
            onClick={() => {
              onItemClicked(item);
            }}
            label={renderTextItem(item)}
          />
        ))}
      </Tabs>
    </StyledCard>
  );
};
