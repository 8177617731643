import { z } from "zod";
import { useQuery } from "@tanstack/react-query";
import { FetchClient } from "@/services/ApiClient";
import { useFirebaseContext } from "@/context/firebase-context";

const SentryExcludedUsersResult = z.object({
  users: z.array(z.string()),
});

export type SentryExcludedUsersResult = z.infer<
  typeof SentryExcludedUsersResult
>;

export const useGetSentryExcludedUsers = () => {
  const { appKey } = useFirebaseContext();

  return useQuery<SentryExcludedUsersResult>({
    queryKey: ["sentry-excluded-users"],
    queryFn: async () => {
      const response = await FetchClient<object, SentryExcludedUsersResult>({
        appKey,
        payload: {},
        url: "dig.c.internalUtility_thing/Services/getSentryExcludedUsers",
      });

      const validatedResponse = SentryExcludedUsersResult.parse(response);
      return validatedResponse;
    },
  });
};
