import { NavButton } from "@/components/NavButton";
import { SelectTimeSelection } from "@/components/SelectTimeSelection";
import { useTranslate } from "@/i18n/config";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { ContentWrapperLayout } from "../..";
import { useGetEfficiencyNavigatorData } from "../../api/useGetEfficiencyNavigatorData";
import { EfficiencyNavigatorLoader } from "../../layout/EfficiencyNavigatorLoader";

export const EfficiencyNavigatorLive = () => {
  const { data, isLoading } = useGetEfficiencyNavigatorData();
  const translate = useTranslate();
  return (
    <>
      <MachineViewNavBar>
        <SelectTimeSelection
          exclude={[
            "Custom",
            "TwoWeeks",
            "Month",
            "Year",
            "SixMonths",
            "ThreeMonths",
          ]}
        />
        <NavButton
          to="/efficiency-navigator/historic"
          text={translate("view_historic")}
        />
      </MachineViewNavBar>
      {isLoading ? <EfficiencyNavigatorLoader /> : null}
      {data ? <ContentWrapperLayout data={data} /> : null}
    </>
  );
};
