import { HighchartsCustomProvider } from "../highcharts";

import {
  HighchartsChart,
  YAxis,
  Chart,
  Tooltip,
  Title,
  VariablePieSeries,
  Subtitle,
} from "react-jsx-highcharts";

export interface PieDataProps {
  name: string;
  y: number;
  color: string;
  selected?: boolean;
}
export const EfficiencyPieChart = ({
  title,
  value,
  firstPieData,
  secondPieData,
}: {
  title: string;
  value: number;
  firstPieData: PieDataProps[];
  secondPieData: PieDataProps[];
}) => {
  return (
    <HighchartsCustomProvider>
      <HighchartsChart
        plotOptions={{
          variablepie: {
            dataLabels: { enabled: false },
            size: "100%",
            center: ["50%", "49.9%"],
            states: {
              inactive: {
                enabled: false,
              },
              hover: {
                halo: null,
              },
            },
          },
        }}
        accessibility={{ enabled: false }}
        exporting={{
          enabled: false,
        }}
      >
        <Chart
          backgroundColor={"transparent"}
          type="variablepie"
          alignTicks={false}
          margin={[0, 5, 0, 5]}
        />
        <Tooltip
          formatter={function (this) {
            const point = this.point;
            if (point.name === "offColor") {
              return false; // Nasconde il tooltip per la fetta "offColor"
            }
            return `<span style="color:${point.color}">\u25CF</span>
      <b>${point.name}</b>: <b>${point.y}%</b><br/>`;
          }}
          outside={true}
        />
        <YAxis>
          <Title
            style={{ color: "white", fontFamily: "Montserrat" }}
            y={5}
            align={"center"}
            verticalAlign={"middle"}
          >
            {title}
          </Title>
          <Subtitle
            style={{
              color: "white",
              fontFamily: "Montserrat",
              fontSize: "150%",
            }}
            align={"center"}
            verticalAlign={"middle"}
            y={30}
          >
            {value + "%"}
          </Subtitle>
          <VariablePieSeries
            minPointSize={"20%"}
            zMin={20}
            innerSize={"90%"}
            borderWidth={0}
            data={firstPieData}
          />
          <VariablePieSeries
            innerSize={"70%"}
            minPointSize={"20%"}
            borderWidth={0}
            zMin={20}
            data={secondPieData}
          />
        </YAxis>
      </HighchartsChart>
    </HighchartsCustomProvider>
  );
};
