import { ProductionBox } from "@/components/CustomIcons/ProductionBox";
import { RecipeIcon } from "@/components/CustomIcons/Recipe";
import { SetupPreProdCategory } from "@/components/CustomIcons/setupPreProdCategory";
import { useTranslate } from "@/i18n/config";
import { TimeHelpers } from "@/utils/TimeHelpers";
import { Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  AISuggest,
  EfficiencyNavigtorResult,
  FaultAnalysis,
  Loss,
  OeeMachineStatus,
  Other,
} from "./api/useGetEfficiencyNavigatorData";
import { AiSuggestItem, ItemLayout } from "./components/AiSuggestItem";
import { ChartItem, FaultAnalysisItem } from "./components/ChartItem";
import { EfficiencyPie } from "./components/EfficiencyPie";

interface FaultAnalysisColors {
  [key: string]: string;
}

export const EffNavigatorColors = {
  category: {
    machine: "#D62422",
    process: "#ED8526",
    line: "#16A99C",
    default: "#4d4d4d",
  },
  pie: {
    oee: "#76A12B",
    offColor: "#1f293f",
    extraCapacity: "#596679",
  },
  faultAnalysis: {
    MACHINE: "#9A0000",
    INTERACTION: "#ED8526",
    LINE: "#16A99C",
    " ": "#4d4d4d",
  } as FaultAnalysisColors,
};

export const ContentWrapperLayout = ({
  data,
}: {
  data: EfficiencyNavigtorResult;
}) => {
  return (
    <Stack gap={2}>
      <TopRow
        aiSuggest={data.aiSuggest}
        oeeMachineStatus={data.oeeMachineStatus}
        other={data.other}
      ></TopRow>
      <BottomRow
        lineLossesData={data.lineLosses}
        processLineData={data.processLosses}
        machineLossesData={data.machineLosses}
        faultAnalysisData={data.faultAnalysis}
        oeeMachineStatus={data.oeeMachineStatus}
      />
    </Stack>
  );
};

const TopRow = ({
  aiSuggest,
  oeeMachineStatus,
  other,
}: {
  aiSuggest: AISuggest[];
  oeeMachineStatus: OeeMachineStatus;
  other: Other;
}) => {
  const aiSuggestSorted = aiSuggest.sort((a, b) =>
    a.efficiencyLost < b.efficiencyLost
      ? 1
      : b.efficiencyLost < a.efficiencyLost
      ? -1
      : 0,
  );
  const aiSuggestTopThree = aiSuggestSorted.slice(0, 3);
  const translate = useTranslate();
  return (
    <Grid2
      container
      direction={{ md: "row", xs: "column" }}
      alignItems="center"
      justifyContent={"space-between"}
      gap={{ md: 0, xs: 2 }}
    >
      <Grid2 md={4} xs={12} order={{ md: 0, xs: 1 }}>
        <Stack gap={1}>
          {aiSuggestTopThree.map((item, index) => (
            <AiSuggestItem data={item} key={index} />
          ))}
        </Stack>
      </Grid2>
      <Grid2 md={4} xs={12} order={{ xs: 0 }}>
        <EfficiencyPie title={translate("kpi.oee")} data={oeeMachineStatus} />
      </Grid2>
      <Grid2 md={4} xs={12} order={{ xs: 1 }}>
        <Stack gap={1}>
          <ItemLayout
            icon={<RecipeIcon width={38} height={38} fill="white" />}
            label={translate("machine.recipe")}
            value={other.recipeName}
          />
          <ItemLayout
            icon={<ProductionBox width={38} height={38} fill="white" />}
            label={translate("total_production")}
            value={other.counter.toString()}
          />
          <ItemLayout
            icon={<SetupPreProdCategory width={38} height={38} fill="white" />}
            label={translate("machine.elapsed_time")}
            value={TimeHelpers.parseDurationToString({
              fmt: "h'h' m'm'",
              duration: other.elapsedTimeSs * 1000,
            })}
          />
        </Stack>
      </Grid2>
    </Grid2>
  );
};

const BottomRow = ({
  lineLossesData,
  processLineData,
  machineLossesData,
  faultAnalysisData,
  oeeMachineStatus,
}: {
  lineLossesData: Loss[];
  processLineData: Loss[];
  machineLossesData: Loss[];
  faultAnalysisData: FaultAnalysis[];
  oeeMachineStatus: OeeMachineStatus;
}) => {
  const translate = useTranslate();

  return (
    <Grid2 container direction="row" spacing={4}>
      <Grid2 md={6} xs={12}>
        <ChartItem
          title={translate("losses.line_losses")}
          data={lineLossesData}
          categoryValue={oeeMachineStatus.line}
          color={EffNavigatorColors.category.line}
        />
      </Grid2>
      <Grid2 md={6} xs={12}>
        <ChartItem
          title={translate("losses.process_losses")}
          data={processLineData}
          categoryValue={oeeMachineStatus.process}
          color={EffNavigatorColors.category.process}
        />
      </Grid2>
      <Grid2 md={6} xs={12}>
        <ChartItem
          title={translate("losses.machine_losses")}
          data={machineLossesData}
          categoryValue={oeeMachineStatus.machine}
          color={EffNavigatorColors.category.machine}
        />
      </Grid2>
      <Grid2 md={6} xs={12}>
        <FaultAnalysisItem
          title={translate("efficiency_navigator.fault_analysis")}
          data={faultAnalysisData}
          color={EffNavigatorColors.faultAnalysis.MACHINE}
        />
      </Grid2>
    </Grid2>
  );
};
