import { GaugeLayout } from "@/features/MachineDetail/components/GaugeLayout";
import { colors } from "@/styles/colors";
import { Stack, Typography } from "@mui/material";
import { MachineProducts } from "@/features/OverallLineEfficiency/Pages/LineDetail/api/useGetLineConfiguration";
import PopupState, { bindHover, bindPopover } from "material-ui-popup-state";
import Popover from "material-ui-popup-state/HoverPopover";
import { useTranslate } from "@/i18n/config";

export const TotalProductsSummary = ({
  output_products = 0,
  output_wastes = 0,
  products,
}: {
  output_products: number;
  output_wastes: number;
  products: MachineProducts[];
}) => {
  const total_output = output_products + output_wastes;
  const translate = useTranslate();
  const line_wastes = products.map((machine) => ({
    wastes: machine.wastes,
    machine: machine.machine,
  }));

  const line_products = products.map((machine) => ({
    products: machine.products,
    machine: machine.machine,
  }));

  return (
    <Stack direction="row" justifyContent="center" gap={8} flex={0.5}>
      <PopupState variant="popover" popupId="popup-products">
        {(popupState) => (
          <>
            <span {...bindHover(popupState)}>
              <GaugeLayout
                dimension="small"
                color={colors.kpi.oee}
                min={0}
                max={total_output}
                type="count"
                title={translate("machine.products")}
                value={output_products}
              />
            </span>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Stack p={2}>
                <Typography variant="body1">{translate("line.products_by_machine")}</Typography>
                {line_products.map((machine) => (
                  <Typography key={machine.machine} variant="body2">
                    {machine.machine} : {machine.products}
                  </Typography>
                ))}
              </Stack>
            </Popover>
          </>
        )}
      </PopupState>

      <PopupState variant="popover" popupId="popup-products">
        {(popupState) => (
          <>
            <span {...bindHover(popupState)}>
              <GaugeLayout
                dimension="small"
                color={colors.states.error}
                min={0}
                max={total_output}
                type="count"
                title={translate("waste_other")}
                value={output_wastes}
              />
            </span>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Stack p={2}>
                <Typography variant="body1">{translate("line.wastes_by_machine")}</Typography>
                {line_wastes.map((machine) => (
                  <Typography key={machine.machine} variant="body2">
                    {machine.machine} : {machine.wastes}
                  </Typography>
                ))}
              </Stack>
            </Popover>
          </>
        )}
      </PopupState>
    </Stack>
  );
};
