import { Tabs, Tab, Box } from "@mui/material";
import { Link, Outlet, useLocation } from "react-router-dom";
import { BatchNavigation } from "../../components/Navigation/BatchNavigation";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const routes = [
  "/analytics/lyophilizer/batch/diagnostic",
  "/analytics/lyophilizer/batch/predictive-analytics",
];

export const BatchPage = () => {
  const { pathname } = useLocation();
  return (
    <div>
      <BatchNavigation />

      <Tabs value={pathname} aria-label="batch detail tabs">
        <Tab
          component={Link}
          to={routes[0]}
          value={routes[0]}
          label="Diagnostics"
          {...a11yProps(0)}
        />
        <Tab
          component={Link}
          to={routes[1]}
          value={routes[1]}
          label="Predictive Analytics"
          {...a11yProps(1)}
        />
      </Tabs>
      <Box sx={{ paddingTop: "1rem" }} />
      <Outlet />
    </div>
  );
};
