import { useReactFlow } from "reactflow";

export const useRemoveNode = () => {
  const rfInstance = useReactFlow();
  const remove = (nodeId: string) => {
    rfInstance.deleteElements({
      nodes: [
        {
          id: nodeId,
        },
      ],
    });
  };

  return { remove };
};
