import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AcceptanceTestDataResult } from "./useGetAcceptanceTestData";

export const useSetComment = () => {
  const { appKey } = useFirebaseContext();
  const { machine } = useMachineContext();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ Id, Comment }: { Id: number; Comment: string }) => {
      await FetchClient<
        { machineName: string; Id: number; Comment: string },
        null
      >({
        appKey,
        payload: {
          machineName: machine!.machine,
          Id,
          Comment,
        },
        url: "dig.c.acceptanceTest_thing/Services/setComment",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["acceptance-test-data", machine!.machine],
      });
    },
    onMutate: async (values) => {
      const { Comment: newComment, Id: updatedId } = values;

      await queryClient.cancelQueries({
        queryKey: ["acceptance-test-data", machine!.machine],
      });

      const previousValues = queryClient.getQueryData([
        "acceptance-test-data",
        machine!.machine,
      ]) as AcceptanceTestDataResult;

      queryClient.setQueryData<AcceptanceTestDataResult>(
        ["acceptance-test-data", machine!.machine],
        (oldValues) => {
          if (!oldValues) return oldValues;
          return {
            ...oldValues,
            DatiTabella: oldValues.DatiTabella.map((tableRow) => {
              if (tableRow.Id === updatedId) {
                return {
                  ...tableRow,
                  Comment: newComment,
                };
              }
              return tableRow;
            }),
          };
        },
      );

      return { previousValues };
    },
    onError: (err, newComment, context) => {
      queryClient.setQueryData(
        ["acceptance-test-data", machine!.machine],
        context?.previousValues,
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["acceptance-test-data", machine!.machine],
      });
    },
  });
};
