import { useEffect, useState } from "react";
import { MachineViewNavBar } from "@/pages/MachineView/layout/MachineViewNavBar/MachineViewNavBar";
import { Tab, Tabs } from "@mui/material";
import { useTranslate } from "@/i18n/config";
import { Summary } from "./pages/Summary";
import { Monitoring } from "./pages/Monitoring";
import { Diagnostic } from "./pages/Diagnostic";
import { DateTimeRangePicker } from "@/components/Calendars/DateTimeRangePicker";
import { IsolatorContextProvider } from "./context/isolator-context";
import { useTimeSelection } from "@/store/useTimeSelection";
import { DateTime } from "luxon";

const SECTIONS = {
  SUMMARY: 0,
  MONITORING: 1,
  DIAGNOSTIC: 2,
};

const IsolatorAnalytics = () => {
  const translate = useTranslate();
  const [section, setSection] = useState(SECTIONS.SUMMARY);

  useEffect(() => {
    //? iso - forzatura per fiera
    useTimeSelection.setState((prevState) => ({
      ...prevState,
      dates: {
        dateStart: DateTime.fromMillis(1609488000000), // 1 gennaio 2021
        dateEnd: DateTime.fromMillis(1612080000000), // 31 gennaio 2021
      },
    }));
  }, []);

  return (
    <>
      <MachineViewNavBar>
        {section !== SECTIONS.SUMMARY && <DateTimeRangePicker maxDays={30} />}
      </MachineViewNavBar>
      <Tabs
        value={section}
        aria-label="acopostrak tabs"
        onChange={(_: React.SyntheticEvent, newValue: number) => {
          setSection(newValue);
        }}
      >
        <Tab value={SECTIONS.SUMMARY} label={translate("summary")} />
        <Tab value={SECTIONS.MONITORING} label={translate("monitoring")} />
        <Tab value={SECTIONS.DIAGNOSTIC} label={translate("diagnostic")} />
      </Tabs>
      {section === SECTIONS.SUMMARY && <Summary />}
      {section === SECTIONS.MONITORING && <Monitoring />}
      {section === SECTIONS.DIAGNOSTIC && <Diagnostic />}
    </>
  );
};

export const IsolatorAnalyticsScreen = () => {
  return (
    <IsolatorContextProvider>
      <IsolatorAnalytics />
    </IsolatorContextProvider>
  );
};
