import { useAskConfirmAction } from "@/hooks/ask-confirm-action";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { Delete } from "@mui/icons-material";
import { useDeleteUser } from "../../../../lambda/useDeleteUser";
import { useTranslate } from "@/i18n/config";

export const DeleteUserCell = ({ user }: { user: string }) => {
  const { mutate: deleteUser } = useDeleteUser();
  const translate = useTranslate();
  const { askConfirm, renderConfirmModal } = useAskConfirmAction(
    translate("delete_user", { user }),
    () => deleteUser({ email: user })
  );

  return (
    <>
      <GridActionsCellItem
        icon={<Delete />}
        label={translate("actions.delete")}
        onClick={askConfirm}
      />
      {renderConfirmModal()}
    </>
  );
};
