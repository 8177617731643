import { z } from "zod";
import { useFirebaseContext } from "@/context/firebase-context";
import { useMachineContext } from "@/context/machine-context";
import { FetchClient } from "@/services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ThingworxError } from "src/types/error";
import { Run, contextInfo } from "../../types";
import { useAcoposContext } from "../../context/acopos-context";

export type GetDescriptiveDataResponse =
  | ThingworxError
  | GetDescriptiveDataResult;

const descriptiveData = z.union([
  z.object({
    analyticName: z.string(),
    data: z.array(z.array(z.number())),
    contextInfo: contextInfo,
    isAggregated: z.literal(false),
  }),
  z.object({
    analyticName: z.string(),
    data: z.array(
      z.object({
        name: z.string(),
        data: z.array(z.array(z.number())),
      })
    ),
    contextInfo: contextInfo,
    isAggregated: z.literal(true),
  }),
]);

export type DescriptiveData = z.infer<typeof descriptiveData>;

type Payload = {
  machineName: string;
  runsAndLaps: { [key: string]: number[] };
  runs: Run[];
  segment: number;
  shuttles: number[];
  analytics: string[];
  isAggregated: boolean;
};

const getDescriptiveDataResult = z.object({
  response: z.literal(true),
  data: z.array(descriptiveData),
});

export type GetDescriptiveDataResult = z.infer<typeof getDescriptiveDataResult>;

export const useGetDescriptiveData = ({
  analytics,
}: {
  analytics: string[];
}) => {
  const { machine } = useMachineContext();
  const { appKey } = useFirebaseContext();
  const { runs, activeSegment, laps, shuttles, isLapsLimitActive } =
    useAcoposContext();
  const runsAndLaps: { [key: string]: number[] } = {};
  Object.keys(laps).forEach((run) => {
    const currentRun = runs.find((item) => item.name === run);
    if (currentRun) {
      const runId = currentRun.id;
      runsAndLaps[runId] = [];
      laps[run].forEach((lap) => runsAndLaps[runId].push(lap.id));
    }
  });

  return useQuery<GetDescriptiveDataResult>({
    queryKey: [
      "descriptive-analytics-data",
      machine!.machine,
      JSON.stringify(runsAndLaps),
      activeSegment,
      shuttles,
      JSON.stringify(analytics),
      isLapsLimitActive,
    ],
    queryFn: async () => {
      const response = await FetchClient<Payload, GetDescriptiveDataResponse>({
        appKey,
        payload: {
          machineName: machine!.machine,
          runsAndLaps,
          runs,
          segment: activeSegment!,
          shuttles,
          analytics,
          isAggregated: !isLapsLimitActive,
        },
        // url: "dig.c.acopostrak_thing/Services/getDescriptiveData",
        url: "dig.c.acoposTrack_thing/Services/getDescriptiveData",
      });

      if (!response.response) {
        throw new Error(response.errorString);
      }

      const validatedResponse = getDescriptiveDataResult.parse(response);

      return validatedResponse;
    },
  });
};
