import { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Modal } from "@/components/Modal";
import { Variable, useGetVariableList } from "../api/useGetVariableList";
import { useDisclosure } from "@/hooks/useDisclosure";
import { VariablesModal } from "./VariablesModal";
import { getSliderValue } from "../utils/getSliderValue";
import { toast } from "react-hot-toast";
import { RuleSchema } from "../api/useGetEventsData/getRules";
import { EmailInput } from "./EmailInput";
import { ConditionConfig, ConditionEdit, TimeRange } from "../types";
import { ConditionsEdit } from "./ConditionsEdit";
import { useUpdateRule } from "../api/useUpdateRule";
import { ONE_MINUTE, ONE_HOUR, ONE_DAY, ONE_WEEK } from "@/utils/durationsInSeconds";
import { useUpdateCondition } from "../api/useUpdateCondition";
import { useTranslate } from "@/i18n/config";

const getDefaultTimeRange = (inputDuration: number): { timeRange: TimeRange; duration: number } => {
  let timeRange: TimeRange = "minutes";
  let duration: number = 0;

  if (inputDuration < ONE_HOUR) {
    timeRange = "minutes";
    duration = inputDuration / ONE_MINUTE;
  } else if (inputDuration > ONE_HOUR && inputDuration < ONE_DAY) {
    timeRange = "hours";
    duration = inputDuration / ONE_HOUR;
  } else if (
    inputDuration > ONE_WEEK &&
    inputDuration <= ONE_WEEK * 10 &&
    inputDuration % ONE_WEEK === 0
  ) {
    timeRange = "weeks";
    duration = inputDuration / ONE_WEEK;
  } else if (inputDuration > ONE_DAY && inputDuration < ONE_DAY * 60) {
    timeRange = "days";
    duration = inputDuration / ONE_DAY;
  }

  return { timeRange, duration };
};

const getDefaultConditions = (data: ConditionConfig[], machine: string): ConditionEdit[] => {
  const defaultConditions: ConditionEdit[] = data.map((config) => ({
    duration: config.duration,
    name: config.name,
    isActiveDuration: config.isActiveDuration,
    isCumulative: config.cumulative,
    machine,
    operator: config.alertType,
    property: config.property,
    propertyNameClient: config.propertyNameClient,
    propertyType: config.propertyType,
    rate: config.rate,
    uom: config.uom[0],
    value1: config.values.value_1,
    value2: config.values.value_2,
  }));

  return defaultConditions;
};

export const EditRuleModal = ({
  isOpen,
  close,
  ruleData,
}: {
  isOpen: boolean;
  close: () => void;
  ruleData: RuleSchema;
}) => {
  const {
    account,
    data,
    eventLooping: defaultEventLooping,
    isEnabled: defaultIsActive,
    machine,
    mailList: defaultMailList,
    priority: defaultSeverity,
    ruleDescription: defaultRuleDescription,
    ruleName: defaultRuleName,
    timeRange: defaultTimeRange,
  } = ruleData;
  const [ruleName, setRuleName] = useState(defaultRuleName);
  const [ruleDescription, setRuleDescription] = useState(defaultRuleDescription);
  const [selectedMachineVariables, setSelectedMachineVariables] = useState<Variable[]>([]);
  const [timeRange, setTimeRange] = useState<TimeRange>(
    getDefaultTimeRange(defaultTimeRange).timeRange,
  );
  const [duration, setDuration] = useState(getDefaultTimeRange(defaultTimeRange).duration);
  const [isActive, setIsActive] = useState(defaultIsActive);
  const [eventLooping, setEventLooping] = useState(defaultEventLooping);
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState<string[]>(defaultMailList);
  const [severity, setSeverity] = useState(defaultSeverity);
  const { data: machineVariables = [] } = useGetVariableList(machine);
  const [conditions, setConditions] = useState<ConditionEdit[]>(
    getDefaultConditions(data, machine),
  );
  useEffect(() => {
    const conditionVariables: string[] = conditions.map((condition) => condition.property);
    setSelectedMachineVariables(
      machineVariables.filter((variable) => conditionVariables.includes(variable.propertyName)),
    );
  }, [machineVariables]);
  const { updateConditions } = useUpdateCondition();
  const { mutate: updateRule } = useUpdateRule();
  const { isOpen: isOpenVariablesModal, close: closeVariablesModal } = useDisclosure();
  const translate = useTranslate();

  const handleConfirm = async () => {
    if (emailError) return toast.error(translate("error.badly_formatted_email"));
    if (conditions.length === 0) return toast.error(translate("error.no_conditions_specified"));
    if (email.length === 0) return toast.error(translate("error.no_email_specified"));

    try {
      const ruleConditions = await updateConditions({
        conditions,
        machineName: machine,
      });

      updateRule(
        {
          newRuleName: ruleName,
          json: {
            account,
            ruleName: defaultRuleName,
            ruleDescription,
            machine,
            data: ruleConditions,
            recapTime: 0,
            timerange: getSliderValue({ duration, timeRange }),
            isEnabled: isActive,
            eventLooping,
            mailList: email,
            priority: severity,
          },
        },
        {
          onSuccess(data) {
            if (!data.response) return toast.error(translate("events.failed_to_update_rule"));
            toast.success(translate("events.rule_updated_successfully"));
            close();
          },
        },
      );
    } catch (error) {
      toast.error(translate("events.failed_to_update_rule"));
    }
  };

  const handleChangeRuleName = (event: ChangeEvent<HTMLInputElement>) => {
    setRuleName(event.target.value);
  };

  const handleChangeRuleDescription = (event: ChangeEvent<HTMLInputElement>) => {
    setRuleDescription(event.target.value);
  };

  const handleChangeEmail = (mailList: string[]) => {
    setEmail(mailList);
  };

  const handleAddEmail = (newMail: string) => {
    setEmail((prevState) => [...prevState, newMail]);
  };

  const handleChangeIsActive = (event: ChangeEvent<HTMLInputElement>) => {
    setIsActive(event.target.checked);
  };

  const handleChangeEventLooping = (event: ChangeEvent<HTMLInputElement>) => {
    setEventLooping(event.target.checked);
  };

  const handleChangeHasError = (hasError: boolean) => {
    setEmailError(hasError);
  };

  const updateCondition = (updatedCondition: ConditionEdit) => {
    const exists = conditions.find((condition) => condition.name === updatedCondition.name);
    if (exists) {
      setConditions((prevState) =>
        prevState.map((condition) =>
          condition.property === updatedCondition.property ? updatedCondition : condition,
        ),
      );
    } else {
      setConditions((prevState) => [...prevState, updatedCondition]);
    }
  };

  const deleteCondition = (propertyName: string) => {
    setConditions((prevState) =>
      prevState.filter((condition) => condition.property !== propertyName),
    );
  };

  return (
    <Modal
      open={isOpen}
      onClose={close}
      maxWidth="xl"
      bodyContent={
        <Stack display="flex" flexDirection="column" gap={2} padding={1}>
          <TextField
            label={translate("events.rule_name")}
            size="small"
            value={ruleName}
            onChange={handleChangeRuleName}
          />
          <TextField
            label={translate("events.rule_description")}
            size="small"
            value={ruleDescription}
            onChange={handleChangeRuleDescription}
          />
          <FormControl fullWidth>
            <InputLabel id="select-machine-label">{translate("machine")}</InputLabel>
            <Select
              labelId="select-machine-label"
              id="select-machine"
              size="small"
              value={machine}
              label={translate("machine")}
              disabled
            >
              <MenuItem value={machine}>{machine}</MenuItem>
            </Select>
          </FormControl>
          {/* <Button
            disabled={isLoading}
            variant="outlined"
            onClick={openVariablesModal}
          >
            Add Condition
          </Button> */}
          {selectedMachineVariables.length > 0 && (
            <ConditionsEdit
              variables={selectedMachineVariables}
              machine={machine}
              setSelectedVariables={setSelectedMachineVariables}
              updateCondition={updateCondition}
              deleteCondition={deleteCondition}
              conditions={conditions}
            />
          )}
          {isOpenVariablesModal && (
            <VariablesModal
              isOpen={isOpenVariablesModal}
              close={closeVariablesModal}
              variables={machineVariables}
              selectedVariables={selectedMachineVariables}
              setSelectedVariables={setSelectedMachineVariables}
            />
          )}
          <Box display="flex" alignItems="center" gap={2}>
            <FormControl sx={{ width: 200 }}>
              <InputLabel id="select-time-range-label">{translate("time_range")}</InputLabel>
              <Select
                labelId="select-time-range-label"
                id="select-time-range"
                value={timeRange}
                label={translate("time_range")}
                size="small"
                onChange={(e) => {
                  setDuration(0);
                  setTimeRange(e.target.value as TimeRange);
                }}
              >
                {["minutes", "hours", "days", "weeks"].map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography width={40}>{duration}</Typography>
            <Slider
              key={timeRange}
              aria-label={translate("duration")}
              value={duration}
              onChange={(_, value) => {
                setDuration(typeof value === "number" ? value : value[0]);
              }}
              valueLabelDisplay="auto"
              min={0}
              max={
                timeRange === "minutes"
                  ? 60
                  : timeRange === "hours"
                  ? 24
                  : timeRange === "days"
                  ? 60
                  : 10
              }
            />
          </Box>
          <FormControlLabel
            label={translate("events.active")}
            control={<Checkbox checked={isActive} onChange={handleChangeIsActive} />}
          />
          <FormControlLabel
            label={translate("events.event_loop")}
            control={<Checkbox checked={eventLooping} onChange={handleChangeEventLooping} />}
          />
          <EmailInput
            emailList={email}
            onChangeEmailList={handleChangeEmail}
            onAddEmail={handleAddEmail}
            hasError={handleChangeHasError}
            defaultValue={email}
          />
          <FormControl fullWidth>
            <InputLabel id="select-severity-label">{translate("events.severity")}</InputLabel>
            <Select
              labelId="select-severity-label"
              id="select-severity"
              value={severity}
              label={translate("events.severity")}
              size="small"
              onChange={(e) => setSeverity(e.target.value as number)}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      }
      titleContent={translate("events.rule_definition")}
      actions={
        <>
          <Button variant="outlined" onClick={close}>
            {translate("actions.cancel")}
          </Button>
          <Button variant="contained" onClick={handleConfirm}>
            {translate("actions.confirm")}
          </Button>
        </>
      }
      fullWidth
    />
  );
};
