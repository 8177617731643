import { useTranslate } from "@/i18n/config";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { SkeletonCard } from "@/components/Layout/SkeletonCard";
import { Card } from "@/components/Layout/Card";
import { useGetSingleDescriptiveData } from "../../api/Diagnostic/useGetSingleDescriptiveData";
import { LineChart } from "@/components/highcharts/linechart/Linechart";
import { ContextInformation } from "@/features/Motors/components/Layouts/ContextInformation";
import { generateColorScale } from "../../utils/dynamic-colors";
import { useMachineContext } from "@/context/machine-context";
import { fileNameFactory } from "@/utils/fileNameFactory";

export const DecompositionDetailModal = ({
  info,
  analyticId,
  isOpen,
  close,
}: {
  info: { runId: number; lapId: number; segmentId: number; shuttleId: number };
  analyticId: number;
  isOpen: boolean;
  close: () => void;
}) => {
  const translate = useTranslate();
  const { runId, lapId, segmentId, shuttleId } = info;
  const { data, isLoading, error } = useGetSingleDescriptiveData({
    analyticId,
    runId,
    lapId,
    segmentId,
    shuttleId,
  });

  const { machine } = useMachineContext();

  const machineName = machine?.machine;

  if (isLoading)
    return (
      <Dialog onClose={close} open={isOpen} maxWidth="xl" fullWidth>
        <DialogTitle>
          {translate("acopos.analytics_raw_data_detail")}
        </DialogTitle>
        <DialogContent>
          <SkeletonCard height={400} />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={close}>
            {translate("actions.back")}
          </Button>
        </DialogActions>
      </Dialog>
    );

  if (error)
    return (
      <Dialog onClose={close} open={isOpen} maxWidth="xl" fullWidth>
        <DialogTitle>
          {translate("acopos.analytics_raw_data_detail")}
        </DialogTitle>
        <DialogContent>
          <Card sx={{ height: 400 }}>
            {translate("user_feedback.an_error_occurred")}
          </Card>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={close}>
            {translate("actions.back")}
          </Button>
        </DialogActions>
      </Dialog>
    );

  if (!data)
    return (
      <Dialog onClose={close} open={isOpen} maxWidth="xl" fullWidth>
        <DialogTitle>
          {translate("acopos.analytics_raw_data_detail")}
        </DialogTitle>
        <DialogContent>
          <Card sx={{ height: 400 }}>{translate("user_feedback.no_data")}</Card>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={close}>
            {translate("actions.back")}
          </Button>
        </DialogActions>
      </Dialog>
    );

  const colors = generateColorScale(
    {
      r: 3,
      g: 244,
      b: 252,
    },
    {
      r: 234,
      g: 12,
      b: 250,
    },
    data.data.data.length,
  );

  return (
    <Dialog onClose={close} open={isOpen} maxWidth="xl" fullWidth>
      <DialogTitle>{translate("acopos.analytics_raw_data_detail")}</DialogTitle>
      <DialogContent>
        <Card>
          <ContextInformation props={data.data.contextInfo} />
          <LineChart.Custom
            title={data.data.analyticName}
            lineType="line"
            legend={{ enabled: true }}
            filename={fileNameFactory({
              name: data.data.analyticName,
              machine: machineName,
            })}
            yAxis={[
              {
                uom: "",
                series: data.data.data.map((serie, i) => {
                  return {
                    type: "line",
                    name: serie.name,
                    data: serie.data,
                    color: colors[i],
                  };
                }),
              },
            ]}
          />
        </Card>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={close}>
          {translate("actions.back")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
