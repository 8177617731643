export const CHART_COLORS = [
  "#7051BD",
  "#D354A6",
  "#9716b5",
  "#D87541",
  "#118DFF",
  "#F87FF1",
  "#2AC3FF",
  "#FFA800",
];

export const GOLDEN_COLOR = "#FFBF00";
